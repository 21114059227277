import React, { createContext, useContext, useEffect, useState } from 'react';

// Components
import AddButton from './components/addTool';
import Tool from './components/tool';

// Context
import { PanelContext } from '../..';
import { mapToolsEntriesToDataInventory } from './components/utils/functions';
import { FormHelperText } from '@mui/material';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { dataInventory, data, validateView } = PanelContext();
    const [validationResults, setValidationResults] = useState({});

    const entries = mapToolsEntriesToDataInventory(data.tools, dataInventory);

    const validateFields = async () => {
        const { results } = await validateView('tools');
        setValidationResults(results);
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    const PassedProps = {
        validationResults,
        setValidationResults,
    };

    return (
        <Context.Provider value={PassedProps}>
            <AddButton />
            {/* List the tools */}
            {entries.length > 0 && (
                <div className="entries">
                    {entries.map((entry, ix) => (
                        <Tool key={ix} data={entry.data} individuals={entry.individuals} />
                    ))}
                </div>
            )}
            {entries.length < 1 && (
                <div className="no-entries">
                    <div className="icon">
                        <i className="elm fa-regular fa-circle-info"></i>
                    </div>
                    <div className="label">
                        {' '}
                        Click the button above to designate the specific tools or third-party services utilized in this process.
                    </div>
                </div>
            )}

            {validationResults['tools'] && (
                <FormHelperText style={{ marginTop: 6 }} error={true}>
                    {validationResults['tools']}
                </FormHelperText>
            )}
        </Context.Provider>
    );
};

export default Component;
