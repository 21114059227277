import React from 'react';

// Components
import { FormHelperText, MenuItem, Select } from '@mui/material';

// Context
import { ViewContext } from '..';
import { PanelContext } from '../../..';
import { legalResponsibilityRoles } from '../../../../../gdprEssentials/steps/legalResponsibility/utils/dataMaps';

const Component = () => {
    const { validationResults } = ViewContext();
    const { data, setData } = PanelContext();

    const onRoleChanged = (newRole) => {
        const payload = {
            role: newRole,
        };

        // If new role is processor
        if (newRole === 'Processor') {
            payload.personalLegalBasis = null;
            payload.sensitiveLegalBasis = null;
            payload.retention = {
                ...data.retention,
                type: 'manual:asRequiredByController',
            };
        }

        // If role was processor and now we changed it to Controller or Joint Controller.
        if (newRole !== 'Processor' && data.role === 'Processor') {
            payload.retention = {
                ...data.retention,
                type: null,
            };
        }

        setData((currentState) => ({ ...currentState, ...payload }));
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Legal Responsibility</div>
                </div>
                <div className="component-description">
                    <div className="label">
                        Learn more about what responsibility to choose{' '}
                        <a
                            href="https://privaseeio.notion.site/Differences-between-legal-responsibility-roles-f8b9ba63248d40f6b63fbe26fc1ba9db?pvs=4"
                            target="_blank"
                        >
                            here
                        </a>
                        .
                    </div>
                </div>
                <div className="component-content">
                    <Select
                        onChange={(ev) => onRoleChanged(ev.target.value)}
                        fullWidth={true}
                        variant="standard"
                        data-cy="selector-legalBasis"
                        value={data.role || 'disabled'}
                    >
                        {!data.role && (
                            <MenuItem disabled value="disabled">
                                No selection
                            </MenuItem>
                        )}
                        {legalResponsibilityRoles.map((c, ix) => (
                            <MenuItem key={ix} value={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                    {validationResults[`role`] !== undefined && <FormHelperText error={true}>{validationResults['role']}</FormHelperText>}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
