import React, { useEffect, useState } from 'react';
import { ComponentState } from '../../..';
import { trackUserInteraction } from '../../../../../../utils/helpers';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import ClickAwayListener from '@mui/material/ClickAwayListener';
let themeTimer = null;

const Component = () => {
    const { data, updateData, loading } = ComponentState();
    const [pickingColor, setPickingColor] = useState(false);

    const onColorChanged = (color) => {
        if (themeTimer) clearTimeout(themeTimer);
        themeTimer = setTimeout(() => {
            updateData(`privacyPortalSettings.theme.color`, color);
        }, 150);
    };

    useEffect(() => {
        if (themeTimer) {
            clearTimeout(themeTimer);
        }
    }, []);

    const themeExists = data && data.privacyPortalSettings && data.privacyPortalSettings.theme ? true : false;

    return (
        <React.Fragment>
            <div className="form-label mb-less">Color</div>
            <div className="form-description">Changing this color here will update all the branded colors from your privacy portal </div>
            <div className="form-content">
                <div className="color-picker-wrapper">
                    <div
                        className="element"
                        data-cy="color-picker"
                        style={{
                            backgroundColor: loading === false && themeExists ? data.privacyPortalSettings.theme.color : '#F79A43',
                        }}
                        onClick={() => {
                            if (loading === true) return false;
                            trackUserInteraction(`Selected "Color Picker`);
                            setPickingColor(true);
                        }}
                    >
                        {pickingColor && (
                            <ClickAwayListener onClickAway={() => setPickingColor(false)}>
                                <div className="color-popper">
                                    <HexColorPicker
                                        color={themeExists ? data.privacyPortalSettings.theme.color : '#F79A43'}
                                        onChange={onColorChanged}
                                    />
                                    <HexColorInput
                                        color={themeExists ? data.privacyPortalSettings.theme.color : '#F79A43'}
                                        onChange={onColorChanged}
                                        className="input-color"
                                        data-cy="color-picker-input"
                                    />
                                </div>
                            </ClickAwayListener>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
