import React, { useState } from 'react';
import { trackUserInteraction } from '../../../../utils/helpers';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { badgeEntries } from '../mapping';

const Section = (props) => {
    const [badgeSelected, setBadgeSelected] = useState(null);

    const onBadgeSelected = async (entry) => {
        await trackUserInteraction(`Selected Badge`, { badgeNumber: entry.id });
        setBadgeSelected(entry);
    };

    const getAPILink = window.location.host.includes(`localhost`) ? `http://localhost:5000` : `${window.location.origin}`;
    const imageSnippet = `${getAPILink}/api/${props.data.company._id}/badge/${badgeSelected?.id}`;
    const snippetCode = `<a href="${window.location.origin}/privacy-portal/${props.data.company._id}?utm_source=badge" rel="nofollow" target="_blank" style="border:0px"><img src="${imageSnippet}" loading="lazy" style="max-width: 100%; max-height: ${badgeSelected?.height}px;" alt="Privasee GDPR Badge" /></a>`;

    const dismissModal = async () => {
        await trackUserInteraction(`Selected "Dismiss"`);
        setBadgeSelected(null);
    };

    const copyCode = async () => {
        navigator.clipboard.writeText(snippetCode);
        await trackUserInteraction(
            `Selected "Copy Code"`,
            { badgeId: badgeSelected.id },
            {
                type: `badges`,
            },
        );
        setBadgeSelected(null);
        window.showAlert(
            'Code copied',
            `You've copied the code to your clipboard. Now please go and paste the code in your website's code where you wish to have the badge showing.`,
            `success`,
        );
    };

    return (
        <div className="section badges">
            <div className="section-summary">
                <div className="title">Badges</div>
                <div className="description">
                    Website badges to promote your GDPR standards. When a visitor clicks on a badge, it takes them to your Privacy Portal.
                </div>
            </div>

            <div className="section-content">
                <div className="steps">
                    <div className="step-entry">
                        <div className="step-header">
                            <div className="step-badge">Step 1</div>
                            <div className="step-label">Select the right badge</div>
                        </div>
                        <div className="step-content no-max-width">
                            <div className="step-text">
                                On the list below you have badges of different sizes and styles. Choose the one that best fits your website
                                and click on it to set it live.
                            </div>
                            <div className="badges">
                                {badgeEntries.map((entry, ix) => (
                                    <div
                                        className={`entry`}
                                        key={ix}
                                        onClick={() => onBadgeSelected(entry, ix)}
                                        onContextMenu={(ev) => {
                                            ev.preventDefault();
                                            onBadgeSelected(entry, ix);
                                        }}
                                    >
                                        <div className="content">
                                            <img
                                                style={{ height: entry.height, width: entry.width }}
                                                className="preview"
                                                src={`${entry.imagePath}`}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="step-entry">
                        <div className="step-header">
                            <div className="step-badge">Step 2</div>
                            <div className="step-label">Place it in the right spot</div>
                        </div>
                        <div className="step-content">
                            <div className="step-text">
                                After choosing a badge the final step is to find a place for it on your site. Most people decide to place it
                                in their website's footer or hero section. You can get as creative as you want!
                            </div>
                            <div className="step-text" style={{ marginTop: 6 }}>
                                Cool developer tip: The badge has a max-width CSS property set in the code snippet. You can play around with
                                that to make the badge slightly bigger or smaller.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {badgeSelected !== null && (
                <Dialog className="badges-modal" open={true} maxWidth="md" fullWidth={true}>
                    <DialogTitle>Add your Badge</DialogTitle>
                    <DialogContent dividers={true}>
                        <div className="content">
                            <div className="left-side">
                                <div className="title">Preview</div>
                                <div className="preview-badge">
                                    <img style={{ height: badgeSelected.height }} className="preview" src={`${badgeSelected.imagePath}`} />
                                </div>
                            </div>
                            <div className="right-side">
                                <div className="text">
                                    Copy the code snippet beneath this text by pressing the “Copy the code” button. Then go to your
                                    website's code and paste it some place within your page body.
                                </div>
                                <div className="text">A great place to place the badge is for example the footer.</div>
                                <TextField value={snippetCode} disabled={true} fullWidth={true} />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="modal-footer">
                        <Button variant="outlined" onClick={dismissModal}>
                            Dismiss
                        </Button>
                        <Button variant="contained" onClick={copyCode}>
                            Copy the code
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default Section;
