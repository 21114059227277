import React from 'react';
import { useHistory } from 'react-router-dom';

// Components
import { Button } from '@mui/material';

// Dependencies
import { trackUserInteraction } from '../../../../utils/helpers';
import { ModuleState } from '..';

const Component = () => {
    const { setOptions } = ModuleState();
    const history = useHistory();

    const updateOnboardingFlow = () => {
        trackUserInteraction(`Selected "Updated Onboarding Flow"`);
        history.push('/stages/gdpr-essentials/edit');
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <div className="title">Tools and Third Parties</div>
                <div className="buttons">
                    <Button variant="outlined" color="primary" onClick={updateOnboardingFlow} style={{ marginRight: 16 }}>
                        Update Onboarding Flow
                    </Button>

                    <Button
                        variant="contained"
                        data-cy="cta-addTools-btn"
                        color="primary"
                        onClick={() => setOptions((currentState) => ({ ...currentState, pickingTools: true }))}
                    >
                        <i className="fa-solid fa-plus" style={{ marginRight: 8 }}></i>
                        Add a tool or third party
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
