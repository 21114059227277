import React, { useState } from 'react';
import { ComponentState } from '../../../..';
import availableLanguages from '../../../../../../../dummy_data/availableLanguages.json';

import { Select, MenuItem } from '@mui/material';
import { AuthStore } from '../../../../../../../utils/context/authStore';
import { checkUserFlag, trackUserInteraction } from '../../../../../../../utils/helpers';
import { useEffect } from 'react';

const Component = () => {
    const { data, loading, updateData } = ComponentState();
    const { account } = AuthStore();
    const [inputValue, setInputValue] = useState(data.languagesAvailable);
    const [languages, setLanguages] = useState(availableLanguages.filter((x) => x.experimental !== true));

    const notIncludedInYourPlan = async () => {
        await trackUserInteraction(`Feature not included in company plan`, {
            reason: `They tried to add more languages than their current plan allows them to`,
        });
        window.showAlert(
            `Plan limitation`,
            `Your plan allows you to have a maximum of ${account.companyPackage.usageLimits.maxLanguages} languages enabled. Get in touch with us to discuss an upgrade for your plan.`,
            'error',
            [
                {
                    text: 'Dismiss',
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Dismiss"`);
                    },
                },
                {
                    text: 'Contact Us',
                    onClick: async ({ dismissAlert }) => {
                        await trackUserInteraction(`Selected "Contact Us to Upgrade"`);
                        dismissAlert();
                        window.location.href = `mailto:support@privasee.io?subject=Upgrading current plan`;
                    },
                },
            ],
        );
    };

    const getFlaggedLanguages = async () => {
        const result = await checkUserFlag(`privacyPortalLanguages`, []);

        // Get the additional languages
        const additionalLanguages = availableLanguages.filter((x) => x.experimental === true && result.includes(x.code));

        // Save
        setLanguages([...languages, ...additionalLanguages]);
    };

    useEffect(() => {
        if (inputValue.length < 1) return false;
        updateData(`languagesAvailable`, inputValue);
        if (!inputValue.includes(data.defaultLanguage) || inputValue.length < 1) {
            updateData(`defaultLanguage`, inputValue[0]);
        }
        // eslint-disable-next-line
    }, [inputValue]);

    useEffect(() => {
        getFlaggedLanguages();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className="form-label">Languages available</div>
            <div className="form-content">
                <Select
                    value={inputValue}
                    fullWidth={true}
                    renderValue={(value) =>
                        value.length > 0
                            ? value
                                  .map((val) => {
                                      return languages.find((l) => l.code === val)
                                          ? languages.find((l) => l.code === val).label
                                          : `(Experimental) ${val}`;
                                  })
                                  .join(', ')
                            : 'Please select at least one language'
                    }
                    displayEmpty={true}
                    data-cy="languages-available-select"
                    disabled={loading}
                    multiple={true}
                    error={inputValue.length < 1 ? true : false}
                    helperText={inputValue.length < 1 ? 'You need at least one language selected.' : ''}
                    onChange={(ev) => {
                        if (
                            ev.target.value.length > data.languagesAvailable.length && // they're adding new ones
                            ev.target.value.length > account.companyPackage.usageLimits.maxLanguages && // checking limit
                            !window.isCypressTestEnvironment // temp bugfix
                        )
                            return notIncludedInYourPlan();
                        setInputValue(ev.target.value);
                    }}
                >
                    {languages.map((lang, ix) => (
                        <MenuItem key={ix} value={lang.code}>
                            {lang.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </React.Fragment>
    );
};

export default Component;
