import React, { createContext, useContext, useRef, useEffect, useState } from 'react';

// Dependencies
import { logError, trackUserInteraction, validateAgainstSchema } from '../../../../utils/helpers';

// Components
import Modal from '../../../components/modulePopout';
import Tabs from './components/tabs';
import Actions from './components/actions';

// Views
import Information from './views/information';
import Tools from './views/tools';
import Retention from './views/retention';
import DangerZone from './views/dangerZone';

// Validation
import { InformationViewSchema, RetentionViewSchema, ToolsViewSchema } from '../utils/validations';
import { makeEndpointRequest } from '../../../../utils/endpoints';
import { formatStringLegalToObject } from '../../thirdParties/components/storageLocations/utils/functions';

// Context
const Context = createContext({});
export const PanelContext = () => useContext(Context);

const Component = (props) => {
    const [data, setData] = useState(props.initialData);
    const [tab, setTab] = useState('information');
    const [panelVisible, setPanelVisible] = useState(true);

    // The ref that controls the panel
    const modalController = useRef(null);

    useEffect(() => {
        if (!props.innerRef) return false;

        // This will allow us to set tab and process inspected from outside the panel.
        props.innerRef.current = {
            setTab,
            modal: modalController,
        };

        // eslint-disable-next-line
    }, []);

    const checkIsUsingSensitiveData = () => {
        const sensitiveElements = props.dataInventory.elements.filter((c) => c.sensitive === true).map((c) => c._id);
        let isSensitive = false;

        data.tools.forEach((tool) => {
            tool.individuals.forEach((individual) => {
                const match = individual.elements.find((c) => sensitiveElements.includes(c));

                if (match) {
                    isSensitive = true;
                }
            });
        });

        return isSensitive;
    };

    const getProcessValidation = async () => {
        let failed = null;
        let reasons = null;

        const views = ['information', 'tools', 'retention'];

        for (const view of views) {
            const { status, results } = await validateView(view);

            if (status === false && failed === null) {
                failed = view;
                reasons = results;
            }
        }

        return { status: failed ? false : true, payload: { failedView: failed ? failed : null, results: reasons } };
    };

    // A simple way to check if current label is used by a different processor.
    const isLabelUsedByDifferentProcessor = () => {
        // Check if tool with same name already exists
        const matchIndex = props.dataInventory.processes.findIndex((i) => i.label === data.label);
        const match = matchIndex === -1 ? null : props.dataInventory.processes[matchIndex];

        // Does exist
        if (match && match._id !== data._id) {
            return true;
        } else return false;
    };

    const validateView = async (id) => {
        try {
            if (id === 'information') {
                // Validate against schema
                await validateAgainstSchema(InformationViewSchema, {
                    ...data,
                    // Other important to know for validation
                    isUsingSensitiveData: checkIsUsingSensitiveData(),
                });

                // Does exist
                if (isLabelUsedByDifferentProcessor()) {
                    return {
                        status: false,
                        results: {
                            label: `Process Name is already used. Try adding a suffix: ${data.label} (2)`,
                        },
                    };
                }
            }

            if (id === 'tools') {
                await validateAgainstSchema(ToolsViewSchema, data);
            }

            if (id === 'retention') {
                await validateAgainstSchema(RetentionViewSchema, data);
            }

            return {
                status: true,
                results: {},
            };
        } catch (err) {
            return {
                status: false,
                results: err,
            };
        }
    };

    const showDialogAboutToolsIncomplete = async (func, action) => {
        trackUserInteraction(`Warned about proceeding with Incomplete tools`, { action });

        window.showAlert(
            'Confirmation',
            `Before proceeding to ${
                action === 'update' ? 'saving changes' : 'creating this process'
            }, be aware that you have incomplete tools (missing individuals or data subjects) which won't be linked unless you provide the missing information. Are you sure you want to continue?`,
            'warning',
            [
                {
                    text: 'Fix Information',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        trackUserInteraction(`Selected "Complete Information"`);
                        setTab('tools');
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        trackUserInteraction(`Selected "Confirm"`);
                        func();
                    },
                },
            ],
        );
    };

    const getIncompleteTools = () => {
        return data.tools.filter((c) => {
            if (c.individuals.length < 1) return true;
            if (c.individuals.find((c) => c.elements.length < 1)) return true;

            return false;
        });
    };

    const createProcess = async () => {
        // Is already closing. Avoid button spamming.
        if (modalController.current.closing === true) return false;

        const validation = await getProcessValidation();

        const createFunc = async () => {
            // Create the process
            await props.onProcessCreation({
                data,
                controller: modalController,
                validation,
            });
        };

        // If we have incomplete tools
        const incompleteTools = getIncompleteTools();
        if (incompleteTools.length > 0) return showDialogAboutToolsIncomplete(createFunc, 'create');

        createFunc();
    };

    const updateProcess = async () => {
        // Is already closing. Avoid button spamming.
        if (modalController.current.closing === true) return false;

        const validation = await getProcessValidation();

        const updateFunc = async () => {
            // Update Process
            await props.onProcessUpdate({
                data,
                controller: modalController,
                validation,
            });
        };

        if (validation.status === false) {
            trackUserInteraction(`Selected "Save Changes" - Warned about missing Information`);
            window.showAlert(
                'Confirmation',
                `This process is missing information. Add the missing information now or Save and come back later.`,
                'warning',
                [
                    {
                        text: 'Save Changes',
                        dataCy: `alert-cancel-button`,

                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            trackUserInteraction(`Selected "Save Changes"`);

                            // If we have incomplete tools
                            const incompleteTools = getIncompleteTools();
                            if (incompleteTools.length > 0) return showDialogAboutToolsIncomplete(updateFunc, 'update');

                            updateFunc();
                        },
                    },
                    {
                        text: 'Add Information',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            setTab(validation.payload.failedView);
                            trackUserInteraction(`Selected "Add Information"`);
                        },
                    },
                ],
            );

            return false;
        }

        // If we have incomplete tools
        const incompleteTools = getIncompleteTools();
        if (incompleteTools.length > 0) return showDialogAboutToolsIncomplete(updateFunc, 'update');

        updateFunc();
    };

    const deleteProcess = () => {
        // Is already closing. Avoid button spamming.
        if (modalController.current.closing === true) return false;

        window.showAlert(
            'Confirmation',
            `Are you sure you want to delete this data process? If you are sure, confirm by writing "Delete" in the input below.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        // Hide the alert..
                        dismissAlert();
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert, inputValue }) => {
                        // If they didn't enter the right one.
                        if (inputValue.toString().toLowerCase() !== 'delete') {
                            return false;
                        }

                        dismissAlert();
                        props.onProcessDelete({ data, controller: modalController });
                    },
                },
            ],
            {
                input: {
                    type: 'text',
                    placeholder: 'Delete',
                    validationField: ({ inputValue }) => {
                        if (inputValue.toString().toLowerCase() !== 'delete') return 'Invalid confirmation message.';

                        return null; // no error, all good.
                    },
                },
            },
        );
    };

    const onCloseButton = () => {
        // Is already closing. Avoid button spamming.
        if (modalController.current.closing === true) return false;

        const closeFunc = () => {
            trackUserInteraction(`Closed Processor Panel`);

            // Close..
            props.onClose(false);
        };

        // if is editing and we have unsaved data.
        if (JSON.stringify(data) !== JSON.stringify(props.initialData) && props.isEditing) {
            window.showAlert(
                'Confirmation',
                `Are you sure you want to exit without saving your changes? If you proceed, any unsaved changes will be discarded.`,
                'warning',
                [
                    {
                        text: `Cancel`,
                        dataCy: `alert-cancel-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                    {
                        text: 'Confirm',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();

                            // Close panel
                            closeFunc();
                        },
                    },
                ],
            );
            return false;
        }

        closeFunc();
    };

    /**
     * Loads recommendations for a given label.
     *
     * @param {*} label - The label for which recommendations are loaded.
     * @param {*} recommendation - The recommendation data to be processed.
     * @returns {boolean} - Returns true if the recommendations are loaded successfully, otherwise false.
     * @throws {Error} - Throws an error if there is any problem during the loading process.
     */

    const loadRecommendationsForLabel = async (label, recommendation) => {
        try {
            // Get article 6 and 9
            const articles = formatStringLegalToObject(recommendation);

            let recommendationApplied = {
                role: recommendation.legalResponsibility,
                personalLegalBasis: articles.personalLegalBasis,
                description: recommendation.description || '',
            };

            if (articles.sensitiveLegalBasis) {
                recommendation.sensitiveLegalBasis = articles.sensitiveLegalBasis;
            }

            // Update the data..
            setData((currentState) => {
                // Formatting the new data..
                let newState = { ...currentState };

                // Load the simple recommendations
                newState = { ...newState, ...recommendationApplied };

                // Apply the retention type recommendation if present
                if (recommendation.retentionPeriod) {
                    newState.retention.type = `recommendations:${recommendation.retentionPeriod}`;
                }

                return newState;
            });
        } catch (err) {
            await logError(`processes.loadRecommendationsForLabel`, err, { label });
            return false;
        }
    };

    const onRecommendedLabelSelected = async (label) => {
        try {
            // Update process label
            const numberOfProcesses = props.dataInventory.processes.filter((c) => c.label.includes(label)).length;

            // If the label already existed we need to add a suffix.
            if (numberOfProcesses > 0) {
                setData((currentState) => ({ ...currentState, label: `${label} (${numberOfProcesses + 1})` }));
            }

            // Load recommendations
            const specificRecommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: [],
                processes: [label],
            });

            // Get the exact recommendation
            const recommendation = specificRecommendations.processes[0];

            // If there is none
            if (!recommendation) return false;

            window.showAlert('Confirmation Required', `Do you want to load recommendations for ${label}?`, 'warning', [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                    },
                },

                {
                    text: `Confirm`,
                    dataCy: `alert-submit-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        loadRecommendationsForLabel(label, recommendation);
                    },
                },
            ]);
        } catch (err) {
            await logError(`onRecommendedLabelSelected`, err, { label });
            return false;
        }
    };

    const PassedProps = {
        // Data
        data,
        companyData: props.companyData,
        initialData: props.initialData,
        setData,
        // Validation
        validateView,
        // Important callbacks
        createProcess,
        deleteProcess,
        saveChanges: updateProcess,
        // Data Inventory
        dataInventory: props.dataInventory,
        setDataInventory: props.setDataInventory,
        // recommendations
        toolsRecommendations: props.toolsRecommendations,
        vitalRecommendations: props.vitalRecommendations,
        // Tab
        tab,
        setTab,
        // Is editing
        isUsingSensitiveData: checkIsUsingSensitiveData(),
        isEditing: props.isEditing,
        isLabelUsedByDifferentProcessor,
        panelVisible,
        setPanelVisible,
        // Callback functions
        onRecommendedLabelSelected,
    };

    const MappedScreens = {
        information: Information,
        tools: Tools,
        retention: Retention,
        dangerZone: DangerZone,
    };

    const MappedScreen = MappedScreens[tab] ? MappedScreens[tab] : null;

    return (
        <React.Fragment>
            <Context.Provider value={PassedProps}>
                <div id="panel-container">
                    <Modal
                        className={`shareable-components module-panel-editor data-inventory edit-popout-model modal-process-editor`}
                        innerRef={modalController}
                        visible={panelVisible}
                        header={{
                            title: !props.isEditing ? 'Create a new process' : 'Edit a process',
                            description: `${(props.isEditing ? props.initialData.label : data.label) || 'New Process'}`,
                            closeButton: <Actions onClose={onCloseButton} />,
                        }}
                        content={
                            <React.Fragment>
                                <Tabs />
                                <div className={`component-panel-content ${tab}`}>
                                    <div className="--container">
                                        <MappedScreen />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    />
                </div>
            </Context.Provider>
        </React.Fragment>
    );
};

export default Component;
