import React from 'react';

// Components
import Header from './components/header';
import RemoveButton from './components/removeButton';
import StorageLocation from './components/storageLocation';
import EditStorageLocations from './components/editStorageLocations';

// MUI Components
import { FormHelperText } from '@mui/material';

const Component = (props) => {
    const storageLocationsMissingElements = props.storageLocations.filter((c) => c.elements.length < 1);

    return (
        <React.Fragment>
            <div className="component-tool">
                <Header label={props.data.label} removeBtn={<RemoveButton data={props.data} />} />
                <div className="individuals">
                    {props.storageLocations.map((c, index) => (
                        <StorageLocation key={index} process={props.data} storageLocation={c.data} elements={c.elements} />
                    ))}
                    {props.storageLocations.length < 1 && (
                        <React.Fragment>
                            <div className="--component-no-entry">
                                This process does not gather data from the current individual. To designate the tool for potential data
                                collection, click the "Add a Tool or Third Party" button.
                            </div>
                            <FormHelperText error={true} style={{ marginTop: 12 }}>
                                To integrate this process into individual, please add at least one Tool or Third Party.
                            </FormHelperText>
                        </React.Fragment>
                    )}
                    {storageLocationsMissingElements.length > 0 && (
                        <React.Fragment>
                            <FormHelperText error={true} style={{ marginTop: 12 }}>
                                Please provide details on the data collected from tools or third parties highlighted in red.
                            </FormHelperText>
                        </React.Fragment>
                    )}
                    <div className="footer">
                        <EditStorageLocations storageLocations={props.storageLocations} process={props.data} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
