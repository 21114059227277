export const renderText = (textContent) => {
    const linkRegex = /-\s\[(.*)\]\((.*)\)/g;

    let newText = textContent;

    const matches = textContent.matchAll(linkRegex);
    Array.from(matches).forEach((match) => {
        const linkText = match[1];
        const href = match[2];
        newText = newText.replace(match[0], `<a target="_blank" class="link" href="${href}">- ${linkText}</a>`);
    });

    newText = newText
        .split('\n')
        .filter((line) => line.trim().length > 0)
        .map((line) => {
            // if (line.startsWith('<a')) {
            //     return line;
            // }
            return `<p>${line}</p>`;
        })
        .join('');

    return newText;
};
