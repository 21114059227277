const list = {
    en: 'English',
    de: 'German',
    fr: 'French',
    es: 'Spanish',
    it: 'Italian',
    pl: 'Polish',
    nl: 'Dutch',
    da: 'Danish',
    hu: 'Hungarian',
    fi: 'Finnish',
    no: 'Norwegian',
    sl: 'Slovenian',
    cs: 'Czech',
    sv: 'Swedish',
    ro: 'Romanian',
    sk: 'Slovak',
    tr: 'Turkish',
    pt: 'Portuguese',
    hr: 'Croatian',
    zh: 'Chinese',
    ru: 'Russian',
    bg: 'Bulgarian',
    lt: 'Lithuanian',
    lv: 'Latvian',
    uk: 'Ukrainian',
    bs: 'Bosnian',
    sr: 'Serbian',
    el: 'Greek',
    et: 'Estonian',
    is: 'Icelandic',
    ja: 'Japanese',
    ko: 'Korean',
    th: 'Thai',
    sq: 'Albanian',
    be: 'Belarusian',
    hi: 'Hindi',
    ur: 'Urdu',
    ar: 'Arabic',
    zh_tw: 'Traditional Chinese (Taiwan)',
    zh_hk: 'Traditional Chinese (Hong Kong)',
    vi: 'Vietnamese',
    sr_latn: 'Serbian (Latin)',
    nb: 'Bokmal, Norwegian',
    nn: 'Nynorsk, Norwegian',
    mk: 'Macedonian',
    ca: 'Catalan',
    mn: 'Mongolian',
    pt_br: 'Brazilian Portuguese',
    id: 'Indonesian',
    ka: 'Georgian',
    kk: 'Kazakh',
    he: 'Hebrew',
    fa: 'Persian',
    cy: 'Welsh',
    uz_latn: 'Uzbek',
    az_latn: 'Azerbaijani',
    af: 'Afrikaans',
    hy: 'Armenian',
    gl: 'Galician',
    ms: 'Malay',
};

export default list;
