import React from 'react';
import { ComponentState } from '../../../../..';
import moment from 'moment';

const Component = (props) => {
    const { serviceVerifications } = ComponentState();

    const getMessage = () => {
        if (props.scanning) return `* It can take up to 30 seconds to scan your website.`;

        const cookieBanner = serviceVerifications['cookieBanner'];

        if (cookieBanner !== null) {
            return `Last scanned: ${moment(cookieBanner.scannedAt).format('dddd, DD MMMM YYYY - HH:mm')}`;
        }

        return null;
    };

    if (getMessage() === null) return null;

    return (
        <React.Fragment>
            <div className="text-information">{getMessage()}</div>
        </React.Fragment>
    );
};

export default Component;
