import React from 'react';

const Component = (props) => {
    return (
        <React.Fragment>
            <div className="header">
                <div className="left-side">
                    <div className="label">{props.label}</div>
                </div>
                <div className="right-side">{props.removeBtn}</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
