import React from 'react';
import { Button, Checkbox, Select, MenuItem } from '@mui/material';
import { trackUserInteraction } from '../../../../../../../../utils/helpers';

const MatrixMapping = (props) => {
    const getMatrixEntry = (element, individual) => {
        const matchingObject = props.storageData.matrixMap.find((elm) => {
            if (elm.process === props.matrixProcess && elm.element === element && elm.individual === individual) return true;
            else return false;
        });
        return matchingObject !== undefined ? [true, props.storageData.matrixMap.indexOf(matchingObject)] : [false, undefined];
    };

    // This updates the value

    const handleCheckChange = (element, individual) => {
        const isChecked = getMatrixEntry(element, individual);
        const newMatrix = [...props.storageData.matrixMap];

        if (isChecked[0] === false) {
            newMatrix.push({
                process: props.matrixProcess,
                element,
                individual,
            });
        } else {
            newMatrix.splice(isChecked[1], 1);
        }
        props.updateStorageData(`matrixMap`, newMatrix);
    };

    const checkAllBoxes = () => {
        const newMatrix = [...props.storageData.matrixMap.filter((elm) => elm.process !== props.matrixProcess)];
        props.storageData.individuals.forEach((individual) => {
            props.storageData.elements.forEach((element) => {
                newMatrix.push({
                    process: props.matrixProcess,
                    element: element.label,
                    individual: individual.label,
                });
            });
        });
        props.updateStorageData(`matrixMap`, newMatrix);
        trackUserInteraction(`Selected "Check all boxes"`);
    };

    const onProcessChanged = async (value) => {
        props.setMatrixProcess(value);
        await trackUserInteraction(`Selected "Change Process`, { newProcess: value });
    };

    const iteratedElements = props.storageData.elements;

    if (props.matrixProcess === null) return null;

    return (
        <React.Fragment>
            <div className="module-elements">
                <div className="header-area">
                    <div className="process-selector">
                        <span className="text">For the purpose of </span>
                        <div className="dropdown">
                            <Select
                                value={props.matrixProcess}
                                onChange={(e) => onProcessChanged(e.target.value)}
                                data-cy={`input-processes`}
                            >
                                {props.storageData.processes.map((process, index) => (
                                    <MenuItem data-cy={`process-entry-${index}`} value={process.label} key={index}>
                                        <span className="text">{process.label}</span>
                                        {props.storageData.matrixMap.filter((matrix) => matrix.process === process.label).length < 1 && (
                                            <div
                                                style={{ display: 'inline-block', marginLeft: 8 }}
                                                className="badge badge-data-warning blue"
                                            />
                                        )}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <span className="text">we use?</span>
                    </div>
                    <Button className="check-all-boxes" onClick={checkAllBoxes} variant="contained" data-cy="check-all-boxes">
                        Check all boxes
                    </Button>
                </div>
                <div className="matrix-table">
                    <div className="elements">
                        <div className="entry empty" />
                        {iteratedElements.map((entry, index) => (
                            <div className="entry" key={index}>
                                {entry.label}
                                {!props.storageData.matrixMap.find((map) => map.element === entry.label) && (
                                    <div style={{ marginLeft: 7 }} className="badge badge-data-warning blue" />
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="scrollable-individuals">
                        {props.storageData.individuals.map((entry, index) => (
                            <div className="entry" key={index}>
                                <div className="label">
                                    {entry.label}
                                    {!props.storageData.matrixMap.find((map) => map.individual === entry.label) && (
                                        <div style={{ marginLeft: 7 }} className="badge badge-data-warning blue" />
                                    )}
                                </div>
                                <div className="checkboxes">
                                    {iteratedElements.map((elementEntry, elementIndex) => (
                                        <div key={elementIndex} className="check-entry">
                                            <Checkbox
                                                data-cy={`checkbox-${index}-${elementIndex}`}
                                                key={entry.label}
                                                checked={getMatrixEntry(elementEntry.label, entry.label)[0]}
                                                onChange={() => handleCheckChange(elementEntry.label, entry.label)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MatrixMapping;
