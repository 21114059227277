import React from 'react';
import { ComponentState } from '../../..';

// Component
import Autocomplete from '../utils/autocomplete';

const Component = () => {
    const { draftData, updateDraftData, vitalRecommendations } = ComponentState();

    const getOptions = () => {
        // eslint-disable-next-line
        let arr = vitalRecommendations.elements;

        arr = arr.map((x) => ({ label: x.label, description: x.description }));
        return arr;
    };

    return (
        <React.Fragment>
            <div className="form-group extra-spacing">
                <div className="form-label mb">
                    What <b>categories of personal data</b> do you use when providing your services/platform?
                </div>
                <div className="form-content">
                    <Autocomplete
                        value={draftData.elements}
                        options={getOptions()}
                        onDataChanged={(newValues) => updateDraftData('elements', newValues)}
                        placeholder="Select elements of personal data"
                        includeDescriptions={true}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
