import React from 'react';

// Context
import { ViewContext } from '..';

const Component = (props) => {
    const { onOptionSelected } = ViewContext();

    /**
     * Formats the description by removing '-' and '...' sequences.
     * @returns {string} The formatted description.
     */

    const formatDescription = () => {
        // Replace all occurrences of '-' and '...' with an empty string
        // using a regular expression.
        return props.data.description.replace(/-|\.\.\./g, '');
    };

    return (
        <React.Fragment>
            <div
                className={`entry full-wd ${props.data.isChecked ? 'selected' : 'not-selected'}`}
                onClick={() => onOptionSelected(props.data.label)}
            >
                <div className="content">
                    <div className="left-side">
                        <div className="label">{props.data.label}</div>
                        <div className="description">
                            {props.data.description.length > 0 && `Examples include: `}
                            {formatDescription()}
                        </div>
                    </div>
                    <div className="checkmark">
                        <i className="icon fa-solid fa-check"></i>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
