import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@mui/material';
import React, { useState } from 'react';
import { ViewContext } from '..';

const Component = () => {
    const { setCurrentOptions } = ViewContext();

    const [inputText, setInputText] = useState('');
    const [modalActive, setModalActive] = useState(false);

    const createCustom = () => {
        setInputText('');
        setModalActive(true);
    };

    const dismissModal = () => setModalActive(false);

    const saveNewIndustry = () => {
        dismissModal();
        setCurrentOptions((currentState) => [...currentState, inputText]);
    };

    return (
        <React.Fragment>
            <div className={`entry last`} data-cy={`entry-create-another`}>
                <div className="content" onClick={createCustom}>
                    <i className={`icon fa-solid fa-plus`}></i>

                    <div className="label" data-cy="entry-other-label">
                        Add custom industry
                    </div>
                </div>
            </div>
            {modalActive && (
                <React.Fragment>
                    {modalActive && (
                        <Dialog open={modalActive} maxWidth="xs" fullWidth>
                            <DialogTitle data-cy="dialog-title">What is this new industry?</DialogTitle>
                            <DialogContent style={{ width: '100%' }}>
                                <Input
                                    placeholder="Industry name"
                                    value={inputText}
                                    onChange={(e) => setInputText(e.target.value)}
                                    fullWidth
                                    data-cy="dialog-input"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={dismissModal} variant="outlined" data-cy="dialog-dismiss-button">
                                    Dismiss
                                </Button>
                                <Button
                                    onClick={saveNewIndustry}
                                    variant="contained"
                                    color="primary"
                                    disabled={inputText.trim().length < 1}
                                    data-cy="dialog-save-button"
                                >
                                    Create
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Component;
