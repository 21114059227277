import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { logError } from '../../../../../utils/helpers';
import { ComponentState } from '../..';
import { AuthStore } from '../../../../../utils/context/authStore';
import { makeEndpointRequest } from '../../../../../utils/endpoints';

const Component = () => {
    const history = useHistory();
    const { globalFlags } = AuthStore();
    const { draftData } = ComponentState();
    const [newMarked, setNewMarked] = useState([]);

    const onComplete = () => {
        // Redirect
        history.push('/data-processing-agreements');
    };

    const reviewInventory = () => {
        history.push('/stages/gdpr-essentials/edit/data-inventory');
    };

    // Check which one is a new sub-processor..
    const checkNewSubProcessors = async () => {
        try {
            if (!globalFlags.missingSubProcessors) return []; // No flag access.

            // Not the interesting kind.
            if (draftData.parties.sender.role !== 'processor' || draftData.parties.receiver.role !== 'controller') return [];

            // This will check if any of these sub processors are in our storage locations OR have been suggested already.
            // If they weren't it means they will be marked.
            const newProcessors = await makeEndpointRequest(`IsNewSubProcessors`, {
                input: draftData.subProcessors.map((c) => c.label),
            });

            setNewMarked(newProcessors);
        } catch (err) {
            await logError(`dpa.checkNewSubProcessors`, err);
        }
    };

    useEffect(() => {
        checkNewSubProcessors();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className="route-content completed">
                <div className="icon">
                    <i className="elm fa-regular fa-file-check"></i>
                </div>
                <div className="heading">Congratulations!</div>
                <div className="content">Your Data Processing Agreement is being generated! You can downloaded it on the next screen.</div>
                {newMarked.length > 0 && (
                    <div className="content">
                        You've added {newMarked.length} new tools and third parties as sub-processors that are not in your personal data
                        inventory. We need a bit more information to add them to your inventory.
                    </div>
                )}
                {newMarked.length > 0 ? (
                    <React.Fragment>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Button variant="contained" color="primary" style={{ marginRight: 8 }} onClick={onComplete}>
                                Update Later
                            </Button>
                            <Button variant="contained" color="primary" onClick={reviewInventory}>
                                Update Now
                            </Button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Button variant="contained" color="primary" onClick={onComplete}>
                            Great!
                        </Button>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
