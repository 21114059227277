import { Button } from '@mui/material';
import React from 'react';
import { PageContext } from '..';

const Component = () => {
    const { currentRole } = PageContext();

    const onButtonPressed = () => {
        document.dispatchEvent(new CustomEvent('workspaceUsers:invite'));
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <div className="details">
                    {' '}
                    <div className="heading">Manage Users</div>
                    <div className="description">Manage who has access to this workspace and their roles</div>
                </div>
                {currentRole === 'admin' && (
                    <div className="right-side">
                        <Button variant="contained" color="primary" onClick={onButtonPressed}>
                            Invite people
                        </Button>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
