import React from 'react';

// Components
import { Autocomplete, TextField } from '@mui/material';
import { formatDescription } from '../../../../gdprEssentials/steps/identifyElements';

const Component = (props) => {
    /**
     *
     * @param {*} newValue New array of autocomplete
     * @invokes A function depending if they added or remove an entity.
     */

    const onValueChanged = (newValue) => {
        // If they added a new entity
        if (newValue.length > props.value.length) {
            // Get the added entity.
            let entityAdded = newValue[newValue.length - 1];

            // If is a manually created
            if (entityAdded.manuallyCreated) {
                entityAdded = { label: entityAdded.input, category: 'Manual' };
            }

            // Add one..
            props.onEntityAdded(entityAdded);

            // // eslint-disable-next-line
            // console.log('Added entity', entityAdded);

            return true;
        }

        // If they remove an entity..
        if (newValue.length < props.value.length) {
            // Get the entity removed.
            const entityRemoved = props.value.find((a) => {
                const exists = newValue.find((b) => b.label === a.label);
                return !exists ? true : false;
            });

            // failed to find it.
            if (!entityRemoved) return false;

            // When they remove one
            props.onEntityRemoved(entityRemoved);

            // eslint-disable-next-line
            // console.log('removed entity', entityRemoved);
        }
    };

    // The default version
    const isOptionEqualToValue = (option, value) => {
        return option.label === value.label;
    };

    /**
     *
     * This is gonna be called by the Autocomplete API when we start searching for something
     */
    const filterOptions = (options, params) => {
        // Get the input value
        const { inputValue } = params;

        // Prepare the input..
        const filteredInput = inputValue.toLowerCase().trim();

        // Find matches
        const filtered = options.filter((option) => {
            const optionInput = option[`label`].toString().toLowerCase().trim();
            const isMatch = optionInput.includes(filteredInput);
            return isMatch ? true : false;
        });

        const exactMatch = options.find((option) => {
            const optionInput = option[`label`].toString().toLowerCase().trim();
            return optionInput === filteredInput;
        });

        // If there's no exact math for example when searching "test"
        if (inputValue !== '' && !exactMatch && !props.notCreatable) {
            const canCreate = props.validateCreation ? props.validateCreation(inputValue) : true;

            if (canCreate) {
                const newOption = {
                    manuallyCreated: true,
                    label: `Add "${inputValue}"`,
                    input: inputValue.replace(/\*/g, ''),
                    category: 'Custom',
                };
                filtered.push(newOption);
            }
        }

        return filtered;
    };

    const renderOption = (defaultProps, option) => {
        if (props.subjectsView) {
            return (
                <div
                    {...defaultProps}
                    className={`autocomplete-review-data-option ${defaultProps['aria-selected'] && 'selected'}`}
                    data-cy="autocomplete-options"
                >
                    <div className="label">{option[`${'label'}`]} </div>
                    {option.description && (
                        <div className="description">{option.description ? formatDescription(option.description) : ''}</div>
                    )}
                </div>
            );
        }

        return (
            <div {...defaultProps} data-cy="autocomplete-options">
                <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    <div style={{ flex: 2 }}>{option[`${'label'}`]} </div>
                    <div style={{ flex: 3, fontSize: 11 }}>{option.description || ''}</div>
                </div>
            </div>
        );
    };

    const getOptionLabel = (option) => option.label;

    return (
        <React.Fragment>
            <Autocomplete
                multiple={true}
                // This is used by the cypress tests
                data-cy={props.dataCy}
                // This must be disabled to avoid scenarios where they type in whatever they want and press enter.
                freeSolo={false}
                // Autocomplete general props changes
                selectOnFocus
                disabled={props.disabled || false}
                fullWidth={true}
                clearOnBlur
                handleHomeEndKeys
                disableClearable={true}
                disableCloseOnSelect={false}
                // The current value of the autocomplete
                value={props.value}
                // The options they can pick
                options={props.options}
                // The function used to know if an option is selected
                isOptionEqualToValue={props.isOptionEqualToValue || isOptionEqualToValue}
                // When the value data is changing..
                onChange={(_, value) => onValueChanged(value)}
                // Filtering the options the user can choose when they search for something
                filterOptions={filterOptions}
                // Rendering the option in front of the user when autocomplete is expanded..
                renderOption={renderOption}
                // Getting the labe value
                getOptionLabel={getOptionLabel}
                // Grouping by..
                groupBy={props.groupCategories ? (option) => option.category : undefined}
                // And finally we render the input!
                renderInput={(params) => <TextField placeholder={props.value.length < 1 ? props.placeholder : ''} {...params} />}
            />
        </React.Fragment>
    );
};

export default Component;
