const endpoints = {
    getAuditData: {
        link: '/graphql',
        method: 'POST',
        query: `query getAuditData ($companyId: String)   {
            GetAuditData(companyId: $companyId)
        }`,
        parserFunction: ({ data }) => data.GetAuditData,
    },
    updateAuditData: {
        link: '/graphql',
        method: 'POST',
        query: `mutation getAuditData ($companyId: String, $data: JSON)   {
            UpdateAuditData(companyId: $companyId, data: $data)
        }`,
        parserFunction: ({ data }) => data.UpdateAuditData,
    },
};

export default endpoints;
