import React from 'react';
import { SettingsContext } from '../../..';
import { FormHelperText } from '@mui/material';
import AutoComplete from '../../../../thirdParties/components/editPanel/components/autocomplete.js';
import { entries } from '../../../../gdprEssentials/steps/industry/index';

const Component = () => {
    const { data, updateData, loading, validationKeys } = SettingsContext();

    const getOptions = () => {
        let res = [...entries.map((c) => ({ label: c.label }))];
        return res;
    };

    const addEntry = (elm) => {
        updateData('industry', [...data.industry, elm.label]);
    };

    const removeEntry = (elm) => {
        updateData(
            'industry',
            data.industry.filter((c) => c !== elm.label),
        );
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="label">Industry</div>
                <AutoComplete
                    // The value and options
                    value={data.industry.map((c) => ({ label: c }))}
                    options={getOptions()}
                    // Props to the autocomplete..
                    groupCategories={false}
                    subjectsView={false}
                    placeholder={'Click here to select'}
                    // Callbacks when data is changed
                    onEntityAdded={(elm) => addEntry(elm)}
                    onEntityRemoved={(elm) => removeEntry(elm)}
                    // Making sure we cannot create sensitive elements
                    notCreatable={false}
                />

                {validationKeys[`industry`] && !loading && <FormHelperText error={true}>{validationKeys['industry']}</FormHelperText>}
            </div>
        </React.Fragment>
    );
};

export default Component;
