import React from 'react';
import { ModuleState } from '..';
import { MenuItem, Select, TextField } from '@mui/material';
import Tooltip from '../../../components/tooltip';

const Component = () => {
    const { data, setData, loading, submitted, dependencies } = ModuleState();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="form-label mb">Security Measure Name</div>
                <div className="form-component">
                    <TextField
                        value={data.name}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                        variant="outlined"
                        fullWidth={true}
                        placeholder="Name"
                        disabled={loading || submitted}
                    />
                </div>
            </div>
            <div className="form-group">
                <div className="form-label">Describe the security measure</div>
                <div className="form-description">
                    It will appear on your Security Measures policy as a bullet point under the correct category
                </div>
                <div className="form-component">
                    <TextField
                        value={data.description}
                        onChange={(e) => setData({ ...data, description: e.target.value })}
                        variant="outlined"
                        fullWidth={true}
                        placeholder="Description"
                        disabled={loading || submitted}
                        multiline={true}
                        rows={3}
                    />
                </div>
            </div>
            <div className="form-group">
                <div className="form-label">Section</div>
                <div className="form-description">
                    Choose the category you believe fits the best with the security measure you’re creating
                </div>
                <div className="form-component">
                    <Select
                        fullWidth={true}
                        value={data.section || 'none'}
                        onChange={(e) => setData({ ...data, section: e.target.value })}
                        disabled={loading || submitted}
                    >
                        {data.section === null && (
                            <MenuItem disabled={true} value={'none'}>
                                Select a section
                            </MenuItem>
                        )}
                        {dependencies.sections.map((entry, ix) => (
                            <MenuItem key={ix} value={entry}>
                                {entry}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="form-group">
                <div className="form-label">Category</div>
                <div className="form-description">
                    Choose the category you believe fits the best with the security measure you’re creating
                </div>
                <div className="form-component">
                    <div className="form-component">
                        <Select
                            fullWidth={true}
                            value={data.category || 'none'}
                            onChange={(e) => setData({ ...data, category: e.target.value })}
                            disabled={loading || submitted}
                        >
                            {data.category === null && (
                                <MenuItem disabled={true} value={'none'}>
                                    Select a category
                                </MenuItem>
                            )}
                            {dependencies.categories.map((entry, ix) => (
                                <MenuItem key={ix} value={entry}>
                                    {entry}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="form-label">
                    Information Security Category
                    <Tooltip content="The option you select here will be matched to a specific sub-article in GDPR Article 32, which helps us organize the information properly in your documents.">
                        <i className="component-tooltip-icon fa-solid fa-circle-info"></i>
                    </Tooltip>
                </div>
                <div className="form-description">Choose the Information Security category that this security measure relates to.</div>
                <div className="form-component">
                    <div className="form-component">
                        <Select
                            fullWidth={true}
                            value={data.gdprArt32 || 'none'}
                            onChange={(e) => setData({ ...data, gdprArt32: e.target.value })}
                            disabled={loading || submitted}
                        >
                            {data.gdprArt32 === null && (
                                <MenuItem disabled={true} value={'none'}>
                                    Select an option
                                </MenuItem>
                            )}
                            {dependencies.gdprArts.map((entry, ix) => (
                                <MenuItem key={ix} value={entry}>
                                    {entry}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
