import React from 'react';
import { ViewContext } from '..';

const Component = (props) => {
    const { options, setOptions } = ViewContext();

    return (
        <React.Fragment>
            <div
                onClick={() => setOptions({ ...options, type: props.data.value })}
                className={options.type === props.data.value ? `entry selected` : `entry`}
            >
                <div className="label">{props.data.label}</div>
                <i className={`icon ${props.data.icon}`}></i>
            </div>
        </React.Fragment>
    );
};

export default Component;
