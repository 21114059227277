import React, { useEffect, useState } from 'react';
import { Button, Checkbox, TextField } from '@mui/material';
import { getValidationPropFields, trackUserInteraction, validateAgainstSchema } from '../../../../../utils/helpers';
import { ModuleState } from '../../index';
import { welcomeValidationScheme } from '../../utils/validations';
import moment from 'moment';
import { ModuleAPI } from '../../components/api';

// Required for testing

window.testScanningBehavior = undefined;

const StepAudience = () => {
    const { data, updateData, setStep, scanning, isEditingSession } = ModuleState();
    const { startWebsiteScan, generateCompanyDescription } = ModuleAPI();

    const [validationResults, setValidationResults] = useState({});
    const [skipScanning, setSkipScanning] = useState(false);

    const scanAgain = () => {
        let timePast = data._meta.domainLastScannedAt;
        let timeSinceLastScanPassed = true;
        if (timePast !== null) {
            let minutes = moment().diff(moment(timePast), 'minutes');
            timeSinceLastScanPassed = minutes >= 1 ? true : false;
        }
        if (
            window.testScanningBehavior === 'force' ||
            (scanning == false &&
                timeSinceLastScanPassed === true &&
                process.env.REACT_APP_GEL_WEBSITE_SCAN == 'true' &&
                window.testScanningBehavior !== 'prevent')
        ) {
            if (skipScanning === true) return false;
            startWebsiteScan();
        }
    };

    const nextStep = async () => {
        await trackUserInteraction(`Selected "Next"`, { destination: `Audience` });
        setStep(`Audience`);

        // Scan again if needed..
        scanAgain();

        // Generate company description
        if (!data._meta.companyDescriptionGenerated && data.companyInfo.companyDescription.length < 1) {
            generateCompanyDescription();
        }
    };

    const validateFields = async () => {
        try {
            await validateAgainstSchema(welcomeValidationScheme, {
                companyDomains: data.companyInfo.companyDomains,
            });
            setValidationResults({});
        } catch (err) {
            setValidationResults(err);
        }
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    const AddAnotherWebsite = () => {
        trackUserInteraction(`Selected "Add another website"`, { reason: 'Adds another website to his company' });
        const websites = [...data.companyInfo.companyDomains];
        websites.push('');
        updateData(`companyInfo.companyDomains`, websites);
    };

    const RemoveWebsite = (index) => {
        trackUserInteraction(`Selected "Remove website"`, { reason: 'Removed one website from his list of domains' });
        const websites = [...data.companyInfo.companyDomains];
        websites.splice(index, 1);
        updateData(`companyInfo.companyDomains`, websites);
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    {/* <div className="back-button">Go back</div> */}
                    <div className="title" data-cy="step-title">
                        <div className="step-icon">
                            <i className="icon fa-solid fa-magnifying-glass"></i>
                        </div>
                        Setup your scanner
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={Object.keys(validationResults).length > 0}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
            <div className="welcome module-content">
                <div className="desc">
                    Which domains do you want us to scan to find your tools and third parties?
                    <br />
                    This can be your website, web app or subdomain.
                </div>
                <div className="component shared-component-domain-edits">
                    {data.companyInfo.companyDomains.map((_, index) => (
                        <div className="listed-domain-entry" key={index}>
                            <TextField
                                autoFocus
                                key={index}
                                fullWidth
                                variant="outlined"
                                placeholder="www.domain.com"
                                data-cy={index === 0 ? `input-website` : undefined}
                                className="input-website"
                                value={data.companyInfo.companyDomains[index]}
                                onChange={(e) => updateData(`companyInfo.companyDomains[${index}]`, e.target.value)}
                                {...getValidationPropFields(
                                    validationResults,
                                    data.companyInfo.companyDomains[index] && data.companyInfo.companyDomains[index].length > 0,
                                    `companyDomains[${index}]`,
                                )}
                            />
                            {data.companyInfo.companyDomains.length > 1 && (
                                <Button
                                    className="remove-domain-btn"
                                    variant="standard"
                                    color="primary"
                                    size="small"
                                    onClick={() => RemoveWebsite(index)}
                                >
                                    <i className="icon fa-solid fa-trash"></i>
                                </Button>
                            )}
                        </div>
                    ))}
                    <div className="footer-section">
                        <div className="left-side">
                            <Button variant="outlined" color="primary" size="small" onClick={AddAnotherWebsite}>
                                Add Another
                            </Button>
                        </div>
                        {isEditingSession && (
                            <div className="right-side">
                                <div className="checkbox-container">
                                    <Checkbox size="small" value={skipScanning} onClick={() => setSkipScanning(!skipScanning)} />
                                    <div className="label">
                                        Don't re-scan my {data.companyDomains && data.companyDomains.length > 1 ? 'domains' : 'domain'}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default StepAudience;
