import { MenuItem, Select } from '@mui/material';

import React from 'react';
import { EntryState } from '../entry';

const Component = () => {
    const { data, isSecondary, setData } = EntryState();

    const typeLabels = {
        primary: 'WHERE',
        or: 'Or',
        and: 'And',
    };

    return (
        <React.Fragment>
            <div className="field type">
                {data.type === 'primary' || (data.type !== 'primary' && !isSecondary) ? (
                    <React.Fragment>
                        <div className="text">{typeLabels[data.type]}</div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Select value={data.type} fullWidth={true} onChange={(ev) => setData('type', ev.target.value)}>
                            <MenuItem value="and">And</MenuItem>
                            <MenuItem value="or">Or</MenuItem>
                        </Select>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};
export default Component;
