import React, { useState, useEffect, createContext, useContext } from 'react';

// Components
import IsChildren from './components/isChildren';

// Validation
import { PanelContext } from '../../';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const [validationResults, setValidationResults] = useState({});

    // Context
    const { data, validateView } = PanelContext();

    const validateFields = async () => {
        const { results } = await validateView('children');
        setValidationResults(results);
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    const PassedProps = {
        validationResults,
        setValidationResults,
    };

    return (
        <Context.Provider value={PassedProps}>
            <div className="component-forms">
                <IsChildren />
            </div>
        </Context.Provider>
    );
};

export default Component;
