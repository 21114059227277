import React from 'react';

// Components
import Label from './components/label';

import Processes from './components/processes';
import SecurityMeasuresURL from './components/securityMeasuresURL';
import DataResidency from './components/dataResidency';
import Website from './components/website';

// External components
import { Button } from '@mui/material';

const Component = (props) => {
    return (
        <React.Fragment>
            <div className="component-view main">
                <Label {...props} />
                <Processes {...props} />
                <Website {...props} />
                <SecurityMeasuresURL {...props} />
                <DataResidency {...props} />
            </div>
            <div className="buttons-footer" style={{ marginTop: 24 }}>
                <Button data-cy="tool-delete-button" variant="outlined" color="primary" onClick={props.onDelete}>
                    Remove Sub-processor
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Component;
