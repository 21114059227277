import React from 'react';

// Components
import { Fab, Menu, Checkbox } from '@mui/material';
import { ModuleState } from '../../..';

const SettingsMenu = () => {
    const { data, options, setOptions } = ModuleState();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isModalOpen = anchorEl !== null;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Fab size="small" className="options-btn" color="primary" onClick={handleClick}>
                <i className="icon fa-regular fa-cog"></i>
            </Fab>
            <Menu className="menu-options-third-parties" anchorEl={anchorEl} open={isModalOpen} onClose={handleClose}>
                <div className="title">Settings</div>
                <div className="content">
                    <div className="entry">
                        <div className="checkbox">
                            <Checkbox
                                checked={options.showArchived}
                                onClick={() =>
                                    setOptions((currentState) => ({ ...currentState, showArchived: !currentState.showArchived }))
                                }
                            />
                        </div>
                        <div className="label">Show Archived tools ({data.storageLocations.filter((c) => c.archived === true).length})</div>
                    </div>
                </div>
            </Menu>
        </React.Fragment>
    );
};

export default SettingsMenu;
