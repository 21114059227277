import React from 'react';

// Components
import PickTool from '../../reviewTools/components/pickTool';
import { Button } from '@mui/material';
import Badge from './storageLocations/components/badge';
import { OnboardingContext } from '../../..';

const Component = () => {
    const { scanner } = OnboardingContext();

    return (
        <React.Fragment>
            <div className="component-storage-locations-buttons">
                <PickTool
                    renderCallToAction={({ setPickTools }) => (
                        <Button variant="contained" color="primary" onClick={() => setPickTools(true)}>
                            Add a tool or third party
                        </Button>
                    )}
                />
                {scanner.results.length > 0 && (
                    <div className="right-side">
                        <Badge color="#f79a43" isFooterBadge={true} />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
