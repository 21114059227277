const endpoints = {
    GetListDPAs: {
        link: '/graphql',
        method: 'POST',
        query: `
    query GetListDPAs {
        GetListDPAs
    }`,
        parserFunction: ({ data }) => data.GetListDPAs,
    },
    GetSubProcessors: {
        link: '/graphql',
        method: 'POST',
        query: `
query GetSubProcessors {
  GetSubProcessors
}`,
        parserFunction: ({ data }) => data.GetSubProcessors,
    },

    IsNewSubProcessors: {
        link: '/graphql',
        method: 'POST',
        query: `
        query IsNewSubProcessors($input: [String]) {
          IsNewSubProcessors(input: $input)
        }`,
        parserFunction: ({ data }) => data.IsNewSubProcessors,
    },

    CreateDPA: {
        link: '/graphql',
        method: 'POST',
        query: `
          mutation CreateDPA($name: String, $_cloneId: String)
          {
            CreateDPA(name: $name, _cloneId: $_cloneId )
          }
      `,
        parserFunction: ({ data }) => data.CreateDPA,
    },
    GetDPA: {
        link: '/graphql',
        method: 'POST',
        query: `
        query GetDPA($_id: String) {
            GetDPA(_id: $_id)
        }`,
        parserFunction: ({ data }) => data.GetDPA,
    },
    UpdateDPADraftData: {
        link: '/graphql',
        method: 'POST',
        query: `
    mutation UpdateDPADraftData($_id: String, $payload: JSON)
    {
      UpdateDPADraftData(_id: $_id, payload: $payload)
    }
`,
        parserFunction: ({ data }) => data.UpdateDPADraftData,
    },
    AddProcessToDPA: {
        link: '/graphql',
        method: 'POST',
        query: `
          mutation AddProcessToDPA($_ids: [String], $locationLabel: String, $processes: [String])
          {
            AddProcessToDPA(_ids: $_ids, locationLabel: $locationLabel, processes: $processes)
          }
        `,
        parserFunction: ({ data }) => data.AddProcessToDPA,
    },
    EditDPA: {
        link: '/graphql',
        method: 'POST',
        query: `
    mutation EditDPA($_id: String, $payload: JSON)
    {
      EditDPA(_id: $_id, payload: $payload)
    }
`,
        parserFunction: ({ data }) => data.EditDPA,
    },
    SubmitDPA: {
        link: '/graphql',
        method: 'POST',
        query: `
          mutation SubmitDPA($_id: String, $payload: JSON)
          {
            SubmitDPA(_id: $_id, payload: $payload)
          }
      `,
        parserFunction: ({ data }) => data.SubmitDPA,
    },
    DeleteDPA: {
        link: '/graphql',
        method: 'POST',
        query: `
        mutation DeleteDPA($_id: String)
        {
          DeleteDPA(_id: $_id)
        }
    `,
        parserFunction: ({ data }) => data.DeleteDPA,
    },
};

export default endpoints;
