import React from 'react';
import { ComponentState } from '../../..';
import { Button } from '@mui/material';

// Components
import LanguagesAvailable from './components/languagesAvailable';
import DefaultLanguage from './components/defaultLanguage';
import Switches from './components/switches';

const Page = () => {
    const { saveChanges, saveDisabled } = ComponentState();

    return (
        <React.Fragment>
            <LanguagesAvailable />
            <DefaultLanguage />
            <Switches />
            <div className="page-footer">
                <Button variant="contained" color="primary" onClick={saveChanges} disabled={saveDisabled}>
                    Save changes
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Page;
