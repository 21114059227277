import React, { useEffect } from 'react';

// Components
import { Button, TextField } from '@mui/material';
import { ComponentState } from '../..';
import { logError, trackUserInteraction } from '../../../../../utils/helpers';
import AutoComplete from '../../../thirdParties/components/editPanel/components/autocomplete';

const Component = () => {
    const { draftData, vitalRecommendations, sessionData, dataInventory, updateDraftData, setRoute } = ComponentState();

    const isNextDisabled = () => {
        if (draftData.services.length < 1 || draftData.serviceDescription.length < 1 || draftData.termsOfServiceURL.length < 1) return true;
        return false;
    };

    const goBack = () => {
        setRoute('start');

        trackUserInteraction(`Selected "Go Back"`);
    };

    const goNext = () => {
        setRoute('map-services');
        trackUserInteraction(`Selected "Next"`, {
            destination: 'Map Services',
        });
    };

    const setDefaultValues = async () => {
        try {
            // Fill it with company description from session
            if (draftData.serviceDescription.length < 1 && sessionData.companyInfo.companyDescription.length > 0) {
                updateDraftData('serviceDescription', sessionData.companyInfo.companyDescription || '');
            }
            // Fill it with company services
            if (draftData.services.length < 1 && sessionData.companyInfo.services.length > 0) {
                updateDraftData('services', sessionData.companyInfo.services);
            }
        } catch (err) {
            await logError(`dpa.describeService.setDefaultValues`, err);
        }
    };

    const getProcessesRecommendations = () => {
        try {
            let processes = [];

            // We load recommendations that are in our data inventory.
            vitalRecommendations.processes.forEach((procRecommended) => {
                // Is it in my data inventory?
                if (!dataInventory.processes.find((c) => c.label === procRecommended.label)) return false;

                processes.push({
                    label: procRecommended.label,
                    category: procRecommended.category,
                    origin: 'recommendations',
                });
            });

            // Now let's do custom ones too.
            dataInventory.processes.forEach((proc) => {
                // Is is in the array already
                if (processes.find((c) => c.label === proc.label)) return false;

                // Add it to custom.
                processes.push({
                    label: proc.label,
                    category: 'Custom',
                    origin: 'recommendations',
                });
            });

            return processes;
        } catch (err) {
            return [];
        }
    };

    const addServiceEntry = (entry) => {
        let newEntry = {
            label: entry.label,
            origin: 'manual', // Even though they pick them from our options. It's still "manual" (as in manually added by the user)
        };

        updateDraftData('services', [...draftData.services, newEntry]);
    };

    const removeServiceEntry = (entry) => {
        let newServices = [...draftData.services].filter((c) => c.label !== entry.label);
        updateDraftData('services', newServices);
    };

    useEffect(() => {
        // Set default values
        setDefaultValues();

        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className="route-header">
                <div className="left-side">
                    <div className="button-left">
                        <Button variant="text" onClick={goBack}>
                            Go Back
                        </Button>
                    </div>
                    <div className="title">Describe the service that you offer to your clients </div>
                </div>
                <div className="button-right">
                    <Button variant="contained" color="primary" disabled={isNextDisabled()} onClick={goNext}>
                        Next
                    </Button>
                </div>
            </div>
            <div className="route-content describe-service">
                <div className="tooltip-block mb">
                    <p>
                        <img src="/assets/images/icons/information-icon.png" className="info-icon" /> Your Terms & Conditions or Master
                        Services Agreement will normally explain the services you provide.
                    </p>
                    <p style={{ paddingTop: 6 }}>Examples of services include:</p>
                    <ul>
                        <li>Hubspot provides Customer Relationship Management Services</li>
                        <li>Intercom provides a live chat and support inbox platform</li>
                        <li>Figma provides an interactive prototyping and graphic design application.</li>
                    </ul>
                </div>
                <div className="form-group">
                    <div className="form-label">Service Description</div>
                    <div className="form-description">Explain the services that you offer in one or two sentences.</div>

                    <div className="form-content">
                        <TextField
                            value={draftData.serviceDescription}
                            onChange={(e) => updateDraftData('serviceDescription', e.target.value)}
                            multiline={true}
                            variant="outlined"
                            fullWidth={true}
                            rows={4}
                            placeholder="Complete the sentence: Our customers use our services/platform/tool to..."
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-label">Service Name</div>
                    <div className="form-description">Complete the sentence. Our company provides xyz services/platform.</div>
                    <div className="form-content">
                        <AutoComplete
                            // The value and options
                            value={draftData.services}
                            options={getProcessesRecommendations()}
                            // Props to the autocomplete..
                            groupCategories={true}
                            subjectsView={false}
                            placeholder={'Please enter your text here and then press Enter.'}
                            // Callbacks when data is changed
                            onEntityAdded={(elm) => addServiceEntry(elm)}
                            onEntityRemoved={(elm) => removeServiceEntry(elm)}
                            notCreatable={false}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-label">Terms of Service URL</div>
                    <div className="form-description">Copy and paste your terms of service URL</div>
                    <div className="form-content">
                        <TextField
                            value={draftData.termsOfServiceURL}
                            onChange={(e) => updateDraftData('termsOfServiceURL', e.target.value)}
                            variant="outlined"
                            fullWidth={true}
                            placeholder="https://www.your-company.com/terms-of-service"
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
