import React, { useEffect, useState } from 'react';
import { AuthStore } from '../../../utils/context/authStore';
import { logError, trackUserInteraction, useWindowSize } from '../../../utils/helpers';
import PageContainer from '../../layout/container';
import { Button } from '@mui/material';
import { makeEndpointRequest } from '../../../utils/endpoints';

const Verify = (props) => {
    const { account } = AuthStore();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const companyId = props.match.params.company_id;
    const [screenWidth] = useWindowSize();

    const loadData = async (reload = false) => {
        try {
            setError(false);
            setLoading(true);
            const companyInfo = await { ...(await makeEndpointRequest('GetCompanyInfo', { companyId })) }.data.GetCompanyInfo;
            setData(companyInfo);
            setError(false);
            setLoading(false);
            if (reload === false && account) {
                await trackUserInteraction('Verify Company', { companyId });
            }
        } catch (err) {
            setError(true);
            await logError('VERIFY_COMPANY', err);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    const seoTitle = error ? `Failed to verify` : `Verify - ${data && !loading ? data.companyLegalName : `Loading..`}`;

    const tryAgain = async () => {
        if (account) {
            await trackUserInteraction(`Selected "Try again"`);
        }
        loadData();
    };

    const contactUs = async () => {
        if (account) {
            await trackUserInteraction(`Selected "Contact us"`, { reason: 'Failure to verify company' });
        }
        window.location.href = `mailto:support@privasee.io?subject=Failure to verify company`;
    };

    const redirectPrivacyPortal = async () => {
        if (account) {
            await trackUserInteraction(`Selected "Privacy Portal"`);
        }
        window.location.href = `${window.location.origin}/privacy-portal/${companyId}`;
    };

    const redirectWebsite = async () => {
        if (account) {
            await trackUserInteraction(`Selected "Website"`);
        }
        let url = data.companyDomains[0];

        if (url.includes('http') === false) {
            url = `https://${url}`;
        }

        window.location.href = url;
    };

    let PageContent = null;

    if (error) {
        PageContent = (
            <React.Fragment>
                <div className="elm-container" key="failure">
                    <i className="icon fa-solid fa-triangle-exclamation"></i>
                    <div className="heading color-gold">Technical difficulties</div>
                    <div className="msg">
                        <span className="text">We failed to get the information from the server.</span>
                    </div>
                    <div className="buttons">
                        <Button onClick={tryAgain} size={screenWidth < 1200 ? 'medium' : 'small'} variant="contained" color="primary">
                            Try again
                        </Button>
                        <Button onClick={contactUs} size={screenWidth < 1200 ? 'medium' : 'small'} variant="outlined" color="primary">
                            Contact us
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    } else if (data && !error && !loading) {
        PageContent = (
            <React.Fragment>
                <div className="elm-container" key="failure">
                    <i className="icon fa-solid fa-shield-check"></i>
                    <div className="heading color-gold">{data.companyLegalName}</div>
                    <div className="msg">
                        <span className="text">This merchant is using</span>{' '}
                        <a href="https://privasee.co.uk" target="_blank" className="own-link">
                            Privasee
                        </a>{' '}
                        <span className="text">to maintain rigorous data protection standards. This ensures that your data is safe.</span>
                    </div>
                    <div className="buttons">
                        <Button
                            size={screenWidth < 1200 ? 'medium' : 'small'}
                            onClick={redirectPrivacyPortal}
                            variant="contained"
                            color="primary"
                        >
                            Privacy Portal
                        </Button>
                        <Button
                            onClick={redirectWebsite}
                            disabled={!data.companyDomains[0]}
                            size={screenWidth < 1200 ? 'medium' : 'small'}
                            variant="outlined"
                            color="primary"
                        >
                            Website
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        PageContent = (
            <div className="elm-container" key="loading">
                <i className="icon fa-solid fa-cog fa-spin"></i>
                <div className="heading color-gold">Verifying...</div>
                <div className="msg">Loading information about this company</div>
            </div>
        );
    }
    return (
        <PageContainer guestLayout={true} title={seoTitle} classNames="page-verify">
            <div className="page-content">{PageContent}</div>
        </PageContainer>
    );
};

export default Verify;
