import React from 'react';

// Components
import TableComponent from './components/table';

// Context
import { PageContext } from '../../../..';

const Component = () => {
    const { isFilteredByProcess, data } = PageContext();

    const getDataFormatted = (mustHaveProcess = null) => {
        let arr = [...data.storageLocations];

        if (mustHaveProcess) {
            arr = arr.filter((c) => {
                const matchProcess = c.processes.find((c) => c.label === mustHaveProcess);
                if (!matchProcess) return false;

                return true;
            });
        }

        // eslint-disable-next-line
        const formattedData = arr.map((c) => ({
            _id: c._id,
            label: c.label,
            dataResidency: c.dataResidency,
            elements: c.elements,
            securityMeasures: c.securityMeasures,
        }));

        formattedData.sort((a, b) => a.label.localeCompare(b.label));

        return formattedData;
    };

    const getProcesses = () => {
        let processes = new Set();

        data.storageLocations.forEach((tool) => {
            tool.processes.forEach((process) => {
                processes.add(process.label);
            });
        });

        return Array.from(processes).sort();
    };

    return (
        <React.Fragment>
            {isFilteredByProcess ? (
                <React.Fragment>
                    {getProcesses().map((processName, ix) => (
                        <TableComponent
                            key={ix}
                            purpose={processName}
                            columns={['Geography', 'Sensitive info?', 'In EEA?', 'Rest Security?', 'Transit Security?']}
                            entries={getDataFormatted(processName)}
                        />
                    ))}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <TableComponent
                        purpose={''}
                        columns={['Geography', 'Sensitive info?', 'In EEA?', 'Rest Security?', 'Transit Security?']}
                        entries={getDataFormatted()}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Component;
