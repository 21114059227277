import React, { createContext, useContext, useState } from 'react';

import { Button } from '@mui/material';

// Components
import Entry from './components/entry';

// Dependencies
import { getIndividualsTypes } from './components/functions';

// Context
import { ModuleState } from '../..';
import { logError, trackUserInteraction } from '../../../../../utils/helpers';
import { ModuleAPI } from '../../components/api';

const Context = createContext({});
export const StepContext = () => useContext(Context);

const Component = () => {
    const { data, setStep, updateData } = ModuleState();
    const [entries, setEntries] = useState([...data.companyInfo.individualsReferring]);
    const { deleteIndividualDependencies, addMissingReferringRolesToStorageLocations } = ModuleAPI();

    const goBack = async () => {
        setStep('Services');
        await trackUserInteraction(`Selected "Go Back"`, { destination: `Services` });
    };

    const nextStep = async (skipWarningDeletion = false) => {
        // We check if they removed anyone..
        if (skipWarningDeletion === false) {
            // Get removed individuals
            const removedIndividuals = getRemovedIndividuals();

            if (removedIndividuals.length > 0) {
                warnAboutDeletion();
                return false;
            }
        }
        // Get added individuals
        const addedIndividuals = getAddedIndividuals();

        // If there's any new individuals added let's apply recommendations.
        if (addedIndividuals.length > 0) {
            addMissingReferringRolesToStorageLocations({
                individualsReferences: addedIndividuals,
            });
        }

        // Update data
        updateData(`companyInfo.individualsReferring`, entries);

        // Set next step
        setStep('IdentifyElements');
        await trackUserInteraction(`Selected "Go Next"`, { destination: `IdentifyElements` });
    };

    const getAddedIndividuals = () => {
        try {
            // Get current array again
            const currentIndividuals = [...data.companyInfo.individualsReferring];

            // Get the added individuals
            const addedIndividuals = entries.filter((c) => {
                // Exists in the current array of entries?
                const exists = currentIndividuals.find((g) => g.label == c.label);
                if (!exists) return true;

                return false;
            });

            return addedIndividuals;
        } catch (err) {
            logError(`gel.individuals.getAddedIndividuals`, err);
            return [];
        }
    };

    const getRemovedIndividuals = () => {
        try {
            // Get current array again
            const currentIndividuals = [...data.companyInfo.individualsReferring];

            // Get the removed individuals
            const removedIndividuals = currentIndividuals.filter((c) => {
                // Is it used anywhere in the data inventory?
                const isIndividualUsed = data.storageLocations.find((storage) => {
                    let usesIndividual = storage.individuals.find((gg) => gg.label === c.label);
                    if (usesIndividual) return true;
                    return false;
                });

                // Is not used so we don't show warning.
                if (!isIndividualUsed) return false;

                // Is individual used?
                // Exists in the current array of entries?
                const exists = entries.find((g) => g.label == c.label);
                if (!exists) return true;

                return false;
            });

            return removedIndividuals;
        } catch (err) {
            logError(`gel.individuals.getRemovedIndividuals`, err);
            return [];
        }
    };

    const warnAboutDeletion = async () => {
        try {
            // Get individuals
            const removedIndividuals = getRemovedIndividuals();

            window.showAlert(
                'Confirmation',
                <React.Fragment>
                    <p>
                        Are you sure about removing the following individuals? If you go ahead any reference to them will be deleted from
                        the data inventory.
                    </p>
                    <p>
                        <b>Individuals deleted:</b>
                    </p>
                    <ul style={{ textAlign: 'left' }}>
                        {removedIndividuals.map((c, index) => (
                            <li key={index}>{c.label}</li>
                        ))}
                    </ul>
                </React.Fragment>,
                'warning',
                [
                    {
                        text: 'Cancel',
                        dataCy: `alert-cancel-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                    {
                        text: 'Confirm',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            // Dismiss...
                            dismissAlert();

                            // Delete them
                            removedIndividuals.forEach((p) => deleteIndividualDependencies(p.label));

                            // Go next
                            nextStep(true);
                        },
                    },
                ],
            );
        } catch (err) {
            await logError(`gel.individuals.warnAboutDeletion`, err);
        }
    };

    const ContextProps = {
        entries,
        setEntries,
    };

    return (
        <React.Fragment>
            <Context.Provider value={ContextProps}>
                <div className="module-subheader">
                    <div className="left-side">
                        <div className="back-button" data-cy="back-button" onClick={goBack}>
                            Go back
                        </div>
                        <div className="title" data-cy="step-title">
                            Individuals
                        </div>
                        <div className="subtitle">The people who’s data you collect and process</div>
                    </div>
                    <div className="right-side">
                        <Button variant="contained" color="primary" onClick={() => nextStep()} data-cy="next-button">
                            Next
                        </Button>
                    </div>
                </div>
                <div className="module-content individuals">
                    <div className="categories">
                        {getIndividualsTypes().map((type, ix) => (
                            <Entry key={ix} data={type} />
                        ))}
                    </div>
                </div>
            </Context.Provider>
        </React.Fragment>
    );
};

export default Component;
