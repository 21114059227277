import { useEffect, useRef } from 'react';
import { ComponentState } from '../index';

const Component = () => {
    const { changesDetected } = ComponentState();
    // ref needed
    const changesDetectedRef = useRef(changesDetected);

    useEffect(() => {
        changesDetectedRef.current = changesDetected;
        // eslint-disable-next-line
    }, [changesDetected]);

    const preventUserFromLeaving = e => {
        if (changesDetectedRef.current === false) return false;
        var confirmationMessage = 'If you leave before saving, your changes will be lost.';
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    };

    useEffect(() => {
        window.addEventListener('beforeunload', preventUserFromLeaving);
        return () => {
            window.removeEventListener('beforeunload', preventUserFromLeaving);
        };
        // eslint-disable-next-line
    }, []);

    return null;
};

export default Component;
