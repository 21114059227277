import React from 'react';
import { Select, MenuItem } from '@mui/material';

const Component = (props) => {
    return (
        <React.Fragment>
            <div className="subheader">
                <div className="page-label">
                    <span>For the purpose of</span>
                    <Select className="select" style={{ margin: 'auto 10px' }} label="Select" value={props.currentProcess}>
                        {props.processes.map((process, menuIndex) => (
                            <MenuItem key={`menuIndex-${menuIndex}`} onClick={() => props.setProcess(process)} value={process}>
                                {process}
                            </MenuItem>
                        ))}
                    </Select>
                    <span>we use</span>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
