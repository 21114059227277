import * as Yup from 'yup';

export const gelFlowDestinationSharingSchema = Yup.object({
    name: Yup.string().required('This field is required.').trim().label('This'),
    type: Yup.string().trim().label('This').required('This field is required.'),
    country: Yup.string().trim().label('This').required('This field is required.'),
});

export const gelFlowEditModalSchema = Yup.object({
    label: Yup.string().required('This field is required.').trim().label('This'),
    securityMeasures: Yup.string().trim().label('This').required('This field is required.'),
    dataResidency: Yup.string().trim().label('This').required('This field is required.'),
});

export const gelFlowEditModalSubProcessorSchema = Yup.object({
    subProcessorMeasuresURL: Yup.string()
        .test('isEmailOrWebsite', 'Must be a valid URL or email', (value) => {
            if (value && value.includes('@')) return Yup.string().required().email().isValidSync(value);
            return websiteValidationScheme.isValidSync(value);
        })
        .label('This')
        .required('This field is required.'),
});

export const gelFlowCreateToolSchema = Yup.object({
    label: Yup.string().required('This field is required.').trim().label('This'),
});

String.prototype.count = function (s1) {
    return (this.length - this.replace(new RegExp(s1, 'g'), '').length) / s1.length;
};

export function isValidHttpUrl(value) {
    // Includs spaces?
    if (value.includes(` `)) return false;

    // // Has more than one : - again weird but happens.
    if (value.count(':') > 1) return false;

    // Check has an ending domain.
    if (value.count('.') < 2) return false;

    // Check if there's a domain extension
    const str1 = value.split('.');
    const hasExtension = str1[str1.length - 1] === '' ? false : true;
    if (!hasExtension) return false;
    return true;
}

export const websiteRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const websiteValidationScheme = Yup.string()
    .trim()
    // First line of defense
    .test('url-check', 'This url is not valid. Example of valid url: https://www.privasee.io', (value) => {
        return isValidHttpUrl(value) ? true : false;
    })
    // Second
    .matches(websiteRegex, 'This url is not valid. Example of valid url: https://www.privasee.io')
    .label('This')
    .required('This field is required.');

export const welcomeValidationScheme = Yup.object({
    companyDomains: Yup.array().of(websiteValidationScheme).min(1),
});
export const certificateSubmitSchema = Yup.object({
    inquiriesEmail: Yup.string().required('This field is required.').trim().label('This'),
    dpoName: Yup.string().label('This').trim(),
    companyLegalName: Yup.string().trim().label('This').required('This field is required.'),
    companyDomains: Yup.array().of(websiteValidationScheme).min(1),
    companyAddress: Yup.object({
        line1: Yup.string().trim().label('This').required('This field is required.'),
        line2: Yup.string().trim().label('This'),
        city: Yup.string().trim().label('This').required('This field is required.'),
        postCode: Yup.string().trim().label('This').required('This field is required.'),
        country: Yup.string().trim().label('This').required('This field is required.'),
    }),
});

export const dpaCreateCustomSchema = Yup.object({
    label: Yup.string().required('This field is required.').trim().label('This'),
});

export const workspaceV2InviteUser = Yup.object({
    email: Yup.string().required('This field is required.').email().trim().label('This'),
});
