import React, { useContext, useEffect, createContext, useState } from 'react';
import lodash from 'lodash';

// Dependencies
import { useHistory } from 'react-router-dom';
import { logError, trackUserInteraction } from '../../../utils/helpers';
import { AuthStore } from '../../../utils/context/authStore';
import { makeEndpointRequest, makeLocalEndpointRequest } from '../../../utils/endpoints';

// Components
import Container from '../../layout/container';
import Header from './layout/header';
import Controls from './layout/controls';
import LoadingContainer from './layout/loading';
import Entry from './components/entry';
import { applyFilters } from '../../components/dataControls/filters/utils/functions';
import { getFilterFields } from './utils/functions';

// Context
const Context = createContext({});
export const ModuleState = () => useContext(Context);

let timerUpdate = null;

const Component = () => {
    const { globalFlags } = AuthStore();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [responses, setResponses] = useState([]);
    const [dependencies, setDependencies] = useState({ sections: [], categories: [] });
    const [dataControls, setDataControls] = useState({
        search: '',
        filters: [],
        groupedBy: '',
    });

    const loadData = async () => {
        try {
            setLoading(true);

            // Fetching them..
            const res = await makeEndpointRequest('GetSecurityMeasures', { filteredByCompanySize: false });
            const responses = await makeEndpointRequest('GetSecurityMeasuresResponses');
            setData(res);
            setResponses(responses);

            // Get dependencies
            const sections = [],
                categories = [];

            res.forEach((entry) => {
                if (!sections.includes(entry.details.section)) {
                    sections.push(entry.details.section);
                }

                if (!categories.includes(entry.details.category)) {
                    categories.push(entry.details.category);
                }
            });

            setDependencies({ sections, categories });

            setLoading(false);
        } catch (err) {
            await logError(`LOAD_SECURITY_MEASURES`, err);
        }
    };

    const updateSecurityMeasureResponse = (_id, source, key, value) => {
        const updatedResponses = [...responses];

        const index = updatedResponses.findIndex((e) => e._id === _id && e.source === source);

        if (index !== -1) {
            updatedResponses[index][key] = value;
        } else {
            let newResponse = {
                _id,
                source,
                value: false,
                adjustedDescription: '',
                ...{ [key]: value },
            };

            updatedResponses.push(newResponse);
        }

        setResponses([...updatedResponses]);

        // Call the API..
        if (timerUpdate) clearTimeout(timerUpdate);

        timerUpdate = setTimeout(async () => {
            try {
                await makeEndpointRequest('UpdateSecurityMeasuresResponses', { payload: updatedResponses });
            } catch (err) {
                await logError(`UPDATE_SECURITY_MEASURE_RESPONSES`, err);
            }
        }, 500);
    };

    const getFilteredData = () => {
        let arr = [...data];

        // If they have the search filter
        if (dataControls.search.length > 0) {
            const search = dataControls.search.toString().toLowerCase();
            arr = arr.filter((e) => e.details.label.toString().toLowerCase().includes(search));
        }

        // If they have filters
        if (dataControls.filters.length > 0) {
            arr = applyFilters(arr, dataControls.filters, getFilterFields(dependencies));
        }

        if (dataControls.groupedBy.length > 0) {
            let groupedArr = {};

            arr.forEach((elm) => {
                const groupingLabel = lodash.get(elm, dataControls.groupedBy);

                if (groupedArr[groupingLabel] === undefined) {
                    groupedArr[groupingLabel] = [];
                }

                groupedArr[groupingLabel].push(elm);
            });

            return groupedArr;
        }
        return arr;
    };
    useEffect(() => {
        // Loading the data
        loadData();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // IF they don't have access to this Module yet.
        if (globalFlags.securityMeasures !== null && !globalFlags.securityMeasures) {
            history.push('/');
            return false;
        }
        // eslint-disable-next-line
    }, [globalFlags]);

    const downloadSecurityMeasuresDocument = async () => {
        try {
            // This checks if they have any positive ones within our list. We filter out the "previously deleted" recommendations just in case.
            const hasPositiveRespones = responses.filter((e) => e.value === true && data.find((d) => `${d._id}` === e._id));
            if (hasPositiveRespones.length < 1) {
                return window.showAlert(
                    'Select one security measure',
                    `To generate your Security Measures policy please select at least one Security Measures that you have at your company.`,
                    'info',
                );
            }

            await trackUserInteraction(`Selected "Download Security Measures document"`);

            // download it..
            const res = await makeLocalEndpointRequest(`/api/documents/securityMeasures`, {}, 'GET', null, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Security measures.docx'); // set the file name
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            await logError(`DOWNLOAD_SECURITY_MEASURES`, err);
            trackUserInteraction(`Having Difficulties`, { reason: `failed to download security measures` });
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    const ContextProps = {
        loading,
        responses,
        setResponses,
        updateSecurityMeasureResponse,
        dataControls,
        setDataControls,
        dependencies,
        setDependencies,
        downloadSecurityMeasuresDocument,
    };

    const checkEntriesExisting = () => {
        if (dataControls.groupedBy.length > 0) {
            return Object.values(getFilteredData()).length > 0 ? true : false;
        } else return getFilteredData().length > 0 ? true : false;
    };

    return (
        <Container title="Security Measures" classNames="page-security-measures page-security-measure-shared" isAlternateBackground={true}>
            <Context.Provider value={ContextProps}>
                <LoadingContainer loading={loading}>
                    <Header />
                    <Controls />
                    <div className="page-content">
                        {!checkEntriesExisting() && (
                            <div className="no-entries">
                                <div className="title">No records</div>
                                <div className="description">Get started by pressing the "NEW" button.</div>
                            </div>
                        )}
                        <div className="entries">
                            {dataControls.groupedBy ? (
                                <React.Fragment>
                                    {Object.keys(getFilteredData()).map((groupKey) => (
                                        <React.Fragment key={groupKey}>
                                            <div className="group-heading">{groupKey}</div>
                                            {getFilteredData()[groupKey].map((entry, ix) => (
                                                <Entry data={entry} key={ix} />
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {getFilteredData().map((entry, ix) => (
                                        <Entry data={entry} key={ix} />
                                    ))}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </LoadingContainer>
            </Context.Provider>
        </Container>
    );
};

export default Component;
