import React from 'react';
import { ComponentState } from '..';
import { trackUserInteraction } from '../../../../utils/helpers';
import { useHistory } from 'react-router-dom';

// Components
import Container from '../../../layout/container';
import Tabs, { tabsWithoutPreview } from './tabs';
import LoadingPlaceholder from './loadingPlaceholder';
import PreviewPanel from '../pages/settings/components/previewPanel';
import WarnBeforeLeaving from './warnBeforeLeaving';
import RightSide from './rightSide';

const Component = ({ children }) => {
    const { tab, loading, error, data, setTab, changesDetected, setChangesDetected } = ComponentState();
    const history = useHistory();

    const isChildrenReady = error === false && data !== null ? true : false;

    const redirectGeneralSettings = async () => {
        trackUserInteraction(`Selected "Looking for General Settings Settings"`);
        history.push(`/settings`);
    };

    const updateTab = async (func, tabNew) => {
        if (changesDetected) {
            await trackUserInteraction(`Warning of unsaved data`);
            window.showAlert(
                'Confirmation',
                `Are you sure you want to leave this section? You have unsaved data! Press the save button at the bottom of this page to save it now.`,
                'warning',
                [
                    {
                        text: 'Leave',
                        dataCy: `alert-submit-button`,

                        onClick: async ({ dismissAlert }) => {
                            setTab(tabNew);
                            setChangesDetected(false);
                            dismissAlert();
                            await trackUserInteraction(`Selected "Leave"`);
                        },
                    },

                    {
                        text: `Stay`,
                        dataCy: `alert-cancel-button`,

                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            await trackUserInteraction(`Selected "Stay"`);
                        },
                    },
                ],
            );
        } else {
            func(tabNew);
        }
    };

    return (
        <React.Fragment>
            <Container title="Privacy Portal - Settings" classNames="page-privacy-portal-settings" isAlternateBackground={true}>
                <div data-cy="page-title" className="page-title">
                    Privacy Portal
                </div>
                <div className="page-content">
                    <Tabs loading={loading} value={tab} setValue={(val) => updateTab(setTab, val)} />
                    <div className="page-subcontent">
                        <div className="page-alignment">
                            <div className="page-alignment-left-side">{isChildrenReady ? children : <LoadingPlaceholder />}</div>
                            {!tabsWithoutPreview.includes(tab) && !loading && !error && <RightSide />}
                        </div>
                    </div>
                </div>

                <div className="pps-container">
                    <div className="shortcut-pps" onClick={redirectGeneralSettings}>
                        Looking for <span>General settings</span>? Click here.
                    </div>
                </div>

                {loading && <WarnBeforeLeaving />}
                {!tabsWithoutPreview.includes(tab) && <PreviewPanel />}
            </Container>
        </React.Fragment>
    );
};

export default Component;
