import React from 'react';

// Components
import { Button, MenuItem, Select, Autocomplete, TextField } from '@mui/material';
import { ComponentState } from '../..';
import { trackUserInteraction } from '../../../../../utils/helpers';

const Component = () => {
    const { draftData, updateDraftData, setRoute, isModuleTaskCompleted } = ComponentState();

    const isNextDisabled = () => {
        const check = isModuleTaskCompleted('terms');
        return check ? false : true;
    };

    const goBack = () => {
        setRoute('start');
        trackUserInteraction(`Selected "Go Back"`);
    };

    const goNext = () => {
        setRoute('start');
        trackUserInteraction(`Selected "Done"`);
    };

    const getOptions = (id) => {
        if (id === 'breachNotificationPeriod') return ['Without undue delay', '24 hours', '48 hours', '72 hours'];
        if (id === 'subProcessorNotificationPeriod') return ['14 days', '30 days', 'A reasonable timeframe'];
    };
    return (
        <React.Fragment>
            <div className="route-header">
                <div className="left-side">
                    <div className="button-left">
                        <Button variant="text" onClick={goBack}>
                            Go Back
                        </Button>
                    </div>
                    <div className="title">The terms of your DPA</div>
                </div>
                <div className="button-right">
                    <Button variant="contained" color="primary" disabled={isNextDisabled()} onClick={goNext}>
                        Done
                    </Button>
                </div>
            </div>
            <div className="route-content roles">
                <div className="form-group">
                    <div className="form-label mb">Breach Notification Period</div>
                    <div className="form-content">
                        <Select
                            value={draftData.breachNotificationPeriod || 'none'}
                            fullWidth={true}
                            onChange={(ev) => updateDraftData('breachNotificationPeriod', ev.target.value)}
                        >
                            <MenuItem value="none" disabled={true}>
                                Select an option
                            </MenuItem>
                            {getOptions('breachNotificationPeriod').map((entry, ix) => (
                                <MenuItem value={entry} key={ix}>
                                    {entry}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="form-description mt mbl">
                        If you were to have a data breach, this is the amount of time that you have before having to notify the other party
                        after becoming aware of the breach. <b>The most friendly terms to your are:</b> “Without undue delay”.
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-label mb">Sub-processor Notification Period</div>
                    <div className="form-content">
                        <Select
                            value={draftData.subProcessorNotificationPeriod || 'none'}
                            fullWidth={true}
                            onChange={(ev) => updateDraftData('subProcessorNotificationPeriod', ev.target.value)}
                        >
                            <MenuItem value="none" disabled={true}>
                                Select an option
                            </MenuItem>
                            {getOptions('subProcessorNotificationPeriod').map((entry, ix) => (
                                <MenuItem value={entry} key={ix}>
                                    {entry}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="form-description mt mbl">
                        If you were to change one of your sub-processors you would need to notify your controllers first. You need to give
                        them some time so that they can assess the new sub-processors and object to them if they don’t agree with the
                        change. <b>The most friendly terms to you are:</b> “14 days” as that is long enough for them to have time to review
                        but not so long that you are blocked from making the change. This change must be communicated in writing.
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-label mb">Liability Cap</div>
                    <div className="form-content">
                        <Select
                            value={draftData.liabilityCapEnabled === null ? 'none' : draftData.liabilityCapEnabled}
                            fullWidth={true}
                            onChange={(ev) => updateDraftData('liabilityCapEnabled', ev.target.value === 'none' ? null : ev.target.value)}
                        >
                            <MenuItem value="none" disabled={true}>
                                Select an option
                            </MenuItem>
                            {[true, false].map((entry, ix) => (
                                <MenuItem value={entry} key={ix}>
                                    {entry === true ? 'Yes' : 'No'}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="form-description mt mbl">
                        If you were to breach your Data Processing Agreement or your requirements to be GDPR Compliant you can set a limit
                        or cap to the amount of money that you’ll be liable for. Note that this does not include fines that a regulator will
                        impose on you directly but rather damages from a claim brought to you in regards to your DPA.{' '}
                        <b>The most friendly terms for you are:</b> “Yes” so that a liability cap is included.
                    </div>
                </div>

                {draftData.liabilityCapEnabled === true && (
                    <React.Fragment>
                        <div className="form-group">
                            <div className="form-label mb">Liability Cap - Amount</div>
                            <div className="form-content">
                                <Autocomplete
                                    className="component-clear-autocomplete"
                                    value={draftData.liabilityCapAmount}
                                    fullWidth={true}
                                    onChange={(_, value) => updateDraftData('liabilityCapAmount', value)}
                                    options={['The liability caps as per the Main Agreement']}
                                    freeSolo={true}
                                    disableClearable={true}
                                    multiple={false}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={(ev) => updateDraftData('liabilityCapAmount', ev.target.value)}
                                            variant="outlined"
                                            placeholder={'Enter your amount'}
                                        />
                                    )}
                                />
                            </div>
                            <div className="form-description mt mbl">
                                Enter the maximum amount you’d like to be liable for under this Data Processing Agreement. This is typically
                                a multiple of the cost of the service that is described in the DPA.{' '}
                                <b>The most friendly terms for you are:</b>
                                Each party’s aggregate liability under this DPA will not exceed... “the liability caps as per the Main
                                Agreement”. You must check that your terms of service have liability caps.
                            </div>
                        </div>
                    </React.Fragment>
                )}

                <div className="form-group">
                    <div className="form-label mb">Governing Law and Jurisdiction</div>
                    <div className="form-content">
                        <Autocomplete
                            className="component-clear-autocomplete"
                            value={draftData.governingLawAndJurisdiction}
                            fullWidth={true}
                            onChange={(_, value) => updateDraftData('governingLawAndJurisdiction', value)}
                            options={['As per the Main Agreement']}
                            freeSolo={true}
                            disableClearable={true}
                            multiple={false}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(ev) => updateDraftData('governingLawAndJurisdiction', ev.target.value)}
                                    variant="outlined"
                                    placeholder={'Enter your text'}
                                />
                            )}
                        />
                    </div>
                    <div className="form-description mt mbl">
                        If there were to be a dispute over this agreement, we must establish in which courts (geographically speaking)
                        claims would be put forward. We recommend this to be the Governing Law and Jurisdiction of your main agreement or
                        where you have your headquarters. <b>The most friendly terms to you here are:</b> “As per the main agreement”
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-label mb">Special Provisions</div>
                    <div className="form-content">
                        <TextField
                            value={draftData.specialProvisions}
                            fullWidth={true}
                            variant="outlined"
                            multiline={true}
                            placeholder="Type here..."
                            rows={3}
                            onChange={(ev) => updateDraftData('specialProvisions', ev.target.value)}
                        />
                    </div>
                    <div className="form-description mt">
                        If there are any special requirements that you’d like to include in your DPA, do so here.
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
