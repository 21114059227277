import React, { createContext, useContext, useEffect, useState } from 'react';

// Context
import { PanelContext } from '../..';

// Components
import Type from './components/type';
import Alternatively from './components/alternatively';
import SpecificTime from './components/specificTime';
import CustomText from './components/customText';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { data, setData, validateView } = PanelContext();
    const [validationResults, setValidationResults] = useState({});

    const [lastRecommended, setLastRecommended] = useState(
        data.retention.type && data.retention.type.includes('recommendations') ? data.retention.type : null,
    );

    const validateFields = async () => {
        const { results } = await validateView('retention');
        setValidationResults(results);
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    const setType = (id) => {
        setData((currentState) => {
            let newState = { ...currentState };

            // Set new id..
            let newId = id === newState.retention.type ? null : id;

            if (newId && newId.includes('recommendations')) {
                setLastRecommended(newId);
            }

            if (newId === null && lastRecommended && data.retention.type && !data.retention.type.includes('recommendations')) {
                newId = lastRecommended;
            }

            newState.retention.type = newId;

            return newState;
        });
    };

    const onTypeChanged = (newId) => {
        // When we set it.
        if (newId == 'recommendations:1' && data.retention.type !== 'recommendations:1') {
            window.showAlert(
                'Confirmation',
                <React.Fragment>
                    <p style={{ fontSize: 14, lineHeight: 1.5, margin: 3 }}>
                        Are you sure? As a rule of thumb, you should not hold personal data indefinitely (unless for archiving,
                        scientific/historical research, or statistical purposes).
                    </p>
                    <p style={{ fontSize: 12, line: 1.5, margin: 2, marginTop: 8 }}>
                        You can find more information on the exceptions above{' '}
                        <a
                            href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/data-protection-principles/a-guide-to-the-data-protection-principles/the-principles/storage-limitation/#archiving"
                            target="_blank"
                        >
                            here.
                        </a>
                    </p>
                </React.Fragment>,
                'warning',
                [
                    {
                        text: 'Cancel',
                        dataCy: `alert-cancel-button`,

                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                    {
                        text: 'Confirm',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            setType(newId);
                        },
                    },
                ],
            );
            return false;
        }
        setType(newId);
    };

    const PassedProps = {
        validationResults,
        setValidationResults,
        setType: onTypeChanged,
    };

    return (
        <Context.Provider value={PassedProps}>
            <div className="component-forms">
                <Type />
                <Alternatively />
                <SpecificTime />
                <CustomText />
            </div>
        </Context.Provider>
    );
};

export default Component;
