import React from 'react';

// Components
import { Button } from '@mui/material';
import { ComponentState } from '../../..';
import { trackUserInteraction } from '../../../../../../utils/helpers';

const Component = (props) => {
    const { isModuleTaskCompleted } = ComponentState();

    const getNumberOfCompletedTasks = () => {
        let arr = props.data.entries
            .map((x) => {
                const isSecurityMeasure = x.type === 'securityMeasure';
                return isModuleTaskCompleted(isSecurityMeasure ? 'security-measures' : x.id, isSecurityMeasure ? x.id : null);
            })
            .filter((x) => x === true);
        return arr.length;
    };

    const onStartClicked = () => {
        let invalidArr = props.data.entries
            .map((x) => {
                const isSecurityMeasure = x.type === 'securityMeasure';
                return {
                    ...x,
                    _status: isModuleTaskCompleted(isSecurityMeasure ? 'security-measures' : x.id, isSecurityMeasure ? x.id : null),
                };
            })
            .filter((x) => x._status !== true);

        props.onTaskSelected(invalidArr[0]);

        trackUserInteraction(`Selected "Start or Continue" from Module Header`, {
            task: invalidArr[0],
        });
    };

    const isModuleCompleted = () => {
        let arr = props.data.entries
            .map((x) => {
                const isSecurityMeasure = x.type === 'securityMeasure';
                return isModuleTaskCompleted(isSecurityMeasure ? 'security-measures' : x.id, isSecurityMeasure ? x.id : null);
            })
            .filter((x) => x === true);
        return arr.length === props.data.entries.length;
    };

    return (
        <React.Fragment>
            <div className="header">
                <div className="left-side">
                    <div className="title">{props.data.label}</div>
                    <div className="description">{props.data.description}</div>
                </div>
                <div className="right-side">
                    {isModuleCompleted() ? (
                        <Button variant="contained" color="primary" disabled={true}>
                            Completed
                        </Button>
                    ) : (
                        <Button variant="contained" color="primary" onClick={onStartClicked}>
                            {getNumberOfCompletedTasks() === 0 ? 'Start' : 'Continue'}
                        </Button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
