import React, { useEffect, useState } from 'react';

// Helpers
import { getValidationPropFields, validateAgainstSchema } from '../../../../utils/helpers';
import { validationSchema } from './validation';

// Components
import { TextField, Button } from '@mui/material';

const Form = (props) => {
    const [validationResults, setValidationResults] = useState({});

    const validateFields = async () => {
        try {
            await validateAgainstSchema(validationSchema, props.data);
            setValidationResults({});
        } catch (err) {
            setValidationResults(err);
        }
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [props.data]);

    return (
        <React.Fragment>
            <div className="page-forms">
                <div className="input-group">
                    <div className="label">Email</div>
                    <div className="input">
                        <TextField
                            disabled={props.submitted}
                            variant="standard"
                            placeholder="john@work.com"
                            value={props.data.email}
                            onChange={(ev) => props.setData({ ...props.data, email: ev.target.value.toString().toLowerCase() })}
                            fullWidth
                            {...getValidationPropFields(validationResults, props.data.email.length > 0, `email`)}
                        />
                    </div>
                </div>
                <div className="input-group">
                    <div className="label">Coupon code</div>
                    <div className="input">
                        <TextField
                            disabled={props.submitted}
                            variant="standard"
                            placeholder="Coupon code"
                            value={props.data.promotionalCode}
                            onChange={(ev) => props.setData({ ...props.data, promotionalCode: ev.target.value })}
                            fullWidth
                            {...getValidationPropFields(validationResults, props.data.promotionalCode.length > 0, `promotionalCode`)}
                        />
                    </div>
                </div>
                <div className="input-group">
                    <div className="checkbox">
                        <span className="text">
                            By accessing the platform you agree to our{' '}
                            <a target="_blank" rel="noreferrer" href="https://www.privasee.co.uk/terms-and-conditions">
                                subscription agreement
                            </a>{' '}
                            and acknowledge that you have read our{' '}
                            <a target="_blank" rel="noreferrer" href="https://app.privasee.co.uk/privacy-center/619377e839d1cf0013805da7">
                                privacy policy
                            </a>
                            .
                        </span>
                    </div>
                </div>
            </div>
            <div className="submit-area">
                <Button
                    disabled={props.submitted || Object.keys(validationResults).length > 0}
                    onClick={props.onSubmit}
                    variant="contained"
                    color="primary"
                >
                    Claim
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Form;
