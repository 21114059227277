import React from 'react';

// Components
import { Button, Checkbox } from '@mui/material';
import { ComponentState } from '../..';
import { trackUserInteraction } from '../../../../../utils/helpers';
import { useState } from 'react';

// UI Components
import TooltipPanel from './components/tooltipPanel';

const Component = () => {
    const { draftData, updateDraftData, setRoute } = ComponentState();
    const [tooltipEnabled, setTooltipEnabled] = useState(false);

    const isNextDisabled = () => {
        if (draftData.dataTransferredOutsideEEA.length < 1) return true;
        if (!draftData.dataTransferredOutsideEEA.includes('No') && draftData.dataTransferredIndividuals.length < 1) return true;
        return false;
    };

    const goBack = () => {
        setRoute('start');

        trackUserInteraction(`Selected "Go Back"`);
    };

    const onDone = () => {
        setRoute('start');
        trackUserInteraction(`Selected "Done"`, {});
    };

    // @Reminder this must 1:1 matching with generateDocument.js
    const willDataOptions = [
        'Yes, my client is/may be outside of the EEA, UK or AC',
        'Yes, I am located outside of the EEA, UK or AC',
        'No',
    ];

    const individualsOptions = ['EEA Individuals', 'UK Individuals', 'Others'];

    const selectIndividualGroup = (value) => {
        let newValues = draftData.dataTransferredIndividuals;

        if (newValues.includes(value)) {
            newValues = newValues.filter((x) => x !== value);
        } else {
            newValues.push(value);
        }
        updateDraftData(`dataTransferredIndividuals`, newValues);
    };

    const updateDataTransfer = (value) => {
        let newValues = draftData.dataTransferredOutsideEEA;

        if (newValues.includes(value)) {
            newValues = newValues.filter((x) => x !== value);
        } else {
            newValues.push(value);
        }

        if (value === 'No') {
            newValues = ['No'];
        } else {
            newValues = newValues.filter((x) => x !== 'No');
        }

        updateDraftData(`dataTransferredOutsideEEA`, newValues);
    };

    return (
        <React.Fragment>
            <div className="route-header">
                <div className="left-side">
                    <div className="button-left">
                        <Button variant="text" onClick={goBack}>
                            Go Back
                        </Button>
                    </div>
                    <div className="title">Service data international transfers </div>
                </div>
                <div className="button-right">
                    <Button variant="contained" color="primary" disabled={isNextDisabled()} onClick={onDone}>
                        Done
                    </Button>
                </div>
            </div>
            <div className="route-content restricted-transfers">
                <div className="tooltip-block mb">
                    <p>
                        <img src="/assets/images/icons/information-icon.png" className="info-icon" /> In this section "data processed" means
                        only the information that you use/collect/store/share when offering your services.
                    </p>
                    <p>E.g. data used for internal HR or accounting would not apply.</p>
                </div>
                <div className="form-group">
                    <div className="form-label">
                        Will data from the EEA or UK be transferred to or accessed from outside of the EEA, UK or an Adequate Country (AC)?
                        <div
                            className="tooltip"
                            style={{ display: 'inline-block', marginLeft: 4 }}
                            onMouseEnter={() => {
                                setTooltipEnabled(true);
                            }}
                        >
                            <i className="tooltip-icon fa-solid fa-circle-info"></i>
                        </div>
                    </div>
                    <div className="form-sub-label grey mb">Select all that apply</div>
                    <div className="form-content">
                        {willDataOptions.map((entry, ix) => (
                            <div key={ix} className="component-check">
                                <div className="input">
                                    <Checkbox
                                        checked={draftData.dataTransferredOutsideEEA.includes(entry)}
                                        onClick={() => updateDataTransfer(entry)}
                                    />
                                </div>
                                <div className="label">{entry}</div>
                            </div>
                        ))}
                    </div>
                </div>
                {draftData.dataTransferredOutsideEEA && !draftData.dataTransferredOutsideEEA.includes('No') && (
                    <div className="form-group">
                        <div className="form-label">The data transferred or accessed is about individuals from:</div>
                        <div className="form-sub-label grey mb">Select all that apply</div>
                        <div className="form-content">
                            {individualsOptions.map((entry, ix) => (
                                <div key={ix} className="component-check">
                                    <div className="input">
                                        <Checkbox
                                            checked={draftData.dataTransferredIndividuals.includes(entry)}
                                            onClick={() => selectIndividualGroup(entry)}
                                        />
                                    </div>
                                    <div className="label">{entry}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            {tooltipEnabled && <TooltipPanel close={() => setTooltipEnabled(false)} />}
        </React.Fragment>
    );
};

export default Component;
