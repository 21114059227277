const privacyCenterEndPoints = {
    GetSystemTranslations: {
        link: '/guest/graphql',
        method: 'POST',
        query: `
        query GetSystemTranslations ($systems: [String], $languages: [String], $payload: JSON){
          GetSystemTranslations (systems: $systems, languages: $languages, payload: $payload)
        }
      `,
        parserFunction: ({ data }) => data.GetSystemTranslations,
    },
    GetCustomerTranslations: {
        link: '/graphql',
        method: 'POST',
        query: `
      query GetCustomerTranslations ($systems: [String]){
        GetCustomerTranslations (systems: $systems)
      }
    `,
        parserFunction: ({ data }) => data.GetCustomerTranslations,
    },
    GetCompanyData: {
        link: '/graphql',
        method: 'POST',
        query: `
          query GetCompanyData {
            GetCompanyData
          }
        `,
        parserFunction: ({ data }) => data.GetCompanyData,
    },
    GetCompanyInfo: {
        link: '/guest/graphql',
        method: 'POST',
        query: `
        query GetCompanyInfo ($companyId: String) {
          GetCompanyInfo (companyId: $companyId) {
            _id
            companyDomains
            isMultiAccountCompany
            companyLegalName
            companyAddress {
              line1
              line2
              postCode
              country
              city
            }
            euRepInfo{
              euRepName
              euRepAddress
              euRepContact
            }
            ukRepInfo{
              ukRepName
              ukRepAddress
              ukRepContact
            }
            inquiriesEmail
            dpoName
            icoRegNumber
            operateInEU
            branchInEU
            branchEUAddress
            operateInUK
            branchInUK
            branchUKAddress
            cookieUse
            consentManagementUse
            webBeaconsUse
            }
          }`,
    },
    SaveCompanyInfo: {
        link: '/graphql',
        method: 'POST',
        query: `
        mutation SaveCompanyInfo ($update: CompanyDataInputType) {
          SaveCompanyInfo (update: $update)
          }`,
    },
    UpdateCookieType: {
        link: '/graphql',
        method: 'POST',
        query: `
        mutation UpdateCookieType ($update: CookieEditorInputType) {
          UpdateCookieType (update: $update)
          }`,
    },
    UpdateDataElementsBulk: {
        link: '/graphql',
        method: 'POST',
        query: `
          mutation UpdateDataElementsBulk($entries: [DataElementExampleUpdateType]) {
            UpdateDataElementsBulk(entries: $entries)
          }
        `,
    },
    SaveCompanyCookieInformation: {
        link: '/graphql',
        method: 'POST',
        query: `
        mutation SaveCompanyCookieInformation ($update: CookieEditorInputType) {
          SaveCompanyCookieInformation (update: $update)
          }`,
    },
    getPrivacyPortal: {
        link: '/guest/graphql',
        method: 'POST',
        query: `
        query GetPrivacyPortalData ($companyId: String)  {
          GetPrivacyPortalData (companyId: $companyId) {
            _id
            isDataProcessor
            template
            companyDomains
            companyLogo
            companyDescription
            companyLegalName
            automatedDecisionMaking
            companyAddress
            {
                  line1
                  line2
                  postCode
                  country
                  city
            }
            individualsReferring {
              label
              referringRole
              isChildren
            }
            defaultLanguage
            languagesAvailable
            inquiriesEmail
            dpoName
            icoRegNumber
            operateInEU
            branchInEU
            branchEUAddress
            operateInUK
            branchInUK
            branchUKAddress
            isPrivacyPortalHosted
            usercentricsEmail
            usercentricsSettingsId
            euRepInfo {
                euRepName
                euRepAddress
                euRepContact
            }
            ukRepInfo {
                ukRepName
                ukRepAddress
                ukRepContact
            }
            theme {
                mode
                color
            }
            certificates{
                type
                dateLastVerify
            }
            dataSubjects {
                label
                personal_data {
                    purpose
                    element_category
                    legal_basis
                    role
                }
                sensitive_data {
                    purpose
                    element_category
                    legal_basis
                    sensitive_legal_basis
                    role
                }
            }
            thirdParties {
                _id
                _storageLocationId
                label
                displayLabel
                residency
                encryption
            }
            thirdPartyCookies {
                useCookies
                useWebBeacons
                useConsentManagementSolution
                entries {
                    _storageLocationId
                    name
                    type
                    purpose
                    external_link
                    translations {
                      cookiePurpose
                    }
                    cookieDefaultConsentStatus
                    cookieNames
                    cookieExpiry
                }
            }
            dataElements {
              _id
              dataExamples
              elementName
              isSensitive
            }
            services
            subProcessors
            privacyPortalSettings
            cookieBannerSettings
            coupons
            isDiscountedCompany
            smartCookieBlocker
            targetAudience
            industry
            companyInsights
          }
      }
      `,
        parserFunction: ({ data }) => data.GetPrivacyPortalData,
    },
    UploadCompanyLogo: {
        link: '/graphql',
        method: 'POST',
        query: `
          mutation UploadCompanyLogo ($data: String, $extension: String) {
            UploadCompanyLogo (data: $data, extension: $extension)
          }`,
        parserFunction: ({ data }) => data.UploadCompanyLogo,
    },
    DeleteCompanyLogo: {
        link: '/graphql',
        method: 'POST',
        query: `
          mutation UploadCompanyLogo {
            DeleteCompanyLogo
          }
        `,
        parserFunction: ({ data }) => data.DeleteCompanyLogo,
    },
};

export default privacyCenterEndPoints;
