import React from 'react';

// Components
import { Button, MenuItem, Select } from '@mui/material';
import { ComponentState } from '../..';
import { trackUserInteraction } from '../../../../../utils/helpers';

const Component = () => {
    const { draftData, updateDraftData, setRoute } = ComponentState();

    const isNextDisabled = () => {
        if (draftData.parties.sender.role.length < 1 || draftData.parties.receiver.role.length < 1) return true;
        return false;
    };

    const goBack = () => {
        setRoute('start');
        trackUserInteraction(`Selected "Go Back"`);
    };

    const goNext = () => {
        setRoute('start');
        trackUserInteraction(`Selected "Done"`);
    };

    const options = [
        {
            label: 'Processor',
            value: 'processor',
        },
        {
            label: 'Controller',
            value: 'controller',
        },
        {
            label: 'Sub-processor',
            value: 'subProcessor',
        },
        {
            label: "I'm not sure",
            value: 'unknown',
        },
    ];

    return (
        <React.Fragment>
            <div className="route-header">
                <div className="left-side">
                    <div className="button-left">
                        <Button variant="text" onClick={goBack}>
                            Go Back
                        </Button>
                    </div>
                    <div className="title">What are the roles in this DPA</div>
                </div>
                <div className="button-right">
                    <Button variant="contained" color="primary" disabled={isNextDisabled()} onClick={goNext}>
                        Done
                    </Button>
                </div>
            </div>
            <div className="route-content roles">
                <div className="tooltip-block mb">
                    <p>
                        <img src="/assets/images/icons/information-icon.png" className="info-icon" /> Controller vs Processor vs
                        Sub-processor
                    </p>
                    <p style={{ paddingTop: 6 }}>For Example:</p>
                    <ul>
                        <li>
                            Company A may use Hubspot for marketing purposes. In this scenario Company A is a <b>Controller</b>.
                        </li>
                        <li>
                            Hubspot offers a CRM platform for their customers to use for marketing purposes, in this scenario Hubspot is a{' '}
                            <b>Processor</b>.
                        </li>
                        <li>
                            Hubspot uses Amazon Web Services to host and deliver their CRM platform service, in this scenario Hubspot is
                            still a <b>Processor</b> and Amazon Web Services is a <b>sub-processor</b> to Hubspot.
                        </li>
                    </ul>
                </div>
                <div className="form-group">
                    <div className="form-label">In this DPA you act as a:</div>
                    <div className="form-description">
                        Please indicate your role in relation to the processing of personal data under this agreement.
                    </div>

                    <div className="form-content">
                        <Select
                            fullWidth={true}
                            value={draftData.parties.sender.role || 'none'}
                            variant="outlined"
                            onChange={(e) => updateDraftData('parties.sender.role', e.target.value)}
                        >
                            <MenuItem value="none" disabled={true}>
                                Select an option
                            </MenuItem>
                            {options.map((entry, ix) => (
                                <MenuItem key={ix} value={entry.value}>
                                    {entry.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-label">In this DPA, the other party acts as a:</div>
                    <div className="form-description">
                        {' '}
                        Please indicate their role in relation to the processing of personal data under this agreement.
                    </div>

                    <div className="form-content">
                        <Select
                            fullWidth={true}
                            value={draftData.parties.receiver.role || 'none'}
                            variant="outlined"
                            onChange={(e) => updateDraftData('parties.receiver.role', e.target.value)}
                        >
                            <MenuItem value="none" disabled={true}>
                                Select an option
                            </MenuItem>
                            {options.map((entry, ix) => (
                                <MenuItem key={ix} value={entry.value}>
                                    {entry.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
