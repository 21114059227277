import React from 'react';
import { ComponentState } from '..';
import { trackUserInteraction } from '../../../../utils/helpers';

// Components
import Container from '../../../layout/container';
import Tabs from './tabs';
import LoadingPlaceholder from './loadingPlaceholder';

const Component = ({ children }) => {
    const { tab, loading, error, data, setTab, changesDetected, setChangesDetected } = ComponentState();

    const isChildrenReady = error === false && data !== null ? true : false;

    const updateTab = async (func, tabNew) => {
        if (changesDetected) {
            await trackUserInteraction(`Warning of unsaved data`);
            window.showAlert(
                'Confirmation',
                `Are you sure you want to leave this section? You have unsaved data! Press the save button at the bottom of this page to save it now.`,
                'warning',
                [
                    {
                        text: 'Leave',
                        dataCy: `alert-submit-button`,

                        onClick: async ({ dismissAlert }) => {
                            setTab(tabNew);
                            setChangesDetected(false);
                            dismissAlert();
                            await trackUserInteraction(`Selected "Leave"`);
                        },
                    },

                    {
                        text: `Stay`,
                        dataCy: `alert-cancel-button`,

                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            await trackUserInteraction(`Selected "Stay"`);
                        },
                    },
                ],
            );
        } else {
            func(tabNew);
        }
    };

    return (
        <React.Fragment>
            <Container title="Cookie Banner" classNames="page-privacy-portal-settings page-cookie-banner" isAlternateBackground={true}>
                <div className="page-title">Cookie Banner</div>
                <div className="page-content">
                    <Tabs loading={loading} value={tab} setValue={(val) => updateTab(setTab, val)} />
                    <div className="page-subcontent">{isChildrenReady ? children : <LoadingPlaceholder />}</div>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default Component;
