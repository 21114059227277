import React from 'react';

import { MenuItem, Select } from '@mui/material';
import { filtersRequiringOptions } from '../../utils/definitions';
import { EntryState } from '../entry';

const Component = () => {
    const { setData, getOptions, data, fields } = EntryState();

    const changeKey = (value) => {
        // Set the new key (key = Name, description, etc)
        setData(`key`, value);

        // If the filter used is exactly and the new key doesn't has options..
        const hasOptions = getOptions(value) && getOptions(value).length > 0 ? true : false;

        if (hasOptions && data.filter !== 'is') {
            setData('filter', 'is');
        }

        if (filtersRequiringOptions.includes(data.filter) && !hasOptions) {
            setData('filter', 'contains'); // reset
        }

        // We reset this data to make sure they didn't select anything before and now it was stuck but not in the other options.
        if (filtersRequiringOptions) {
            setData('value', '');
        }
    };

    return (
        <React.Fragment>
            <div className="field key">
                <Select fullWidth={true} value={data.key} onChange={(ev) => changeKey(ev.target.value)}>
                    {fields.map((field, ix) => (
                        <MenuItem key={ix} value={field.key}>
                            {field.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </React.Fragment>
    );
};

export default Component;
