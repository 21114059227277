import { logError } from '../../../../../../utils/helpers';

/**
 * Validates if the tab is valid or not.
 * @param {Object} storageLoc - Storage location object.
 * @param {string} tab - Tab id (e.g., 'information', 'detailedView').
 * @returns {boolean} - True if the tab is valid, false if not.
 */

export const isTabValid = (data, tab) => {
    try {
        // If tab is general and is missing information such as data residency
        if (tab === 'information' && (data.dataResidency.length < 1 || data.securityMeasures.length < 1)) {
            return false;
        }

        // If tab is advanced.
        if (tab === 'detailedView') {
            // If there is no matrix entry at all.
            if (data.matrixMap.length < 1) return false;

            // A simple boolean to check
            let isNotValid = false;

            data.matrixMap.forEach((process) => {
                // If the process is missing individuals
                if (process.individuals.length < 1) {
                    isNotValid = true;
                }

                process.individuals.forEach((individual) => {
                    // If the individual is missing elements
                    if (individual.elements.length < 1) {
                        isNotValid = true;
                    }
                });
            });

            // If is not valid
            if (isNotValid) return false;
        }

        return true;
    } catch (err) {
        logError(`editPanel.isTabValid`, err);
        return false;
    }
};

/**
 * This function will validate the storage location and tell us if it should show on Privacy portal or not.
 * @param {*} data - Data from the Edit Panel.
 * @returns {*} Returns an object with this format: { status: boolean, reason: string }
 */

// * @Reminder: On 7th Dec 2023 we added a server-side function called "validateSessionStorageLocation" make sure to keep that in sync.

export const validateStorageLocation = (data) => {
    try {
        let response = { status: true, reason: 'Success' }; // By default it will be valid.

        // Core requirements
        if (data.label.length < 1) return { status: false, reason: 'NO_LABEL' };
        if (data.dataResidency.length < 1) return { status: false, reason: 'NO_RESIDENCY' };
        if (data.securityMeasures.length < 1) return { status: false, reason: 'NO_SECURITY_MEASURE' };

        // Missing elements, individuals or processes
        if (data.matrixMap.length < 1) return { status: false, reason: 'NO_PROCESSES' };
        if (data.matrixMap.filter((c) => c.individuals.length < 1).length > 0) return { status: false, reason: 'NO_INDIVIDUALS' };
        if (data.matrixMap.filter((c) => c.individuals.find((c) => c.elements.length < 1)).length > 0)
            return { status: false, reason: 'NO_ELEMENTS' };

        return response;
    } catch (err) {
        logError(`editPanel.validateStorageLocation`, err);
        return { status: false, reason: 'Code error' };
    }
};

/**
 * This is just a simple function that helps us to map the entries to their allocated data. Ex: it will fetch the process data from a process id.
 * This data is not saved anywhere, is simply for helping the front-end with data.
 * @param {*} matrixMap - the return from the getInitialData matrix map.
 * @param {*} dataInventory - the whole data inventory.
 * @returns
 */

export const mapEntriesToVisualData = (matrixMap, dataInventory) => {
    try {
        return matrixMap
            .map((c) => {
                // get the tool mentioned
                const processMentioned = dataInventory.processes.find((b) => b._id === c._processId);
                if (!processMentioned) return null;

                return {
                    individuals: c.individuals
                        .map((i) => {
                            let data = dataInventory.individuals.find((c) => c._id === i._individualId);
                            if (!data) return null;

                            return {
                                _id: data._id,
                                data,
                                elements: i.elements
                                    .map((em) => {
                                        let dataElm = dataInventory.elements.find((d) => d._id == em);

                                        return dataElm ? { _id: em, data: dataElm } : null;
                                    })
                                    .filter((c) => c !== null),
                            };
                        })
                        .filter((c) => c !== null),
                    data: processMentioned,
                };
            })
            .filter((c) => c !== null);
    } catch (err) {
        logError(`module.individuals.utils.mapProcessesEntriesToDataInventory`, err);
        return [];
    }
};

/**
 * This function will convert all the matriceViews to Matrix Entries right for the inserting in the Data Inventory.
 */

export const convertMatriceViewToMatrixEntries = (matriceView) => {
    try {
        let arr = [];

        for (const process of matriceView) {
            for (const individual of process.individuals) {
                for (const elementId of individual.elements) {
                    arr.push({
                        _processId: process._processId,
                        _individualId: individual._individualId,
                        _elementId: elementId,
                    });
                }
            }
        }

        return arr;
    } catch (err) {
        logError(`convertMatriceViewToMatrixEntries`, err, { matriceView });
        throw err;
    }
};

/**
 *
 * @param {*} entries - Matrice entries like the one from Data Inventory.
 * @returns an object with relationships.
 */

export const getRelationshipsFromMatrice = (entries) => {
    try {
        let processes = [];
        let individuals = [];
        let elements = [];

        for (const entry of entries) {
            if (!processes.includes(entry._processId)) {
                processes.push(entry._processId);
            }

            if (!individuals.includes(entry._individualId)) {
                individuals.push(entry._individualId);
            }

            if (!elements.includes(entry._elementId)) {
                elements.push(entry._elementId);
            }
        }

        return { processes, elements, individuals };
    } catch (err) {
        logError(`convertMatriceViewToMatrixEntries`, err, { matriceView });
        throw err;
    }
};
