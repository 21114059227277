import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ComponentState } from '../../..';
import { getValidationPropFields, validateAgainstSchema } from '../../../../../../utils/helpers';
import { dpaCreateCustomSchema } from '../../../../gdprEssentials/utils/validations';

const Component = (props) => {
    const [validationResults, setValidationResults] = useState({});
    const { draftData } = ComponentState();

    const updateField = (field, value) => {
        props.setEditData((currentData) => {
            let modifiedData = JSON.parse(JSON.stringify(currentData));
            modifiedData.payload[field] = value;
            return modifiedData;
        });
    };

    const validateFields = async () => {
        if (props.editData.active === false) return false;
        try {
            await validateAgainstSchema(dpaCreateCustomSchema, props.editData.payload);
            setValidationResults({});
            const matchTool = draftData.subProcessors.find((i) => i.label === props.editData.payload.label);
            const matchIndex = draftData.subProcessors.findIndex((i) => i.label === props.editData.payload.label);
            if (matchTool && matchIndex !== -1 && matchIndex !== props.editData.index) {
                setValidationResults({
                    label: `Name is already used. Try adding a suffix: ${props.editData.payload.label} (2)`,
                });
            }
        } catch (err) {
            setValidationResults(err);
        }
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [props.editData.payload]);

    return (
        <React.Fragment>
            <Dialog
                className={`create-storage-location-modal create-dpa-storage-location-modal`}
                open={true}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle data-cy="dialog-title">{props.editData.creating ? `Create tool` : `Editing Tool`}</DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <div className="form-group" style={{ marginBottom: 18 }}>
                        <div className="label">Tool or 3rd party Name</div>

                        <div className="input">
                            <TextField
                                autoFocus
                                id="tool-name"
                                fullWidth
                                variant="standard"
                                placeholder="DigitalOcean"
                                value={props.editData.payload.label}
                                onChange={(e) => updateField('label', e.target.value)}
                                data-cy="dialog-input"
                                {...getValidationPropFields(validationResults, props.editData.payload.label.length > 0, `label`)}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={props.onDismiss} data-cy="dialog-dismiss-button">
                        Dismiss
                    </Button>
                    <Button
                        disabled={Object.values(validationResults).length > 0}
                        variant="contained"
                        color="primary"
                        onClick={() => props.onSubmit()}
                        data-cy="dialog-submit-button"
                    >
                        {props.editData.creating ? `Create` : `Save`}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
