import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { CustomToolContext } from '..';

const Component = (props) => {
    const { validationResults, onSubmit, onDismiss } = CustomToolContext();

    return (
        <React.Fragment>
            <Dialog className={`create-storage-location-modal v2`} open={true} maxWidth={'lg'} fullWidth={true}>
                <DialogTitle data-cy="dialog-title">Create Tool</DialogTitle>
                <DialogContent style={{ width: '100%' }}>{props.children}</DialogContent>
                <DialogActions>
                    <Button variant="outlined" data-cy="dialog-dismiss-button" onClick={onDismiss}>
                        Dismiss
                    </Button>
                    <Button
                        disabled={Object.keys(validationResults).length > 0}
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        data-cy="dialog-submit-button"
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
