import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem } from '@mui/material';
import { getIndividualsTypes } from '../../../../../../individuals/components/functions';
import { createIndividual } from '../../../../../../storageLocations/utils/helpers';
import { ModuleState } from '../../../../../../..';

const Component = (props) => {
    const [modalData, setModalData] = useState(null);
    const { data, updateData } = ModuleState();

    const showEditModal = (ev) => {
        setModalData(ev.detail);
    };

    const dismiss = () => {
        setModalData(null);
    };

    const onSubmit = () => {
        // Save data..
        props.updateStorageData(
            `individuals`,
            [
                ...props.storageData.individuals,
                createIndividual({
                    label: modalData.label,
                }),
            ],
            true,
        );

        // Is it already added?
        const alreadyAdded = data.companyInfo.individualsReferring.find((c) => c.label === modalData.label);

        // Is not already added.
        if (!alreadyAdded) {
            // Add it to the individuals referring
            updateData('companyInfo.individualsReferring', [
                ...data.companyInfo.individualsReferring,
                { label: modalData.label, referringRole: modalData.type },
            ]);
        }

        // Close modal
        setModalData(null);
    };

    useEffect(() => {
        document.addEventListener('individualModal:start', showEditModal);

        return () => {
            document.removeEventListener('individualModal:start', showEditModal);
        };
    }, []);

    if (modalData === null) return null;

    return (
        <React.Fragment>
            <Dialog className={`set-individual-type-modal`} open={true} maxWidth={'sm'} fullWidth={true}>
                <DialogTitle data-cy="dialog-title">
                    Additional information required for <b>{modalData.label}</b>
                </DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <p>
                        Before adding this new category, please let us know how you refer to these individuals. This helps us ensure
                        accurate recommendations from the platform in the future.
                    </p>
                    <hr />
                    <p>
                        How do you refer to <b>{modalData.label}</b>?
                    </p>
                    <Select
                        value={modalData.type || 'not-selected'}
                        fullWidth={true}
                        onChange={(ev) => setModalData({ ...modalData, type: ev.target.value })}
                    >
                        <MenuItem disabled={true} value="not-selected">
                            Click here to select an option..
                        </MenuItem>
                        {getIndividualsTypes().map((c) => (
                            <MenuItem key={c.value} value={c.value}>
                                {c.label}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={dismiss} data-cy="dialog-dismiss-button">
                        Dismiss
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!modalData.type}
                        color="primary"
                        onClick={onSubmit}
                        data-cy="dialog-submit-button"
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
