import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@mui/material';
import { getValidationPropFields, logError, trackUserInteraction, validateAgainstSchema } from '../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../utils/endpoints';
import { PageContext } from '..';
import { workspaceV2InviteUser } from '../../gdprEssentials/utils/validations';

const Component = () => {
    const { loadData, data } = PageContext();
    const [submitted, setSubmitted] = useState(false);
    const [visible, setVisible] = useState(false);
    const [fields, setFields] = useState({ email: '', role: 'admin' });
    const [validationResults, setValidationResults] = useState({});

    const validateFields = async () => {
        try {
            await validateAgainstSchema(workspaceV2InviteUser, fields);
            setValidationResults({});
        } catch (err) {
            setValidationResults(err);
        }
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [fields]);

    const onEventOfButton = () => {
        trackUserInteraction(`Selected "Invite user"`);
        setVisible(true);
        setFields({
            email: '',
            role: 'admin',
        });
    };

    const dismissModal = () => {
        trackUserInteraction(`Selected "Dismiss Modal"`);
        setVisible(false);
    };

    const roles = [
        {
            label: 'Admin',
            value: 'admin',
        },
        {
            label: 'Member',
            value: 'member',
        },
    ];
    const onSubmit = async () => {
        try {
            if (Object.keys(validationResults).length > 0) return false;
            setSubmitted(true);
            await makeEndpointRequest(`InviteToWorkspaceV2`, { _workspaceId: data._id, email: fields.email.trim(), role: fields.role });
            setSubmitted(false);
            setVisible(false);
            await trackUserInteraction(`Invited new user`, fields);
            await loadData();
        } catch (err) {
            trackUserInteraction(`Having Difficulties`, { reason: `failed to invite new user` });
            await logError(`INVITE_WORKSPACE_USER_V2`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
            setSubmitted(false);
        }
    };

    useEffect(() => {
        document.addEventListener('workspaceUsers:invite', onEventOfButton);

        return () => {
            document.removeEventListener('workspaceUsers:invite', onEventOfButton);
        };

        // eslint-disable-next-line
    }, []);

    if (visible === false) return null;

    return (
        <React.Fragment>
            <Dialog className="create-storage-location-modal " open={true} maxWidth="xs" fullWidth>
                <DialogTitle data-cy="dialog-title">Invite user</DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <div className="form-group">
                        <div className="label">Email</div>
                        <div className="form-content">
                            <TextField
                                variant="standard"
                                placeholder="Email"
                                value={fields.email}
                                onChange={(e) => setFields({ ...fields, email: e.target.value })}
                                fullWidth
                                data-cy="dialog-input"
                                disabled={submitted}
                                {...getValidationPropFields(validationResults, fields.email.length > 0, `email`)}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="label">Role</div>
                        <div className="form-content">
                            <Select
                                disabled={submitted}
                                value={fields.role}
                                variant="standard"
                                fullWidth={true}
                                onChange={(e) => setFields({ ...fields, role: e.target.value })}
                            >
                                {roles.map((role, ix) => (
                                    <MenuItem key={ix} value={role.value}>
                                        {role.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dismissModal} variant="outlined" data-cy="dialog-dismiss-button">
                        Dismiss
                    </Button>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        disabled={fields.email.length < 1 || submitted || Object.keys(validationResults).length > 0}
                        data-cy="dialog-save-button"
                    >
                        Invite
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
