import React from 'react';

// Dependencies
import { PageContext } from '..';

// UI Components
import Entry from './entry/index';

const Component = () => {
    const { data } = PageContext();

    return (
        <React.Fragment>
            <div className="entries">
                {data.workspaces.map((entry, ix) => (
                    <Entry data={entry} key={ix} />
                ))}
            </div>
        </React.Fragment>
    );
};

export default Component;
