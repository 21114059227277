import { makeEndpointRequest } from '../../../../utils/endpoints';
import { logError } from '../../../../utils/helpers';

/**
 * A simple function used to get the current matrice entries for a storage location.
 * @param {*} _id
 * @param {*} dbInventory
 * @returns
 */

export const getCurrentMatriceEntries = async (_id, dbInventory) => {
    try {
        const match = dbInventory.matrices.find((c) => c._storageLocationId === _id);

        return match ? match.entries : [];
    } catch (err) {
        await logError(`module.processes.getCurrentMatriceEntries`, err);
        return false;
    }
};

/**
 * This function will set the storage location's relationships elements. Is a way to know what elements are used by a storage location.
 * @param {*} storageLocation
 * @param {*} newEntries
 * @returns
 */

export const updateStorageLocationRelationships = async (storageLocation, newEntries) => {
    try {
        let processes = [],
            individuals = [],
            elements = [];

        // we'll simply look at the matrice and extract the unique ones.
        // To be honest in the future we should delete this storage location relationship stuff.

        newEntries.forEach((entry) => {
            if (!processes.includes(entry._processId)) {
                processes.push(entry._processId);
            }

            if (!individuals.includes(entry._individualId)) {
                individuals.push(entry._individualId);
            }

            if (!elements.includes(entry._elementId)) {
                elements.push(entry._elementId);
            }
        });

        await makeEndpointRequest(`UpdateStorageLocation`, {
            _id: storageLocation._id,
            payload: {
                processes,
                elements,
                individuals,
            },
            options: {
                preventUpdatingTimestamp: true, // Don't update the updatedAt timestamp.
            },
        });
    } catch (err) {
        await logError(`module.processes.updateStorageLocationRelationships`, err);
        return false;
    }
};
