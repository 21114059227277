import React, { useState, useEffect, useContext, createContext } from 'react';

// Components
import Container from '../../layout/container';
import Loading from './components/loadingScreen';

//  views
import GeneralView from './views/general';
import EditView from './views/edit';

// Dependencies
import { checkUserFlag, logError, trackUserInteraction } from '../../../utils/helpers';
import { makeEndpointRequest, makeLocalEndpointRequest } from '../../../utils/endpoints';

// Context
const Context = createContext({});
export const PageContext = () => useContext(Context);

const ExportingComponent = () => {
    const [isFilteredByProcess, setIsFilteredByProcess] = useState(true);
    const [editingToolId, setEditingToolId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({
        storageLocations: [],
        processes: [],
        elements: [],
        individuals: [],
    });

    const loadData = async () => {
        try {
            setLoading(true);
            const res = await makeEndpointRequest(`GetDataInventory`, {
                onlyPublishedStorageLocations: false,
                bundleStorageLocations: true,
            });
            setData(res);
            setLoading(false);
        } catch (err) {
            await logError(`LOAD_DATA_INVENTORY`, err);
            setLoading(false);
        }
    };

    useEffect(() => {
        trackUserInteraction(`Personal Data Inventory`);
        loadData();
        // eslint-disable-next-line
    }, []);

    const downloadRopa = async () => {
        try {
            // Check the v2 flag
            const v2Flag = await checkUserFlag('ropa-v2', false);

            await trackUserInteraction(`Selected "Download ROPA"`, {
                v2FlagEnabled: v2Flag,
            });

            // download it..
            const res = await makeLocalEndpointRequest(`/api/documents/ropa?v2=${v2Flag}`, {}, 'GET', null, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ROPA.csv'); // set the file name
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            await logError(`DOWNLOAD_ROPA`, err);
            trackUserInteraction(`Having Difficulties`, { reason: `failed to download ropa` });
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    const ContextProps = {
        downloadRopa,
        // Is filtered..
        setIsFilteredByProcess,
        isFilteredByProcess,
        // Editing a tool
        editingToolId,
        setEditingToolId,
        // Loading
        loading,
        setLoading,
        // Data
        data,
        setData,
    };

    if (loading) {
        return (
            <Container title="Personal Data Inventory" classNames="page-personal-data-inventory">
                <Loading />
            </Container>
        );
    }

    return (
        <Container title="Personal Data Inventory" classNames="page-personal-data-inventory">
            <Context.Provider value={ContextProps}>{editingToolId ? <EditView /> : <GeneralView />}</Context.Provider>
        </Container>
    );
};

export default ExportingComponent;
