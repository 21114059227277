import { Button, TextField } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ModuleState } from '../../../../';

let timerInterval = null;

const lowerCasing = (str) => str.toString().toLowerCase();

const splitSearch = (_val1, _val2) => {
    const val1 = lowerCasing(_val1).replace(/ /g, '');
    const val2 = lowerCasing(_val2).replace(/ /g, '');

    return val1.includes(val2);
};

export const filterListingOptions = ({ source, searchedText, categorySelected, storageLocations }) => {
    let opts = source;

    if (searchedText && searchedText.length > 0) {
        const matches = opts.filter((c) => lowerCasing(c.label).includes(lowerCasing(searchedText)) || splitSearch(c.label, searchedText));

        if (matches.length < 1) return [];

        const restOfCategory = opts
            .filter((c) => c.category === matches[0].category && !matches.find((m) => m.label === c.label))
            .map((opt) => ({ ...opt, _similar: true }));

        return [...matches, ...restOfCategory];
    }

    // We need to mark them to know which ones are in our data inventory and which are not.

    if (storageLocations) {
        opts = opts.map((entry) => {
            const _inStorageLocations = storageLocations.find((e) => e.label.includes(entry.label)) ? true : false;
            return {
                ...entry,
                _inStorageLocations,
            };
        });
    }

    if (categorySelected === 'All' || !categorySelected) return opts;

    return opts.filter((c) => c.category === categorySelected);
};

const Component = (props) => {
    const [input, setInput] = useState('');
    const [categorySelected, setCategorySelected] = useState('All');
    const [selected, setSelected] = useState([]);
    const [searchedText, setSearchedText] = useState('');
    const [closing, setClosing] = useState(false);
    const { data } = ModuleState();

    useEffect(() => {
        if (timerInterval) {
            clearTimeout(timerInterval);
        }

        timerInterval = setTimeout(() => {
            setSearchedText(input);
            timerInterval = null;
        }, 400);
    }, [input]);

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'initial';
        };
    }, []);

    const onToolSelected = (label) => {
        setSelected((currentState) => {
            let arr = [...currentState];
            if (currentState.includes(label)) {
                const indexOf = selected.findIndex((c) => c === label);
                if (indexOf === -1) return false;
                arr.splice(indexOf, 1);
            } else {
                arr.push(label);
            }
            return arr;
        });
    };

    const closeWithAnimation = (func) => {
        setClosing(true);

        setTimeout(() => {
            setClosing(false);
            func();
        }, 550);
    };

    const isSelected = (label) => (selected.includes(label) ? true : false);

    const isToolAlreadyAdded = (label) => {
        if (
            data.storageLocations.find((p) => {
                // Get the unique pLabel which is Hubspot instead of Hubspot (2)
                const pLabel = p.label
                    .replace(/\(\d+\)/g, '')
                    .trim()
                    .toLowerCase();
                const toolLabel = label.toString().toLowerCase().trim();
                return p.archived === false && pLabel === toolLabel;
            })
        )
            return true;
        return false;
    };

    const onCreateCustomTool = (label) => {
        props.onDone(selected, props.meta.options);
        props.createCustomTool(label);
    };

    const AddCustomToolBlock = () => (
        <div className="component-storage-recommendation-entry add-button">
            <div className="content" onClick={() => closeWithAnimation(() => onCreateCustomTool(''))}>
                <div className="left-side">
                    <div className="label">Create a custom tool or third party</div>
                </div>
                <div className="btn">
                    <i className={`elm fa-solid fa-plus`} />
                </div>
            </div>
        </div>
    );

    const getEntries = () => {
        let opts = filterListingOptions({
            source: props.meta.options,
            searchedText,
            categorySelected,
        });
        return opts;
    };
    const EntryBlock = (props) => {
        return (
            <div
                className={`component-storage-recommendation-entry ${isSelected(props.data.label) ? `selected` : `not-selected`}`}
                data-cy="entry"
                onClick={() => onToolSelected(props.data.label)}
            >
                <div className="content">
                    <div className="left-side">
                        {props.data.image ? (
                            <div
                                className="comp-img"
                                style={{
                                    backgroundImage: `url("${props.data.image}")`,
                                }}
                            />
                        ) : (
                            <div className="comp-avatar"> {props.data['label'].charAt(0).toUpperCase()} </div>
                        )}

                        <div className="label">{props.data.label}</div>
                        {isToolAlreadyAdded(props.data.label) && <div className="already-added-badge">Already added</div>}
                    </div>
                    <div className="btn" key={`${props.data.label}-${isSelected(props.data.label) ? `selected` : `not-selected`}`}>
                        <i className={`elm fa-solid ${isSelected(props.data.label) ? `fa-minus` : `fa-plus`}`} />
                    </div>
                </div>
                {isToolAlreadyAdded(props.data.label) && <div className="already-badge-subfooter">Already added</div>}
            </div>
        );
    };

    return (
        <React.Fragment>
            <div data-cy="module-popout" className={`module-popout module-picker-locations ${closing && `closing`}`}>
                <div className="module-content">
                    <div className="module-close" data-cy="module-close">
                        {selected.length > 0 ? <div className="tools-selected">{selected.length} tools selected</div> : null}
                        <div
                            className="done-button"
                            onClick={() =>
                                closeWithAnimation(() => {
                                    props.onDone(selected, props.meta.options);
                                })
                            }
                            data-cy="done-button"
                        >
                            Done
                        </div>
                    </div>
                    <div className="module-title">Add a tool or third party</div>
                    <div className="categories">
                        {props.meta.categories.map((entry, ix) => (
                            <div
                                key={ix}
                                className={`component-entry ${entry === categorySelected && `selected`}`}
                                onClick={() => setCategorySelected(entry === categorySelected ? 'All' : entry)}
                            >
                                {entry}
                            </div>
                        ))}
                    </div>
                    <div className="search-container">
                        <TextField
                            data-cy="search-input"
                            variant="outlined"
                            type="text"
                            fullWidth={true}
                            placeholder="Search for a specific tool.."
                            value={input}
                            onChange={(ev) => setInput(ev.target.value)}
                        />
                    </div>
                    <div className="scrollable-container">
                        <div className="entries" data-cy="inventory-options">
                            {getEntries().length > 0 && searchedText.length < 1 && categorySelected === 'All' && <AddCustomToolBlock />}
                            {getEntries()
                                .filter((elm) => (elm._similar === true ? false : true))
                                .map((entry, ix) => (
                                    <EntryBlock data={entry} key={ix} />
                                ))}
                            {getEntries().length > 0 && (categorySelected !== 'All' || searchedText.length > 0) && <AddCustomToolBlock />}

                            {getEntries().length < 1 && (
                                <div className="no-results">
                                    <div className="heading">No results</div>
                                    <div className="description">
                                        There is no tool or third party matching this name in our list of recommendations.
                                    </div>
                                    <div className="advice">Press the button below to add it as a custom tool or third party.</div>
                                    <Button
                                        data-cy="create-custom-tool-btn"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => closeWithAnimation(() => onCreateCustomTool(searchedText))}
                                    >
                                        Create custom tool
                                    </Button>
                                </div>
                            )}
                        </div>
                        {searchedText.length > 0 && getEntries().length > 0 && getEntries().filter((x) => x._similar === true).length > 0 && (
                            <React.Fragment>
                                <div className="header-subsection">Other similar tools</div>
                                <div className="entries" data-cy="similar-tools-options">
                                    {getEntries()
                                        .filter((x) => x._similar === true)
                                        .map((entry, ix) => (
                                            <EntryBlock data={entry} key={ix} />
                                        ))}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
            <div className="module-shadow-underlay" />
        </React.Fragment>
    );
};

export default Component;
