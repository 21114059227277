import React from 'react';

// Context
import { ModuleState } from '../../../../..';
import { FunctionsContext } from './functions';

// Components
import PickPanel from '../../../../pickPanel';

const Component = () => {
    const { onToolsPicked, onCustomToolCreated, panelController, onPanelClose } = FunctionsContext();
    const { data, options, storageLocationsOptions } = ModuleState();

    // We are not creating tools.
    if (options.pickingTools === false) return null;

    return (
        <PickPanel
            //Important class name
            className="module-picker-locations"
            // Ref to control the panel
            innerRef={panelController}
            // Data Inventory is important to know what tools they have.
            dataInventory={data}
            // When they picked tools
            addTool={onToolsPicked}
            // Options the user can pick storage locations
            options={storageLocationsOptions}
            // When tools are created
            addPickedTools={onToolsPicked}
            createCustomTool={onCustomToolCreated}
            // When they close the panel
            closePanel={onPanelClose}
            // View type will be simple
            viewType="createTools"
        />
    );
};

export default Component;
