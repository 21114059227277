import React from 'react';
import { ComponentState } from '../../..';

const Component = () => {
    const { data } = ComponentState();

    return (
        <React.Fragment>
            <div className="step-entry">
                <div className="step-header">
                    <div className="step-badge">Step 2</div>
                    <div className="step-label">Add it to your Website</div>
                </div>
                <div className="step-content">
                    <div className="step-two-rows">
                        <div className="step-text">
                            {data.isPrivacyPortalHosted ? (
                                <React.Fragment>
                                    Redirect visitors to this link from your website so that they can access your privacy portal. You can
                                    add it to your footer or menu.
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    Paste the code above on the <b>&lt;body&gt;</b> of the page in your website you want your privacy
                                    documentation to appear. If you don't know how to do these, scroll down for our guides.
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
