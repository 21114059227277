import React from 'react';

import { Button } from '@mui/material';

// Context
import { PageContext } from '../../..';
import { useHistory } from 'react-router-dom';

const Component = () => {
    const { downloadRopa } = PageContext();
    const history = useHistory();

    const goBack = () => {
        history.push('/');
    };

    return (
        <React.Fragment>
            <div className="header" style={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                <div className="left-side" style={{ display: 'flex', alignItems: 'center' }}>
                    <div onClick={goBack}>
                        <i className="icon fa-solid fa-chevron-left"></i>
                    </div>

                    <div className="label">Personal data inventory</div>
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" onClick={downloadRopa}>
                        <i className="fa-solid fa-download" style={{ marginRight: 8 }}></i>
                        Records of Processing Activities (ROPA)
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
