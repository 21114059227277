import React from 'react';

// Components
import { TextField } from '@mui/material';

// Context
import { ViewContext } from '../../..';

// Dependencies
import { getValidationPropFields } from '../../../../../../../../utils/helpers';

const Component = () => {
    const { form, updateData, validationKeys, submitted } = ViewContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="label">Name</div>
                <div className="input">
                    <TextField
                        autoFocus
                        id="name"
                        fullWidth
                        disabled={submitted}
                        variant="outlined"
                        placeholder="John Doe"
                        data-cy={`input-name`}
                        value={form.dpoName}
                        onChange={(e) => updateData('dpoName', e.target.value)}
                        {...getValidationPropFields(validationKeys, form.dpoName, `dpoName`)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
