import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Slider, CircularProgress } from '@mui/material';
import { formatNumber, trackUserInteraction } from '../../../../../../utils/helpers';
import Tooltip from '../../../../../components/tooltip';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { ComponentState } from '../../..';
import { AuthStore } from '../../../../../../utils/context/authStore';

const Component = ({ action, update, hideDialog }) => {
    const [submitted, setSubmitted] = useState(false);
    const { data } = ComponentState();
    const { account } = AuthStore();

    const currency = account.currency || 'gbp';

    const freeThresholdSessions = account.companyPackage.usageLimits.cookieBannerDiscountedSessions;

    const [userSessions, setUserSessions] = useState(freeThresholdSessions);

    const takeAction = async (bool) => {
        try {
            setSubmitted(true);
            await trackUserInteraction(`Selected "Confirm"`, {
                to: bool === true ? `Enable feature` : `Disable featue`,
            });
            await update(bool);
            if (data.isDiscountedCompany && bool === true) {
                await trackUserInteraction(`Agreed to be charged extra for Cookie Banner`, {
                    reason: `This user is not supposed to use this feature for free, therefore we need to charge him extra.`,
                });
                await makeEndpointRequest(`ChargeCookieBanner`);
            }
        } catch (err) {
            setSubmitted(false);
        }
    };

    const calculatePriceSession = () => {
        let finalCost = 0;
        let sessions = userSessions;
        const freeThreshold = freeThresholdSessions;
        let chargeableSessions = sessions - freeThreshold; // We discount the first 5000 or 2k if is APPSUMO

        if (sessions <= freeThreshold) {
            finalCost = 0; // If is within their free threshold is free
        } else if (sessions > freeThreshold && sessions <= 20000) {
            let price = getOffersPrice()['Growth'][currency];
            finalCost = price * (chargeableSessions / 1000);
        } else if (sessions > 20000) {
            let price = getOffersPrice()['Unicorn'][currency];

            // First 2,000 (Appsumo) or 5 thousands are free free, therefore the total price is 15,000 sessions at the growth plan price + the number of sessions above 20k at the current unicorn price
            finalCost = (getOffersPrice()['Growth'][currency] * 15000) / 1000 + price * ((chargeableSessions - 15000) / 1000);
        }

        return formatCurrency(finalCost);
    };

    const getOffersPrice = () => {
        return {
            Early: {
                eur: 10, // 10 EUR
                gbp: 8.5, // 8.50
                usd: 10.5, // 10.50
            },
            Growth: {
                eur: 2, // 2
                gbp: 1.7, // 1.70
                usd: 2.1, // 2.10
            },
            Unicorn: {
                eur: 1.2, // 1.20
                gbp: 1.0, // 1.00
                usd: 1.3, // 1.30
            },
        };
    };

    const formatCurrency = (value) => {
        value = value.toFixed(2);
        if (currency === 'gbp') {
            return `£${formatNumber(value)}`;
        } else if (currency === 'eur') {
            return `${formatNumber(value)}€`;
        } else {
            return `$${formatNumber(value)}`;
        }
    };

    const getStepNumber = () => {
        return 1000;
    };

    if (action === null) return null;

    return (
        <React.Fragment>
            <Dialog className="consent-confirmation-modal" open={true} maxWidth="md" fullWidth={true}>
                <DialogTitle data-cy="dialog-title">Cookie Banner</DialogTitle>
                <DialogContent style={{ width: '100%', padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className="heading-area">
                        {action === false && <h4>Are you sure you wanna disable this feature?</h4>}
                        {action === true ? (
                            <React.Fragment>
                                <p style={{ marginBottom: 0 }}>Cookie Banner is an add-on feature.</p>
                                <p style={{ marginTop: 6 }}>
                                    It's automatically configured and updated over time. Once you confirm you will be billed automatically
                                    each month.
                                </p>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <p>
                                    Once disabled, the cookie banner will no longer work on your website. If you decide to re-enable it, you
                                    will have to copy a new code since the previous one will be disabled.
                                </p>
                            </React.Fragment>
                        )}
                    </div>
                    {action === true && (
                        <React.Fragment>
                            <div className="cards">
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-title">Early</div>
                                        <div className={`early-price card-price dashed`}>
                                            {formatCurrency(getOffersPrice()['Early'][currency])}
                                        </div>
                                        <div className="card-description">
                                            First {formatNumber(account.companyPackage.usageLimits.cookieBannerDiscountedSessions)} sessions
                                            per month are free.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-title">Growth</div>
                                        <div className="card-price">
                                            {formatCurrency(getOffersPrice()['Growth'][currency])} per 1,000 sessions per month
                                        </div>
                                        <div className="card-description">
                                            From {formatNumber(account.companyPackage.usageLimits.cookieBannerDiscountedSessions)} to 20,000
                                            sessions
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-title">Unicorn</div>
                                        <div className="card-price">
                                            {formatCurrency(getOffersPrice()['Unicorn'][currency])} per 1,000 sessions per month
                                        </div>
                                        <div className="card-description">From 20,000 sessions</div>
                                    </div>
                                </div>
                            </div>
                            <div className="calculator">
                                <div className="price-container">
                                    {userSessions >= 50000 && <div className="fromText">FROM</div>}
                                    <div className="price">{calculatePriceSession()}</div>
                                </div>

                                <div className="sessions">
                                    {formatNumber(userSessions)}
                                    {userSessions >= 50000 && '+'}
                                    <Tooltip
                                        useHTML={true}
                                        trackInteraction={true}
                                        interactionId={`consent-management:sessions-underline`}
                                        content={
                                            <p>
                                                A session is a new visitor or any user that after 30 minutes on your site reloads the page.
                                            </p>
                                        }
                                    >
                                        {' '}
                                        <span>sessions</span>
                                    </Tooltip>{' '}
                                    a month
                                </div>
                                <div className="input-container">
                                    <Slider
                                        value={userSessions}
                                        onChange={(ev) => setUserSessions(ev.target.value)}
                                        valueLabelDisplay="auto"
                                        step={getStepNumber()}
                                        marks
                                        disabled={submitted}
                                        min={freeThresholdSessions}
                                        valueLabelFormat={(value) => <div>{formatNumber(value)}</div>}
                                        max={50000}
                                    />
                                </div>
                                {/* In the future this will be used again */}
                                {/* <div className="bottom-controllers">
                                    <div className="currency">
                                        {['gbp', 'euro', 'usd'].map((val) => ( // reminder: 'euro' may not work since the correct currency format is 'eur'
                                            <div
                                                key={val}
                                                className={`entry ${currency === val && `selected`}`}
                                                onClick={() => setCurrency(val)}
                                            >
                                                {val}
                                            </div>
                                        ))}
                                    </div>
                                </div> */}
                                <div className="vat-warning">Prices exclude VAT where VAT is applicable</div>
                            </div>
                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <div className="buttons">
                        <Button variant="outlined" disabled={submitted} onClick={hideDialog} data-cy="dialog-dismiss-button">
                            Dismiss
                        </Button>
                        <Button
                            disabled={submitted}
                            variant="contained"
                            color="primary"
                            onClick={() => takeAction(action)}
                            data-cy="dialog-submit-button"
                        >
                            {submitted && <CircularProgress size={16} style={{ marginRight: 8 }} />} Confirm
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
