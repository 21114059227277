export const getFilterFields = (dependencies) => {
    return [
        {
            key: 'details.label',
            label: 'Name',
            type: 'text',
        },
        {
            key: 'details.description',
            label: 'Description',
            type: 'text',
        },
        {
            key: 'details.section',
            label: 'Section',
            type: 'text',
            options: dependencies.sections,
        },
        {
            key: 'details.category',
            label: 'Category',
            type: 'text',
            options: dependencies.categories,
        },
    ];
};
