import React from 'react';
import { PanelContext } from '../index';

import { ButtonBase } from '@mui/material';

const Component = (props) => {
    const { data, isEditing, createIndividual, saveChanges, isLabelUsedByDifferentIndividual } = PanelContext();

    const isPassingMinimumValidation = () => {
        if (isEditing && isLabelUsedByDifferentIndividual()) return false;
        if (!isEditing && data.label.length < 1) return false;
        return true;
    };

    return (
        <React.Fragment>
            <div className="panel-component-actions">
                {isEditing ? (
                    <React.Fragment>
                        {/* Save the changes.. */}
                        <ButtonBase
                            disabled={!isPassingMinimumValidation()}
                            className={`component-btn`}
                            data-cy="btn-save"
                            onClick={saveChanges}
                        >
                            <i className="elm fa-solid fa-floppy-disk"></i>
                        </ButtonBase>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <ButtonBase
                            disabled={!isPassingMinimumValidation()}
                            className={`component-btn`}
                            data-cy="btn-save"
                            onClick={createIndividual}
                        >
                            <i className="elm fa-solid fa-floppy-disk"></i>
                        </ButtonBase>
                    </React.Fragment>
                )}

                <ButtonBase className="component-btn" onClick={props.onClose} data-cy="btn-close">
                    <i className="elm fa-solid fa-xmark"></i>
                </ButtonBase>
            </div>
        </React.Fragment>
    );
};

export default Component;
