import React from 'react';
import Container from '../../layout/container';
import { Button } from '@mui/material';

const PageNotFound = props => {
    return (
        <Container guestLayout={true} title={`Page not found`} classNames="page-not-found">
            <div className="page-container">
                <div className="page-content">
                    <i className="icon-elm fa-thin fa-circle-exclamation"></i>
                    <div className="title">Page not found</div>
                    <div className="message">We can't seem to find the page you're looking for.</div>
                    <Button variant="contained" color="primary" onClick={() => props.history.push('/')}>
                        Go to homepage
                    </Button>
                </div>
            </div>
        </Container>
    );
};

export default PageNotFound;
