import { Button, Switch, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModuleState } from '..';
import { trackUserInteraction } from '../../../../utils/helpers';

import { renderText } from '../../dataProcessingAgreement/routes/securityMeasures/utils';
import Tooltip from '../../../components/tooltip';

const Component = (props) => {
    const [expanded, setExpanded] = useState(false);
    const { responses, updateSecurityMeasureResponse } = ModuleState();
    const [adjusting, setAdjusting] = useState({
        state: false,
        value: '',
    });

    const startAdjusting = () => {
        setAdjusting({
            state: true,
            value: getText(),
        });
    };

    const saveAdjusting = () => {
        updateSecurityMeasureResponse(props.data._id, props.data.source, 'adjustedDescription', adjusting.value || '');

        setAdjusting({
            state: false,
            value: '',
        });
    };

    const stopAdjusting = () => {
        setAdjusting({
            state: false,
            value: '',
        });
    };

    const resetAdjusting = () => {
        updateSecurityMeasureResponse(props.data._id, props.data.source, 'adjustedDescription', '');
    };

    const getAdjustedText = () => {
        const response = responses.find((c) => c._id === props.data._id && c.source === props.data.source);
        if (response && response.adjustedDescription && response.adjustedDescription.length > 0) return response.adjustedDescription;
        return null;
    };

    const getText = () => {
        const adjustedText = getAdjustedText();
        if (adjustedText) return adjustedText;
        return props.data.details.description;
    };

    const history = useHistory();

    const onEdit = async () => {
        trackUserInteraction(`Selected "Edit"`, { data: props.data });
        history.push(`/security-measures/edit/${props.data._id}`);
    };

    const getCurrentValue = () => {
        const match = responses.find((x) => x._id === props.data._id && x.source === props.data.source);
        return match ? match.value : false;
    };

    const switchValue = () => updateSecurityMeasureResponse(props.data._id, props.data.source, 'value', !getCurrentValue());

    return (
        <div className="entry">
            <div className="header">
                <div className="left-side">
                    <div className="input">
                        <Switch checked={getCurrentValue()} onClick={switchValue} />
                    </div>
                </div>
                <div className="right-side" onClick={() => setExpanded(!expanded)}>
                    <div className="label">{props.data.details.label}</div>
                    {props.data.source === 'database' && (
                        <Tooltip content="Custom security measure created by you">
                            <div className="custom star">
                                <i className="fas fa-database"></i>
                            </div>
                        </Tooltip>
                    )}

                    <div className="toggler">
                        {expanded ? <i className="icon fa-regular fa-chevron-up"></i> : <i className="icon fa-regular fa-chevron-down"></i>}
                    </div>
                </div>
            </div>
            <div className={`content ${expanded ? 'expanded' : 'not-expanded'}`}>
                {adjusting.state === false ? (
                    <React.Fragment>
                        <div className="description" dangerouslySetInnerHTML={{ __html: renderText(getText()) }} />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <TextField
                            multiline={true}
                            rows={3}
                            variant="outlined"
                            fullWidth={true}
                            value={adjusting.value}
                            onChange={(ev) => setAdjusting((curr) => ({ ...curr, value: ev.target.value }))}
                        />
                    </React.Fragment>
                )}
                {props.data.source === 'database' ? (
                    <div className="edit-button-area">
                        <Button variant="contained" color="primary" size="small" onClick={onEdit}>
                            Edit this security measure
                        </Button>
                    </div>
                ) : (
                    <div className="edit-button-area">
                        {adjusting.state === true ? (
                            <React.Fragment>
                                <Button variant="contained" color="primary" size="small" onClick={stopAdjusting}>
                                    Stop Adjusting
                                </Button>
                                <Button variant="contained" color="primary" size="small" style={{ marginLeft: 8 }} onClick={saveAdjusting}>
                                    Save Changes
                                </Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Button variant="contained" color="primary" size="small" onClick={startAdjusting}>
                                    Adjust recommended text
                                </Button>
                                {getText() !== props.data.details.description && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: 8 }}
                                        onClick={resetAdjusting}
                                    >
                                        Reset to Recommended Text
                                    </Button>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                )}
                <div className="tags">
                    <div className="tag">{props.data.details.section}</div>
                    <div className="tag">{props.data.details.category}</div>
                </div>
            </div>
        </div>
    );
};

export default Component;
