export const personalLegalBasis = [
    { string: 'Consent', gdpr: 'gdpr-6.1.a' },
    { string: 'Legitimate Interest', gdpr: 'gdpr-6.1.f' },
    { string: 'Contractual Obligations', gdpr: 'gdpr-6.1.b' },
    { string: 'Legal Obligations', gdpr: 'gdpr-6.1.c' },
    { string: 'Vital Interests', gdpr: 'gdpr-6.1.d' },
    { string: 'Public Interest', gdpr: 'gdpr-6.1.e' },
];

export const sensitiveLegalBasis = [
    { string: 'Consent', gdpr: 'gdpr-9.2.a' },
    { string: 'Legal Obligation (Employment/social security & protection)', gdpr: 'gdpr-9.2.b' },
    { string: 'Vital Interests', gdpr: 'gdpr-9.2.c' },
    { string: 'Legitimate Interests (Only for non-profits)', gdpr: 'gdpr-9.2.d' },
    { string: 'Publicly Available Data', gdpr: 'gdpr-9.2.e' },
    { string: 'Judicial Obligations', gdpr: 'gdpr-9.2.f' },
    { string: 'Public Interest', gdpr: 'gdpr-9.2.g' },
    { string: 'Provision of Health/Social Care', gdpr: 'gdpr-9.2.h' },
    { string: 'Public Health', gdpr: 'gdpr-9.2.f' },
    { string: 'Public Interest, Scientific or Historical Archiving', gdpr: 'gdpr-9.2.j' },
];

export const legalResponsibilityRoles = ['Controller', 'Processor', 'Joint Controller'];
