import React from 'react';

import { Avatar } from '@mui/material';
import { PanelContext } from '../..';

const Component = () => {
    const { initialData, recommendations } = PanelContext();

    return (
        <React.Fragment>
            <React.Fragment>
                <div className="component-subtitle">
                    <Avatar className="avatar" src={recommendations ? recommendations.image : undefined}>
                        {initialData.label[0]}
                    </Avatar>
                    <div className="label">{initialData.label}</div>
                </div>
            </React.Fragment>
        </React.Fragment>
    );
};

export default Component;
