import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthStore } from '../../../utils/context/authStore';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { logError, trackUserInteraction } from '../../../utils/helpers';
import { getSubProcessorsMissing } from './components/functions';

// Components
import Container from '../../layout/container';
import Table from './components/table';
import Modal from './components/modal';
import Start from './components/start';

const Component = () => {
    const { globalFlags } = AuthStore();
    const history = useHistory();

    const [data, setData] = useState([]);
    const [subProcessors, setSubProcessors] = useState([]);

    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState(null);
    const [creating, setCreating] = useState(false);

    useEffect(() => {
        // Load the data..
        loadData();

        // eslint-disable-next-line
    }, []);

    const refreshSubProcessors = async () => {
        try {
            // Get the list of sub-processors
            const res2 = await makeEndpointRequest('GetSubProcessors');
            setSubProcessors(res2);
        } catch (err) {
            throw err;
        }
    };

    const loadData = async () => {
        try {
            setLoading(true);

            // Get the DPAs
            const res = await makeEndpointRequest('GetListDPAs');

            // Get sub processors
            await refreshSubProcessors();

            setLoading(false);
            setData(res);
        } catch (err) {
            await logError(`LOAD_DPAS`, err);
            setLoading(false);
            setData(null);
        }
    };

    const promptDownloadIsOutdated = async (dpa, missingTools) => {
        try {
            trackUserInteraction(`Outdated DPA`, { _id: dpa._id, missing: missingTools });
            // Format the content message..
            const content = (
                <React.Fragment>
                    <p>Since this DPA was last submitted new sub-processors have been added:</p>
                    <ul style={{ textAlign: 'left' }}>
                        {missingTools.map((c) => (
                            <li key={c}>{c}</li>
                        ))}
                    </ul>
                    <p>You can edit to update your DPA or download it without changes.</p>
                </React.Fragment>
            );

            const buttons = [
                {
                    text: 'Download without Changes',
                    fullWidth: true,
                    onClick: async ({ dismissAlert }) => {
                        trackUserInteraction(`Selected "Download"`);
                        dismissAlert();
                        onEntityDownloaded(dpa, true);
                    },
                },
                {
                    text: 'Update DPA',
                    fullWidth: true,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        trackUserInteraction(`Selected "Review"`);

                        await localStorage.setItem(`@dpa.bypassPromptMissingTools`, true);

                        history.push(`/data-processing-agreements/${dpa._id}/sub-processors?reviewMissingSubProcessors=true`);
                    },
                },
            ];

            window.showAlert(`Warning`, content, `warning`, buttons);
        } catch (err) {
            await logError(`dpa.promptDownloadIsOutdated`, err);
        }
    };

    const onEntityDownloaded = async (entity, skipMissingCheck = false) => {
        try {
            // Access the data easily.
            const data = entity.data.published;

            // The only ones that marking tools as sub processors is this one.
            if (
                data.parties.sender.role === 'processor' &&
                data.parties.receiver.role === 'controller' &&
                globalFlags.missingSubProcessors // needs flag for this check.
            ) {
                // Check if it should be updated.
                const isMissing = await getSubProcessorsMissing(entity, subProcessors);

                // If is really missing..
                if (isMissing.length > 0 && skipMissingCheck === false) {
                    promptDownloadIsOutdated(entity, isMissing);
                    return false;
                }
            }

            await trackUserInteraction(`Selected "Download DPA`, {
                id: entity._id,
                link: entity.downloadLink,
            });

            // Download it
            const link = document.createElement('a');
            link.href = entity.downloadLink;
            link.setAttribute('download', 'Data Processing Agreement.docx'); // set the file name
            document.body.appendChild(link);
            link.click();

            // Remove the element after the download
            document.body.removeChild(link);
        } catch (err) {
            await logError(`DOWNLOAD_DPA`, err);
        }
    };

    const onEntityDelete = async (entity) => {
        window.showAlert('Confirmation', `Are you sure you want to delete this DPA? This is not reversible.`, 'warning', [
            {
                text: 'Cancel',
                dataCy: `alert-cancel-button`,
                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },
            {
                text: 'Confirm',
                dataCy: `alert-submit-button`,
                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                    try {
                        await trackUserInteraction(`Selected "Delete DPA"`, { _id: entity._id });
                        await makeEndpointRequest(`DeleteDPA`, { _id: entity._id });
                        loadData();
                    } catch (err) {
                        await logError(`DELETE_DPA`, err, entity._id);
                        await trackUserInteraction(`Having Difficulties`, { reason: 'Failed to delete A DPA' });
                    }
                },
            },
        ]);
    };

    const onEntitySelect = async (entity) => {
        try {
            await trackUserInteraction(`Selected "Edit DPA`, {
                id: entity._id,
            });
            history.push(`/data-processing-agreements/${entity._id}/start`);
        } catch (err) {
            await logError(`DPA_SELECT`, err);
        }
    };

    const onEditSelected = async (entity) => {
        trackUserInteraction(`Selected "Edit DPA"`, { _id: entity._id });
        setEditData({
            creating: false,
            environment: entity.data.published ? 'published' : 'draft',
            _id: entity._id,
            payload: {
                name: entity.name,
            },
        });
    };

    const onEditSubmitted = async () => {
        try {
            await makeEndpointRequest(`EditDPA`, {
                _id: editData._id,
                payload: {
                    name: editData.payload.name,
                },
            });
            await loadData(); // refresh
            setEditData(null);
        } catch (err) {
            await logError(`EDIT_DPA`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    const onCreateDPA = async (params) => {
        try {
            const res = await makeEndpointRequest('CreateDPA', {
                name: params.name,
                _cloneId: params._cloneId,
            });

            history.push(`/data-processing-agreements/${res._id}/start`);
        } catch (err) {
            await logError(`CREATE_DPA`, err);
        }
    };

    return (
        <Container title="Data Processing Agreements" classNames="page-dpa-listing">
            <div className="page-header">
                <div className="label">Your Data Processing Agreements</div>
                <div className="right-side">
                    <Button variant="contained" color="primary" disabled={loading} onClick={() => setCreating(true)}>
                        Create DPA
                    </Button>
                </div>
            </div>
            <Table
                data={data}
                loading={loading}
                onEdit={onEditSelected}
                onSelect={onEntitySelect}
                onDelete={onEntityDelete}
                onDownload={onEntityDownloaded}
            />
            {editData !== null && (
                <Modal editData={editData} setEditData={setEditData} onSubmit={onEditSubmitted} onDismiss={() => setEditData(null)} />
            )}
            {creating && <Start data={data} onSubmit={onCreateDPA} onDismiss={() => setCreating(false)} />}
        </Container>
    );
};

export default Component;
