/**
 *
 * @param {*} storageLocations (Data Inventory model)
 * @param {*} filters
 * @returns The data inventory filtered right.
 */

import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { capitalizeFirstLetter, logError } from '../../../../../../utils/helpers';
import { applyFilters } from '../../../../../components/dataControls/filters/utils/functions';
import {
    createCustomStorageLocation,
    createStorageLocationWithRecommendations,
} from '../../../../gdprEssentials/steps/storageLocations/utils/helpers';

/**
 *
 * @param {*} dataInventory Data Inventory
 * @param {*} filters Filters set by the users
 * @param {*} fields The fields filtered and their properties
 * @returns Storage locations filtered
 */

export const getFilteredStorageLocations = (inventory, filters, fields, dataProcessingAgreements) => {
    try {
        // We format them temporarily like this so we can apply the filters on them.
        const formattedStorages = inventory.storageLocations.map((c) => {
            // The filter API expects an array of processes with a label property.
            let processes = c.processes.map((c) => inventory.processes.find((d) => d._id === c) || null).filter((c) => c !== null);
            let elements = c.elements.map((c) => inventory.elements.find((d) => d._id === c) || null).filter((c) => c !== null);
            let individuals = c.individuals.map((c) => inventory.individuals.find((d) => d._id === c) || null).filter((c) => c !== null);

            // Check if this DPA is used by any DPAs.
            const isUsedInDPA = dataProcessingAgreements.find((g) => {
                let match = g.data.draft.subProcessors.find((d) => d.label === c.label);
                return match ? true : false;
            });

            let storageLoc = {
                _id: c._id,
                label: c.label,
                processes,
                dataElements: elements,
                individuals: individuals,
                dataResidency: c.dataResidency,
                securityMeasures: c.securityMeasures,
                isSubProcessor: isUsedInDPA || c.isSubProcessor ? 'Yes' : 'No',
            };

            return storageLoc;
        });

        // We now apply filters
        const filteredLocations = applyFilters(formattedStorages, filters, fields);

        // Now we will map them back to the original format after the filters were applied
        const mappedFilteredLocations = filteredLocations.map(({ _id }) => {
            const match = inventory.storageLocations.find((c) => c._id === _id);
            return match || null;
        });

        // eslint-disable-next-line
        return mappedFilteredLocations;
    } catch (err) {
        logError(`thirdParties.getFilteredStorageLocations`, err);
        return [];
    }
};

/**
 *
 * @param {*} storageLocations
 * @returns The storage locations grouped into arrays.
 */

export const groupStorageLocations = (storageLocations, options) => {
    try {
        // This array will hold the groups.
        let groups = [];

        // Get the missingInformation
        const missingInformationEntries = storageLocations.filter((c) => c.archived === false && c.validated === false);

        if (missingInformationEntries.length > 0) {
            groups.push({ id: 'missingInformation', entries: missingInformationEntries });
        }

        // Get the valid tools
        const validEntries = storageLocations.filter((c) => c.archived === false && c.validated === true);

        if (validEntries.length > 0) {
            groups.push({ id: 'validated', entries: validEntries });
        }

        // Get the archived tools
        const archivedEntries = storageLocations.filter((c) => c.archived === true);

        if (archivedEntries.length > 0 && options.showArchived === true) {
            groups.push({ id: 'archived', entries: archivedEntries });
        }

        return groups;
    } catch (err) {
        logError(`thirdParties.groupStorageLocations`, err);
        return [];
    }
};

export const formatCreatedToolName = (newLabel, storageLocations) => {
    try {
        // Capitalize..
        newLabel = capitalizeFirstLetter(newLabel);

        // Get the proper number of tools with this exact name
        const entries = storageLocations.filter((elm) => {
            // Transform Xero (2) into Xero
            const modifiedElmLabel = elm.label
                .replace(/\(\d+\)/g, '')
                .trim()
                .toLowerCase();

            // Just lowercasing..
            const modalChangedLabel = newLabel.trim().toLowerCase();
            return modifiedElmLabel === modalChangedLabel;
        });

        if (entries.length > 0) {
            return `${newLabel} (${entries.length + 1})`;
        }

        return newLabel;
    } catch (err) {
        logError(`thirdParties.formatCreatedToolName`, err);
        throw err;
    }
};

/**
 * This function will create the javascript objects of tools that we will later send to the API to create tools in our data inventory.
 * Is a useful way to create tools in bulk.
 * @param {*} params  - Params of dependencies
 * @param {*} params.toolLabels - An array of tools that we'll try to find recommendations for
 * @param {*} params.dataInventory - The current data inventory
 * @param {*} params.companyData - Current company data
 * @param {*} params.vitalRecommendations - Vital recommendations
 * @param {*} companyData - response from company data api
 */

export const createRecommendedStorageLocationsInBulk = async (params) => {
    try {
        const { toolLabels, companyData, dataInventory, vitalRecommendations } = params;

        // Get recommendations for these labels
        let specificRecommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
            locations: toolLabels,
            processes: [],
        });

        // The array that will hold them.
        let tools = [];
        let processesDependencies = [];

        // Iterate and format tools.
        for (const labelName of toolLabels) {
            try {
                // Format the storage accordingly.
                const tool = createStorageLocationWithRecommendations({
                    toolName: labelName,
                    specificRecommendations,
                    vitalRecommendations,
                    targetAudience: companyData.targetAudience,
                    individualsReferring: companyData.individualsReferring,
                });

                if (!tool) continue; // Failed.

                // Get the recommendation.
                const rec = specificRecommendations.storageLocations.find((c) => c.label === tool.label);

                let entry = {
                    ...tool,
                    label: formatCreatedToolName(tool.label, dataInventory.storageLocations),
                    origin: `recommendations`,
                };

                // We have recommendations received..
                if (rec) {
                    entry.dpaType = rec.dpaType || null;

                    // Extract the process recommendations
                    const toolProcesses = rec.processRecommendations
                        .map((c) => specificRecommendations.processes.find((d) => d.label === c))
                        // Making sure there's no undefined by mistake.
                        .filter((c) => c !== undefined)
                        // We now format it for the back-end.
                        .map((c) => ({
                            processName: c.label,
                            ...formatStringLegalToObject(c),
                            legalResponsibility: c.legalResponsibility || null,
                        }));

                    // Add the unique processes to the overall list of processes that must be created by the back-end.
                    processesDependencies = [
                        ...processesDependencies,
                        ...toolProcesses.filter((c) => !processesDependencies.find((d) => d.processName == c.processName)),
                    ];
                }

                tools.push(entry);
            } catch (err) {
                await logError(`createRecommendedStorageLocationsInBulk.iteratedLabel`, err, { labelName });
            }
        }

        return { tools, processesDependencies };
    } catch (err) {
        await logError(`createRecommendedStorageLocationsInBulk`, err, { storageLocationLabels });
    }
};

/**
 * A simple function that will take the objects created by createRecommendedStorageLocationsInBulk and create them using the inventory API.
 * @param {*} storageLocations
 * @param {*} processesDependencies - An array of process dependencies matching to Session scheme.
 * @returns An array of tools of the tools created or the whole data inventory.
 */

export const createStorageLocationsInDataInventory = async (storageLocations, processesDependencies = []) => {
    try {
        // Create the tools picked
        const dataInventoryUpdated = await makeEndpointRequest(`CreateStorageLocations`, {
            entries: storageLocations,
            processesDependencies,
        });

        // Get the storage locations that we just created
        const createdTools = dataInventoryUpdated.storageLocations.filter((c) => {
            // Was this a storage location just created?
            const match = storageLocations.find((d) => `${d._storageLocationId}` === `${c._storageLocationId}`);
            if (match) return true;
            return false;
        });

        return { createdTools, dataInventoryUpdated };
    } catch (err) {
        await logError(`createStorageLocationsInDataInventory`, err, { storageLocations });
        return false;
    }
};

export const createCustomStorageLocationInDataInventory = async (params) => {
    try {
        const { customTool, vitalRecommendations, companyData, dataInventory } = params;

        // Variables required
        let toolFormatted = null;
        let dataObject = {};

        // If this custom tool is similar to a tool
        if (customTool.similarData) {
            // Get recommendations for these labels
            let specificRecommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: [customTool.similarData.label],
                processes: [],
            });

            // format..
            toolFormatted = createStorageLocationWithRecommendations({
                toolName: customTool.similarData.label,
                specificRecommendations,
                vitalRecommendations,
                targetAudience: companyData.targetAudience,
                individualsReferring: companyData.individualsReferring,
            });
        }

        // Format the label accordingly.
        let label = formatCreatedToolName(customTool.label, dataInventory.storageLocations);

        // If there is no formatted matching similar to.
        if (!toolFormatted) {
            dataObject = {
                ...createCustomStorageLocation(label),
                origin: `manual`,
            };
        } else {
            dataObject = {
                ...toolFormatted,
                label,
                // Reset these...
                securityMeasures: '',
                dataResidency: '',
                image: '',
                // Cookie info
                cookieExternalInfo: '',
                cookiePurpose: '',
                cookieType: '',
                //
                origin: 'manual',
            };
        }

        // Create the tools picked
        const dataInventoryUpdated = await makeEndpointRequest(`CreateStorageLocations`, { entries: [dataObject] });
        return { dataInventoryUpdated, label };
    } catch (err) {
        await logError(`createCustomStorageLocationInDataInventory`, err);
        return null;
    }
};

/**
 * A simple function that is required when needing to converting recommendations from airtable into actual objects that could be used.
 * @param {*} recommendation
 * @returns
 */

export const formatStringLegalToObject = (recommendation) => {
    try {
        // Extracting..
        const art6 = recommendation.article6.split('~');
        const art9 = recommendation.article9.split('~');

        // Format the data
        const personalLegalBasis = { string: art6[0] ? art6[0].trim() : '', gdpr: art6[1] ? art6[1].trim() : '' };
        const sensitiveLegalBasis = art9[1] ? { string: art9[0] ? art9[0].trim() : '', gdpr: art9[1] ? art9[1].trim() : '' } : null;

        return { personalLegalBasis, sensitiveLegalBasis };
    } catch (err) {
        logError(`formatStringLegalToObject`, err, { input });
        throw err;
    }
};

/**
 * A reusable function that can take care of deleting the waste entities.
 * @param {*} entities
 * @returns
 */
export const deleteWasteEntities = async (entities) => {
    try {
        const { processes = [], storageLocations = [], individuals = [], elements = [] } = entities;

        // Deleting incomplete storage locations created and then removed to avoid waste.
        for (const storageLocation of storageLocations) {
            try {
                // Delete it using the API
                await makeEndpointRequest(`DeleteStorageLocation`, { _storageLocationId: storageLocation });
            } catch (err) {
                await logError(`deleteWasteEntities.storageLocationIterated`, err, { storageLocation });
            }
        }

        // Deleting incomplete processes created and then removed to avoid waste.
        for (const process of processes) {
            try {
                // Delete it using the API
                await makeEndpointRequest(`DeleteProcess`, { _id: process });
            } catch (err) {
                await logError(`deleteWasteEntities.processIterated`, err, { process });
            }
        }

        // Deleting incomplete elements created and then removed to avoid waste.
        for (const element of elements) {
            try {
                // Delete it using the API
                await makeEndpointRequest(`DeleteElement`, { _id: element });
            } catch (err) {
                await logError(`deleteWasteEntities.elementIterated`, err, { element });
            }
        }

        // Deleting incomplete individuals created and then removed to avoid waste.
        for (const individual of individuals) {
            try {
                // Delete it using the API
                await makeEndpointRequest(`DeleteIndividual`, { _id: individual });
            } catch (err) {
                await logError(`deleteWasteEntities.individualIterated`, err, { individual });
            }
        }
    } catch (err) {
        await logError(`deleteWasteEntities`, err, { entities });
        return false;
    }
};
