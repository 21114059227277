import React from 'react';

// Context
import { SetProcessesContext } from '..';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// Components
import AutoComplete from '../../../../../../../../pages/thirdParties/components/editPanel/components/autocomplete';

const Component = () => {
    const { options, closeModal, currentData, addProcess, removeProcess, textContents } = SetProcessesContext();

    return (
        <React.Fragment>
            <Dialog open={true} maxWidth="md" fullWidth>
                <DialogTitle data-cy="dialog-title">
                    <Typography variant="h6">{textContents.heading}</Typography>
                    <Typography variant="subtitle2">{textContents.description}</Typography>
                </DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <AutoComplete
                        // The value and options
                        value={currentData}
                        options={options}
                        dataCy="autocomplete-setProcesses"
                        // Props to the autocomplete..
                        groupCategories={true}
                        placeholder={`Select data processes here..`}
                        // Callbacks when data is changed
                        onEntityAdded={addProcess}
                        onEntityRemoved={removeProcess}
                        // Props
                        notCreatable={false}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} variant="outlined" data-cy="dialog-done-button">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
