import React from 'react';

// Components
import { TextField } from '@mui/material';
import { getValidationPropFields } from '../../../../../../../utils/helpers';

// Context
import { CustomToolContext } from '..';

const Component = () => {
    const { validationResults, data, setData } = CustomToolContext();

    return (
        <React.Fragment>
            <div className="form-group" style={{ marginBottom: 18 }}>
                <div className="label">Tool or 3rd party Name</div>
                <div className="input">
                    <TextField
                        autoFocus
                        id="tool-name"
                        fullWidth
                        variant="standard"
                        placeholder="DigitalOcean"
                        value={data.label}
                        onChange={(e) => setData((cs) => ({ ...cs, label: e.target.value }))}
                        data-cy="dialog-input"
                        {...getValidationPropFields(validationResults, data.label.length > 0, `label`)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
