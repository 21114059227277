import React, { useEffect, useState, useContext, createContext } from 'react';

import { trackUserInteraction, logError } from '../../../utils/helpers';

// Dependencies
import Container from '../../layout/container';
import { AuthStore } from '../../../utils/context/authStore';

// Components
import PrivacyPortal from './components/privacy_portal';
import Shareable from './components/shareable';
import CookieBanner from './components/cookie_banner';
import Audits from './components/audits';
import Header from './components/header';
import SuggestedTools from './components/suggestedTools';
import Steps from './components/steps';
import QuestionsAssigned from './components/questionsAssigned';
import IncompleteDataInventory from './components/incompleteDataInventory';

// Dependencies
import PersonalDataInventory from './components/personal_data_inventory';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { Button } from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const Context = createContext({});
export const HomepageContext = () => useContext(Context);

export default function Homepage() {
    const { account, globalFlags } = AuthStore();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState({
        companyProgress: {
            value: 0,
            nextSteps: [],
        },
        implementations: {},
        personalDataInventory: {
            locations: [],
            subjects: [],
            elements: [],
            processes: [],
        },
        // The actual data inventory not the fake one above.
        dataInventory: {
            matrices: [],
            processes: [],
            storageLocations: [],
            elements: [],
            individuals: [],
        },
        certificates: [],
        pendingScans: [],
    });

    const [flags, setFlags] = useState({});
    const history = useHistory();
    const appNotLicensed = account.workspace.licensed === false;

    const loadFlags = async () => {
        try {
            const newFlags = {
                ...flags,
            };
            setFlags(newFlags);
            return newFlags;
        } catch (err) {
            await logError(`FAILED_CHECK_FLAGS`, err, { page: '/' });
        }
    };

    const loadData = async () => {
        try {
            // Redirecting to the right page..
            if (appNotLicensed) {
                history.replace('/choose-your-plan');
                return false;
            }
            setLoading(true);
            const response = await makeEndpointRequest('getDashboardDetails');

            const { scans: pendingScans } = await makeEndpointRequest('GetCompanyScans');

            // @Reminder: The api below is also used by GEL Flow to get the scans.
            const implementations = await makeEndpointRequest('GetImplementationsChecks');
            const flags = await loadFlags();
            const companyProgress = await makeEndpointRequest('GetCompanyScoreAndSteps', {
                payload: {
                    pendingScans,
                    flags,
                },
            });

            // Load data inventory
            const dataInventory = await makeEndpointRequest(`GetDataInventory`, {
                onlyPublishedStorageLocations: false,
                bundleStorageLocations: false,
            });

            setData({
                ...response,
                pendingScans,
                implementations,
                companyProgress,
                dataInventory,
            });

            setLoading(false);
        } catch (err) {
            if (err.message && err.message.includes(`User is not authorized to view the dashboard`)) {
                // seeing dashboard for first time
                setLoading(false);
                setError(false);
                return false;
            }
            await logError(`LOAD_DASHBOARD`, err);
            setLoading(false);
            setError(true);
        }
    };

    const additionalActions = async () => {
        try {
            if (appNotLicensed) return false; // No point in checking that at this point.
            await makeEndpointRequest('checkPrivacyPortal');
            // Syncs data to hubspot
            await makeEndpointRequest('getHSData');
        } catch (err) {
            if (err.message && err.message.includes(`User is not authorized to view the dashboard`)) {
                return false;
            }
            await logError(`DASHBOARD_ADDITIONAL_ACTIONS`, err);
        }
    };

    useEffect(() => {
        loadData();
        additionalActions();
        trackUserInteraction(`Dashboard`);
        if (account.workspace.licensed === false) {
            trackUserInteraction(`Paywall Encountered`);
        }
        // eslint-disable-next-line
    }, []);

    const isDataInventoryExpired = () => {
        const gel = data.certificates.find((x) => x.type === 'gdpr-essentials');
        if (!gel) return false;
        return {
            expiresAt: gel.dateNextVerify,
            expired: gel.expired,
        };
    };

    const renewsInventory = async () => {
        await trackUserInteraction(`Selected "Renew data inventory`);
        history.push(`/stages/gdpr-essentials/edit?refresh=true`);
    };

    const reviewTools = async () => {
        await trackUserInteraction(`Selected "Review tools`);
        history.push(`/stages/gdpr-essentials/edit/data-inventory`);
    };

    return (
        <Container title="Dashboard" classNames={`page-dashboard-old`}>
            <Context.Provider value={{ loading, error, data, flags, reviewTools }}>
                <Header data={data} />
                <SuggestedTools pendingScans={data.pendingScans} />
                <QuestionsAssigned />
                <IncompleteDataInventory data={data} />
                {isDataInventoryExpired() && isDataInventoryExpired().expired === true && (
                    <div className="notification-dashboard-box red">
                        <div className="title">
                            On {moment(isDataInventoryExpired().expiredAt).format('DD MMM yyyy')} your GDPR Essentials certificate expired
                        </div>
                        <div className="content-box">
                            You can re-validate it by updating your <b>Personal Data Inventory</b>. Check if you need to remove, add or
                            update any information.
                            <br />
                            <br />
                            Keeping your information up to date shows your stakeholders your commitment to them. In most cases, this only
                            takes 5 minutes to complete.
                        </div>
                        <div className="cta-button">
                            <Button variant="outlined" color="primary" onClick={renewsInventory}>
                                Renew your data inventory
                            </Button>
                        </div>
                    </div>
                )}

                <div className="page-sides">
                    <div className="left-side">
                        <PersonalDataInventory />
                    </div>
                    <div className="right-side">
                        <PrivacyPortal />
                        <Shareable />
                        <CookieBanner />
                        {globalFlags.audits ? <Audits /> : null}
                    </div>
                    <Steps />
                </div>
            </Context.Provider>
        </Container>
    );
}
