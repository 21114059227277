import React from 'react';
import { PickContext } from '..';
import { OnboardingContext } from '../../../../..';

// Components
import PickPanel from '../../../../../../thirdParties/components/pickPanel';

const Component = () => {
    const { pickTools, onPanelClose, panelController, onToolsPicked, onCustomToolCreated } = PickContext();
    const { dataInventory, toolsRecommended } = OnboardingContext();

    if (!pickTools) return null;

    return (
        <PickPanel
            //Important class name
            className="module-picker-locations"
            // Ref to control the panel
            innerRef={panelController}
            // Data Inventory is important to know what tools they have.
            dataInventory={dataInventory}
            // When they picked tools
            addTool={onToolsPicked}
            // Options the user can pick storage locations
            options={toolsRecommended}
            // When tools are created
            addPickedTools={onToolsPicked}
            createCustomTool={onCustomToolCreated}
            // When they close the panel
            closePanel={onPanelClose}
            // View type will be simple
            viewType="createTools"
        />
    );
};

export default Component;
