import { FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';

// Context
import { PanelContext } from '../../..';
import { ViewContext } from '..';

const Intervals = ['Days', 'Months', 'Years'];

const Component = () => {
    const { data, setData } = PanelContext();
    const { validationResults } = ViewContext();

    const setValue = (value) => {
        setData((currentState) => {
            let newState = { ...currentState };

            newState.retention.specificTime.value = value;

            return newState;
        });
    };

    const setIntervalValue = (value) => {
        setData((currentState) => {
            let newState = { ...currentState };

            newState.retention.specificTime.interval = value;

            return newState;
        });
    };

    const getErrorMessage = () => {
        if (validationResults['retention.specificTime.value']) return validationResults['retention.specificTime.value'];
        if (validationResults['retention.specificTime.interval']) return validationResults['retention.specificTime.interval'];

        return null;
    };

    // We don't render if that is true.
    if (data.retention.type !== 'manual:specificTime') return null;

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Specific Data Retention</div>
                </div>
                <div className="component-description">
                    <div className="text">How long is the data for the purpose of {data.label} stored for?</div>
                </div>
                <div className="component-content">
                    <div className="inputs" style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            value={data.retention.specificTime.value}
                            type="number"
                            data-cy="input-specificTime"
                            onChange={(ev) => setValue(parseInt(ev.target.value))}
                            variant="standard"
                            style={{ marginRight: 8 }}
                        />
                        <Select
                            value={data.retention.specificTime.interval}
                            onChange={(e) => setIntervalValue(e.target.value)}
                            variant="standard"
                            data-cy="selector-specificTime"
                        >
                            {Intervals.map((c, index) => (
                                <MenuItem value={c} key={index}>
                                    {c}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    {/* Error */}
                    {getErrorMessage() && (
                        <React.Fragment>
                            <FormHelperText style={{ marginTop: 6 }} error={true}>
                                {getErrorMessage()}
                            </FormHelperText>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
