import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

// Dependencies
import { createReferringRole, getOptions, isChildrenRole } from './functions';

// Context
import { ModuleState } from '../../..';
import { StepContext } from '..';

const Component = (props) => {
    const { data, vitalRecommendations } = ModuleState();
    const { entries, setEntries } = StepContext();

    const getLabel = () => {
        if (props.data.value === 'others') return `Other types of individuals`;

        return (
            <React.Fragment>
                How do you refer to your <span>{props.data.label}</span>?
            </React.Fragment>
        );
    };

    const getDescription = () => {
        if (props.data.value === 'others')
            return `You can use this area to include individuals who don't belong to any other category.        `;

        return props.data.description;
    };

    const addIndividual = (label) => {
        let arr = [...entries];

        arr.push(
            createReferringRole({
                label,
                referringRole: props.data.value,
                isChildren: isChildrenRole(label) || false,
            }),
        );

        setEntries(arr);
    };

    const removeIndividual = (label) => {
        const arr = [...entries];

        const index = arr.findIndex((c) => c.label === label);
        if (index === -1) return false;

        arr.splice(index, 1);
        setEntries(arr);
    };

    // Get the value of individuals
    const individuals = entries.filter((c) => c.referringRole === props.data.value);

    return (
        <React.Fragment>
            <div className={`entry`}>
                <div className="label">{getLabel()}</div>
                <div className="description">{getDescription()}</div>
                <div className="input-container">
                    <Autocomplete
                        value={individuals}
                        fullWidth={true}
                        className="input-individuals"
                        disableClearable={true}
                        options={getOptions(data, props.data, vitalRecommendations)}
                        renderInput={(params) => (
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                {...params}
                                placeholder={individuals.length < 1 ? 'Select an individual here..' : ''}
                            />
                        )}
                        freeSolo={true}
                        multiple={true}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        groupBy={(option) => option.category}
                        onChange={(_, inputValues) => {
                            // Someone has been removed.
                            if (inputValues.length < individuals.length) {
                                // Find which individual is now missing from the individuals array.
                                const missingIndividual = individuals.find((c) => !inputValues.find((g) => g.label === c.label));
                                if (!missingIndividual) return false; // bug

                                // Remove him..
                                removeIndividual(missingIndividual.label);
                                return true;
                            }

                            // Someone has been added
                            if (inputValues.length > individuals.length) {
                                const newAdded = inputValues[inputValues.length - 1];

                                // @Reminder: By default they can either by custom written and then we press Click. Or we can select a recommendation.
                                let label = newAdded.custom ? newAdded.inputValue : newAdded.label;

                                // @Bugfix Autocomplete bug: If they type something then press enter without clicking on "add".
                                if (typeof newAdded === 'string') {
                                    label = newAdded;
                                }

                                // Add him..
                                addIndividual(label);
                                return true;
                            }
                        }}
                        filterOptions={(options, params) => {
                            // Get what we wrote in.
                            const { inputValue } = params;

                            // Let's prepare our input for easy filtering.
                            const filteredInput = inputValue.toLowerCase().trim();

                            // Let's check the filtered ones..
                            const filtered = options.filter((option) => {
                                const optionInput = option[`label`].toString().toLowerCase().trim();
                                const isMatch = optionInput.includes(filteredInput);
                                return isMatch ? true : false;
                            });

                            // If they wrote something and there's no filtered results.
                            if (inputValue.length > 0 && filtered.length < 1) {
                                const newOption = {
                                    custom: true,
                                    label: `Add "${inputValue}"`,
                                    inputValue: inputValue.replace(/\*/g, ''),
                                };

                                filtered.push(newOption);
                            }

                            return filtered;
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
