import React, { useEffect } from 'react';

const Component = (props) => {
    const getCurrencies = () => {
        // This filter will remove any currency that does not have a stripe pricing id linked to the package.
        const currencyStripeFilter =
            props.plans.length === 1 && props.plans[0].isPremade === true && props.plans[0].customStripePricingIds ? true : false;

        let arr = [
            {
                label: 'Euro',
                format: 'eur',
            },
            {
                label: 'British Pounds',
                format: 'gbp',
            },
            {
                label: 'Dollars',
                format: 'usd',
            },
        ];

        if (currencyStripeFilter) {
            arr = arr.filter((x) => {
                return props.plans[0].planStripePricingIds[x.format] ? true : false;
            });
        }

        return arr;
    };

    useEffect(() => {
        const arr = getCurrencies();
        // If it's only GBP let's show GBP.
        if (arr.length === 1 || !arr.find((x) => x.format === props.currency)) {
            props.setCurrency(arr[0].format);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            {/* If that filter is activated and we have only one or none (if something uses the api wrong) */}
            {getCurrencies().length > 1 && (
                <div className="currency">
                    <div className="options">
                        {getCurrencies().map((entry, index) => (
                            <div
                                onClick={() => props.setCurrency(entry.format)}
                                className={`option ${props.currency === entry.format}`}
                                key={index}
                            >
                                {entry.label}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Component;
