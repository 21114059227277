import React from 'react';
import { OnboardingContext } from '../..';
import { OnboardingFunctions } from '../functions';

const Component = () => {
    const { viewId } = OnboardingContext();
    const { getViews } = OnboardingFunctions();

    const calculateProgress = () => {
        const views = getViews();

        const totalViews = views.length;
        const currentViewIndex = views.findIndex((view) => view.id === viewId);

        // If the viewId is not found in the views array, then set progress to 0
        // Also if is the first one.

        if (currentViewIndex === 0 || currentViewIndex === -1) {
            // ViewId not found in the views array
            return 0;
        }

        const progress = ((currentViewIndex + 1) / totalViews) * 100;
        const roundedProgress = Math.round(progress / 5) * 5; // Round to nearest 5%
        return roundedProgress;
    };

    return (
        <React.Fragment>
            <div className="progress-bar">
                <div className="bar">
                    <div className="value" style={{ width: `${calculateProgress()}%` }} />
                </div>
                <div className="procent">{calculateProgress()}%</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
