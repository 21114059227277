import React from 'react';

import { MenuItem, Select } from '@mui/material';
import Tooltip from '../../../../../components/tooltip';
import { AuthStore } from '../../../../../../utils/context/authStore';
import { trackUserInteraction } from '../../../../../../utils/helpers';
import { SettingsContext } from '../../..';

// Components
import ScannableDomains from './scannableDomains';
import NotificationsEmails from './notificationEmails';

const Component = () => {
    const { updateData, data, loading } = SettingsContext();

    const periods = ['daily', 'weekly', 'monthly'];
    const { account } = AuthStore();

    const onScanningChanged = async (e) => {
        const packageLimits = account.companyPackage.usageLimits;

        if (!packageLimits.scanningPeriods.includes(e.target.value)) {
            await trackUserInteraction(`Feature not included in company plan`, {
                reason: `They tried to change scanning period to ${e.target.value}`,
                currentPeriodsAllowed: packageLimits.scanningPeriods,
            });

            window.showAlert(
                `Plan limitation`,
                `Your plan allows doesn't allow ${e.target.value} scans. Get in touch with us to discuss an upgrade for your plan`,
                'error',
                [
                    {
                        text: 'Dismiss',
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            await trackUserInteraction(`Selected "Dismiss"`);
                        },
                    },
                    {
                        text: 'Contact Us',
                        onClick: async ({ dismissAlert }) => {
                            await trackUserInteraction(`Selected "Contact Us to Upgrade"`);
                            dismissAlert();
                            window.location.href = `mailto:support@privasee.io?subject=Upgrading current plan`;
                        },
                    },
                ],
            );
            return false;
        }

        updateData(`monitoringSettings.scanInterval`, e.target.value);

        await trackUserInteraction(`Changed "Scan Interval"`, { value: e.target.value });
    };

    return (
        <React.Fragment>
            <div className="section-header second">{data.companyDomains.length > 1 ? 'Domains' : 'Domain'} Monitoring</div>
            <div className="form-group">
                <div className="label">
                    Enabled{' '}
                    <Tooltip content="Enable this if you want us to scan your domain(s) periodically and notify you about it.">
                        <i className="color-primary tooltip-icon fa-solid fa-circle-question"></i>
                    </Tooltip>
                </div>
                <Select
                    fullWidth={true}
                    disabled={loading}
                    variant="outlined"
                    value={data.monitoringSettings.enabled}
                    onChange={(ev) => updateData(`monitoringSettings.enabled`, ev.target.value)}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
            </div>
            {data.monitoringSettings.enabled && (
                <React.Fragment>
                    <ScannableDomains />
                    <NotificationsEmails />
                    <div className="form-group">
                        <div className="label">
                            Scanning interval{' '}
                            <Tooltip content="How often you want us to scan your website for new tools and third parties">
                                <i className="color-primary tooltip-icon fa-solid fa-circle-question"></i>
                            </Tooltip>
                        </div>
                        <Select
                            value={data.monitoringSettings.scanInterval}
                            onChange={(e) => onScanningChanged(e)}
                            fullWidth={true}
                            renderValue={(val) => <span style={{ textTransform: 'capitalize' }}>{val}</span>}
                            disabled={loading}
                            variant="outlined"
                        >
                            {periods.map((period, ix) => (
                                <MenuItem value={period} key={ix} style={{ textTransform: 'capitalize' }}>
                                    {period}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="form-group">
                        <div className="label">
                            Emails Interval{' '}
                            <Tooltip content="How often you want to receive emails about new tools detected.">
                                <i className="color-primary tooltip-icon fa-solid fa-circle-question"></i>
                            </Tooltip>
                        </div>
                        <Select
                            value={data.monitoringSettings.communicationsInterval}
                            onChange={(e) => updateData(`monitoringSettings.communicationsInterval`, e.target.value)}
                            fullWidth={true}
                            renderValue={(val) => <span style={{ textTransform: 'capitalize' }}>{val}</span>}
                            disabled={loading}
                            variant="outlined"
                        >
                            {periods.map((period, ix) => (
                                <MenuItem value={period} key={ix} style={{ textTransform: 'capitalize' }}>
                                    {period}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Component;
