import React from 'react';

// Context
import { SetStorageLocationsContext } from '..';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, ButtonBase, CircularProgress } from '@mui/material';
import { getToolRecommendations } from '../../../../../../../thirdParties/components/storageLocations/components/editTools/utils/helpers';

const Component = () => {
    const { closeModal, entries, textContents, addingTools, removeStorageLocation, toolsRecommendations, startPicking } =
        SetStorageLocationsContext();

    // Get recommendations avatar
    const getAvatar = (label) => {
        const recommendation = getToolRecommendations(label, toolsRecommendations);
        return recommendation ? recommendation.image : '';
    };

    const onStartPickingClicked = (ev) => {
        if (addingTools) return false;
        const attr = ev.target.getAttribute('data-start-picking');
        if (attr !== 'true') return false;

        startPicking();
    };

    return (
        <React.Fragment>
            <Dialog open={true} maxWidth="md" fullWidth>
                <DialogTitle data-cy="dialog-title">
                    <Typography variant="h6">{textContents.heading}</Typography>
                    <Typography variant="subtitle2">{textContents.description}</Typography>
                </DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <div className="component-storage-locations-input">
                        <div className="entries" data-start-picking="true" onClick={onStartPickingClicked}>
                            {entries.length < 1 && (
                                <div className="placeholder-text">Click here to start adding tools or third parties..</div>
                            )}
                            {entries.map((c, index) => (
                                <div className="entry" key={index}>
                                    <div
                                        className="img"
                                        style={{
                                            backgroundImage: `url("${getAvatar(c.label)}")`,
                                        }}
                                    ></div>
                                    <div className="label">{c.label}</div>
                                    <div className="controls">
                                        <ButtonBase onClick={() => removeStorageLocation(c)}>
                                            <i className="elm fa-solid fa-trash" />
                                        </ButtonBase>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between', padding: '0px 16px 20px 16px' }}>
                    <Button variant="contained" color="primary" disabled={addingTools} onClick={startPicking}>
                        Add a tool or third party {addingTools && <CircularProgress size={16} style={{ marginLeft: 8 }} />}
                    </Button>
                    <Button onClick={closeModal} disabled={addingTools} variant="outlined" data-cy="dialog-done-button">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
