import { Select, MenuItem } from '@mui/material';

import React from 'react';

import { EntryState } from '..';
import { ModuleState } from '../../..';
import moment from 'moment';

const Component = () => {
    const { data, setData, isEditing } = EntryState();
    const { settingsMeta } = ModuleState();

    return (
        <React.Fragment>
            <div className="component-meta" style={{ width: 350 }}>
                <div style={{ fontWeight: 500, fontSize: 16, marginBottom: 24 }}>Details</div>
                <div className="--entries">
                    <div className="component-row">
                        <div className="component-data-field" style={{ marginBottom: 16 }}>
                            <span style={{ fontWeight: 800 }}>Created by:</span> {data.createdBy}
                        </div>
                        <div className="component-data-field" style={{ marginBottom: 16 }}>
                            <span style={{ fontWeight: 800 }}>Created at:</span> {moment(data.createdAt).format('DD MMM YY, HH:mm')}
                        </div>
                        {JSON.stringify(data.createdAt) !== JSON.stringify(data.updatedAt) && (
                            <React.Fragment>
                                <div className="component-data-field" style={{ marginBottom: 16 }}>
                                    <span style={{ fontWeight: 800 }}>Updated by:</span> {data.updatedBy}
                                </div>
                                <div className="component-data-field" style={{ marginBottom: 16 }}>
                                    <span style={{ fontWeight: 800 }}>Updated at:</span> {moment(data.updatedAt).format('DD MMM YY, HH:mm')}
                                </div>
                            </React.Fragment>
                        )}
                        <hr style={{ borderColor: 'rgba(255,255,255, 0.07)', marginBottom: 16 }} />
                        <div style={{ fontWeight: 500, marginBottom: 16 }}>Assigned To</div>
                        <div className="input">
                            <PeopleSelector
                                disabled={!isEditing}
                                currentValue={data.assignedTo}
                                options={settingsMeta.companyEmails}
                                onChange={(newValue) => setData({ assignedTo: newValue })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const PeopleSelector = (props) => {
    const currentValue = props.currentValue ? props.currentValue : 'no-one';
    return (
        <React.Fragment>
            <Select
                disabled={props.disabled}
                fullWidth={true}
                className="component-select-people"
                value={currentValue}
                onChange={(ev) => props.onChange(ev.target.value)}
            >
                {/* If no one is selected */}
                {currentValue === 'no-one' && (
                    <MenuItem disabled={true} value="no-one">
                        {props.disabled ? 'No-one is assigned.' : 'Click here to assign.'}
                    </MenuItem>
                )}

                {/* If current option is not listed because is not part of current workspace */}
                {currentValue && currentValue !== 'no-one' && !props.options.includes(currentValue) && (
                    <MenuItem value={currentValue}>{currentValue}</MenuItem>
                )}

                {/* List the options */}
                {props.options.map((c, index) => (
                    <MenuItem key={index} value={c}>
                        {c}
                    </MenuItem>
                ))}
            </Select>
        </React.Fragment>
    );
};

export default Component;
