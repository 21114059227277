import * as Yup from 'yup';

export const InformationViewSchema = Yup.object({
    label: Yup.string().required('This field is required.').trim().label('This'),
    role: Yup.string().nullable().required('This field is required').label('This'),
    personalLegalBasis: Yup.lazy((value, originalValue) => {
        // If role is processor is all good.
        if (originalValue?.parent?.role === 'Processor') return Yup.object({}).nullable();

        return legalBasisSchema;
    }),
    sensitiveLegalBasis: Yup.lazy((value, originalValue) => {
        // If we are not using sensitive data.
        if (!originalValue.parent?.isUsingSensitiveData) return Yup.object({}).nullable();

        // If role is processor is all good.
        if (originalValue.parent?.role === 'Processor') return Yup.object({}).nullable();

        return legalBasisSchema;
    }),
});

export const ToolsViewSchema = Yup.object({
    tools: Yup.array().min(1, 'At least one tool is required').label('This'),
});

export const RetentionViewSchema = Yup.object({
    retention: Yup.object({
        type: Yup.string().label('This').required('An option listed here (or an alternative) is required.').nullable(),
        customText: Yup.lazy((value, originalValue) => {
            // If necessary is off.
            if (originalValue?.parent?.type !== `manual:customText`) return Yup.string();

            return Yup.string().required('This field is required');
        }),
        specificTime: Yup.lazy((value, originalValue) => {
            // If necessary is off.
            if (originalValue?.parent?.type !== `manual:specificTime`) return Yup.object({}).nullable();

            return Yup.object({
                value: Yup.number().typeError('This field must have a number set.').min(1).label('This'),
                interval: Yup.string().oneOf(['Days', 'Months', 'Years']).required(),
            });
        }),
    }),
});

const legalBasisSchema = Yup.object({
    string: Yup.string().required('This field is required').label('This'),
})
    .nullable()
    .required('This field is required')
    .label('This');
