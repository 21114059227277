import React from 'react';
import { TextField, Select, MenuItem } from '@mui/material';

// Schema
import { ComponentState } from '../../../..';
import { getValidationPropFields } from '../../../../../../../utils/helpers';

const Page = props => {
    const { data, updateData, validationKeys } = ComponentState();

    return (
        <React.Fragment>
            {props.operatesLocation === 'UK' ? (
                <React.Fragment>
                    <div className="form-group">
                        <div className="label">ICO Registration Number</div>
                        <TextField
                            value={data.icoRegNumber || ''}
                            fullWidth={true}
                            data-cy="ico-reg-input"
                            className="input"
                            placeholder="ICO Registration Number"
                            onChange={e => updateData(`icoRegNumber`, e.target.value)}
                            variant="standard"
                            {...getValidationPropFields(validationKeys, true, `icoRegNumber`)}
                        />
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className="form-label">Do you operate in the United Kingdom?</div>
                    <div className="form-content">
                        <Select
                            fullWidth={true}
                            onChange={e => updateData(`operateInUK`, e.target.value)}
                            value={data.operateInUK === null ? 'none-selected' : data.operateInUK}
                        >
                            <MenuItem value="none-selected" disabled={true}>
                                Select from list
                            </MenuItem>
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </div>
                    {data.operateInUK && (
                        <React.Fragment>
                            <div className="form-label">Do you have an office, branch or establishment in the United Kingdom?</div>
                            <div className="form-content">
                                <Select
                                    fullWidth={true}
                                    onChange={e => updateData(`branchInUK`, e.target.value)}
                                    value={data.branchInUK !== null ? data.branchInUK : 'none-selected'}
                                >
                                    <MenuItem value="none-selected" disabled={true}>
                                        Select from list
                                    </MenuItem>
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </div>
                            {data.operateInUK && data.branchInUK && (
                                <React.Fragment>
                                    <div className="form-heading">UK Branch Details</div>
                                    <div className="form-label">ICO Registration Number</div>
                                    <div className="form-content">
                                        <TextField
                                            value={data.icoRegNumber || ''}
                                            fullWidth={true}
                                            className="input"
                                            placeholder="ICO Registration Number"
                                            onChange={e => updateData(`icoRegNumber`, e.target.value)}
                                            variant="standard"
                                            {...getValidationPropFields(validationKeys, true, `icoRegNumber`)}
                                        />
                                    </div>
                                    <div className="form-label">Address</div>
                                    <div className="form-content">
                                        <TextField
                                            value={data.branchUKAddress || ''}
                                            fullWidth={true}
                                            className="input"
                                            placeholder="UK Branch Address"
                                            onChange={e => updateData(`branchUKAddress`, e.target.value)}
                                            variant="standard"
                                            {...getValidationPropFields(validationKeys, true, `branchUKAddress`)}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                            {data.operateInUK && !(data.branchInUK === null) && !data.branchInUK && (
                                <React.Fragment>
                                    <div className="form-heading">UK Representative Information</div>
                                    <div className="form-label">Company Name</div>
                                    <div className="form-content">
                                        <TextField
                                            value={data.ukRepInfo.ukRepName || ''}
                                            fullWidth={true}
                                            className="input"
                                            placeholder="Company Name"
                                            onChange={e => updateData(`ukRepInfo.ukRepName`, e.target.value)}
                                            variant="standard"
                                            {...getValidationPropFields(validationKeys, true, `ukRepInfo.ukRepName`)}
                                        />
                                    </div>
                                    <div className="form-label">Address</div>
                                    <div className="form-content">
                                        <TextField
                                            value={data.ukRepInfo.ukRepAddress || ''}
                                            fullWidth={true}
                                            className="input"
                                            placeholder="Address"
                                            onChange={e => updateData(`ukRepInfo.ukRepAddress`, e.target.value)}
                                            variant="standard"
                                            {...getValidationPropFields(validationKeys, true, `ukRepInfo.ukRepAddress`)}
                                        />
                                    </div>
                                    <div className="form-label">Contact Details</div>
                                    <div className="form-content">
                                        <TextField
                                            value={data.ukRepInfo.ukRepContact || ''}
                                            fullWidth={true}
                                            className="input"
                                            placeholder="Contact details"
                                            onChange={e => updateData(`ukRepInfo.ukRepContact`, e.target.value)}
                                            variant="standard"
                                            {...getValidationPropFields(validationKeys, true, `ukRepInfo.ukRepContact`)}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Page;
