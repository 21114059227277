import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useWindowSize } from '../../../../utils/helpers';

const Component = (props) => {
    const [width] = useWindowSize();

    const getEntries = () => {
        // eslint-disable-next-line
        let entries = [
            {
                label: 'Settings',
                value: 'settings',
            },
            {
                label: 'Cookies',
                value: 'cookies',
            },
            {
                label: 'Add to website',
                value: 'add-to-website',
            },
        ].filter((x) => x !== null);

        return entries;
    };

    const onChangeFunc = (ex, val) => {
        props.setValue(val);
    };

    return (
        <div className="tabs">
            <Tabs data-cy="tabs" value={props.value} variant={width < 1100 ? `scrollable` : `standard`} onChange={onChangeFunc}>
                {getEntries().map((entry, index) => (
                    <Tab disabled={props.loading} label={entry.label} key={index} value={entry.value} />
                ))}
            </Tabs>
        </div>
    );
};

export default Component;
