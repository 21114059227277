import React from 'react';

// Context
import { SetIndividualsContext } from '..';

const Component = () => {
    const { individuals, setEnabled } = SetIndividualsContext();

    return (
        <React.Fragment>
            <div className="component-btn" onClick={() => setEnabled(true)} data-cy="edit-individuals-btn">
                <i className="icon fa-solid fa-circle-plus"></i>
                <div className="label">{individuals.length < 1 ? 'Add an Individual' : 'Edit Individuals'}</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
