import React, { useState, useEffect, createContext, useContext } from 'react';

// Components
import Name from './components/name';
import Type from './components/type';
import Description from './components/description';

// Validation
import { PanelContext } from '../../';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const [validationResults, setValidationResults] = useState({});

    // Context
    const { data, validateView } = PanelContext();

    const validateFields = async () => {
        const { results } = await validateView('information');
        setValidationResults(results);
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    const PassedProps = {
        validationResults,
        setValidationResults,
    };

    return (
        <Context.Provider value={PassedProps}>
            <div className="component-forms">
                <Name />
                <Type />
                <Description />
            </div>
        </Context.Provider>
    );
};

export default Component;
