import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { trackUserInteraction } from '../../../../../utils/helpers';
import Tooltip from '../../../../components/tooltip';

// Context
import { ModuleState } from '../../index';
import { ModuleAPI } from '../../components/api';

const sensitiveElementsList = [
    {
        label: 'Racial or Ethnic Origin',
    },
    {
        label: 'Medical Data',
    },
    {
        label: 'Sexual Orientation or Sex Life Data',
    },
    {
        label: 'Biometric Data',
    },
    {
        label: 'Genetic Data',
    },
    {
        label: 'Political Opinions',
    },
    {
        label: 'Religious or Philosophical Beliefs',
    },
    {
        label: 'Trade Union Membership',
    },
    {
        label: `None`,
    },
];

const StepIdentifySensitive = () => {
    const { data, updateData, step, setStep } = ModuleState();
    const { filterCheckedData, refreshCheckedData } = ModuleAPI();
    const [noneSelected, setNoneSelected] = useState(data.checkedData.elements.filter((elm) => elm.sensitive === true).length < 1);

    const nextStep = async () => {
        filterCheckedData();

        let nextOne = 'Children';

        setStep(nextOne);
        await trackUserInteraction(`Selected "Next"`, { destination: nextOne });
    };

    const goBack = async () => {
        filterCheckedData();
        setStep('IdentifyElements');
        await trackUserInteraction(`Selected "Go Back"`, { destination: `IdentifyElements` });
    };

    const warnAgainstRemoving = (elm) => {
        window.showAlert(
            'Confirmation',
            `Are you sure about removing ${elm.label}? This will remove it from your data inventory entirely.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        removeElementSelected(elm);
                    },
                },
            ],
        );
    };

    const removeElementSelected = async (elm) => {
        let checkedData = data.checkedData;
        checkedData.elements.forEach((elmIterated, index) => {
            if (elmIterated.label === elm.label && elmIterated.sensitive === true) {
                checkedData.elements.splice(index, 1);
            }
        });
        updateData(`checkedData`, checkedData);
        await trackUserInteraction(`Removed selected "Sensitive Data Element"`, {
            removed: elm.label,
        });
    };

    const onElementSelected = async (elm) => {
        if (elm.label === 'None') {
            await trackUserInteraction(`${noneSelected ? `Removed selected` : `Selected`} "None"`, { screen: `reviewSensitiveElements` });
            setNoneSelected(!noneSelected);
            return false;
        }
        let checkedData = data.checkedData;
        let lastBiggestStep = data._meta.biggestStep;
        if (checkedData.elements.find((iterated) => iterated.label === elm.label && iterated.sensitive === true)) {
            if (lastBiggestStep !== step) {
                warnAgainstRemoving(elm);
                return false;
            }
            removeElementSelected(elm);
        } else {
            let elmAdded = { label: elm.label, sensitive: true, type: 'manual' };
            await trackUserInteraction(`Selected "Sensitive Data Element`, { added: elm.label });
            checkedData.elements.push(elmAdded);
            updateData('checkedData', checkedData);
            if (noneSelected) {
                setNoneSelected(false);
            }
        }
    };

    useEffect(() => {
        refreshCheckedData();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" onClick={goBack} data-cy="back-button">
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Sensitive Personal Data
                    </div>
                    <div className="subtitle">
                        Do you process any of the sensitive data categories below? If so, which ones?
                        <div className="tooltip" style={{ display: 'inline-block' }}>
                            <Tooltip
                                trackInteraction={true}
                                interactionId={`gdpr-essentials:what-data-category-is`}
                                content={`A data category is a type of data that you collect or use. It could be about your customers, employees, or business partners. E.g. you may keep your customer's home address for online orders. That's part of the contact details group.`}
                            >
                                <i className="tooltip-icon fa-solid fa-circle-info"></i>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" disabled={false} onClick={nextStep} data-cy="next-button">
                        Next
                    </Button>
                </div>
            </div>
            <div className="identify-elements module-content">
                <div className="entries d-flex-row-columns centered">
                    {sensitiveElementsList.map((elm, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`entry ${
                                    elm.label === 'None' &&
                                    data.checkedData.elements.filter((elm) => elm.sensitive === true).length > 0 &&
                                    `disabled`
                                } ${
                                    data.checkedData.elements.find((x) => x.label === elm.label && x.sensitive === true) ||
                                    (elm.label === 'None' && noneSelected === true)
                                        ? `selected`
                                        : `not-selected`
                                }`}
                                data-cy={`entry-${index}`}
                                key={index}
                                onClick={() => onElementSelected(elm)}
                            >
                                <div className="content">
                                    <div className="left-side">
                                        <div className="label">{elm.label}</div>
                                        {/* <div className="description">{elm.description}</div> */}
                                    </div>
                                    <div className="checkmark">
                                        <i className="icon fa-solid fa-check"></i>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default StepIdentifySensitive;
