import * as Yup from 'yup';
import { websiteValidationScheme } from '../../../../gdprEssentials/utils/validations';

export const informationViewSchema = Yup.object({
    label: Yup.string().required('This field is required.').trim().label('This'),
    securityMeasures: Yup.string().trim().label('This').required('This field is required.'),
    dataResidency: Yup.string().trim().label('This').required('This field is required.'),
    executedDpaLink: Yup.string().trim().label('This'),
});

// Dependencies

export const subProcessorViewSchema = Yup.object({
    subProcessorMeasuresURL: Yup.string().when('isSubProcessor', {
        is: true,
        then: Yup.string()
            .test('isEmailOrWebsite', 'Must be a valid URL or email', (value) => {
                if (value && value.includes('@')) return Yup.string().required().email().isValidSync(value);
                return websiteValidationScheme.isValidSync(value);
            })
            .label('This')
            .required('This field is required.'),
        otherwise: Yup.string(),
    }),
});
