import React, { useState } from 'react';

// Components
import SetStorageLocations from '../../../../shareableComponents/setStorageLocations';

// Dependencies
import { logError, trackUserInteraction } from '../../../../../../../../../../utils/helpers';
import { PanelContext } from '../../../../../..';
import { convertMatriceEntriesToProcesses } from '../../../../../../../utils/functions';

const Component = (props) => {
    const { data, toolsRecommendations, vitalRecommendations, setData, setPanelVisible, dataInventory, companyData } = PanelContext();
    const { setDataInventory } = PanelContext();

    const [enabled, setEnabled] = useState(false);

    /**
     * This function will look in the matrices and find any elements linked to this individual, processes and storage location.
     * @param {*} matrices
     * @param {*} _id
     */

    const getElementsForStorageLocation = (matrices, _id) => {
        try {
            const matrice = matrices.find((c) => c._storageLocationId === _id);
            if (!matrice) return []; // No recommendations.

            let entries = matrice.entries;

            // We will find entries
            let matches = entries.filter((c) => c._individualId === data._id && c._processId === props.process._id);

            return matches.map((c) => c._elementId);
        } catch (err) {
            logError(`getElementsForStorageLocation`, err, { matrices, _id });
            return [];
        }
    };

    /**
     * Sometimes when we add new storage locations they may add recommendations and those recommendations may have new processes collecting from
     * our current individual. When that happens is a good thing to load them in too.
     */
    const loadNewProcesses = async (updatedDataInventory) => {
        try {
            setData((currentState) => {
                const newProcesses = convertMatriceEntriesToProcesses(updatedDataInventory, data._id);
                let updatedProcesses = [...currentState.processes];

                // Go through all the processes returned from the function
                for (const newProcess of newProcesses) {
                    const exists = updatedProcesses.find((c) => c._processId === newProcess._processId);

                    // This process collects data from this individual but is not listed. Let's add it so UI represents 1:1.
                    if (!exists) {
                        updatedProcesses.push(newProcess);
                    }
                }

                return { ...currentState, processes: updatedProcesses };
            });
        } catch (err) {
            await logError(`individuals.loadNewProcesses`, err);
            return false;
        }
    };

    /**
     *
     * @param {*} storageLocation
     * @param {*} updatedDataInventory
     * @param {*} isCreated
     */
    const onStorageLocationAdded = async (storageLocation, updatedDataInventory) => {
        try {
            // Add storage location
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the current process.
                const processIndex = newState.processes.findIndex((c) => c._processId === props.process._id);
                if (processIndex === -1) return newState;

                newState.processes[processIndex].storageLocations.push({
                    _id: storageLocation._id,
                    elements: getElementsForStorageLocation(updatedDataInventory.matrices, storageLocation._id),
                });

                return newState;
            });

            // Load new processes.
            loadNewProcesses(updatedDataInventory);
        } catch (err) {
            await logError(`individuals.editStorageLocations.onStorageLocationAdded`, err, { storageLocation });
        }
    };

    const onStorageLocationRemoved = async (storageLocation) => {
        try {
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the current process.
                const processIndex = newState.processes.findIndex((c) => c._processId === props.process._id);
                if (processIndex === -1) return newState;

                // Get the index of current storage Locations
                const toolIndex = newState.processes[processIndex].storageLocations.findIndex((c) => c._id === storageLocation._id);
                if (toolIndex === -1) return newState;

                // Remove storage location.
                newState.processes[processIndex].storageLocations.splice(toolIndex, 1);

                return newState;
            });

            trackUserInteraction(`Removed storage location from Purpose`, {
                storageLocation: storageLocation,
                process: props.process.label,
            });
        } catch (err) {
            await logError(`individuals.editStorageLocations.onStorageLocationRemoved`, err, { storageLocation });
        }
    };

    return (
        <React.Fragment>
            <div
                className="component-btn"
                data-cy={props.storageLocations.length < 1 ? 'add-a-tool' : 'edit-a-tool'}
                onClick={() => setEnabled(true)}
            >
                <i className="icon fa-solid fa-circle-plus"></i>
                <div className="label">
                    {props.storageLocations.length < 1 ? 'Add a Tool or Third Party' : 'Edit Tools or Third Parties'}
                </div>
            </div>
            {enabled && (
                <SetStorageLocations
                    // The current storage locations added to this panel.
                    entries={props.storageLocations.map((c) => ({ _id: c.data._id, label: c.data.label }))}
                    // Entities
                    onEntryAdded={onStorageLocationAdded}
                    onEntryRemoved={onStorageLocationRemoved}
                    // Make panel visible
                    setParentPanelVisible={setPanelVisible}
                    // Close modal
                    closeModal={() => setEnabled(false)}
                    // Texts
                    textContents={{
                        heading: `Tools or Third Parties used for ${props.process.label}`,
                        description: `What tools are used for the purpose of ${props.process.label}?`,
                        placeholder: 'Click here to add a tool or third party',
                    }}
                    // Dependencies
                    dataInventory={dataInventory}
                    setDataInventory={setDataInventory}
                    companyData={companyData}
                    vitalRecommendations={vitalRecommendations}
                    toolsRecommendations={toolsRecommendations}
                />
            )}
        </React.Fragment>
    );
};

export default Component;
