import { Button } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ModuleState } from '..';

const Component = () => {
    const { submitDisabled, onSubmit, submitted } = ModuleState();

    const history = useHistory();

    return (
        <React.Fragment>
            <div className="page-controls">
                <div className="left-side">
                    <Button variant="text" className="goBack" onClick={() => history.push('/security-measures/')} disabled={submitted}>
                        Go Back
                    </Button>
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" disabled={submitDisabled} onClick={onSubmit}>
                        Save
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
