import React from 'react';

// Components
import Header from './components/header';
import SetIndividuals from './components/setIndividuals';
import Individual from './components/individual';
import RemoveTool from './components/removeTool';

// Context
import { PanelContext } from '../../../..';

// Dependencies
import { logError } from '../../../../../../../../utils/helpers';
import { FormHelperText } from '@mui/material';

const Component = (props) => {
    const { dataInventory, vitalRecommendations } = PanelContext();

    const getElementsOptions = () => {
        try {
            // not ready.
            if (!vitalRecommendations.elements) return [];

            let elements = [];

            // Go through normal elements
            vitalRecommendations.elements.forEach((elm) => {
                const inventoryEntry = dataInventory.elements.find((c) => c.label === elm.label);
                elements.push({
                    _id: inventoryEntry ? inventoryEntry._id : null,
                    label: elm.label,
                    sensitive: false,
                    description: elm.description,
                    type: 'vitalRecommendation',
                });
            });

            // Go through sensitive elements
            vitalRecommendations.sensitiveElements.forEach((elm) => {
                const inventoryEntry = dataInventory.elements.find((c) => c.label === elm.label);
                elements.push({
                    _id: inventoryEntry ? inventoryEntry._id : null,
                    label: elm.label,
                    sensitive: true,
                    description: '',
                    type: 'vitalRecommendation',
                });
            });

            // Now custom ones..
            dataInventory.elements.forEach((element) => {
                const exists = elements.find((c) => c.label === element.label && c.sensitive === element.sensitive);
                if (exists) return;

                elements.push({
                    _id: element._id,
                    label: element.label,
                    sensitive: element.sensitive,
                    description: '',
                    type: 'custom',
                });
            });

            // Formatting elements once more
            elements = elements
                .map((c) => ({ ...c, category: c.sensitive ? 'Sensitive Data Categories' : 'Personal Data Categories' }))
                .sort((a, b) => a.category.localeCompare(b.category)); // sort by category

            return elements;
        } catch (err) {
            logError(`module.processes.panel.getElementsOptions`, err);
            return [];
        }
    };

    const elementOptions = getElementsOptions();
    const individualsMissingElements = props.individuals.filter((c) => c.elements.length < 1);

    return (
        <React.Fragment>
            <div className="component-tool">
                <Header label={props.data.label} removeBtn={<RemoveTool tool={props.data} />} />
                <div className="individuals">
                    {props.individuals.map((c, index) => (
                        <Individual
                            tool={props.data}
                            individual={c.data}
                            elements={c.elements}
                            key={index}
                            elementsOptions={elementOptions}
                        />
                    ))}
                    {props.individuals.length < 1 && (
                        <React.Fragment>
                            <div className="--component-no-entry">
                                This tool doesn't collect data from individuals. To specify the individuals from whom data may be collected,
                                click the "Add an Individual" button.
                            </div>
                            <FormHelperText error={true} style={{ marginTop: 12 }}>
                                To integrate this tool into the process, please add at least one individual.
                            </FormHelperText>
                        </React.Fragment>
                    )}
                    {individualsMissingElements.length > 0 && (
                        <React.Fragment>
                            <FormHelperText error={true} style={{ marginTop: 12 }}>
                                Please provide details on the data collected from individuals highlighted in red.
                            </FormHelperText>
                        </React.Fragment>
                    )}
                </div>
                <div className="footer">
                    <SetIndividuals individuals={props.individuals} tool={props.data} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
