import React from 'react';
import { CircularProgress } from '@mui/material';

const Component = (props) => {
    return (
        <React.Fragment>
            <div className="loading-container">
                {props.data === undefined ? (
                    <div className="error">
                        <div className="icon-container">
                            <i className="elm fa-light fa-triangle-exclamation"></i>
                        </div>
                        <div className="title">Having Difficulties</div>
                        <div className="message">We're experiencing technical difficulties, please try again.</div>
                    </div>
                ) : (
                    <CircularProgress />
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
