import React from 'react';

// Components
import { Button } from '@mui/material';
import Tooltip from '../../../../../components/tooltip';

// Context
import { OnboardingFunctions } from '../../../components/functions';
import { ViewContext } from '..';

const Component = () => {
    const { filterOptionsFromInventory, createOptionsInInventory, checkedOptions } = ViewContext();
    const { navigateViews } = OnboardingFunctions();

    const nextStep = async () => {
        // filter out the options unchecked now.
        filterOptionsFromInventory();

        // Create the secret elements selected
        createOptionsInInventory();

        // Move on.
        navigateViews('forward');
    };

    const previousStep = () => {
        navigateViews('backward');
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">Sensitive Personal Data</div>
                    <div className="subtitle">
                        Do you process any of the sensitive data categories below? If so, which ones?
                        <div className="tooltip" style={{ display: 'inline-block' }}>
                            <Tooltip
                                trackInteraction={true}
                                interactionId={`gdpr-essentials:what-data-category-is`}
                                content={`A data category is a type of data that you collect or use. It could be about your customers, employees, or business partners. E.g. you may keep your customer's home address for online orders. That's part of the contact details group.`}
                            >
                                <i className="tooltip-icon fa-solid fa-circle-info"></i>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={nextStep}
                        disabled={checkedOptions.length < 1}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
