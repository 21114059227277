import React from 'react';

// Context
import { SettingsContext } from '..';

// Components
import { Button } from '@mui/material';

const Component = () => {
    const { submitData, loading, validationKeys, sectionRendered } = SettingsContext();
    return (
        <React.Fragment>
            <div className="page-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className="page-title" style={{ marginBottom: 0 }}>
                    Settings
                </div>
                {['company', 'scanners'].includes(sectionRendered) && (
                    <React.Fragment>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submitData}
                            disabled={loading || Object.keys(validationKeys).length > 0}
                        >
                            Save Changes
                        </Button>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
