import React from 'react';

// Dependencies
import AutoComplete from '../../../../thirdParties/components/editPanel/components/autocomplete.js';

// Context
import { SettingsContext } from '../../../index.js';
import { AuthStore } from '../../../../../../utils/context/authStore.js';

const Component = () => {
    const { globalFlags } = AuthStore();

    const { data, updateData } = SettingsContext();

    const addEntry = (elm) => {
        updateData('services', [
            ...data.services,
            {
                label: elm.label,
                origin: 'manual',
            },
        ]);
    };

    const removeEntry = (elm) => {
        updateData(
            'services',
            data.services.filter((c) => c.label !== elm.label),
        );
    };

    // If they don't have the flag.
    if (!globalFlags.gelDecoupling) return null;

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="label">Core business activity/activities</div>
                <AutoComplete
                    // The value and options
                    value={data.services}
                    options={[]}
                    // Props to the autocomplete..
                    groupCategories={false}
                    subjectsView={false}
                    placeholder={'Click here to enter a service you offer'}
                    // Callbacks when data is changed
                    onEntityAdded={(elm) => addEntry(elm)}
                    onEntityRemoved={(elm) => removeEntry(elm)}
                    notCreatable={false}
                />
            </div>
        </React.Fragment>
    );
};

export default Component;
