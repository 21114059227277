import React, { createContext, useContext, useRef } from 'react';

// Module Context
import { ModuleState } from '../../../../..';

// Dependencies
import {
    createCustomStorageLocation,
    createStorageLocationWithRecommendations,
} from '../../../../../../gdprEssentials/steps/storageLocations/utils/helpers';
import { logError } from '../../../../../../../../utils/helpers';
import { makeEndpointRequest, makeLocalEndpointRequest } from '../../../../../../../../utils/endpoints';
import {
    createRecommendedStorageLocationsInBulk,
    createStorageLocationsInDataInventory,
    formatCreatedToolName,
} from '../../../utils/functions';
import { AuthStore } from '../../../../../../../../utils/context/authStore';

// Context
const Context = createContext({});
export const FunctionsContext = () => useContext(Context);

const Component = (props) => {
    const { setOptions, vitalRecommendations, data, companyData, refreshDataInventory } = ModuleState();
    const { globalFlags } = AuthStore();

    // This will be used so we can set the panel's view and more from outside it.
    const panelController = useRef(null);

    const onPanelClose = async (instant = false) => {
        try {
            const onClosing = () => {
                // Reset it.
                setOptions((currentState) => ({ ...currentState, pickingTools: false }));
            };

            // If we need the panel closed right away
            if (instant) return onClosing();

            // Close the panel
            panelController.current.modal.current.close(() => onClosing());
        } catch (err) {
            await logError(`createTools.onPanelClose`, err);
        }
    };

    const formatToolWithRecommendations = async (toolName) => {
        try {
            const recommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: [toolName],
                processes: [],
            });

            const storageObject = createStorageLocationWithRecommendations({
                toolName: toolName,
                specificRecommendations: recommendations,
                vitalRecommendations,
                targetAudience: companyData.targetAudience,
                currentStorageLocations: data.storageLocations,
                individualsReferring: companyData.individualsReferring,
            });

            if (!storageObject) return null;

            return storageObject;
        } catch (err) {
            await logError('createTools.formatToolWithRecommendations', err, { toolName });
            return null;
        }
    };

    const onToolsPicked = async (pickedToolsLabels) => {
        try {
            // Check if we use the new API
            if (globalFlags.gelDecoupling) {
                // Iterate and create
                for (const [index, label] of pickedToolsLabels.entries()) {
                    // Check if the current label is the last one
                    const isLastLabel = index === pickedToolsLabels.length - 1 ? true : false;

                    // Make the API Request
                    await makeLocalEndpointRequest(`/api/v1/storageLocations/pickToolFromRecommendations`, {
                        label,
                        options: {
                            syncToSession: isLastLabel, // Set syncToSession to true only for the last label
                            updateCookieBanner: isLastLabel,
                        },
                    });
                }
            } else {
                const { tools, processesDependencies } = await createRecommendedStorageLocationsInBulk({
                    toolLabels: pickedToolsLabels,
                    dataInventory: data,
                    companyData,
                    vitalRecommendations,
                });

                // Create them
                await createStorageLocationsInDataInventory(tools, processesDependencies);
            }

            // Refresh data inventory
            refreshDataInventory();
        } catch (err) {
            await logError(`createTools.onToolsPicked`, err, { data });
        }
    };

    const onCustomToolCreated = async (customTool) => {
        try {
            // Format the label accordingly.
            let label = formatCreatedToolName(customTool.label, data.storageLocations);

            // Check if we use the new API
            if (globalFlags.gelDecoupling) {
                // Make the API Request
                await makeLocalEndpointRequest(`/api/v1/storageLocations/createCustomTool`, {
                    label,
                    similarTo: customTool.similarData ? customTool.similarData.label : null,
                    options: {
                        syncToSession: true,
                    },
                });
            } else {
                // Variables required
                let toolFormatted = null;
                let dataObject = {};

                // If this custom tool is similar to a tool
                if (customTool.similarData) {
                    toolFormatted = await formatToolWithRecommendations(customTool.similarData.label);
                }

                // If there is no formatted matching similar to.
                if (!toolFormatted) {
                    dataObject = {
                        ...createCustomStorageLocation(label),
                        origin: `manual`,
                    };
                } else {
                    dataObject = {
                        ...toolFormatted,
                        label,
                        // Reset these...
                        securityMeasures: '',
                        dataResidency: '',
                        image: '',
                        // Cookie info
                        cookieExternalInfo: '',
                        cookiePurpose: '',
                        cookieType: '',
                        //
                        origin: 'manual',
                    };
                }

                // Create the tools picked
                await makeEndpointRequest(`CreateStorageLocations`, { entries: [dataObject] });
            }

            // Refresh data inventory
            refreshDataInventory();
        } catch (err) {
            await logError(`createTools.onCustomToolCreated`, err);
            return false;
        }
    };

    const PassedProps = {
        // Ref is important
        panelController,
        // Functions
        onPanelClose,
        onToolsPicked,
        onCustomToolCreated,
    };

    return <Context.Provider value={PassedProps}>{props.children}</Context.Provider>;
};

export default Component;
