import React from 'react';

// Components
import { Button } from '@mui/material';
import { PanelContext } from '../../..';

const Component = () => {
    const { deleteIndividual } = PanelContext();

    return (
        <React.Fragment>
            <div className="component-section" style={{ maxWidth: 800 }}>
                <h4>Delete</h4>

                <p style={{ fontSize: 14, color: 'rgba(255,255,255,0.8)' }}>
                    Deleting this individual will remove it from your data inventory, ensuring it is no longer referenced in your policy.
                    Please be aware that this action is irreversible, so exercise caution.
                </p>

                <Button variant="contained" color="primary" style={{ marginTop: 16 }} onClick={deleteIndividual} data-cy="btn-delete">
                    Delete this Individual
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Component;
