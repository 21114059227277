import React, { createContext, useContext } from 'react';

// Dependencies
import { checkUserFlag, logError } from '../../../../utils/helpers';
import { makeEndpointRequest, makeLocalEndpointRequest } from '../../../../utils/endpoints';

// Context Imported
import { OnboardingContext } from '..';
import { getIndividualTypesIdFromLabel } from '../../gdprEssentials/steps/individuals/components/functions';

// Context
const Context = createContext({});
export const OnboardingFunctions = () => useContext(Context);

const Component = (props) => {
    const {
        viewId,
        flags,
        companyData,
        setCompanyData,
        dataInventory,
        setDataInventory,
        setLoading,
        setViewId,
        setVitalRecommendations,
        setToolsRecommended,
        setFlags,
        vitalRecommendations,
        tasksPending,
        setTasksPending,
        // scanner,
        setScanner,
    } = OnboardingContext();

    /**
     * The core essential function for the onboarding flow. Is loading the company data and the data inventory of the current company.
     */

    const loadData = async () => {
        try {
            // Mark that we're loading
            setLoading(true);

            // Load company data
            const company = await makeEndpointRequest(`GetCompanyData`);

            // Load data inventory
            const dataInventory = await makeEndpointRequest(`GetDataInventory`, {
                onlyPublishedStorageLocations: false,
                bundleStorageLocations: false,
            });

            // Set the data
            setDataInventory(dataInventory);
            setCompanyData(company);

            // Finished loading
            setLoading(false);
        } catch (err) {
            await logError(`onboarding.loadData`, err);

            // In case of an error
            setLoading(false);
        }
    };

    const getViews = () => {
        try {
            // This is the order of the views
            let viewsOrder = [
                { id: 'welcome' },
                { id: 'audience' },
                { id: 'industry' },
                { id: 'company-insights' },
                { id: `describe-company` },
                { id: 'data-inventory' },
                { id: 'activities' },
                { id: 'individuals' },
                { id: 'personal-data' },
                { id: 'sensitive-data' },
                { id: 'children' },
                { id: 'review-tools' },
                { id: 'legal-responsibility' },
                { id: 'complete' },
            ];

            // // We will now filter the views that our current user doesn't have access to.
            // // Example: Maybe there is a new view step for people with a certain flag.

            // viewsOrder = viewsOrder.filter((step) => {
            //     // This view is available for anyone.
            //     if (step.preCheck === undefined) return true;

            //     // If this view has a pre check (checking for flags, for example) and we don't pass.
            //     if (step.preCheck && !step.preCheck()) return false;

            //     return true;
            // });

            return viewsOrder;
        } catch (err) {
            throw err;
        }
    };
    /**
     * Navigates the user through the views of the system.
     * @param {string} direction - The direction of navigation. Either 'forward' or 'backward'.
     */

    const navigateViews = async (direction) => {
        try {
            const viewsOrder = getViews();

            // The current view id
            const currentViewIndex = viewsOrder.findIndex((c) => c.id === viewId);

            // If we go forward.
            if (direction === 'forward' && viewsOrder[currentViewIndex + 1]) {
                setViewId(viewsOrder[currentViewIndex + 1].id);
            }

            // If we go back.
            if (direction === 'backward' && viewsOrder[currentViewIndex - 1]) {
                setViewId(viewsOrder[currentViewIndex - 1].id);
            }
        } catch (err) {
            await logError(`onboarding.navigateViews`, err);
        }
    };

    /**
     * Refresh the data inventory.
     *
     */

    const refreshDataInventory = async () => {
        try {
            // Load data inventory
            const dataInventory = await makeEndpointRequest(`GetDataInventory`, {
                onlyPublishedStorageLocations: false,
                bundleStorageLocations: false,
            });

            // Set the data
            setDataInventory(dataInventory);
        } catch (err) {
            await logError(`onboarding.refreshDataInventory`, err);
            return false;
        }
    };

    const loadFlags = async () => {
        try {
            if (window.isCypressTestEnvironment) return true;

            const missingSubProcessors = await checkUserFlag('missingSubProcessors', false);
            const showDeleteInsteadOfArchive = await checkUserFlag(`onboarding-flow-show-delete-instead-of-archive`, false);

            setFlags({
                ...flags,
                missingSubProcessors,
                showDeleteInsteadOfArchive,
            });
        } catch (err) {
            await logError(`onboarding.loadFlags`, err);
        }
    };

    /**
     * Generate a description of activity for the company based on their website.
     */

    const generateCompanyDescription = async (domains = null) => {
        try {
            // If already generating.
            if (tasksPending.generatingDescription) return false;

            // Mark that the task has started
            setTasksPending((c) => ({ ...c, generatingDescription: true }));

            // Make endpoint..
            const response = await makeEndpointRequest(`GenerateCompanyDescription`, {
                domains: domains ? domains : companyData.companyDomains,
            });

            if (response.length > 0) {
                updateCompanyData({
                    companyDescription: response,
                });
            }

            // Mark that the task has finished
            setTasksPending((c) => ({ ...c, generatingDescription: false }));
        } catch (err) {
            // Mark that the task has finished
            setTasksPending((c) => ({ ...c, generatingDescription: false }));

            // Log the error.
            await logError(`onboarding.generateCompanyDescription`, err);
        }
    };

    /**
     * Generate core activities for the company based on their description.
     */

    const generateCompanyActivities = async (companyDescription, currentActivities) => {
        try {
            // If already generating.
            if (tasksPending.generatingActivities) return false;

            // Mark that the task has started
            setTasksPending((c) => ({ ...c, generatingActivities: true }));

            // Make endpoint..
            const response = await makeEndpointRequest(`GenerateCompanyActivities`, {
                companyDescription,
            });

            if (response.length > 0) {
                const manuallyCreatedEntries = currentActivities.filter((c) => c.origin === 'manual');

                updateCompanyData({
                    services: [...response, ...manuallyCreatedEntries],
                });
            }

            // Mark that the task has finished
            setTasksPending((c) => ({ ...c, generatingActivities: false }));
        } catch (err) {
            // Mark that the task has finished
            setTasksPending((c) => ({ ...c, generatingActivities: false }));

            // Log the error.
            await logError(`onboarding.generateCompanyActivities`, err);
        }
    };

    const loadVitalRecommendations = async () => {
        try {
            const res = await makeEndpointRequest('GetVitalRecommendations');
            setVitalRecommendations(res);
        } catch (err) {
            await logError(`loadVitalRecommendations`, err);
        }
    };

    const loadRecommendedTools = async () => {
        try {
            const data = await makeEndpointRequest(`getStorageLocationsOptions`);
            setToolsRecommended(data);
        } catch (err) {
            await logError('loadRecommendedTools', err);
        }
    };

    /**
     * Updates a process in the data inventory and then update local data inventory.
     * @param {*} _id
     * @param {*} payload
     * @param {*} additionalPayload
     */

    const updateProcess = async (_id, payload = {}, additionalPayload = {}) => {
        try {
            // Update process
            const updatedProcess = await makeEndpointRequest(`UpdateProcess`, {
                _id: _id,
                payload,
                additionalPayload,
            });

            // Update data inventory
            setDataInventory((currentInventory) => {
                let newInventory = { ...currentInventory };

                let index = currentInventory.processes.findIndex((g) => g._id === _id);
                if (index === -1) return currentInventory;

                // Update inventory
                newInventory.processes[index] = updatedProcess;
                return newInventory;
            });
        } catch (err) {
            await logError(`onboarding.updateProcess`, err);
        }
    };

    /**
     * Updates the company data using an API, and returns the updated company data. It also updates the local company data and syncs it to the session.
     * @param {*} payload - The data to be updated
     * @returns {Object} - The updated company data
     */

    const updateCompanyData = async (payload = {}) => {
        try {
            const response = await makeEndpointRequest(`SaveCompanyInfo`, {
                update: payload,
            });

            setCompanyData(response.data.SaveCompanyInfo);

            return response.data.SaveCompanyInfo;
        } catch (err) {
            await logError(`onboarding.updateCompanyData`, err, { payload });
        }
    };

    /**
     * Creates an individual in our data inventory.
     * @param {*} individualName
     * @returns
     */

    const createIndividual = async (individualName) => {
        try {
            // Get recommendations for this process.
            let rec = vitalRecommendations.individuals.find((c) => c.label === individualName);

            // Format referring role
            let referringRole = rec && rec.type && getIndividualTypesIdFromLabel(rec.type) ? getIndividualTypesIdFromLabel(rec.type) : null;

            // Create individual..
            const newIndividual = await makeEndpointRequest(`CreateIndividual`, {
                payload: {
                    label: individualName,
                },
                individualReferring: {
                    referringRole: referringRole || undefined,
                    isChildren: individualName === 'Children' ? true : undefined,
                },
            });

            // Add this new individual to data inventory
            setDataInventory((currentState) => {
                // Format new data
                let newState = { ...currentState };

                // Change it..
                newState.individuals.push(newIndividual);

                return newState;
            });

            return newIndividual;
        } catch (err) {
            await logError(`onboarding.createIndividual`, err);
            return null;
        }
    };

    const deleteIndividual = async (_id, refreshInventory = true) => {
        try {
            // Delete it using the API
            await makeEndpointRequest(`DeleteIndividual`, { _id });

            if (refreshInventory) {
                // Refresh data inventory
                await refreshDataInventory();
            }
        } catch (err) {
            await logError(`onboarding.deleteIndividual`, err, { _id });
        }
    };

    /**
     * Deletes an element from the Data Inventory plus removes any links to any storage location or matrice.
     * @param {*} _id The ID of the element
     * @param {*} refreshInventory A boolean that defines if the data inventory in the onboarding should be refreshed.
     */

    const deleteElement = async (_id, refreshInventory = true) => {
        try {
            // Delete it using the API
            await makeEndpointRequest(`DeleteElement`, { _id });

            // We also need to refresh the data inventory after an element is deleted. This can be skipped when this function is executed within a loop.

            if (refreshInventory) {
                // Refresh data inventory
                await refreshDataInventory();
            }
        } catch (err) {
            await logError(`onboarding.deleteElement`, err);
            return null;
        }
    };

    const createElement = async (elementCreated, refreshInventory = true) => {
        try {
            await makeEndpointRequest(`CreateElement`, {
                payload: {
                    label: elementCreated.label,
                    sensitive: elementCreated.sensitive || false,
                },
            });

            // If we have to refresh the inventory.
            if (refreshInventory) {
                await refreshDataInventory();
            }
        } catch (err) {
            await logError(`onboarding.createElement`, err);
        }
    };

    const updateStorageLocation = async (_id, payload = {}, options = {}) => {
        try {
            // Update storage location
            const updatedLocation = await makeEndpointRequest(`UpdateStorageLocation`, { _id, payload, options });

            // Update local state.
            setDataInventory((currentState) => {
                let newState = { ...currentState };

                const currentIndex = newState.storageLocations.findIndex((d) => d._id === _id);
                if (currentIndex === -1) return currentState;

                newState.storageLocations[currentIndex] = updatedLocation;

                return newState;
            });
        } catch (err) {
            await logError(`onboarding.updateStorageLocation`, err, { _id, payload });
        }
    };

    const deleteStorageLocation = async (_id) => {
        try {
            const match = dataInventory.storageLocations.find((c) => c._id === _id);
            if (!match) throw new Error(`This storage location id does not exist.`);

            // Delete it
            await makeEndpointRequest(`DeleteStorageLocation`, {
                _storageLocationId: match._storageLocationId,
            });

            // Update local state.
            setDataInventory((currentState) => {
                let newState = { ...currentState };

                const currentIndex = newState.storageLocations.findIndex((d) => d._id === _id);
                if (currentIndex === -1) return currentState;

                newState.storageLocations.splice(currentIndex, 1);

                return newState;
            });
        } catch (err) {
            await logError(`onboarding.deleteStorageLocation`, err, { _id });
        }
    };

    const scanWebsites = async (domains = []) => {
        try {
            if (tasksPending.scanningWebsites) return false;

            // Mark that we are now scanning.
            setTasksPending((c) => ({ ...c, scanningWebsites: true }));

            // Get API..
            const res = await makeEndpointRequest(`ScanWebsiteDomain`, { domains });

            // We need to filter out existing tools that are already present in our inventory
            const scanResults = res.filter(({ label }) => {
                // Check if this storage location already exists in our inventory.
                const alreadyExists = dataInventory.storageLocations.find((d) => d.label.toLowerCase().includes(label.toLowerCase()));

                // If it does exist we won't mark it as a result.
                if (alreadyExists) return false;

                return true;
            });

            // Set scanner results
            setScanner({
                // If we are on data inventory screen it means we will see the results of the scan right away.
                reviewed: false,
                results: scanResults,
                lastScannedAt: new Date(),
            });

            // Add the tools scanned
            if (scanResults.length > 0) {
                addToolsFromScan(scanResults.map((c) => c.label));
            }

            // Mark that we finished scanning
            setTasksPending((c) => ({ ...c, scanningWebsites: false }));
        } catch (err) {
            // Mark that we finished scanning
            setTasksPending((c) => ({ ...c, scanningWebsites: false }));

            await logError(`onboarding.scanWebsites`, err, { websites });
        }
    };

    const addToolsFromScan = async (toolLabels = []) => {
        try {
            // Mark that we are now adding the tools
            setTasksPending((c) => ({ ...c, addingScannedTools: true }));

            // Iterate and create
            for (const [index, label] of toolLabels.entries()) {
                // Check if the current label is the last one
                const isLastLabel = index === toolLabels.length - 1 ? true : false;

                // Make the API Request
                const response = await makeLocalEndpointRequest(`/api/v1/storageLocations/pickToolFromRecommendations`, {
                    label,
                    options: {
                        syncToSession: isLastLabel, // Set syncToSession to true only for the last label
                        updateCookieBanner: isLastLabel,
                        responseType: 'inventory',
                    },
                });

                if (isLastLabel) {
                    // Update data inventory
                    setDataInventory(response);
                }
            }

            // Mark that we finished
            setTasksPending((c) => ({ ...c, addingScannedTools: false }));
        } catch (err) {
            // Mark that we finished
            setTasksPending((c) => ({ ...c, addingScannedTools: false }));

            await logError(`onboarding.addToolsFromScan`, err);
        }
    };

    const PassedFunctions = {
        loadData,
        navigateViews,
        refreshDataInventory,
        loadFlags,
        loadVitalRecommendations,
        loadRecommendedTools,
        updateProcess,
        updateCompanyData,
        createIndividual,
        deleteElement,
        generateCompanyDescription,
        generateCompanyActivities,
        updateStorageLocation,
        deleteStorageLocation,
        scanWebsites,
        getViews,
        deleteIndividual,
        createElement,
    };

    return <Context.Provider value={PassedFunctions}>{props.children}</Context.Provider>;
};

export default Component;
