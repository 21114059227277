import React, { useState } from 'react';
import { AuthStore } from '../../../../../utils/context/authStore';
import { makeLocalEndpointRequest } from '../../../../../utils/endpoints';
import { trackUserInteraction, logError } from '../../../../../utils/helpers';

// Components
import CancelSubscription from './components/cancelSubscription';

const Component = () => {
    const { account } = AuthStore();
    const [cancelSubscription, setCancelSubscription] = useState(false);
    const allowCancellationsThroughApp = false;

    const updateBillingDetails = async () => {
        try {
            await trackUserInteraction(`Selected "Update Billing Details"`);
            const data = await makeLocalEndpointRequest(
                `/stripe/create-customer-portal-session`,
                {
                    customerId: account.stripeCustomerId,
                },
                `POST`,
            );
            window.location.href = data;
        } catch (err) {
            await logError(`GENERATE_LINK_UPDATE_BILLING_DETAILS`, err);
            window.showAlert('Having Difficulties', `We're encountering technical difficulties, please try again.`, `error`);
        }
    };

    const dismissModal = async () => {
        setCancelSubscription(false);
    };

    const onCancelSubscription = async () => {
        await trackUserInteraction(`Selected "Cancel Subscription"`);
        setCancelSubscription(true);
    };

    return (
        <React.Fragment>
            <div className="section billing">
                <div className="section-header first">Company Billing</div>

                <div className="entries">
                    <div className="entry" onClick={() => updateBillingDetails()}>
                        <div className="content">
                            <div className="left-side">
                                <div className="title">Open Billing Center</div>
                                <div className="description">
                                    Download your invoices, update your credit card details, manage your subscription and billing
                                    information.
                                </div>
                            </div>
                            <div className="right-side">
                                <div className="icon-container">
                                    <i className="icon fa-light fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    {allowCancellationsThroughApp && (
                        <div className="entry" onClick={() => onCancelSubscription()}>
                            <div className="content">
                                <div className="left-side">
                                    <div className="label">Cancel Subscription</div>
                                    <div className="description">
                                        Thinking of leaving us? Many people love our product but in case you wish to cancel your
                                        subscription, click here.
                                    </div>
                                </div>
                                <div className="right-side">
                                    <div className="icon-container">
                                        <i className="icon fa-light fa-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {cancelSubscription && allowCancellationsThroughApp && <CancelSubscription dismissModal={dismissModal} />}
        </React.Fragment>
    );
};

export default Component;
