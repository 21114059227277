import React from 'react';

// Components
import { TextField } from '@mui/material';

// Context
import { ViewContext } from '../../..';

// Dependencies
import { getValidationPropFields } from '../../../../../../../../utils/helpers';

const Component = () => {
    const { form, updateData, validationKeys, submitted } = ViewContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="label">Privacy Queries Email</div>
                <div className="input">
                    <TextField
                        autoFocus
                        id="email"
                        fullWidth
                        disabled={submitted}
                        variant="outlined"
                        data-cy={`input-email`}
                        placeholder="name@domain.com"
                        value={form.inquiriesEmail}
                        onChange={(e) => updateData('inquiriesEmail', e.target.value)}
                        {...getValidationPropFields(validationKeys, form.inquiriesEmail, `inquiriesEmail`)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
