import React, { createContext, useState, useContext, useEffect } from 'react';

// Components
import Container from '../../layout/container';
import Header from './components/header';
import LoadingContainer from './components/loading';
import Controls from './components/controls';
import Entries from './components/entries';
import { logError, trackUserInteraction } from '../../../utils/helpers';
import { makeEndpointRequest, makeLocalEndpointRequest } from '../../../utils/endpoints';
import { filterQuestions } from './utils/functions';
import { AuthStore } from '../../../utils/context/authStore';

// Context
const Context = createContext({});
export const ModuleState = () => useContext(Context);

const Component = () => {
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const { account, globalFlags } = AuthStore();

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const [dataControls, setDataControls] = useState({
        search: '',
        filters: [],
    });
    const [filtering, setFiltering] = useState(false);
    const [expandedQuestions, setExpandedQuestions] = useState({});
    const [editingId, setEditingId] = useState(null);
    const [settingsMeta, setSettingsMeta] = useState(null);
    const [selectedEntities, setSelectedEntities] = useState([]);

    const loadData = async () => {
        try {
            setLoading(true);

            // Call this experimental API to get our company's questions.
            const res = await makeLocalEndpointRequest(`/api/questionsAndAnswers/getList`, {}, 'GET');
            setData(res);
            setFilteredData(res);

            // We'll execute a quick search to cache their questions.
            // executeEmbeddingSearch('LOAD'); // load is just a text I came up with. Nothing special to it.

            // @Reminder: In the next months we should combine the api above and this one.
            const meta = await makeEndpointRequest(`getSettingsMeta`);
            setSettingsMeta(meta);

            setLoading(false);
        } catch (err) {
            await logError(`questionsAndAnswers.loadData`, err);
            setLoading(false);
            return false;
        }
    };

    const createEntry = async () => {
        try {
            setSubmitted(true);

            // Create the new entry
            const entry = await makeLocalEndpointRequest(`/api/questionsAndAnswers/createQuestion`, {}, 'POST');

            // Add it to the local state
            setData((cs) => [...cs, entry]);

            // Set it to be expanded
            setExpandedQuestions((cs) => ({ ...cs, [entry._id]: true }));

            // Scroll down so they see it
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });

            setSubmitted(false);

            return entry;
        } catch (err) {
            await logError(`questionsAndAnswers.createEntry`, err);
            setSubmitted(false);
        }
    };

    const updateEntities = async (requests) => {
        try {
            // This will update the data in bulk.
            for (const request of requests) {
                // Update local state.
                setData((cs) => {
                    let cState = [...cs];

                    // Get the index
                    const index = cState.findIndex((c) => c._id === request._id);
                    if (index === -1) return cState;

                    cState[index] = { ...cState[index], ...request.payload, updatedAt: new Date(), updatedBy: account.email };

                    return cState;
                });
            }

            // Update.
            await makeLocalEndpointRequest(`/api/questionsAndAnswers/updateQuestionsBulk`, {
                entities: requests,
            });
        } catch (err) {
            await logError(`questionsAndAnswers.updateEntities`, err);
            return false;
        }
    };

    const deleteEntities = async (ids) => {
        try {
            setSubmitted(true);

            // This will update the data in bulk.
            for (const id of ids) {
                // Update local state.
                setData((cs) => {
                    let cState = [...cs];

                    // Get the index
                    const index = cState.findIndex((c) => c._id === id);
                    if (index === -1) return cState;

                    cState.splice(index, 1);

                    return cState;
                });
            }

            await makeLocalEndpointRequest(`/api/questionsAndAnswers/deleteQuestionsBulk`, {
                ids,
            });

            setSubmitted(false);
        } catch (err) {
            await logError(`questionsAndAnswers.deleteEntities`, err);
            setSubmitted(false);
            return false;
        }
    };

    const executeEmbeddingSearch = async (text) => {
        try {
            const vanillaSearch = () => {
                // A function and a shortcut variable is all i need!
                const clean = (s) => s.toString().toLowerCase().trim();
                const search = dataControls.search;

                // Filtering like in the old ways..
                let result = data.filter((c) => clean(c.answer).includes(clean(search)) || clean(c.question).includes(clean(search)));

                return result;
            };

            if (!globalFlags.algoliaSearch) return vanillaSearch();

            const res = await new Promise(async (resolve) => {
                // If search takes more than 2 seconds we'll do a basic javascript search.
                let failSafe = setTimeout(() => {
                    // Filtering like in the old ways..
                    let result = vanillaSearch();

                    // Result.
                    resolve(result);
                }, 2000);

                // Call the API
                const entries = await makeLocalEndpointRequest(
                    `/api/questionsAndAnswers/search`,
                    {
                        text,
                    },
                    'POST',
                );

                // Clearing the timeout so it's not double called.
                clearTimeout(failSafe);

                // Resolving it.
                resolve(entries);
            });

            return res;
        } catch (err) {
            await logError(`qa.executeAdvancedSearch`, err);
        }
    };

    const filterData = async () => {
        try {
            if (data.length < 1 || settingsMeta === null) return false; // Too early.

            setFiltering(true);

            let entries = [...data];

            const clean = (s) => s.toString().toLowerCase().trim();

            // If we're searching
            if (clean(dataControls.search).length > 0) {
                entries = await executeEmbeddingSearch(dataControls.search);
            }

            // Filter now
            entries = filterQuestions({ settingsMeta, data: entries }, dataControls.filters);

            setFilteredData(entries);

            setFiltering(false);
        } catch (err) {
            await logError(`questionnaire.filterData`, err);
            return false;
        }
    };

    useEffect(() => {
        loadData();

        trackUserInteraction(`Questions And Answers`);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        filterData();
        // eslint-disable-next-line
    }, [dataControls, data]);

    const ContextProps = {
        loading,
        setLoading,
        data,
        filteredData,
        setData,
        dataControls,
        setDataControls,
        settingsMeta,
        setSettingsMeta,
        selectedEntities,
        setSelectedEntities,
        createEntry,
        submitted,
        setSubmitted,
        expandedQuestions,
        setExpandedQuestions,
        updateEntities,
        deleteEntities,
        editingId,
        setEditingId,
        // filtering
        filtering,
    };

    // to add error page.

    return (
        <Container
            title={`Questions & Answers`}
            classNames="page-security-measures page-security-measure-shared page-questions-and-answers"
            isAlternateBackground={true}
        >
            <Context.Provider value={ContextProps}>
                <LoadingContainer loading={loading}>
                    <Header />
                    <Controls />
                    <div className="page-content">
                        <Entries />
                    </div>
                </LoadingContainer>
            </Context.Provider>
        </Container>
    );
};

export default Component;
