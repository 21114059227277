import React from 'react';
import { ComponentState } from '../../..';

const Component = () => {
    const { data } = ComponentState();

    if (data.cookieBannerSettings.enabled) return null;

    return (
        <React.Fragment>
            <div className="step-entry">
                <div className="step-header">
                    <div className="step-badge">Step 1</div>
                    <div className="step-label">Activate this feature</div>
                </div>
                <div className="step-content">
                    <div className="step-two-rows">
                        <div className="parent-container-rows" style={{ flex: 1 }}>
                            <div className="step-text">
                                To implement the cookie banner on your website you must activate the feature first from the "Settings" tab.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
