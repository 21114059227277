import React, { useState, useContext, createContext, useEffect } from 'react';

// Components
import Name from './components/name';
import SecurityMeasures from './components/securityMeasures';
import DataResidency from './components/dataResidency';
import ExecutedDPALink from './components/executedDpaLink';
import DpaType from './components/dpaType';
import ExternalLinks from './components/externalLinks';

// Panel Context
import { PanelContext } from '../..';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { data, validateView } = PanelContext();

    // Validation requirements
    const [validationResults, setValidationResults] = useState({});

    const validateFields = async () => {
        const { results } = await validateView('information');
        setValidationResults(results);
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    const PassedProps = {
        // Validation results
        validationResults,
        setValidationResults,
    };

    return (
        <Context.Provider value={PassedProps}>
            <div className="component-forms">
                <Name />
                <SecurityMeasures />
                <DataResidency />
                <DpaType />
                {data.dpaType === 'signature' && <ExecutedDPALink />}

                <ExternalLinks />
            </div>
        </Context.Provider>
    );
};

export default Component;
