import React from 'react';
import { logError, parseErrorBody } from '../../utils/helpers';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorMsg: null };
    }

    componentDidCatch(error) {
        logError(`UNCAUGHT_ERROR`, error, {
            page: window.location.href,
            _accountId: window.account ? window.account._id : 'Not logged',
        });
        this.setState({ hasError: true, errorMsg: parseErrorBody(error) });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <React.Fragment>
                    <h1>Something went wrong.</h1>
                    <hr />
                    <div style={{ width: '70vw' }}>{this.state.errorMsg}</div>
                </React.Fragment>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
