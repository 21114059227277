import React from 'react';

// Components
import Heading from './components/heading';
import SearchBar from './components/search';
import Entries from './components/entries';

const Component = () => {
    return (
        <React.Fragment>
            <Heading />
            <div className="recommendations-entries">
                <SearchBar />
                <Entries />
            </div>
        </React.Fragment>
    );
};

export default Component;
