import React, { useState } from 'react';

// Components
import Task from './components/task';
import ModuleHeader from './components/moduleHeader';

// Context
import { ComponentState } from '../..';

// Dependencies
import { getModules } from './utils';
import { trackUserInteraction } from '../../../../../utils/helpers';
import { Button } from '@mui/material';

const Component = () => {
    const { data, setRoute, setSecondRoute, securityMeasures, submitData, submitted, isModuleTaskCompleted } = ComponentState();
    const [modulesListed] = useState(getModules(securityMeasures));

    const onTaskSelected = (data) => {
        if (submitted) return false; // not the time.

        // Track interaction..
        trackUserInteraction(`Selected Task "${data.label}" from Module`);

        if (data.id === 'service-information') {
            setRoute('describe-service');
        }

        if (data.id === 'sub-processors') {
            // Check if your role is completed
            const isYourRoleCompleted = isModuleTaskCompleted('roles', null);

            // Is not..
            if (!isYourRoleCompleted) {
                window.showAlert(
                    'Not Permitted',
                    `Please complete the section 'Your role in this DPA' before selecting your sub-processors.`,
                    `error`,
                );
                return false;
            }

            setRoute('sub-processors');
        }

        if (data.id === 'restricted-transfers') {
            setRoute('restricted-transfers');
        }

        if (data.id === 'roles') {
            setRoute('roles');
        }

        if (data.id === 'terms') {
            setRoute('terms');
        }

        if (data.type === 'securityMeasure') {
            setRoute('security-measures');
            setSecondRoute(data.id);
        }
    };

    const allModuleCompleted = () => {
        let modules = [...modulesListed];

        modules = modules.filter((module) => {
            let arr = module.entries
                .map((x) => {
                    const isSecurityMeasure = x.type === 'securityMeasure';
                    return isModuleTaskCompleted(isSecurityMeasure ? 'security-measures' : x.id, isSecurityMeasure ? x.id : null);
                })
                .filter((x) => x === false);

            return arr.length > 0 ? false : true;
        });

        return modules.length === modulesListed.length;
    };

    return (
        <React.Fragment>
            <div className="route-header main-route">
                <div className="left-side">
                    <div className="title">Complete the steps below to generate your Data Processing Agreement</div>
                </div>
            </div>
            <div className="route-content start">
                <div className="sections">
                    {modulesListed.map((module, ix) => (
                        <div className="section" key={ix}>
                            <ModuleHeader data={module} onTaskSelected={onTaskSelected} />
                            <div className="tasks">
                                {module.entries.map((entry, ix) => (
                                    <Task key={ix} onSelected={() => onTaskSelected(entry)} data={entry} />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="submit-data">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={submitted || !allModuleCompleted()}
                        data-cy="btn-submit"
                        onClick={submitData}
                    >
                        <i className={`icon fa-regular ${!allModuleCompleted() ? 'fa-lock' : 'fa-paper-plane-top'}`}></i>
                        <div className="text">{allModuleCompleted() ? (data.data.published ? 'Re-Submit' : 'Submit') : 'In Progress'}</div>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
