import React from 'react';

// Components
import { MenuItem, Select } from '@mui/material';

// Context
import { PanelContext } from '../../..';

const Component = () => {
    const { data, setData } = PanelContext();

    const options = [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ];

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Core Service</div>
                </div>
                <div className="component-description">
                    <div className="text">
                        Is this process a core business activity that defines the main value you offer to your customers?
                    </div>
                </div>

                <div className="component-content">
                    <Select
                        onChange={(ev) => setData({ ...data, isCoreService: ev.target.value })}
                        fullWidth={true}
                        variant="standard"
                        value={data.isCoreService}
                    >
                        {options.map((c, ix) => (
                            <MenuItem key={ix} value={c.value}>
                                {c.label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
