import { CircularProgress } from '@mui/material';
import React from 'react';

const Section = props => {
    return (
        <div className="section loading">
            <div className="section-content">
                {props.data === null ? (
                    <React.Fragment>
                        <p className="loading-text">An error occurred, please come back later.</p>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <p className="loading-text">Preparing the goodies...</p>
                        <CircularProgress />
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default Section;
