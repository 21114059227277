import React, { createContext, useContext, useEffect } from 'react';

// Dependencies
import Container from '../../layout/container';

// Components
import Input from './components/input';
import Conversation from './components/conversation';
import InstructionBanner from './components/instructionBanner';

// Dependencies
import { makeEndpointRequest, makeLocalEndpointRequest } from '../../../utils/endpoints';
import { logError, trackUserInteraction, useStateRef } from '../../../utils/helpers';
import { Button } from '@mui/material';

// Context
const Context = createContext({});
export const PageContext = () => useContext(Context);

const Component = () => {
    const [data, setData] = useStateRef({
        input: '',
        conversation: [],
        waitingReply: false,
        prefetched: false,
    });

    const addToConversation = (sender, message) => {
        setData((currentState) => {
            const newArr = [...currentState.conversation];

            newArr.push({
                sender,
                message,
                animShowUp: true,
            });

            return { ...currentState, conversation: newArr };
        });
    };

    useEffect(() => {
        // Scroll down onw..
        const container = document.getElementById('conversation-container');
        container.scrollTop = container.scrollHeight;
    }, [data.conversation]);

    const sendMessage = async () => {
        // get the input
        const inputMessage = data.input;
        if (inputMessage.trim().length < 1) return false;

        try {
            // Add your message to the conversation history
            addToConversation(`User`, inputMessage);

            // Delete his input now
            setData((currentState) => ({ ...currentState, input: '' }));

            // Mark we're waiting for reply..
            setData((currentState) => ({ ...currentState, waitingReply: true }));

            // Is not prefetched. We need to cache embeddings.
            if (!data.prefetched) {
                let dataLoaded = false;
                let attempts = 0;

                trackUserInteraction(`Training Virtual DPO`);

                while (dataLoaded === false && attempts < 5) {
                    attempts++;
                    try {
                        await makeLocalEndpointRequest(`/api/ai/dpo/prefetch`, { message: inputMessage }, 'POST', null, {
                            axios: {
                                timeout: 90000,
                            },
                        });
                        dataLoaded = true;
                    } catch (err) {
                        // nothing. we just keep waiting.
                    }
                }

                trackUserInteraction(`Finished Training Virtual DPO`);

                setData((currentState) => ({ ...currentState, prefetched: true }));
            }

            // Call the API..
            const { response } = await makeLocalEndpointRequest(
                `/api/ai/dpo`,
                {
                    message: data.input,
                },
                'POST',
                null,
                {
                    axios: {
                        timeout: 90000,
                    },
                },
            );

            // Mark we're not for reply..
            setData((currentState) => ({ ...currentState, waitingReply: false }));

            // Add his response here..
            addToConversation('Bot', response);
        } catch (err) {
            await logError(`virtualDPO:sendMessage`, err);

            // Inform
            addToConversation(`Bot`, `We're encountering technical difficulties. Please try your sending your message again.`);

            trackUserInteraction(`Virtual DPO - Having Difficulties`, { reason: `The bot failed to reply.` });

            setData((currentState) => ({ ...currentState, input: inputMessage, waitingReply: false }));
        }
    };

    const loadConversation = async () => {
        try {
            const res = await makeEndpointRequest('GetDPOWebConversation');

            // Set the result..
            setData((currentState) => {
                return { ...currentState, conversation: res };
            });
        } catch (err) {
            await logError(`LOAD_CONVERSATION`, err);
            return false;
        }
    };

    const deleteConversation = async () => {
        try {
            await makeEndpointRequest(`DeleteConversationDPO`);
            setData((currentState) => ({
                ...currentState,
                conversation: [],
            }));
        } catch (err) {
            await logError(`DELETE_CONVERASTION`, err);
        }
    };

    const confirmConversationDelete = async () => {
        window.showAlert('Confirmation', `Are you sure you want to clear your conversation history?`, 'warning', [
            {
                text: 'Dismiss',
                dataCy: `alert-submit-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },

            {
                text: `Delete`,
                dataCy: `alert-cancel-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                    deleteConversation();
                },
            },
        ]);
    };

    useEffect(() => {
        loadConversation();
        // eslint-disable-next-line
    }, []);

    const ContextProps = {
        data,
        setData,
        sendMessage,
    };

    return (
        <Container title="Virtual DPO" classNames="page-virtual-dpo" fullWidth={true}>
            <Context.Provider value={ContextProps}>
                <div className="page-header">
                    <div className="left-side">
                        <div className="label">Virtual DPO</div>
                        <div className="badge warning">This is an experimental AI</div>
                    </div>
                    <div className="right-side">
                        {data.conversation.length > 0 && (
                            <Button variant="contained" color="primary" disabled={data.waitingReply} onClick={confirmConversationDelete}>
                                Clear Conversation History
                            </Button>
                        )}
                    </div>
                </div>
                <div className="page-content">
                    {data.conversation.length < 1 && <InstructionBanner />}
                    <Conversation />
                    <Input />
                </div>
            </Context.Provider>
        </Container>
    );
};

export default Component;
