import { FormHelperText, Switch } from '@mui/material';
import React from 'react';

// Context
import { PanelContext } from '../../..';
import { ViewContext } from '..';

const Component = () => {
    const { data, vitalRecommendations } = PanelContext();
    const { validationResults, setType } = ViewContext();

    const retentionPeriods = vitalRecommendations.retentionPeriods || [];

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Retention duration</div>
                </div>
                <div className="component-description">
                    <div className="text">How long is the data for the purpose of {data.label || 'Unnamed process'} stored for?</div>
                </div>
                <div className="component-content">
                    <div className="retention-periods">
                        {retentionPeriods.map((c, ix) => (
                            <Entry
                                key={ix}
                                data={c}
                                id={`recommendations:${c.id}`}
                                isChecked={data.retention.type === `recommendations:${c.id}`}
                                onClick={() => setType(`recommendations:${c.id}`)} // This will set the type to be recommendations:{id}
                            />
                        ))}
                    </div>
                    {/* Error */}
                    {validationResults['retention.type'] && (
                        <React.Fragment>
                            <FormHelperText style={{ marginTop: 16 }} error={true}>
                                {validationResults['retention.type']}
                            </FormHelperText>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export const Entry = (props) => {
    const { data } = PanelContext();

    const isOptionDisabled = (id) => {
        // Shortcuts due to long names
        const controllerId = `manual:asRequiredByController`;
        const currentId = data.retention.type;
        const currentRole = data.role;

        // If we are a processor we must disable everything. We'll enforce the controller entry.
        if (currentRole === 'Processor' && id !== controllerId) return true;

        // Once controller is set we must disable it. (We don't disable every time, just in case due to some bug they have it unchecked)
        if (currentRole === 'Processor' && id === controllerId && currentId === controllerId) return true;

        return false;
    };

    return (
        <div className="--component-entry">
            <div className="switch">
                <Switch id={props.id} checked={props.isChecked} disabled={isOptionDisabled(props.id)} onClick={props.onClick} />
            </div>

            <div className="details">
                <div className="label">{props.data.name}</div>
                <div className="description">{props.data.explanation}</div>
            </div>
        </div>
    );
};

export default Component;
