import React, { useEffect, useState } from 'react';

import lodash from 'lodash';
import { removeFromUrlQueryString, getFromUrlQueryString, logError, useStateRef, setInUrlQueryString } from '../../../../utils/helpers';
import { Button } from '@mui/material';

import Base from '../base';
import Entry from './components/entry';
import { getFiltersFromSlugParams, isValueNotRequired, transformFilters } from './utils/functions';

let timerChanges = null;

const Component = (props) => {
    const [open, setOpen, openRef] = useStateRef(false);
    const [entries, setEntries] = useState([]);
    const [urlChecked, setUrlChecked] = useState(false);

    // Definitions
    const containerTargetId = 'component-filters-container';

    const cleanupFields = () => {
        const newEntries = [...entries].filter((entry) => {
            const match = props.fields.find((d) => d.key === entry.key);

            if (!match) return false;

            return true;
        });

        // If nothing changed.
        if (JSON.stringify(entries) === JSON.stringify(newEntries)) return false;

        setEntries(newEntries);
        props.onChanges(newEntries);
    };

    useEffect(() => {
        if (open === true && props.onChanges) {
            if (timerChanges) clearTimeout(timerChanges);
            timerChanges = setTimeout(() => {
                onChangesEntries(entries);
            }, 500);
        }

        if (props.urlSupport && urlChecked) {
            updateFiltersInURL();
        }

        // eslint-disable-next-line
    }, [entries]);

    useEffect(() => {
        // For when we add dynamic filter fields we must make sure that existing filters don't feature fields that no longer exist.
        cleanupFields();

        // eslint-disable-next-line
    }, [props.fields]);

    const updateEntry = (index, path, value) => {
        const arr = [...entries];
        lodash.set(arr, `[${index}].${path}`, value);

        // If the secondary changed their type we must update the others below too.
        if (path === 'type' && index === 1) {
            arr.forEach((_, ix) => {
                if (ix < 2) return;
                lodash.set(arr, `[${ix}].${path}`, value);
            });
        }

        setEntries(arr);
    };

    const onChangesEntries = (entries) => {
        let arr = [...entries];

        // We must filter the invalid filters..
        arr = arr.filter((elm) => {
            if (!isValueNotRequired(elm.filter) && elm.value.length < 1) {
                return false;
            }
            return true;
        });

        props.onChanges(arr);
    };

    const addNewCondition = () => {
        const arr = [...entries];

        let type = 'primary';

        if (arr.length > 0) {
            type = arr.length > 1 ? arr[arr.length - 1].type : 'and';
        }

        arr.push({
            key: props.fields[0].key,
            filter: 'contains',
            value: '',
            type,
        });

        setEntries(arr);
    };

    const deleteEntry = (index) => {
        setEntries((currentState) => {
            let newState = [...currentState];
            newState.splice(index, 1);
            return newState;
        });
    };

    const loadFiltersFromURL = async () => {
        try {
            // Get filters..
            const res = await getFiltersFromSlugParams();

            // eslint-disable-next-line
            setEntries(res);

            // Mark that we checked the url so other function can function
            setUrlChecked(true);

            // Update filters in parent
            onChangesEntries(res);

            return true;
        } catch (err) {
            setUrlChecked(true);
            return false;
        }
    };

    const updateFiltersInURL = async () => {
        try {
            // Get the valid filters
            const validFilters = entries.filter((c) => {
                if (!isValueNotRequired(c.filter) && c.value.length < 1) return false;
                return true;
            });

            // We no longer have any filters active so let's remove the filters.
            if (validFilters.length < 1 && getFromUrlQueryString('filters')) return removeFromUrlQueryString('filters');

            // No filters to add.
            if (validFilters.length < 1) return false;

            // Transform current filters into slugs.
            const slugs = transformFilters(validFilters, 'transform');

            // Set the filter param
            setInUrlQueryString('filters', slugs);
        } catch (err) {
            await logError(`components.filter.updateFiltersInURL`, err);
            return false;
        }
    };

    useEffect(() => {
        if (props.urlSupport) {
            loadFiltersFromURL();
        }
        // eslint-disable-next-line
    }, []);

    const passedProps = {
        containerTargetId,
        open,
        setOpen,
        openRef,
        buttonId: props.buttonId,
        updateEntry,
    };

    return (
        <Base className="component-filters" {...passedProps}>
            <div className="heading">
                {entries.length < 1 ? 'No filter conditions are applied to this view.' : 'In this view, show records'}
            </div>
            {entries.length > 0 && (
                <div className="entries">
                    {entries.map((entry, ix) => (
                        <Entry
                            data={entry}
                            key={ix}
                            fields={props.fields}
                            setData={(path, value) => updateEntry(ix, path, value)}
                            isSecondary={ix === 1}
                            delete={() => deleteEntry(ix)}
                        />
                    ))}
                </div>
            )}
            <div className="buttons">
                <Button variant="outlined" size="small" onClick={addNewCondition}>
                    Add Condition
                </Button>
            </div>
        </Base>
    );
};

export default Component;
