import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

// Components
import Modal from '../../../../components/modulePopout';
import Categories from './components/categories';
import CloseButton from './components/closeButton';
import SearchBar from './components/searchBar';
import Tools from './components/tools';
import CustomTool from './customTool';

// Context
const Context = createContext({});
export const PanelContext = () => useContext(Context);

const Component = (props) => {
    const [searchValue, setSearchValue] = useState('');
    const [tools, setTools] = useState([]);
    const [category, setCategory] = useState('all');
    const [customTool, setCustomTool] = useState(null);

    // The ref that controls the panel
    const modalController = useRef(null);

    useEffect(() => {
        if (!props.innerRef) return false;

        // This will allow us to set tab and process inspected from outside the panel.
        props.innerRef.current = {
            modal: modalController,
        };

        // eslint-disable-next-line
    }, []);

    const showCustomToolModal = (label) => {
        modalController.current.close(() => {
            setCustomTool({ label: label || '' });
        });
    };

    const createCustomTool = (data) => {
        // Invoke callback
        props.createCustomTool(data);

        // If we also have picked tools
        if (tools.length > 0) {
            props.addPickedTools(tools);
        }
        // Stop the creating of custom tool
        setCustomTool(null);

        // Close panel
        props.closePanel(true);
    };

    const stopCreatingCustomTool = () => {
        // Stop the creating of custom tool
        setCustomTool(null);
    };

    const getOptions = () => {
        // Get the normal tools recommendations
        let arr = [...props.options];

        // Get the custom tools if view type is pick from inventory
        if (props.viewType === 'pickFromInventory') {
            props.dataInventory.storageLocations.forEach((tool) => {
                if (arr.find((c) => c.label === tool.label)) return false; // Already in recommendations

                arr.push({
                    label: tool.label,
                    dataResidency: tool.dataResidency,
                    image: '',
                    securityMeasuresTitle: '',
                    securityMeasuresURL: '',
                    category: 'Custom Tools',
                });
            });
        }

        return arr;
    };

    const ContextProps = {
        // Tools picked
        tools,
        setTools,
        // Search value
        searchValue,
        setSearchValue,
        // Category
        category,
        setCategory,
        // Custom tool
        customTool,
        setCustomTool,
        stopCreatingCustomTool,
        showCustomToolModal,
        // Data
        dataInventory: props.dataInventory,
        options: getOptions(),
        // View type
        viewType: props.viewType,
        // Functions
        addPickedTools: props.addPickedTools,
        createCustomTool,
        closePanel: props.closePanel,
    };

    // If they are creating a custom tool.
    if (customTool !== null) {
        return (
            <Context.Provider value={ContextProps}>
                <CustomTool />
            </Context.Provider>
        );
    }

    return (
        <Context.Provider value={ContextProps}>
            <Modal
                className={props.className}
                innerRef={modalController}
                header={{
                    title: <React.Fragment>Add a tool or third party</React.Fragment>,
                    closeButton: <CloseButton />,
                }}
                content={
                    <React.Fragment>
                        <Categories />
                        <SearchBar />
                        <Tools />
                    </React.Fragment>
                }
            />
        </Context.Provider>
    );
};

export default Component;
