import React from 'react';
import { ComponentState } from '..';
import { getModules } from '../routes/start/utils';

const Component = () => {
    const { securityMeasures, isModuleTaskCompleted } = ComponentState();

    const percentageOfCompletion = () => {
        const modules = getModules(securityMeasures);

        let totalEntries = 0;
        let checkedEntries = 0;

        modules.forEach((item) => {
            item.entries.forEach((entry) => {
                totalEntries++;

                // Checking here..
                const checked = isModuleTaskCompleted(
                    entry.type === 'securityMeasure' ? 'security-measures' : entry.id,
                    entry.type === 'securityMeasure' ? entry.id : null,
                );

                if (checked) {
                    checkedEntries++;
                }
            });
        });

        const nr = (checkedEntries / totalEntries) * 100;
        return parseInt(nr.toFixed(0));
    };

    return (
        <React.Fragment>
            <div className="--component-header">
                <div className="title">Data Processing Agreement (DPA)</div>
                <div className="progress-bar">
                    <div className="bar">
                        <div className="value" style={{ width: `${percentageOfCompletion()}%` }} />
                    </div>
                    <div className="procent">{percentageOfCompletion()}%</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
