import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

// Components
import Modal from '../../../components/modulePopout';
import Actions from './components/actions';
import Tabs from './components/tabs';

// Views
import Information from './views/information';
import Processes from './views/processes';
import Children from './views/children';
import DangerZone from './views/dangerZone';
import { trackUserInteraction, validateAgainstSchema } from '../../../../utils/helpers';
import { ChildrenViewSchema, InformationViewSchema, ProcessorViewSchema } from '../utils/validation';

// Context
const Context = createContext({});
export const PanelContext = () => useContext(Context);

const Component = (props) => {
    const [data, setData] = useState(props.initialData);
    const [tab, setTab] = useState('information');
    const [panelVisible, setPanelVisible] = useState(true);

    // The ref that controls the panel
    const modalController = useRef(null);

    // A simple way to check if current label is used by a different individual.
    const isLabelUsedByDifferentIndividual = () => {
        // Check if tool with same name already exists
        const matchIndex = props.dataInventory.individuals.findIndex((i) => i.label === data.label);
        const match = matchIndex === -1 ? null : props.dataInventory.individuals[matchIndex];

        // Does exist
        if (match && match._id !== data._id) {
            return true;
        } else return false;
    };

    const getIndividualValidation = async () => {
        let failed = null;
        let reasons = null;

        const views = ['information', 'processes', 'children'];

        for (const view of views) {
            const { status, results } = await validateView(view);

            if (status === false && failed === null) {
                failed = view;
                reasons = results;
            }
        }

        return { status: failed ? false : true, payload: { failedView: failed ? failed : null, results: reasons } };
    };

    const validateView = async (id) => {
        try {
            if (id === 'information') {
                // Validate against schema
                await validateAgainstSchema(InformationViewSchema, {
                    ...data,
                });

                // Does exist
                if (isLabelUsedByDifferentIndividual()) {
                    return {
                        status: false,
                        results: {
                            label: `Individual Name is already used. Try adding a suffix: ${data.label} (2)`,
                        },
                    };
                }
            }

            if (id === 'processes') {
                await validateAgainstSchema(ProcessorViewSchema, data);
            }

            if (id === 'children') {
                await validateAgainstSchema(ChildrenViewSchema, data);
            }

            return {
                status: true,
                results: {},
            };
        } catch (err) {
            return {
                status: false,
                results: err,
            };
        }
    };

    const showDialogAboutProcessesIncomplete = async (func, action) => {
        trackUserInteraction(`Warned about proceeding with Incomplete Processes`, { action });

        window.showAlert(
            'Confirmation',
            `Before proceeding to ${
                action === 'update' ? 'saving changes' : 'creating this individual'
            }, be aware that you have incomplete processes (missing tools or data categories) which won't be linked unless you provide the missing information. Are you sure you want to continue?`,
            'warning',
            [
                {
                    text: 'Fix Information',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        trackUserInteraction(`Selected "Complete Information"`);
                        setTab('processes');
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        trackUserInteraction(`Selected "Confirm"`);
                        func();
                    },
                },
            ],
        );
    };

    const getIncompleteProcesses = () => {
        return data.processes.filter((c) => {
            if (c.storageLocations.length < 1) return true;
            if (c.storageLocations.find((c) => c.elements.length < 1)) return true;

            return false;
        });
    };

    const createIndividual = async () => {
        // Is already closing. Avoid button spamming.
        if (modalController.current.closing === true) return false;

        const validation = await getIndividualValidation();

        const createFunc = async () => {
            await props.functions.create({
                data,
                controller: modalController,
                validation,
            });
        };

        // If we have incomplete tools
        const incompleteTools = getIncompleteProcesses();
        if (incompleteTools.length > 0) return showDialogAboutProcessesIncomplete(createFunc, 'create');

        createFunc();
    };

    const updateIndividual = async () => {
        // Is already closing. Avoid button spamming.
        if (modalController.current.closing === true) return false;

        const validation = await getIndividualValidation();

        const updateFunc = async () => {
            await props.functions.update({
                data,
                controller: modalController,
                validation,
            });
        };

        if (validation.status === false) {
            trackUserInteraction(`Selected "Save Changes" - Warned about missing Information`);
            window.showAlert(
                'Confirmation',
                `This individual is missing information. Add the missing information now or Save and come back later.`,
                'warning',
                [
                    {
                        text: 'Save Changes',
                        dataCy: `alert-cancel-button`,

                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            trackUserInteraction(`Selected "Save Changes"`);

                            // If we have incomplete tools
                            const incomplete = getIncompleteProcesses();
                            if (incomplete.length > 0) return showDialogAboutProcessesIncomplete(updateFunc, 'update');

                            // Update..
                            updateFunc();
                        },
                    },
                    {
                        text: 'Add Information',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            setTab(validation.payload.failedView);
                            trackUserInteraction(`Selected "Add Information"`);
                        },
                    },
                ],
            );

            return false;
        }

        // If we have incomplete tools
        const incomplete = getIncompleteProcesses();
        if (incomplete.length > 0) return showDialogAboutProcessesIncomplete(updateFunc, 'update');

        updateFunc();
    };

    const deleteIndividual = () => {
        // Is already closing. Avoid button spamming.
        if (modalController.current.closing === true) return false;

        window.showAlert(
            'Confirmation',
            `Are you sure you want to delete this individual? If you are sure, confirm by writing "Delete" in the input below.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        // Hide the alert..
                        dismissAlert();
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert, inputValue }) => {
                        // If they didn't enter the right one.
                        if (inputValue.toString().toLowerCase() !== 'delete') {
                            return false;
                        }

                        dismissAlert();
                        props.functions.delete({ data, controller: modalController });
                    },
                },
            ],
            {
                input: {
                    type: 'text',
                    placeholder: 'Delete',
                    validationField: ({ inputValue }) => {
                        if (inputValue.toString().toLowerCase() !== 'delete') return 'Invalid confirmation message.';

                        return null; // no error, all good.
                    },
                },
            },
        );
    };

    const onCloseButton = () => {
        // Is already closing. Avoid button spamming.
        if (modalController.current.closing === true) return false;

        const closeFunc = () => {
            trackUserInteraction(`Closed Individual Panel`);
            props.functions.closePanel();
        };

        // if is editing and we have unsaved data.
        if (JSON.stringify(data) !== JSON.stringify(props.initialData) && props.isEditing) {
            window.showAlert(
                'Confirmation',
                `Are you sure you want to exit without saving your changes? If you proceed, any unsaved changes will be discarded.`,
                'warning',
                [
                    {
                        text: `Cancel`,
                        dataCy: `alert-cancel-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                    {
                        text: 'Confirm',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();

                            // Close panel
                            closeFunc();
                        },
                    },
                ],
            );
            return false;
        }

        closeFunc();
    };

    useEffect(() => {
        if (!props.innerRef) return false;

        // This will allow us to set tab and process inspected from outside the panel.
        props.innerRef.current = {
            setTab,
            modal: modalController,
        };

        // eslint-disable-next-line
    }, []);

    const PassedProps = {
        // Data
        data,
        companyData: props.companyData,
        initialData: props.initialData,
        setData,
        // Validation
        validateView,
        // Important callbacks
        createIndividual,
        deleteIndividual,
        saveChanges: updateIndividual,
        // data Inventory
        dataInventory: props.dataInventory,
        setDataInventory: props.setDataInventory,
        // recommendations
        toolsRecommendations: props.toolsRecommendations,
        vitalRecommendations: props.vitalRecommendations,
        // Tab
        tab,
        setTab,
        // Is editing
        isEditing: props.isEditing,
        isLabelUsedByDifferentIndividual,
        panelVisible,
        setPanelVisible,
    };

    const MappedScreens = {
        information: Information,
        processes: Processes,
        children: Children,
        dangerZone: DangerZone,
    };

    const MappedScreen = MappedScreens[tab] ? MappedScreens[tab] : null;

    return (
        <React.Fragment>
            <Context.Provider value={PassedProps}>
                <div id="panel-container">
                    <Modal
                        className={`shareable-components module-panel-editor data-inventory edit-popout-model modal-individual-editor modal-process-editor`}
                        innerRef={modalController}
                        visible={panelVisible}
                        header={{
                            title: !props.isEditing ? 'Create a new Individual' : 'Edit an Individual',
                            description: `${(props.isEditing ? props.initialData.label : data.label) || 'New Individual'}`,
                            closeButton: <Actions onClose={onCloseButton} />,
                        }}
                        content={
                            <React.Fragment>
                                <Tabs />
                                <div className={`component-panel-content ${tab}`}>
                                    <div className="--container">
                                        <MappedScreen />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    />
                </div>
            </Context.Provider>
        </React.Fragment>
    );
};

export default Component;
