import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { ComponentState } from '../../../../../..';

const Component = (props) => {
    const { getTranslationFiledValue, setTranslationField } = ComponentState();
    const [expanded, setExpanded] = useState(false);

    return (
        <React.Fragment>
            <div className={`entry ${expanded === true ? 'expanded' : 'not-expanded'}`}>
                <div className="entry-heading">
                    <div className="left-side" onClick={() => setExpanded(!expanded)}>
                        <i className={`icon fa-solid fa-chevron-${expanded ? `down` : `right`}`}></i>
                    </div>
                    <div className="right-side">
                        <div className="label" onClick={() => setExpanded(!expanded)}>
                            {getTranslationFiledValue(`privacyPortal`, props.lang, `contentBlock:${props.data._blockId}`, 'heading') ||
                                'New custom clause'}
                        </div>
                        <div className="--buttons">
                            <Button variant="outlined" onClick={props.onDelete}>
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="entry-content">
                    <div className="form-group">
                        <div className="form-label">Header</div>
                        <div className="form-content">
                            <TextField
                                value={getTranslationFiledValue(
                                    `privacyPortal`,
                                    props.lang,
                                    `contentBlock:${props.data._blockId}`,
                                    'heading',
                                )}
                                onChange={(e) =>
                                    setTranslationField(
                                        `privacyPortal`,
                                        props.lang,
                                        `contentBlock:${props.data._blockId}`,
                                        `heading`,
                                        e.target.value,
                                    )
                                }
                                fullWidth={true}
                                className="input"
                                placeholder="Header"
                                variant="outlined"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-label">Content</div>
                        <div className="form-content">
                            <TextField
                                value={getTranslationFiledValue(
                                    `privacyPortal`,
                                    props.lang,
                                    `contentBlock:${props.data._blockId}`,
                                    'content',
                                )}
                                onChange={(e) =>
                                    setTranslationField(
                                        `privacyPortal`,
                                        props.lang,
                                        `contentBlock:${props.data._blockId}`,
                                        `content`,
                                        e.target.value,
                                    )
                                }
                                fullWidth={true}
                                className="input"
                                placeholder="Content"
                                variant="outlined"
                                rows={5}
                                multiline={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
