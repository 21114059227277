import React, { useState, useEffect } from 'react';
import { MenuItem, Select, TextField } from '@mui/material';

// Dependencies
import { getValidationPropFields, validateAgainstSchema } from '../../../../../../../../utils/helpers';
import { gelFlowEditModalSubProcessorSchema } from '../../../../../utils/validations';

// Components
let updateGeneralDataTimer = null;

const General = (props) => {
    const [pageData, setPageData] = useState({
        isSubProcessor: props.storageData.isSubProcessor,
        subProcessorMeasures: props.storageData.subProcessorMeasures,
    });

    const [validationResults, setValidationResults] = useState({});

    const validateFields = async () => {
        try {
            await validateAgainstSchema(gelFlowEditModalSubProcessorSchema, {
                ...pageData,
                // url and title are the sae..
                subProcessorMeasuresURL: pageData.subProcessorMeasures.url,
            });

            setValidationResults({});

            if (updateGeneralDataTimer !== null) {
                clearTimeout(updateGeneralDataTimer);
            }

            updateGeneralDataTimer = setTimeout(() => {
                props.updateStorageData(`isSubProcessor`, pageData.isSubProcessor);
                props.updateStorageData(`subProcessorMeasures`, pageData.subProcessorMeasures);
                updateGeneralDataTimer = null;
            }, 1000);
        } catch (err) {
            setValidationResults(err);
        }
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [pageData]);

    return (
        <React.Fragment>
            <div className="module-elements">
                <div className="form-group">
                    <div className="label">
                        <span className="text">Is Sub-Processor?</span>
                    </div>
                    <div className="input-container">
                        <Select
                            value={pageData.isSubProcessor}
                            fullWidth={true}
                            variant="standard"
                            onChange={(ev) => setPageData({ ...pageData, isSubProcessor: ev.target.value })}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </div>
                </div>
                <div className="form-group">
                    <div className="label">
                        <span className="text">Sub-processor's Security Policy or Page</span>
                    </div>
                    <div className="input-container">
                        <TextField
                            placeholder="Security Measures URL"
                            variant="standard"
                            value={pageData.subProcessorMeasures.url}
                            onChange={(ev) => {
                                setPageData({
                                    ...pageData,
                                    subProcessorMeasures: {
                                        title: ev.target.value,
                                        url: ev.target.value,
                                    },
                                });
                            }}
                            fullWidth={true}
                            {...getValidationPropFields(
                                validationResults,
                                pageData.subProcessorMeasures.url.length > 0,
                                `subProcessorMeasuresURL`,
                            )}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default General;
