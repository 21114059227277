import { logError } from '../../../../utils/helpers';
import { applyFilters } from '../../../components/dataControls/filters/utils/functions';

export const getFilterFields = (dependencies) => {
    let arr = [
        {
            key: 'question',
            label: 'Question',
            type: 'text',
        },
        {
            key: 'answer',
            label: 'Answer',
            type: 'text',
        },
        {
            key: 'assignedTo',
            label: 'Assigned To',
            options: dependencies.settingsMeta.companyEmails,
            type: 'text',
        },
        {
            key: 'createdBy',
            label: 'Created By',
            options: dependencies.settingsMeta.companyEmails,
            type: 'text',
        },
    ];

    // Get the unique properties keys.
    const properties = [];

    dependencies.data.forEach((q) => {
        q.properties.forEach((p) => {
            // Get the index of an existing property in the array
            const pIndex = properties.findIndex((c) => c.label === p.label);

            if (pIndex === -1) {
                properties.push({
                    label: p.label,
                    values: [p.value],
                });
            } else {
                properties[pIndex].values.push(p.value);
            }
        });
    });

    // Add it now to the array
    properties.forEach((p) => {
        arr.push({
            key: `properties:${p.label}`,
            label: `${p.label}`,
            options: p.values,
            type: 'text',
        });
    });

    return arr;
};

/**
 *  this will filter the processes
 * @param {*} dependencies
 * @param {*} optionsFilters
 * @returns
 */

export const filterQuestions = (dependencies, optionsFilters) => {
    try {
        // Get filter fields.
        const fields = getFilterFields(dependencies);

        // We simulate data like this so we can filter it.
        const simulatedData = dependencies.data.map((c) => {
            let obj = {
                _id: c._id, // so we can put it back together
                question: c.question,
                answer: c.answer,
                assignedTo: c.assignedTo ? c.assignedTo : null,
                createdBy: c.createdBy ? c.createdBy : null,
            };

            c.properties.forEach((property) => {
                obj[`properties:${property.label}`] = property.value;
            });

            return obj;
        });

        // Filter the data
        const filteredData = applyFilters(simulatedData, optionsFilters, fields);

        // We now put it back together.
        const entries = filteredData.map(({ _id }) => dependencies.data.find((c) => c._id === _id));

        return entries;
    } catch (err) {
        logError(`questionsAndAnswers.filterQuestions`, err);
        return [];
    }
};
