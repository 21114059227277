import { Button, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { ModuleState } from '../..';
import { trackUserInteraction } from '../../../../../utils/helpers';
import Tooltip from '../../../../components/tooltip';
import ManualSelection from './picker';
import { ModuleAPI } from '../../components/api';

const StepStorageLocations = () => {
    const { data, setStep, scanning, pendingTools, scannerResults, setScannerResults, isEditingSession, flags } = ModuleState();
    const { cancelScanning, showPromptOfAddedTools } = ModuleAPI();

    const previousStep = async () => {
        let lastStep = `DescribeCompany`;

        setStep(lastStep);
        await trackUserInteraction(`Selected "Go Back"`, { destination: lastStep });
    };

    const nextStep = async () => {
        let nextStep = 'Services';

        setStep(nextStep);
        await trackUserInteraction(`Selected "Go Next"`, { destination: nextStep });
    };

    useEffect(() => {
        if (scannerResults) {
            // Scanner results doesn't include temp data.
            showPromptOfAddedTools(scannerResults.toolsScanned);

            setScannerResults(null);
        }
        // eslint-disable-next-line
    }, [scannerResults]);

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className={`back-button`} onClick={previousStep} data-cy="back-button">
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Data Inventory
                    </div>
                    <div className="subtitle">
                        Add the tools and third parties that you use in your company
                        <Tooltip
                            useHTML={true}
                            trackInteraction={true}
                            interactionId="gdpr_essentials:add-tools-and-third-parties"
                            leftAligned={true}
                            content={
                                <p>
                                    A "tool or a third party" is software or services used in your business which contain Personal Data.
                                    <br />
                                    <br />
                                    This includes data about customers, employees, contactors or any other person).
                                    <br />
                                    <br />
                                    For example, you use Hubspot to store your customer contact information. Or you use Google Drive to keep
                                    your Employees' passports. This includes data about customers, employees, contactors or any other
                                    person).
                                </p>
                            }
                        >
                            <span className="tooltip">
                                <i data-cy="data-inventory-tooltip" className="tooltip-icon fa-solid fa-circle-info"></i>
                            </span>
                        </Tooltip>
                    </div>
                </div>
                <div className={`right-side`}>
                    {scanning === true && isEditingSession && (
                        <Button variant="contained" color="primary" onClick={cancelScanning} style={{ marginRight: 12 }}>
                            Skip scanning
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={
                            data.storageLocations.filter((i) => !i.archived).length < 1 || scanning === true || pendingTools.length > 0
                        }
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        {scanning == true && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress size={16} style={{ marginRight: 8 }} />
                                <span>Still scanning</span>
                            </div>
                        )}
                        {pendingTools.length > 0 && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress size={16} style={{ marginRight: 8 }} />
                                <span>Updating data</span>
                            </div>
                        )}

                        {scanning === false && pendingTools.length < 1 && 'Next'}
                    </Button>
                </div>
            </div>
            <div className="storage-locations module-content">
                <div className="sect-component">
                    <ManualSelection deleteInsteadOfArchive={flags.showDeleteInsteadOfArchive} />
                    {scanning === true && (
                        <div className="scanning-alert">
                            <div className="icon-container">
                                <i className="elm fa-solid fa-magnifying-glass fa-beat"></i>
                            </div>
                            <span>
                                {' '}
                                We're scanning your website to find tools and third parties. In the meantime you can add the ones you know
                                manually.
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default StepStorageLocations;
