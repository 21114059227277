import React, { useContext, createContext, useState, useEffect } from 'react';
import { AuthStore } from '../../../utils/context/authStore';

// Dependencies
import { logError, trackUserInteraction } from '../../../utils/helpers';
import { makeEndpointRequest } from '../../../utils/endpoints';

// Components
import Container from '../../layout/container';
import Header from './components/header';
import Controls from './components/controls';
import Forms from './components/forms';
import LoadingContainer from './components/loading';
import { useHistory } from 'react-router-dom';

// Context
const Context = createContext({});
export const ModuleState = () => useContext(Context);

const Component = () => {
    const history = useHistory();
    const [data, setData] = useState({
        name: '',
        description: '',
        category: null,
        section: null,
        gdprArt32: null,
    });

    const { globalFlags } = AuthStore();
    const [dependencies, setDependencies] = useState({ categories: [], sections: [], gdprArts: [] });

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const loadRecommendedSecurityMeasures = async () => {
        try {
            setLoading(true);
            const res = await makeEndpointRequest('GetRecommendedSecurityMeasures');

            const sections = [],
                categories = [],
                gdprArts = [];

            res.forEach((entry) => {
                if (!sections.includes(entry.details.section)) {
                    sections.push(entry.details.section);
                }

                if (!categories.includes(entry.details.category)) {
                    categories.push(entry.details.category);
                }

                if (!gdprArts.includes(entry.details.gdprArt32) && entry.details.gdprArt32) {
                    gdprArts.push(entry.details.gdprArt32);
                }
            });

            setDependencies({ sections, categories, gdprArts });
            setLoading(false);
        } catch (err) {
            await logError(`LOAD_INTERNAL_SECTIONS`, err);
        }
    };

    useEffect(() => {
        // IF they don't have access to this Module yet.
        if (globalFlags.securityMeasures !== null && !globalFlags.securityMeasures) {
            history.push('/');
            return false;
        }
        // eslint-disable-next-line
    }, [globalFlags]);

    useEffect(() => {
        loadRecommendedSecurityMeasures();
        // eslint-disable-next-line
    }, []);

    const onSubmit = async () => {
        try {
            setSubmitted(true);
            await trackUserInteraction(`Selected "Save"`);
            const entryCreated = await makeEndpointRequest(`CreateSecurityMeasure`, { payload: data });

            // Get current security measures responses
            const responses = await makeEndpointRequest('GetSecurityMeasuresResponses');

            // Update it.
            await makeEndpointRequest('UpdateSecurityMeasuresResponses', {
                payload: [
                    ...responses,
                    {
                        _id: entryCreated._id,
                        source: 'database',
                        value: true,
                        adjustedDescription: '',
                    },
                ],
            });

            setSubmitted(false);

            history.replace('/security-measures/');
        } catch (err) {
            await logError(`SUBMIT_SECURITY_MEASURE`, err, data);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
            setSubmitted(false);
        }
    };

    const ContextProps = {
        data,
        setData,
        dependencies,
        setDependencies,
        loading,
        setLoading,
        submitted,
        setSubmitted,
        onSubmit,
        submitDisabled:
            submitted ||
            loading ||
            data.name.length < 1 ||
            data.description.length < 1 ||
            data.category === null ||
            data.section === null ||
            data.gdprArt32 === null,
    };

    return (
        <Container
            title={`Security Measures - Create`}
            classNames="page-security-measure-create page-security-measure-shared"
            useAlternateBackground={true}
        >
            <Context.Provider value={ContextProps}>
                <LoadingContainer loading={loading}>
                    <Header />
                    <Controls />
                    <div className="tooltip-block">
                        <img src="/assets/images/icons/information-icon.png" className="info-icon" />
                        The security measure you will create will be added to your Security Tab in your Privacy Portal and to your Technical
                        and Organisational Measures policy (which is part of your Data Processing Agreement).
                    </div>
                    <Forms />
                </LoadingContainer>
            </Context.Provider>
        </Container>
    );
};

export default Component;
