import { Button } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ModuleState } from '..';

// Controls
import Search from '../../../components/dataControls/search';
import Filters from '../../../components/dataControls/filters';
import Groups from '../../../components/dataControls/groups';
import { getFilterFields } from '../utils/functions';

const Component = () => {
    const history = useHistory();
    const { dataControls, setDataControls, dependencies, downloadSecurityMeasuresDocument } = ModuleState();

    const onClickCreate = () => {
        history.push('/security-measures/create');
    };

    // Definitions
    const searchButtonId = 'search-btn-trigger';
    const filterButtonId = 'filter-btn-trigger';
    const groupButtonId = 'group-btn-trigger';

    const filterFields = getFilterFields(dependencies);

    const groupByFields = [
        {
            key: 'details.section',
            label: 'Section',
            options: dependencies.sections,
        },
        {
            key: 'details.category',
            label: 'Category',
            options: dependencies.categories,
        },
    ];

    return (
        <React.Fragment>
            <div className="page-controls">
                <div className="left-side">
                    <Button id={searchButtonId} variant={`${dataControls.search.length > 0 ? 'contained' : 'outlined'}`} color="primary">
                        Search
                    </Button>
                    <Button
                        id={filterButtonId}
                        variant={`${Object.keys(dataControls.filters).length > 0 ? 'contained' : 'outlined'}`}
                        color="primary"
                    >
                        Filter
                    </Button>
                    <Button id={groupButtonId} variant={`${dataControls.groupedBy.length > 0 ? 'contained' : 'outlined'}`} color="primary">
                        Group
                    </Button>
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" onClick={downloadSecurityMeasuresDocument} style={{ marginRight: 8 }}>
                        <i className="fa-solid fa-download" style={{ marginRight: 8 }}></i>
                        Security Measures Policy
                    </Button>
                    <Button variant="contained" color="primary" onClick={onClickCreate}>
                        New
                    </Button>
                </div>
                <Search
                    onChanges={(value) => setDataControls({ ...dataControls, search: value })}
                    buttonId={searchButtonId}
                    heading={'Search by Name'}
                    inputPlaceholder={'Name...'}
                />
                <Filters
                    onChanges={(value) => setDataControls({ ...dataControls, filters: value })}
                    buttonId={filterButtonId}
                    fields={filterFields}
                    urlSupport={true}
                />
                <Groups
                    onChanges={(value) => setDataControls({ ...dataControls, groupedBy: value })}
                    buttonId={groupButtonId}
                    fields={groupByFields}
                />
            </div>
        </React.Fragment>
    );
};

export default Component;
