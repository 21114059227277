import React from 'react';

// Context
import { PanelContext } from '../../..';

const Component = (props) => {
    const { tools, setTools, dataInventory, viewType } = PanelContext();

    const checkIsToolSelected = () => (tools.includes(props.data.label) ? true : false);

    const getToolFromInventory = () => {
        const comparedLabel = props.data.label
            .replace(/\(\d+\)/g, '')
            .trim()
            .toLowerCase();

        // We need to check if this tool is already in our data inventory.
        const match = dataInventory.storageLocations.find((c) => {
            // Turn Xero (2) into Xero
            const iteratedLabel = c.label
                .replace(/\(\d+\)/g, '')
                .trim()
                .toLowerCase();

            return iteratedLabel === comparedLabel;
        });

        return match || null;
    };

    const getToolText = () => {
        const tool = getToolFromInventory();

        // No text to show.
        if (!tool) return null;

        // If we're creating tools
        if (viewType === 'createTools') {
            if (tool.archived) return 'Already Added (Archived)';

            return 'Already Added';
        }

        // If we pick tools..
        if (viewType == 'pickFromInventory') {
            if (tool.archived) return 'Archived';

            return `In your Data Inventory`;
        }
    };

    const selectTool = () => {
        setTools((currentState) => {
            let arr = [...currentState];

            let index = arr.findIndex((c) => c === props.data.label);

            if (index === -1) {
                arr.push(props.data.label);
            } else {
                arr.splice(index, 1);
            }

            return arr;
        });
    };

    return (
        <React.Fragment>
            <div
                className={`component-storage-recommendation-entry ${checkIsToolSelected() ? `selected` : `not-selected`}`}
                data-cy="entry"
                onClick={selectTool}
            >
                <div className="content">
                    <div className="left-side">
                        {props.data.image ? (
                            <div
                                className="comp-img"
                                style={{
                                    backgroundImage: `url("${props.data.image}")`,
                                }}
                            />
                        ) : (
                            <div className="comp-avatar"> {props.data['label'].charAt(0).toUpperCase()} </div>
                        )}

                        <div className="label">{props.data.label}</div>
                        {getToolText() && <div className="already-added-badge">{getToolText()}</div>}
                    </div>
                    <div className="btn" key={`${props.data.label}-${checkIsToolSelected() ? `selected` : `not-selected`}`}>
                        <i className={`elm fa-solid ${checkIsToolSelected() ? `fa-minus` : `fa-plus`}`} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
