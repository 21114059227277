import React from 'react';

// Components
import AutoComplete from '../../../../../../thirdParties/components/editPanel/components/autocomplete';
import { makeEndpointRequest } from '../../../../../../../../utils/endpoints';
import { logError } from '../../../../../../../../utils/helpers';

const Component = (props) => {
    const { vitalRecommendations, dataInventory, setDataInventory } = props;

    const getOptions = () => {
        try {
            // not ready.
            if (!vitalRecommendations.elements) return [];

            let elements = [];

            // Go through normal elements
            vitalRecommendations.elements.forEach((elm) => {
                const inventoryEntry = dataInventory.elements.find((c) => c.label === elm.label);
                elements.push({
                    _id: inventoryEntry ? inventoryEntry._id : null,
                    label: elm.label,
                    sensitive: false,
                    description: elm.description,
                    type: 'vitalRecommendation',
                });
            });

            // Go through sensitive elements
            vitalRecommendations.sensitiveElements.forEach((elm) => {
                const inventoryEntry = dataInventory.elements.find((c) => c.label === elm.label);
                elements.push({
                    _id: inventoryEntry ? inventoryEntry._id : null,
                    label: elm.label,
                    sensitive: true,
                    description: '',
                    type: 'vitalRecommendation',
                });
            });

            // Now custom ones..
            dataInventory.elements.forEach((element) => {
                const exists = elements.find((c) => c.label === element.label && c.sensitive === element.sensitive);
                if (exists) return;

                elements.push({
                    _id: element._id,
                    label: element.label,
                    sensitive: element.sensitive,
                    description: '',
                    type: 'custom',
                });
            });

            // Formatting elements once more
            elements = elements
                .map((c) => ({ ...c, category: c.sensitive ? 'Sensitive Data Categories' : 'Personal Data Categories' }))
                .sort((a, b) => a.category.localeCompare(b.category)); // sort by category

            return elements;
        } catch (err) {
            logError(`module.setElements.getOptions`, err);
            return [];
        }
    };

    const addElement = async (elementAdded) => {
        try {
            // Does this elements exist in the data inventory?
            let inventory = dataInventory.elements.find((c) => {
                // If is already existing in our db..
                if (elementAdded._id && c._id === elementAdded._id) return true;

                // If not at least match by label
                return c.label === elementAdded.label;
            });

            let isCreated = false;

            // Does it exist in the data inventory already?
            if (!inventory) {
                // Create element first.
                inventory = await makeEndpointRequest(`CreateElement`, {
                    payload: {
                        label: elementAdded.label,
                        sensitive: elementAdded.sensitive || false, // custom element are not sensible.
                    },
                });

                isCreated = true;

                // Get data inventory again
                const dataInventoryUpdated = await makeEndpointRequest(`GetDataInventory`, {
                    onlyPublishedStorageLocations: false,
                    bundleStorageLocations: false,
                });

                // Save it
                setDataInventory(dataInventoryUpdated);
            }

            // Invoke callback
            props.onElementAdded(inventory, isCreated);
        } catch (err) {
            logError(`module.individuals.setElements.addElement`, err);
            return false;
        }
    };

    const removeElement = async (elementRemoved) => {
        try {
            props.onElementRemoved(elementRemoved);
        } catch (err) {
            logError(`module.individuals.setElements.removeElement`, err);
            return false;
        }
    };

    return (
        <React.Fragment>
            <AutoComplete
                // The value and options
                value={props.currentElements}
                options={getOptions()}
                // Props to the autocomplete..
                groupCategories={true}
                subjectsView={false}
                dataCy="elements-autocomplete"
                placeholder={props.textContents.placeholder}
                // Callbacks when data is changed
                onEntityAdded={addElement}
                onEntityRemoved={removeElement}
                // Props
                notCreatable={false}
            />
        </React.Fragment>
    );
};

export default Component;
