import React, { createContext, useContext } from 'react';

// Components
import Header from './components/header';
import FieldNames from './components/fieldNames/index';
import Process from './components/process';

// Context
import { OnboardingContext } from '../..';
import { Button } from '@mui/material';
import { logError, trackUserInteraction } from '../../../../../utils/helpers';
import { formatStringLegalToObject } from '../../../thirdParties/components/storageLocations/utils/functions';
import { OnboardingFunctions } from '../../components/functions';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { dataInventory, vitalRecommendations } = OnboardingContext();
    const { updateProcess } = OnboardingFunctions();

    const hasCompanySensitiveData = () => {
        const match = dataInventory.elements.find((d) => {
            if (!d.sensitive) return false;

            // Check if we have a non archived location using this Element.
            let validLocation = dataInventory.storageLocations.find((g) => g.archived === false && g.elements.includes(d._id));

            return validLocation ? true : false;
        });

        return match ? true : false;
    };

    const getProcesses = () => {
        let processes = [...dataInventory.processes];

        // Filter out the processes not used by Storage locations not archived
        processes = processes.filter((proc) => {
            // Is this process used by a non-archived storage location?
            const matching = dataInventory.storageLocations.find((d) => d.archived === false && d.processes.includes(proc._id));

            return matching ? true : false;
        });

        return processes;
    };

    /**
     * This function retrieves vital recommendations for specific processes, attempts to apply them, and alerts the user if any process fails to receive recommendations.
     */

    const applyLegalRecommendations = async () => {
        try {
            // Get the processes we need to get recommendations for.
            const processes = getProcesses();

            // Processes we failed to apply recommendations
            let processesFailed = [];

            // Iterating through each process and get recommendation
            for (const process of processes) {
                const rec = vitalRecommendations.processes.find((d) => d.label === process.label);

                // If there is no rec
                if (!rec) {
                    processesFailed.push(process);
                    continue;
                }

                // Extract recommendations
                let role = rec.legalResponsibility;
                let legalBasis = formatStringLegalToObject(rec);

                let changes = {};

                if (!process.role) {
                    changes.role = role;
                }

                if (!process.personalLegalBasis && legalBasis.personalLegalBasis) {
                    changes.personalLegalBasis = legalBasis.personalLegalBasis;
                }

                if (!process.sensitiveLegalBasis && legalBasis.sensitiveLegalBasis) {
                    changes.sensitiveLegalBasis = legalBasis.sensitiveLegalBasis;
                }

                // Update the process with the recommendation
                updateProcess(process._id, changes);
            }

            // If we have failures
            if (processesFailed.length > 0) {
                window.showAlert(
                    'Warning',
                    <React.Fragment>
                        <p>The following processes couldn't get recommendations from our AI Legal Framework.</p>
                        <ul style={{ textAlign: 'left' }}>
                            {processesFailed.map((p, ix) => (
                                <li key={ix}>{p.label}</li>
                            ))}
                        </ul>
                        <p>
                            You will need to add this information before going forward. If you are not sure about what legal basis to choose
                            feel free to reach out through Live Chat and someone from our team will be happy to help.
                        </p>
                    </React.Fragment>,
                    'warning',
                    [
                        {
                            text: 'Understood',
                            dataCy: `alert-button-confirm`,
                            onClick: async ({ dismissAlert }) => {
                                dismissAlert();
                                await trackUserInteraction(`Selected "Understood"`);
                            },
                        },
                    ],
                );
            }
        } catch (err) {
            await logError(`onboarding.onAutofillLegal`);
        }
    };

    /**
     * A simple warning displayed to the user when he taps the button.
     */

    const onAutoFill = async () => {
        window.showAlert(
            'Disclaimer',
            `This advice is general in nature and does not take into account your specific objectives. You should consider whether
            this advice is suitable to you and your circumstances. Where relevant you should consider seeking professional legal
            advice.`,
            'warning',
            [
                {
                    text: 'Dismiss',
                    dataCy: `alert-button-dismiss`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Dismiss"`);
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-button-confirm`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        applyLegalRecommendations();
                        await trackUserInteraction(`Selected "Confirm"`);
                    },
                },
            ],
        );
    };

    /***
     * Check if this current process collects sensitive data.
     * @returns {boolean}
     */

    const isCollectingSensitiveData = (process) => {
        // Find a sensitive element collected by this process.
        const element = dataInventory.elements.find((elm) => {
            if (!elm.sensitive) return false;

            const matrice = dataInventory.matrices.find((d) => {
                const matchingEntry = d.entries.find((g) => g._processId === process._id && g._elementId === elm._id);

                return matchingEntry ? true : false;
            });

            return matrice ? true : false;
        });

        return element ? true : false;
    };

    /**
     * This function determines if a process is incomplete.
     * It checks for the existence of role, personalLegalBasis, and sensitiveLegalBasis properties.
     * If any are missing, or if sensitive data is being collected without a legal basis, it returns true.
     */

    const isProcessIncomplete = (process) => {
        if (!process.role) return true;

        if (process.role !== 'Processor') {
            if (!process.personalLegalBasis) return true;
            if (!process.sensitiveLegalBasis && isCollectingSensitiveData(process)) return true;
        }

        return false;
    };

    const PassedProps = {
        processes: getProcesses(),
        isProcessIncomplete,
        isCollectingSensitiveData,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <Button variant="contained" color="primary" style={{ marginBottom: 14 }} onClick={onAutoFill}>
                Autofill
            </Button>
            <div className="legal-responsability module-content">
                <div className="processes">
                    <FieldNames hasCompanySensitiveData={hasCompanySensitiveData()} />
                    {getProcesses().map((process, index) => (
                        <Process key={index} data={process} hasCompanySensitiveData={hasCompanySensitiveData()} />
                    ))}
                </div>
            </div>
        </Context.Provider>
    );
};

export default Component;
