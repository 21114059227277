import { Button } from '@mui/material';
import React from 'react';
import { ModuleState } from '..';
import { trackUserInteraction } from '../../../../utils/helpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Component = () => {
    const { setPanelData } = ModuleState();
    const history = useHistory();
    const createProcess = () => {
        trackUserInteraction(`Selected "Add a Data Process"`);
        setPanelData({ _id: null, method: 'create' });
    };

    const updateOnboardingFlow = () => {
        trackUserInteraction(`Selected "Updated Onboarding Flow"`);
        history.push('/stages/gdpr-essentials/edit');
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <div className="title">Data Processes</div>
                <div className="buttons">
                    <Button variant="outlined" color="primary" onClick={updateOnboardingFlow} style={{ marginRight: 16 }}>
                        Update Onboarding Flow
                    </Button>
                    <Button variant="contained" color="primary" onClick={createProcess}>
                        <i className="fa-solid fa-plus" style={{ marginRight: 8 }}></i>
                        Add a data process
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
