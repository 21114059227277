export const sortSubProcessors = (data) => {
    const valids = [],
        invalids = [];

    data.forEach((entry, index) => {
        let isInvalid = isSubProcessorInvalid(entry);
        // Sorting finally
        const i = { ...entry, originalIndex: index };

        if (isInvalid) {
            invalids.push(i);
        } else {
            valids.push(i);
        }
    });
    return { valids, invalids };
};

export const isSubProcessorInvalid = (data) => {
    if (data.processes.length < 1) return true;
    if (data.url.length < 1) return true;
    if (data.securityMeasuresURL.length < 1) return true;
    if (data.dataResidency.length < 1) return true;

    return false;
};
