import React from 'react';

// Context
import { PanelContext } from '../../../../..';

// Dependencies
import { getToolRecommendations } from '../../../../../../../thirdParties/components/storageLocations/components/editTools/utils/helpers';
import { Avatar } from '@mui/material';

const Component = (props) => {
    const { toolsRecommendations } = PanelContext();

    // Get recommendations
    const recommendation = getToolRecommendations(props.label, toolsRecommendations);

    return (
        <React.Fragment>
            <div className="header">
                <div className="left-side">
                    <Avatar className="avatar" src={recommendation ? recommendation.image : undefined}>
                        {props.label.slice(0, 2)}
                    </Avatar>
                    <div className="label">{props.label}</div>
                </div>
                <div className="right-side">{props.removeBtn}</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
