import React, { useState, useContext, createContext, useEffect } from 'react';

// Components
import IsSubProcessor from './components/isSubProcessor';
import Measures from './components/measures';

// Panel Context
import { PanelContext } from '../..';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { data, validateView } = PanelContext();

    // Validation requirements
    const [validationResults, setValidationResults] = useState({});

    const validateFields = async () => {
        const { results } = await validateView('subProcessor');
        setValidationResults(results);
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    const PassedProps = {
        // Validation results
        validationResults,
        setValidationResults,
    };

    return (
        <Context.Provider value={PassedProps}>
            <div className="component-forms">
                <IsSubProcessor />
                <Measures />
            </div>
        </Context.Provider>
    );
};

export default Component;
