const shareablesEndpoints = {
    GetPricingPlans: {
        link: '/graphql',
        method: 'POST',
        query: `
            query GetPricingPlans {
                GetPricingPlans {
                    pricingPlansListed
                }
              }
        `,
        parserFunction: ({ data }) => data.GetPricingPlans,
    },
};

export default shareablesEndpoints;
