import React, { useEffect, useState } from 'react';

import { useStateRef } from '../../../../utils/helpers';
import { Button, MenuItem, Select } from '@mui/material';

let timerChanges = null;

import Base from '../base';

const Component = (props) => {
    const [open, setOpen, openRef] = useStateRef(false);
    const [fieldValue, setFieldValue] = useState('');

    // Definitions
    const containerTargetId = 'component-group-container';

    useEffect(() => {
        if (open === true && props.onChanges) {
            if (timerChanges) clearTimeout(timerChanges);
            timerChanges = setTimeout(() => {
                props.onChanges(fieldValue);
            }, 500);
        }
        // eslint-disable-next-line
    }, [fieldValue]);

    const passedProps = {
        containerTargetId,
        open,
        setOpen,
        openRef,
        buttonId: props.buttonId,
    };

    return (
        <Base className="component-search" {...passedProps}>
            <div className="heading">Group by</div>
            <div className="container">
                <Select fullWidth={true} value={fieldValue || 'none'} onChange={(ev) => setFieldValue(ev.target.value)}>
                    <MenuItem value="none" disabled={true}>
                        Select an option
                    </MenuItem>
                    {props.fields.map((entry) => (
                        <MenuItem key={entry.key} value={entry.key}>
                            {entry.label}
                        </MenuItem>
                    ))}
                </Select>
                {fieldValue.length > 0 && (
                    <Button
                        variant="contained"
                        fullWidth={true}
                        style={{ marginTop: 16, marginBottom: 16 }}
                        onClick={() => setFieldValue('')}
                    >
                        Clear
                    </Button>
                )}
            </div>
        </Base>
    );
};

export default Component;
