import React from 'react';

// Components
import { TextField } from '@mui/material';

// Context
import { ViewContext } from '..';
import { getValidationPropFields } from '../../../../../../../../utils/helpers';
import { PanelContext } from '../../..';

const Component = () => {
    const { validationResults } = ViewContext();
    const { data, updateData } = PanelContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <span className="text">Sub-processor's Security Policy or Page</span>
                </div>
                <div className="component-content">
                    <TextField
                        placeholder="Security Measures URL"
                        variant="standard"
                        data-cy="securityMeasuresUrl"
                        value={data.subProcessorMeasures.url}
                        onChange={(ev) => {
                            updateData({
                                subProcessorMeasures: {
                                    title: ev.target.value,
                                    url: ev.target.value,
                                },
                            });
                        }}
                        fullWidth={true}
                        {...getValidationPropFields(validationResults, true, `subProcessorMeasuresURL`)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
