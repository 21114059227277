const list = [
    'Add This',
    'Adition',
    'Adobe Experience Cloud',
    'Adobe Typekit',
    'Amazon advertising',
    'anchor.fm',
    'ArcGIS Map',
    'AT Internet',
    'Audioboom',
    'Audiocon',
    'Bing Maps',
    'Bitmovin',
    'Bookingkit',
    'Bryter',
    'Usercentrics Consent Management Platform', // for older cmp customers.
    'Privasee', // new cmp customers
    'Calendly',
    'Civey',
    'Criteo',
    'Dailymotion',
    'DataWrapper',
    'Discord',
    'Disqus',
    'eKomi',
    'Facebook Audience',
    'Facebook Pixel',
    'Facebook Social Plugin',
    'Facebook Videos',
    'Flickr',
    'Flockler',
    'Flourish',
    'Glomex',
    'Google Ads',
    'Google AdWords Remarketing',
    'Google Analytics',
    'Google Analytics 4',
    'Google Calendar',
    'Google Forms',
    'Google Maps',
    'Google Syndication',
    'Google Tag Manager',
    'Google Fonts',
    'Here',
    'HubSpot',
    'HubSpot forms',
    'Instagram',
    'Intercom',
    'Issuu',
    'JotForm',
    'Juicer',
    'Juris',
    'JW Player',
    'Klaviyo',
    'LinkedIn Plugin',
    'Livestorm.io',
    'Machineseeker',
    'Mapbox',
    'Matomo',
    'Matomo (self hosted)',
    'Media Math',
    'Mixcloud',
    'News Anwaltblatt',
    'Outdooractive',
    'Open as App',
    'Open Street Map',
    'Opinion Stage',
    'Paypal',
    'Pinterest',
    'Piwik Pro - Duda',
    'Playbuzz',
    'Podigee',
    'Prescreen',
    'PriceHubble',
    'reCAPTCHA v1/v2',
    'reCAPTCHA v3 automatically bind variant',
    'ShareThis',
    'Shore',
    'Site Analytics - Duda',
    'Site Personalization - Duda',
    'Slideshare',
    'Smart-rechner',
    'Snatchbot',
    'SoundCloud',
    'Sovendus',
    'Splicky',
    'Spotify',
    'Taboola',
    'Taggbox',
    'Thinglink',
    'TikTok',
    'TwentyThree',
    'Twitch',
    'Twitter Plugin',
    'Typeform',
    'Videoask',
    'Videolyser',
    'Vidyard',
    'Vimeo',
    'Walls.io',
    'Webtrekk',
    'Wetter',
    'Wigeogis',
    'Wistia',
    'Xing Plugin',
    'Yelp',
    'Youtube Video',
    'Yumpu',
    'Zoho SalesIQ',
    '3Q Video',
];

export default list;
