const endpoints = {
    CreateTCF: {
        link: '/graphql',
        method: 'POST',
        query: `mutation {
            CreateTCF 
        }`,
        parserFunction: ({ data }) => data.CreateTCF,
    },
    DisableTCF: {
        link: '/graphql',
        method: 'POST',
        query: `mutation {
            DisableTCF
        }`,
        parserFunction: ({ data }) => data.DisableTCF,
    },
};

export default endpoints;
