import React, { useEffect } from 'react';
import { ComponentState } from '../..';

// Components
import Boolean from './components/boolean';
import { Button } from '@mui/material';

const Component = () => {
    const { secondRoute, measuresRendered, setRoute, setSecondRoute } = ComponentState();

    const goBack = () => {
        setRoute('start');
        setSecondRoute(null);
    };

    const goNext = () => {
        // Set this next..
        setRoute('start');
        setSecondRoute(null);
    };

    useEffect(() => {
        // Trigger this to DPAs
        document.dispatchEvent(new CustomEvent('dpa:setDefaultsInSection'));

        // eslint-disable-next-line
    }, []);

    // Safety checks...
    if (secondRoute === null || measuresRendered === null) return null;

    return (
        <React.Fragment>
            <div className="route-header">
                <div className="left-side">
                    <div className="button-left">
                        <Button variant="text" onClick={goBack}>
                            Go Back
                        </Button>
                    </div>
                    <div className="title">{measuresRendered[0].details.section}</div>
                </div>
                <div className="button-right">
                    <Button variant="contained" color="primary" onClick={goNext}>
                        Done
                    </Button>
                </div>
            </div>
            <div className="route-content securityMeasures">
                <div className="questions">
                    {measuresRendered.map((entry, ix) => (
                        <Boolean data={entry} key={ix} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
