import React, { useState } from 'react';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { logError } from '../../../utils/helpers';
import Container from '../../layout/container';

// Components
import Forms from './components/forms';

const SignUp = () => {
    const [submitted, setSubmitted] = useState(false);
    const [successful, setSuccessful] = useState(false);
    const [data, setData] = useState({
        firstName: '',
        email: '',
        phoneNumber: '',
    });

    const onSubmit = async () => {
        try {
            setSubmitted(true);
            await makeEndpointRequest(`SendSignupEmail`, {
                body: {
                    firstName: data.firstName,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                },
            });
            setSubmitted(false);
            setSuccessful(true);
        } catch (err) {
            setSubmitted(false);
            await logError(`SEND_SIGNUP_EMAIL`, err);
        }
    };

    const passedProps = {
        data,
        setData,
        onSubmit,
        submitted,
        setSubmitted,
    };

    return (
        <Container guestLayout={true} title="Sign Up" classNames="page-signup">
            <div className="page-container">
                <div className="page-content">
                    <div className="page-header">
                        <div className="logo" />
                        <div className="text-side">
                            <div className="heading">Sign Up</div>
                            <div className="subheading">GDPR the easy way</div>
                        </div>
                    </div>
                    {successful ? (
                        <React.Fragment>
                            <div className="page-forms">
                                <p>
                                    Congrats, your request has been submitted. An email has been sent to {data.email} with instructions to
                                    complete your sign-up.
                                </p>
                                <p>You can safely close this tab.</p>
                            </div>
                        </React.Fragment>
                    ) : (
                        <Forms {...passedProps} />
                    )}
                </div>
            </div>
        </Container>
    );
};

export default SignUp;
