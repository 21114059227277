import React from 'react';
import { ComponentState } from '../../../..';
import { trackUserInteraction } from '../../../../../../../utils/helpers';
import { Checkbox } from '@mui/material';

const Component = () => {
    const { data, loading, ucSettings, setUcSettings } = ComponentState();

    const updatePosition = async (value) => {
        if (!data.cookieBannerSettings.enabled) return false;
        if (loading) return false;
        await trackUserInteraction(`Selected "Button Placement"`, { newDirection: value });
        setUcSettings({ ...ucSettings, buttonDisplayLocation: value });
    };

    const changePrivacyTrigger = async (value) => {
        if (!data.cookieBannerSettings.enabled) return false;
        if (ucSettings.privacyButtonIsVisible === value) return false;
        if (loading) return false;
        await trackUserInteraction(`Selected "Privacy Trigger"`, { newValue: value ? 'button' : 'link' });
        setUcSettings({ ...ucSettings, privacyButtonIsVisible: value });
    };

    const privacyOptions = [
        {
            image: ucSettings.buttonDisplayLocation === 'bl' ? `button_left` : 'button_right',
            label: `Button`,
            value: true,
        },
        {
            image: `link`,
            label: `Link within Privacy Portal`,
            value: false,
        },
    ];

    const placementOptions = [
        {
            label: `Left side`,
            // description: `Choose this option to make the button appear on the left side`,
            value: 'bl',
            // icon: `fa-solid fa-align-left`,
        },
        {
            label: `Right side`,
            // description: `Choose this option to make the button appear on the right side`,
            value: 'br',
            // icon: `fa-solid fa-align-right`,
        },
    ];

    return (
        <React.Fragment>
            <div className="form-label">Cookie Preferences Trigger</div>
            <div className="form-description">
                Choose if you wish to have a button always visible on your website or a special link within your privacy portal.
            </div>
            <div className="form-content">
                <div className="privacy-trigger-options">
                    {privacyOptions.map((entry, ix) => (
                        <div className={`entry`} key={ix}>
                            <div
                                className="image"
                                style={{
                                    backgroundImage: `url("/assets/images/page-cookie-banner/${entry.image}.svg")`,
                                }}
                            />
                            <div className="controller">
                                <div className="label">{entry.label}</div>
                                <Checkbox
                                    data-cy={`buttonDisplayLocation-${entry.value}`}
                                    onClick={() => changePrivacyTrigger(entry.value)}
                                    disabled={!data.cookieBannerSettings.enabled || loading}
                                    checked={ucSettings.privacyButtonIsVisible === entry.value}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {ucSettings.privacyButtonIsVisible === true && (
                <React.Fragment>
                    <div className="form-label">Additional options for Cookie Preferences Trigger</div>
                    <div className="form-description">Select on which part of the screen you want the button to appear</div>
                    <div className="form-content">
                        <div className="privacy-trigger-placements">
                            {placementOptions.map((entry, ix) => (
                                <div className={`entry`} key={ix}>
                                    <Checkbox
                                        onClick={() => updatePosition(entry.value)}
                                        disabled={!data.cookieBannerSettings.enabled || loading}
                                        checked={ucSettings.buttonDisplayLocation === entry.value}
                                    />
                                    <div className="label">{entry.label}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Component;
