import React from 'react';

// Context
import { ModuleState } from '../..';
import { filterIndividuals } from '../../utils/helpers';

// Components
import Group from './components/group';

const Component = () => {
    const { data, companyData, options } = ModuleState();

    const getGroups = () => {
        // If there's no company data yet is too early to filter, since that is needed.
        if (!companyData) return [];

        // Groups of data
        const groups = {
            missingInformation: [],
            prospects: [],
            customers: [],
            staff: [],
            others: [],
        };

        const sortingOrder = {
            missingInformation: 1,
            prospects: 2,
            customers: 3,
            staff: 4,
            others: 5,
        };

        let dataFiltered = filterIndividuals(data, companyData, options.filters);

        // Iterate
        dataFiltered.forEach((individual) => {
            // Get the referring role
            const r = companyData.individualsReferring.find((r) => r.label === individual.label) || {};

            // Any incomplete process will be put there.
            if (!individual.validated) {
                groups['missingInformation'].push({
                    ...individual,
                    individualReferring: r || {},
                });
                return false;
            }

            // List of all the possible roles.
            const referringRoles = ['prospects', 'customers', 'staff', 'others'];

            // Add them.
            referringRoles.forEach((role) => {
                if (r.referringRole === role) {
                    groups[role].push({
                        ...individual,
                        individualReferring: r || {},
                    });
                }
            });
        });

        // Format sections
        let sections = Object.keys(groups)
            .sort((a, b) => sortingOrder[a] - sortingOrder[b])
            .map((key) => ({ id: key, entries: groups[key] }))
            .filter((c) => c.entries.length > 0); // only those with entries

        return sections;
    };

    return (
        <React.Fragment>
            {getGroups().map((c, ix) => (
                <Group id={c.id} key={ix} entries={c.entries} />
            ))}
        </React.Fragment>
    );
};

export default Component;
