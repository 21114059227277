import React, { createContext, useContext } from 'react';

// Components
import Header from './components/header';
import Group from './components/group';
import EditTool from './components/editTool';

// Context
import { OnboardingContext } from '../..';
import { logError } from '../../../../../utils/helpers';

// View Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { companyData, dataInventory } = OnboardingContext();

    const getTools = (group) => {
        let groups = {
            archived: dataInventory.storageLocations.filter((c) => c.archived === true),
            incomplete: dataInventory.storageLocations.filter((c) => c.validated === false && !c.archived),
            validated: dataInventory.storageLocations.filter((c) => c.validated === true && !c.archived),
        };

        return groups[group] || [];
    };

    const showWarningDialog = (params) => {
        window.showAlert(params.title, params.content, 'warning', [
            {
                text: 'Continue Anyways',
                dataCy: `alert-submit-button`,
                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                    params.onContinue();
                },
            },
            {
                text: 'Go back and Edit',
                dataCy: `alert-cancel-button`,
                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },
        ]);
    };

    const validateView = async (onContinueCallBack, acknowledgedProblems = []) => {
        try {
            // Check for core services that haven't been added as processes yet.
            const missingCoreServices = companyData.services.filter(({ label }) => !dataInventory.processes.find((d) => d.label === label));
            const incompleteTools = dataInventory.storageLocations.filter((d) => d.validated === false && d.archived === false);
            const elementsNotUsed = dataInventory.elements.filter((d) => {
                const isUsed = dataInventory.storageLocations.find((g) => g.elements.includes(d._id));

                return isUsed ? false : true;
            });

            // If we have tools that are not validated.
            if (incompleteTools.length > 0 && !acknowledgedProblems.includes('incompleteTools')) {
                return showWarningDialog({
                    title: `Missing Information`,
                    content: (
                        <React.Fragment>
                            <div className="review-data-missing-warning-popup">
                                <p>Some tools are missing information and won't appear in your policies:</p>
                                <div className="list">
                                    <p>Tools or Third Parties</p>
                                    <ul>
                                        {incompleteTools.map((l, index) => (
                                            <li key={index}>{l.label}</li>
                                        ))}
                                    </ul>
                                </div>
                                <p>
                                    Click on a tool to edit it or skip this for now. You will be able to come to this later by editing your
                                    data inventory.
                                </p>
                            </div>
                        </React.Fragment>
                    ),
                    onContinue: () => validateView(onContinueCallBack, [...acknowledgedProblems, 'incompleteTools']),
                });
            }

            // If we have core services not created as processes
            if (missingCoreServices.length > 0 && !acknowledgedProblems.includes('missingCoreServices')) {
                return showWarningDialog({
                    title: `Missing Information`,
                    content: (
                        <div className="review-data-missing-warning-popup">
                            <p>The core activities of your company are currently not included in the data inventory.</p>
                            <p>The following activities are missing:</p>
                            <div className="list">
                                <ul>
                                    {missingCoreServices.map((c, index) => (
                                        <li key={index}>{c.label}</li>
                                    ))}
                                </ul>
                            </div>
                            <p>
                                It seems that you haven't added them to a tool or third party. To address this click on go back and edit,
                                then click on any third party and add it to the detailed view tab.
                            </p>
                            <p>Alternatively, you can Dismiss this warning for now.</p>
                        </div>
                    ),
                    onContinue: () => validateView(onContinueCallBack, [...acknowledgedProblems, 'missingCoreServices']),
                });
            }

            // If they said they are storing data but not where
            if (elementsNotUsed.length > 0 && !acknowledgedProblems.includes(`elementsNotUsed`)) {
                const personalData = elementsNotUsed.filter((d) => d.sensitive === false);
                const sensitiveData = elementsNotUsed.filter((d) => d.sensitive === true);

                return showWarningDialog({
                    title: `Missing Information`,
                    content: (
                        <div className="review-data-missing-warning-popup">
                            <p>You've informed us that you are storing data about:</p>
                            {personalData.length > 0 && (
                                <React.Fragment>
                                    <div className="list">
                                        <p>Personal Data</p>
                                        <ul>
                                            {personalData.map((l, index) => (
                                                <li key={index}>{l.label}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </React.Fragment>
                            )}
                            {sensitiveData.length > 0 && (
                                <React.Fragment>
                                    <div className="list">
                                        <p>Sensitive Personal Data</p>
                                        <ul>
                                            {sensitiveData.map((l, index) => (
                                                <li key={index}>{l.label}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </React.Fragment>
                            )}
                            <p>You failed to let us know where. Please use the "Edit" feature to fix that.</p>
                            <p>Otherwise, you can go back and uncheck it if you don't use it.</p>
                        </div>
                    ),
                    onContinue: () => validateView(onContinueCallBack, [...acknowledgedProblems, 'elementsNotUsed']),
                });
            }

            // By default we go ahead
            onContinueCallBack();
        } catch (err) {
            await logError(`onboarding.reviewData.validateView`, err);
        }
    };

    const PassedProps = {
        validateView,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <div className="review-data module-content">
                <Group id="incomplete" entries={getTools('incomplete')} optionalSection={true} addMarginBottom={true} />
                <Group id="validated" entries={getTools('validated')} addMarginBottom={true} />
                <Group id="archived" entries={getTools('archived')} optionalSection={true} />
            </div>
            {/* Optional Components */}
            <EditTool />
        </Context.Provider>
    );
};

export default Component;
