import React, { useEffect, useState } from 'react';

const Component = (props) => {
    const [closing, setClosing] = useState(false);

    const closeModal = async (callback = null) => {
        setClosing(true);
        setTimeout(() => {
            setClosing(false);

            if (callback) {
                callback();
            }
        }, 550);
    };

    useEffect(() => {
        if (props.innerRef) {
            props.innerRef.current = {
                close: closeModal,
                closing,
            };
        }

        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'initial';
        };

        // eslint-disable-next-line
    }, []);

    // An optional prop.
    const isVisible = props.visible !== undefined ? props.visible : true;

    return (
        <React.Fragment>
            <div
                data-cy="module-popout"
                className={`module-popout ${props.className || ''} ${!isVisible && 'invisible'} ${closing && `closing`}`}
            >
                <div className="module-content">
                    {props.header.closeButton ? (
                        <React.Fragment>
                            <div className="module-close">{props.header.closeButton}</div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="module-close" onClick={props.onClose} data-cy="panel-close">
                                <i className="icon fa-solid fa-xmark"></i>
                            </div>
                        </React.Fragment>
                    )}

                    <div className="module-title">{props.header.title}</div>
                    {props.header.description && <div className="module-subtitle">{props.header.description}</div>}
                    {props.content}
                </div>
            </div>
            <div className={`module-shadow-underlay ${!isVisible && 'invisible'}`} />
        </React.Fragment>
    );
};

export default Component;
