import React from 'react';

// Components
import Panel from '../../../../../thirdParties/components/storageLocations/components/editTools/components/panel';
import Functions from '../../../../../thirdParties/components/storageLocations/components/editTools/components/functions';

// Context
import { OnboardingContext } from '../../../..';
import { OnboardingFunctions } from '../../../../components/functions';

const Component = () => {
    const { dataInventory, setDataInventory, editingLocId, setEditingLocId } = OnboardingContext();
    const { vitalRecommendations, toolsRecommended, companyData, flags } = OnboardingContext();
    const { refreshDataInventory } = OnboardingFunctions();

    return (
        <React.Fragment>
            <Functions
                // Data Inventory
                data={dataInventory}
                setData={setDataInventory}
                // Editing Storage Location ID
                editingLocId={editingLocId}
                setEditingLocId={setEditingLocId}
                // Functions
                refreshDataInventory={refreshDataInventory}
                // Recommendations
                vitalRecommendations={vitalRecommendations}
            >
                <Panel
                    // Data Inventory
                    data={dataInventory}
                    // Company
                    companyData={companyData}
                    // Editing Storage Location ID
                    editingLocId={editingLocId}
                    // Flags - @Reminder: Make sure the flags in Third Parties and Onboarding Flow are always the same.
                    flags={{
                        missingSubProcessors: flags.missingSubProcessors,
                    }}
                    // Recommendations
                    toolsRecommended={toolsRecommended}
                    vitalRecommendations={vitalRecommendations}
                />
            </Functions>
        </React.Fragment>
    );
};

export default Component;
