import React from 'react';

// Components
import { FormHelperText, MenuItem, Select } from '@mui/material';

// Context
import { ViewContext } from '..';
import { PanelContext } from '../../..';
import { personalLegalBasis } from '../../../../../gdprEssentials/steps/legalResponsibility/utils/dataMaps';

const Component = () => {
    const { validationResults } = ViewContext();
    const { data, setData } = PanelContext();

    const onValueChanged = (ev) => {
        // Get the basis selected
        const basisSelected = personalLegalBasis.find((c) => c.string === ev.target.value);
        if (!basisSelected) return false;

        setData({ ...data, personalLegalBasis: basisSelected });
    };

    // At first it will be persona legal basis null, then we can also have a missing .string (legacy users)
    const ErrorMessage = validationResults[`personalLegalBasis`] || validationResults[`personalLegalBasis.string`];

    const getCurrentValue = () => {
        if (!data.personalLegalBasis || !data.personalLegalBasis.string) return null;

        return data.personalLegalBasis;
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Personal data legal justification</div>
                </div>
                <div className="component-description">
                    <div className="label">
                        The GDPR requires controllers to select a specific legal reason or legal basis to justify their use of data.
                    </div>
                </div>
                <div className="component-content">
                    <Select
                        onChange={onValueChanged}
                        fullWidth={true}
                        variant="standard"
                        data-cy="input-legalBasis"
                        disabled={data.role === 'Processor'}
                        value={data.role !== 'Processor' && getCurrentValue() ? getCurrentValue().string : 'disabled'}
                    >
                        {/* If none is selected. */}
                        {!getCurrentValue() && data.role !== 'Processor' && (
                            <MenuItem disabled value="disabled">
                                No selection
                            </MenuItem>
                        )}
                        {data.role === 'Processor' && (
                            <MenuItem disabled value="disabled">
                                No need for selection
                            </MenuItem>
                        )}
                        {personalLegalBasis.map((c, ix) => (
                            <MenuItem key={ix} value={c.string}>
                                {c.string}
                            </MenuItem>
                        ))}
                    </Select>

                    {ErrorMessage && (
                        <React.Fragment>
                            <FormHelperText error={true}>{ErrorMessage}</FormHelperText>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
