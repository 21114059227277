import React from 'react';
import { ComponentState } from '../../../index';
import { TextField } from '@mui/material';
import lodash from 'lodash';

const Component = () => {
    const { data, loading, ucSettings, setUcSettings } = ComponentState();

    if (data.cookieBannerSettings.enabled == false) return null;

    const onChangeEvent = async (field, value) => {
        setUcSettings((oldState) => {
            const newState = { ...oldState };
            lodash.set(newState, `links.${field}.url`, value);
            return newState;
        });
    };

    return (
        <React.Fragment>
            <div className="form-label">Banner Links</div>
            <div className="form-description-with-image">
                <div className="form-description">
                    <p>This allows you to change the links on your cookie banner.</p>
                </div>
            </div>
            <div className="form-label">Privacy Policy Link</div>
            <div className="form-content">
                <TextField
                    disabled={loading || !data.cookieBannerSettings.enabled}
                    fullWidth={true}
                    variant="outlined"
                    value={ucSettings.links.privacyPolicy.url}
                    onChange={(ev) => onChangeEvent('privacyPolicy', ev.target.value)}
                />
            </div>
            <div className="form-label">Cookie Policy Link</div>
            <div className="form-content">
                <TextField
                    disabled={loading || !data.cookieBannerSettings.enabled}
                    fullWidth={true}
                    variant="outlined"
                    value={ucSettings.links.cookiePolicy.url}
                    onChange={(ev) => onChangeEvent('cookiePolicy', ev.target.value)}
                />
            </div>
        </React.Fragment>
    );
};

export default Component;
