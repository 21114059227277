import moment from 'moment';
import QRCode from 'qrcode';
import React from 'react';
import { pdf } from '@react-pdf/renderer';
import download from 'downloadjs';

// Dependencies
import CertificateElm from './pdf';
import { makeEndpointRequest } from '../../../../../utils/endpoints';

export const generateCodeVerify = async (companyId) => {
    try {
        const qr_code = await new Promise((resolve, reject) => {
            QRCode.toDataURL(`${window.location.origin}/verify/${companyId}`, {
                color: {
                    dark: '#fff', // Blue dots
                    light: '#0000', // Transparent background
                },
            })
                .then((url) => resolve(url))
                .catch((err) => reject(err));
        });
        return qr_code;
    } catch (err) {
        throw err;
    }
};

export const getCertificateProps = async ({ certificateInfo: data, companyInfo, certName }) => {
    const qr = await generateCodeVerify(companyInfo._id);

    const copyrightText = `Privasee recognises the merchant for its efforts to comply with Data Protection Regulations such as the GDPR. By completing GDPR Essentials (Self Assessment Tool Including Data Mapping, Legal Basis Selection, Privacy and Cookie Policy), this merchant is maintaining rigorous data protection standards to ensure that the personal information from its data subjects remains safe and secure. To maintain compliance with regulations the self-assessment tool has to be past every quarter.`;

    const dataList = [
        {
            label: 'Representative',
            value: data.dpoName,
            width: '33%',
        },
        {
            label: 'Certificate ID',
            value: data._id,
            width: '67%',
        },
        {
            label: 'First verification date',
            value: moment(data.dateCreated).format('Do MMMM YYYY'),
        },
        {
            label: 'Latest verification date',
            value: moment(data.dateLastVerify).format('Do MMMM YYYY'),
        },
        {
            label: 'Next verification date',
            value: moment(data.dateNextVerify).format('Do MMMM YYYY'),
        },
        {
            label: 'Company address',
            value: `${[data.companyAddress.line1, data.companyAddress.line2].filter((str) => str.length > 0).join(', ')}, ${
                data.companyAddress.city
            }, ${data.companyAddress.country}`,
            width: '100%',
        },
    ];

    return {
        qr: qr,
        companyInfo: companyInfo,
        content: {
            copyrightText,
            dataList,
            certName,
        },
    };
};

export const getCertificateGenerated = async (companyId, certificate_type) => {
    try {
        const certNames = {
            'gdpr-essentials': 'GDPR Essentials',
        };
        const companyInfo = { ...(await makeEndpointRequest('GetCompanyInfo', { companyId })) }.data.GetCompanyInfo;
        const certificateInfo = await makeEndpointRequest('GetGDPREssentialsCertificate', { companyId });
        const certProps = await getCertificateProps({ certificateInfo, companyInfo, certName: certNames[certificate_type] });
        const blob = await pdf(<CertificateElm {...certProps} />).toBlob();
        download(blob, `${companyInfo.companyLegalName} - ${certNames[certificate_type]}`, 'application/pdf');
        return true;
    } catch (err) {
        throw err;
    }
};
