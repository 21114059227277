import React from 'react';

const Component = (props) => {
    return (
        <React.Fragment>
            <div
                className="shared-component chip-just-added chip-just-added-position"
                style={{ backgroundColor: props.color, marginRight: props.isFooterBadge ? 8 : 0 }}
            ></div>
            {props.isFooterBadge && (
                <React.Fragment>
                    <div className="text">New tools found by the Scanner</div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Component;
