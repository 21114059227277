import React, { useEffect, useState } from 'react';
import { HomepageContext } from '..';
import { AuthStore } from '../../../../utils/context/authStore';

let timer = null;

const Component = () => {
    const { account } = AuthStore();
    const { data } = HomepageContext();
    const [makeItFull, setMakeItFull] = useState(false);

    useEffect(() => {
        if (data.certificates.length < 1) return false;

        if (data.companyProgress.value > 99) {
            timer = setTimeout(() => {
                // eslint-disable-next-line
                setMakeItFull(true);
            }, 1200);
        }
        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line
    }, [data]);

    const getCompanyName = () => {
        if (data && data.companyLegalName && data.companyLegalName.length > 1) return `${data.companyLegalName} Dashboard`;

        return `Your Company's Dashboard`;
    };

    return (
        <React.Fragment>
            <div className="component-header">
                <div className="logged-as">Logged in as {account.email}</div>
                <div className="progress-section">
                    <div className="top">
                        <div className="left">{getCompanyName()}</div>
                        <div className="right">{parseFloat(data.companyProgress.value).toFixed(0)}%</div>
                    </div>
                    <div className="bottom">
                        <div className="bar">
                            <div className={`value ${makeItFull && 'full'}`} style={{ width: `${data.companyProgress.value}%` }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
