import React from 'react';

// Components

import EnableFeature from './components/enableFeature';
import AddItToWebsite from './components/installCode';
import Support from './components/support';
import CheckImplementation from './components/CheckImplementation';

const Component = () => {
    return (
        <div className="steps">
            <EnableFeature />
            <AddItToWebsite />
            <CheckImplementation />
            <Support />
        </div>
    );
};

export default Component;
