/**
 * This function tells us what are the valid individuals types.
 * @returns An array of types of individuals
 */

import { logError } from '../../../../../../utils/helpers';

export const getIndividualsTypes = () => {
    return [
        {
            label: `Prospects`,
            description: 'People you are looking to convert but who are not yet customers.',
            value: `prospects`,
        },
        {
            label: `Customers`,
            description: 'Those subject to your Terms & Conditions and who might pay for your services',
            value: `customers`,
        },
        {
            label: `Staff`,
            description: 'People that work for you in any capacity',
            value: `staff`,
        },
        {
            label: `Others`,
            description: '',
            value: `others`,
        },
    ];
};

/**
 * This function will find the matching value for a specific label. So we are future proof.
 * @param {*} label
 */

export const getIndividualTypesIdFromLabel = (label) => {
    const match = getIndividualsTypes().find((c) => c.label === label);

    if (!match) return null;

    return match.value;
};

/**
 * A simple function to make it easy to find available individuals to pick.
 * @param {*} data
 * @param {*} entry
 * @returns
 */

export const getOptions = (data, entry, recommendations) => {
    try {
        let individuals = [];
        let dataInventory = [];

        data.storageLocations.forEach((storage) => {
            storage.individuals.forEach((indiv) => {
                if (dataInventory.includes(indiv.label)) return false;

                dataInventory.push(indiv.label);
            });
        });
        recommendations.individuals.forEach((individualRecommended) => {
            // If it doesn't have a type we can't judge.
            if (individualRecommended.type !== entry.label) return;

            individuals.push({
                category: dataInventory.includes(individualRecommended.label) ? `Data Inventory` : `Other recommendations`,
                label: individualRecommended.label,
                referringRole: entry.value,
            });
        });

        return individuals.sort((a, b) => a.category.localeCompare(b.category));
    } catch (err) {
        logError(`gel.individuals.loadOptions`, err);
        return [];
    }
};

export const createReferringRole = (payload) => {
    return {
        label: '',
        referringRole: '',
        isChildren: false,
        ...payload,
    };
};

// @TBD: We need to work this out later.

export const isChildrenRole = (label) => {
    if (label === 'Children') return true;
    return false;
};
