import React from 'react';
import { ComponentState } from '../../../index';

// Components
import { Switch } from '@mui/material';
import { logError } from '../../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';

const Component = () => {
    const { data, setData, setLoading, loading } = ComponentState();

    const setTCF = async (state) => {
        try {
            // If is to activate
            if (state === true) {
                setLoading(true);
                // Make endpoint call
                const { settingsId } = await makeEndpointRequest(`CreateTCF`);

                // Update local state
                setData((currentState) => ({
                    ...currentState,
                    cookieBannerSettings: {
                        ...currentState.cookieBannerSettings,
                        tcf: {
                            enabled: true,
                            settingsId,
                        },
                    },
                }));

                setLoading(false);
                return true;
            } else {
                setLoading(true);
                // Make endpoint call to disable
                await makeEndpointRequest(`DisableTCF`);

                // Update local state..
                setData((currentState) => ({
                    ...currentState,
                    cookieBannerSettings: {
                        ...currentState.cookieBannerSettings,
                        tcf: {
                            ...currentState.cookieBannerSettings.tcf,
                            enabled: false,
                        },
                    },
                }));

                setLoading(false);
            }
        } catch (err) {
            await logError(`cookieBanner.tcf.setTCF`, err, { state });
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <div className="form-label">TCF 2.2</div>
            <div className="form-description-with-image">
                <div className="form-description">
                    <p>
                        Upgrade your cookie banner to meet TCF 2.2 standards, ensuring global privacy compliance. Display vendors' TCF
                        status dynamically for user transparency.
                    </p>
                </div>
            </div>
            <div className="form-content">
                <Switch
                    checked={data.cookieBannerSettings.tcf.enabled}
                    color="primary"
                    onClick={() => setTCF(!data.cookieBannerSettings.tcf.enabled)}
                    disabled={loading || !data.cookieBannerSettings.enabled}
                />
            </div>
        </React.Fragment>
    );
};

export default Component;
