import React from 'react';
import { ComponentState } from '../../..';
import { logError, trackUserInteraction } from '../../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';

// Components
import { useState } from 'react';
import Check from '../../../../cookieBanner/pages/addToWebsite/components/CheckImplementation/components/check';
import { Button } from '@mui/material';
import moment from 'moment';

const Component = () => {
    const { data, setImplementationCheck, implementationCheck } = ComponentState();

    const [checkingInstallation, setCheckingInstallation] = useState(false);
    const implementationSuccessful = implementationCheck && implementationCheck.data.result.found !== 'none' ? true : false;

    const checkInstallation = async () => {
        try {
            await trackUserInteraction(`Selected "Check Implementation"`, { type: 'privacyPortal' });
            setCheckingInstallation(true);
            const { result, debug } = await makeEndpointRequest('CheckPrivacyPortalImplementation');

            // Check that it's been more than 15 seconds since last scan.
            if (result === 'cooldown') {
                window.showAlert('Cooldown period', `Wait ${debug.cooldownSecondsLeft} seconds before scanning again.`, `error`);
                setCheckingInstallation(false);
                return false;
            }

            setImplementationCheck({
                scannedAt: new Date(),
                data: {
                    ...result,
                },
            });

            setCheckingInstallation(false);
            await trackUserInteraction(`Implementation checked`, { type: 'privacyPortal', result });
        } catch (err) {
            await logError('CHECK_INSTALLATION_PRIVACY_PORTAL', err);
            trackUserInteraction(`Having Difficulties`, { reason: `failed to verify pp implementation` });
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
            setCheckingInstallation(false);
        }
    };

    const getServicesStatus = () => {
        // We're scanning.
        if (checkingInstallation) return `pending`;
        if (implementationCheck === null) return `unknown`; // Unknown - we never scanned it.
        if (implementationSuccessful) return 'success'; // Success

        // Otherwise failed
        return 'failed';
    };

    const getFooterMessage = () => {
        if (checkingInstallation) return `* It can take up to 30 seconds to scan your website.`;

        if (implementationCheck !== null) {
            return `Last scanned: ${moment(implementationCheck.scannedAt).format('dddd, DD MMMM YYYY - HH:mm')}`;
        }

        return null;
    };

    return (
        <React.Fragment>
            <div className="step-entry scan-entry">
                <div className="step-header">
                    <div className="step-badge">Step 3</div>
                    <div className="step-label">Verify Implementation</div>
                </div>
                <div className="step-content">
                    <div className="step-two-rows">
                        <div className="parent-container-rows" style={{ flex: 1 }}>
                            <div className="step-text">
                                Verify that your privacy portal {data.isPrivacyPortalHosted ? 'link' : 'code snippet'} is correctly added to
                                your website or webapp.
                            </div>
                            <div className="check-installation">
                                <div className="checkpoints">
                                    <div className="header">Checks</div>
                                    <div className="content">
                                        <Check
                                            label="Health Check"
                                            description={`Verifies that your privacy portal is accessible from your website and operational.`}
                                            status={getServicesStatus('cookieBanner')}
                                        />
                                    </div>
                                    <div className="footer">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={!data.companyDomains[0] || checkingInstallation}
                                            onClick={checkInstallation}
                                            size="small"
                                        >
                                            {checkingInstallation ? 'Checking..' : 'Check Installation'}
                                        </Button>
                                        <div className="right-side">
                                            <div className="text-information">{getFooterMessage()}</div>
                                        </div>
                                    </div>
                                </div>
                                {!implementationSuccessful && implementationCheck !== null && (
                                    <div className="advice">
                                        <div className="icon">
                                            <i className="elm fa-solid fa-circle-exclamation"></i>
                                        </div>
                                        <div className="message">
                                            Our scanner failed to find {data.isPrivacyPortalHosted ? 'the link' : 'the code snippet'} on
                                            your website. Click{' '}
                                            <a
                                                href="https://privaseeio.notion.site/Why-doesn-t-the-platform-scan-my-domain-83646845485c4e9bbb9d69d03fc906fa"
                                                target="_blank"
                                            >
                                                here
                                            </a>{' '}
                                            to read our guide on how to fix this.
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
