import React from 'react';
import { Avatar } from '@mui/material';
// Context
import { ModuleState } from '../../..';

// Components
import Tooltip from '../../../../../components/tooltip';

// Dependencies
import { isProcessCollectingSensitiveData } from '../../../utils/helpers';
import { getToolRecommendations } from '../../../../thirdParties/components/storageLocations/components/editTools/utils/helpers';
import { trackUserInteraction } from '../../../../../../utils/helpers';

const Component = (props) => {
    const { data, toolsRecommended, setPanelData } = ModuleState();

    const getLegalBasis = () => {
        if (props.data.role === 'Processor') return `No need for selection`;
        if (!props.data.personalLegalBasis) return `Not Selected`;
        return props.data.personalLegalBasis.string;
    };

    const getSensitiveBasis = () => {
        if (props.data.role === 'Processor') return `No need for selection`;
        if (!props.data.sensitiveLegalBasis) return `Not Selected`;
        return props.data.sensitiveLegalBasis.string;
    };

    const isCollectingSensitiveData = () => {
        let isUsingSensitiveData = isProcessCollectingSensitiveData(data, props.data);
        return isUsingSensitiveData;
    };

    const getTools = () =>
        data.storageLocations
            .filter((c) => c.processes.includes(props.data._id))
            .map((tool) => ({ ...tool, recommendation: getToolRecommendations(tool.label, toolsRecommended) }))
            .slice(0, 5); // only 5 max.

    const editProcess = () => {
        setPanelData({ method: 'edit', _id: props.data._id });
        trackUserInteraction(`Selected "Edit Process"`, {
            processName: props.data.label,
        });
    };

    return (
        <React.Fragment>
            <div className="--component-entry" onClick={editProcess}>
                <div className="left-side">
                    <div className="label">{props.data.label}</div>
                    <div className="details">
                        {['missingInformation'].includes(props.groupId) && (
                            <div className="entry">Role: {props.data.role || 'Not Selected'}</div>
                        )}
                        <div className="entry">Legal Basis: {getLegalBasis()}</div>
                        {/* If is collecting sensitive data and is not processor */}
                        {isCollectingSensitiveData() && props.data.role !== 'Processor' && (
                            <div className="entry">Sensitive Legal Basis: {getSensitiveBasis()}</div>
                        )}
                    </div>
                    {props.groupId === 'missingInformation' && (
                        <div className={`miss-info-badge`}>
                            <Tooltip content={`Click here to add the missing information.`}>
                                <i className="elm fa-solid fa-circle-info"></i>
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div className="right-side">
                    <div className="tools">
                        {getTools().length > 0 ? (
                            <React.Fragment>
                                {getTools().map((c, index) => (
                                    <div className="entry" key={index}>
                                        <Avatar className="avatar" src={c.recommendation ? c.recommendation.image : undefined}>
                                            {c.label.slice(0, 2)}
                                        </Avatar>
                                    </div>
                                ))}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="placeholder-text">Tools and Third Parties - None selected.</div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
