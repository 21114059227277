import React, { useState } from 'react';
import { ComponentState } from '../../../index';
import { AuthStore } from '../../../../../../utils/context/authStore';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { logError, trackUserInteraction } from '../../../../../../utils/helpers';
import { Switch, Button } from '@mui/material';

import ConfirmationDialog from './dialog';
import { useHistory } from 'react-router-dom';

const Component = () => {
    const { account, setAccount } = AuthStore();
    const [actioningDialog, setActioningDialog] = useState(null);
    const history = useHistory();

    const { setData, setDefaultData, data, loading, setLoading, refreshUcSettings, updateInvoiceDetails } = ComponentState();

    const confirmFeatureChange = async (bool) => {
        try {
            if (bool === true && data && data.privacyPortalSettings && data.privacyPortalSettings.features.cookiePolicy === false) {
                await trackUserInteraction(`Failed to activate cookie banner`, {
                    reason: `He doesn't have a cookie policy activated. He was instructed how to correct it.`,
                });
                window.showAlert(
                    `Having Difficulties`,
                    `Before you activate this feature you must enable your cookie policy from the Privacy Portal settings page.`,
                    `info`,
                    [
                        {
                            text: 'Skip For Now',
                            dataCy: `alert-skip-button`,
                            onClick: async ({ dismissAlert }) => {
                                dismissAlert();
                                await trackUserInteraction(`Selected "Skip For Now"`);
                            },
                        },
                        {
                            text: 'Fix Now',
                            dataCy: `alert-fix-button`,
                            onClick: async ({ dismissAlert }) => {
                                dismissAlert();
                                await trackUserInteraction(`Selected "Fix Now"`);
                                history.push(`/settings/privacy-portal/general`);
                            },
                        },
                    ],
                );
                return false;
            }

            await trackUserInteraction(`Selected "${bool === true ? 'Enable' : 'Disable'} Feature`, { type: `consentManagement` });
            setActioningDialog(bool);
        } catch (err) {
            await logError(`CONFIRM_COOKIE_BANNER_SWITCH`, err);
        }
    };

    const switchFeature = async (bool) => {
        try {
            if (updateInvoiceDetails === true && !account.stripeCustomerId) {
                setLoading(false);
                setActioningDialog(null);
                document.dispatchEvent(new CustomEvent('cookieBanner:startCheckoutSession', { detail: true }));
                return false;
            }

            setLoading(true);
            const currentCbState = !bool;

            const meta = await makeEndpointRequest(`getSettingsMeta`);
            const sessionsTracked = meta.currentUsage.cookieBannerSessions.total;
            const limitSessions = meta.usageLimits.cookieBannerDiscountedSessions;

            // If he is on trial mode, and his company ran out of credits this month.
            if (
                currentCbState === false &&
                data.cookieBannerSettings.trial === true &&
                sessionsTracked > limitSessions &&
                !account.stripeCustomerId
            ) {
                // eslint-disable-next-line
                setLoading(false);
                setActioningDialog(null);
                document.dispatchEvent(new CustomEvent('cookieBanner:showUpdateInvoice', { detail: true }));
                return false;
            }

            const res = await makeEndpointRequest(
                currentCbState ? `DisableCookieBanner` : `EnableCookieBanner`,
                currentCbState
                    ? {
                          settingsId: data.usercentricsSettingsId,
                          email: data.usercentricsEmail,
                      }
                    : {},
            );

            setLoading(false);
            setActioningDialog(null);

            setAccount({
                ...account,
                ucSettingsId: bool === false ? null : res.settingsId,
            });

            if (bool === true) {
                await refreshUcSettings({
                    id: res.settingsId,
                    email: res.email,
                });
            }
            // The API will decide what email was used to activate it (in case this is a support account)

            const newData = {
                ...data,
                usercentricsSettingsId: bool === false ? null : res.settingsId,
                usercentricsEmail: bool === true ? res.email : data.usercentricsEmail,
                cookieBannerSettings: {
                    ...data.cookieBannerSettings,
                    enabled: bool,
                },
            };

            setData({ ...newData });
            setDefaultData({ ...newData });

            window.showAlert(
                `Confirmation`,
                bool === true // if activated
                    ? `The code for your cookie banner is ready. Please copy the code in the "Add to website" tab to set it live.`
                    : `The cookie banner has been disabled.`,
                `info`,
            );

            await trackUserInteraction(`${bool === false ? `Disabled` : `Enabled`} cookie banner`, {
                ucSettingsId: bool === false ? `N/A` : res.settingsId,
                isDiscountedCompany: data.isDiscountedCompany,
                usingTrialMode: bool === true ? res.usingTrialMode : 'N/A',
            });

            setLoading(false);
        } catch (err) {
            await logError(`SWITCH_UC`, err, { bool });
            await trackUserInteraction(`Having Difficulties`, {
                reason: `Failed to switch UC`,
                newBool: bool,
            });
            setLoading(false);
            window.showAlert('Having Difficulties', `We're encountering technical difficulties, please try again.`, `error`);
            throw err;
        }
    };

    const onClickUpgrade = () => {
        trackUserInteraction(`Selected "Upgrade from Trial" on Cookie Banner Settings`);
        confirmFeatureChange(true);
    };

    const shouldSeeUpgradeMessage = updateInvoiceDetails === true && !account.stripeCustomerId ? true : false;

    return (
        <React.Fragment>
            {shouldSeeUpgradeMessage === false ? (
                <React.Fragment>
                    <div className="form-label">Enabling of feature</div>
                    <div className="form-description">
                        Use this option if you wish to switch {data.cookieBannerSettings.enabled ? `off` : `on`} the cookie banner.
                    </div>
                    <div className="form-content">
                        <Switch
                            data-cy="switch"
                            checked={data.cookieBannerSettings.enabled ? true : false}
                            color="primary"
                            disabled={loading}
                            onChange={() => confirmFeatureChange(!data.cookieBannerSettings.enabled)}
                        />
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className="form-label">
                        Review your trial situation <span className="badge red">Attention required</span>
                    </div>{' '}
                    <div className="form-description">
                        Take a look at our costs and review your invoice details in order to continue using our Cookie Banner.
                    </div>
                    <div className="form-content" onClick={onClickUpgrade}>
                        <Button variant="outlined">Upgrade</Button>
                    </div>
                </React.Fragment>
            )}

            {actioningDialog !== null && (
                <ConfirmationDialog action={actioningDialog} update={switchFeature} hideDialog={() => setActioningDialog(null)} />
            )}
        </React.Fragment>
    );
};

export default Component;
