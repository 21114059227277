import React from 'react';

// Context
import { PanelContext } from '..';

const Component = () => {
    const { tools, addPickedTools, closePanel } = PanelContext();

    const onClose = () => {
        // Add the picked tools
        if (tools.length > 0) {
            addPickedTools(tools);
        }

        // Close the panel
        closePanel();
    };

    return (
        <React.Fragment>
            {tools.length > 0 ? <div className="tools-selected">{tools.length} tools selected</div> : null}
            <div className="done-button" onClick={onClose} data-cy="done-button">
                Done
            </div>
        </React.Fragment>
    );
};

export default Component;
