import React from 'react';
import { useHistory } from 'react-router-dom';

const Shortcut = () => {
    const history = useHistory();

    const goToShareable = () => history.push('/shareables');

    return (
        <React.Fragment>
            <div className="card-bg shortcut-card shareables">
                <div className="header">
                    <div className="title">Shareables</div>
                    <div className="arrow settings" onClick={goToShareable}>
                        <i className="icon fa-light fa-chevron-right"></i>
                    </div>
                </div>
                <div className="header-description">Use these resources to inform your customers that their data is safe with you.</div>
            </div>
        </React.Fragment>
    );
};

export default Shortcut;
