import React from 'react';
import { useHistory } from 'react-router-dom';
import { HomepageContext } from '..';
import { logError, trackUserInteraction } from '../../../../utils/helpers';

const Component = () => {
    const { data } = HomepageContext();
    const history = useHistory();

    const onStepClicked = async (step) => {
        try {
            if (step.checked === true) return false;

            if (step.onClick.action === 'redirect') {
                history.push(step.onClick.payload);
            }

            await trackUserInteraction(`Selected Next Step "${step.text}"`);
        } catch (err) {
            await logError(`SELECTED_NEXT_STEP`, err, { step });
        }
    };

    return (
        <React.Fragment>
            <div className="page-steps">
                <div className="heading">
                    {data.companyProgress.nextSteps.filter((c) => c.checked === false).length > 0 ? (
                        'Next Steps'
                    ) : (
                        <React.Fragment>
                            <i className="icon fa-solid fa-party-horn"></i>
                            You're Up-to-date!
                        </React.Fragment>
                    )}
                </div>
                <div className="entries">
                    {data.companyProgress.nextSteps.map((entry, ix) => (
                        <div
                            key={ix}
                            onClick={() => onStepClicked(entry)}
                            className={`entry ${entry.checked === true ? 'checked' : 'not-checked'}`}
                        >
                            <i className={`icon fa-light fa-shield${entry.checked ? '-check' : ''}`}></i>
                            <div className="text">{entry.text}</div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
