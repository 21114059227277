import React from 'react';
import { PanelContext } from '../../../../../..';

import EditElements from '../../../../../../../../../individuals/panel/views/processes/shareableComponents/setElements';
import { logError, trackUserInteraction } from '../../../../../../../../../../../utils/helpers';

const Component = (props) => {
    const { vitalRecommendations, dataInventory, setDataInventory, setData } = PanelContext();

    /**
     *
     * @param {*} inventoryElement
     * @param {*} isCreated
     * @returns
     */

    const elementAdded = async (inventoryElement) => {
        try {
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the current matrix map.
                const mIx = newState.matrixMap.findIndex((c) => c._processId === props.process._id);
                if (mIx === -1) return newState;

                // Get the index of current individual
                const indIx = newState.matrixMap[mIx].individuals.findIndex((c) => c._individualId === props.individual._id);
                if (indIx === -1) return newState;

                // Add element
                newState.matrixMap[mIx].individuals[indIx].elements.push(inventoryElement._id);

                return newState;
            });

            trackUserInteraction(`Added data category to Individual`, {
                element: inventoryElement.label,
                individual: props.individual.label,
                process: props.process.label,
            });
        } catch (err) {
            await logError(`thirdParties.matrixMap.elementAdded`, err, { inventoryElement });
            return false;
        }
    };

    const elementRemoved = async (inventoryElement) => {
        try {
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the current matrix map.
                const mIx = newState.matrixMap.findIndex((c) => c._processId === props.process._id);
                if (mIx === -1) return newState;

                // Get the index of current individual
                const indIx = newState.matrixMap[mIx].individuals.findIndex((c) => c._individualId === props.individual._id);
                if (indIx === -1) return newState;

                // Remove element
                newState.matrixMap[mIx].individuals[indIx].elements = newState.matrixMap[mIx].individuals[indIx].elements.filter(
                    (id) => id !== inventoryElement._id,
                );

                return newState;
            });

            trackUserInteraction(`Removed data category from Individual`, {
                element: inventoryElement.label,
                individual: props.individual.label,
                process: props.process.label,
            });
        } catch (err) {
            await logError(`thirdParties.matrixMap.elementRemoved`, err, { elementRemoved });
            return false;
        }
    };

    return (
        <React.Fragment>
            <div className="--component-entry">
                <div className="individual">
                    <div className={`label ${props.elements.length < 1 && 'invalid'}`} style={{ display: 'flex', alignItems: 'center' }}>
                        {props.individual.label}
                    </div>

                    {props.elements.length < 1 && (
                        <div className="warning">
                            <i className="icon fa-solid fa-triangle-exclamation"></i>
                        </div>
                    )}
                </div>
                <div className="elements">
                    <EditElements
                        vitalRecommendations={vitalRecommendations}
                        // Data Inventory
                        dataInventory={dataInventory}
                        setDataInventory={setDataInventory}
                        // Texts
                        textContents={{ placeholder: 'Click here to select the data categories' }}
                        // Current Data
                        currentElements={props.elements.map((c) => ({ _id: c._id, label: c.data.label }))}
                        // Callbacks
                        onElementAdded={elementAdded}
                        onElementRemoved={elementRemoved}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
