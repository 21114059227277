import { logError } from '../../../../utils/helpers';
import { applyFilters } from '../../../components/dataControls/filters/utils/functions';
import { getIndividualsTypes } from '../../gdprEssentials/steps/individuals/components/functions';

export const getFilterFields = (data) => {
    return [
        {
            key: 'label',
            type: 'text',
            label: 'Name',
        },
        {
            key: 'isChildren',
            type: 'text',
            options: ['Yes', 'No'],
            label: 'Is Children',
        },
        {
            key: 'type',
            type: 'text',
            options: getIndividualsTypes().map((c) => c.label),
            label: 'Type',
        },
        {
            key: 'processes',
            label: 'Processes',
            type: 'array',
            propertyCompared: 'label',
            options: data.processes, // We will compare our value against the "label" in processes
            optionsLabel: 'label',
        },
        {
            key: 'storageLocations',
            label: 'Tools and Third Parties',
            type: 'array',
            propertyCompared: 'label',
            options: data.storageLocations, // We will compare our value against the "label" in processes
            optionsLabel: 'label',
        },
    ];
};

/**
 * This will filter the individuals
 * @param {*} dataInventory
 * @param {*} optionsFilters
 * @returns
 */

export const filterIndividuals = (dataInventory, companyData, optionsFilters) => {
    try {
        // Get filter fields.
        const fields = getFilterFields(dataInventory);

        // We simulate data like this so we can filter it.
        const simulatedData = dataInventory.individuals.map((c) => {
            // Get the referring role
            const role = companyData.individualsReferring.find((r) => r.label === c.label) || {};

            // Get individual type
            const type = getIndividualsTypes().find((c) => c.value === role.referringRole);

            let formattedEntry = {
                _id: c._id, // so we can put it back together
                label: c.label,
                isChildren: role.isChildren ? 'Yes' : 'No',
                type: type ? type.label : 'Others',
                storageLocations: dataInventory.storageLocations.filter((d) => d.individuals.includes(c._id)),
                processes: dataInventory.processes.filter((process) => {
                    // Matched
                    const match = dataInventory.matrices.find((m) => {
                        const matchEntry = m.entries.find((z) => z._individualId === c._id && z._processId === process._id);
                        return matchEntry;
                    });

                    // Get all the individuals that are linked to this process through a matrix table.
                    return match ? true : false;
                }),
            };

            return formattedEntry;
        });

        // Filter the data
        const filteredData = applyFilters(simulatedData, optionsFilters, fields);

        // We now put it back together.
        const individuals = filteredData.map(({ _id }) => dataInventory.individuals.find((c) => c._id === _id));

        return individuals;
    } catch (err) {
        logError(`module.individuals.filterIndividuals`, err);
        return [];
    }
};
