import React from 'react';
import { ComponentState } from '../../../..';
import availableLanguages from '../../../../../../../dummy_data/availableLanguages.json';

import { Select, MenuItem } from '@mui/material';

const Component = () => {
    const { data, loading, updateData } = ComponentState();

    if (data && data.languagesAvailable.length < 2) return null; // If there is only one language available no need to pick

    return (
        <React.Fragment>
            <div className="form-label">Default language</div>
            <div className="form-content">
                <Select
                    value={data && data.defaultLanguage ? data.defaultLanguage : 'EN'}
                    fullWidth={true}
                    data-cy="default-languages-select"
                    disabled={loading}
                    onChange={(ev) => {
                        if (ev.target.value.length < 1) return false;
                        updateData(`defaultLanguage`, ev.target.value);
                    }}
                >
                    {availableLanguages
                        .filter((x) => data.languagesAvailable.includes(x.code))
                        .map((lang, ix) => (
                            <MenuItem key={ix} value={lang.code}>
                                {lang.label}
                            </MenuItem>
                        ))}
                </Select>
            </div>
        </React.Fragment>
    );
};

export default Component;
