import React from 'react';

// Context
import { AuthStore } from '../../../../utils/context/authStore';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Component = (props) => {
    const { checkUserAccess } = AuthStore();
    const history = useHistory();
    /**
     * A simple function that will return the incomplete inventory elements that require more attention.
     * @returns An object with an array of invalid items.
     */

    const getIncompleteInventory = () => {
        let storageLocations = props.data.dataInventory.storageLocations.filter((c) => c.validated === false && c.archived == false);
        let processes = props.data.dataInventory.processes.filter((c) => c.validated === false);
        let individuals = props.data.dataInventory.individuals.filter((c) => c.validated === false);

        return { storageLocations, processes, individuals };
    };

    /**
     * We need to pick which item we'll fix first, example: if they have problems with storage locations and individuals, we need to show them just one here.
     */

    const getActionableTarget = () => {
        // Get the incomplete ones
        const incomplete = getIncompleteInventory();

        if (incomplete.storageLocations.length > 0) return `storageLocations`;
        if (incomplete.processes.length > 0) return `processes`;
        if (incomplete.individuals.length > 0) return `individuals`;

        return null;
    };

    const getTitle = () => {
        // Get the target that needs fixing.
        const target = getActionableTarget();

        // Get data inventory of incomplete too.
        const incomplete = getIncompleteInventory();

        // Get the return based on the target..
        switch (target) {
            case 'storageLocations':
                return `${incomplete.storageLocations.length} tools or third parties lack essential information`;
            case 'processes':
                return `${incomplete.processes.length} data processes are incomplete`;
            case 'individuals':
                return `${incomplete.individuals.length} individuals have missing details`;
            default:
                return null;
        }
    };

    const renderContentMessage = () => {
        // Get the target that needs fixing.
        const target = getActionableTarget();

        switch (target) {
            case 'storageLocations':
                return (
                    <React.Fragment>
                        <p style={{ margin: 0 }}>
                            Your data inventory may have tools or third-party entries with missing information. Take action by updating
                            these entries with the required information.
                        </p>
                        <p style={{ margin: 0 }}>Click the button below to access the Tools and Third Parties page.</p>
                    </React.Fragment>
                );
            case 'individuals':
                return (
                    <React.Fragment>
                        <p style={{ margin: 0 }}>
                            Your data inventory may have individuals with missing details. Take action by updating these entries with the
                            required information.
                        </p>
                        <p style={{ margin: 0 }}>Click the button below to access the Individuals page.</p>
                    </React.Fragment>
                );
            case 'processes':
                return (
                    <React.Fragment>
                        <p style={{ margin: 0 }}>
                            Your data inventory may have incomplete data processes. Take action by updating these processes with the
                            required information.
                        </p>
                        <p style={{ margin: 0 }}>Click the button below to access the Data Processes page.</p>
                    </React.Fragment>
                );
            default:
                return null;
        }

        return null;
    };

    const onActionButton = () => {
        const action = getActionableTarget();

        switch (action) {
            case 'storageLocations':
                history.push('/third-parties');
                break;
            case 'individuals':
                history.push('/individuals');
                break;
            case 'processes':
                history.push('/processes');
                break;
            default:
                break;
        }
    };

    // They don't have access yet.
    if (!checkUserAccess({ flag: 'modules' })) return null; // Not for their eyes.

    // They don't have any incomplete tools.
    if (getActionableTarget() === null) return null;

    // If by any any mistake this happens..
    if (getTitle() === null || renderContentMessage() === null) return null;

    return (
        <React.Fragment>
            <div className="notification-dashboard-box yellow">
                <div className="title">{getTitle()}</div>
                <div className="content-box">{renderContentMessage()}</div>

                <div className="cta-button">
                    <Button variant="outlined" color="primary" onClick={onActionButton}>
                        Fix Information
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
