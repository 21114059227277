import React from 'react';
import { trackUserInteraction } from '../../../../../../utils/helpers';

const Component = () => {
    const liveChatSupport = async () => {
        if (window.HubSpotConversations === undefined && window.location.href.includes('localhost:3000')) return false; // blocking it on localhost since it won't work (chat is disabled)
        await trackUserInteraction(`Selected "Request Support via Chat"`, {
            subject: `cookie-banner`,
        });
        window.HubSpotConversations.widget.open();
    };

    return (
        <React.Fragment>
            <div className="step-entry">
                <div className="step-header">
                    <div className="step-badge">Info</div>
                    <div className="step-label">Support</div>
                </div>
                <div className="step-content" id="live-chat">
                    <div className="step-text">
                        <p style={{ margin: 0, marginBottom: 0 }}>
                            If you want to install your cookie banner using Google Tag Manager{' '}
                            <a
                                href="https://docs.usercentrics.com/#/google-tag-manager-configuration?id=create-a-new-google-analytics-tag"
                                target="_blank"
                            >
                                see this documentation.
                            </a>{' '}
                            (Not recommended)
                        </p>
                        <p style={{ margin: 0, marginBottom: 0 }}>
                            If you need more help,{' '}
                            <a style={{ cursor: 'pointer' }} onClick={liveChatSupport}>
                                click here{' '}
                            </a>{' '}
                            to request support via live chat.
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
