import React from 'react';

// Context
import { OnboardingContext } from '../..';

// Dependencies
import { CircularProgress } from '@mui/material';

const Component = () => {
    const { loading } = OnboardingContext();

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    {/* <div className="back-button">Go back</div> */}
                    <div className="title">{!loading ? `Error` : `Loading`}</div>
                </div>
            </div>
            <div className="loading module-content">
                <div className="entries d-flex-row-columns centered column-forced">
                    <span className="text-hint">
                        {!loading
                            ? `We're experiencing technical difficulties, please try again later.`
                            : `Preparing the workbench before we're starting..`}
                    </span>
                    {!loading ? (
                        <React.Fragment>
                            <i className="icon fa-solid fa-triangle-exclamation"></i>
                        </React.Fragment>
                    ) : (
                        <CircularProgress size={60} color="primary" />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
