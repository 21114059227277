import React from 'react';
import moment from 'moment';

// Components
import { Avatar } from '@mui/material';
import Tooltip from '../../../../../../../components/tooltip';

// Dependencies
import { addEllipsisIfExceedsMaxLength, trackUserInteraction } from '../../../../../../../../utils/helpers';

// Context
import { ModuleState } from '../../../../..';
import { getToolRecommendations } from '../../editTools/utils/helpers';

// Context
const Component = (props) => {
    const { data, setEditingLocId, toolsRecommended, onToolFoundSelected } = ModuleState();

    const getProcessesListing = (storageProcesses) => {
        if (storageProcesses.length < 1) return `No processes listed.`;

        let processes = [];

        // If is  anew tool found by Diana
        if (props.isNewTool) {
            processes = locationRecommendations.processesRecommended;
        } else {
            // Map the processes ids to their
            processes = storageProcesses
                .map((id) => {
                    const match = data.processes.find((c) => c._id === id);

                    return match ? match.label : null;
                })
                .filter((c) => c !== null);
        }

        const visiblePart = processes.slice(0, 3).join(', ');
        const invisiblePart = processes.slice(3);

        // Format..
        let str = invisiblePart.length > 0 ? `${visiblePart}, + ${invisiblePart.length} more.` : `${visiblePart}`;

        return addEllipsisIfExceedsMaxLength(str, 118);
    };

    const onClick = () => {
        // If is a new tool selected.
        if (props.isNewTool) {
            onToolFoundSelected(props.data._id);
            return false;
        }

        trackUserInteraction(`Clicked to edit tool "${props.data.label}"`);
        setEditingLocId(props.data._storageLocationId);
    };

    // Get the recommendations for this specific tool
    const locationRecommendations = getToolRecommendations(props.data.label, toolsRecommended);

    return (
        <React.Fragment>
            <div className={`component-storageLocation-card`} onClick={onClick}>
                <div className="content">
                    <div className="header">
                        <Avatar className="avatar" src={locationRecommendations ? locationRecommendations.image : undefined}>
                            {props.data.label[0]}
                        </Avatar>
                        <div className="details">
                            <div className="label">{props.data.label}</div>
                            <div className="createdAt">
                                {props.isNewTool ? 'Found' : 'Added'} on {moment(props.data.createdAt).format('DD MMM, YYYY')}
                            </div>
                        </div>
                        {props.showMissInfoBadge && (
                            <React.Fragment>
                                <div className={`miss-info-badge`}>
                                    <Tooltip content={`Click here to add the missing information.`}>
                                        <i className="elm fa-solid fa-circle-info"></i>
                                    </Tooltip>
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    {/* If is not a tool found */}
                    {!props.isNewTool && (
                        <React.Fragment>
                            <div className="processes">
                                <div className="--heading">
                                    <i className="icon fa-light fa-list-tree"></i> Processes
                                </div>
                                <div className="--content">{getProcessesListing(props.data.processes)}</div>
                            </div>

                            <div className="updatedAt">
                                <i className="icon fa-sharp fa-regular fa-clock"></i>Last updated on{' '}
                                {moment(props.data.updatedAt).format('DD MMM, YYYY, HH:mm')}
                            </div>
                        </React.Fragment>
                    )}

                    {/* If is a new tool found we display this instead */}
                    {props.isNewTool && locationRecommendations && (
                        <React.Fragment>
                            <div className="processes">
                                <div className="--heading">
                                    <i className="icon fa-light fa-table"></i> Category
                                </div>
                                <div className="--content">{locationRecommendations.category}</div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
