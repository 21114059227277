import React, { useState, useEffect } from 'react';

// Dependencies
import { makeLocalEndpointRequest } from '../../../../utils/endpoints';
import { logError, trackUserInteraction } from '../../../../utils/helpers';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AuthStore } from '../../../../utils/context/authStore';

const Component = () => {
    const [data, setData] = useState([]);
    const history = useHistory();
    const { account, globalFlags } = AuthStore();

    const loadData = async () => {
        try {
            if (!globalFlags['qaModule']) return false;

            // Get the company suggested tools
            let res = await makeLocalEndpointRequest(`/api/questionsAndAnswers/getList`, {}, 'GET');

            // filter
            res = res.filter((c) => c.assignedTo === `${account.email}` && (c.answer.trim().length < 1 || c.question.trim().length < 1));

            setData(res);

            // If there's more than one suggestion
            if (res.length > 0) {
                trackUserInteraction(`Questions Assigned`, { questionsAmount: res.length });
            }
        } catch (err) {
            await logError(`loadQuestionsAssigned`, err);
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (globalFlags['qaModule'] && data.length < 1) {
            loadData();
        }
        // eslint-disable-next-line
    }, [globalFlags]);

    const review = async () => {
        await trackUserInteraction(`Selected "Review"`);
        history.push(`/questions-and-answers?filters=primary:assignedTo:is=${account.email}`);
    };

    if (data.length < 1) return null;

    return (
        <React.Fragment>
            <div className="notification-dashboard-box yellow">
                <div className="title">Review {data.length} questions assigned</div>
                <div className="content-box">
                    <p style={{ margin: 0 }}>You've been assigned a question from the Question & Answers section.</p>
                </div>
                <div className="cta-button">
                    <Button variant="outlined" color="primary" onClick={review}>
                        Review Questions
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
