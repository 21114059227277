import React from 'react';
import { legalResponsibilityRoles } from '../../../../../../gdprEssentials/steps/legalResponsibility/utils/dataMaps';

// Context
import { ProcessContext } from '..';

const Component = () => {
    const { data, updateData } = ProcessContext();

    return (
        <React.Fragment>
            <div className="roles">
                <div className="sub-label">Legal responsibility</div>

                {legalResponsibilityRoles.map((role, xx) => (
                    <div
                        key={xx}
                        onClick={() => updateData({ role })}
                        data-cy={`process-${xx}-legalResponsibility-${xx}`}
                        className={`role-entry ${data.role === role && `active`}`}
                    >
                        {role}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default Component;
