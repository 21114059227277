import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';

// Dependencies
import {
    getFromUrlQueryString,
    logError,
    removeFromUrlQueryString,
    setInUrlQueryString,
    trackUserInteraction,
} from '../../../../../utils/helpers';
import { storageIsInvalid } from './utils';

// Component
import CreateTools from './components/createTools';
import EntryCard from './components/entryCard';
import EditPanel from './components/edit';
import DPAProcesses from './components/dpaNewProcesses';

// Events
import EventOnNewProcessAdded from './components/events/newProcessesAdded';

// Context
import { ModuleState } from '../..';

const StepReviewData = () => {
    const { data, setData, setStep } = ModuleState();
    const [createToolsPanelActive, setCreateToolsPanelActive] = useState(false);
    const [editStorage, setEditStorage] = useState({ index: null, sensitiveElements: false });
    const [archiveExpanded, setArchiveExpanded] = useState(false);
    const [checkQueries, setCheckQueries] = useState(false);

    const goBack = async () => {
        let previousStep = 'Children';

        setStep(previousStep);
        await trackUserInteraction(`Selected "Go Back"`, { destination: previousStep });
        updateValidationStatuses();
    };

    const getJointedElementsAndIndividuals = () => {
        let jointedIndividuals = [],
            jointedElements = [];

        data.storageLocations.forEach((loc) => {
            jointedIndividuals = [...jointedIndividuals, ...loc.individuals];
            jointedElements = [...jointedElements, ...loc.elements];
        });

        return { jointedIndividuals, jointedElements };
    };

    const updateValidationStatuses = async () => {
        setData((currentState) => {
            let newState = { ...currentState };
            newState.storageLocations.forEach((loc, index) => {
                newState.storageLocations[index].validated = storageIsInvalid(loc) ? false : true;
            });
            return newState;
        });
    };

    const checkInvalidTools = async (existingSkipping = {}) => {
        if (sortedLocations().invalids.length > 0) {
            await trackUserInteraction(`Showing "Missing Information" warning`);
            window.showAlert(
                'Missing Information',
                <div className="review-data-missing-warning-popup">
                    <p>Some tools are missing information and won't appear in your policies:</p>
                    <div className="list">
                        <p>Tools or Third Parties</p>
                        <ul>
                            {sortedLocations().invalids.map((l, index) => (
                                <li key={index}>{l.label}</li>
                            ))}
                        </ul>
                    </div>
                    <p>
                        Click on a tool to edit it or skip this for now. You will be able to come to this later by editing your data
                        inventory.
                    </p>
                </div>,
                'warning',
                [
                    {
                        text: 'Continue Anyways',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            nextStep({ ...existingSkipping, skipMissingInfo: true });
                            await trackUserInteraction(`Selected "Continue Anyways"`);
                        },
                    },
                    {
                        text: 'Go back and Edit',
                        dataCy: `alert-cancel-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            await trackUserInteraction(`Selected "Go back and Edit"`);
                        },
                    },
                ],
            );
            return false;
        }
        return true;
    };

    const checkDataMissing = async (existingSkipping = {}) => {
        const { jointedElements, jointedIndividuals } = getJointedElementsAndIndividuals();
        let checkedData = data.checkedData;
        let groupsMissing = { elements: [], individuals: [] };
        groupsMissing.elements = checkedData.elements.filter((elm) => (jointedElements.find((x) => x.label === elm.label) ? false : true));
        groupsMissing.individuals = checkedData.individuals.filter((indiv) =>
            jointedIndividuals.find((iteratedIndiv) => iteratedIndiv.label === indiv.label) ? false : true,
        );

        // Bugfix: As of right now we create Children individual whenever someone mark as ref role as isChildren
        const isMissingChildren = groupsMissing.individuals.find((c) => c.label === 'Children');
        const hasIndividualsWithIsChildren = data.companyInfo.individualsReferring.filter((c) => {
            // Referring role is not marked as children.
            if (c.isChildren === true) return true;

            // Is not used in any storage location.
            if (jointedIndividuals.find((d) => d.label === c.label)) return false;

            return false;
        });

        // If is missing children and he has individuals marked as children we're not actually missing children.
        if (isMissingChildren && hasIndividualsWithIsChildren) {
            // We remove the "we are missing children"
            groupsMissing.individuals = groupsMissing.individuals.filter((c) => c.label !== 'Children');
        }

        // Is he missing individuals data?
        if (groupsMissing.individuals.length > 0 || groupsMissing.elements.length > 0) {
            await trackUserInteraction(`Showing "Missing Data" warning`);
            return window.showAlert(
                'Missing Data',
                <div className="review-data-missing-warning-popup">
                    <p>You've informed us that you are storing data about:</p>
                    {groupsMissing.individuals.length > 0 && (
                        <div className="list">
                            <p>Vulnerable Individuals2</p>
                            <ul>
                                {groupsMissing.individuals.map((l, index) => (
                                    <li key={index}>{l.label}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {groupsMissing.elements.filter((elm) => elm.sensitive === true).length > 0 && (
                        <div className="list">
                            <p>Sensitive Personal Data</p>
                            <ul>
                                {groupsMissing.elements
                                    .filter((elm) => elm.sensitive === true)
                                    .map((l, index) => (
                                        <li key={index}>{l.label}</li>
                                    ))}
                            </ul>
                        </div>
                    )}
                    {groupsMissing.elements.filter((elm) => elm.sensitive === false).length > 0 && (
                        <div className="list">
                            <p>Personal Data</p>
                            <ul>
                                {groupsMissing.elements
                                    .filter((elm) => elm.sensitive === false)
                                    .map((l, index) => (
                                        <li key={index}>{l.label}</li>
                                    ))}
                            </ul>
                        </div>
                    )}
                    <p>You failed to let us know where. Please use the "Edit" feature to fix that.</p>
                    <p>Otherwise you can go back and unchecked it if you don't use it.</p>
                </div>,
                'warning',
                [
                    {
                        text: 'Continue Anyways',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            nextStep({ ...existingSkipping, skipDataMissing: true });
                            await trackUserInteraction(`Selected "Continue Anyways"`);
                        },
                    },
                    {
                        text: 'Go back and Edit',
                        dataCy: `alert-cancel-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            await trackUserInteraction(`Selected "Go back and Edit"`);
                        },
                    },
                ],
            );
        }

        // Show alert if services are not present in data inventory

        // Get the missing services from data inventory
        const servicesMissing = getMissingServices();

        // If there is something missing..
        if (servicesMissing.length > 0) {
            // Show alert.
            await trackUserInteraction(`Showing "Missing Data" warning`);
            return window.showAlert(
                'Missing Data',
                <div className="review-data-missing-warning-popup">
                    <p>The core activities of your company are currently not included in the data inventory.</p>
                    <p>The following activities are missing:</p>
                    <div className="list">
                        <ul>
                            {servicesMissing.map((c, index) => (
                                <li key={index}>{c.label}</li>
                            ))}
                        </ul>
                    </div>
                    <p>
                        It seems that you haven't added them to a tool or third party. To address this click on go back and edit, then click
                        on any third party and add it to the data overview tab.
                    </p>
                    <p>Alternatively, you can Dismiss this warning for now.</p>
                </div>,
                'warning',
                [
                    {
                        text: 'Continue Anyways',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            nextStep({ ...existingSkipping, skipDataMissing: true });
                            await trackUserInteraction(`Selected "Continue Anyways"`);
                        },
                    },
                    {
                        text: 'Go back and Edit',
                        dataCy: `alert-cancel-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            await trackUserInteraction(`Selected "Go back and Edit"`);
                        },
                    },
                ],
            );
        }

        return true;
    };

    const checkIndividualsMissing = async (existingSkipping = {}) => {
        try {
            // Get the individuals missing
            const individualsNotInDataInventory = data.companyInfo.individualsReferring.filter((indiv) => {
                const storageMatching = data.storageLocations.find((c) => {
                    const individualMatching = c.individuals.find((g) => g.label == indiv.label);
                    return individualMatching ? true : false;
                });

                if (!storageMatching) return true;
                return false;
            });

            // All is good.
            if (individualsNotInDataInventory.length < 1) return true;

            window.showAlert(
                'Missing Data',
                <div className="review-data-missing-warning-popup">
                    <p>
                        You mentioned that certain individuals are being used, but it's not clear what information we are collecting from
                        these individuals.
                    </p>
                    <p>The following individuals are not used anywhere:</p>
                    <div className="list">
                        <ul>
                            {individualsNotInDataInventory.map((c, index) => (
                                <li key={index}>{c.label}</li>
                            ))}
                        </ul>
                    </div>
                    <p>
                        It seems that you haven't added them to a tool or third party. To address this click on go back and edit, then click
                        on any third party and add it to the data overview tab.
                    </p>
                    <p>Alternatively, you can Dismiss this warning for now.</p>
                </div>,
                'warning',
                [
                    {
                        text: 'Continue Anyways',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            nextStep({ ...existingSkipping, skipIndividualsMissing: true });
                        },
                    },
                    {
                        text: 'Go back and Edit',
                        dataCy: `alert-cancel-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                ],
            );

            return false;
        } catch (err) {
            await logError(`gel.reviewData.checkIndividualsMissing`, err);
            return false;
        }
    };

    const getMissingServices = () => {
        // Is he missing services from data inventory?
        const featuresExisting = [];

        // Check which one is missing.
        data.storageLocations.forEach((storageLocation) => {
            storageLocation.processes.forEach((process) => {
                // Find match
                const match = data.companyInfo.services.find((c) => c.label === process.label);
                if (!match) return; // Not found.

                // Already exist..
                if (featuresExisting.includes(match)) return;

                // Add
                featuresExisting.push(match);
            });
        });

        // Now who is missing
        const featuresMissing = [...data.companyInfo.services].filter((c) => !featuresExisting.includes(c));

        return featuresMissing;
    };

    const nextStep = async ({ skipDataMissing = false, skipMissingInfo = false, skipIndividualsMissing = false }) => {
        const currentSkipping = { skipDataMissing, skipMissingInfo, skipIndividualsMissing };

        if (skipDataMissing === false && (await checkDataMissing(currentSkipping)) !== true) return false;
        if (skipMissingInfo === false && (await checkInvalidTools(currentSkipping)) !== true) return false;
        if (skipIndividualsMissing === false && (await checkIndividualsMissing(currentSkipping)) !== true) return false;

        setStep('LegalResponsibility');
        updateValidationStatuses();
        await trackUserInteraction(`Selected "Next"`, { destination: `LegalResponsibility` });
    };

    const addThirdParty = async () => {
        if (editStorage && editStorage.index) return false;
        await trackUserInteraction(`Selected "Add custom third party"`);
        setCreateToolsPanelActive(true);
    };

    const sortByLabelAlphabetically = (a, b) => {
        const aLabel = a.label.toLowerCase();
        const bLabel = b.label.toLowerCase();

        if (aLabel < bLabel) {
            return -1;
        }
        if (aLabel > bLabel) {
            return 1;
        }
        return 0;
    };

    const sortedLocations = () => {
        const valids = [],
            invalids = [],
            archived = [];

        data.storageLocations.forEach((storage, index) => {
            let isInvalid = storageIsInvalid(storage);
            // Sorting finally
            const i = { ...storage, originalIndex: index };

            if (storage.archived === true) {
                archived.push(i);
            } else if (isInvalid) {
                invalids.push(i);
            } else {
                valids.push(i);
            }
        });
        return { valids, invalids, archived };
    };

    const getStorageEditingIndexFromQuery = () => {
        try {
            // Query
            const query = getFromUrlQueryString('editingStorageLocationId');
            if (!query) return null;

            // Get the storage index
            const storageIndex = data.storageLocations.findIndex((c) => c._storageLocationId === query);
            if (storageIndex === -1) return null; // If the storage location id is invalid.

            return storageIndex;
        } catch (err) {
            logError('gel.reviewData.getStorageEditingIndexFromQuery', err);
        }
    };

    const checkQueriesForEditingIndex = () => {
        try {
            // Check initial query
            const editingIndex = getStorageEditingIndexFromQuery();

            // Safety cleanup
            const query = getFromUrlQueryString('editingStorageLocationId');
            if (query && editingIndex === null) {
                removeFromUrlQueryString('editingStorageLocationId');
            }

            // Mark as checked queries so the normal updating can begin.
            setCheckQueries(true);

            // Now since we don't find any tool we just return false.
            if (editingIndex === null) return false;

            // Open panel.
            setEditStorage({ index: editingIndex });
        } catch (err) {
            logError(`gel.checkQueriesForEditingIndex`, err);
        }
    };

    useEffect(() => {
        checkQueriesForEditingIndex();
        // eslint-disable-next-line
    }, []);

    const updateQueryForEditingId = () => {
        try {
            // Did we finish checking for queries and opening tools..?
            if (!checkQueries) return false;

            // If we set it to true..
            if (editStorage.index !== null) {
                const storageLocation = data.storageLocations[editStorage.index];

                if (!storageLocation) return false;
                setInUrlQueryString('editingStorageLocationId', storageLocation._storageLocationId);
            }

            // We turn it off but we have that in param.
            if (editStorage.index === null && getFromUrlQueryString('editingStorageLocationId')) {
                removeFromUrlQueryString('editingStorageLocationId');
            }
        } catch (err) {
            logError(`gel.reviewData.updateQueryForEditingId`, err);
        }
    };

    useEffect(() => {
        updateQueryForEditingId();

        // eslint-disable-next-line
    }, [editStorage.index]);

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" onClick={goBack} data-cy="back-button">
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Review your tools and third parties
                    </div>
                    <div className="subtitle">
                        We've found the most common uses, security measures and data types for your tools. Please check the information
                        below before moving forward.
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={sortedLocations().valids.length < 1}
                        onClick={() => nextStep({})}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
            <CreateTools setActive={setCreateToolsPanelActive} active={createToolsPanelActive} />
            <div className="review-data module-content">
                {sortedLocations().invalids.length > 0 && (
                    <React.Fragment>
                        <div className="entries-label">More information needed ({sortedLocations().invalids.length})</div>
                        <div className="entries-description">
                            We couldn't find recommendations for the following tools. Please click on each tool and fill in the missing
                            information.
                        </div>
                        <div className="entries mb" data-cy="entries-more-info-needed">
                            {sortedLocations()
                                .invalids.sort(sortByLabelAlphabetically)
                                .map((entry, sortedIndex) => (
                                    <EntryCard
                                        dataCy={`entry-faulty-${sortedIndex}`}
                                        editStorage={editStorage}
                                        setEditStorage={setEditStorage}
                                        key={sortedIndex}
                                        data={entry}
                                        alert={true}
                                    />
                                ))}
                        </div>
                    </React.Fragment>
                )}
                <div className="entries-label">Your tools and third parties</div>
                <div className={`entries ${sortedLocations().archived.length > 0 && `mb`}`} data-cy="entries">
                    <div className="entry create" data-cy="create-entry-button">
                        <div className="content" onClick={addThirdParty}>
                            <i className="icon fa-solid fa-circle-plus"></i>
                            <div className="label">Add third party or tool</div>
                        </div>
                    </div>
                    {sortedLocations()
                        .valids.sort(sortByLabelAlphabetically)
                        .map((entry, sortedIndex) => (
                            <EntryCard
                                dataCy={`entry-${sortedIndex}`}
                                editStorage={editStorage}
                                setEditStorage={setEditStorage}
                                key={sortedIndex}
                                data={entry}
                            />
                        ))}
                </div>
                {sortedLocations().archived.length > 0 && (
                    <React.Fragment>
                        <div className="entries-heading archive">
                            <div className="left-area">
                                <div className="entries-label">Archived tools</div>
                                <div className="entries-description">
                                    <b>{sortedLocations().archived.length}</b> tools have been archived. This means they will not appear on
                                    your inventory or your policies.
                                </div>
                            </div>
                            <div className="side-area">
                                <Button
                                    variant="outlined"
                                    data-cy="button-switch-archive-show"
                                    color="primary"
                                    onClick={() => {
                                        setArchiveExpanded(!archiveExpanded);
                                        trackUserInteraction(`Selected "${archiveExpanded ? `Hide Archived` : `See Archived`}"`);
                                    }}
                                >
                                    {archiveExpanded ? `Hide archived` : `See archived`}
                                </Button>
                            </div>
                        </div>
                        <div className={`collapsed-section ${archiveExpanded && `true`}`}>
                            <div className="entries mb" data-cy="entries-archived">
                                {sortedLocations()
                                    .archived.sort(sortByLabelAlphabetically)
                                    .map((entry, sortedIndex) => (
                                        <EntryCard
                                            dataCy={`entry-archived-${sortedIndex}`}
                                            editStorage={editStorage}
                                            setEditStorage={setEditStorage}
                                            key={sortedIndex}
                                            data={entry}
                                            alert={true}
                                            archived={true}
                                        />
                                    ))}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
            <EditPanel editStorage={editStorage} setEditStorage={setEditStorage} />
            <EventOnNewProcessAdded />
            <DPAProcesses />
        </React.Fragment>
    );
};

export default StepReviewData;
