import React, { createContext, useContext, useEffect, useState } from 'react';

// Component
import Header from './components/header';
import Autocomplete from './components/autocomplete';
import Footer from './components/footer';

// Context
import { OnboardingContext } from '../..';
import { logError } from '../../../../../utils/helpers';
import { OnboardingFunctions } from '../../components/functions';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { companyData } = OnboardingContext();
    const { updateCompanyData } = OnboardingFunctions();

    const [activities, setActivities] = useState(companyData.services);

    const updateCompanyActivities = async () => {
        try {
            await updateCompanyData({ services: activities });
        } catch (err) {
            await logError(`onboarding.activities.updateCompanyActivities`, err);
            return false;
        }
    };

    // When the function is successful we need to update the local state too.
    useEffect(() => {
        setActivities(companyData.services);
    }, [companyData.services]);

    const PassedProps = {
        activities,
        setActivities,
        updateCompanyActivities,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <div className="company-services module-content">
                <div className="input-wrapper">
                    <Autocomplete />
                    <Footer />
                </div>
            </div>
        </Context.Provider>
    );
};

export default Component;
