import React from 'react';

const Component = () => {
    return (
        <React.Fragment>
            <div className="instruction-banners">
                <div className="entries">
                    <div className="entry">
                        <div className="content">
                            <div className="heading">Examples</div>
                            <div className="rows">
                                <div className="text">"What data do we collect?"</div>
                                <div className="text">"What tools or third parties are we using?"</div>
                                <div className="text">"Do I use cookies?"</div>
                            </div>
                        </div>
                    </div>
                    <div className="entry">
                        <div className="content">
                            <div className="heading">Capabilities</div>
                            <div className="rows">
                                <div className="text">Continuously trained with knowledge</div>
                                <div className="text">Understanding of Data Privacy Laws</div>
                                <div className="text">Knows your company's data</div>
                            </div>
                        </div>
                    </div>
                    <div className="entry">
                        <div className="content">
                            <div className="heading">Limitations</div>
                            <div className="rows">
                                <div className="text">May generate incorrect information</div>
                                <div className="text">Unable to remember conversation</div>
                                <div className="text">Struggles with extremely large inventories</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
