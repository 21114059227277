const DataInventoryEndpoints = {
    GetDataInventory: {
        link: '/graphql',
        method: 'POST',
        query: `query GetDataInventory($onlyPublishedStorageLocations: Boolean!, $bundleStorageLocations: Boolean) {
          GetDataInventory(onlyPublishedStorageLocations: $onlyPublishedStorageLocations, bundleStorageLocations: $bundleStorageLocations)
        }`,
        parserFunction: ({ data }) => data.GetDataInventory,
    },
    SyncUCInventory: {
        link: `/graphql`,
        method: `POST`,
        query: `
      mutation UpdateSettings
        {
        UpdateSettings
        }
    `,
        timeout: 60000,
    },
    EnableCookieBanner: {
        link: `/graphql`,
        method: `POST`,
        query: `
          mutation CreateSettings
            {
              CreateSettings
            }
        `,
        parserFunction: ({ data }) => data.CreateSettings,
        timeout: 80000,
    },
    DisableCookieBanner: {
        link: `/graphql`,
        method: `POST`,
        query: `
          mutation DisableCookieBanner
          {
            DisableCookieBanner
          }
    `,
        parserFunction: ({ data }) => data.DisableCookieBanner,
        timeout: 60000,
    },
    DeleteCBSettings: {
        link: `/graphql`,
        method: `POST`,
        query: `
        mutation DeleteSettings($settingsId: String, $email: String)
          {
            DeleteSettings(settingsId: $settingsId, email: $email)
          }
      `,
        parserFunction: ({ data }) => data.DeleteSettings,
        timeout: 60000,
    },
};

export default DataInventoryEndpoints;
