import React from 'react';

// Components
import Entry from '../entry';

const Component = (props) => {
    const getTitle = () => {
        if (props.id === 'missingInformation') return `Missing Information (${props.entries.length})`;
        if (props.id === 'prospects') return `Prospects`;
        if (props.id === 'customers') return `Customers`;
        if (props.id === 'staff') return `Staff`;
        if (props.id === 'others') return `Other types of individuals`;
    };

    const getDescription = () => {
        if (props.id === 'missingInformation')
            return `We're missing some information for the following Individuals. Complete the missing information so they appear in your policies.`;

        if (props.id == 'prospects')
            return `An individual who is or might be interested in our product or service but are not customers yet. They might be enrolled in sequences, have left their details on an ad or form, or be in your CRM.`;

        if (props.id === 'customers')
            return `An individual who has purchased or is using your company’s product or service. You might have multiple types of customers or users.`;

        if (props.id === 'staff')
            return `An individual on staff plays a crucial role in your organization, contributing to overall success and functionality.`;

        if (props.id === 'others')
            return `An individual in this category is not a prospect, customer, or staff member, representing miscellaneous individuals outside defined groups.`;
    };

    return (
        <React.Fragment>
            <div className="component-group">
                <div className="--component-header">
                    <div className="title">{getTitle()}</div>
                    <div className="message">{getDescription()}</div>
                </div>
                <div className="--component-entries">
                    {props.entries.map((c, index) => (
                        <Entry data={c} key={index} groupId={props.id} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
