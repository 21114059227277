import React from 'react';

// Components
import AutoComplete from '../../../../thirdParties/components/editPanel/components/autocomplete';

// Context
import { OnboardingContext } from '../../..';
import { OnboardingFunctions } from '../../../components/functions';

// Dependencies
import { logError } from '../../../../../../utils/helpers';

const Component = ({ data }) => {
    const { vitalRecommendations, dataInventory, companyData } = OnboardingContext();
    const { updateCompanyData, createIndividual, deleteIndividual } = OnboardingFunctions();

    const getTitle = () => {
        if (data.value === 'others') return `Other types of Individuals`;
        return (
            <React.Fragment>
                How do you refer to your <span>{data.label}</span>?
            </React.Fragment>
        );
    };

    const getDescription = () => {
        if (data.value === 'others') return `You can use this area to include individuals who don't belong to any other category.        `;

        return data.description;
    };

    /**
     * This function generates a list of options for user selection.
     * It includes both recommended and existing individuals for a group.
     * It checks each individual's eligibility and presence in the data inventory.
     * Returns a sorted list of options.
     */

    const getOptions = () => {
        try {
            let options = [];

            // Go through all the individuals recommended
            vitalRecommendations.individuals.forEach((individualRecommended) => {
                // Is not recommended for this individual group
                if (!individualRecommended.allowedTypes.includes(data.label)) return;

                // Check if it exists in
                const inInventory = dataInventory.individuals.find((c) => c.label === individualRecommended.label);

                // @Prevention: If this individual is already set in another individual group, we must not show it as an option.
                // Example: Viewers/Listeners can be both Prospects and Customer. But if is set for Customers, we won't display it for Prospects.
                const alreadyReferred = companyData.individualsReferring.find(
                    (c) => c.referringRole !== data.value && c.label === individualRecommended.label,
                );
                if (alreadyReferred) return; // Not gonna display it.

                options.push({
                    referringRole: data.value,
                    label: individualRecommended.label,
                    category: inInventory ? 'Data Inventory' : 'Other Recommendations',
                });
            });

            return options.sort((a, b) => a.category.localeCompare(b.category));
        } catch (err) {
            console.error(`[render Error] getOptions`, err);
            return [];
        }
    };

    const getCurrentValue = () => {
        try {
            const entries = companyData.individualsReferring.filter((c) => c.referringRole === data.value);
            return entries;
        } catch (err) {
            console.error(`[render Error] getCurrentValue`, err);
            return [];
        }
    };

    const onIndividualAdded = async (entry) => {
        try {
            // Ensure it exists in the data inventory.
            let individual = dataInventory.individuals.find((c) => c.label === entry.label);

            // If the individual does not exist.
            if (!individual) {
                // Create the individual
                individual = await createIndividual(entry.label);
            }

            // Update company referring role
            await updateCompanyData({
                individualsReferring: [
                    ...companyData.individualsReferring,
                    {
                        label: entry.label,
                        referringRole: data.value,
                        isChildren: false,
                    },
                ],
            });
        } catch (err) {
            await logError(`onboarding.individuals.onIndividualAdded`, err, {});
        }
    };

    const onIndividualRemoved = async (entry) => {
        try {
            // Update individual referring
            await updateCompanyData({ individualsReferring: companyData.individualsReferring.filter((c) => c.label !== entry.label) });

            // Also remove this individual if is not used by any storage location
            const unusedIndividuals = dataInventory.individuals.filter((individual) => {
                const isUsed = dataInventory.storageLocations.find((s) => s.individuals.includes(individual._id));

                return isUsed ? false : true;
            });

            // Check if is used
            const isUnused = unusedIndividuals.find((d) => d.label === entry.label);

            // If this individual is not used by any storage location we will remove it.
            if (isUnused) {
                deleteIndividual(isUnused._id);
            }
        } catch (err) {
            await logError(`onboarding.onIndividualRemoved`, err, { entry });
        }
    };

    const canCreateThisLabel = (label) => {
        // This already exists
        const alreadyExistInOptions = getOptions().find((d) => d.label === label);
        if (alreadyExistInOptions) return false;

        // Already exists but in a different group.
        const alreadyDefinedSomewhere = companyData.individualsReferring.find((c) => c.referringRole !== data.value && label === c.label);
        if (alreadyDefinedSomewhere) return false;

        return true;
    };

    return (
        <React.Fragment>
            <div className="entry">
                <div className="label">{getTitle()}</div>
                <div className="description">{getDescription()}</div>
                <div className="input-container">
                    <AutoComplete
                        // The value and options
                        value={getCurrentValue()}
                        options={getOptions()}
                        // Props to the autocomplete..
                        groupCategories={true}
                        subjectsView={false}
                        placeholder={'Select an individual here..'}
                        // Callbacks when data is changed
                        onEntityAdded={(elm) => onIndividualAdded(elm)}
                        onEntityRemoved={(elm) => onIndividualRemoved(elm)}
                        notCreatable={false}
                        validateCreation={canCreateThisLabel}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
