import React from 'react';

import Panel from './utils/panel';
import Functions from './utils/functions';

const Component = () => {
    return (
        <Functions>
            <Panel />
        </Functions>
    );
};

export default Component;
