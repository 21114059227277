import { PageMappings } from '.';

export const formatCookieObjects = (arr) => {
    return arr.map((c) => {
        return {
            _id: c.id,
            _storageLocationId: c._storageLocationId,
            locationName: c.name,
            cookieType: c.type,
            cookiePurpose: c.purpose,
            cookieExternalInfo: c.external_link,
            translations: c.translations,
            cookieDefaultConsentStatus: c.cookieDefaultConsentStatus,
            cookieNames: c.cookieNames,
            cookieExpiry: c.cookieExpiry,
        };
    });
};

export const isValidPageToRender = (str) => {
    if (PageMappings[str] !== undefined) return true;
    return false;
};

export const decideBestPage = (tab) => {
    if (isValidPageToRender(tab)) {
        return tab;
    } else {
        return `customization`; // default one if nothing else works.
    }
};

export const updateUrl = (tab) => window.history.pushState('_', '_', `/settings/privacy-portal/${tab}`);
