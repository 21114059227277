import React, { createContext, useContext, useRef } from 'react';

// Dependencies
import { convertMatriceToMatriceView, getMatriceEntriesData, getStorageLocationData } from '../utils/helpers';
import { makeEndpointRequest } from '../../../../../../../../utils/endpoints';
import { logError } from '../../../../../../../../utils/helpers';
import { getIndividualTypesIdFromLabel } from '../../../../../../gdprEssentials/steps/individuals/components/functions';

// Module Context
import { convertMatriceViewToMatrixEntries, getRelationshipsFromMatrice } from '../../../../editPanel/utils/functions';

// Context
const Context = createContext({});
export const FunctionsContext = () => useContext(Context);

const Component = (props) => {
    const { data, editingLocId, setData, refreshDataInventory, setEditingLocId, vitalRecommendations } = props;

    // This will be used so we can set the panel's view and more from outside it.
    const panelController = useRef(null);

    // Get the data of the storage location we're editing to pass it.
    const locationData = getStorageLocationData(editingLocId, data.storageLocations);

    /**
     * This function will return the initial data for the locationData.
     */

    const getInitialData = () => {
        try {
            // Get data src
            const src = getStorageLocationData(editingLocId, data.storageLocations);
            if (!src) return null;

            // Get matrice data
            const matrice = getMatriceEntriesData(src._id, data.matrices);

            // We convert our data inventory Matrices to Matrix View (V2) data.
            const matrixMap = convertMatriceToMatriceView(matrice, src.processes);

            return {
                _id: src._id,
                label: src.label,
                _storageLocationId: src._storageLocationId,
                // Details
                securityMeasures: src.securityMeasures,
                dataResidency: src.dataResidency,
                // DPA
                executedDpaLink: src.executedDpaLink,
                dpaType: src.dpaType,
                isSubProcessor: src.isSubProcessor,
                subProcessorMeasures: src.subProcessorMeasures,
                // State
                archived: src.archived,
                validated: src.validated,
                // Data used
                matrixMap,
            };
        } catch (err) {
            return null;
        }
    };

    /**
     * This function is called when they try to close the panel.
     */

    const onPanelClose = async () => {
        try {
            // Close the panel
            panelController.current.modal.current.close(() => {
                // Reset it.
                setEditingLocId(null);
            });
        } catch (err) {
            await logError(`editTools.onPanelClose`, err);
        }
    };

    /**
     * This is called whenever the user presses "Save Changes" button on Edit Panel.
     * @param {*} data - Data of the Edit Panel.
     * @returns
     */

    const updateStorageLocationData = async (data) => {
        try {
            // Update the relationship and matrix according to matrix map (visual data)
            const matrixEntries = convertMatriceViewToMatrixEntries(data.matrixMap);

            // Updating matrix entries
            await setMatriceEntries(matrixEntries);

            // Get relationships after the matrix has been updated.
            const relationships = getRelationshipsFromMatrice(matrixEntries);

            // The payload
            let dataPayload = {
                label: data.label,
                // Statuses
                archived: data.archived,
                // General
                dataResidency: data.dataResidency,
                securityMeasures: data.securityMeasures,
                // DPA
                dpaType: data.dpaType,
                executedDpaLink: data.executedDpaLink,
                isSubProcessor: data.isSubProcessor,
                subProcessorMeasures: data.subProcessorMeasures,
                // Updating relationships
                ...relationships,
                // Updating timestamp
                updatedAt: new Date(),
            };

            // Update the data in data inventory and session (API Takes care of that)
            const updatedDocument = await makeEndpointRequest(`UpdateStorageLocation`, {
                _id: locationData._id,
                payload: dataPayload,
            });

            // Update local data state
            setData((currentState) => {
                // Find the index
                let matchIndex = currentState.storageLocations.findIndex((c) => c._id === locationData._id);
                if (matchIndex === -1) return currentState;

                // Format new data
                let newState = { ...currentState };

                // Change it..
                newState.storageLocations[matchIndex] = updatedDocument;

                return newState;
            });
        } catch (err) {
            await logError(`thirdParties.updateStorageLocationData`, err, { payload, editingLocId });
            return false;
        }
    };

    const deleteStorageLocation = async () => {
        try {
            // Delete it
            await makeEndpointRequest(`DeleteStorageLocation`, {
                _storageLocationId: locationData._storageLocationId,
            });

            // Stop editing
            setEditingLocId(null);

            // Refresh data inventory.
            refreshDataInventory();
        } catch (err) {
            await logError(`thirdParties.deleteStorageLocation`, err);
            return false;
        }
    };

    const createProcess = async (processName) => {
        try {
            // Get recommendations for this process.
            const recommendation = vitalRecommendations.processes.find((c) => c.label === processName);

            let personalLegalBasis = null;
            let sensitiveLegalBasis = null;
            let role = recommendation ? recommendation.legalResponsibility : null;

            if (recommendation) {
                // Extracting..
                const art6 = recommendation.article6.split('~');
                const art9 = recommendation.article9.split('~');

                // Format the data
                personalLegalBasis = { string: art6[0] ? art6[0].trim() : '', gdpr: art6[1] ? art6[1].trim() : '' };
                sensitiveLegalBasis = art9[1] ? { string: art9[0] ? art9[0].trim() : '', gdpr: art9[1] ? art9[1].trim() : '' } : null;
            }

            // Create the new process in the back-end
            const newProcess = await makeEndpointRequest(`CreateProcess`, {
                payload: {
                    label: processName,
                    role,
                    personalLegalBasis,
                    sensitiveLegalBasis,
                },
            });

            // Add this new process to data inventory
            setData((currentState) => {
                // Format new data
                let newState = { ...currentState };

                // Change it..
                newState.processes.push(newProcess);

                return newState;
            });

            // Return the new created process
            return newProcess;
        } catch (err) {
            await logError(`thirdParties.createProcess`, err);
            return null;
        }
    };

    const createElement = async (elementCreated) => {
        try {
            // Create the new process in the back-end
            const newElement = await makeEndpointRequest(`CreateElement`, {
                payload: {
                    label: elementCreated.label,
                    sensitive: elementCreated.sensitive,
                },
            });

            // Add this new process to data inventory
            setData((currentState) => {
                // Format new data
                let newState = { ...currentState };

                // Change it..
                newState.elements.push(newElement);

                return newState;
            });

            // Return the new created process
            return newElement;
        } catch (err) {
            await logError(`thirdParties.createElement`, err);
            return null;
        }
    };

    const createIndividual = async (individualName) => {
        try {
            // Get recommendations for this process.
            let rec = vitalRecommendations.individuals.find((c) => c.label === individualName);

            // Format referring role
            let referringRole = rec && rec.type && getIndividualTypesIdFromLabel(rec.type) ? getIndividualTypesIdFromLabel(rec.type) : null;

            // Create individual..
            const newIndividual = await makeEndpointRequest(`CreateIndividual`, {
                payload: {
                    label: individualName,
                },
                individualReferring: {
                    referringRole: referringRole || undefined,
                    isChildren: individualName === 'Children' ? true : undefined,
                },
            });

            // Add this new individual to data inventory
            setData((currentState) => {
                // Format new data
                let newState = { ...currentState };

                // Change it..
                newState.individuals.push(newIndividual);

                return newState;
            });

            return newIndividual;
        } catch (err) {
            await logError(`thirdParties.createIndividual`, err);
            return null;
        }
    };

    const setMatriceEntries = async (entries) => {
        try {
            // Call the API
            const updatedDocument = await makeEndpointRequest(`SetStorageMatriceEntries`, {
                _id: locationData._id,
                entries,
            });

            // Update local data state for matrices.
            setData((currentState) => {
                // Find the index
                let matchIndex = currentState.matrices.findIndex((c) => c._storageLocationId === locationData._id);

                // Format new data
                let newState = { ...currentState };

                // Change it or add it if it never existed.
                if (matchIndex !== -1) {
                    newState.matrices[matchIndex] = updatedDocument;
                } else {
                    newState.matrices.push(updatedDocument);
                }

                return newState;
            });
        } catch (err) {
            await logError(`setMatriceEntries`, err);
            return false;
        }
    };

    const cleanupInventory = async () => {
        try {
            // Cleanup
            await makeEndpointRequest(`CleanupInventory`);

            // Refresh
            refreshDataInventory();
        } catch (err) {
            await logError(`editTools.cleanupInventory`, err);
        }
    };

    const PassedProps = {
        // Storage Locations
        updateStorageLocationData,
        deleteStorageLocation,
        // Processes
        createProcess,
        // Individuals
        createIndividual,
        // Elements
        createElement,
        // Matrices
        setMatriceEntries,
        // Ref is important
        panelController,
        // Functions
        onPanelClose,
        refreshDataInventory,
        cleanupInventory,
        // Data
        getInitialData,
    };

    return <Context.Provider value={PassedProps}>{props.children}</Context.Provider>;
};

export default Component;
