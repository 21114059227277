import React from 'react';

// Component
import PanelComponent from '../../panel';

// Context
import { ModuleState } from '../..';
import { FunctionsContext } from './components/functions';

const Component = () => {
    const { data: dataInventory, setData, panelData, companyData, toolsRecommended, vitalRecommendations } = ModuleState();
    const { panelController, getInitialData, closePanel } = FunctionsContext();
    const { createProcess, deleteProcess, updateProcess } = FunctionsContext();

    // We don't use the panel right now
    if (panelData.method === null) return null;

    return (
        <React.Fragment>
            <PanelComponent
                isEditing={panelData.method === 'edit' ? true : false}
                // The initial data for this process
                initialData={getInitialData()}
                // Data Inventory
                dataInventory={dataInventory}
                setDataInventory={setData}
                // Company data
                companyData={companyData}
                // Tools recommendations
                toolsRecommendations={toolsRecommended}
                // Vital recommendations (for tools etc)
                vitalRecommendations={vitalRecommendations}
                // Ref to close it
                innerRef={panelController}
                // Callback when panel is closed.
                onClose={closePanel}
                // Callbacks important
                onProcessCreation={createProcess}
                onProcessUpdate={updateProcess}
                onProcessDelete={deleteProcess}
            />
        </React.Fragment>
    );
};

export default Component;
