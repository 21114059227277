import React, { useEffect, useState } from 'react';

// Component
import { Switch } from '@mui/material';

// Dependencies
import { ComponentState } from '../../..';
import { renderText } from '../utils';

const Component = (props) => {
    const { draftData, setDraftData, responses } = ComponentState();
    const [expanded, setExpanded] = useState(false);

    const getCurrentValue = () => {
        const match = draftData.securityMeasures.find((s) => s._id === props.data._id && s.source === props.data.source);

        return match !== undefined ? match.value : false;
    };

    const onChecked = () => {
        setDraftData((currentState) => {
            const currentMeasures = [...currentState.securityMeasures];

            const currentIndex = currentMeasures.findIndex((e) => e._id === props.data._id && e.source === props.data.source);

            if (currentIndex === -1) {
                currentMeasures.push({
                    _id: props.data._id,
                    source: props.data.source,
                    value: true,
                });
            } else {
                currentMeasures[currentIndex].value = !currentMeasures[currentIndex].value;
            }

            return { ...currentState, securityMeasures: currentMeasures };
        });
    };

    const setDefaultValue = () => {
        setDraftData((currentState) => {
            const currentMeasures = [...currentState.securityMeasures];
            const currentIndex = currentMeasures.findIndex((e) => e._id === props.data._id && e.source === props.data.source);

            if (currentIndex !== -1) return currentState;

            const responseMatching = responses.find((e) => e.source === props.data.source && e._id === props.data._id);

            currentMeasures.push({
                _id: props.data._id,
                value: responseMatching ? responseMatching.value : false,
                source: props.data.source,
            });

            return { ...currentState, securityMeasures: [...currentMeasures] };
        });
    };

    useEffect(() => {
        document.addEventListener('dpa:setDefaultsInSection', setDefaultValue);

        return () => {
            document.removeEventListener('dpa:setDefaultsInSection', setDefaultValue);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className={`entry boolean ${expanded ? 'expanded' : 'not-expanded'}`}>
                <div className="header">
                    <div className="input">
                        <Switch checked={getCurrentValue()} onClick={onChecked} />
                    </div>
                    <div className="label">{props.data.details.label}</div>
                    <div className="toggler" onClick={() => setExpanded(!expanded)}>
                        {expanded ? <i className="icon fa-regular fa-chevron-up"></i> : <i className="icon fa-regular fa-chevron-down"></i>}
                    </div>
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: renderText(props.data.details.description) }} />
            </div>
        </React.Fragment>
    );
};

export default Component;
