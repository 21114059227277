import { logError } from '../../../../../../../../utils/helpers';

/**
 * This is just for the front-end so we can display the labels etc.
 * @param {*} tools - the return from convertMatriceEntriesToTools function.
 * @param {*} dataInventory - the whole data inventory.
 * @returns
 */

export const mapToolsEntriesToDataInventory = (tools, dataInventory) => {
    try {
        return tools
            .map((c) => {
                // get the tool mentioned
                const toolMentioned = dataInventory.storageLocations.find((b) => b._id === c._storageLocationId);
                if (!toolMentioned) return null;

                return {
                    individuals: c.individuals
                        .map((i) => {
                            // get the tool mentioned
                            const individual = dataInventory.individuals.find((b) => b._id === i._individualId);
                            if (!individual) return null;

                            return {
                                ...i,
                                elements: i.elements
                                    .map((_id) => {
                                        // get the tool mentioned
                                        const element = dataInventory.elements.find((c) => c._id === _id);
                                        if (!element) return null;

                                        return {
                                            _id,
                                            data: element,
                                        };
                                    })
                                    .filter((c) => c !== null),
                                data: individual,
                            };
                        })
                        .filter((c) => c !== null),
                    data: toolMentioned,
                };
            })
            .filter((c) => c !== null);
    } catch (err) {
        logError(`module.processes.utils.mapToolsEntriesToDataInventory`, err);
        return [];
    }
};

/**
 * A simple function to map tools to data inventory storage locations.
 * @param {*} toolLabels
 * @param {*} dataInventoryStorageLocations
 * @returns
 */

export const mapToolLabelsToDataInventory = (toolLabels, dataInventoryStorageLocations) => {
    try {
        return toolLabels.map((label) => ({
            label: label,
            data: dataInventoryStorageLocations.find((c) => c.label === label),
        }));
    } catch (err) {
        return [];
    }
};

/**
 * This function will format the data from data matrice to the tools format required for our processor module.
 * @param {*} dataInventory - Your data inventory
 * @param {*} _processId
 */

export const convertMatriceEntriesToTools = (dataInventory, _currentProcessId) => {
    try {
        // Formatting the list of tools
        let tools = [];

        // Go through all the tools that are linked to this process.
        dataInventory.storageLocations.forEach((storageLocation) => {
            // Not linked.
            if (!storageLocation.processes.includes(_currentProcessId)) return;

            // Adding tools..
            tools.push({
                _storageLocationId: storageLocation._id,
                individuals: [],
            });
        });

        // Iterating through all the entries now to map individuals
        dataInventory.matrices.forEach((matrice) => {
            // Getting the tool index from our array
            const tIndex = tools.findIndex((c) => c._storageLocationId === matrice._storageLocationId);
            if (tIndex === -1) return; // Is not of interest.

            // Get all the entries linked to this tool.
            const entries = matrice.entries.filter((c) => c._processId === _currentProcessId);

            // Going through the entries
            entries.forEach((entry) => {
                // Is there an entry for this individual already?
                const indIndex = tools[tIndex].individuals.findIndex((d) => d._individualId === entry._individualId);

                // This individual has never been added to our array of storage location.individuals
                if (indIndex === -1) {
                    tools[tIndex].individuals.push({
                        _individualId: entry._individualId,
                        elements: [entry._elementId],
                    });
                } else {
                    tools[tIndex].individuals[indIndex].elements.push(entry._elementId);
                }
            });
        });

        return tools;
    } catch (err) {
        logError(`module.processes.utils.convertMatriceEntriesToTools`, err, { matrices });
        return [];
    }
};
