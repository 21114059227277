import React, { createContext, useContext, useEffect, useState } from 'react';
import lodash from 'lodash';

// Dependencies
import { makeEndpointRequest } from '../../../utils/endpoints';
import { logError, trackUserInteraction, useStateRef } from '../../../utils/helpers';

// Routing
import { mapRoutes, validateRoute } from './mapRoutes';

// Components
import Container from './components/container';
import Header from './components/header';
import API from './components/api';

import { convertToSlug, isTaskCompleted } from './routes/start/utils';

// Context
const Context = createContext({});
export const ComponentState = () => useContext(Context);

// Variables
let draftUpdateTimer = null;

const Component = (props) => {
    const [data, setData] = useState(null);
    const [draftData, setDraftData, draftDataRef] = useStateRef(null);
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [route, setRoute] = useState(validateRoute(props.match.params.route || 'start'));
    const [secondRoute, setSecondRoute] = useState(props.match.params.secondRoute || null);
    const [vitalRecommendations, setVitalRecommendations] = useState({
        elements: [],
        individuals: [],
        processes: [],
        sensitiveElements: [],
    });
    const [securityMeasures, setSecurityMeasures] = useState(null);
    const [responses, setResponses] = useState([]);
    const [personalInventory, setPersonalInventory] = useState([]);
    const [subProcessors, setSubProcessors] = useState(null);
    const [toolSuggestions, setToolSuggestions] = useState([]);
    const [sessionData, setSessionData] = useState(null);
    const [dataInventory, setDataInventory] = useState(null);

    // Variables
    const _dpaId = props.match.params.id;

    const loadData = async () => {
        try {
            // Mark as loading
            setLoading(true);

            // Get the data
            const res = await makeEndpointRequest('GetDPA', { _id: _dpaId });
            const responses = await makeEndpointRequest('GetSecurityMeasuresResponses');
            const gelSession = await makeEndpointRequest(`GetGELSession`);

            setData(res); // The whole document data in case we ever need to check Published data across.
            setDraftData(res.data.draft); // This is the data we will be manipulating
            setResponses(responses);
            setPersonalInventory(gelSession ? gelSession.storageLocations.filter((e) => e.archived !== true) : []);
            setSessionData(gelSession);

            // Stopped loading..
            setLoading(false);

            // Track analytical
            trackUserInteraction(`Data processing agreement`, { _id: _dpaId });
        } catch (err) {
            await logError(`LOAD_DPA`, err, { _dpaId });
            setLoading(false);
            setData(undefined);
        }
    };

    const loadDataInventory = async () => {
        try {
            // Get data inventory
            const res = await makeEndpointRequest(`GetDataInventory`, {
                onlyPublishedStorageLocations: false,
                bundleStorageLocations: false,
            });
            setDataInventory(res);
        } catch (err) {
            await logError(`dpa.loadDataInventory`, err);
            setDataInventory(null);
            trackUserInteraction(`Having Difficulties`, { reason: 'Failed to load data inventory' });
        }
    };

    useEffect(() => {
        // Load the module data..
        loadData();

        // Load data inventory
        loadDataInventory();

        // Load the questions
        loadSecurityMeasures();

        // Loading vital recommendations..
        loadVitalRecommendations();

        // Load sub-processors
        loadSubProcessors();

        // Load tool suggestions
        loadToolSuggestions();

        return () => {
            if (draftUpdateTimer !== null) {
                onDraftDataUpdated();
                clearTimeout(draftUpdateTimer);
            }
        };
        // eslint-disable-next-line
    }, []);

    const loadSubProcessors = async () => {
        try {
            // Get the list of sub-processors
            const res2 = await makeEndpointRequest('GetSubProcessors');
            setSubProcessors(res2);
        } catch (err) {
            await logError(`loadSubProcessors`, err);
        }
    };

    const onRouteChanged = () => {
        // We need to add the route to the link slug.
        let newLink = `/data-processing-agreements/${_dpaId}/${route}${secondRoute ? `/${secondRoute}` : ``}`;

        // eslint-disable-next-line
        window.history.pushState(null, null, newLink);
    };

    useEffect(() => {
        onRouteChanged();
        if (draftData !== null) {
            onDraftDataUpdated();
        }

        // eslint-disable-next-line
    }, [route, secondRoute]);

    useEffect(() => {
        // Safety check..
        if (
            secondRoute &&
            securityMeasures !== null &&
            securityMeasures.find((x) => convertToSlug(x.details.section) === secondRoute) === undefined
        ) {
            setSecondRoute(null);
            setRoute('start');
        }

        // Another safety check...
        if (route === 'security-measures' && !secondRoute) {
            setRoute('start');
        }

        // eslint-disable-next-line
    }, [secondRoute, securityMeasures, route]);

    // When they change the route we must re-render..
    useEffect(() => {
        setRoute(validateRoute(props.match.params.route || 'start'));

        // Set the second route
        if (props.match.params.secondRoute) {
            setSecondRoute(props.match.params.secondRoute);
        }

        // eslint-disable-next-line
    }, [props.match]);

    const updateDraftData = (path, value) => {
        setDraftData((currentState) => {
            let newState = { ...currentState };
            lodash.set(newState, path, value);
            return newState;
        });

        if (draftUpdateTimer !== null) {
            clearTimeout(draftUpdateTimer);
        }

        draftUpdateTimer = setTimeout(() => {
            onDraftDataUpdated();
            draftUpdateTimer = null;
        }, 5000);
    };

    const onDraftDataUpdated = async () => {
        try {
            await makeEndpointRequest('UpdateDPADraftData', { _id: _dpaId, payload: { ...draftDataRef.current } });
        } catch (err) {
            await logError('UPDATE_DPA_DRAFT_DATA', err, {
                draftData: draftData,
                _id: _dpaId,
            });
        }
    };

    const loadVitalRecommendations = async () => {
        try {
            const data = await makeEndpointRequest('GetVitalRecommendations');
            setVitalRecommendations(data);
        } catch (err) {
            await logError(`LOAD_VITAL_RECOMMENDATIONS`, err);
        }
    };

    const loadSecurityMeasures = async () => {
        try {
            // Get the dynamic questions
            const res = await makeEndpointRequest('GetSecurityMeasures', { filteredByCompanySize: true });
            setSecurityMeasures(res);
        } catch (err) {
            await logError(`LOAD_DPA_SECURITY_MEASURES`, err);
        }
    };

    const loadToolSuggestions = async () => {
        try {
            const res = await makeEndpointRequest(`GetCompanySuggestedTools`);
            setToolSuggestions(res);
        } catch (err) {
            await logError(`LOAD_TOOL_SUGGESTIONS`, err);
        }
    };

    const submitData = async () => {
        try {
            setSubmitted(true);
            await trackUserInteraction(`Selected "Submit DPA"`);
            await makeEndpointRequest(`SubmitDPA`, {
                _id: _dpaId,
                payload: draftDataRef.current,
            });
            setSubmitted(false);
            setRoute('completed');
        } catch (err) {
            await logError(`SUBMIT_DPA`, err);
            setSubmitted(false);
            trackUserInteraction(`Having Difficulties`, { reason: `failed to submit dpa` });
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    const isModuleTaskCompleted = (route, securityMeasureId = null) => {
        const result = isTaskCompleted(route, draftData, securityMeasureId, securityMeasures);
        return result;
    };

    const ContextProps = {
        data,
        setData,
        draftData,
        setDraftData,
        updateDraftData,
        loading,
        setLoading,
        route,
        setRoute,
        vitalRecommendations,
        securityMeasures,
        setSecurityMeasures,
        measuresRendered:
            secondRoute && securityMeasures !== null
                ? securityMeasures.filter((s) => convertToSlug(s.details.section) === secondRoute)
                : null,
        secondRoute,
        setSecondRoute,
        submitted,
        submitData,
        isModuleTaskCompleted,
        responses,
        setResponses,
        personalInventory,
        setPersonalInventory,
        // Sub-processors
        subProcessors,
        setSubProcessors,
        // tool suggestions
        toolSuggestions,
        setToolSuggestions,
        // Session
        sessionData,
        setSessionData,
        // Data Inventory
        dataInventory,
    };

    const RouteComponent = mapRoutes[route];

    return (
        <Context.Provider value={ContextProps}>
            <Container>
                <Header />
                <RouteComponent />
                <API />
            </Container>
        </Context.Provider>
    );
};

export default Component;
