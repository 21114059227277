import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import Container from '../../layout/container';
import { getAuth0Client, logError, trackUserInteraction } from '../../../utils/helpers';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { AuthStore } from '../../../utils/context/authStore';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const Auth = () => {
    const { getAuthenticationState, setAccount } = AuthStore();
    const router = useHistory();

    const [checkedQuery, setCheckedQuery] = useState(false);
    const location = window.location.href;

    const checkUrlForAuthRedirectQuery = async () => {
        try {
            const auth0 = await getAuth0Client();
            await auth0.handleRedirectCallback();

            const tokenClaims = await auth0.getIdTokenClaims();

            // Call login API to add cookie
            await makeEndpointRequest(
                'Login',
                {
                    referrer: localStorage.getItem('referrer'),
                },
                {
                    headers: {
                        Authorization: `Bearer ${tokenClaims.__raw}`,
                    },
                },
            );

            // redirect to the page they were trying to access
            const account = await getAuthenticationState();
            const oldAppRedirectTo = localStorage.getItem('@redirectTo');
            localStorage.removeItem('@redirectTo');

            const newAppRedirectTo = Cookies.get('redirectTo');
            const prod = process.env.NODE_ENV === 'production';
            const cookieConfig = prod ? { secure: true, domain: '.privasee.io' } : {};
            Cookies.remove('redirectTo', cookieConfig);

            // once logged in we check if the user has completed the gdpr essentials
            // depending on hasCompletedGDPREssentials we send them to homepage or GEL flow
            if (account.hasCompletedGDPREssentials) {
                if (!newAppRedirectTo) router.push(oldAppRedirectTo ? oldAppRedirectTo : '/');

                if (newAppRedirectTo) {
                    window.location.href = newAppRedirectTo;
                }
            } else {
                router.push('/stages/gdpr-essentials');
                await trackUserInteraction(`Started GDPR Essentials`);
            }
        } catch (err) {
            // @Bugfix: The user created an account with name and password then tried to log in with GMAIL.
            if (err.message && err.message.includes('Attempted to log in through different login method.')) {
                onAttemptToLoginWithDifferentMethod();
                return false;
            }

            await logError(`CHECK_AUTH0_REDIRECT_QUERY_URL`, err);

            // @Bugfix: Invalid state from Auth0 due to bad url redirection
            if (err.message && err.message === 'Invalid state') {
                logIn();
            }
        }
    };

    const checkQueryFunc = async () => {
        if (location.includes('code=')) {
            await checkUrlForAuthRedirectQuery();
        } else {
            await logIn();
        }
    };

    useEffect(() => {
        if (checkedQuery === false) {
            checkQueryFunc();
            setCheckedQuery(true);
        }
        // eslint-disable-next-line
    }, [checkedQuery, setAccount, router]);

    const onAttemptToLoginWithDifferentMethod = async () => {
        const auth0 = await getAuth0Client();

        window.showAlert(
            'Having Difficulties',
            <React.Fragment>
                <p style={{ margin: 8 }}> Please try to log in through the same method you signed up</p>
                <p style={{ margin: 8 }}> E.g. If you signed up with Google you must log in with Google, and vice versa</p>
            </React.Fragment>,
            `error`,
            [
                {
                    text: 'Log in with different method',
                    onClick: async () => {
                        await auth0.loginWithRedirect({ max_age: 0 });
                    },
                },
            ],
        );
    };

    const isLoggedInAPI = async () => {
        let apiIsLoggedIn = false;

        // We need to check our API too cause sometimes our API will say they're logged out but Auth0 will say otherwise
        try {
            await makeEndpointRequest('GetCurrentUser');
            apiIsLoggedIn = true;
        } catch (err) {
            apiIsLoggedIn = false;
        }

        return apiIsLoggedIn;
    };

    const logIn = async () => {
        const auth0 = await getAuth0Client();
        let apiIsLoggedIn = await isLoggedInAPI();

        // They should not access this page if they're logged in on our API.
        if (apiIsLoggedIn) {
            router.replace('/');
            return false;
        }

        await auth0.loginWithRedirect({
            max_age: 0, // @Bugfix: APP-2923
        });
    };

    return (
        <Container guestLayout={true} title="Authentication" classNames="page-authentication">
            <div className="page-container">
                <CircularProgress />
            </div>
        </Container>
    );
};

export default Auth;
