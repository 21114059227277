import React, { useState } from 'react';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { logError } from '../../../utils/helpers';
import Container from '../../layout/container';

// Components
import Forms from './components/forms';

const SignUp = () => {
    const [submitted, setSubmitted] = useState(false);
    const [successful, setSuccessful] = useState(false);

    const [data, setData] = useState({
        email: '',
        promotionalCode: '',
    });

    const onSubmit = async () => {
        try {
            setSubmitted(true);
            await makeEndpointRequest(`RedeemCouponSignUp`, {
                email: data.email,
                promotionalCode: data.promotionalCode,
            });
            setSubmitted(false);
            setSuccessful(true);
        } catch (err) {
            if (err.message.includes(`COUPON_INVALID`)) {
                window.showAlert(
                    'Invalid code',
                    `The coupon code is not valid Please make sure the code entered is right. Please get in touch with us through Live Chat if this is not right.`,
                    `error`,
                );
                setSubmitted(false);
                return false;
            }

            if (err.message.includes(`EMAIL_ALREADY_REGISTERED`)) {
                window.showAlert(
                    'Email already used',
                    `${data.email} is already used on this platform. Please use a different email address.`,
                    `error`,
                );
                setSubmitted(false);
                return false;
            }

            window.showAlert('Having Difficulties', `We're facing technical difficulties. Please try again!`, `error`);
            await logError(`REDEEM_COUPON_SIGN_UP`, err, {
                email: data.email,
                promotionalCode: data.promotionalCode,
            });
            setSubmitted(false);
        }
    };

    const passedProps = {
        data,
        setData,
        onSubmit,
        submitted,
        setSubmitted,
    };

    return (
        <Container guestLayout={true} title="Redeem coupon" classNames="page-signup">
            <div className="page-container">
                <div className="page-content">
                    <div className="page-header">
                        <div className="logo" />
                        <div className="text-side">
                            <div className="heading">Redeem coupon code</div>
                            <div className="subheading">Get enrolled into our platform easily.</div>
                        </div>
                    </div>
                    {successful ? (
                        <React.Fragment>
                            <div className="page-forms">
                                <p>
                                    Congrats, your coupon has been redeemed. An email has been sent to {data.email} with instructions to
                                    complete your sign-up.
                                </p>
                                <p>You can safely close this tab.</p>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="page-forms" style={{ marginBottom: 16 }}>
                                <p>
                                    Please share your Email and Coupon code to get enrolled on our platform. After your submission you will
                                    receive an email to finish your sign up and then you will be ready to go!
                                </p>
                            </div>
                            <Forms {...passedProps} />
                        </React.Fragment>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default SignUp;
