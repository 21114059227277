import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthStore } from '../../../utils/context/authStore';
import { makeLocalEndpointRequest } from '../../../utils/endpoints';
import { logError, trackUserInteraction } from '../../../utils/helpers';
import Container from '../../layout/container';

// Components
import Loading from './views/loading';
import Dashboard from './views/dashboard';
import Entry from './views/entry';

// Context
const Context = createContext({});
export const AuditsContext = () => useContext(Context);

// Mapped

const mappedViews = {
    Loading,
    Dashboard,
    Entry,
};

const Component = (props) => {
    const [view, setView] = useState('Loading');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [auditId, setAuditId] = useState(null);

    const { account, globalFlags } = AuthStore();

    const loadingData = async () => {
        try {
            if (globalFlags.audits !== null && globalFlags.audits === false) {
                props.history.push('/');
                return false;
            }
            await trackUserInteraction(`Audits`);
            const data = await makeLocalEndpointRequest(`/api/audits`, {}, `GET`);
            setData(data);
            setError(false);
            setLoading(false);
            setView('Dashboard');
            if (props.match.params && props.match.params.id) {
                let idFound = parseInt(props.match.params.id);
                const match = data.auditItems.find((x) => x.id === parseInt(idFound));
                if (!match) return false;
                setAuditId(idFound);
                setView('Entry');
                await trackUserInteraction(`Selected Audit`, { entryId: idFound });
            }
        } catch (err) {
            await trackUserInteraction(`Having Difficulties`, { reason: `Failed to load audit data` });
            await logError(`LOAD_AUDIT_DATA`, err, { companyId: account._companyId });
            setError(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadingData();
        // eslint-disable-next-line
    }, []);

    const RenderedView = mappedViews[loading || data === null ? `Loading` : view];

    const contextVariables = {
        view,
        setView,
        data,
        setData,
        submitted,
        setSubmitted,
        error,
        loading,
        setLoading,
        setError,
        auditId,
        setAuditId,
    };

    return (
        <Container title="Audits" classNames="page-audits">
            <Context.Provider value={contextVariables}>
                <RenderedView />
            </Context.Provider>
        </Container>
    );
};

export default Component;
