import React from 'react';
import { TextField, Button } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Switch } from '@mui/material';
import Tooltip from '../../../../../../../../components/tooltip';
import { useState } from 'react';
import { logError, trackUserInteraction } from '../../../../../../../../../utils/helpers';

import { AuthStore } from '../../../../../../../../../utils/context/authStore';
import mappedLanguages from '../../../../../../../../../dummy_data/availableLanguages.json';

const Component = ({ languagesAvailable, setEditData, editData, saveEdit, cancelEdit, languages }) => {
    const [showMore, setShowMore] = useState(false);
    const [loadedDefaultLanguages, setLoadedDefaultLanguages] = useState(false);
    const { globalFlags } = AuthStore();

    const switchShowMore = async () => {
        await trackUserInteraction(`Selected "Show More preferences" for Cookie`, { bool: !showMore });
        setShowMore(!showMore);
        if (loadedDefaultLanguages === false) {
            loadDefaultTranslations();
        }
    };

    const getTranslationValueField = (langKey) => {
        // If the user already filled in his own version of that language
        if (
            editData.translations &&
            editData.translations.cookiePurpose[langKey] &&
            editData.translations.cookiePurpose[langKey].length > 0
        )
            return editData.translations.cookiePurpose[langKey];
        return '';
    };

    const loadDefaultTranslations = async () => {
        try {
            const translations = editData.translations.cookiePurpose;

            Object.keys(translations).forEach(async (lang) => {
                // If a translation already exists...
                const translationExists =
                    editData.translations.cookiePurpose[lang] && editData.translations.cookiePurpose[lang].length > 0 ? true : false;
                if (translationExists) return;

                if (!languages['privacyPortal'][lang]) return; // If there's no translation..

                const translations = languages['privacyPortal'][lang]['thirdPartyCookies'];
                const translationKey = `Cookie Purpose:${editData.purpose}`;
                const translation = translations[translationKey];

                // It means that we found a valid variant in this other language already defined by us.
                if (translation) {
                    updatePurposeTranslation(lang, translation);
                }
            });

            setLoadedDefaultLanguages(true);
        } catch (err) {
            await logError('LOAD_DEFAULT_LANGS_COOKIE_PURPOSE', err);
        }
    };

    const updatePurposeTranslation = (key, value) => {
        let translations = editData.translations || {
            cookiePurpose: {},
        };
        translations.cookiePurpose[key] = value;
        setEditData({ ...editData, translations });
    };

    const switchDefaultConsentStatus = async () => {
        await trackUserInteraction(`Switched on or off the "Cookie Default Consent Status`, {
            newValue: !editData.cookieDefaultConsentStatus,
        });
        setEditData({ ...editData, cookieDefaultConsentStatus: !editData.cookieDefaultConsentStatus });
    };

    return (
        <React.Fragment>
            <Dialog open={true}>
                <DialogTitle>{editData.name}</DialogTitle>
                <DialogContent
                    dividers={true}
                    className="modal-edit-cookie page-privacy-portal-settings"
                    style={{ width: 550, maxWidth: '80vw' }}
                >
                    <div className="form-group">
                        <div className="label">
                            External Link{' '}
                            <Tooltip
                                trackInteraction={true}
                                interactionId={`privacy-portal-settings:cookie-external-link`}
                                content={`Link to ${editData.name}'s privacy policy`}
                            >
                                <i className="color-primary tooltip-icon fa-solid fa-circle-question"></i>
                            </Tooltip>
                        </div>
                        <TextField
                            placeholder="External Link"
                            value={editData.external_link || ''}
                            fullWidth={true}
                            variant="standard"
                            onChange={(e) => setEditData({ ...editData, external_link: e.target.value })}
                        />
                    </div>
                    <div className="form-group">
                        <div className="label">
                            Purpose{' '}
                            <Tooltip
                                trackInteraction={true}
                                interactionId={`privacy-portal-settings:what-is-cookie-used-for`}
                                content={`What does ${editData.name}'s use this cookie for? Try to explain why this cookie matters`}
                            >
                                <i className="color-primary tooltip-icon fa-solid fa-circle-question"></i>
                            </Tooltip>
                        </div>
                        <TextField
                            placeholder="Purpose"
                            value={editData.purpose || ''}
                            data-cy="purpose"
                            fullWidth={true}
                            variant="standard"
                            onChange={(e) => setEditData({ ...editData, purpose: e.target.value })}
                        />
                    </div>

                    <Button
                        data-cy="show-more"
                        variant="outlined"
                        className="show-more"
                        color="primary"
                        style={{ marginTop: 16, marginBottom: showMore ? 16 : 0 }}
                        onClick={switchShowMore}
                    >
                        {showMore ? 'Hide these options ' : 'Show more options'}
                    </Button>
                    {showMore && (
                        <React.Fragment>
                            <div className="sub-header">Preferences</div>
                            <div className="sub-container">
                                <div className="form-group" style={{ marginBottom: 16 }}>
                                    <div className="label">Default consent status</div>
                                    <div className="description">
                                        When on, consent will be on by default, this means that cookies for this tool will be automatically
                                        placed. The user can still edit the configuration to revoke consent.
                                    </div>
                                    <div className="inline-switch">
                                        <Switch
                                            className="control"
                                            checked={editData.cookieDefaultConsentStatus}
                                            onClick={switchDefaultConsentStatus}
                                        />
                                        {editData.cookieDefaultConsentStatus && (
                                            <div className="sub-label red">This setting is discouraged and non-compliant.</div>
                                        )}
                                    </div>
                                </div>
                                {globalFlags.additionalCookieInformation && (
                                    <React.Fragment>
                                        <div className="form-group" style={{ marginBottom: 16 }}>
                                            <div className="label">Cookie Name</div>
                                            <div className="description">
                                                Enter the specific cookie name utilized by the tool (e.g., GA_) for inclusion in your
                                                privacy policy.
                                            </div>
                                            <TextField
                                                placeholder="Cookie Name"
                                                value={editData.cookieNames || ''}
                                                fullWidth={true}
                                                variant="standard"
                                                onChange={(e) => setEditData({ ...editData, cookieNames: e.target.value })}
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginBottom: 16 }}>
                                            <div className="label">Cookie Duration</div>
                                            <div className="description">
                                                Specify the duration for which the cookie will remain active, indicating its expiration
                                                date.
                                            </div>
                                            <TextField
                                                placeholder="Cookie Duration"
                                                value={editData.cookieExpiry || ''}
                                                fullWidth={true}
                                                variant="standard"
                                                onChange={(e) => setEditData({ ...editData, cookieExpiry: e.target.value })}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                            {languagesAvailable.length > 1 && (
                                <React.Fragment>
                                    <div className="sub-header">Translations</div>
                                    <div className="sub-container">
                                        {mappedLanguages
                                            .filter((x) => x.code !== 'EN' && languagesAvailable.includes(x.code))
                                            .map((entry, ix) => (
                                                <div className="form-group" key={ix}>
                                                    <div className="label">
                                                        Purpose ({entry.label}){' '}
                                                        <Tooltip
                                                            trackInteraction={true}
                                                            interactionId={`privacy-portal-settings:what-is-this-cookie-purpose-translation`}
                                                            content={`This is how this purpose will translate on your ${entry.label} version of the privacy portal`}
                                                        >
                                                            <i className="color-primary tooltip-icon fa-solid fa-circle-question"></i>
                                                        </Tooltip>
                                                    </div>
                                                    <TextField
                                                        placeholder={'Purpose'}
                                                        value={getTranslationValueField(entry.code)}
                                                        fullWidth={true}
                                                        data-cy={`purpose-${entry.code}`}
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            updatePurposeTranslation(entry.code, e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={cancelEdit}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        data-cy="save-dialog"
                        color="primary"
                        disabled={editData.external_link.length < 1 || editData.purpose.length < 1}
                        onClick={saveEdit}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
