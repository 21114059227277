import React from 'react';

// Components
import { TextField } from '@mui/material';
import Tooltip from '../../../../../../../components/tooltip';

// Context
import { PanelContext } from '../../..';
import { ViewContext } from '..';
import { getValidationPropFields } from '../../../../../../../../utils/helpers';

const Component = () => {
    const { data, setData } = PanelContext();
    const { validationResults } = ViewContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Executed Data Processing Agreement (DPA)</div>
                    <Tooltip content="Add a link to the signed version of the Data Processing Agreement (DPA).">
                        <i style={{ marginLeft: 6 }} className="color-primary tooltip-icon fa-solid fa-circle-question"></i>
                    </Tooltip>
                </div>

                <div className="component-content">
                    <TextField
                        placeholder="Link"
                        variant="standard"
                        value={data.executedDpaLink}
                        data-cy="field-executedDpaLink"
                        onChange={(ev) =>
                            setData({
                                ...data,
                                executedDpaLink: ev.target.value,
                            })
                        }
                        fullWidth={true}
                        {...getValidationPropFields(validationResults, true, `executedDpaLink`)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
