import React from 'react';
import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import { customCountries } from '../../../../../../gdprEssentials/steps/reviewData/components/edit/pages/general';

// Context
import { ViewContext } from '..';
import { PanelContext } from '../../..';

const Component = () => {
    const { validationResults } = ViewContext();
    const { data, setData } = PanelContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <span className="text">Data Residency</span>
                    {data.dataResidency.length < 1 && <div className="badge badge-data-warning blue" />}
                </div>
                <div className="component-content">
                    <Autocomplete
                        value={data.dataResidency}
                        fullWidth={true}
                        data-cy="field-dataResidency"
                        disableClearable={true}
                        options={customCountries.map((c) => c.name)}
                        groupBy={(option) => {
                            const o = customCountries.find((c) => c.name === option);
                            return o._muiCategory || 'Rest of the world';
                        }}
                        renderInput={(params) => <TextField fullWidth={true} variant="standard" {...params} placeholder="No selection" />}
                        freeSolo={false}
                        multiple={false}
                        onChange={(_, value) => setData({ ...data, dataResidency: value })}
                        isOptionEqualToValue={(option, value) => value === option}
                    />
                    {validationResults[`dataResidency`] !== undefined && (
                        <FormHelperText error={true}>{validationResults['dataResidency']}</FormHelperText>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
