import * as Yup from 'yup';

export const privacyPolicySchema = Yup.object({
    icoRegNumber: Yup.string()
        .nullable(true)
        .when([`operateInUK`, `branchInUK`], {
            is: true,
            then: schema =>
                schema
                    .trim()
                    .label(`This`)
                    .required(),
        }),
    branchEUAddress: Yup.string()
        .nullable(true)
        .when([`operateInEU`, `branchInEU`], {
            is: (val1, val2) => val1 && val2,
            then: schema =>
                schema
                    .trim()
                    .required()
                    .label('This'),
        }),
    branchUKAddress: Yup.string()
        .nullable(true)
        .when([`operateInUK`, `branchInUK`], {
            is: (val1, val2) => {
                return val1 && val2;
            },
            then: schema =>
                schema
                    .trim()
                    .nullable(true)
                    .label(`This`)
                    .required(),
        }),
    euRepInfo: Yup.object()
        .nullable(true)
        .when([`operateInEU`, `branchInEU`], {
            is: (operateInEu, branchInEu) => operateInEu && branchInEu !== null && !branchInEu,
            then: schema =>
                schema.shape({
                    euRepName: Yup.string()
                        .nullable(true)
                        .trim()
                        .label(`This`)
                        .required(),
                    euRepAddress: Yup.string()
                        .nullable(true)
                        .trim()
                        .nullable(true)
                        .label(`This`)
                        .required(),
                    euRepContact: Yup.string()
                        .nullable(true)
                        .trim()
                        .label(`This`)
                        .required(),
                }),
        }),
    ukRepInfo: Yup.object()
        .nullable(true)
        .when([`operateInUK`, `branchInUK`], {
            is: (operateInUK, branchInUK) => {
                return operateInUK && branchInUK !== null && !branchInUK;
            },
            then: schema =>
                schema.shape({
                    ukRepName: Yup.string()
                        .trim()
                        .nullable(true)
                        .label(`This`)
                        .required(),
                    ukRepAddress: Yup.string()
                        .trim()
                        .nullable(true)
                        .label(`This`)
                        .required(),
                    ukRepContact: Yup.string()
                        .trim()
                        .nullable(true)
                        .label(`This`)
                        .required(),
                }),
        }),
});
