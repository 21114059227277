import React from 'react';
import { Button } from '@mui/material';
import { ComponentState } from '../..';

// Components
import Implementation from './components/implementationMethod';
import Theme from './components/theme';
import Color from './components/color';
import Logo from './components/logo';

const Component = () => {
    const { saveChanges, saveDisabled } = ComponentState();

    return (
        <React.Fragment>
            <Implementation />
            <Theme />
            <Color />
            <Logo />
            <div className="page-footer">
                <Button variant="contained" color="primary" onClick={saveChanges} disabled={saveDisabled}>
                    Save changes
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Component;
