import React, { createContext, useContext, useEffect, useState } from 'react';
import lodash from 'lodash';

// Other things
import { checkUserFlag, logError, trackUserInteraction } from '../../../utils/helpers';
import { AuthStore } from '../../../utils/context/authStore';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { decideBestPage, updateUrl } from './utils';
import defaultCMPColors from './pages/settings/components/colors/defaultColors';

// Components
import PageContainer from './components/pageContainer';

// Pages
import Settings from './pages/settings';
import AddToWebsite from './pages/addToWebsite';
import CookiePolicy from '../privacyPortalSettings/pages/settings/cookies';
import { formatCookieObjects } from '../privacyPortalSettings/utils';

// Mappings
export const PageMappings = {
    settings: (props) => <Settings {...props} />,
    cookies: (props) => <CookiePolicy source="cb" {...props} />,
    'add-to-website': (props) => <AddToWebsite {...props} />,
};

// Context
const Context = createContext({});
export const ComponentState = () => useContext(Context);

const Component = (props) => {
    const [tab, setTab] = useState(props.match.params.tab ? props.match.params.tab : 'settings');
    const [defaultData, setDefaultData] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { account } = AuthStore();
    const [error, setError] = useState(false);
    const [validationKeys, setValidationKeys] = useState({});
    const [changesDetected, setChangesDetected] = useState(false);
    const [specificRecommendations, setSpecificRecommendations] = useState({});
    const [ucSettings, setUcSettings] = useState({
        buttonDisplayLocation: 'bl',
        languagesAvailable: ['en'],
        editableLanguages: ['en'],
        privacyButtonIsVisible: true,
        googleConsentMode: true,
        customization: {
            color: defaultCMPColors,
        },
        ccpa: {
            isActive: false,
        },
        links: {
            privacyPolicy: {
                url: ``,
                label: '',
            },
            cookiePolicy: {
                url: ``,
                label: '',
            },
        },
    });

    // Flags
    const [flags, setFlags] = useState({
        californiaPolicy: false,
        googleConsentMode: false,
    });

    const [updateInvoiceDetails, setUpdateInvoiceDetails] = useState(
        window.location.href.includes('?update-invoice-details=true') ? true : false,
    );

    const [serviceVerifications, setServiceVerifications] = useState({
        cookieBanner: null,
        inventoryAssessment: null,
    });

    const loadFlags = async () => {
        try {
            const californiaPolicy = await checkUserFlag(`californiaPolicy`, false);
            const googleConsentMode = await checkUserFlag(`googleConsentMode`, false);
            setFlags({
                ...flags,
                californiaPolicy,
                googleConsentMode,
            });
        } catch (err) {
            await logError(`FAILED_GET_COMPANY_CB_FLAGS`, err);
        }
    };

    const updateData = (path, val) => {
        setData((currentState) => {
            const objMod = Object.assign({}, currentState);
            lodash.set(objMod, path, val);
            return objMod;
        });
    };

    const updateUcSettings = (path, val) => {
        setUcSettings((currentState) => {
            const objMod = Object.assign({}, currentState);
            lodash.set(objMod, path, val);
            return objMod;
        });
    };

    const refreshUcSettings = async (p = null) => {
        try {
            const res = await makeEndpointRequest(`GetUsercentricsSettings`, {
                settingsId: p.id,
                email: p.email,
            });
            setUcSettings(res);
        } catch (err) {
            await logError(`REFRESH_UC_SETTINGS`, err);
        }
    };

    const loadData = async () => {
        try {
            setLoading(true);
            let response = await makeEndpointRequest('getPrivacyPortal', {
                companyId: account._companyId,
            });
            let res = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: response.thirdParties.map((x) => x.label),
                processes: [],
            });
            await loadFlags();
            setSpecificRecommendations(res);
            setData({ ...response });
            setDefaultData(JSON.parse(JSON.stringify(response)));
            if (response.usercentricsSettingsId) {
                res = await makeEndpointRequest(`GetUsercentricsSettings`, {
                    settingsId: response.usercentricsSettingsId,
                    email: response.usercentricsEmail,
                });
                setUcSettings(res);
            }
            // Check if it's been implemented right
            const { cookieBanner, inventoryAssessment } = await makeEndpointRequest('GetImplementationsChecks');
            setServiceVerifications({
                cookieBanner,
                inventoryAssessment,
            });

            await trackUserInteraction(`Privacy Portal - Cookie Banner`);
            setLoading(false);
        } catch (err) {
            await logError(`LOAD_PRIVACY_SETTINGS`, err);
            await trackUserInteraction(`Having Difficulties`, { reason: `Failed to load cookie banner settings` });
            setError(true);
            setLoading(false);
        }
    };

    const saveChanges = async () => {
        try {
            setLoading(true);
            const pageRendered = decideBestPage(tab);
            const mappingIndex = Object.keys(PageMappings).findIndex((k) => k === pageRendered);
            if (mappingIndex === 0) {
                // Customization page
                await makeEndpointRequest(`SaveCompanyInfo`, {
                    update: {
                        privacyPortalSettings: data.privacyPortalSettings,
                        smartCookieBlocker: data.smartCookieBlocker,
                        cookieBannerSettings: data.cookieBannerSettings,
                    },
                });
                if (data.cookieBannerSettings.enabled && data.usercentricsSettingsId) {
                    await makeEndpointRequest('UpdateUsercentricsSettings', {
                        settingsId: data.usercentricsSettingsId,
                        email: data.usercentricsEmail,
                        payload: {
                            buttonDisplayLocation: ucSettings.buttonDisplayLocation,
                            languagesAvailable: ucSettings.languagesAvailable,
                            editableLanguages: ucSettings.editableLanguages,
                            customization: ucSettings.customization,
                            privacyButtonIsVisible: ucSettings.privacyButtonIsVisible,
                            ccpa: ucSettings.ccpa,
                            links: ucSettings.links,
                            googleConsentMode: ucSettings.googleConsentMode,
                        },
                    });
                }
            } else if (mappingIndex === 1) {
                // Settings => Cookie Policy
                await makeEndpointRequest('UpdateCookieType', {
                    update: {
                        functionality: formatCookieObjects(data.thirdPartyCookies.entries.filter((c) => c.type === 'Functional')),
                        necessary: formatCookieObjects(data.thirdPartyCookies.entries.filter((c) => c.type === 'Necessary')),
                        analytical: formatCookieObjects(data.thirdPartyCookies.entries.filter((c) => c.type === 'Analytical')),
                        targeting: formatCookieObjects(data.thirdPartyCookies.entries.filter((c) => c.type === 'Targeting')),
                    },
                });
                //API to Edit Cookie data
                await makeEndpointRequest('SaveCompanyCookieInformation', {
                    update: {
                        webBeaconsUse: data.thirdPartyCookies.useWebBeacons,
                    },
                });
                if (data.usercentricsSettingsId) {
                    // Not all users have it enabled by default.
                    await makeEndpointRequest(`SyncUCInventory`);
                }
            }

            setDefaultData(JSON.parse(JSON.stringify(data)));
            setLoading(false);

            let successMsg = `All the changes you've made have been saved.`;

            if (data.cookieBannerSettings.enabled && tab !== Object.keys(PageMappings)[1]) {
                successMsg += ` Please be aware that any changes related to the cookie banner will take some time to be applied.`;
            }

            window.showAlert('Settings Saved', successMsg, `success`);
            setChangesDetected(false);
        } catch (err) {
            await logError(`SAVE_COOKIE_BANNER_SETTINGS`, err, { tab });
            await trackUserInteraction(`Having Difficulties`, {
                reason: `Failed to save the privacy settings`,
            });
            window.showAlert('Having Difficulties', `The changes you've made couldn't be saved. Please try again!`, `error`);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (defaultData !== null) {
            // avoid issues on boot
            setData(JSON.parse(JSON.stringify(defaultData)));
            setValidationKeys({});
        }
        updateUrl(tab);
        // eslint-disable-next-line
    }, [tab]);

    const ComponentRendered = PageMappings[decideBestPage(tab)];

    const ContextProps = {
        // Tabs
        tab,
        setTab,
        // Loadings and errors..
        loading,
        setLoading,
        error,
        setError,
        // Data..
        data,
        setData,
        defaultData,
        setDefaultData,
        updateData,
        // Validation system
        validationKeys,
        setValidationKeys,
        // Changes..
        changesDetected,
        setChangesDetected,
        // Saving
        saveChanges,
        saveDisabled: loading || Object.keys(validationKeys).length > 0,
        // Recommendations
        specificRecommendations,
        ucSettings,
        setUcSettings,
        updateUcSettings,
        refreshUcSettings,
        // Invoicing
        updateInvoiceDetails,
        setUpdateInvoiceDetails,
        // Implementation checks
        serviceVerifications,
        setServiceVerifications,
        // Flags
        flags,
    };

    return (
        <Context.Provider value={ContextProps}>
            <PageContainer>
                <ComponentRendered />
            </PageContainer>
        </Context.Provider>
    );
};

export default Component;
