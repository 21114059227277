import React from 'react';

// Components
import { TextField } from '@mui/material';

// Context
import { ViewContext } from '..';
import { getValidationPropFields } from '../../../../../../../utils/helpers';
import { PanelContext } from '../../..';

const Component = () => {
    const { validationResults } = ViewContext();
    const { data, setData } = PanelContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Description</div>
                </div>
                {/* <div className="component-description">
                    <div className="text">Clearly explain the purpose of the processing of personal data (Optional)</div>
                </div> */}
                <div className="component-content">
                    <TextField
                        placeholder="Explain here..."
                        variant="outlined"
                        multiline={true}
                        rows={5}
                        value={data.description}
                        onChange={(ev) => setData({ ...data, description: ev.target.value })}
                        fullWidth={true}
                        data-cy={`input-description`}
                        {...getValidationPropFields(validationResults, true, `description`)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
