import React from 'react';
import ReactDOM from 'react-dom';

// App components

import Theme from './utils/theme';
import AuthStore from './utils/context/authStore';
import Routes from './utils/router';
import ErrorBoundary from './views/layout/errorBoundary';

import './theme/style.scss';

import { PostHogProvider } from 'posthog-js/react';

const options = {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
};

ReactDOM.render(
    <React.Fragment>
        <ErrorBoundary>
            <PostHogProvider apiKey={process.env.REACT_APP_POSTHOG_KEY} options={options}>
                <Theme>
                    <AuthStore>
                        <Routes />
                    </AuthStore>
                </Theme>
            </PostHogProvider>
        </ErrorBoundary>
    </React.Fragment>,
    document.getElementById('root'),
);
