import React from 'react';
import { ComponentState } from '../../..';
import { trackUserInteraction } from '../../../../../../utils/helpers';

// Components
import { TextField, InputAdornment, Button, Select, MenuItem } from '@mui/material';
import { useState } from 'react';

const Component = () => {
    const { data } = ComponentState();
    const [codeCopied, setCodeCopied] = useState(false);
    const [onlySubProcessors, setOnlySubProcessors] = useState(false);

    const getPrivacyPortalLink = () =>
        `${window.location.origin}/${data.isPrivacyPortalHosted ? 'privacy-portal' : 'embedded-privacy-portal'}/${data._id}`;

    const getPrivacyPortalCode = () => {
        let ppLink = getPrivacyPortalLink();

        if (onlySubProcessors && data.isPrivacyPortalHosted === false) {
            ppLink += `?sections=sub-processors`;
        }

        let styleCode = `width: 100%; height: 100vh;`;
        let elmId = `privacy-policy-iframe`;
        let seoTitle = `Privasee - ${data.companyLegalName}`;
        let jsCode = `<script>window.addEventListener('message', (event) => { if(event.data) { const { message, contentHeight, url} = event.data; if(message === "Open cookie banner") { if(window.privasee === undefined) return console.warn("[CB] Cookie blocker global variable is not detected."); window.privasee.cookieBanner.showPreferences(); }; if(message === "Open external link") { window.open(url, "_blank"); } if(message === 'Updating content height') { var _doc = document.getElementById('privacy-policy-iframe'); if(!_doc) return false; _doc.style.height = contentHeight; }}}, false);</script>`;
        let iframeCode = `<iframe title="${seoTitle}" id="${elmId}" frameBorder="0" src="${ppLink}" style="${styleCode}"></iframe>${jsCode}`;
        return iframeCode;
    };

    const copyLink = async () => {
        await trackUserInteraction(`Selected "Copy link"`, {
            type: `privacyPortal`,
        });
        navigator.clipboard.writeText(getPrivacyPortalLink());
        setCodeCopied(true);
        setTimeout(() => setCodeCopied(false), 2500);
    };

    const copyCode = async () => {
        await trackUserInteraction(`Selected "Copy code"`, {
            type: `privacyPortal`,
        });
        navigator.clipboard.writeText(getPrivacyPortalCode());
        window.showAlert('Link copied', `You've copied the code to your clipboard.`, `success`);
    };

    const changeSettingSection = (newValue) => {
        setOnlySubProcessors(newValue);

        window.showAlert(
            'Update Successful',
            'Your customization has been applied to the code. Please proceed to copy the updated code!',
            'success',
        );
    };

    return (
        <React.Fragment>
            <div className="step-entry">
                <div className="step-header">
                    <div className="step-badge">Step 1</div>
                    <div className="step-label">
                        {data.isPrivacyPortalHosted ? `Copy Privacy Portal Link` : `Copy Privacy Portal Code`}{' '}
                    </div>
                </div>
                <div className="step-content">
                    <div className="step-two-rows">
                        <div className="parent-container-rows" style={{ flex: 1 }}>
                            {data.isPrivacyPortalHosted === false && data.privacyPortalSettings.features.listSubProcessors === true && (
                                <React.Fragment>
                                    <p style={{ color: 'rgba(255,255,255,0.6)', marginBottom: 8 }}>
                                        What sections should this Embedded Portal code include?
                                    </p>
                                    <Select
                                        fullWidth={true}
                                        value={onlySubProcessors}
                                        onChange={(ev) => changeSettingSection(ev.target.value)}
                                        style={{ marginBottom: 16 }}
                                    >
                                        <MenuItem value={false}>Privacy Policy & Cookie Policy</MenuItem>
                                        <MenuItem value={true}>Sub-Processors</MenuItem>
                                    </Select>
                                </React.Fragment>
                            )}

                            <div className="step-text">
                                {data.isPrivacyPortalHosted === true ? (
                                    <React.Fragment>
                                        Copy the link below. It takes you to Privacy Portal. It contains all your GDPR Documentation through
                                        the link. Once you add it to your website you don't need to do anything else, the content will
                                        update automatically.
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        Copy the code below. When you embed it on your website it will contain all your GDPR Documentation.
                                        Once you add it to your website you don't need to do anything else, the content will update
                                        automatically.
                                    </React.Fragment>
                                )}
                            </div>

                            <TextField
                                className="input-link"
                                disabled={true}
                                data-cy="input-link"
                                fullWidth={true}
                                value={data.isPrivacyPortalHosted ? getPrivacyPortalLink() : getPrivacyPortalCode()}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                className="input-button"
                                                disabled={codeCopied}
                                                onClick={data.isPrivacyPortalHosted ? copyLink : copyCode}
                                            >
                                                <i className="icon fa-solid fa-copy"></i>{' '}
                                                {codeCopied === true ? (
                                                    'Code copied!'
                                                ) : (
                                                    <React.Fragment>Copy {data.isPrivacyPortalHosted ? 'link' : 'code'}</React.Fragment>
                                                )}
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
