import React from 'react';

// Context
import { OnboardingFunctions } from '../../../components/functions';
import { Button } from '@mui/material';

// Context
import { ViewContext } from '..';
import { logError } from '../../../../../../utils/helpers';

const Component = () => {
    const { navigateViews, updateCompanyData } = OnboardingFunctions();
    const { currentOptions } = ViewContext();

    const nextStep = async () => {
        try {
            // Update on back-end
            await updateCompanyData({ targetAudience: currentOptions });

            // Peace of mind information
            window.showAlert(
                'For your peace of mind',
                `You don't need to have all the information right now. If you are not sure about something you can always add more information or change your answers later.`,
                'info',
                [
                    {
                        text: 'Good to know',
                        dataCy: `alert-confirm-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                ],
            );

            // Navigate
            navigateViews('forward');
        } catch (err) {
            await logError(`onboarding.audience.nextStep`, err);
        }
    };

    const previousStep = () => {
        navigateViews('backward');
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">Choose who your target audience is</div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={currentOptions.length < 1}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
