import React from 'react';

// Components
import Archive from './components/archive';
import Delete from './components/delete';

const Component = () => {
    return (
        <React.Fragment>
            <Archive />
            <Delete />
        </React.Fragment>
    );
};

export default Component;
