import React from 'react';

// Context
import { OnboardingContext } from '../../../../..';
import { getToolRecommendations } from '../../../../../../thirdParties/components/storageLocations/components/editTools/utils/helpers';
import { ButtonBase } from '@mui/material';
import { logError } from '../../../../../../../../utils/helpers';
import { OnboardingFunctions } from '../../../../../components/functions';

// Components
import Badge from './badge';

const Component = (props) => {
    const { toolsRecommended, flags, scanner } = OnboardingContext();
    const { updateStorageLocation, deleteStorageLocation } = OnboardingFunctions();

    const getAvatar = () => {
        const recommendation = getToolRecommendations(props.data.label, toolsRecommended);
        return recommendation ? recommendation.image : '';
    };

    const archiveLocation = async () => {
        try {
            window.showAlert(
                'Confirmation',
                `Are you sure you want to archive ${props.data.label}?\nThis tool or third party won't show up on your inventory or policies. This can be reversed later.`,
                'warning',
                [
                    {
                        text: 'Cancel',
                        dataCy: `alert-cancel-button`,

                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                    {
                        text: 'Confirm',
                        dataCy: `alert-submit-button`,

                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();

                            // Update
                            await updateStorageLocation(props.data._id, {
                                archived: true,
                            });
                        },
                    },
                ],
            );
        } catch (err) {
            await logError(`onboarding.dataInventory.archiveStorageLocations`, err);
        }
    };

    const deleteLocation = async () => {
        try {
            window.showAlert(
                'Confirmation',
                `Are you certain you wish to delete ${props.data.label}? Rest assured, you can always reselect it from the recommendations list if needed later.`,
                'warning',
                [
                    {
                        text: 'Cancel',
                        dataCy: `alert-cancel-button`,

                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                    {
                        text: 'Confirm',
                        dataCy: `alert-submit-button`,

                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();

                            // Update
                            await deleteStorageLocation(props.data._id);
                        },
                    },
                ],
            );
        } catch (err) {
            await logError(`onboarding.dataInventory.archiveStorageLocations`, err);
        }
    };

    const checkIsRecentlyFound = () => {
        const match = scanner.results.find((g) => props.data.label.toLowerCase().includes(g.label.toLowerCase()));

        return match ? true : false;
    };

    return (
        <React.Fragment>
            <div className="entry">
                <div
                    className="img"
                    style={{
                        backgroundImage: `url("${getAvatar()}")`,
                    }}
                ></div>
                <div className="label">{props.data.label}</div>
                <div className="controls">
                    {flags.showDeleteInsteadOfArchive ? (
                        <React.Fragment>
                            <ButtonBase data-cy={`chip-delete`} onClick={deleteLocation}>
                                <i className="elm fa-solid fa-trash" />
                            </ButtonBase>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <ButtonBase data-cy={`chip-archive`} onClick={archiveLocation}>
                                <i className="elm fa-solid fa-box-archive" />
                            </ButtonBase>
                        </React.Fragment>
                    )}
                </div>
                {checkIsRecentlyFound() && <Badge color="#f79a43" />}
            </div>
        </React.Fragment>
    );
};

export default Component;
