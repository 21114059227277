import React, { useEffect } from 'react';
import { ModuleState } from '../../..';
import { logError } from '../../../../../../utils/helpers';

const Component = () => {
    const { data, updateData } = ModuleState();

    const setResponse = (response) => {
        updateData(`companyInfo.processChildrenData`, response === 'Yes' ? true : false);

        if (response === 'No') {
            document.dispatchEvent(new CustomEvent('children:resetIndividualsWithChildren'));
        }

        // Legacy thing.
        setProcessChildrenData(response === 'Yes' ? true : false);
    };

    const setProcessChildrenData = async (value = false) => {
        try {
            let checkedData = data.checkedData;

            // We check if they already have children data
            const hasChildrenData = data.checkedData.individuals.find((c) => c.label === 'Children');

            if (value === true && !hasChildrenData) {
                checkedData.individuals.push({
                    label: 'Children',
                    type: 'manual',
                });
            } else if (value === false) {
                // We remove any individual with label Children
                checkedData.individuals = data.checkedData.individuals.filter((c) => c.label !== 'Children');
            }

            updateData('checkedData', checkedData);
        } catch (err) {
            await logError(`setProcessChildrenData`, err);
        }
    };

    useEffect(() => {
        return () => {
            // If we're gonna go away from this screen and we haven't listed.
            if (data.companyInfo.individualsReferring.filter((c) => c.isChildren === true).length < 1) {
                setResponse('No');
            }
        };

        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className="form-entry">
                <div className="label">Do you process data from anyone under the age of 16?</div>
                <div className="content">
                    <div className="yes-no-questions">
                        {['Yes', 'No'].map((c, ix) => (
                            <div
                                className={`entry ${data.companyInfo.processChildrenData === (c === 'Yes' ? true : false) && 'selected'}`}
                                key={ix}
                                onClick={() => setResponse(c)}
                            >
                                {c}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
