import React from 'react';

// Components
import CompanyLegalName from './components/companyLegalName';
import Address from './components/address';

const Component = () => {
    return (
        <React.Fragment>
            <div className="section-title">Company Details</div>
            <div className="section-group">
                <CompanyLegalName />
                <Address />
            </div>
        </React.Fragment>
    );
};

export default Component;
