import React from 'react';

// Components
import { TextField, Autocomplete } from '@mui/material';

// Context
import { ViewContext } from '..';
import { getValidationPropFields } from '../../../../../../../utils/helpers';
import { PanelContext } from '../../..';

const Component = () => {
    const { validationResults } = ViewContext();
    const { data, setData, isEditing, vitalRecommendations, onRecommendedLabelSelected } = PanelContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Process Name</div>
                </div>
                <div className="component-content">
                    <Autocomplete
                        disablePortal
                        data-cy="field-label-autocomplete"
                        value={data.label}
                        options={
                            data.label.length < 1 && !isEditing && vitalRecommendations && vitalRecommendations.processes
                                ? vitalRecommendations.processes
                                : []
                        }
                        freeSolo={true}
                        multiple={false}
                        // When they pick an option
                        onChange={(ev, option) => {
                            // @Bugfix: We need to set this label like otherwise it will set to 'zero'
                            setData({ ...data, label: option.label });

                            // Callback
                            onRecommendedLabelSelected(option.label);
                        }}
                        // Bugfix for free solo when they write but don't press Enter.
                        onInputChange={(ev) => {
                            if (!ev) return false;
                            setData({ ...data, label: ev.target.value });
                        }}
                        fullWidth={true}
                        disableClearable={true}
                        groupBy={(e) => e.category}
                        renderInput={(params) => (
                            <TextField
                                data-cy={`input-label`}
                                placeholder="Process Name"
                                {...params}
                                {...getValidationPropFields(validationResults, true, `label`)}
                            />
                        )}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
