import { capitalizeFirstLetter, logError } from '../../../../../../utils/helpers';
import ObjectId from 'bson-objectid';
import { getStorageLocationMissingReferringRoles } from '../../../components/functions';

export const generateStorageLocationId = () => `${ObjectId()}`; // must be a string.

export const createStorageLocationWithRecommendations = (params) => {
    try {
        // Extract the params..
        const {
            toolName,
            specificRecommendations,
            vitalRecommendations,
            targetAudience,
            processes = [],
            individualsReferring,
            // currentStorageLocations = [],
        } = params;

        const storage = specificRecommendations.storageLocations.find((x) => x.label === toolName);
        if (!storage) {
            console.warn(`Failed to find matching item storage recommendation.`, toolName);
            return null;
        }

        let newProcesses = [],
            newIndividuals = [],
            newElements = [],
            newMatrix = [];

        // Combine storage.processRecommendations and processes arrays
        const combinedArray = [...storage.processRecommendations, ...processes];

        // Use a Set to remove duplicates and convert back to an array
        const processesMapped = [...new Set(combinedArray)];

        // Map...
        processesMapped.forEach((processName) => {
            if (processName.includes('B2B') && !targetAudience.includes('b2b')) return false;
            if (processName.includes('B2C') && !targetAudience.includes('b2c')) return false;

            newProcesses.push({
                label: processName,
                category: ``, // As of 10 Jun 2022, we don't have this anymore returned. I suggest we remove it - We don't use it anywhere on the front-end.
            });

            let matchProcess = specificRecommendations.processes.find((proc) => proc.label === processName);

            if (!matchProcess) return false; // No point in going forward. There is no recommendation for this process.

            matchProcess.subjectCategories
                .filter((st) => st.trim().length > 0)
                .forEach((individual) => {
                    if (newIndividuals.find((e) => e.label === individual)) return false; // already exists

                    // eslint-disable-next-line
                    newIndividuals.push(
                        createIndividual({
                            label: individual,
                            category: ``, // Had to remove it. No longer returned and wasn't used.
                        }),
                    );
                });

            matchProcess.elementCategories
                .filter((st) => st.trim().length > 0)
                .forEach((elm) => {
                    if (newElements.find((e) => e.label === elm)) return false; // already exists

                    newElements.push({
                        label: elm,
                        description: ``, // No longer returned.
                        sensitive: false,
                    });
                });

            matchProcess.specialCategoryElements
                .filter((st) => st.trim().length > 0)
                .forEach((elm) => {
                    if (newElements.find((e) => e.label === elm)) return false; // already exists

                    newElements.push({
                        label: elm,
                        description: ``,
                        sensitive: true,
                    });
                });
        });

        processesMapped.forEach((proc) => {
            const matchProcess = specificRecommendations.processes.find((x) => x.label === proc);
            if (!matchProcess) return false;
            if (matchProcess.label.includes('B2B') && !targetAudience.includes('b2b')) return false;
            if (matchProcess.label.includes('B2C') && !targetAudience.includes('b2c')) return false;

            const elements = [...matchProcess.elementCategories];
            const individuals = [...matchProcess.subjectCategories];

            elements
                .filter((str) => str.length > 0)
                .forEach((element) => {
                    individuals
                        .filter((str) => str.length > 0)
                        .forEach((individual) => {
                            newMatrix.push({
                                process: matchProcess.label,
                                element: element,
                                individual: individual,
                            });
                        });
                });
        });

        // We now need to get the recommendations for this Storage Location according to the Referring roles.
        // So if there's any referring roles we should add (beside our recommendations)
        const missingReferringRoles = getStorageLocationMissingReferringRoles({
            individualsReferences: individualsReferring,
            vitalRecommendations,
            storageLocation: {
                processes: newProcesses,
                elements: newElements,
                individuals: newIndividuals,
            },
        });

        // Adding the missing referring roles
        missingReferringRoles.forEach((r) => {
            // Adding the individual
            newIndividuals.push(r.individual);

            // Adding the matrices
            newMatrix = [...newMatrix, ...r.matrices];
        });

        const newStorageItem = {
            _storageLocationId: generateStorageLocationId(),
            label: storage.label,
            category: storage.category,
            description: storage.description,
            cookieType: storage.cookieType,
            cookiePurpose: storage.cookiePurpose,
            cookieExternalInfo: storage.cookieExternalInfo,
            image: storage.image,
            securityMeasures: storage.securityMeasures,
            dataResidency: storage.dataResidency,
            individuals: newIndividuals,
            elements: newElements,
            processes: newProcesses,
            matrixMap: newMatrix,
            archived: false,
            validated: false,
            isSubProcessor: false,
            subProcessorMeasures: { title: '', url: '' },
            origin: 'recommendations', // this is set by the place that calls this function.
        };

        return newStorageItem;
    } catch (err) {
        logError(`createStorageLocationWithRecommendations`, err, { toolName: params.toolName });
        return null;
    }
};

export const createCustomStorageLocation = (toolName) => {
    return {
        _storageLocationId: generateStorageLocationId(),
        label: capitalizeFirstLetter(toolName),
        category: '',
        dataResidency: '',
        securityMeasures: '',
        description: '',
        image: '',
        cookieType: '',
        cookiePurpose: '',
        cookieExternalInfo: '',
        individuals: [],
        elements: [],
        processes: [],
        matrixMap: [],
        isSubProcessor: false,
        subProcessorMeasures: { title: '', url: '' },
        origin: 'manual',
        archived: false,
        validated: false,
    };
};

/**
 * A simple function that will make sure that we keep our individuals models in check. Aka we don't have missing data ever.
 * @param {*} params
 * @returns
 */

export const createIndividual = (params) => {
    return {
        label: '',
        category: '', // not used anymore i think
        ...params,
    };
};

export const createMatrice = (params) => {
    return {
        process: params.process,
        element: params.element,
        individual: params.individual,
    };
};
