import React, { useState, useContext, createContext, useEffect, useRef } from 'react';

// Components
import Modal from '../../../../components/modulePopout';
import SubHeader from './components/subHeader';
import Tabs from './components/tabs';
import Views from './views';
import Actions from './components/actions';
import API from './components/api';

// Dependencies
import { logError, useStateRef, validateAgainstSchema } from '../../../../../utils/helpers';
import { informationViewSchema, subProcessorViewSchema } from './utils/validation';
import { isTabValid, validateStorageLocation } from './utils/functions';

// Context
const Context = createContext({});
export const PanelContext = () => useContext(Context);

const Component = (props) => {
    const [tab, setTab] = useState('information');
    const [data, setData, dataRef] = useStateRef(props.data);
    const [dataInventory, _setDataInventory, dataInventoryRef] = useStateRef(props.dataInventory);

    // The ref that controls the panel
    const modalController = useRef(null);
    const dataChanged = JSON.stringify(props.data) !== JSON.stringify(data) ? true : false;

    const isToolNameAlreadyUsed = () => {
        // Check if tool with same name already exists
        const matchIndex = props.dataInventory.storageLocations.findIndex((i) => i.label === data.label);
        const matchingTool = matchIndex === -1 ? null : props.dataInventory.storageLocations[matchIndex];

        // Does exist
        if (matchingTool && matchingTool._storageLocationId !== data._storageLocationId) return true;
        return false;
    };

    const validateView = async (id) => {
        try {
            // Validate information view..
            if (id === 'information') {
                // Validate against schema for basic things
                await validateAgainstSchema(informationViewSchema, data);

                // Check if tool name is already used
                const nameUsed = isToolNameAlreadyUsed();

                // Does exist
                if (nameUsed) {
                    return {
                        status: false,
                        results: {
                            label: `Name is already used. Try adding a suffix: ${data.label} (2)`,
                        },
                    };
                }
            }

            if (id === 'detailedView') {
                const tabValid = isTabValid(data, 'detailedView');

                // If is not valid we need to return it like this.
                if (!tabValid) return { status: false, results: {} };
            }

            if (id === 'subProcessor' && props.flags.missingSubProcessors) {
                // Validate against schema
                await validateAgainstSchema(subProcessorViewSchema, {
                    subProcessorMeasuresURL: data.subProcessorMeasures.url,
                    isSubProcessor: data.isSubProcessor,
                });
            }

            return {
                status: true,
                results: {},
            };
        } catch (err) {
            return {
                status: false,
                results: err,
            };
        }
    };

    /**
     * This function will validate and then inform the user about what is missing from the tool.
     * @Reminder: Is not using the same validateView validation. Is more simpler.
     * @param {*} onContinue - What to execute when the user taps "continue anyway"
     * @returns
     */

    const showValidationErrorsOnSave = (onContinue = null) => {
        try {
            // Validate the storage location
            const validation = validateStorageLocation(data);

            // If we are supposed to validate before closing.
            if (validation.status === false) {
                // The message that will be displayed by Default.
                let message = `This tool and third party is missing information. Add the missing information now or Save and come back later.`;

                // A simple function that will be called when the warning shows up
                let onAction = () => {};

                // If is one of those
                if (['NO_ELEMENTS', 'NO_PROCESSES', 'NO_INDIVIDUALS'].includes(validation.reason)) {
                    onAction = () => {
                        setTab('detailedView');
                    };
                }

                // Show the message
                window.showAlert('Confirmation', message, 'warning', [
                    {
                        text: 'Skip For Now',
                        dataCy: `alert-skip-button`,
                        onClick: async ({ dismissAlert }) => {
                            onContinue();
                            dismissAlert();
                        },
                    },
                    {
                        text: 'Fix Now',
                        dataCy: `alert-fix-button`,
                        onClick: async ({ dismissAlert }) => {
                            // Dismiss Alert
                            dismissAlert();

                            // Action callback
                            onAction();
                        },
                    },
                ]);

                return true;
            }

            // Return false it means all is good with this storage location.
            return false;
        } catch (err) {
            logError(`editTools.panel.showValidationErrorsOnSave`, err);
            return false;
        }
    };

    const closePanel = () => {
        props.onClose();
    };

    const saveChanges = async () => {
        try {
            // Is already closing. Avoid button spamming.
            if (modalController.current.closing === true) return false;

            // Is this tool valid to save?
            const validation = validateStorageLocation(data);

            // This will be the function to save the changes
            const updateFunc = async () => {
                // Close the panel
                closePanel();

                // Update the tool now.
                await props.functions.updateData(data);

                // Let's cleanup inventory too now.
                await props.functions.cleanupInventory();
            };

            // If is not valid about certain things we need to inform the user.
            if (validation.status === false) {
                const reasons = ['NO_ELEMENTS', 'NO_PROCESSES', 'NO_INDIVIDUALS', 'INCOMPLETE_PROCESS'];

                // If the warning is one of those with a message..
                if (reasons.includes(validation.reason)) {
                    showValidationErrorsOnSave(updateFunc);
                    return true;
                }
            }

            // Update..
            await updateFunc();
        } catch (err) {
            await logError(`tools.editPanel.saveChanges`, err);
            return false;
        }
    };

    const stopEditing = async () => {
        // Is already closing. Avoid button spamming.
        if (modalController.current.closing === true) return false;

        const onStopEditing = async () => {
            // Close Panel
            closePanel();
        };

        // Check if data changed
        if (dataChanged) {
            window.showAlert(
                'Confirmation',
                `Are you sure you want to exit without saving your changes? If you proceed, any unsaved changes will be discarded.`,
                'warning',
                [
                    {
                        text: `Cancel`,
                        dataCy: `alert-cancel-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                    {
                        text: 'Confirm',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            onStopEditing();
                        },
                    },
                ],
            );
            return true;
        }

        onStopEditing();
    };

    // Is important to have an useRef for the data inventory.
    useEffect(() => {
        _setDataInventory(props.dataInventory);

        // eslint-disable-next-line
    }, [props.dataInventory]);

    // The information passed through context
    const PassedProps = {
        data,
        dataRef,
        initialData: props.data, // The data that is currently saved.
        setData,
        updateData: (payload) => setData((currentState) => ({ ...currentState, ...payload })),
        functions: props.functions,
        closePanel,
        // Others
        recommendations: props.recommendations,
        vitalRecommendations: props.vitalRecommendations,
        // Tabs
        tab,
        setTab,
        // Company data
        companyData: props.companyData,
        // Data inventory
        dataInventory,
        dataInventoryRef,
        setDataInventory: _setDataInventory,
        // flags
        flags: props.flags,
        // Functions
        validateView,
        saveChanges,
        stopEditing,
        isToolNameAlreadyUsed,
    };

    useEffect(() => {
        if (!props.innerRef) return false;

        // This will allow us to set tab and process inspected from outside the panel.
        props.innerRef.current = {
            setTab,
            modal: modalController,
            validateView,
        };

        // eslint-disable-next-line
    }, []);

    // Get the tab component rendered.
    const MappedScreen = Views[tab];

    return (
        <Context.Provider value={PassedProps}>
            <Modal
                className={`${props.className} module-panel-editor modal-individual-editor modal-process-editor`}
                header={{
                    title: <React.Fragment>Edit tool or third party</React.Fragment>,
                    description: <SubHeader />,
                    closeButton: <Actions />,
                }}
                content={
                    <React.Fragment>
                        <Tabs />

                        <div className={`component-panel-content ${tab}`}>
                            <div className="--container">
                                <MappedScreen />
                            </div>
                        </div>
                    </React.Fragment>
                }
                innerRef={modalController}
            />
            <API />
        </Context.Provider>
    );
};

export default Component;
