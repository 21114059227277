import React, { createContext, useContext, useState, useEffect } from 'react';

// Components
import Modal from './components/modal';
import Label from './components/label';
import Tools from './components/tools';

// Dependencies
import { gelFlowCreateToolSchema } from '../../../../gdprEssentials/utils/validations';
import { validateAgainstSchema } from '../../../../../../utils/helpers';

// Panel Context
import { PanelContext } from '..';

// Context
const Context = createContext({});
export const CustomToolContext = () => useContext(Context);

const Component = () => {
    // Dependencies
    const { dataInventory, customTool, createCustomTool, stopCreatingCustomTool } = PanelContext();

    const [isSimilarTo, setIsSimilarTo] = useState(null);
    const [searchingText, setSearchingText] = useState('');
    const [validationResults, setValidationResults] = useState({});
    const [data, setData] = useState({
        label: customTool.label,
    });

    const validateFields = async () => {
        try {
            await validateAgainstSchema(gelFlowCreateToolSchema, data);
            setValidationResults({});

            // If a tool with this name already exists
            const matchTool = dataInventory.storageLocations.find((i) => i.label.trim() === data.label.trim());
            if (matchTool) {
                setValidationResults({ label: `Name is already used. Try adding a suffix: ${data.label} (2)` });
            }
        } catch (err) {
            setValidationResults(err);
        }
    };

    const onSubmit = () => {
        createCustomTool({
            label: data.label,
            similarData: isSimilarTo,
        });
    };

    const onDismiss = () => {
        stopCreatingCustomTool();
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    const PassedProps = {
        validationResults,
        setValidationResults,
        data,
        setData,
        isSimilarTo,
        setIsSimilarTo,
        searchingText,
        setSearchingText,
        onSubmit,
        onDismiss,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Modal>
                <Label />
                <Tools />
            </Modal>
        </Context.Provider>
    );
};

export default Component;
