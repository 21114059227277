import React from 'react';

// Context
import { PanelContext } from '../../..';

// Components
import { MenuItem, Select } from '@mui/material';

const Component = () => {
    const { data, setData } = PanelContext();

    const options = [
        {
            value: 'reference',
            label: 'Part of the terms or agreement',
        },
        {
            value: 'signature',
            label: `Signature required`,
        },
    ];
    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <span className="text">Data Protection Agreement (DPA) type</span>
                    {/* {data.dpaType === null && <div className="badge badge-data-warning blue" />} */}
                </div>
                <div className="component-content">
                    <Select
                        onChange={(ev) => {
                            let newValue = ev.target.value === 'none' ? null : ev.target.value;

                            setData({ ...data, dpaType: newValue });
                        }}
                        fullWidth={true}
                        data-cy="field-dpaType"
                        variant="standard"
                        value={data.dpaType || 'none'}
                    >
                        <MenuItem value="none">No selection</MenuItem>
                        {options.map((c, ix) => (
                            <MenuItem key={ix} value={c.value}>
                                {c.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {/* {validationResults[`dpaType`] !== undefined && (
                        <FormHelperText error={true}>{validationResults['dpaType']}</FormHelperText>
                    )} */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
