import React from 'react';
import Tooltip from '../../../../../../../../components/tooltip';

const Component = () => {
    return (
        <React.Fragment>
            <div className="heading">
                Finding a similar tool or third party can help you map your personal data quicker (optional)
                <div className="tooltip" style={{ display: 'inline-block' }}>
                    <Tooltip
                        trackInteraction={true}
                        interactionId={`gdpr-essentials:finding-a-similar-tool`}
                        content={`If you find a similar tool or third party, the recommendations that are applied to that tool will be applied to your custom one. Making the process to map your data easier.`}
                    >
                        <i className="tooltip-icon fa-solid fa-circle-info"></i>
                    </Tooltip>
                </div>
            </div>
            <div className="subheading">Select a competitor or similar tool or third party. Don’t worry you will be able to edit it.</div>
        </React.Fragment>
    );
};

export default Component;
