import lodash from 'lodash';
import { validationFiltersSchema } from './validations';
import { getFromUrlQueryString, logError, validateAgainstSchema } from '../../../../../utils/helpers';

function filtersToSlugParams(arr) {
    try {
        // The slug that we format..
        let slugs = [];

        // Iterate through each field
        arr.forEach((elm) => {
            // // Is not valid yet.
            if (!isValueNotRequired(elm.filter) && elm.value.length < 1) return false;

            // Escape "|" and "," to make sure is not breaking if user ads , in the value
            const escapedValue = elm.value.replace(/[=:,]/g, '\\$&');
            // @Reminder: Remember to update "originalValue" below. to "unescape" them right.
            // Formatting the text
            let text = `${elm.type}:${elm.key}:${elm.filter}`;

            // If this filter requires a value we will add at the end of the field.
            if (!isValueNotRequired(elm.filter)) {
                text += `=${escapedValue}`;
            }

            slugs.push(text);
        });

        // Format the final response
        const response = slugs.join(',');

        return response;
    } catch (err) {
        throw err;
    }
}

function slugParamsToFilters(slug) {
    try {
        // Split the params using a regular expression that doesn't consider escaped ","
        const params = slug.split(/(?<!\\),/);

        // Array to hold the result
        const result = [];

        // Iterate through params
        params.forEach((s) => {
            // Split the string into its components
            const components = s.split(':');

            // Getting the easy ones..
            const type = components[0];
            const key = components[1];

            // If a "=" is found in the component two it means is a filter with a value. we also need to split by "=" that are not escaped by us.
            const notEscapedEqual = /(?<!\\)=/;
            const filter = components[2].includes('=') ? components[2].split(notEscapedEqual)[0] : components[2];
            const value = !isValueNotRequired(filter) ? components[2].split(notEscapedEqual)[1] : null;

            // De-escape ":" and "=" and "," characters
            const originalValue = value ? value.replace(/\\([:=,])/g, '$1') : null;

            // Add to the array..
            result.push({
                type,
                key,
                filter,
                value: originalValue,
            });
        });

        return result;
    } catch (err) {
        throw err;
    }
}

/**
 * @param {*} input Filters (Object or slug)
 * @param {*} action It can be 'transform' or 'parse'
 * @returns Response formatted
 */

export function transformFilters(input, action) {
    // Convert the object into a slug
    if (action === 'transform') return filtersToSlugParams(input);

    // Convert the slug into a JSON object
    if (action === 'parse') return slugParamsToFilters(input);

    return null;
}

/**
 * Validate the filters against a well thought out schema
 * @param {*} filters
 * @returns
 */

export const validateFilters = async (filters) => {
    try {
        await validateAgainstSchema(validationFiltersSchema, { filters });
        return true;
    } catch (err) {
        // eslint-disable-next-line
        console.log(`[Filters] Validation failed for url params.`, err);

        // Remove filters from url due to being invalid
        if (window.location.href.includes('filters=')) {
            // Update the current URL with the new slugs.
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.delete('filters');
            window.history.replaceState({}, document.title, currentUrl); // Update the URL without triggering a page reload
        }

        throw err;
    }
};

/**
 * This will tell you if this filter requires a value.
 * @param {*} filter
 * @returns boolean
 */

export const isValueNotRequired = (filter) => {
    if (['isEmpty', 'notEmpty'].includes(filter)) return true;
    return false;
};

/**
 * This function will read the current browser url and get there filters if there's any in the right format.
 * @returns filters if any
 */

export const getFiltersFromSlugParams = async () => {
    try {
        // Get filters param
        const slug = getFromUrlQueryString('filters');
        if (!slug) return [];

        // Attempt to convert current slug.
        const parsed = transformFilters(slug, 'parse');

        // Validate that these filters are right.
        await validateFilters(parsed);

        // // eslint-disable-next-line
        // console.log(`Valid and well: `, parsed);
        return parsed;
    } catch (err) {
        throw err;
    }
};

const validateFieldTypeText = (filter, _, fieldValue) => {
    try {
        // Get the type of this filtering set by the user
        const type = filter.filter;
        const filterValue = filter.value || '';

        // We expect to be empty
        if (type === 'isEmpty' && fieldValue.length > 0) {
            return false;
        }

        // We don't expect to be empty
        if (type === 'notEmpty' && fieldValue.length < 1) {
            return false;
        }

        // We expect this to be inside the value
        if (type === 'contains' && !lowerCaseValue(fieldValue).includes(lowerCaseValue(filterValue))) {
            return false;
        }

        // We don't expect this to be inside the value
        if (type === 'notContains' && lowerCaseValue(fieldValue).includes(lowerCaseValue(filterValue))) {
            return false;
        }

        // We expect the text field to be an exact value
        if (type === 'is' && filterValue !== fieldValue) {
            return false;
        }

        return true;
    } catch (err) {
        logError(`filters.validateFieldTypeText`, err, { filter, fieldValue });
        return false;
    }
};

/**
 * Shortcut to avoid long lines of code.
 */

const lowerCaseValue = (val) => {
    return val.toString().toLowerCase();
};

/**
 *
 * @param {*} filter The filter set by the user.
 * @param {*} field  The field we created in our app
 * @param {*} arr The array of what the user added in that filter.
 * @returns
 */

const validateFieldTypeArray = (filter, field, arr) => {
    try {
        // Get the type of this filtering set by the user
        const type = filter.filter;
        const filterValue = filter.value;

        // If the array should be empty but is not.
        if (type === 'isEmpty' && arr.length > 0) {
            return false;
        }

        // If the array should not be empty but it is.
        if (type === 'notEmpty' && arr.length < 1) {
            return false;
        }

        // This array should contain a certain field.
        if (type === 'contains' || type == 'notContains') {
            let match = arr.find((c) => {
                const comparedValue = field.propertyCompared ? c[field.propertyCompared] : c;
                return lowerCaseValue(comparedValue).includes(lowerCaseValue(filterValue));
            });

            // If filter is contain but we don't contain it.
            if (!match && type === 'contains') return false;

            // If filter is notContain but we do contain it.
            if (match && type === 'notContains') return false;
        }

        if (type === 'is') {
            let match = arr.find((c) => {
                const comparedValue = field.propertyCompared ? c[field.propertyCompared] : c;
                return lowerCaseValue(comparedValue) === lowerCaseValue(filterValue);
            });

            if (!match) return false;
        }

        return true;
    } catch (err) {
        logError(`filters.validateFieldTypeArray`, err);
        return false;
    }
};

/**
 *
 * @param {*} data Array of what we need to filter through
 * @param {*} filters The filters set up by the user
 * @param {*} fields The filters set up by the App where it's defined what fields they can filter.
 * @returns The data filtered
 */

export const applyFilters = (data, filters, fields) => {
    try {
        data = data.filter((elm) => {
            // Store the filter statuses in this array.
            let filtersStatus = {};

            // Iterate through each filter set up by the user
            filters.forEach((e, ix) => {
                // Get the properties of the filter field. (Type, Options etc)
                const field = fields.find((c) => c.key === e.key);

                // By default all filter checks will pass.
                let passed = true;

                // Get the value of the field filtered..
                const data = lodash.get(elm, e.key);

                // If the field type we filter is a type text..
                if (field.type === 'text') {
                    // Validate..
                    passed = validateFieldTypeText(e, field, data);
                }

                // If the field type we filter is a type array..
                if (field.type === 'array') {
                    // Validate..
                    passed = validateFieldTypeArray(e, field, data);
                }

                filtersStatus[ix] = passed;
            });

            // If there are more than one filters..
            if (filters.length > 1) {
                const typeOfFilters = filters[1].type;

                // If all the other types are an "AND" we must make sure that all statuses including first one have passed
                if (typeOfFilters === 'and') {
                    const allTrues = Object.values(filtersStatus).filter((x) => x === false).length > 0 ? false : true;
                    return allTrues ? true : false;
                }

                if (typeOfFilters === 'or') {
                    const someTrues = Object.values(filtersStatus).filter((x) => x === true).length > 0 ? true : false;
                    return someTrues ? true : false;
                }
            }

            // If they failed the main "WHERE" and there's no other check
            if (filtersStatus[0] === false && filters.length < 2) return false;

            return true;
        });

        return data;
    } catch (err) {
        logError(`filters.applyFilters`, err, { filters, fields });
        return data;
    }
};
