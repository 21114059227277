import React, { useEffect, createContext, useContext, useState } from 'react';

// Context
import { ModuleState } from '..';

// Dependencies
import {
    createStorageLocationWithRecommendations,
    createCustomStorageLocation,
    createIndividual,
} from '../steps/storageLocations/utils/helpers';
import { sensitiveIndividualsList } from '../steps/identifyVulnerable';
import { checkUserFlag, logError, trackUserInteraction } from '../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../utils/endpoints';

// Functions
import {
    calculateProgress,
    getStorageLocationMissingReferringRoles,
    getMapStepsToSlugs,
    groupSuggestedTools,
    isStepMoreAdvanced,
    loadScansIntoStorageLocations,
    updateLink,
} from './functions';
import { mapSlugsToSteps, mappedSteps } from './mappings';
import { createReferringRole, getIndividualTypesIdFromLabel, isChildrenRole } from '../steps/individuals/components/functions';

// Context
const Context = createContext({});
export const ModuleAPI = () => useContext(Context);

// Others
let updateDataTimer = null;

const Component = (props) => {
    const { data, setData, dataRef, isEditingSession, setSuggestedTools } = ModuleState();
    const { flags, setFlags, setDataProcessingAgreements, vitalRecommendations, setVitalRecommendations } = ModuleState();
    const { step, _setStep, setProgress, updateData, submittedRef } = ModuleState();
    const { setScanning, scanCancelledRef, setScannerResults, setScanCancelled } = ModuleState();
    const { setScansReviewed, generatingCompanyDescription, setGeneratingCompanyDescription } = ModuleState();
    const { generatingCompanyActivities, setGeneratingCompanyActivities } = ModuleState();

    // Needed
    const [loadedRecommendations, setLoadedRecommendations] = useState(false);

    // Variables
    let paramStage = props.urlParams.stage;

    const updateSession = async () => {
        try {
            // Format current storage locations
            const storageLocations = [...data.storageLocations].map((entry) => {
                let formattedEntry = { ...entry };
                return formattedEntry;
            });

            await makeEndpointRequest(`UpdateGELSession`, {
                session: {
                    storageLocations: storageLocations,
                    processesDependencies: data.processesDependencies,
                    companyInfo: data.companyInfo,
                    stepNumber: Object.keys(mappedSteps).indexOf(step),
                    checkedData: data.checkedData,
                    _meta: data._meta,
                },
            });

            // Syncs data to hubspot
            makeEndpointRequest('GetHSDataFromSession');
        } catch (err) {
            await logError(`gel.api.updateSession`, err);
        }
    };

    const loadFlags = async () => {
        try {
            if (window.isCypressTestEnvironment) return true; // @Bugfix: This is affecting cypress tests when running in bulk.

            const missingSubProcessors = await checkUserFlag('missingSubProcessors', false);
            const showDeleteInsteadOfArchive = await checkUserFlag(`onboarding-flow-show-delete-instead-of-archive`, false);

            setFlags({
                ...flags,
                missingSubProcessors,
                showDeleteInsteadOfArchive,
            });
        } catch (err) {
            await logError(`gel.api.loadFlags`, err);
        }
    };

    useEffect(() => {
        // Is needed for scenarios outside functions.
        window.gelFlags = flags;
    }, [flags]);

    const showPromptOfAddedTools = (tools) => {
        try {
            // Get the groups
            const groups = groupSuggestedTools(tools);

            // No group to show..
            if (groups.length < 1) return false;

            trackUserInteraction(`New tools added`, { tools: tools.map((c) => c.label) });

            const content = (
                <React.Fragment>
                    <div className="toolsAdded" style={{ width: '100%' }}>
                        <b style={{ marginBottom: 16, display: 'block' }}>New third party tools added:</b>
                        {groups.map((group, gix) => (
                            <div style={{ width: '100%', width: 380, marginBottom: groups.length > 1 ? 8 : 0 }} key={gix}>
                                {groups.length > 1 && (
                                    <div className="heading" style={{ width: '100%', textAlign: 'left', marginBottom: 6 }}>
                                        {group.label}
                                    </div>
                                )}
                                <ul style={{ textAlign: 'left', paddingLeft: 30, marginTop: 6, marginBottom: 6 }}>
                                    {group.entries.map((c, index) => (
                                        <li key={index}>{c.label}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        <div className="info-block">
                            These tools have been found. Please add them to the inventory if they are used and personal data is processed.
                        </div>
                    </div>
                </React.Fragment>
            );

            window.showAlert(`Success`, content, `info`, [
                {
                    text: 'OK',
                    onClick: async ({ dismissAlert }) => {
                        trackUserInteraction(`Selected "OK"`);
                        dismissAlert();
                    },
                },
            ]);
        } catch (err) {
            logError(`gel.showPromptOfAddedTools`, err);
        }
    };

    const loadSuggestedTools = async (suggestedTools) => {
        try {
            // The current storage locations
            let currentLocations = [...dataRef.current.storageLocations];
            let newStorageLocations = [];

            // Needed processes
            const processes = [];

            // Fetch processes
            suggestedTools.forEach((tool) => {
                tool.data.processes.forEach((proc) => {
                    if (processes.includes(proc)) return;
                    processes.push(proc);
                });
            });

            // Get the recommendations for the tools possible..
            let recommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: suggestedTools.map((x) => x.data.label),
                processes,
            });

            // Iterate through each location...
            suggestedTools.forEach((item) => {
                // Already added to our storage locations
                const alreadyExist = currentLocations.find((x) => x.label === item.data.label);
                if (alreadyExist) return false; // no duplicates

                // Map this tool to a suggestion.
                const formattedItem = createStorageLocationWithRecommendations({
                    toolName: item.data.label,
                    specificRecommendations: recommendations,
                    vitalRecommendations,
                    targetAudience: data.companyInfo.targetAudience,
                    processes: item.data.processes || [],
                    currentStorageLocations: currentLocations,
                    individualsReferring: data.companyInfo.individualsReferring,
                });

                // Meta..
                const suggestedMeta = {
                    source: item.source,
                    _id: item._id,
                };

                // The item had recommendations and was successfully mapped.
                if (formattedItem !== null) {
                    // Push it to the array
                    newStorageLocations.push({
                        ...formattedItem,
                        isSubProcessor: true,
                        subProcessorMeasures: item.data.subProcessorMeasures,
                        origin: 'suggestions',
                        // A simple field that will not make it to the data inventory or session. This field is used so front-end
                        // Knows to display a nice badge or something to let us know this is a suggested tool.
                        tempData: {
                            suggestedTool: suggestedMeta,
                        },
                    });
                }

                // The item has no recommendations
                if (!formattedItem) {
                    newStorageLocations.push({
                        ...createCustomStorageLocation(item.data.label),
                        isSubProcessor: true,
                        subProcessorMeasures: item.data.subProcessorMeasures,
                        origin: 'suggestions',
                        // A simple field that will not make it to the data inventory or session. This field is used so front-end
                        // Knows to display a nice badge or something to let us know this is a suggested tool.
                        tempData: {
                            suggestedTool: suggestedMeta,
                        },
                    });
                }
            });

            // Is important to do it like this to make sure that the array won't be broken in case other functions are adding new storage locations.
            setData((currentState) => {
                return {
                    ...currentState,
                    storageLocations: [...currentState.storageLocations, ...newStorageLocations],
                };
            });

            return newStorageLocations;
        } catch (err) {
            await logError(`gel.loadSuggestedTools`, err);
            return [];
        }
    };

    const fetchSuggestedTools = async () => {
        try {
            // If not edit
            if (isEditingSession === false) return [];

            // Get company suggested tools
            const res = await makeEndpointRequest(`GetCompanySuggestedTools`);

            // If there are more than 1 tool suggested
            if (res.length > 0) {
                const tools = await loadSuggestedTools(res);
                setSuggestedTools(res);
                return tools;
            }

            return [];
        } catch (err) {
            await logError(`gel.fetchSuggestedTools`, err);
            return [];
        }
    };

    const loadDataProcessingAgreements = async () => {
        try {
            const res = await makeEndpointRequest('GetListDPAs');
            setDataProcessingAgreements(res);
        } catch (err) {
            await logError(`gel.api.loadDataProcessingAgreements`, err);
        }
    };

    const loadVitalRecommendations = async () => {
        try {
            const data = await makeEndpointRequest('GetVitalRecommendations');
            setVitalRecommendations(data);
        } catch (err) {
            await logError(`gel.api.loadVitalRecommendations`, err);
            throw err;
        }
    };

    const autoCheckMatrix = () => {
        try {
            data.storageLocations.forEach((location, locationIndex) => {
                const processes = location.processes;
                const elements = location.elements;
                const individuals = location.individuals;
                let matrixMap = location.matrixMap;

                if (processes.length === 1 && elements.length === 1 && individuals.length === 1 && matrixMap.length === 0) {
                    matrixMap.push({
                        process: processes[0].label,
                        element: elements[0].label,
                        individual: individuals[0].label,
                    });
                    updateData(`storageLocations[${locationIndex}].matrixMap`, matrixMap);
                }
            });
        } catch (err) {
            logError(`gel.api.autoCheckMatrix`);
        }
    };

    const filterCheckedData = async () => {
        try {
            let checkedData = { ...data.checkedData };
            let updatedStorageLocations = [...data.storageLocations];

            // Filter out the checked data that is not used anymore and was recommendations.
            checkedData.elements = checkedData.elements.filter((e) => {
                if (e.type === 'manual') return true;

                const exists = updatedStorageLocations.find((s) => s.elements.find((ee) => ee.label === e.label));

                return exists ? true : false;
            });

            checkedData.individuals = checkedData.individuals.filter((e) => {
                if (e.type === 'manual') return true;

                const exists = updatedStorageLocations.find((s) => s.individuals.find((ee) => ee.label === e.label));

                return exists ? true : false;
            });

            // Filter out any elements from storage Locations that are not "checked"
            data.storageLocations.forEach((storage, index) => {
                updatedStorageLocations[index].elements = storage.elements.filter((elmIterated) => {
                    if (checkedData.elements.find((elmCheck) => elmCheck.label === elmIterated.label)) return true;
                    return false;
                });
                updatedStorageLocations[index].individuals = storage.individuals.filter((indivIterated) => {
                    if (!sensitiveIndividualsList.find((c) => c.label === indivIterated.label)) return true;
                    if (checkedData.individuals.find((elmCheck) => elmCheck.label === indivIterated.label)) return true;
                    return false;
                });

                // After both filters are applied we need to filter the matrix map of any invalid entry
                // Process never gets removed but elements and individuals yes.
                updatedStorageLocations[index].matrixMap = storage.matrixMap.filter((m) => {
                    if (!storage.elements.find((e) => e.label === m.element)) return false;
                    if (!storage.individuals.find((e) => e.label === m.individual)) return false;
                    return true;
                });
            });

            updateData('storageLocations', updatedStorageLocations);
            updateData('checkedData', checkedData);
        } catch (err) {
            await logError(`gel.api.filterCheckedData`, err);
        }
    };

    const refreshCheckedData = () => {
        try {
            // Re do it to make sure it will be accurate.
            const currentChecked = data.checkedData;

            data.storageLocations.forEach((location) => {
                if (location.archived === true) return false;
                location.elements.forEach((element) => {
                    if (currentChecked.elements.find((e) => e.label === element.label)) return;
                    currentChecked.elements.push({
                        label: element.label,
                        sensitive: element.sensitive,
                        type: 'recommended',
                    });
                });
                location.individuals.forEach((individual) => {
                    if (!sensitiveIndividualsList.find((c) => c.label === individual.label)) return false;
                    if (currentChecked.individuals.find((i) => i.label === individual.label)) return;
                    currentChecked.individuals.push({
                        label: individual.label,
                        type: 'recommended',
                    });
                });
            });

            updateData('checkedData', currentChecked);
            return currentChecked;
        } catch (err) {
            logError(`gel.api.refreshCheckedData`, err);

            return data.checkedData; // Return current data in case of error
        }
    };

    const deleteIndividualDependencies = async (label) => {
        try {
            setData((currentState) => {
                let storageLocations = [...currentState.storageLocations];

                storageLocations.forEach((storage, storageIndex) => {
                    // Delete it from the storage of individuals
                    storage.individuals.forEach((individual, individualIndex) => {
                        if (individual.label !== label) return false; // Not of interest

                        // Remove it from the array.
                        storageLocations[storageIndex].individuals.splice(individualIndex, 1);
                    });

                    // Delete it from the matrix map
                    storage.matrixMap.forEach((matrice, matriceIndex) => {
                        if (matrice.individual !== label) return; // Not of interest.

                        // Remove it from the array
                        storageLocations[storageIndex].matrixMap.splice(matriceIndex, 1);
                    });
                });

                return { ...currentState, storageLocations };
            });
        } catch (err) {
            await logError(`gel.deleteIndividualDependencies`, err);
        }
    };

    const updateStageAccordingly = () => {
        try {
            const biggestStep = data._meta.biggestStep;
            const slug = paramStage;
            const matchStep = mapSlugsToSteps[slug];

            if (!slug || !matchStep) {
                updateLink(''); // empty.
                return false;
            }

            if (!isEditingSession) {
                // if the user hasn't reached here yet.
                const isThisStepMoreAdvanced = isStepMoreAdvanced(biggestStep, matchStep);
                if (!isThisStepMoreAdvanced) return false; // He hasn't got there yet so he shouldn't go there.
            }

            _setStep(matchStep); // All validation passed so therefore the user should be allowed to move there.
        } catch (err) {
            logError(`gel.api.updateStageAccordingly`);
        }
    };

    const startWebsiteScan = async () => {
        try {
            setScanning(true);
            let startedScanAt = new Date();

            // Get API..
            const res = await makeEndpointRequest(`ScanWebsiteDomain`, {
                domains: data.companyInfo.companyDomains,
            });

            // If already cancelled..
            if (scanCancelledRef.current === true) return false;

            const {
                locations: mLocations,
                addedSuccessfully,
                noRecommendations,
            } = await loadScansIntoStorageLocations({
                scannedTools: res,
                sessionData: dataRef.current,
                vitalRecommendations,
            });

            // it makes sense to have it there. Cause if you are on any other page, it's not needed.
            // @Explanation for the var below: We cannot know when this alert may show up. As of now it's failing the testing scripts when this shows up too early.

            if (!window.isCypressTestEnvironment && addedSuccessfully.length > 0) {
                setScannerResults({
                    toolsScanned: addedSuccessfully,
                });
            }

            // Save..
            await updateData(`storageLocations`, mLocations);
            await updateData(`_meta.domainLastScannedAt`, startedScanAt);

            // Stop scanning
            setScanning(false);

            // Track..
            var difference = (new Date() - startedScanAt) / 1000;
            await trackUserInteraction(`Scanned domains for tools`, {
                results: res.map((i) => i.label).join(', '),
                withoutRecommendations: noRecommendations.join(', '),
                timeElapsed: difference,
                timeFormat: `seconds`,
                domains: data.companyInfo.companyDomains,
            });
        } catch (err) {
            await trackUserInteraction(`Having Difficulties`, { reason: `Failed to scan his website ` });
            await logError(`WEBSITE_SCAN`, err);
            setScanning(false);
        }
    };

    const cancelScanning = async () => {
        trackUserInteraction(`Selected "Cancel Scanning"`);
        setScanCancelled(true);
        setScanning(false);
    };

    const updateSessionFunc = () => {
        if (isEditingSession !== true) {
            if (updateDataTimer !== null) {
                clearTimeout(updateDataTimer);
            }
            updateDataTimer = setTimeout(() => {
                updateSession();
                updateDataTimer = null;
            }, 5000);
        }
    };

    const loadCompanyScans = async () => {
        try {
            // Also loading the scans cause we need them.
            const { scans } = await makeEndpointRequest(`GetCompanyScans`);
            if (scans.length < 1) return [];

            // Save results..
            setScansReviewed(scans);
            setScanning(true);

            await trackUserInteraction(`Loaded scanned tools found`, {
                scans: scans.map((x) => x.data.label),
            });

            // Added them in..
            const { locations, addedSuccessfully } = await loadScansIntoStorageLocations({
                scannedTools: scans.map((x) => x.data),
                sessionData: dataRef.current,
                vitalRecommendations,
            });

            // Updating locations
            setData((currentState) => {
                return { ...currentState, storageLocations: locations };
            });

            setScanning(false);

            return addedSuccessfully;
        } catch (err) {
            await logError(`api.loadCompanyScans`, err);
            return [];
        }
    };

    const generateCompanyDescription = async () => {
        try {
            // Already generating, waiting
            if (generatingCompanyDescription) return false;

            // // eslint-disable-next-line
            // if (window.location.href.includes(`localhost:3000`))
            //     return console.warn(`[AI] Generate company description has been prevented due to Development mode.`);

            // Mark..
            setGeneratingCompanyDescription(true);

            // Make endpoint..
            const response = await makeEndpointRequest(`GenerateCompanyDescription`, {
                domains: data.companyInfo.companyDomains,
            });

            trackUserInteraction(`Generated Company Description`, {
                response,
                from: 'onboardingFlow',
            });

            // @Quickfix: When we just re-generated the company description, let's reset the company activities date so the user can regenerate it.
            if (data._meta.companyActivitiesGenerated) {
                updateData(`_meta.companyActivitiesLastGeneratedAt`, data._meta.companyDescriptionLastGeneratedAt);
            }

            updateData(`_meta.companyDescriptionGenerated`, true);
            updateData(`_meta.companyDescriptionLastGeneratedAt`, new Date());

            // New uses
            let currentNumberOfUsages = data._meta.companyDescriptionGeneratedUses;

            // Reset
            if (currentNumberOfUsages === 3) {
                currentNumberOfUsages = 1;
            } else {
                currentNumberOfUsages++;
            }

            updateData(`_meta.companyDescriptionGeneratedUses`, currentNumberOfUsages);

            // There's something to work with.
            if (response.length > 1) {
                updateData(`companyInfo.companyDescription`, response);
            }

            // Mark false..
            setGeneratingCompanyDescription(false);
        } catch (err) {
            await logError(`gel.api.generateCompanyDescription`, err);
            setGeneratingCompanyDescription(false);
        }
    };

    const generateCompanyActivities = async () => {
        try {
            // Already generating, waiting
            if (generatingCompanyActivities) return false;

            // // eslint-disable-next-line
            // if (window.location.href.includes(`localhost:3000`))
            //     return console.warn(`[AI] Generate company activities has been prevented due to Development mode.`);

            // Mark..
            setGeneratingCompanyActivities(true);

            // Make endpoint..
            const response = await makeEndpointRequest(`GenerateCompanyActivities`, {
                companyDescription: data.companyInfo.companyDescription,
            });

            trackUserInteraction(`Generated Company Activities`, {
                companyDescription: data.companyInfo.companyDescription,
                response,
                from: 'onboardingFlow',
            });

            // Update this..
            updateData(`_meta.companyActivitiesGenerated`, true);
            updateData(`_meta.companyActivitiesLastGeneratedAt`, new Date());

            // New uses
            let currentNumberOfUsages = data._meta.companyActivitiesGeneratedUses;

            // Reset
            if (currentNumberOfUsages === 3) {
                currentNumberOfUsages = 1;
            } else {
                currentNumberOfUsages++;
            }

            updateData(`_meta.companyActivitiesGeneratedUses`, currentNumberOfUsages);

            // There's something to work with.
            if (response.length > 1) {
                // Remove the old recommendations
                const notRecommendations = data.companyInfo.services.filter((c) => c.origin !== 'recommendations');

                // Save the new ones and the old manual ones.
                updateData(`companyInfo.services`, [...response, ...notRecommendations]);
            }

            // Mark false..
            setGeneratingCompanyActivities(false);
        } catch (err) {
            await logError(`gel.api.generateCompanyActivities`, err);
            setGeneratingCompanyActivities(false);
        }
    };

    const setIndividualIsChildren = (label, value) => {
        setData((currentState) => {
            let newState = { ...currentState };

            // Get the index of the referring role
            const index = newState.companyInfo.individualsReferring.findIndex((c) => c.label === label);
            if (index === -1) return newState;

            // Update value
            newState.companyInfo.individualsReferring[index].isChildren = value;

            return newState;
        });
    };

    const loadScansAsynchronously = async () => {
        try {
            // @Reminder: Is important to have them one by one and not both at same time to make sure we're not adding duplicated data.

            // First we load the company scans...
            const firstGroup = await loadCompanyScans();

            // Now we load suggestions..
            const secondGroup = await fetchSuggestedTools();

            // Show prompt..
            showPromptOfAddedTools([
                ...secondGroup,
                ...firstGroup.map((c) => ({
                    ...c,
                    tempData: {
                        scannerResult: true,
                    },
                })),
            ]);
        } catch (err) {
            await logError(`scans.loadScans`);
        }
    };

    const updateIndividualsReferring = async () => {
        try {
            // Can't do it now.
            if (vitalRecommendations.individuals.length < 1) return false;

            setData((currentState) => {
                // Get current state.
                let newState = { ...currentState };

                // Go through all possible individuals
                for (const storage of newState.storageLocations) {
                    for (const individual of storage.individuals) {
                        // Is already referred
                        const isInArray = newState.companyInfo.individualsReferring.find((c) => c.label === individual.label);
                        if (isInArray) continue;

                        // Let's get the recommendations then.
                        const rec = vitalRecommendations.individuals.find((c) => c.label === individual.label);

                        // Add it to array.
                        newState.companyInfo.individualsReferring.push(
                            createReferringRole({
                                label: individual.label,
                                referringRole: rec ? getIndividualTypesIdFromLabel(rec.type) : 'others', // Default is others.
                                isChildren: isChildrenRole(individual.label),
                            }),
                        );
                    }
                }

                return { ...newState };
            });
        } catch (err) {
            await logError(`gel.updateIndividualsReferring`, err);
        }
    };

    /**
     * This function will add an individual to a storage location.
     * @param {*} storageLocationId
     * @param {*} label
     */

    const addIndividualToStorageLocation = (storageLocationId, individual, matrices) => {
        setData((currentState) => {
            // Format the new data
            let newState = { ...currentState };

            const storageIndex = newState.storageLocations.findIndex((c) => c._storageLocationId === storageLocationId);
            if (storageIndex === -1) return false;

            // Add to storage locations
            newState.storageLocations[storageIndex].individuals.push(
                createIndividual({
                    label: individual.label,
                }),
            );

            // If we also need to add matrices
            if (matrices) {
                newState.storageLocations[storageIndex].matrixMap = [...newState.storageLocations[storageIndex].matrixMap, ...matrices];
            }

            return newState;
        });
    };

    // @Reminder: This script will find the most common processes to use with these roles (prospects, customers)
    // Then it will add the data subjects added everywhere one of common processes are found.

    const addMissingReferringRolesToStorageLocations = async ({ individualsReferences }) => {
        try {
            // Iterate through all the storage locations
            for (const storageLocation of data.storageLocations) {
                // Get the recommendations for this storage location (if any)
                const rr = await getStorageLocationMissingReferringRoles({
                    individualsReferences,
                    vitalRecommendations,
                    storageLocation: {
                        processes: storageLocation.processes,
                        elements: storageLocation.elements,
                        individuals: storageLocation.individuals,
                    },
                });

                // No recommendation.
                if (rr.length < 1) continue;

                // We will now add all the recommended individuals and their matrix changes
                rr.forEach((rec) => {
                    // Add this data subject to this storage location.
                    addIndividualToStorageLocation(storageLocation._storageLocationId, rec.individual, rec.matrices);
                });
            }
        } catch (err) {
            await logError(`addMissingReferringRolesToStorageLocations`, err, { individualsReferences });
            return false;
        }
    };

    /**
     * Making sure the process children data field is updated accordingly.
     */

    const onIndividualsReferringChanged = async () => {
        try {
            // Can't do it now. too soon.
            if (vitalRecommendations.individuals.length < 1) return false;

            // Do we have children data..
            const existsChildren = data.companyInfo.individualsReferring.find((c) => c.isChildren);

            // We must make sure this is now set to true.
            if (existsChildren && !data.companyInfo.processChildrenData) {
                // Set it to true if they do process..
                updateData('companyInfo.processChildrenData', true);
            }
        } catch (err) {
            await logError(`onIndividualsReferringChanged`, err);
        }
    };

    useEffect(() => {
        onIndividualsReferringChanged();
        // eslint-disable-next-line
    }, [data.companyInfo.individualsReferring]);

    useEffect(() => {
        // If we submitted.
        if (submittedRef.current === true) return false;

        // Update data..
        updateStageAccordingly();
        //eslint-disable-next-line
    }, [paramStage]);

    useEffect(() => {
        // Call functions..
        setProgress(calculateProgress(step));
        updateSessionFunc();

        // Update slug..
        if (getMapStepsToSlugs()[step] && submittedRef.current === false) {
            updateLink(getMapStepsToSlugs()[step]);
        }

        // eslint-disable-next-line
    }, [step]);

    useEffect(() => {
        autoCheckMatrix();
        updateSessionFunc();

        // // eslint-disable-next-line
        // console.log(data.storageLocations);

        return () => {
            if (updateDataTimer !== null) {
                clearTimeout(updateDataTimer);
            }
        };

        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        // Update individuals referring.
        updateIndividualsReferring();

        // eslint-disable-next-line
    }, [data.storageLocations]);

    useEffect(() => {
        loadFlags();
        loadVitalRecommendations();

        // If is not editing
        if (isEditingSession) {
            loadDataProcessingAgreements();
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (loadedRecommendations === false && vitalRecommendations.individuals.length > 0) {
            // Once we have the recommendations loaded...
            loadScansAsynchronously();

            // Set this..
            setLoadedRecommendations(true);
        }
        // eslint-disable-next-line
    }, [vitalRecommendations]);

    const PassedProps = {
        // Re-usable functions
        loadDataProcessingAgreements,
        generateCompanyDescription,
        generateCompanyActivities,
        // Scanning
        startWebsiteScan,
        cancelScanning,
        // Individuals
        setIndividualIsChildren,
        addMissingReferringRolesToStorageLocations,
        // Checked data
        filterCheckedData,
        refreshCheckedData,
        deleteIndividualDependencies,
        showPromptOfAddedTools,
    };

    return <Context.Provider value={PassedProps}>{props.children}</Context.Provider>;
};

export default Component;
