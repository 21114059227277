import React from 'react';

// Material UI
import AutoComplete from '../../../../../../../thirdParties/components/editPanel/components/autocomplete';

// Dependencies
import { addEllipsisIfExceedsMaxLength, logError, trackUserInteraction } from '../../../../../../../../../utils/helpers';
import { PanelContext } from '../../../../..';
import { makeEndpointRequest } from '../../../../../../../../../utils/endpoints';

const Component = (props) => {
    const { dataInventory, setDataInventory, setData } = PanelContext();

    const addElement = async (elementAdded) => {
        try {
            // Does this elements exist in the data inventory?
            const inventory = dataInventory.elements.find((c) => {
                // If is already existing in our db..
                if (elementAdded._id && c._id === elementAdded._id) return true;

                // If not at least match by label
                return c.label === elementAdded.label;
            });

            // Figure out what id to use..
            let idAllocated = inventory ? inventory._id : null;

            // Does it exist in the data inventory already?
            if (!inventory) {
                // Create element first.
                const { _id } = await makeEndpointRequest(`CreateElement`, {
                    payload: {
                        label: elementAdded.label,
                        sensitive: elementAdded.sensitive || false, // custom element are not sensible.
                    },
                });

                // Get data inventory again
                const dataInventoryUpdated = await makeEndpointRequest(`GetDataInventory`, {
                    onlyPublishedStorageLocations: false,
                    bundleStorageLocations: false,
                });

                // Save it
                setDataInventory(dataInventoryUpdated);

                idAllocated = _id;
            }

            // Add this element to our tool
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the current tool.
                const toolIndex = newState.tools.findIndex((c) => c._storageLocationId === props.tool._id);
                if (toolIndex === -1) return newState;

                // Get the index of current individual
                const individualIndex = newState.tools[toolIndex].individuals.findIndex((c) => c._individualId === props.individual._id);
                if (individualIndex === -1) return newState;

                // Add element
                newState.tools[toolIndex].individuals[individualIndex].elements.push(idAllocated);

                return newState;
            });

            trackUserInteraction(`Added data category to Process`, {
                toolLabel: props.tool.label,
                element: elementAdded.label,
                individualLabel: props.individual.label,
            });
        } catch (err) {
            logError(`module.processes.panel.addElement`, err);
            return false;
        }
    };

    const removeElement = async (elementRemoved) => {
        try {
            // Add this element to our tool
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the current tool.
                const toolIndex = newState.tools.findIndex((c) => c._storageLocationId === props.tool._id);
                if (toolIndex === -1) return newState;

                // Get the index of current individual
                const individualIndex = newState.tools[toolIndex].individuals.findIndex((c) => c._individualId === props.individual._id);
                if (individualIndex === -1) return newState;

                // Add element
                newState.tools[toolIndex].individuals[individualIndex].elements = newState.tools[toolIndex].individuals[
                    individualIndex
                ].elements.filter((id) => id !== elementRemoved._id);

                return newState;
            });

            trackUserInteraction(`Removed data category from Process`, {
                toolLabel: props.tool.label,
                element: elementRemoved.label,
                individualLabel: props.individual.label,
            });
        } catch (err) {
            await logError(`module.processes.panel.removeElement`, err);
            return false;
        }
    };

    return (
        <React.Fragment>
            <div className="--component-entry">
                <div className="individual">
                    <div className={`label ${props.elements.length < 1 && 'invalid'}`}>
                        {addEllipsisIfExceedsMaxLength(props.individual.label, 20)}
                    </div>
                    {props.elements.length < 1 && (
                        <div className="warning">
                            <i className="icon fa-solid fa-triangle-exclamation"></i>
                        </div>
                    )}
                </div>
                <div className="elements">
                    <AutoComplete
                        // The value and options
                        value={props.elements.map((c) => ({ _id: c._id, label: c.data.label }))}
                        options={props.elementsOptions}
                        // Props to the autocomplete..
                        groupCategories={true}
                        dataCy="elements-autocomplete"
                        subjectsView={false}
                        placeholder={`What categories of data are collected from this data subject?`}
                        // Callbacks when data is changed
                        onEntityAdded={addElement}
                        onEntityRemoved={removeElement}
                        // Props
                        notCreatable={false}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
