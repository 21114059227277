import React from 'react';

import PanelComponent from '../../panel';

// Context
import { ModuleState } from '../..';
import { FunctionsContext } from './components/functions';

const Component = () => {
    const { data: dataInventory, setData, panelData, companyData, toolsRecommended, vitalRecommendations } = ModuleState();
    const { panelController, getInitialData, closePanel } = FunctionsContext();
    const { createIndividual, deleteIndividual, updateIndividual } = FunctionsContext();

    // We don't use the panel right now
    if (panelData.method === null) return null;

    return (
        <React.Fragment>
            <PanelComponent
                isEditing={panelData.method === 'edit' ? true : false}
                // The initial data for this process
                initialData={getInitialData()}
                // Data Inventory
                dataInventory={dataInventory}
                setDataInventory={setData}
                // Company data
                companyData={companyData}
                // Recommendations
                toolsRecommendations={toolsRecommended}
                vitalRecommendations={vitalRecommendations}
                // Ref to close it
                innerRef={panelController}
                // Functions
                functions={{
                    closePanel: closePanel,
                    create: createIndividual,
                    update: updateIndividual,
                    delete: deleteIndividual,
                }}
            />
        </React.Fragment>
    );
};

export default Component;
