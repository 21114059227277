import React from 'react';
import { Button, TextField } from '@mui/material';

// Dependencies
import { getValidationPropFields } from '../../../../../../utils/helpers';

// Context
import { ViewContext } from '..';

const Component = ({ value, onChange, onDelete, showDelete, currentIndex }) => {
    const { validationResults } = ViewContext();

    return (
        <React.Fragment>
            <div className="listed-domain-entry">
                <TextField
                    autoFocus
                    fullWidth
                    variant="outlined"
                    placeholder="www.domain.com"
                    className="input-website"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    {...getValidationPropFields(validationResults, value.length > 0, `companyDomains[${currentIndex}]`)}
                />
                {showDelete && (
                    <Button className="remove-domain-btn" variant="standard" color="primary" size="small" onClick={() => onDelete()}>
                        <i className="icon fa-solid fa-trash"></i>
                    </Button>
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
