import React from 'react';

// Context
import { FunctionsContext } from '../components/functions';

// Components
import EditPanel from '../../../../editPanel/index';

// Dependencies
import { getMatriceEntriesData, getToolRecommendations } from '../utils/helpers';

const Component = (props) => {
    const { data, flags, editingLocId } = props;
    const { toolsRecommended, vitalRecommendations, companyData } = props;

    // Functions required..
    const { updateStorageLocationData, deleteStorageLocation, panelController, getInitialData } = FunctionsContext();
    const { createProcess, createElement, createIndividual, onPanelClose, cleanupInventory } = FunctionsContext();

    // If we're not editing a location we don't need this.
    if (editingLocId === null) return null;

    // Get the initial data
    const initialData = getInitialData();
    if (!initialData) return null;

    // Get the matrix table
    const matriceData = getMatriceEntriesData(editingLocId, data.matrices);

    // Get the recommendations for this specific tool
    const locationRecommendations = getToolRecommendations(initialData.label, toolsRecommended);

    return (
        <EditPanel
            // This ref helps us control the panel from outside it.
            innerRef={panelController}
            // Classes
            className={`shareable-components data-inventory edit-popout-model`}
            // Simple data about tool (label, processes, etc.)
            data={initialData}
            // When we stop editing.
            onClose={(bool) => onPanelClose(bool)}
            // We need to know recommendations about the tool itself sometimes.
            recommendations={locationRecommendations || null}
            // Vital recommended needed to list processes, elements etc.
            vitalRecommendations={vitalRecommendations}
            // Flags needed by the edit panel
            flags={flags}
            // Company data also required to know their individuals types
            companyData={companyData}
            // Matrices of this tool
            matrices={matriceData}
            // The entire data inventory is needed.
            dataInventory={{
                matrices: data.matrices,
                storageLocations: data.storageLocations,
                individuals: data.individuals,
                elements: data.elements,
                processes: data.processes,
            }}
            functions={{
                updateData: updateStorageLocationData,
                delete: deleteStorageLocation,
                // Processes
                createProcess,
                // Elements
                createElement,
                // Individuals
                createIndividual,
                // Cleanup
                cleanupInventory,
            }}
        />
    );
};

export default Component;
