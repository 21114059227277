import React from 'react';

// Context
import { SetIndividualsContext } from '..';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// Components
import AutoComplete from '../../../../../../../../../../thirdParties/components/editPanel/components/autocomplete';

const Component = () => {
    const { enabled, options, setEnabled, process, individuals, addIndividual, removeIndividual } = SetIndividualsContext();

    const closeModal = () => {
        setEnabled(false);
    };

    if (enabled === false) return null;

    return (
        <React.Fragment>
            <Dialog open={true} maxWidth="md" fullWidth>
                <DialogTitle data-cy="dialog-title">
                    <Typography variant="h6">Data Subjects for {process.label}</Typography>
                    <Typography variant="subtitle2">
                        Who are the data subjects involved in the data processing for the purpose of {process.label}?
                    </Typography>
                </DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <AutoComplete
                        // The value and options
                        value={individuals.map((c) => ({ _id: c._id, label: c.data.label }))}
                        options={options}
                        // Props to the autocomplete..
                        groupCategories={true}
                        dataCy="individuals-autocomplete"
                        placeholder={`Select data subjects here..`}
                        // Callbacks when data is changed
                        onEntityAdded={addIndividual}
                        onEntityRemoved={removeIndividual}
                        // Props
                        notCreatable={false}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} variant="outlined" data-cy="dialog-done-button">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
