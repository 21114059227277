import React, { useContext, createContext, useState, useEffect } from 'react';

// Components
import Container from '../../layout/container';
import Header from './components/header';
import SubHeader from './components/subHeader';
import StorageLocations from './components/storageLocations';

// Others
import API from './utils/api';
import ContextFunctions from './contexts/functions';

// Dependencies
import { makeEndpointRequest, makeLocalEndpointRequest } from '../../../utils/endpoints';
import { checkUserFlag, getFromUrlQueryString, logError, trackUserInteraction, useStateRef } from '../../../utils/helpers';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { AuthStore } from '../../../utils/context/authStore';

// Context
const Context = createContext({});
export const ModuleState = () => useContext(Context);

const Component = () => {
    // Data required to function
    const [data, setData, dataRef] = useStateRef(null);
    const [loading, setLoading] = useState(true);
    const [editingLocId, setEditingLocId, editingLocIdRef] = useStateRef(getFromUrlQueryString('editingStorageLocationId') || null);
    const [companyData, setCompanyData] = useState(null);
    const [sessionData, setSessionData] = useState(null);
    const [toolsFound, setToolsFound] = useState([]);
    const [loadingToolFound, setLoadingToolFound] = useState(false);
    const { globalFlags } = AuthStore();

    const [options, setOptions] = useState({
        showArchived: getFromUrlQueryString('showArchived') ? true : false,
        pickingTools: false,
        creatingCustomTool: false,
        filters: [],
    });

    // Recommendations
    const [storageLocationsOptions, setStorageLocationsOptions] = useState([]);
    const [toolsRecommended, setToolsRecommended] = useState([]);
    const [vitalRecommendations, setVitalRecommendations] = useState({
        elements: [],
        individuals: [],
        processes: [],
        sensitiveElements: [],
    });
    const [dataProcessingAgreements, setDataProcessingAgreements] = useState([]);

    const [flags, setFlags] = useState({});

    const loadData = async () => {
        try {
            setLoading(true);
            // Get data inventory
            const res = await makeEndpointRequest(`GetDataInventory`, {
                onlyPublishedStorageLocations: false,
                bundleStorageLocations: false,
            });
            setData(res);
            setLoading(false);
            trackUserInteraction(`Tools and Third Parties`);
        } catch (err) {
            await logError(`thirdParties.loadData`, err);
            setLoading(false);
            setData(null);
            trackUserInteraction(`Having Difficulties`, { reason: 'Failed to load tools and third parties' });
        }
    };

    const refreshDataInventory = async () => {
        try {
            const res = await makeEndpointRequest(`GetDataInventory`, {
                onlyPublishedStorageLocations: false,
                bundleStorageLocations: false,
            });
            setData(res);

            return res;
        } catch (err) {
            await logError(`thirdParties.refreshDataInventory`, err);
        }
    };

    const loadDataProcessingAgreements = async () => {
        try {
            const res = await makeEndpointRequest('GetListDPAs');
            setDataProcessingAgreements(res);
        } catch (err) {
            await logError(`thirdParties.loadDataProcessingAgreements`, err);
        }
    };

    const loadToolsFound = async () => {
        try {
            // If they don't have this flag we don't load company scans.
            if (!globalFlags.gelDecoupling) return false;

            // Get company suggested tools
            const res = await makeEndpointRequest(`GetCompanyScans`);

            setToolsFound(res.scans);
        } catch (err) {
            await logError(`thirdParties.loadSuggestedTools`, err);
            return false;
        }
    };

    const onToolFoundSelected = async (_id) => {
        try {
            if (loadingToolFound) return false;

            // Mark is as loading a tool found
            setLoadingToolFound(true);

            // Get tool
            const match = toolsFound.find((d) => d._id === _id);
            if (!match) throw new Error(`Failed to find it in the local state.`);

            // Create tool recommended.
            const response = await makeLocalEndpointRequest(`/api/v1/storageLocations/pickToolFromRecommendations`, {
                label: match.data.label,
                options: {
                    syncToSession: true,
                    responseType: 'inventory',
                    // We need to send this over to the API to trigger an event that will mark the scanned tools as reviewed.
                    markScannedToolAsReviewed: match,
                },
            });

            // Tool created
            const toolCreated = response.storageLocations.find((d) => d.label === match.data.label);

            // Update data inventory.
            setData(response);

            // Set editing to open it up
            if (toolCreated) {
                setEditingLocId(toolCreated._storageLocationId);
            }

            // Remove it from tools found
            setToolsFound((currentState) => currentState.filter((c) => c._id !== _id));

            // Finished
            setLoadingToolFound(false);
            // Finding the tool
        } catch (err) {
            await logError(`thirdParties.onToolFoundSelected`, err);
            setLoadingToolFound(false);
            return false;
        }
    };

    const downloadRopa = async () => {
        try {
            // Check the v2 flag
            const v2Flag = await checkUserFlag('ropa-v2', false);

            await trackUserInteraction(`Selected "Download ROPA"`, {
                v2FlagEnabled: v2Flag,
            });

            // download it..
            const res = await makeLocalEndpointRequest(`/api/documents/ropa?v2=${v2Flag}`, {}, 'GET', null, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            let fileName = `ROPA - ${companyData.companyLegalName} - ${moment().format('DD-MM-yyyy')}.csv`;

            // If is a cypress test
            if (window.isCypressTestEnvironment) {
                fileName = 'ROPA.csv';
            }

            link.setAttribute('download', fileName); // set the file name
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            await logError(`DOWNLOAD_ROPA`, err);
            trackUserInteraction(`Having Difficulties`, { reason: `failed to download ropa` });
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    useEffect(() => {
        // Load data..
        loadData();

        // Load DPAs
        loadDataProcessingAgreements();

        // Load tools found by the scanner
        loadToolsFound();

        // eslint-disable-next-line
    }, []);

    const ContextProps = {
        // Options
        options,
        setOptions,
        // loading
        loading,
        setLoading,
        // Data
        data,
        dataRef,
        setData,
        // Recommendations
        vitalRecommendations,
        setVitalRecommendations,
        // Company data
        companyData,
        setCompanyData,
        // Session data
        sessionData,
        setSessionData,
        // Tools recommended
        setToolsRecommended,
        toolsRecommended,
        // Storage location
        editingLocId,
        editingLocIdRef,
        setEditingLocId,
        // Flags
        flags,
        setFlags,
        // Storage Locations Options
        storageLocationsOptions,
        setStorageLocationsOptions,
        // Functions
        refreshDataInventory,
        downloadRopa,
        // DPAs
        dataProcessingAgreements,
        // Tools found
        toolsFound,
        setToolsFound,
        onToolFoundSelected,
    };

    // If we are loading the data
    if (loading === true && !data) {
        return (
            <Container title={`Tools and Third Parties - Loading..`} classNames="page-third-parties">
                <div className="component-informative-screen">
                    <div className="icon">
                        <CircularProgress size={25} />
                    </div>
                    <div className="heading">Tools and third parties</div>
                    <div className="message">Please wait a moment while we retrieve your data.</div>
                </div>
            </Container>
        );
    }

    // If we failed to load the data
    if (loading === false && !data) {
        return (
            <Container title={`Tools and Third Parties - Having Difficulties`} classNames="page-third-parties">
                <div className="component-informative-screen">
                    <div className="icon">
                        <i className="elm fa-light fa-circle-exclamation"></i>
                    </div>
                    <div className="heading">Having Difficulties</div>
                    <div className="message">
                        We're experiencing technical difficulties while retrieving your data. Please try again later.
                    </div>
                </div>
            </Container>
        );
    }

    return (
        <Context.Provider value={ContextProps}>
            <ContextFunctions>
                <Container
                    title={`Tools and Third Parties`}
                    classNames="page-third-parties shareable-components data-inventory"
                    isAlternateBackground={true}
                >
                    <Header />
                    <SubHeader />
                    <StorageLocations />

                    {/* APIS */}
                    <API />
                </Container>
            </ContextFunctions>
        </Context.Provider>
    );
};

export default Component;
