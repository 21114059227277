import createAuth0Client from '@auth0/auth0-spa-js';
import Axios from 'axios';
import PackageJSON from '../../package.json';
import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { hotjar } from 'react-hotjar';
import pluralize from 'pluralize';
import amplitude from 'amplitude-js';
import * as Yup from 'yup';
import moment from 'moment';
import { makeEndpointRequest, makeLocalEndpointRequest } from './endpoints';
import lodash from 'lodash';
import CryptoJS from 'crypto-js';
import Intercom from '@intercom/messenger-js-sdk';

let auth0Client = null;

export const getAuth0Client = async () => {
    if (auth0Client !== null) return auth0Client;
    auth0Client = await createAuth0Client({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        returnTo: window.location.origin,
        redirect_uri: `${window.location.origin}/authenticate?`,
        useCookiesForTransactions: true,
    });
    return auth0Client;
};

/**
 *
 * @param {*} event_title: The name of the action performed by the user
 * @param {*} event_properties: A small chunk of json you can add to it.
 * @param {*} user_id: The user ID that will show up in amplitude
 * @param {*} user_properties: A global json attached to the user.
 */

export async function trackAnalyticEvent(event_title = '', event_properties = {}, user_id = undefined, user_properties = {}) {
    try {
        const project = amplitude.getInstance();
        project.init(process.env.REACT_APP_AMPLITUDE_USERS_PROJECT_KEY);
        if (user_id) {
            amplitude.setUserId(user_id);
        }
        amplitude.getInstance().setUserProperties(user_properties);
        await project.logEvent(event_title, event_properties);
    } catch (err) {
        console.error(`Failed to create amplitude event`, err);
    }
}
/**
 *
 * @param {*} event_title: Example "Logged In"
 * @param {*} event_properties : A JSON Object. Example { username: "123" }
 */

export async function trackUserInteraction(event_title, event_properties = {}) {
    try {
        if (!window.account) throw new Error('This user is not logged in.');

        if (window.location.host === 'localhost:3000') {
            // eslint-disable-next-line
            console.log(`[Amplitude] ${event_title}`, event_properties);
        }

        if (window.account.email === 'testAccount') return false; // Not allowing cypress test amplitude events

        const allowedDomains = ['staging.privasee.io', 'localhost:3000'];
        const isAllowedDomain =
            allowedDomains.filter((url) => {
                if (window.location.origin.includes(url)) return true;
                return false;
            }).length > 0
                ? true
                : false;

        if (window.account.email.includes('@privasee') && !isAllowedDomain) return false;

        const eventPropertiesFinal = {
            ...event_properties,
            referrer: localStorage.getItem('referrer') || 'None.',
            app_version: PackageJSON.version,
        };

        await trackAnalyticEvent(event_title, eventPropertiesFinal, window.account.email, {});
    } catch (err) {
        await logError(`CREATE_AMP_EVENT`, err);
    }
}

export const parseErrorBody = (err) => {
    if (err && err.response && err.request) {
        return JSON.stringify({
            responseData: err.response.data,
            responseStatus: err.response.status,
            requestBody: err.config.data,
            requestMethod: err.config.method,
            requestUrl: err.config.url,
            requestHeaders: err.config.headers,
        });
    } else if (err.stack !== undefined) {
        return JSON.stringify({
            internalErrorStack: err.stack,
        });
    } else if (err && typeof err === 'string') {
        return err;
    } else return `Error couldn't be parsed.`;
};

/**
 * Function used in some of the steps of the GDPR essentials to get references
 * to deleted values in the keys of some of the session objects
 * @param {*} objectToSearch - Session object to search
 * @param {*} params - different options to search by
 * @returns array of keys containing the provided params
 */
export function findDeletedKeysInMatrixCheckbox(objectToSearch, { location, process, element, subject }) {
    const foundKeys = [];
    const regex = new RegExp(
        `(${location ? location : '.*'})\\_\\_(${process ? process : '.*'})\\_\\_(${
            element ? element.replace(/\*/g, '\\*') : '.*'
        })\\_\\_(${subject ? subject : '.*'})`,
        'g',
    );

    for (var k in objectToSearch) {
        if (k.match(regex)) foundKeys.push(k);
    }
    return foundKeys;
}

/**
 * A simple function that checks which errors are valid or not to determinate if they should be sent to logz.
 * @param {*} err
 */
export const isInvalidError = (code, err = {}) => {
    try {
        const errorMessage = err && err.message ? err.message : '';
        const errorResponse = err && err.response ? err.response : {};

        // Is an error returned when the user is timed out. It's not dangerous.
        if (errorResponse.data && errorResponse.data.error === 'Please log in!') return true;

        // Network errors can happen often, it means the user's internet is down. Our app works just fine.
        if (errorMessage === 'Network Error') return true;

        // This is also another error that happens often linked to the one above.
        if (errorMessage === 'timeout of 30000ms exceeded') return true;

        // If the request has been aborted by the user - Is linked to network issues.
        if (errorMessage === 'Request Aborted') return true;

        // This means they had a cache token - Temp disabled to inspect a bug.
        // if (code === 'CHECK_AUTH0_REDIRECT_QUERY_URL' && errorMessage.includes('Error: Invalid state')) return true;

        // All good.
        return false;
    } catch (err) {
        return false;
    }
};

export const logError = async (code = '', error_body = {}, payload = {}) => {
    try {
        const email = window.account ? window.account.email : `N/A`;
        const _companyId = window.account ? window.account._companyId : `N/A`;

        // Check if is a valid error
        if (isInvalidError(code, error_body)) return false;

        const logBody = {
            code,
            source: process.env.REACT_APP_LOGZ_NAME,
            message: error_body.message ? error_body.message : `No message`,
            payload: JSON.stringify(payload),
            error: parseErrorBody(error_body),
            email,
            _companyId,
        };

        const key = process.env.REACT_APP_LOGZ_KEY;
        const endpoint = `https://listener-uk.logz.io:8071/?token=${key}&type=${logBody.source}`;
        await Axios.post(`${endpoint}`, `${JSON.stringify(logBody)}`);
        if (window.location.host === 'localhost:3000') {
            // eslint-disable-next-line
            console.log(`Error logged`, logBody);
        }
    } catch (err) {
        console.error(`Failed to log error`, err);
    }
};

/**
 * @returns a correctly captalised company name from email.
 * @example convertEmailToCompanyName("alex@privasee.co.uk") => Privasee
 */
export function convertEmailToCompanyName(email) {
    const domain = email.split('@');
    const text = domain.length === 1 ? domain[0] : domain[1].split('.')[0];
    const initial = text.substr(0, 1).toString().toUpperCase();
    const rest = text.substr(1, text.length);
    return `${initial}${rest}`;
}

/**
 *
 * @returns an array of [width of screen, height of screen] - is responsive too.
 */
export function useWindowSize() {
    let [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

/**
 *
 * @param {*} ms: Miliseconds to wait
 * @returns a fake await to see how stuff looks while loading
 */

export async function fakeAwait(ms) {
    return await new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, ms);
    });
}

export const initializeHojar = async () => {
    try {
        if (window.location.hostname === 'localhost') {
            console.warn(`[Hotjar] Disabled on localhost development`);
            return false;
        }
        if (new RegExp(/.*privacy-(portal|center).*/).test(window.location.pathname)) {
            console.warn(`[Hotjar] Disabled on privacy portal`);
            return false;
        }

        if (window.location.href.includes(`?embed-preview=true`)) {
            console.warn(`[Hotjar] Disabled on iframe preview.`);
            return false;
        }

        await hotjar.initialize(1826606, 6);
        const account = window.account;
        if (!account) throw new Error('Hotjar cannot be initialized without an account');
        await hotjar.identify(account.email, {
            companyId: account._companyId,
            id: account._id,
            isAdmin: account.isAdmin,
        });
    } catch (err) {
        await logError('START_HOTJAR', err);
    }
};

export const LiveChatProvider = (props) => {
    const [chatMounted, setChatMounted] = useState(false);

    const isAllowedChatOnThisPrivacyPortal = () => {
        let firstCheck = new RegExp(/.*privacy-(portal|center).*/).test(window.location.pathname);
        let secondCheck = window.location.pathname.includes(`/shareables`) || window.location.pathname.includes(`/settings`);
        if (firstCheck && !secondCheck) {
            let companyId = window.location.pathname.split('/')[2];
            if (props.account && companyId === props.account._companyId) return true;
            return false;
        } else {
            return true;
        }
    };

    const loadChatProvider = () => {
        if (chatMounted) return false;

        // Listener..
        window.hsConversationsOnReady = [setChatIdentity];

        // Load the script
        const d = window.document;
        let s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'hs-script-loader';
        s.async = 1;
        s.defer = 1;
        s.src = '//js-na1.hs-scripts.com/7054655.js';
        s.onload = () => setChatMounted(true);
        // d.getElementsByTagName('body')[0].appendChild(s);
    };

    const setChatIdentity = async () => {
        try {
            // Set the identity
            try {
                let chatSettings = null;

                // If there is a token already generated for his account.

                // Generate token
                const token = await makeEndpointRequest('GetHubspotIdentificationToken');

                // Set the settings
                chatSettings = {
                    identificationEmail: props.account.email,
                    identificationToken: token,
                };

                // Set hs settings
                window.hsConversationsSettings = chatSettings;
            } catch (err) {
                await logError(`HUBSPOT_GENERATE_TOKEN`, err);
            }

            // Load the SDK no matter if we got an error or not..
            window.HubSpotConversations.widget.load();
        } catch (err) {
            await logError(`HUBSPOT_SET_CHAT_IDENTITY`, err);
        }
    };

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            console.warn(`[Live Chat] Disabled on localhost development`);
            return false;
        }

        if (!isAllowedChatOnThisPrivacyPortal()) {
            console.warn(`[Live Chat] Disabled on privacy portal`);
            return false;
        }

        if (window.location.href.includes(`?embed-preview=true`)) {
            console.warn(`[Live Chat] Disabled on iframe preview.`);
            return false;
        }

        if (props.account && chatMounted === false) {
            // Make sure the chat will not start right away
            window.hsConversationsSettings = { loadImmediately: false };

            // Load provider..
            loadChatProvider();

            // Intercom chat
            Intercom({
                api_base: 'https://api-iam.intercom.io',
                app_id: 'm82kbztx',
                user_id: props.account._id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
                name: props.account.email, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
                email: props.account.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email address
                created_at: props.account.registerDate,
            });
        }

        // eslint-disable-next-line
    }, [account]);

    return null;
};

export async function copyStringToClipboard(text) {
    navigator.clipboard.writeText(text);
}

// Add rules for our identifiers.
export const customIsPlural = (str) => {
    if (new RegExp(/Temporary(\s)*Staff/i).test(str)) {
        return true;
    } else if (new RegExp(/General(\s)*Practitioners/i).test(str)) {
        return true;
    } else {
        return pluralize.isPlural(str);
    }
};

export function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

// This function will validate against a yup.js schema and its payload sent.

export const validateAgainstSchema = (schemaGiven, payload) => {
    return new Promise(async (resolve, reject) => {
        try {
            await schemaGiven.validate(payload, {
                abortEarly: false,
            });
            resolve();
        } catch (err) {
            try {
                const errorObject = {};
                err.inner.forEach((elm) => {
                    errorObject[elm.path] = elm.message;
                });
                reject(errorObject);
            } catch (_) {
                resolve({});
                console.error(`Yup.js validation parser error`, err);
            }
        }
    });
};

// Simple function required to translate certain things into readable textfield props

export const getValidationPropFields = (validationFields, conditionToDisplay = true, label) => {
    const val = lodash.get(validationFields, label);
    if (val !== undefined && conditionToDisplay === true) {
        return {
            error: true,
            helperText: val,
        };
    } else return {};
};

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatNumber = (number) => {
    let str = String(number).replace(/(.)(?=(\d{3})+$)/g, '$1,');
    return str;
};

export const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const validateEmail = (emailString) => {
    const emailSchema = Yup.string().min(3, 'Must be at least 3 characters long').email('Must be a valid email');

    try {
        emailSchema.validateSync(emailString);
        return true;
    } catch (err) {
        return err.message;
    }
};

export const getAppDomain = () => {
    const origin = window.location.origin;
    if (origin.includes('localhost')) return origin; // Is on localhost.
    if (origin.includes(`privasee.`)) return origin; // Is on one our staging or live domain.
    return `https://privasee.io`; // If not it means is from an embedded portal from someone else's website so let's point to .io
};

/**
 * A straightforward function that simplifies the process of determining the domain of the products app.
 * @returns String
 */

export const getProductsDomain = () => {
    const origin = window.location.origin;
    if (origin.includes('localhost')) return `http://localhost:4000`; // Usually when testing the portal we will run the products app on port 4000.
    return `https://products.privasee.io`;
};

export const calculatePercentage = (partialValue, totalValue) => {
    if (partialValue > totalValue) return 100;
    return (100 * partialValue) / totalValue;
};

// @ToDo: Maybe one day they allow to use ls?[c] to improve those nasty checks. As of now it works.

export const getLanguageSystemComponent = ({ languageSelected, languages, system, component, key }) => {
    try {
        if (!languages[system]) throw Error(`${system} is not a valid system.`);

        // Shortcuts required
        const ls = languages;
        const l = languageSelected;
        const c = component;
        const k = key;
        const s = system;
        const fl = Object.keys(languages[system])[0] || 'EN';

        // Get the official translation from the selected language pack..
        const translation = lodash.get(ls, `${s}.${l}.${c}.${k}`);

        if (translation !== undefined) return translation;

        // If it's not available in Spanish it's gonna fallback to english..
        const fallbackComponent = lodash.get(ls, `${s}.${fl}.${c}.${k}`);
        if (fallbackComponent !== undefined) return fallbackComponent;

        // If not at least return the key..
        return key;
    } catch (err) {
        console.error(err);
        return key;
    }
};

export const dateInPast = function (dateToCheck, dateToCompareItTo) {
    const diff = moment(new Date(dateToCheck)).diff(new Date(dateToCompareItTo), 'minutes');
    if (diff < 1) return true;
    return false;
};

export async function checkBackendFlag(flagName = '', defaultResponse = false, email = null, custom = {}, options = {}) {
    try {
        const userObject = {
            key: 'backend', // identifier for launchdarkly.
            email: email ? email : undefined,
            custom: {
                ...custom,
            },
        };

        const response = await makeLocalEndpointRequest('/api/flags/checkFlag', {
            userObject,
            flagName,
            defaultResponse,
            options,
        });

        return response;
    } catch (err) {
        await logError(`CHECK_BACKEND_FLAG`, err, { flagName, defaultResponse });
        return defaultResponse;
    }
}

export async function checkUserFlag(flagName = '', defaultResponse = false) {
    try {
        if (!window.account) throw new Error('This user is not logged in to check his flag.');

        const response = await makeLocalEndpointRequest('/api/flags/checkUserFlag', {
            flagName,
            defaultResponse,
        });

        return response;
    } catch (err) {
        await logError(`CHECK_USER_FLAG`, err, { flagName, defaultResponse });
        return defaultResponse;
    }
}

export function useStateRef(initialValue) {
    const [value, setValue] = useState(initialValue);

    const ref = useRef(value);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return [value, setValue, ref];
}

export const getBreaksRendered = (field) =>
    field
        .split('\n')
        .map((item, key) => {
            return <p key={key}>{item}</p>;
        })
        .filter((x) => x.props.children.length > 0);

export const getGravatarUrl = (email) => {
    const trimmedEmail = email.trim(); // trim leading/trailing whitespace
    const lowercaseEmail = trimmedEmail.toLowerCase(); // convert to lowercase
    const md5Hash = CryptoJS.MD5(lowercaseEmail).toString(); // calculate md5 hash

    return `https://www.gravatar.com/avatar/${md5Hash}?d=mp`;
};

/**
 * Adds "..." to the text if it exceeds a specific length.
 *
 * @param {string} text - The input text.
 * @param {number} maxLength - The maximum length of the resulting text before adding "...".
 * @returns {string} - The modified text with "..." if it exceeds maxLength.
 */

export function addEllipsisIfExceedsMaxLength(text, maxLength) {
    if (text.length > maxLength) {
        // If the text exceeds maxLength, truncate it and add "..."
        return `${text.substring(0, maxLength - 3)}...`;
    } else {
        // If the text is within or equal to maxLength, return it as is
        return text;
    }
}

/**
 * Set the value of a parameter in the URL query string.
 * @param {string} paramName - The name of the parameter.
 * @param {string} paramValue - The value to set.
 */

export const setInUrlQueryString = (paramName, paramValue) => {
    try {
        // If the URL already has a filter param.
        const currentUrl = new URL(window.location.href);

        // If we have this param..
        if (getFromUrlQueryString(paramName)) {
            // Update the current URL with the new value.
            currentUrl.searchParams.set(paramName, paramValue); // Replace existing filter with new value
        } else {
            // Add a new filter parameter with the value to the current URL.
            currentUrl.searchParams.append(paramName, paramValue); // Add new filter parameter
        }
        window.history.replaceState({}, document.title, currentUrl); // Update the URL without triggering a page reload
    } catch (err) {
        logError('setInUrlQueryString', err, { paramName, paramValue });
    }
};

/**
 * Remove a parameter from the URL query string.
 * @param {string} paramName - The name of the parameter to remove.
 */

export const removeFromUrlQueryString = (paramName) => {
    try {
        // Update the current URL with the parameter removed.
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete(paramName);
        window.history.replaceState({}, document.title, currentUrl); // Update the URL without triggering a page reload
    } catch (err) {
        logError('removeFromUrlQueryString', err, { paramName });
    }
};

/**
 * Get the value of a parameter from the URL query string.
 * @param {string} paramName - The name of the parameter to extract.
 * @returns {string|null} - The parameter value or null if not found.
 */
export const getFromUrlQueryString = (paramName) => {
    try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const hasParam = urlSearchParams.has(paramName);
        if (!hasParam) return null;
        const value = urlSearchParams.get(paramName);
        return value;
    } catch (err) {
        logError('getFromUrlQueryString', err, { paramName });
        return null;
    }
};

/**
 * Capitalizes the first letter of a given string.
 *
 * @param {string} inputString - The string to be modified.
 * @returns {string} - The string with the first letter capitalized.
 */

export function uppercaseFirstLetter(inputString) {
    // Check if the input is a valid string
    if (typeof inputString !== 'string' || inputString.length === 0) {
        return inputString; // Return the input string as is
    }

    // Capitalize the first letter and concatenate it with the rest of the string
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}
