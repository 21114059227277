import React, { useState } from 'react';
import { TextField, Select, MenuItem, Button } from '@mui/material';
import { trackUserInteraction } from '../../../../../../utils/helpers';
import { ComponentState } from '../../..';

const Component = (props) => {
    const [input, setInput] = useState('');
    const [inputCorrected, setInputCorrected] = useState('');
    const [toolSelected, setToolSelected] = useState(null);
    const { data } = ComponentState();

    function elementToString(element) {
        const wrapper = document.createElement('div');
        wrapper.appendChild(element.cloneNode(true));
        return wrapper.innerHTML;
    }

    const correctCode = async () => {
        try {
            var parser = new DOMParser();
            var doc = parser.parseFromString(input, 'text/html');
            var scriptElement = doc.querySelector('script');

            if (scriptElement) {
                scriptElement.type = 'text/plain';
                scriptElement.setAttribute('data-usercentrics', toolSelected);
            }

            setInputCorrected(elementToString(scriptElement));
        } catch (err) {
            await trackUserInteraction('Having Difficulties', {
                reason: 'Tried to correct a wrong code snippet',
                inputEntered: input,
            });
            window.showAlert(
                'Having Difficulties',
                'The code entered is not a valid code snippet as shown in the first example. Please check your code snippet and try again.',
                'error',
            );
        }
    };

    return (
        <React.Fragment>
            <div className="step-entry code-builder">
                <div className="step-header">
                    <div className="step-badge gold">Tool</div>
                    <div className="step-label">Manually Block Cookies - Update your script</div>
                </div>
                <div className="step-content">
                    <div className="step-text">Insert your existing script to update it and make sure it blocks cookies.</div>
                    <TextField
                        className="input-link"
                        fullWidth={true}
                        maxRows={10}
                        multiline={true}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder={`Paste your code here...`}
                    />
                    {inputCorrected && (
                        <React.Fragment>
                            <div className="label">Snippet adjusted</div>
                            <TextField
                                className="input-link"
                                maxRows={10}
                                multiline={true}
                                fullWidth={true}
                                disabled={true}
                                value={inputCorrected}
                            />
                        </React.Fragment>
                    )}
                    <div className="options-builder">
                        <Select className="dropdown" value={toolSelected || 'none'} onChange={(ev) => setToolSelected(ev.target.value)}>
                            <MenuItem value={'none'} disabled={true}>
                                Click here to select the tool
                            </MenuItem>
                            {props.data.map((x, ix) => (
                                <MenuItem key={ix} value={x}>
                                    {x}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button variant="contained" color="primary" disabled={!toolSelected} onClick={correctCode}>
                            Correct
                        </Button>
                    </div>
                    {data.cookieBannerSettings.enabled && (
                        <div className="note">
                            <p style={{ color: 'rgba(255,255,255, 0.6)', fontSize: 14 }}>
                                * Some tools are not listed here as they are automatically blocked by your Smart cookie blocker, so no
                                action is needed for those.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
