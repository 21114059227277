import { makeEndpointRequest } from '../../../../utils/endpoints';
import { logError, trackUserInteraction } from '../../../../utils/helpers';
import { getIndividualTypesIdFromLabel } from '../steps/individuals/components/functions';
import {
    createStorageLocationWithRecommendations,
    createCustomStorageLocation,
    createIndividual,
    createMatrice,
} from '../steps/storageLocations/utils/helpers';
import { mapSlugsToSteps } from './mappings';

export const updateLink = (slug) => {
    const isEdit = window.location.href.includes(`/edit`) ? true : false;
    const currentSearchParams = new URLSearchParams(window.location.search); // Get the current query parameters
    let newLink = `/stages/gdpr-essentials/${isEdit ? `edit/` : ``}${slug}`;

    // Check if there are existing query parameters
    if (currentSearchParams.toString() !== '') {
        newLink += `?${currentSearchParams.toString()}`;
    }

    window.history.pushState(null, null, newLink);
};

export const getMapStepsToSlugs = () => {
    const res = {};

    const mapSlugsKeys = Object.keys(mapSlugsToSteps);
    const mapSlugsValues = Object.values(mapSlugsToSteps);

    mapSlugsKeys.forEach((key, index) => {
        res[mapSlugsValues[index]] = key;
    });

    return res;
};

export const isStepMoreAdvanced = (step1, step2) => {
    const mapStepsToSlugs = getMapStepsToSlugs();
    const keys = Object.keys(mapStepsToSlugs);
    const valBiggestStep = keys.findIndex((s) => s === step1);
    const valMatchStep = keys.findIndex((s) => s === step2);
    if (valMatchStep > valBiggestStep) return false;
    return true;
};

export const calculateProgress = (step) => {
    let progress = 0;
    switch (step) {
        case 'Welcome':
            progress = 0;
            break;
        case 'Audience':
            progress = 10;
            break;
        case 'Industry':
            progress = 20;
            break;

        case 'CompanyInsights':
            progress = 30;
            break;

        case 'DescribeCompany':
            progress = 33;
            break;

        case 'StorageLocations':
            progress = 40;
            break;

        case 'Services':
            progress = 45;
            break;

        case 'Individuals':
            progress = 48;
            break;

        case 'IdentifyElements':
            progress = 50;
            break;
        case 'IdentifySensitive':
            progress = 60;
            break;

        case 'Children':
            progress = 70;
            break;

        case 'IdentifyVulnerable':
            progress = 70;
            break;
        case 'ReviewData':
            progress = 80;
            break;
        case 'LegalResponsibility':
            progress = 90;
            break;
        case 'Complete':
            progress = 100;
            break;
    }

    return progress;
};

/**
 *
 * A simple function that helps me load scanned tools into storage location much easier.
 * @param {*} params
 * @returns
 */

export const loadScansIntoStorageLocations = async (params) => {
    try {
        const { scannedTools, sessionData, vitalRecommendations } = params;

        // Variables
        let mLocations = [...sessionData.storageLocations];
        let noRecommendations = [];
        let addedSuccessfully = [];

        // Get the recommendations..
        let recommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
            locations: scannedTools.map((x) => x.label),
            processes: [],
        });

        // Temporary due to a bug that should be fixed. When picking Xero one process rec is null.
        recommendations.processes = recommendations.processes.filter((x) => x !== null);

        scannedTools.forEach((item) => {
            try {
                // If tool already exists..
                const alreadyExist = mLocations.find((x) => x.label === item.label);
                if (alreadyExist) return false; // no duplicates

                // Create item..
                const formattedItem = createStorageLocationWithRecommendations({
                    toolName: item.label,
                    specificRecommendations: recommendations,
                    vitalRecommendations,
                    targetAudience: sessionData.companyInfo.targetAudience,
                    currentStorageLocations: mLocations,
                    individualsReferring: sessionData.companyInfo.individualsReferring,
                });

                if (formattedItem !== null) {
                    // It will be undefined if there's no suggestion

                    const obj = {
                        ...formattedItem,
                        origin: 'recommendations',
                        tempData: {
                            scannerResult: true,
                        },
                    };
                    mLocations.push(obj);
                    addedSuccessfully.push(obj);
                } else {
                    let obj = {
                        ...createCustomStorageLocation(item.label),
                        origin: 'manual',
                        tempData: {
                            scannerResult: true,
                        },
                    };

                    mLocations.push(obj);
                    trackUserInteraction(`Created custom tool`, { label: item.label, origin: 'manual', scanned: true });
                    noRecommendations.push(item.label);
                    addedSuccessfully.push(obj);
                }
            } catch (err) {
                logError(`gel.api.loadScansIntoStorageLocations`, err);
            }
        });

        return {
            locations: mLocations,
            noRecommendations,
            addedSuccessfully,
        };
    } catch (err) {
        await logError(`LOAD_SCANS_INTO_STORAGE_LOCATIONS`, err);
        throw err;
    }
};

/**
 *
 * @param {*} roleLabel
 * @param {*} processRecommendations
 * @returns All the processes that are commonly used with this type of referring role.
 */

export const getProcessesCommonlyUsedWithRole = (roleLabel, processRecommendations) => {
    return processRecommendations.filter((c) =>
        c.appliedToSubjectTypesOf.find((label) => getIndividualTypesIdFromLabel(label) === roleLabel),
    );
};

/**
 * Compare function for sorting groups by groupId.
 * @param {string} a - The first groupId.
 * @param {string} b - The second groupId.
 * @returns {number} - Negative if a should come before b, positive if b should come before a, 0 if equal.
 */

export const compareGroupIds = (a, b) => {
    const order = { scannerResult: 1, dpa: 2, general: 3 };
    return order[a] - order[b];
};

/**
 *
 * @param {*} groupId
 * @returns The label for that group
 */

export const getSuggestedToolGroupLabel = (groupId) => {
    if (groupId === 'dpa') {
        return 'Found from your sub-processors in the DPA';
    }

    return 'Found by scanning your domains';
};

/**
 *
 * @param {*} groupId
 * @returns The footer label for that group
 */

export const getSuggestedToolGroupFooterLabel = (groupId) => {
    if (groupId === 'dpa') {
        return 'Tools added as sub-processors via the DPA';
    }

    return 'New tools found by the Scanner';
};

export const getSuggestedToolGroupBadgeColor = (groupId) => {
    if (groupId === 'dpa') return 'rgb(205, 79, 79)';

    // Default color
    return '#f79a43';
};

/**
 *
 * @param {*} storageLocation
 * @returns The group where a suggested tool belongs to.
 */

export const getSuggestedToolGroupId = (storageLocation) => {
    // Is not a suggested tool at all since it doesn't have temp data.
    if (!storageLocation.tempData) return null;

    if (storageLocation.tempData.suggestedTool && storageLocation.tempData.suggestedTool.source === 'dpa') return 'dpa';
    if (storageLocation.tempData.suggestedTool) return 'scannerResult';

    // The scanner results should be removed in the future.
    if (storageLocation.tempData.scannerResult) return 'scannerResult';

    return null;
};

/**
 * This function will group the storage locations in groups so we can display them properly.
 * @param {*} storageLocations
 * @returns
 */

export const groupSuggestedTools = (storageLocations) => {
    try {
        let groups = {};

        // Group the tools accordingly
        storageLocations.forEach((tool) => {
            // It doesn't have temp data therefore we cannot group them
            if (!tool.tempData) return false;

            const groupId = getSuggestedToolGroupId(tool);
            if (!groups[groupId]) {
                groups[groupId] = {
                    id: groupId,
                    label: getSuggestedToolGroupLabel(groupId),
                    entries: [],
                };
            }
            groups[groupId].entries.push(tool);
        });

        const result = Object.values(groups);

        // Sort the result by groupId
        result.sort((a, b) => compareGroupIds(a.id, b.id));

        return result;
    } catch (err) {
        logError(`gel.groupSuggestedTools`, err);
        return [];
    }
};

/**
 * The logic is that this function will check what data subjects should be added to a Storage Location accordingly to the Individuals references.
 * The plan is that when we add a new referring role of type Customers - We will add this new Data Subject in every tool where it has processes associated with Customer individuals type.
 */

export const getStorageLocationMissingReferringRoles = ({ individualsReferences, vitalRecommendations, storageLocation }) => {
    try {
        // We know the referring roles but now we need to check what processes are they usually used on.
        const individuals = individualsReferences.map((c) => ({
            label: c.label,
            matchingProcesses: getProcessesCommonlyUsedWithRole(c.referringRole, vitalRecommendations.processes),
        }));

        // The data we're gonna return..
        let returnedData = {
            matrices: [],
            individuals: [],
        };

        // We will now iterate the referring roles
        individuals.forEach((referringRole) => {
            // Check if this referring role should be checked against this storage location
            // If the storage location has one of the processes usually associated with this referring role.
            const isApplicableStorage = storageLocation.processes.find((c) =>
                referringRole.matchingProcesses.find((g) => g.label === c.label),
            );

            // Not applicable.
            if (!isApplicableStorage) return;

            // @Prevention of duplicate data: Is the storage location already having this referring role created? If yes there's nothing we should do further.
            if (storageLocation.individuals.find((c) => c.label === referringRole.label)) return false;

            // Iterate the processes
            storageLocation.processes.forEach((p) => {
                // Is this one of the processes this referring role (data subject) is usually used with?
                if (!referringRole.matchingProcesses.find((g) => g.label === p.label)) return false;

                // Now let's get the recommendations for this process
                const processRecommendations = vitalRecommendations.processes.find((c) => c.label === p.label);
                if (!processRecommendations) return; // No recommendation. Must be a custom process

                // We need to check what data elements are usually collected by this process, but we will filter out what's not used by the Storage Location already.
                const elements = processRecommendations.elementCategories.filter((elementRecommended) => {
                    const isUsedByStorageLocation = storageLocation.elements.find((c) => c.label === elementRecommended);
                    if (!isUsedByStorageLocation) return false;
                    return true;
                });

                // Iterate elements and fill up the matrice now that we know the process name, element and data subject (referring role)
                elements.forEach((element) => {
                    returnedData.matrices.push(
                        createMatrice({
                            process: p.label,
                            individual: referringRole.label,
                            element: element,
                        }),
                    );
                });
            });

            // We now check: Is this new data individual gonna be used anywhere in the matrice (meaning a connect has been made)
            if (!returnedData.matrices.find((c) => c.individual === referringRole.label)) return false;

            // We now add the individuals too.
            returnedData.individuals.push(createIndividual({ label: referringRole.label }));
        });

        // Format the response
        const response = returnedData.individuals.map((individual) => {
            return {
                individual,
                matrices: returnedData.matrices.filter((c) => c.individual === individual.label),
            };
        });

        // Format the response..
        return response;
    } catch (err) {
        logError(`getStorageLocationMissingReferringRoles`, err);
        return [];
    }
};
