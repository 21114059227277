import React from 'react';
import { trackUserInteraction } from '../../../../../../utils/helpers';

// Components
import ManualAutocomplete from '../../storageLocations/picker';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { ModuleState } from '../../..';

const Panel = (props) => {
    const { pendingTools } = ModuleState();

    if (props.active === false) return null;

    const onClose = async () => {
        if (pendingTools.length > 0) return false;
        await trackUserInteraction(`Selected "Close"`);
        props.setActive(false);
    };

    return (
        <React.Fragment>
            <Dialog className="storage-locations-add-tools-modal" open={true} maxWidth="md" fullWidth={true}>
                <DialogTitle data-cy="dialog-title">
                    <div className="text">Add tools or third parties</div>
                    <div className="close-button" onClick={onClose} data-cy="review-module-done-btn">
                        Done
                    </div>
                </DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <ManualAutocomplete disableEdit={true} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
};

export default Panel;
