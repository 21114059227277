/**
 *
 * @param _storageLocationId The id of the storage location (UUID Generated on creation time)
 * @param arrayLocations The array where we can find a match
 */

import { logError } from '../../../../../../../../utils/helpers';

export const getStorageLocationData = (_storageLocationId, arrayLocations) => {
    try {
        const match = arrayLocations.find((c) => c._storageLocationId === _storageLocationId);
        return match || null;
    } catch (err) {
        logError('editPanel.getStorageLocationData', err);
        return null;
    }
};

/**
 * Gets the matrice table.
 * @param {*} _storageId - real _id of storage location
 * @param {*} arrayMatrices
 * @returns
 */

export const getMatriceEntriesData = (_storageId, arrayMatrices) => {
    try {
        // Get data
        const match = arrayMatrices.find((c) => c._storageLocationId === _storageId);

        return match ? match.entries : [];
    } catch (err) {
        logError('editPanel.getMatriceEntriesData', err);
        return [];
    }
};

/**
 *
 * @param {*} comparedLabel The label of storage location
 * @param {*} arrayRecommendations  The array from airtable with our recommendations for tools
 * @returns
 */
export const getToolRecommendations = (label, arrayRecommendations) => {
    try {
        const comparedLabel = label
            .replace(/\(\d+\)/g, '')
            .trim()
            .toLowerCase();

        const match = arrayRecommendations.find((c) => {
            // Turn Xero (2) into Xero
            const iteratedLabel = c.label
                .replace(/\(\d+\)/g, '')
                .trim()
                .toLowerCase();

            // Check matching..
            return iteratedLabel === comparedLabel;
        });

        return match || null;
    } catch (err) {
        logError('editPanel.getToolRecommendations', err);
        return null;
    }
};

/**
 * This function will convert a matrix map to the format required by the third parties view.
 * @param {*} matrice - data inventory matrice entries
 */

export const convertMatriceToMatriceView = (matrice, processesRelationships = []) => {
    try {
        let processes = [];

        // Map the processes
        [...matrice.map((c) => c._processId), ...processesRelationships].forEach((id) => {
            if (processes.find((c) => c._processId === id)) return; // already added.

            // Map process
            processes.push({ _processId: id, individuals: [] });
        });

        // Now let's map the Individuals to the processes.
        matrice.forEach((m) => {
            const ixProcess = processes.findIndex((c) => c._processId === m._processId);
            if (ixProcess === -1) return;

            // Already added?
            if (processes[ixProcess].individuals.find((i) => i._individualId === m._individualId)) return;

            // Add it.
            processes[ixProcess].individuals.push({
                _individualId: m._individualId,
                elements: [],
            });
        });

        // Now final thing: we map the elements.
        // Now let's map the Individuals to the processes.
        matrice.forEach((m) => {
            const ixProcess = processes.findIndex((c) => c._processId === m._processId);
            if (ixProcess === -1) return;

            const ixIndividual = processes[ixProcess].individuals.findIndex((i) => i._individualId === m._individualId);
            if (ixIndividual === -1) return;

            // Already added ?
            if (processes[ixProcess].individuals[ixIndividual].elements.includes(m._elementId)) return;

            // Add it.
            processes[ixProcess].individuals[ixIndividual].elements.push(m._elementId);
        });

        return processes;
    } catch (err) {
        logError(`thirdParties.convertMatrixToProcesses`, err, { matrice });
        return [];
    }
};
