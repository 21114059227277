import React from 'react';
import { ComponentState } from '../../..';

const Component = () => {
    const { data, updateData, loading } = ComponentState();

    const onChange = (val) => {
        if (data.isPrivacyPortalHosted === val) return false;
        if (loading === true) return false;
        updateData(`isPrivacyPortalHosted`, val);
    };

    const entries = [
        {
            label: `Public link`,
            description: `Choose this option to share your privacy portal through a public link from us`,
            value: true,
            icon: `fa-solid fa-share-nodes`,
        },
        {
            label: `Embedded`,
            description: `Choose this option to embed the portal on an existing page of your website.`,
            value: false,
            icon: `fa-solid fa-code`,
        },
    ];
    return (
        <React.Fragment>
            <div className="form-label">Choose your method of implementation</div>
            <div className="form-content">
                <div className="component-switch-cards">
                    {entries.map((entry, ix) => (
                        <div
                            key={ix}
                            data-cy={`implementation-embedded-${entry.value ? 'disabled' : 'enabled'}`}
                            onClick={() => onChange(entry.value)}
                            className={`entry ${data.isPrivacyPortalHosted === entry.value && `selected`}`}
                        >
                            <div className="icon">
                                <i className={`elm ${entry.icon}`}></i>
                            </div>
                            <div className="title">{entry.label}</div>
                            <div className="description">{entry.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
