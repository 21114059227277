import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';

import countriesInEEA from '../../../../../../../../dummy_data/countriesInEEA.json';
import { PageContext } from '../../../../..';

const ExportingComponent = (props) => {
    const cols = props.columns;
    const { setEditingToolId } = PageContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedToolId, setSelectedToolId] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event, entry) => {
        setAnchorEl(event.currentTarget);
        setSelectedToolId(entry._id);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedToolId(null);
    };

    const handleDataInventoryOption = () => {
        setAnchorEl(null);
        setEditingToolId(selectedToolId);
    };

    return (
        <React.Fragment>
            <div className="theme-generic-table with-overflow">
                <div className="table-header">
                    <div className="column label">{props.purpose}</div>
                    {props.columns.map((col, index) => (
                        <div key={index} className={`column ${col === null ? `empty` : `used`} ${col}`}>
                            {col}
                        </div>
                    ))}
                    <div className="column options" />
                </div>
                <div className="table-entries">
                    {props.entries.map((entry, index) => (
                        <div key={index} className="row">
                            <div className="column label">{entry.label} </div>
                            <div className="column geography">
                                {cols[0] !== null && <div className="input-text">{entry.dataResidency}</div>}
                            </div>
                            <div className="column sensitive-info">
                                <div className="input-text">{entry.elements.some(({ sensitive }) => sensitive) ? 'Yes' : 'No'}</div>
                            </div>
                            <div className="column eea">
                                <div className="input-text">{countriesInEEA.includes(entry.dataResidency) ? 'Yes' : 'No'}</div>
                            </div>
                            <div className="column security">
                                <div className="input-text">{entry.securityMeasures.includes('Rest') ? 'Yes' : 'No'}</div>
                            </div>
                            <div className="column security">
                                <div className="input-text">{entry.securityMeasures.includes('Transit') ? 'Yes' : 'No'}</div>
                            </div>
                            <div className="column options">
                                <div onClick={(e) => handleClick(e, entry)}>
                                    <i className="icon fa-solid fa-eye"></i>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleDataInventoryOption}>See More</MenuItem>
            </Menu>
        </React.Fragment>
    );
};

export default ExportingComponent;
