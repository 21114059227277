import React, { useState, useEffect } from 'react';

// Dependencies
import { makeEndpointRequest } from '../../../../utils/endpoints';
import { logError, trackUserInteraction } from '../../../../utils/helpers';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AuthStore } from '../../../../utils/context/authStore';

const Component = (props) => {
    const [suggestions, setSuggestions] = useState([]);
    const history = useHistory();
    const { globalFlags } = AuthStore();

    const loadData = async () => {
        try {
            // Get the company suggested tools
            const res = await makeEndpointRequest(`GetCompanySuggestedTools`);

            setSuggestions(res);

            // If there's more than one suggestion
            if (res.length > 0) {
                trackUserInteraction(`New Tools Suggestions`, { suggestions: res.length });
            }
        } catch (err) {
            await logError(`LOAD_SUGGESTED_TOOLS_DASHBOARD`, err);
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    const review = async () => {
        await trackUserInteraction(`Selected "Review"`);
        history.push(globalFlags.gelDecoupling ? '/third-parties' : '/stages/gdpr-essentials/edit/data-inventory');
    };

    const getNewTools = () => {
        const arr = [...props.pendingScans, ...suggestions];
        return arr;
    };

    if (getNewTools().length < 1) return null;

    return (
        <React.Fragment>
            <div className="notification-dashboard-box yellow">
                <div className="title">
                    Review {getNewTools().length} new {getNewTools().length > 1 ? 'tools' : 'tool'} suggested
                </div>
                <div className="content-box">
                    <p style={{ margin: 0 }}>
                        Your data inventory may benefit from some suggested updates. Reviewing them ensures your policy remains current.
                    </p>
                    <p style={{ margin: 0 }}>
                        Demonstrating your commitment to stakeholders by keeping information up to date typically takes just 5 minutes.
                    </p>
                </div>
                <div className="cta-button">
                    <Button variant="outlined" color="primary" onClick={review}>
                        Review tools suggested
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
