import React, { createContext, useContext, useRef, useState } from 'react';

// Context
const Context = createContext({});
export const SetStorageLocationsContext = () => useContext(Context);

// Components
import Modal from './components/modal';
import PickTools from './components/pickTools/index';
import { logError } from '../../../../../../../../utils/helpers';

const Component = (props) => {
    const [pickTools, setPickTools] = useState(false);
    const [addingTools, setAddingTools] = useState(false);

    const panelController = useRef(null);

    const removeStorageLocation = async (storageRemoved) => {
        try {
            props.onEntryRemoved(storageRemoved);
        } catch (err) {
            await logError(`module.individuals.setStorageLocations.removeStorageLocation`, err, { storageRemoved });
            return false;
        }
    };

    const addStorageLocation = async (inventoryStorageLocation, inventoryUpdated, isCreated) => {
        try {
            props.onEntryAdded(inventoryStorageLocation, inventoryUpdated, isCreated);
        } catch (err) {
            await logError(`module.individuals.setStorageLocations.addStorageLocation`, err, { inventoryStorageLocation });
            return false;
        }
    };

    const startPicking = () => {
        setPickTools(true);
        props.setParentPanelVisible(false);
    };

    const stopPicking = () => {
        setPickTools(false);
        props.setParentPanelVisible(true);
    };

    const passedProps = {
        entries: props.entries,
        closeModal: props.closeModal,
        textContents: props.textContents,
        // Callback functions
        addStorageLocation,
        removeStorageLocation,
        // Picking
        startPicking,
        stopPicking,
        setPickTools,
        pickTools,
        panelController,
        // Dependencies
        dataInventory: props.dataInventory,
        setDataInventory: props.setDataInventory,
        toolsRecommendations: props.toolsRecommendations,
        vitalRecommendations: props.vitalRecommendations,
        companyData: props.companyData,
        // UI
        setAddingTools,
        addingTools,
    };

    return (
        <Context.Provider value={passedProps}>
            {/* We display the modal only when we're not picking tools. */}
            {pickTools ? <PickTools /> : <Modal />}
        </Context.Provider>
    );
};

export default Component;
