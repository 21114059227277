import { useEffect } from 'react';
import { logError } from '../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../utils/endpoints';
import { ComponentState } from '..';

const Component = () => {
    const { setDataInventory } = ComponentState();

    const loadDataInventory = async () => {
        try {
            const res = await makeEndpointRequest(`GetDataInventory`, {
                onlyPublishedStorageLocations: true,
                bundleStorageLocations: true,
            });
            setDataInventory(res);
        } catch (err) {
            await logError(`settings.loadDataInventory`, err);
        }
    };

    useEffect(() => {
        loadDataInventory();

        // eslint-disable-next-line
    }, []);

    return null;
};

export default Component;
