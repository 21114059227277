import React, { useEffect, useState } from 'react';
import { AuthStore } from '../../../utils/context/authStore';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { logError, trackUserInteraction } from '../../../utils/helpers';
import Container from '../../layout/container';

// Components
import Menu from './components/menu';

// Sections
import Badges from './sections/badges';
import Certificates from './sections/certificates';
import Loading from './sections/loading';

const SectionMaps = {
    Badges,
    Certificates,
    Loading,
};

const convertComponentNameToUrl = name => {
    let newName = name.toLocaleLowerCase();
    return newName;
};

const getDefaultSection = tabUrl => {
    let tabMatch = Object.keys(SectionMaps).find(comp => convertComponentNameToUrl(comp) === tabUrl);
    if (tabMatch) return tabMatch;
    return 'Badges';
};

const Shareables = props => {
    const [sectionRendered, setSectionRendered] = useState(getDefaultSection(props.match.params.tab));
    const [data, setData] = useState(undefined);
    const { account } = AuthStore();

    const SectionProps = {
        sectionRendered,
        setSectionRendered,
        data,
        setData,
        companyId: account._companyId,
    };

    const SectionComponent = SectionMaps[!data ? 'Loading' : sectionRendered];

    useEffect(() => {
        const asyncLoadData = async () => {
            try {
                await trackUserInteraction(`Shareables`);
                const pp = await makeEndpointRequest(`getPrivacyPortal`, { companyId: account._companyId });
                const ci = await makeEndpointRequest('GetGDPREssentialsCertificate', { companyId: account._companyId });
                const dd = await makeEndpointRequest('getDashboardDetails');
                setData({
                    company: pp,
                    dashboardDetails: dd,
                    certificates: [ci],
                });
            } catch (err) {
                await logError(`LOAD_GOODIES`, err);
                await trackUserInteraction(`Having Difficulties`, {
                    reason: `Loading goodies failed`,
                });
                setData(null);
            }
        };
        asyncLoadData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.history.pushState('_', '_', `/shareables/${convertComponentNameToUrl(sectionRendered)}`);
    }, [sectionRendered]);

    return (
        <Container title={`Shareables`} classNames="page-shareables">
            <div className="page-title">Shareables</div>
            <div className="page-content">
                <Menu data={data} value={sectionRendered} onChange={val => setSectionRendered(val)} />
                <SectionComponent {...SectionProps} />
            </div>
        </Container>
    );
};

export default Shareables;
