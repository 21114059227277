import React from 'react';
import { Button } from '@mui/material';
import { trackUserInteraction } from '../../../../utils/helpers';

const Component = () => {
    const triggerPreview = async () => {
        await trackUserInteraction(`Selected "Preview"`, { reason: 'To preview the privacy portal' });
        document.dispatchEvent(new CustomEvent('openSettingsPreviewPanel'));
    };

    return (
        <React.Fragment>
            <div className="page-alignment-right-side">
                <div className="preview">
                    <div className="image" onClick={triggerPreview}>
                        <div className="overlay">
                            <i className="icon fa-solid fa-eye"></i>
                        </div>
                    </div>
                    <div className="button-container">
                        <Button variant="contained" color="primary" onClick={triggerPreview} data-cy="preview-button">
                            Preview
                        </Button>
                    </div>
                </div>
                {/* <div className="tips-section">
                    <div className="heading">
                        <div className="icon">
                            <i className="elm fa-solid fa-lightbulb-on"></i>
                        </div>
                        <div className="text">Tips:</div>
                    </div>
                    <div className="tips-entries">
                        <div className="entry">
                            <div className="header">What is a Privacy Policy?</div>
                            <div className="response">
                                A privacy policy (also referred to as a privacy notice) is a statement or legal document that describes how
                                a company, website, or app collects, uses, maintains and shares information collected from or about its
                                users. Most countries require any website that collects personal information from its users to have a writen
                                privacy policy posted on the website.
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </React.Fragment>
    );
};

export default Component;
