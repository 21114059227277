import React from 'react';

// Components
import Entry from './entry';

const Component = (props) => {
    const getTitle = () => {
        if (props.id === 'missingInformation') return `Missing Information (${props.entries.length})`;
        if (props.id === 'processor') return `Acting as a Processor`;
        if (props.id === 'controller') return `Acting as a Controller`;
        if (props.id === 'jointController') return `Acting as a Joint Controller`;
    };

    const getDescription = () => {
        if (props.id === 'missingInformation')
            return `We're missing some information for the following Processes. Complete the missing information so they appear in your policies.`;

        if (props.id == 'processor')
            return `Activities you conduct when your company (acting as a processor) processes data on behalf of your customer (who acts as controller). These don’t require a legal basis and will not directly appear in your privacy policy - instead they will be part of a Data Processing Agreement (DPA) with your customers.`;

        if (props.id === 'controller')
            return `Core activities your company (acting as a controller) conducts whilst engaging with your leads, providing a service to your customers and working with your staff. These processes will appear in your privacy policy.`;

        if (props.id === 'jointController')
            return `Engaging in data processing activities collaboratively with another entity as joint controllers. This entails shared responsibilities and joint decision-making regarding the processing of personal data, often requiring clear agreements and coordination.`;
    };

    return (
        <React.Fragment>
            <div className="component-group">
                <div className="--component-header">
                    <div className="title">{getTitle()}</div>
                    <div className="message">{getDescription()}</div>
                </div>
                <div className="--component-entries">
                    {props.entries.map((c, index) => (
                        <Entry data={c} key={index} groupId={props.id} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
