import React, { useEffect } from 'react';
import { trackUserInteraction } from '../../../utils/helpers';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

import Layout from '../../layout/container';

const UnsuccessfulPayment = () => {
    const router = useHistory();

    useEffect(() => {
        const exec = async () => {
            await trackUserInteraction(`Payment unsuccessful`);
            setTimeout(() => {
                if (1 === 1) return false;
                router.push('/');
            }, 3000);
        };
        exec();
        // eslint-disable-next-line
    }, []);

    const tryAgain = async () => {
        await trackUserInteraction(`Selected "Try again"`);
        router.push('/');
    };

    const contactUs = async () => {
        if (window.HubSpotConversations === undefined && window.location.href.includes('localhost:3000')) return false; // blocking it on localhost since it won't work (chat is disabled)
        await trackUserInteraction(`Selected "Contact us"`, { reason: 'Transaction failure' });
        window.HubSpotConversations.widget.open();
    };

    return (
        <Layout title="Payment unsuccessful" classNames="page-failure-payment">
            <div className="page-content">
                <div className="content">
                    <i className="icon fa-light fa-triangle-exclamation"></i>
                    <div className="heading">Transaction Failure</div>
                    <div className="message">
                        Please try again with another card. Get in touch with us for more help through Live Chat or by emailing us at
                        support@privasee.io
                    </div>
                    <div className="buttons">
                        <Button onClick={tryAgain} variant="contained" color="primary">
                            Try again
                        </Button>
                        <Button onClick={contactUs} variant="outlined" color="primary">
                            Contact us
                        </Button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default UnsuccessfulPayment;
