import { useEffect } from 'react';

// Context
import { ModuleState } from '..';

// Dependencies
import { makeEndpointRequest } from '../../../../utils/endpoints';
import { getFromUrlQueryString, logError, removeFromUrlQueryString, setInUrlQueryString } from '../../../../utils/helpers';

const Component = () => {
    const { panelData, companyData, setCompanyData, setPanelData, data, setToolsRecommended, setVitalRecommendations } = ModuleState();

    const loadCompanyData = async () => {
        try {
            // Replace this with a await endpoint request.
            const res = await makeEndpointRequest(`GetCompanyData`);
            setCompanyData(res);

            // Resume panel data
            resumePanelDataFromURL();
        } catch (err) {
            await logError(`individuals.loadCompanyData`, err);
        }
    };

    const resumePanelDataFromURL = async () => {
        try {
            const id = getFromUrlQueryString('individual');
            if (!id) return false;

            const matchIndividual = data.individuals.find((c) => `${c._id}` === id);
            if (matchIndividual) {
                setPanelData({
                    method: 'edit',
                    _id: matchIndividual._id,
                });
            } else {
                removeFromUrlQueryString('individual');
            }
        } catch (err) {
            await logError(`individuals.resumePanelDataFromURL`, err);
        }
    };

    const loadRecommendedTools = async () => {
        try {
            const data = await makeEndpointRequest(`getStorageLocationsOptions`);
            setToolsRecommended(data);
        } catch (err) {
            await logError('individuals.loadRecommendedTools', err);
        }
    };

    const loadVitalRecommendations = async () => {
        try {
            const res = await makeEndpointRequest('GetVitalRecommendations');
            setVitalRecommendations(res);
        } catch (err) {
            await logError(`individuals.loadVitalRecommendations`, err);
        }
    };

    const onPanelDataChanging = async () => {
        try {
            if (panelData.method === 'edit' && panelData._id) {
                setInUrlQueryString('individual', panelData._id);
            } else if (panelData.method === null && getFromUrlQueryString('individual')) {
                removeFromUrlQueryString('individual');
            }
        } catch (err) {
            await logError(`individuals.onPanelDataChanging`, err);
        }
    };

    useEffect(() => {
        if (companyData) {
            onPanelDataChanging();
        }
        // eslint-disable-next-line
    }, [panelData]);

    useEffect(() => {
        // Load company data
        loadCompanyData();

        // Load tools recommendations
        loadRecommendedTools();

        // Load vital recommendations
        loadVitalRecommendations();

        // eslint-disable-next-line
    }, []);

    return null;
};

export default Component;
