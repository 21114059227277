import React from 'react';
import { getValidationPropFields } from '../../../../../../../../../../utils/helpers';

// Components
import { TextField } from '@mui/material';

const Component = (props) => {
    return (
        <React.Fragment>
            <div className="form-group extra-spacing">
                <div className="form-label mb">Sub-processor name</div>
                <div className="form-content">
                    <TextField
                        placeholder="Label"
                        variant="standard"
                        value={props.data.label}
                        onChange={(ev) => {
                            props.updateField('label', ev.target.value);
                        }}
                        fullWidth={true}
                        {...getValidationPropFields(props.validationResults, true, `label`)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
