import React from 'react';

// Context
import { OnboardingFunctions } from '../../../../components/functions';
import { Button } from '@mui/material';
import { OnboardingContext } from '../../../..';
import { ViewContext } from '../..';

const Component = () => {
    const { navigateViews } = OnboardingFunctions();
    const { dataInventory } = OnboardingContext();
    const { validateView } = ViewContext();

    const nextStep = () => {
        // Validate and if everything is alright we move forward.
        validateView(() => {
            navigateViews('forward');
        });
    };

    const previousStep = () => {
        navigateViews('backward');
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">Review your tools and third parties</div>
                    <div className="subtitle">
                        We've found the most common uses, security measures and data types for your tools. Please check the information
                        below before moving forward.
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={dataInventory.storageLocations.length < 1}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
