import React, { createContext, useContext, useRef } from 'react';
import { ModuleState } from '../../..';
import { convertMatriceEntriesToTools } from '../../../panel/views/tools/components/utils/functions';
import { logError, trackUserInteraction } from '../../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { getCurrentMatriceEntries, updateStorageLocationRelationships } from '../../../utils/functions';

// Context
const Context = createContext({});
export const FunctionsContext = () => useContext(Context);

const Component = (props) => {
    const { data: dataInventory, panelData, setPanelData, companyData } = ModuleState();
    const { refreshDataInventory, refreshCompanyData } = ModuleState();

    // This will be used so we can set the panel's view and more from outside it.
    const panelController = useRef(null);

    const closePanel = () => {
        // Close the panel
        panelController.current.modal.current.close(() => {
            // Reset it.
            setPanelData({ _id: null, method: null });
        });
    };

    /**
     * This function is used to generate the data required for the panel interface.
     * @returns
     */

    const getInitialData = () => {
        // Get the data of the process
        const currentData = panelData.method === 'edit' ? dataInventory.processes.find((c) => c._id === panelData._id) || {} : {};

        // The array of tools that are linked to this process.
        let tools = [];

        // If there is an id let's convert current matrices to tools linked format of data.
        if (currentData._id) {
            tools = convertMatriceEntriesToTools(dataInventory, currentData._id);
        }

        // Check if it's a process service (Core Service)
        const isProcessServices = currentData ? (companyData.services.find((d) => d.label === currentData.label) ? true : false) : false;

        // @Bugfix: we need to do the json parse, json stringify to remove any link to the useState, otherwise when you changed something then simply closed the panel, the data inventory was updated in hook state.

        return JSON.parse(
            JSON.stringify({
                _id: currentData._id || null,
                label: currentData.label || '',
                description: currentData.description || '',
                role: currentData.role || null,
                personalLegalBasis: currentData.personalLegalBasis || null,
                sensitiveLegalBasis: currentData.sensitiveLegalBasis || null,
                isCoreService: isProcessServices,
                tools,
                retention: currentData.retention
                    ? currentData.retention
                    : {
                          type: null,
                          customText: '',
                          specificTime: {
                              value: 0,
                              interval: 'Days',
                          },
                      },
            }),
        );
    };

    /**
     * A simple function to convert our tools to matrice entries
     * @param {*} _storageLocationId
     * @param {*} tools
     * @returns
     */

    const convertToolsToMatriceEntries = (_storageLocationId, _processId, tools) => {
        try {
            const match = tools.find((c) => c._storageLocationId === _storageLocationId);
            if (!match) return []; // none.

            // Get current matrice entries for this process.
            const processEntries = [];

            // Iterate through individuals
            match.individuals.forEach((individual) => {
                individual.elements.forEach((_elementId) => {
                    processEntries.push({
                        _processId,
                        _individualId: individual._individualId,
                        _elementId,
                    });
                });
            });

            return processEntries;
        } catch (err) {
            logError(`module.processes.getToolMatriceEntries`, err);
            throw err;
        }
    };

    /**
     * This function will set the matrice entries based on the tools array from the panel data.
     * @param {*} _processId
     * @param {*} tools
     * @returns
     */

    const setMatriceEntries = async (_processId, tools) => {
        try {
            // We'll refresh data inventory to make sure we'll not overwrite existing matrice entries.
            const inventoryUpdated = await refreshDataInventory();

            // We'll iterate through each tool in storage location.
            for (const storageLocation of inventoryUpdated.storageLocations) {
                try {
                    // Get current matrice entries for this process. (And filter invalid ones out)
                    const processEntries = convertToolsToMatriceEntries(storageLocation._id, _processId, tools);

                    // We'll go through this storage location only if it HAD this process  or if we have matrice entries for it now.
                    if (!storageLocation.processes.includes(_processId) && processEntries.length < 1) continue;

                    // Get current matrice entries from DB.
                    let entries = await getCurrentMatriceEntries(storageLocation._id, inventoryUpdated);

                    // Remove existing ones from this tool linked to this process
                    entries = entries.filter((c) => c._processId !== _processId);

                    const newEntries = [
                        // Existing entries (not related to this process)
                        ...entries,
                        // This new process entries
                        ...processEntries,
                    ];

                    // Set the new processes.
                    await makeEndpointRequest(`SetStorageMatriceEntries`, {
                        _id: storageLocation._id,
                        entries: newEntries,
                    });

                    // Update location data to reflect that is using this process id.
                    await updateStorageLocationRelationships(storageLocation, newEntries);
                } catch (err) {
                    await logError(`module.processes.setMatriceEntries.toolIterated`, err, {
                        storageLocationId: storageLocation._id,
                        _processId,
                    });
                }
            }

            // Refresh the inventory again now to apply relationships too
            refreshDataInventory();
        } catch (err) {
            await logError(`module.processes.setMatriceEntries`, err, { _processId, tools });
            return false;
        }
    };

    /**
     * A simple function that creates the process in our database and then sets the matrice entries.
     * @param {*} param.data - The data from the panel
     * @param {*} param.controller - The controller to control the panel from within (set tab etc)
     * @param {*} param.validation - A validation object that informs you if the form was valid and if not which one.
     */

    const createProcess = async ({ data }) => {
        try {
            trackUserInteraction(`Selected "Create Process"`, { processName: data.label });

            // Create the process
            const process = await makeEndpointRequest(`CreateProcess`, {
                payload: {
                    label: data.label,
                    description: data.description,
                    role: data.role,
                    personalLegalBasis: data.personalLegalBasis,
                    sensitiveLegalBasis: data.sensitiveLegalBasis,
                    retention: data.retention,
                },
                additionalPayload: {
                    isCoreService: data.isCoreService ? true : false,
                },
            });

            // // eslint-disable-next-line
            // console.log('Process created', process);

            // Update the data matrice accordingly (While filtering out unfinished tools)
            // @Reminder: this will also update data inventory and set the new process in the data inventory.
            await setMatriceEntries(process._id, data.tools);

            // We will refresh the company data in the background because it may have updated the core services.
            refreshCompanyData();

            // Close panel
            closePanel();
        } catch (err) {
            await logError(`module.processes.createProcess`, err, { data });
        }
    };

    /**
     * A simple function that updates the process in our database and then sets the matrice entries.
     * @param {*} param.data - The data from the panel
     * @param {*} param.controller - The controller to control the panel from within (set tab etc)
     * @param {*} param.validation - A validation object that informs you if the form was valid and if not which one.
     */

    const updateProcess = async ({ data }) => {
        try {
            trackUserInteraction(`Selected "Update Process"`, { processName: data.label });

            // If is not valid warn

            // Update process
            await makeEndpointRequest(`UpdateProcess`, {
                _id: data._id,
                payload: {
                    label: data.label,
                    description: data.description,
                    role: data.role,
                    personalLegalBasis: data.personalLegalBasis,
                    sensitiveLegalBasis: data.sensitiveLegalBasis,
                    retention: data.retention,
                },
                additionalPayload: {
                    isCoreService: data.isCoreService ? true : false,
                },
            });

            // Update matrice
            await setMatriceEntries(data._id, data.tools);

            // Close panel to avoid crashes
            closePanel();

            // We will refresh the company data in the background because it may have updated the core services.
            refreshCompanyData();

            // // eslint-disable-next-line
            // console.log(`update`, { data, controller, validation });
        } catch (err) {
            await logError(`module.processes.updateProcess`, err, { data });
        }
    };

    /**
     * A simple function that deletes the process from our database and then sets the matrice entries to remove any links to this process.
     * @param {*} param.data - The data from the panel
     */

    const deleteProcess = async ({ data }) => {
        try {
            trackUserInteraction(`Selected "Delete Process"`, { processName: data.label });

            // Close panel to avoid crashes
            closePanel();

            // Delete it using the API
            await makeEndpointRequest(`DeleteProcess`, { _id: data._id });

            // Refresh inventory to be squeaky clean.
            refreshDataInventory();
        } catch (err) {
            await logError(`module.processes.deleteProcess`, err, { data });
        }
    };

    const PassedProps = {
        panelController,
        closePanel,
        getInitialData,
        createProcess,
        updateProcess,
        deleteProcess,
    };

    return <Context.Provider value={PassedProps}>{props.children}</Context.Provider>;
};

export default Component;
