import React, { createContext, useContext, useState } from 'react';

// Components
import Header from './components/header';
import Type from './components/type';
import Size from './components/size';

// Context
import { OnboardingContext } from '../..';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

// Dependencies
import { types, sizes } from '../../../gdprEssentials/steps/companyInsights';

const Component = () => {
    const { companyData } = OnboardingContext();

    const [options, setOptions] = useState(companyData.companyInsights);

    const PassedProps = {
        options,
        setOptions,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <div className="company-insights module-content">
                <div className="section-title">What kind of funding do you have?</div>
                <div className="entries types d-flex-row-columns centered">
                    {types.map((entry, index) => (
                        <Type data={entry} key={index} />
                    ))}
                </div>
                <div className="divider-line"></div>
                <div className="section-title">How many people work at this company?</div>
                <div className="entries sizes d-flex-row-columns centered">
                    {sizes.map((entry, index) => (
                        <Size key={index} data={entry} />
                    ))}
                </div>
            </div>
        </Context.Provider>
    );
};

export default Component;
