import { logError } from '../../../../../utils/helpers';
import { sortSubProcessors } from '../subProcessors/utils';

export const getModules = (securityMeasures) => {
    const arr = [];

    // The processor module
    arr.push({
        label: 'Processor Module',
        description: 'Map the data that you use to offer your services.',
        entries: [
            // array of tasks
            {
                id: 'service-information', // the task id that will be used to validate if a certain step (which may include more than 1 route)
                label: 'Information about your service (2 mins)',
            },
            {
                id: 'roles',
                label: 'Your role in this DPA (2 mins)',
            },
            {
                id: 'sub-processors',
                label: 'Sub-processors (3 mins)',
            },
            {
                label: 'Restricted transfers (1 min)',
                id: 'restricted-transfers',
            },

            {
                id: 'terms',
                label: `The terms of your DPA (8 mins)`,
            },
        ],
    });

    // We need now to create the security measure group
    let securityMeasuresSections = [];

    // We need to create the sections
    securityMeasures.forEach((s) => {
        // Generate the slug which for routing.
        const slugId = convertToSlug(s.details.section);

        // Is there a section already created for this label?
        const match = securityMeasuresSections.find((e) => e.id == slugId && e.label === s.details.section);

        // If not let's create one..
        if (!match) {
            securityMeasuresSections.push({
                id: slugId,
                label: s.details.section,
                type: 'securityMeasure',
            });
        }
    });

    arr.push({
        label: 'Security Measures',
        description: 'Tell us about the security measures at your company.',
        entries: securityMeasuresSections.sort((a, b) => a.label.localeCompare(b.label)), // sorting alphabetically,
    });

    return arr;
};

export const isTaskCompleted = (id, data, secondRouteId = null, securityMeasures = null) => {
    try {
        // If is about service info
        if (id === 'service-information') {
            // Is title and description entered?
            if (data.services.length < 1 || data.serviceDescription.length < 1 || data.termsOfServiceURL.length < 1) return false;

            // Services mapping..
            if (data.elements.length < 1 || data.individuals.length < 1 || data.isSensitiveData === null) return false;

            return true;
        }

        if (id === 'sub-processors') {
            if (data.subProcessors.length < 1) return false;
            const { invalids } = sortSubProcessors(data.subProcessors);
            if (invalids.length > 1) return false;
            return true;
        }

        if (id === 'sub-processors') {
            if (data.subProcessors.length < 1) return false;
            return true;
        }

        if (id === 'security-measures' && secondRouteId !== null && securityMeasures !== null) {
            const sectionEntries = securityMeasures.filter((e) => convertToSlug(e.details.section) === secondRouteId);
            const hasEntries = sectionEntries.find((x) => (data.securityMeasures.find((s) => s._id === x._id) ? true : false));

            return hasEntries !== undefined ? true : false;
        }

        if (id === 'restricted-transfers') {
            if (data.dataTransferredOutsideEEA.length < 1) return false;
            if (!data.dataTransferredOutsideEEA.includes('No') && data.dataTransferredIndividuals.length < 1) return false;
            return true;
        }

        if (id === 'roles') {
            if (data.parties.sender.role.length < 1 || data.parties.receiver.role.length < 1) return false;
            return true;
        }

        if (id === 'terms') {
            if (data.breachNotificationPeriod === null) return false;
            if (data.subProcessorNotificationPeriod === null) return false;
            if (data.liabilityCapEnabled === null) return false;
            if (data.liabilityCapEnabled === true && data.liabilityCapAmount === null) return false;
            if (data.governingLawAndJurisdiction === null) return false;
            return true;
        }
        return false;
    } catch (err) {
        logError(`ERROR_MODULE_STEP_COMPLETED`, err, { id });
        return false;
    }
};

export const convertToSlug = (id) =>
    id
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
