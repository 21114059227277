import React from 'react';

// Components
import AutoComplete from '../../../../thirdParties/components/editPanel/components/autocomplete';

// Context
import { ViewContext } from '..';
import { OnboardingContext } from '../../..';

const Component = () => {
    const { activities, setActivities } = ViewContext();
    const { tasksPending } = OnboardingContext();

    const onActivityAdded = (entry) => {
        // Add it.
        setActivities((curr) => [...curr, { label: entry.label, origin: 'manual' }]);
    };

    const onActivityRemoved = (entry) => {
        // Remove it
        setActivities((curr) => curr.filter((d) => d.label !== entry.label));
    };

    return (
        <React.Fragment>
            <AutoComplete
                // The value and options
                value={activities}
                disabled={tasksPending.generatingActivities}
                options={[]}
                // Props to the autocomplete..
                groupCategories={false}
                subjectsView={false}
                placeholder={'Please enter your activity here and then press Enter.'}
                // Callbacks when data is changed
                onEntityAdded={(elm) => onActivityAdded(elm)}
                onEntityRemoved={(elm) => onActivityRemoved(elm)}
                notCreatable={false}
            />
        </React.Fragment>
    );
};

export default Component;
