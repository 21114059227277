import React, { useEffect, useState } from 'react';

import { Tabs, Tab } from '@mui/material';
import { PanelContext } from '../..';

const Component = () => {
    const { data, tab, setTab, flags, validateView } = PanelContext();

    const [entries, setEntries] = useState([]);

    const onTabChanged = (value) => setTab(value);

    const setTabs = async () => {
        const validInformation = await validateView('information');
        const validDetailedView = await validateView(`detailedView`);
        const validSubProcessors = await validateView(`subProcessor`);

        const arr = [
            {
                label: `Tool Information`,
                value: `information`,
                validated: validInformation.status,
            },
            {
                label: `Detailed View`,
                value: `detailedView`,
                validated: validDetailedView.status,
            },
        ];

        if (flags.missingSubProcessors || window.isCypressTestEnvironment) {
            arr.push({
                label: `Sub-Processor`,
                value: `subProcessor`,
                validated: validSubProcessors.status,
            });
        }

        arr.push({
            label: `Danger Zone`,
            value: `dangerZone`,
            validated: true,
        });

        setEntries(arr);

        return arr;
    };

    useEffect(() => {
        setTabs();
        // eslint-disable-next-line
    }, [data, flags]);

    return (
        <React.Fragment>
            <Tabs value={tab} onChange={(_, newValue) => onTabChanged(newValue)} className="component-tabs" variant="scrollable">
                {entries.map((entry, index) => (
                    <Tab
                        value={entry.value}
                        data-cy={`tab-${entry.value}`}
                        key={index}
                        label={
                            <React.Fragment>
                                <div className="tab-content">
                                    <span className="text">{entry.label}</span>
                                    {entry.validated === false && entry.disabled !== true && (
                                        <div className="missing-info-warning">
                                            <i className="elm fa-solid fa-circle-exclamation"></i>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        }
                        disabled={entry.disabled ? true : false}
                    />
                ))}
            </Tabs>
        </React.Fragment>
    );
};

export default Component;
