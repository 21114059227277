import React from 'react';

// Context
import { ViewContext } from '..';
import { PanelContext } from '../../..';

// Components
import { FormHelperText, MenuItem, Select } from '@mui/material';

// Dependencies
import { securityMeasures } from '../../../../../../gdprEssentials/steps/reviewData/components/edit/pages/general';

const Component = () => {
    const { validationResults } = ViewContext();
    const { data, setData } = PanelContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <span className="text">Security Measures</span>
                    {data.securityMeasures.length < 1 && <div className="badge badge-data-warning blue" />}
                </div>
                <div className="component-content">
                    <Select
                        onChange={(ev) => setData({ ...data, securityMeasures: ev.target.value })}
                        fullWidth={true}
                        data-cy="field-securityMeasures"
                        variant="standard"
                        value={data.securityMeasures || 'disabled'}
                    >
                        <MenuItem disabled value="disabled">
                            No selection
                        </MenuItem>
                        {securityMeasures.map((c, ix) => (
                            <MenuItem key={ix} value={c}>
                                {c}
                            </MenuItem>
                        ))}
                    </Select>
                    {validationResults[`securityMeasures`] !== undefined && (
                        <FormHelperText error={true}>{validationResults['securityMeasures']}</FormHelperText>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
