import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';

const Context = () => {
    const history = useHistory();

    const [state, setState] = useState({
        active: false,
        title: null,
        message: null,
        type: null,
        buttons: [],
        options: {},
    });
    const [submitted, setSubmitted] = useState(false);
    const [inputValue, setInputValue] = useState(null);
    const iconTypes = {
        error: 'circle-exclamation',
        success: 'circle-check',
        info: `circle-info`,
        warning: 'triangle-exclamation',
    };

    const defaultButtons = [
        {
            text: `OK`,
            dataCy: 'alert-submit-button',
            onClick: ({ dismissAlert }) => {
                dismissAlert();
            },
        },
    ];

    const showAlert = (title, message, type = 'error', buttons, options = {}) => {
        if (!['error', 'warning', 'success', 'info'].includes(type)) {
            console.warn(`Type "${type}" is not a valid alert type.`);
            return false;
        }
        setSubmitted(false);

        setState({
            active: true,
            title,
            message,
            type,
            buttons: buttons || defaultButtons,
            options,
        });

        setInputValue('');
    };

    const dismissAlert = () => setState({ active: false, title: null, type: null, message: null, buttons: [], options: {} });

    window.showAlert = showAlert;

    window.showAlertWithDelay = (...params) => {
        setTimeout(() => {
            showAlert(...params);
        }, 300);
    };

    const inputState = state.options.input;

    const getInputProps = () => {
        if (!inputState || inputValue.length < 1) return {};
        const validation = inputState.validationField;
        if (!validation) return {};

        const params = {
            error: validation({ inputValue }) ? true : false,
            helperText: validation({ inputValue }) ? validation({ inputValue }) : undefined,
        };

        return params;
    };

    window.dismissAlert = dismissAlert;

    return (
        <div className={`layout-alert ${state.active && `active`}`}>
            {state.active && (
                <div className={`content ${state.options.size === 'large' && `large`}`}>
                    <i className={`icon fa-solid fa-${iconTypes[state.type]}`}></i>
                    <div className="title">{state.title}</div>
                    <div className="message">{state.message}</div>
                    {inputState && (
                        <React.Fragment>
                            <div className="input-container">
                                <TextField
                                    placeholder={inputState.placeholder || ''}
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    fullWidth={true}
                                    {...getInputProps()}
                                />
                            </div>
                        </React.Fragment>
                    )}
                    {state.buttons && state.buttons.length > 0 && (
                        <React.Fragment>
                            <div className="buttons">
                                {state.buttons.map((button, index) => (
                                    <Button
                                        key={index}
                                        variant={button.variant || 'contained'}
                                        color={button.color || 'primary'}
                                        disabled={button.disabled || submitted || false}
                                        className={`${button.fullWidth && 'fullWidth'}`}
                                        onClick={
                                            button.onClick
                                                ? () => button.onClick({ history, dismissAlert, setSubmitted, inputValue })
                                                : undefined
                                        }
                                        data-cy={button.dataCy || undefined}
                                    >
                                        {button.text}
                                    </Button>
                                ))}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    );
};

export default Context;
