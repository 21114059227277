import React, { createContext, useContext, useState } from 'react';

// Components
import Header from './components/header';
import Option from './components/option';

// Context
import { OnboardingContext } from '../..';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

// Dependencies
import { entries } from '../../../gdprEssentials/steps/audience';

const Component = () => {
    const { companyData } = OnboardingContext();

    const [currentOptions, setCurrentOptions] = useState(companyData.targetAudience);

    /**
     * When we click on an option we need to either add it to the array of audiences or remove it.
     * @param {*} value
     */

    const selectOption = (value) => {
        setCurrentOptions((arr) => {
            return arr.includes(value) ? arr.filter((item) => item !== value) : [...arr, value];
        });
    };

    const PassedProps = {
        currentOptions,
        setCurrentOptions,
        selectOption,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <div className="target-audience module-content">
                <div className="entries d-flex-row-columns centered">
                    {entries.map((c, ix) => (
                        <Option key={ix} icon={`fa-${c.icon}`} label={`${c.label}`} value={`${c.value}`} description={c.description} />
                    ))}
                </div>
            </div>
        </Context.Provider>
    );
};

export default Component;
