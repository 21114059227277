import Axios from 'axios';

import authEndpoints from './entries/auth_endpoints';
import gdprEssentials from './entries/modules/gdpr_essentials';
import dataInventoryEndpoints from './entries/data_inventory_endpoints';
import privacyCenterEndPoints from './entries/privacy_center_endpoints';
import certificateEndpoints from './entries/certificate_endpoints';
import userEndpoints from './entries/user_endpoints';
import dashboardDetails from './entries/dashboard';
import analyticalEndpoints from './entries/analytics';
import auditsEndpoints from './entries/audits';
import shareableEndpoints from './entries/shareables';
import workspaceEndpoints from './entries/workspaces';
import settings from './entries/settings';
import pricingPlans from './entries/pricingPlans';
import scanners from './entries/scanners';
import dpaModule from './entries/modules/dpas';
import securityMeasures from './entries/securityMeasures';
import ThirdParties from './entries/modules/thirdParties';
import CookieBanner from './entries/cookieBanner';
import ai from './entries/ai';

const endpointsMap = {
    ...authEndpoints,
    ...gdprEssentials,
    ...dataInventoryEndpoints,
    ...privacyCenterEndPoints,
    ...certificateEndpoints,
    ...userEndpoints,
    ...dashboardDetails,
    ...analyticalEndpoints,
    ...auditsEndpoints,
    ...shareableEndpoints,
    ...workspaceEndpoints,
    ...settings,
    ...pricingPlans,
    ...scanners,
    ...dpaModule,
    ...securityMeasures,
    ...ai,
    ...ThirdParties,
    ...CookieBanner,
};

const getApiDomain = () => {
    let apiDomain = 'http://localhost:5000';

    if (window.location.href.includes(`staging.`)) {
        apiDomain = `https://staging.privasee.io`;
    }

    if (window.location.href.includes(`app.`)) {
        apiDomain = `https://app.privasee.io`;
    }
    return apiDomain;
};
export const makeEndpointRequest = async (endpointGroup, bodyData, extraOptions = {}) => {
    try {
        const endpoint = endpointsMap[endpointGroup] || {};
        let headerProps = endpoint && endpoint.headers ? endpoint.headers : {};

        if (extraOptions && extraOptions.headers) {
            headerProps = {
                ...headerProps,
                ...extraOptions.headers,
            };
        }

        const bodyRequest = {
            method: endpoint.method,
            withCredentials: true,
            headers: headerProps,
            data: { query: endpoint.query, variables: bodyData },
            timeout: endpoint.timeout ? endpoint.timeout : 30000,
        };

        const apiDomain = getApiDomain();

        const endpointUrl = `${apiDomain}${encodeURI(endpoint.link)}`;

        const { data } = (await Axios(endpointUrl, bodyRequest)) || {};

        if (data.errors !== undefined) {
            // Proper error handling
            let err = new Error(data.errors[0].message || `Unknown error message`); // Just in case the back-end goes wild one day
            err.status = 400;
            err.response = data.errors; // So we can access and log the errors array too.
            throw err;
        }

        if (!data) {
            throw Error('GraphQL endpoint returned error');
        }
        return endpoint.parserFunction ? endpoint.parserFunction(data) : data;
    } catch (err) {
        if (!['GetCurrentUser', `Logout`].includes(endpointGroup)) {
            if (err.response && err.response.data && err.response.data.error === 'Please log in!') {
                document.dispatchEvent(new CustomEvent('onSessionTimeOut'));
                return false;
            }
        }

        throw err;
    }
};

// This will make a call to the local api instead of the graphql usual api. (localhost:5000/{link})

export const makeLocalEndpointRequest = async (link, bodyData, method = 'POST', parserFunction = null, extraOptions = {}) => {
    try {
        const apiDomain = getApiDomain();
        const headers = extraOptions.headers || {};
        const { data } = await Axios(`${apiDomain}${link}`, {
            method,
            responseType: extraOptions.responseType || undefined,
            data: bodyData,
            withCredentials: true,
            headers: {
                ...headers,
            },
            ...(extraOptions.axios || {}),
        });
        return parserFunction ? parserFunction(data) : data;
    } catch (err) {
        throw err;
    }
};
