import React from 'react';

// Context
import { PanelContext } from '../../..';

// Components
import { Button } from '@mui/material';

const Component = () => {
    const { showCustomToolModal, searchValue } = PanelContext();

    return (
        <React.Fragment>
            <div className="no-results">
                <div className="heading">No results</div>
                <div className="description">There is no tool or third party matching this name in our list of recommendations.</div>
                <div className="advice">Press the button below to add it as a custom tool or third party.</div>
                <Button
                    data-cy="create-custom-tool-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => showCustomToolModal(searchValue)}
                >
                    Create custom tool
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Component;
