import React, { useEffect } from 'react';

const Component = (props) => {
    const getIntervals = () => {
        // This filter will remove any currency that does not have a stripe pricing id linked to the package.

        let arr = [
            {
                label: 'Monthly',
                format: 'monthly',
            },
            {
                label: 'Yearly',
                format: 'yearly',
            },
        ].filter((c) => props.plans.find((i) => i.recurring === c.format));

        return arr;
    };

    useEffect(() => {
        const arr = getIntervals();
        // If it's only GBP let's show GBP.
        if (arr.length === 1 || !arr.find((x) => x.format === props.recurring)) {
            props.setRecurring(arr[0].format);
        }
        // eslint-disable-next-line
    }, []);

    if (getIntervals().length < 2) return null;

    return (
        <React.Fragment>
            {/* If that filter is activated and we have only one or none (if something uses the api wrong) */}
            {getIntervals().length > 1 && (
                <div className="recurring">
                    <div className="options">
                        {getIntervals().map((entry, index) => (
                            <div
                                onClick={() => props.setRecurring(entry.format)}
                                className={`option ${props.recurring === entry.format}`}
                                key={index}
                            >
                                {entry.label}
                                {entry.label === 'Yearly' && <div className="yearly-off">25% Off</div>}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Component;
