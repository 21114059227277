const gdprEssentialsWizardEndpoints = {
    GetGDPREssentialsCertificate: {
        link: '/guest/graphql',
        method: 'POST',
        query: `
        query GetGDPREssentialsCertificate ($companyId: String) {
          GetGDPREssentialsCertificate (companyId: $companyId) {
            _id,
            type,
            dateCreated,
            dateLastVerify,
            dateNextVerify,
            companyLegalName,
            companyAddress{
              line1,
              line2,
              city,
              country,
            },
            dpoName,
            }
          }`,
        parserFunction: ({ data }) => data.GetGDPREssentialsCertificate,
    },
    GetOpenLocationsInformation: {
        link: '/guest/graphql',
        method: 'POST',
        query: `
        query GetStorageLocationRecommendations {
            GetStorageLocationRecommendations {
                label,
                type,
                category,
                description,
                cookieType,
                cookiePurpose,
                cookieExternalInfo,
                dataResidency,
                securityMeasures,
            }
          }`,
    },
};

export default gdprEssentialsWizardEndpoints;
