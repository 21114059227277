const authEndpoints = {
    Login: {
        link: '/auth/graphql',
        method: 'POST',
        query: `
        mutation
          AuthLogin ($subscription: JSON)
          {
            AuthLogin (subscription: $subscription)
          }`,
    },
    RedeemCouponSignUp: {
        link: '/guest/graphql',
        method: 'POST',
        query: `
          mutation RedeemCouponSignUp ($email: String, $promotionalCode: String)
            {
              RedeemCouponSignUp (email: $email, promotionalCode: $promotionalCode )
            }
        `,
    },
    Logout: {
        link: '/graphql',
        method: 'POST',
        query: `
        query
          AuthLogOut
          {
            AuthLogOut
          }`,
    },
    GetCurrentUser: {
        link: '/graphql',
        method: 'POST',
        query: `
        query {
          GetCurrentUser {
            _id
            _companyId
            email
            isAdmin
          }
        }`,
    },
    GetUserSettingId: {
        link: '/graphql',
        method: 'POST',
        query: `
              query { GetUserSettingId }`,
    },
    GetUserAccountInformation: {
        link: '/graphql',
        method: 'POST',
        query: `
              query {
                GetUserAccountInformation {
                  registerDate
                  completedGDPREssentials
                  stripeData {
                    stripeCustomerId
                    stripeSubscriptionId
                    subscriptionEndDate
                    plan
                    currency
                  }
                  usercentricsSettingsId
                  companyPackage {
                    _id
                    name
                    usageLimits
                    pricingTableDetails
                    features
                  }
                  workspace
                  companyCreatedAt
                }
              }`,
    },
};

export default authEndpoints;
