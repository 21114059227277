// This will detect when new processes are added
// And will ask the user if he wants to sync them across to their DPA in draft state.

import { useEffect } from 'react';

// Dependencies
import { useStateRef } from '../../../../../../../utils/helpers';

const Component = () => {
    // eslint-disable-next-line
    const [editingStorageLabel, setEditingStorageLabel, editingStorageLabelRef] = useStateRef(false);
    // eslint-disable-next-line
    const [processes, setProcesses, processesRef] = useStateRef([]);

    const onEditStorage = ({ detail: label }) => {
        // When we stop editing
        if (label === null) {
            // If we stopped editing and now we have processes and we have a dpa..
            if (processesRef.current.length > 0) {
                // Dispatch an event that tells us that a process have been added and saved
                document.dispatchEvent(
                    new CustomEvent(`reviewData:done.processAdded`, {
                        detail: { label: editingStorageLabelRef.current, processes: processesRef.current },
                    }),
                );
            }

            // Clear out processes..
            setProcesses([]);
        }

        // Update the state..
        setEditingStorageLabel(label);
    };

    const onProcessAdded = ({ detail: processName }) => {
        // Is not active..
        if (!editingStorageLabelRef.current) return false;

        // Add the process..
        setProcesses((currentState) => [...currentState, processName]);
    };

    const onProcessRemoved = ({ detail: processName }) => {
        // Is not active..
        if (!editingStorageLabelRef.current) return false;

        // Remove the process IF it was added during this session of editing.
        setProcesses((currentState) => {
            return currentState.filter((c) => c !== processName);
        });
    };

    useEffect(() => {
        document.addEventListener(`reviewData:editing`, onEditStorage);
        document.addEventListener(`reviewData:edit.processAdded`, onProcessAdded);
        document.addEventListener(`reviewData:edit.processRemoved`, onProcessRemoved);

        return () => {
            document.removeEventListener(`reviewData:editing`, onEditStorage);
            document.removeEventListener(`reviewData:edit.processAdded`, onProcessAdded);
            document.removeEventListener(`reviewData:edit.processRemoved`, onProcessRemoved);
        };

        // eslint-disable-next-line
    }, []);

    return null;
};

export default Component;
