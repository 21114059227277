import React from 'react';
import { useHistory } from 'react-router-dom';

const Shortcut = () => {
    const history = useHistory();

    const goTo = () => history.push('/cookie-banner');

    return (
        <React.Fragment>
            <div className="card-bg shortcut-card shareables">
                <div className="header">
                    <div className="title">
                        Cookie Banner
                        {/* <span className="badge">New</span> */}
                    </div>

                    <div className="arrow settings" onClick={goTo}>
                        <i className="icon fa-light fa-chevron-right"></i>
                    </div>
                </div>
                <div className="header-description">Customise and configure your cookie banner.</div>
            </div>
        </React.Fragment>
    );
};

export default Shortcut;
