import React, { useState, useEffect, createContext, useContext } from 'react';

// Components
import Name from './components/name';
import Description from './components/description';
import Role from './components/role';
import PersonalLegalBasis from './components/personalLegalBasis';
import SensitiveLegalBasis from './components/sensitiveLegalBasis';
import IsCoreService from './components/isCoreService';

// Validation
import { PanelContext } from '../..';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const [validationResults, setValidationResults] = useState({});

    // Context
    const { data, isUsingSensitiveData, validateView } = PanelContext();

    const validateFields = async () => {
        const { results } = await validateView('information');
        setValidationResults(results);
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    const PassedProps = {
        validationResults,
        setValidationResults,
    };

    return (
        <Context.Provider value={PassedProps}>
            <div className="component-forms">
                <Name />
                <Description />
                <Role />
                <IsCoreService />
                {data.role !== 'Processor' && (
                    <React.Fragment>
                        <PersonalLegalBasis />
                        {isUsingSensitiveData && <SensitiveLegalBasis />}
                    </React.Fragment>
                )}
            </div>
        </Context.Provider>
    );
};

export default Component;
