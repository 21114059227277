import React from 'react';

// Components
import Tooltip from '../../../../../../components/tooltip';
import { Avatar } from '@mui/material';

// Dependencies
import { getToolRecommendations } from '../../../../../thirdParties/components/storageLocations/components/editTools/utils/helpers';

// Context
import { ModuleState } from '../../../..';
import { getIndividualsTypes } from '../../../../../gdprEssentials/steps/individuals/components/functions';
import { trackUserInteraction } from '../../../../../../../utils/helpers';

const Component = (props) => {
    const { data, toolsRecommended, setPanelData } = ModuleState();

    const editIndividual = () => {
        setPanelData({ method: 'edit', _id: props.data._id });
        trackUserInteraction(`Selected "Edit Individual"`, {
            individualName: props.data.label,
        });
    };

    const getTools = () =>
        data.storageLocations
            .filter((c) => c.individuals.includes(props.data._id))
            .map((tool) => ({ ...tool, recommendation: getToolRecommendations(tool.label, toolsRecommended) }))
            .slice(0, 5); // only 5 max.

    const getType = () => {
        if (!props.data.individualReferring || !props.data.individualReferring.referringRole) return 'Not Selected';

        const match = getIndividualsTypes().find((c) => c.value === props.data.individualReferring.referringRole);

        return match ? match.label : 'Others';
    };

    const getProcesses = () => {
        let ids = [];

        data.matrices.forEach((matrice) => {
            matrice.entries.forEach((entry) => {
                if (entry._individualId !== props.data._id) return;

                if (!ids.includes(entry._processId)) {
                    ids.push(entry._processId);
                }
            });
        });

        const processes = data.processes.filter((c) => ids.includes(c._id)).map((c) => c.label);

        // If we have zero..
        if (processes.length < 1) return `None Selected`;

        const visiblePart = processes.slice(0, 4).join(', ');
        const invisiblePart = processes.slice(4);

        // Format..
        let str = invisiblePart.length > 0 ? `${visiblePart}, + ${invisiblePart.length} more.` : `${visiblePart}`;

        return str;
    };

    const getChildren = () => {
        if (props.data.individualReferring.isChildren === undefined) return `Not Selected`;

        return props.data.individualReferring.isChildren ? 'Yes' : 'No';
    };

    return (
        <React.Fragment>
            <div className="--component-entry" onClick={editIndividual}>
                <div className="left-side">
                    <div className="label">{props.data.label}</div>
                    <div className="details">
                        {['missingInformation'].includes(props.groupId) && <div className="entry">Type: {getType()}</div>}
                        <div className="entry">Children: {getChildren()}</div>
                    </div>
                    <div className="processes">
                        <div className="--heading">
                            <i className="icon fa-light fa-list-tree"></i>
                            Processes
                        </div>
                        <div className="--content">{getProcesses()}</div>
                    </div>

                    {props.groupId === 'missingInformation' && (
                        <div className={`miss-info-badge`}>
                            <Tooltip content={`Click here to add the missing information.`}>
                                <i className="elm fa-solid fa-circle-info"></i>
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div className="right-side">
                    <div className="tools">
                        {getTools().length > 0 ? (
                            <React.Fragment>
                                {getTools().map((c, index) => (
                                    <div className="entry" key={index}>
                                        <Avatar className="avatar" src={c.recommendation ? c.recommendation.image : undefined}>
                                            {c.label.slice(0, 2)}
                                        </Avatar>
                                    </div>
                                ))}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="placeholder-text">Tools and Third Parties - None selected.</div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
