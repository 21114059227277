import React from 'react';

// Components
import Entry from './components/entry';

// Dependencies
import { filterOptions } from '../../../../../utils/functions';

// Context
import { PanelContext } from '../../../../..';
import { CustomToolContext } from '../../../..';

const Component = () => {
    const { options } = PanelContext();
    const { searchingText } = CustomToolContext();

    const getOptions = () => filterOptions({ options, searchValue: searchingText, category: 'all' });

    return (
        <React.Fragment>
            <div className="entries">
                {/* Listing the options.. */}
                {getOptions().entries.map((c, ix) => (
                    <Entry data={c} key={ix} />
                ))}

                {/* If there are no search results */}
                {getOptions().entries.length < 1 && searchingText.length > 0 && (
                    <div className="no-results">No results for this search</div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
