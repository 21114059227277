import React, { useEffect, useState, createContext, useContext } from 'react';

// Dependencies
import Container from '../../layout/container';
import { logError } from '../../../utils/helpers';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { AuthStore } from '../../../utils/context/authStore';

// Context
const Context = createContext({});
export const PageContext = () => useContext(Context);

// UI Components
import Header from './components/header';
import List from './components/list';

// Modals
import CreateWorkspaces from './modals/createWorkspace';

const Component = () => {
    const [data, setData] = useState({ workspaces: [], selectedWorkspace: null });
    const { account } = AuthStore();

    const loadData = async () => {
        try {
            // Fetch the data
            // @Reminder: this api has a imperfect query to the also the package details, that should be improved if we ever move this to navigation level.
            const res = await makeEndpointRequest(`GetWorkspaces`);

            // Get workspace Index
            const workspaceIndex = res.findIndex((e) => `${e._id}` === `${account.workspace._id}`);

            // Set in the state..
            setData({ workspaces: res, selectedWorkspace: workspaceIndex || 0 });
        } catch (err) {
            await logError(`LOAD_WORKSPACES`, err);
            setData(undefined);
        }
    };

    useEffect(() => {
        // Load the UI Data...
        loadData();

        // eslint-disable-next-line
    }, []);

    const PassedProps = {
        loadData,
        data,
        workspace: data.workspaces && data.workspaces.length > 0 ? data.workspaces[data.selectedWorkspace] : null,
    };

    // If there's an error
    if (data === undefined) return <ErrorPlaceholder />;

    return (
        <React.Fragment>
            <Container title={`Workspaces`} classNames="page-workspaces" isAlternateBackground={true}>
                <Context.Provider value={PassedProps}>
                    <Header />
                    <List />
                    <CreateWorkspaces />
                </Context.Provider>
            </Container>
        </React.Fragment>
    );
};

const ErrorPlaceholder = () => {
    return (
        <React.Fragment>
            <Container title={`Workspaces`} classNames="page-workspaces is-error">
                <div className="loading-wrapper">
                    <div className="title">Having Difficulties</div>
                    <div className="text">
                        <p>Failed to load your workspace data. Please get in touch with us.</p>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default Component;
