import React from 'react';
import { CustomToolContext } from '../../../../..';

const Component = (props) => {
    const { isSimilarTo, setIsSimilarTo } = CustomToolContext();

    const isSelected = isSimilarTo && isSimilarTo.label === props.data.label ? true : false;

    return (
        <React.Fragment>
            <div
                className={`component-storage-recommendation-entry ${isSelected ? 'selected' : 'not-selected'}`}
                data-cy="entry"
                onClick={() => setIsSimilarTo(isSelected ? null : props.data)}
            >
                <div className="content">
                    <div className="left-side">
                        {props.data.image ? (
                            <div
                                className="comp-img"
                                style={{
                                    backgroundImage: `url("${props.data.image}")`,
                                }}
                            />
                        ) : (
                            <div className="comp-avatar"> {props.data['label'].charAt(0).toUpperCase()} </div>
                        )}

                        <div className="label">{props.data.label}</div>
                    </div>
                    <div className="btn">
                        <i className={`elm fa-solid fa-check`} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
