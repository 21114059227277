import React from 'react';

import { Button, CircularProgress } from '@mui/material';
import { ModuleState } from '../..';
import { trackUserInteraction } from '../../../../../utils/helpers';
import { ModuleAPI } from '../../components/api';
import moment from 'moment';

// Component
import AutoComplete from './components/autoComplete';

// @Reminder: This component is also used in DPA => Describe Service screen.

const Component = () => {
    const { data, setStep, updateData, generatingCompanyActivities } = ModuleState();
    const { generateCompanyActivities } = ModuleAPI();

    const nextStep = async () => {
        let nextStep = 'Individuals';

        // to change the go back from identifyElements too.
        setStep(nextStep);
        await trackUserInteraction(`Selected "Next"`, { destination: nextStep });
    };

    const goBack = async () => {
        setStep('StorageLocations');
        await trackUserInteraction(`Selected "Go Back"`, { destination: `StorageLocations ` });
    };

    const generateAgain = () => {
        // Check if we have previously generated company description data
        if (
            data._meta.companyActivitiesLastGeneratedAt &&
            data._meta.companyActivitiesGenerated &&
            data._meta.companyActivitiesGeneratedUses === 3 // Used already 3 times
        ) {
            // Calculate the time elapsed since the last generation using Moment.js
            const currentTime = moment();
            const lastGeneratedTime = moment(data._meta.companyActivitiesLastGeneratedAt);
            const timeDifferenceInMinutes = currentTime.diff(lastGeneratedTime, 'minutes');

            // Check if it's been less than 5 minutes since the last generation
            if (timeDifferenceInMinutes < 5) {
                window.showAlert('Cooldown period', `Wait ${5 - timeDifferenceInMinutes} minutes before generating again.`, `error`);
                return; // Stop execution if the condition is not met
            }
        }

        trackUserInteraction(`Selected "Generate Again" on Company Activities`, {
            from: 'onboardingFlow',
        });

        // Do it..
        generateCompanyActivities();
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={goBack}>
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Core business activity/activities
                    </div>
                    <div className="subtitle">What core activities define the main value you offer to your customers?</div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={data.companyInfo.services.length < 1}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
            <div className="company-services module-content">
                <div className="input-wrapper">
                    <AutoComplete
                        firstLetterUppercase={true}
                        value={data.companyInfo.services}
                        disabled={generatingCompanyActivities}
                        inputPlaceholder={
                            data.companyInfo.services.length < 1
                                ? generatingCompanyActivities
                                    ? 'Awaiting the Completion of Your Personalized AI-Generated Suggestions...'
                                    : 'Please enter your activity below and then press Enter.'
                                : undefined
                        }
                        onEntryAdded={(entry) => updateData('companyInfo.services', [...data.companyInfo.services, entry])}
                        updateEntries={(inputValues) => updateData('companyInfo.services', inputValues)}
                    />
                    {generatingCompanyActivities && (
                        <div className="alert-ai">
                            <CircularProgress size={30} />
                            <div className="details">
                                <div className="heading">Generating Activity Suggestions</div>
                                <div className="description">
                                    Please allow a moment for our AI Assistant to create a list of recommended business activities based on
                                    your company description.
                                </div>
                            </div>
                        </div>
                    )}
                    <Button
                        onClick={generateAgain}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 16 }}
                        disabled={generatingCompanyActivities}
                    >
                        Generate Again
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
