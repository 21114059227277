import React, { useEffect, useState, createContext, useContext } from 'react';

// Dependencies
import Container from '../../layout/container';
import { logError } from '../../../utils/helpers';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { CircularProgress } from '@mui/material';

// Context
const Context = createContext({});
export const PageContext = () => useContext(Context);

// UI Components
import Header from './components/header';
import List from './components/list';
import { AuthStore } from '../../../utils/context/authStore';

// Modals
import ModalInvite from './modals/inviteUsers';
import { useHistory } from 'react-router-dom';

const Component = (props) => {
    const [data, setData] = useState(null);
    const { account } = AuthStore();
    const history = useHistory();

    const loadData = async () => {
        try {
            const data = await makeEndpointRequest('GetWorkspaceDetailsV2', {
                _id: props.match.params.id,
            });

            // If the user is no longer part of the workspace due to removing himself..
            const partOfWorkspace = data.users.find((u) => `${u._userId}` === `${account._id}`) ? true : false;
            if (!partOfWorkspace) {
                return history.replace('/workspaces');
            }

            setData(data);
        } catch (err) {
            await logError(`LOAD_WORKSPACES`, err);
            setData(undefined);
        }
    };

    useEffect(() => {
        // Load the UI Data...
        loadData();

        // eslint-disable-next-line
    }, []);

    const PassedProps = {
        loadData,
        data,
        currentRole: data ? data.users.find((e) => e._userId === account._id).role : 'member',
    };

    // Is loading
    if (data === null) return <LoadingPlaceholder />;

    // If there's an error
    if (data === undefined) return <ErrorPlaceholder />;

    return (
        <React.Fragment>
            <Container title={`Workspaces - Users`} classNames="page-workspace-users" isAlternateBackground={true}>
                <Context.Provider value={PassedProps}>
                    <Header />
                    <List />
                    {/* Modals */}
                    <ModalInvite />
                </Context.Provider>
            </Container>
        </React.Fragment>
    );
};

const ErrorPlaceholder = () => {
    return (
        <React.Fragment>
            <Container title={`Workspaces - Users`} classNames="page-workspace-users is-error">
                <div className="loading-wrapper">
                    <div className="title">Having Difficulties</div>
                    <div className="text">
                        <p>Failed to load your workspace data. Please get in touch with us.</p>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    );
};

const LoadingPlaceholder = () => {
    return (
        <React.Fragment>
            <Container title={`Workspaces - Users`} classNames="page-workspace-users is-loading">
                <div className="loading-wrapper">
                    <CircularProgress />
                </div>
            </Container>
        </React.Fragment>
    );
};

export default Component;
