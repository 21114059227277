const endpoints = {
    GetDPOWebConversation: {
        link: '/graphql',
        method: 'POST',
        query: `query GetDPOWebConversation   {
            GetDPOWebConversation
        }`,
        parserFunction: ({ data }) => data.GetDPOWebConversation,
    },
    DeleteConversationDPO: {
        link: '/graphql',
        method: 'POST',
        query: `mutation DeleteConversationDPO   {
            DeleteConversationDPO
        }`,
        parserFunction: ({ data }) => data.DeleteConversationDPO,
    },
    GenerateCompanyDescription: {
        link: '/graphql',
        method: 'POST',
        query: `query GenerateCompanyDescription($domains: [String])   {
            GenerateCompanyDescription(domains: $domains)
        }`,
        timeout: 300000,
        parserFunction: ({ data }) => data.GenerateCompanyDescription,
    },
    GenerateCompanyActivities: {
        link: '/graphql',
        method: 'POST',
        query: `query GenerateCompanyActivities($companyDescription: String)   {
            GenerateCompanyActivities(companyDescription: $companyDescription)
        }`,
        timeout: 80000,
        parserFunction: ({ data }) => data.GenerateCompanyActivities,
    },
};

export default endpoints;
