import React from 'react';
import { Avatar } from '@mui/material';

const Component = props => {
    return (
        <React.Fragment>
            <Avatar variant="square" style={{ height: 28, width: 28, marginRight: 8, borderRadius: 6 }} src={props.image}>
                {props.label[0]}
            </Avatar>
        </React.Fragment>
    );
};

export default Component;
