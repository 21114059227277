import React, { useState, useEffect } from 'react';

// Context
import { ComponentState } from '..';
import { logError, trackUserInteraction } from '../../../../utils/helpers';
import { getSubProcessorsMissing } from '../../dpaListing/components/functions';
import { makeEndpointRequest } from '../../../../utils/endpoints';
import { AuthStore } from '../../../../utils/context/authStore';

const Component = () => {
    const [checked, setChecked] = useState(false);

    const { globalFlags } = AuthStore();

    // Context
    const { data, draftData, subProcessors, updateDraftData, setRoute } = ComponentState();

    const showConfirmationMessageForAddingTools = (missingTools) => {
        try {
            trackUserInteraction(`New sub-processors added to DPA`, { _id: data._id, missing: missingTools });

            // Format the content message..
            const content = (
                <React.Fragment>
                    <p>These sub-processors have been added to your DPA. Please review their information is correct.</p>
                    <b>New sub-processors that have been added:</b>
                    <ul style={{ textAlign: 'left' }}>
                        {missingTools.map((c) => (
                            <li key={c}>{c}</li>
                        ))}
                    </ul>
                </React.Fragment>
            );

            const buttons = [
                {
                    text: 'OK',
                    onClick: async ({ dismissAlert }) => {
                        trackUserInteraction(`Selected "OK"`);
                        dismissAlert();
                    },
                },
            ];

            window.showAlert(`Success`, content, `info`, buttons);
        } catch (err) {
            logError(`showConfirmationMessageForAddingTools`, err);
        }
    };

    const addMissingTools = async (missingTools) => {
        try {
            let recommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: missingTools,
                processes: [],
            });

            // Map the new picked tools with recommendations..
            const newSubProcessors = missingTools.map((toolLabel) => {
                const r = recommendations.storageLocations.find((e) => e.label === toolLabel) || { processRecommendations: [] };
                const processes = r ? r.processRecommendations.filter((s) => s.length > 0) : [];

                return {
                    label: r.label || toolLabel,
                    image: r.image || '',
                    url: r.url || '',
                    dataResidency: r.dataResidency || '',
                    securityMeasuresURL: r.securityMeasuresURL || '',
                    securityMeasuresTitle: r.securityMeasuresTitle || '',
                    processes: processes,
                    type: 'recommendations',
                };
            });

            // Update draft..
            const newTools = [...draftData.subProcessors, ...newSubProcessors];
            updateDraftData('subProcessors', newTools);

            // Show confirmation message.
            showConfirmationMessageForAddingTools(missingTools);
        } catch (err) {
            await logError(`dpa.addMissingTools`, err);
        }
    };

    const promptToAddTools = async (missingTools) => {
        try {
            trackUserInteraction(`Outdated DPA`, { _id: data._id, missing: missingTools });
            // Format the content message..
            const content = (
                <React.Fragment>
                    <p>
                        Since this DPA was last submitted, a few additional tools have been used as sub-processors in other DPAs that could
                        potentially be used in this one as well.
                    </p>
                    <b>New sub-processors that could be of interest:</b>
                    <ul style={{ textAlign: 'left' }}>
                        {missingTools.map((c) => (
                            <li key={c}>{c}</li>
                        ))}
                    </ul>
                    <p>
                        You have two options: either you can dismiss this message without taking any further action, or we can add the
                        missing sub-processors and you can review them.
                    </p>
                </React.Fragment>
            );

            const buttons = [
                {
                    text: 'Dismiss',
                    fullWidth: true,
                    onClick: async ({ dismissAlert }) => {
                        trackUserInteraction(`Selected "Dismiss"`);
                        dismissAlert();
                    },
                },
                {
                    text: 'Review new sub-processors',
                    fullWidth: true,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        trackUserInteraction(`Selected "Review"`);
                        addMissingTools(missingTools);
                        setRoute('sub-processors');
                    },
                },
            ];

            window.showAlert(`Warning`, content, `warning`, buttons);
        } catch (err) {
            await logError(`dpa.promptDownloadIsOutdated`, err);
        }
    };

    const checkSubProcessorsUpToDate = async () => {
        try {
            // The only scenario that is important.
            if (draftData.parties.sender.role !== 'processor' || draftData.parties.receiver.role !== 'controller') return false;

            // Is not submitted therefore we won't know what the last sub-processors available were.
            if (!data.data.published) return false;

            // Not having the flag
            if (!globalFlags.missingSubProcessors) return false;

            // Check if it should be updated.
            const isMissing = await getSubProcessorsMissing(data, subProcessors);

            // Not missing anything.
            if (isMissing.length < 1) return false;

            const isBypassed = await localStorage.getItem(`@dpa.bypassPromptMissingTools`);

            // Was prompted..
            if (isBypassed) {
                await localStorage.removeItem(`@dpa.bypassPromptMissingTools`);
                addMissingTools(isMissing);
                return false;
            }

            // Prompt to add tools
            promptToAddTools(isMissing);
        } catch (err) {
            await logError(`dpa.checkSubProcessorsUpToDate`, err);
        }
    };

    useEffect(() => {
        if (subProcessors !== null && checked === false) {
            checkSubProcessorsUpToDate();
            setChecked(true);
        }

        // eslint-disable-next-line
    }, [subProcessors]);

    return null;
};

export default Component;
