import React, { createContext, useContext } from 'react';
import { ModuleState } from '..';

// Context
const Context = createContext({});
export const ModuleFunctions = () => useContext(Context);

const Component = (props) => {
    const { toolsRecommended } = ModuleState();

    const getToolRecommendations = (label) => {
        const match = toolsRecommended.find((storageLoc) => {
            // Turn Xero (2) into Xero
            const iteratedLabel = storageLoc.label
                .replace(/\(\d+\)/g, '')
                .trim()
                .toLowerCase();

            // Check matching..
            return iteratedLabel === label;
        });

        return match || null;
    };

    // Passed functions
    const PassedFunctions = {
        getToolRecommendations,
    };

    return <Context.Provider value={PassedFunctions}>{props.children}</Context.Provider>;
};

export default Component;
