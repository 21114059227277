import React from 'react';

// Context
import { OnboardingContext } from '../../..';
import { Button, CircularProgress } from '@mui/material';
import { OnboardingFunctions } from '../../../components/functions';
import { ViewContext } from '..';

const Component = () => {
    const { tasksPending, companyData } = OnboardingContext();
    const { generateCompanyActivities } = OnboardingFunctions();
    const { activities } = ViewContext();

    return (
        <React.Fragment>
            {tasksPending.generatingActivities && (
                <div className="alert-ai">
                    <CircularProgress size={30} />
                    <div className="details">
                        <div className="heading">Generating Activity Suggestions</div>
                        <div className="description">
                            Please allow a moment for our AI Assistant to create a list of recommended business activities based on your
                            company description.
                        </div>
                    </div>
                </div>
            )}
            <Button
                onClick={() => generateCompanyActivities(companyData.companyDescription, activities)}
                variant="contained"
                color="primary"
                style={{ marginTop: 16 }}
                disabled={tasksPending.generatingActivities}
            >
                Generate Again
            </Button>
        </React.Fragment>
    );
};

export default Component;
