import React from 'react';
import { ComponentState } from '../../../..';
import { Switch } from '@mui/material';
import { AuthStore } from '../../../../../../../utils/context/authStore';
import { trackUserInteraction } from '../../../../../../../utils/helpers';

const Component = () => {
    const { data, loading, updateData, flags, dataInventory } = ComponentState();
    const { account, checkUserAccess } = AuthStore();

    const getEntries = () => {
        const entries = [
            {
                value: `privacyPolicy`,
                label: `Privacy Policy`,
                disabled: true,
                description: 'Enables the display of your privacy policy on the privacy portal.',
            },
            {
                value: `cookiePolicy`,
                label: `Cookie Policy`,
                description: 'Enables the display of your cookie policy on the privacy portal.',
            },
            {
                value: `dataRequestForm`,
                label: `Data Requests Form`,
                description: `Allows your website visitors to submit requests to access, delete, or manage their collected data.`,
            },
            {
                value: `showCertificates`,
                label: `Show GDPR Certificate`,
                description: `Displays a GDPR Certificate on your privacy portal. This is not proof of compliance.`,
            },
            {
                value: `showHeaderOnEmbedded`,
                label: `Embedded Portal Header`,
                description: `Adds a header with a logo and motto to your embedded privacy portal.`,
            },
            {
                value: `hideThirdPartyToolNames`,
                label: `Hide Tool and Third Party Names`,
                description: `Replaces the real names of third-party tools with concise descriptions.`,
            },
        ];

        if (flags.privacyPortalTemplates) {
            entries.push({
                value: `useDynamicTemplate`,
                label: `Custom Templates`,
                description: `Enables the use of a custom template for your privacy portal.`,
            });
        }

        if (flags.californiaPolicy) {
            entries.push({
                value: `californiaPolicy`,
                label: `California Policy Addition`,
                description: `Adds a dedicated policy for California residents to your privacy portal.`,
            });
        }

        // If they have at least one tool with is sub processor true.
        const hasProcessor = dataInventory && dataInventory.storageLocations.find((c) => c.isSubProcessor === true) ? true : false;

        if (hasProcessor && flags.listSubProcessors) {
            entries.push({
                value: `listSubProcessors`,
                label: `Sub-Processors`,
                description: `List sub-processors on your privacy portal.`,
            });
        }

        if (checkUserAccess({ flag: 'modules' }) || flags.dashboardOnboardingEntities) {
            entries.push({
                value: `listDataRetention`,
                label: `Show Data Retention Table`,
                description: `List the retention periods and associated processes in the Data Retention section of your policy.`,
            });
        }

        return entries;
    };

    const notIncludedInYourPlan = async () => {
        await trackUserInteraction(`Feature not included in company plan`, {
            reason: `They tried to enable data requests form`,
        });
        window.showAlert(
            `Plan limitation`,
            `Your plan doesn't include data requests form on your privacy portal. Get in touch with us to discuss an upgrade for your plan`,
            'error',
            [
                {
                    text: 'Dismiss',
                    dataCy: 'alert-dismiss-button',
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Dismiss"`);
                    },
                },
                {
                    text: 'Contact Us',
                    onClick: async ({ dismissAlert }) => {
                        await trackUserInteraction(`Selected "Contact Us to Upgrade"`);
                        dismissAlert();
                        window.location.href = `mailto:support@privasee.io?subject=Upgrading current plan`;
                    },
                },
            ],
        );
    };

    const onChange = async (key) => {
        if (
            key === 'dataRequestForm' &&
            data.privacyPortalSettings.features['dataRequestForm'] === false &&
            account.companyPackage.usageLimits.dataRequestsForm === false
        )
            return notIncludedInYourPlan();

        const newState =
            data.privacyPortalSettings && data.privacyPortalSettings.features && data.privacyPortalSettings.features[key] !== undefined
                ? !data.privacyPortalSettings.features[key]
                : true;

        updateData(`privacyPortalSettings.features.${key}`, newState);

        if (key === 'cookiePolicy' && newState === false) {
            window.showAlert('Information', `Your cookie policy will be removed from your privacy portal.`, `info`);
        } else if (key === 'cookiePolicy' && newState === true && data.thirdPartyCookies.entries.length < 1) {
            window.showAlert(
                'Information',
                <React.Fragment>
                    <p>
                        After enabling the cookie policy on your privacy portal, remember to visit the "Cookie Policy" tab post-saving to
                        list your website's cookies. Currently, no cookies are listed.
                    </p>
                    <p style={{ fontWeight: 'Bold' }}>Click "Save Changes" below to save your changes.</p>
                </React.Fragment>,
                `info`,
            );
        }
    };
    return (
        <React.Fragment>
            <div className="form-label">Additional Settings</div>
            <div className="form-description">
                Use these additional settings to customize the experience your customers will have on your privacy portal.
            </div>
            <div className="switches-listing">
                {getEntries().map((entry, ix) => (
                    <React.Fragment key={ix}>
                        <div className="entry">
                            <div className="details">
                                <div className="label">{entry.label}</div>
                                <div className="description">{entry.description || ''}</div>
                            </div>
                            <div className="value">
                                <Switch
                                    checked={
                                        data && data.privacyPortalSettings.features && data.privacyPortalSettings.features[entry.value]
                                            ? true
                                            : false
                                    }
                                    color="primary"
                                    disabled={loading || entry.disabled}
                                    data-cy={`switch-${entry.value}`}
                                    onChange={() => onChange(entry.value)}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </React.Fragment>
    );
};

export default Component;
