import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { logError, trackUserInteraction } from '../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../utils/endpoints';

// Context
import { ModuleState } from '../../index';
import { ModuleAPI } from '../../components/api';

// List itself

export const formatDescription = (description) => description.replace('- ', '').split(',').join(', ').replace('...', '');

const StepIdentifyElements = () => {
    const { data, step, updateData, setStep, vitalRecommendations } = ModuleState();
    const { filterCheckedData, refreshCheckedData } = ModuleAPI();

    const nextStep = async () => {
        filterCheckedData();
        setStep('IdentifySensitive');
        await trackUserInteraction(`Selected "Next"`, { destination: `IdentifySensitive` });
    };

    const goBack = async () => {
        // If they're using the flag..
        let nextStep = `Individuals`;

        filterCheckedData();
        setStep(nextStep);
        await trackUserInteraction(`Selected "Go Back"`, { destination: nextStep });
    };

    const getOptions = () => {
        const elms = [];

        vitalRecommendations.elements.map((element) => {
            elms.push({
                label: element.label,
                sensitive: false,
                description: element.description ? formatDescription(element.description) : '',
            });
        });

        data.storageLocations.forEach((storage) => {
            storage.elements.forEach((elm) => {
                if (elms.find((x) => x.label === elm.label)) return false;
                if (elm.sensitive === true) return false;
                elms.push({
                    label: elm.label,
                    sensitive: elm.sensitive,
                    description: '',
                });
            });
        });

        return elms;
    };

    const removeElementSelected = async (elm) => {
        let checkedData = data.checkedData;
        checkedData.elements.forEach((elmIterated, index) => {
            if (elmIterated.label === elm.label && elmIterated.sensitive === false) {
                checkedData.elements.splice(index, 1);
            }
        });
        updateData(`checkedData`, checkedData);
        await trackUserInteraction(`Removed selected "Data Element"`, {
            removed: elm.label,
        });
    };

    const warnAgainstRemoving = (elm) => {
        window.showAlert(
            'Confirmation',
            `Are you sure about removing ${elm.label}? This will remove it from your data inventory entirely.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        removeElementSelected(elm);
                    },
                },
            ],
        );
    };

    const applyLegalRecommendations = async (element) => {
        try {
            const recommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: data.storageLocations.map((entry) => entry.label),
                processes: [],
            });

            data.storageLocations.forEach((loc, ix) => {
                const storageRecommended = recommendations.storageLocations.find((e) => e.label === loc.label);
                if (!storageRecommended) return false;

                // Get the storage location
                storageRecommended.processRecommendations.forEach((process) => {
                    // Get process details
                    const processRecommended = recommendations.processes.find((p) => p.label === process);
                    if (!processRecommended) return false;

                    // Check does this process has a recommendation of this element?
                    const positive = processRecommended.elementCategories.includes(element);
                    if (!positive) return false;

                    let matrixMapToBeInserted = [];
                    let individualsToBeInserted = [];

                    processRecommended.subjectCategories
                        .filter((s) => s.length > 0)
                        .forEach((individual) => {
                            const existsIndividual = loc.individuals.find((e) => e.label === individual) ? true : false;

                            if (!existsIndividual) {
                                individualsToBeInserted.push({
                                    label: individual,
                                    category: '',
                                });
                            }

                            const matrixObject = {
                                process: processRecommended.label,
                                element: element,
                                individual: individual,
                            };

                            const matrixExists = loc.matrixMap.find((o) => JSON.stringify(o) === JSON.stringify(matrixObject))
                                ? true
                                : false;

                            if (!matrixExists) {
                                matrixMapToBeInserted.push(matrixObject);
                            }
                        });

                    // Something needs to be added back.
                    const newLocations = data.storageLocations;

                    if (!newLocations[ix].elements.find((elm) => elm.label === element)) {
                        newLocations[ix].elements.push({
                            label: element,
                            sensitive: false,
                        });
                    }
                    newLocations[ix].individuals = [...newLocations[ix].individuals, ...individualsToBeInserted];
                    newLocations[ix].matrixMap = [...newLocations[ix].matrixMap, ...matrixMapToBeInserted];

                    updateData('storageLocations', newLocations);
                });
            });
        } catch (err) {
            await logError(`RE_APPLY_LOC_RECOMMENDATIONS`, err);
        }
    };

    const isElementUsed = (element) => {
        const storageLocation = data.storageLocations.find((storage) => {
            const elementMatch = storage.elements.find((c) => c.label == element.label);
            return elementMatch ? true : false;
        });

        return storageLocation ? true : false;
    };

    const onElementSelected = async (elm) => {
        let checkedData = data.checkedData;
        let lastBiggestStep = data._meta.biggestStep;

        if (checkedData.elements.find((iterated) => iterated.label === elm.label && iterated.sensitive === false)) {
            if (lastBiggestStep !== step && isElementUsed(elm)) {
                warnAgainstRemoving(elm);
                return false;
            }
            removeElementSelected(elm);
        } else {
            let elmAdded = { label: elm.label, sensitive: elm.sensitive, type: 'manual' };
            await trackUserInteraction(`Selected "Data Element`, { added: elm.label });
            checkedData.elements.push(elmAdded);

            // If the user has not got to review data first then we will re-apply the recommendations.
            const stepsInterested = ['IdentifyElements', 'IdentifySensitive', 'IdentifyVulnerable'];
            if (stepsInterested.includes(data._meta.biggestStep)) {
                // His last step is one of the above
                applyLegalRecommendations(elmAdded.label);
            }
            updateData('checkedData', checkedData);
        }
    };

    useEffect(() => {
        refreshCheckedData();
        // eslint-disable-next-line
    }, []);

    const getSubtitle = () => {
        try {
            // Get array of individuals
            let arr = [];

            // Iterate..
            data.storageLocations.forEach((storageLocation) => {
                storageLocation.individuals.forEach((individual) => {
                    if (arr.includes(individual.label)) return;

                    // Add array..
                    arr.push(individual.label);
                });
            });

            let arrIndividuals = arr.slice(0, 5);

            if (arrIndividuals.length > 1) {
                let first = arrIndividuals.slice(0, arrIndividuals.length - 1);
                let last = arrIndividuals[arrIndividuals.length - 1];

                arrIndividuals = `${first.join(', ')} and ${last}`;
            } else {
                arrIndividuals = arrIndividuals.join(', ');
            }

            return `Based on your ${arrIndividuals} it looks like you may be using these personal data categories.`;
        } catch (err) {
            logError(`identifyElements.getSubtitle`, err);
            return `Based on your data subjects it looks like you may be using these personal data categories.`;
        }
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" onClick={goBack} data-cy="back-button">
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Data Categories
                    </div>
                    <div className="subtitle">{getSubtitle()}</div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={data.checkedData.elements.length < 1}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
            <div className="identify-elements module-content">
                <div className="entries d-flex-row-columns centered">
                    {getOptions().map((elm, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`entry full-wd ${
                                    data.checkedData && data.checkedData.elements.find((x) => x.label === elm.label)
                                        ? 'selected'
                                        : 'not-selected'
                                }`}
                                data-cy={`entry-${index}`}
                                key={index}
                                onClick={() => onElementSelected(elm)}
                            >
                                <div className="content">
                                    <div className="left-side">
                                        <div className="label">{elm.label}</div>
                                        <div className="description">
                                            {elm.description.length > 0 && `Examples include: `}
                                            {elm.description
                                                ? elm.description.replace('- ', '').split(',').join(', ').replace('...', '')
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="checkmark">
                                        <i className="icon fa-solid fa-check"></i>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default StepIdentifyElements;
