import { CircularProgress } from '@mui/material';
import React from 'react';
import { AuditsContext } from '..';

const Component = () => {
    const { error, loading } = AuditsContext();

    return (
        <React.Fragment>
            <div className="centered-view loading">
                <div className="title">{loading ? `Loading your data...` : `Failed to load your data.`}</div>
                {error === false ? <CircularProgress /> : null}
            </div>
        </React.Fragment>
    );
};

export default Component;
