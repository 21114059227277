import React, { createContext, useContext } from 'react';

// Components
import Type from './components/type';
import Key from './components/key';
import Filter from './components/filter';
import Value from './components/value';

// Context
const Context = createContext({});
export const EntryState = () => useContext(Context);

const Component = (props) => {
    /**
     * This function will simply return the filter options.
     * @param {*} keyUsed
     * @returns The options for this filter
     */

    const getOptions = (keyUsed) => {
        keyUsed = keyUsed ? keyUsed : props.data.key;
        const field = props.fields.find((e) => e.key === keyUsed);
        const optionsExist = field && field.options && field.options.length > 0 ? true : false;

        if (!optionsExist) return [];
        return field.options;
    };

    /**
     *
     * @param {*} keyUsed
     * @returns The field settings (such as propertyCompared, type) etc.
     */

    const getField = (keyUsed) => {
        keyUsed = keyUsed ? keyUsed : props.data.key;
        const field = props.fields.find((e) => e.key === keyUsed);

        if (!field) return null;
        return field;
    };

    const passedProps = {
        isSecondary: props.isSecondary,
        setData: props.setData,
        data: props.data,
        field: getField(),
        getOptions,
        fields: props.fields,
    };

    return (
        <React.Fragment>
            <Context.Provider value={passedProps}>
                <div className="entry">
                    <Type />
                    <Key />
                    <Filter />
                    <Value />
                    <div className="field remove" onClick={props.delete}>
                        <i className="elm fa-solid fa-trash"></i>
                    </div>
                </div>
            </Context.Provider>
        </React.Fragment>
    );
};

export default Component;
