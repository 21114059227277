import { MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { ComponentState } from '../../../..';

const Component = () => {
    const { data, updateData } = ComponentState();

    const link = `https://gdpr-info.eu/art-22-gdpr/#:~:text=22%20GDPR%20Automated%20individual%20decision,significantly%20affects%20him%20or%20her.`;
    return (
        <React.Fragment>
            <div className="form-section">Automated decision-making</div>
            <div className="form-label">
                Do you use automated decision-making including profiling? More information{' '}
                <a href={link} target="_blank">
                    here
                </a>
                .
            </div>
            <div className="form-content">
                <Select
                    fullWidth={true}
                    onChange={(e) => updateData(`automatedDecisionMaking.enabled`, e.target.value)}
                    value={data.automatedDecisionMaking.enabled}
                >
                    <MenuItem value="none-selected" disabled={true}>
                        Select from list
                    </MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
            </div>
            {data.automatedDecisionMaking.enabled === true && (
                <React.Fragment>
                    <div className="form-label">Clause</div>
                    <div className="form-content">
                        <TextField
                            rows={3}
                            multiline={true}
                            fullWidth={true}
                            onChange={(e) => updateData(`automatedDecisionMaking.clauseText`, e.target.value)}
                            value={data.automatedDecisionMaking.clauseText}
                            placeholder="Write your clause here..."
                        />
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Component;
