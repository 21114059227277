const endpoints = {
    GetSecurityMeasures: {
        link: `/graphql`,
        method: `POST`,
        query: `
          query GetSecurityMeasures($filteredByCompanySize: Boolean) {
            GetSecurityMeasures(filteredByCompanySize: $filteredByCompanySize)
          }
      `,
        parserFunction: ({ data }) => data.GetSecurityMeasures,
    },
    GetRecommendedSecurityMeasures: {
        link: '/graphql',
        method: 'POST',
        query: `
          query GetRecommendedSecurityMeasures {
            GetRecommendedSecurityMeasures
          }`,
        parserFunction: ({ data }) => data.GetRecommendedSecurityMeasures,
    },
    GetSecurityMeasuresResponses: {
        link: '/graphql',
        method: 'POST',
        query: `
        query GetSecurityMeasuresResponses {
          GetSecurityMeasuresResponses
        }`,
        parserFunction: ({ data }) => data.GetSecurityMeasuresResponses,
    },
    GetSecurityMeasure: {
        link: '/graphql',
        method: 'POST',
        query: `
          query GetSecurityMeasure($_id: String) {
            GetSecurityMeasure(_id: $_id)
          }`,
        parserFunction: ({ data }) => data.GetSecurityMeasure,
    },
    CreateSecurityMeasure: {
        link: '/graphql',
        method: 'POST',
        query: `
      mutation CreateSecurityMeasure($payload: JSON)
      {
        CreateSecurityMeasure(payload: $payload)
      }
  `,
        parserFunction: ({ data }) => data.CreateSecurityMeasure,
    },
    UpdateSecurityMeasure: {
        link: '/graphql',
        method: 'POST',
        query: `
      mutation UpdateSecurityMeasure($_id: String, $payload: JSON)
      {
        UpdateSecurityMeasure(_id: $_id, payload: $payload)
      }
    `,
        parserFunction: ({ data }) => data.UpdateSecurityMeasure,
    },
    UpdateSecurityMeasuresResponses: {
        link: '/graphql',
        method: 'POST',
        query: `
          mutation UpdateSecurityMeasuresResponses($payload: JSON)
          {
            UpdateSecurityMeasuresResponses(payload: $payload)
          }
        `,
        parserFunction: ({ data }) => data.UpdateSecurityMeasuresResponses,
    },

    DeleteSecurityMeasure: {
        link: '/graphql',
        method: 'POST',
        query: `
          mutation DeleteSecurityMeasure($_id: String)
          {
            DeleteSecurityMeasure(_id: $_id)
          }
        `,
        parserFunction: ({ data }) => data.DeleteSecurityMeasure,
    },
};

export default endpoints;
