import React from 'react';

// Context
import { ViewContext } from '../../..';
import { TextField } from '@mui/material';
import { getValidationPropFields } from '../../../../../../../../utils/helpers';

const Component = () => {
    const { submitted, form, updateData, validationKeys } = ViewContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="label">Company Legal Name</div>
                <div className="input">
                    <TextField
                        autoFocus
                        fullWidth
                        disabled={submitted}
                        variant="outlined"
                        placeholder="Company Name LTD"
                        data-cy={`input-companyName`}
                        value={form.companyLegalName}
                        onChange={(e) => updateData('companyLegalName', e.target.value)}
                        {...getValidationPropFields(validationKeys, form.companyLegalName, `companyLegalName`)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
