import React, { useState } from 'react';

import { PageContext } from '../../../index';
import { useHistory } from 'react-router-dom';

import { ButtonBase, Menu, MenuItem } from '@mui/material';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { logError, trackUserInteraction } from '../../../../../../utils/helpers';

const Component = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { data, loadData } = PageContext();
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDelete = async () => {
        try {
            if (props.data.companies.length > 0) {
                return window.showAlert(`Not Permitted`, `You cannot delete a workspace that has active companies inside it.`);
            }

            await makeEndpointRequest(`DeleteWorkspaceV2`, { _id: props.data._id });
            await loadData();
            await trackUserInteraction(`Deleted Workspace`, { _id: props.data._id });
        } catch (err) {
            trackUserInteraction(`Having Difficulties`, { reason: `failed to create new workspace` });
            await logError(`CREATE_WORKSPACE`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    const confirmDeletion = async () => {
        handleClose();

        window.showAlert('Confirmation', `Are you sure you want to delete this workspace?`, 'warning', [
            {
                text: 'Cancel',
                dataCy: `alert-cancel-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },
            {
                text: 'Confirm',
                dataCy: `alert-submit-button`,
                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                    onDelete();
                },
            },
        ]);
    };

    const manageCompanies = () => {
        handleClose();
        document.dispatchEvent(new CustomEvent('workspaces:ManageCompanies', { detail: props.data._id }));
    };

    const getOptions = () => {
        const arr = [];
        const currentUser = props.data.users.find((e) => e._userId === account._id);
        const currentRole = currentUser ? currentUser.role : 'member';

        arr.push({
            label: 'Manage Users',
            onClick: () => history.push(`/workspaces/${props.data._id}/users`),
        });

        if (currentRole === 'admin') {
            arr.push({
                label: 'Manage Companies',
                onClick: manageCompanies,
            });
        }

        if (data.workspaces.length > 1 && currentRole === 'admin') {
            arr.push({
                label: 'Delete Workspace',
                onClick: confirmDeletion,
            });
        }

        return arr;
    };
    return (
        <React.Fragment>
            <ButtonBase className="btn" onClick={handleClick} disabled={getOptions().length < 1}>
                <i className="elm fa-solid fa-ellipsis"></i>
            </ButtonBase>
            <Menu anchorEl={anchorEl} open={anchorEl ? true : false} onClose={handleClose}>
                {getOptions().map((entry, ix) => (
                    <MenuItem key={ix} onClick={entry.onClick}>
                        {entry.label}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
};

export default Component;
