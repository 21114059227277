import React from 'react';
import { SettingsContext } from '../../..';
import { FormHelperText, MenuItem, Select } from '@mui/material';

import { types } from '../../../../gdprEssentials/steps/companyInsights/index';

const Component = () => {
    const { data, updateData, loading, validationKeys } = SettingsContext();

    const onDataChanged = (ev) => {
        updateData('companyInsights.type', ev.target.value);
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="label">Funding</div>
                <Select disabled={loading} value={data.companyInsights.type} onChange={onDataChanged}>
                    {types.map((entry, index) => (
                        <MenuItem key={index} value={entry.value}>
                            {entry.label}
                        </MenuItem>
                    ))}
                </Select>
                {validationKeys[`companyInsights.type`] && !loading && (
                    <FormHelperText error={true}>{validationKeys['companyInsights.type']}</FormHelperText>
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
