import React from 'react';
// import { PageContext } from '..';
import { Button } from '@mui/material';

const Component = () => {
    // const { workspace } = PageContext();

    const onButtonCreate = () => {
        document.dispatchEvent(new CustomEvent('workspaces:CreateWorkspace'));
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <div className="title">Workspaces</div>
                <div className="right-side">
                    <Button variant="outlined" color="primary" onClick={onButtonCreate}>
                        Create Workspace
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
