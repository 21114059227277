import * as Yup from 'yup';

export const InformationViewSchema = Yup.object({
    label: Yup.string().required('This field is required.').trim().label('This'),
    individualReferring: Yup.object({
        referringRole: Yup.string().required('This field is required.').trim().nullable().label('This'),
    }),
});

export const ProcessorViewSchema = Yup.object({
    processes: Yup.array().min(1, 'At least one process is required').label('This'),
});

export const ChildrenViewSchema = Yup.object({
    individualReferring: Yup.object({
        isChildren: Yup.boolean().required('This field is required.').nullable().label('This'),
    }),
});
