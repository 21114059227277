import React from 'react';

// Context
import { ViewContext } from '..';

const Component = (props) => {
    const { selectOption, currentOptions } = ViewContext();

    const isSelected = currentOptions.includes(props.label);

    return (
        <React.Fragment>
            <div className={`entry ${isSelected && 'selected'}`} onClick={() => selectOption(props.label)}>
                <div className="content">
                    <i className={`icon fa-solid ${props.icon}`}></i>
                    <div className="label">{props.label}</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
