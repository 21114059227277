import React, { useEffect } from 'react';

// Monitor Settings
import MonitorSettings from './components/monitoringSettings';
import { SettingsContext } from '../..';
import { validateAgainstSchema } from '../../../../../utils/helpers';
import { settingsGeneralSchema } from '../../utils/validation';

const Component = () => {
    const { setValidationKeys, data } = SettingsContext();

    const validateFields = async () => {
        try {
            await validateAgainstSchema(settingsGeneralSchema, data);
            setValidationKeys({});
        } catch (err) {
            setValidationKeys(err);
        }
    };

    useEffect(() => {
        validateFields();
        return () => {
            setValidationKeys({});
        };
        // eslint-disable-next-line
    }, [data]);

    return (
        <React.Fragment>
            <div className="section general">
                <div className="section-content">
                    <div className="section-group no-sections">
                        <MonitorSettings />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
