import { logError } from '../../../../../../utils/helpers';

/**
 * Filter and categorize options based on search criteria and category.
 *
 * @param {Object} params - The parameters for filtering.
 * @param {Array} params.options - The list of options to filter.
 * @param {string} params.searchValue - The search value to match against option labels.
 * @param {string} params.category - The category by which to filter the options.
 * @returns {Object} An object containing filtered entries and similar entries.
 */

export const filterOptions = (params) => {
    try {
        // Extract parameters.
        const { options, searchValue, category } = params;

        // Two groups of tools.
        let entries = options; // by default is all of them.
        let similarEntries = [];

        // Filter by category when we are not searching and is not the "all" category.
        if (category !== 'all' && searchValue.length < 1) {
            entries = entries.filter((c) => lowerCaseString(c.category) === category);
        }

        // If we are searching
        if (searchValue.length > 0) {
            // Get matching entries
            entries = options.filter((c) => labelMatching(c.label, searchValue));

            // If we have some results.
            if (entries.length > 0) {
                // Get similar tools too from the same category
                similarEntries = options.filter(
                    (c) =>
                        // If is the same category
                        c.category === entries[0].category &&
                        // And is not a match already.
                        !entries.find((d) => d.label === c.label),
                );
            }
        }

        return { entries, similarEntries };
    } catch (err) {
        logError(`createPanel.filterOptions`, err);
        return { entries: [], similarEntries: [] };
    }
};

export const labelMatching = (currentToolLabel, searchValue) => {
    const label = currentToolLabel
        .replace(/\(\d+\)/g, '')
        .trim()
        .toLowerCase();

    const search = searchValue
        .replace(/\(\d+\)/g, '')
        .trim()
        .toLowerCase();

    return label === search || label.includes(search) ? true : false;
};

export const lowerCaseString = (str) => str.toString().toLowerCase();
