import React from 'react';

// Components
import Status from './status';

const Component = (props) => {
    return (
        <React.Fragment>
            <div className="entry">
                <div className="details">
                    <div className="label">{props.label}</div>
                    <div className="description">{props.description}</div>
                </div>
                <div className="status">
                    <Status value={props.status} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
