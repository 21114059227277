import React from 'react';
import { useHistory } from 'react-router-dom';

const Shortcut = () => {
    const history = useHistory();

    const goTo = () => history.push('/settings/privacy-portal');

    return (
        <React.Fragment>
            <div className="card-bg shortcut-card shareables">
                <div className="header">
                    <div className="title">Privacy Portal</div>

                    <div className="arrow settings" onClick={goTo}>
                        <i className="icon fa-light fa-chevron-right"></i>
                    </div>
                </div>
                <div className="header-description">
                    You can find your Privacy & Cookie Policy here. Share the public website with your visitors!
                </div>
            </div>
        </React.Fragment>
    );
};

export default Shortcut;
