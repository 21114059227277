import React from 'react';
import { TextField } from '@mui/material';

// Context
import { CustomToolContext } from '../../..';

const Component = () => {
    const { searchingText, setSearchingText } = CustomToolContext();

    return (
        <React.Fragment>
            <div className="search">
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search for a specific tool..."
                    value={searchingText}
                    onChange={(e) => setSearchingText(e.target.value)}
                />
            </div>
        </React.Fragment>
    );
};

export default Component;
