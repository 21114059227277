import React from 'react';
import { CircularProgress } from '@mui/material';

const Component = () => {
    return (
        <React.Fragment>
            <div
                className="loading-container"
                style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 500 }}
            >
                <CircularProgress />
            </div>
        </React.Fragment>
    );
};

export default Component;
