import React from 'react';
import { Button } from '@mui/material';
import { calculatePercentage, formatNumber, trackUserInteraction } from '../../../../../utils/helpers';
import Tooltip from '../../../../components/tooltip';
import { useHistory } from 'react-router-dom';

const Section = (props) => {
    const history = useHistory();

    const pressedIncrease = async (entry) => {
        trackUserInteraction(`Selected "Increase"`, {
            on: entry.label,
        });
        window.showAlert(
            `Upgrading your plan`,
            `All you have to do is just upgrade to a bigger plan! Please get in touch with us to discuss an upgrade.`,
            'info',
            [
                {
                    text: 'Dismiss',
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Dismiss"`);
                    },
                },
                {
                    text: 'Contact Us',
                    onClick: async ({ dismissAlert }) => {
                        await trackUserInteraction(`Selected "Contact Us to Upgrade"`);
                        dismissAlert();
                        window.location.href = `mailto:support@privasee.io?subject=Upgrading current plan`;
                    },
                },
            ],
        );
    };

    const currentUsage = props.data.currentUsage;
    const usageLimits = props.data.usageLimits;

    const usages = [
        {
            label: `Cookie banner sessions`,
            percentage: calculatePercentage(
                currentUsage.cookieBannerSessions.total, // how many he's billed for
                usageLimits.cookieBannerDiscountedSessions, // total discounted
            ),
            rightText: (
                <React.Fragment>
                    <a target="_blank" href="https://privaseeio.notion.site/What-counts-as-a-session-95af24fd942d46258b2f94dd36253f7c">
                        What counts as a session?
                    </a>
                </React.Fragment>
            ),
            leftText: `${formatNumber(currentUsage.cookieBannerSessions.total)} (${formatNumber(
                usageLimits.cookieBannerDiscountedSessions,
            )} free) sessions per month`,
            onIncreasePressed: (entry) => {
                if (props.data.usercentricsSettingsId) {
                    pressedIncrease(entry);
                } else {
                    history.push('/cookie-banner');
                }
            },
            increaseDisabled: false,
            increaseText: props.data.usercentricsSettingsId ? `Increase` : `Enable`,
        },
        {
            label: `Languages translations`,
            percentage: calculatePercentage(currentUsage.languagesUsed, usageLimits.maxLanguages),
            rightText: ``,
            leftText: `${currentUsage.languagesUsed}/${usageLimits.maxLanguages}`,
            onIncreasePressed: pressedIncrease,
        },
    ];

    return (
        <div className="section usage-limits">
            <div className="section-content">
                <div className="section-header first">Current usage</div>

                <div className="usages">
                    {usages.map((entry, ix) => (
                        <div className="entry" key={ix}>
                            <div className="left-area">
                                <div className="label">{entry.label}</div>
                            </div>
                            <div className="right-area">
                                <div className={`progress-bar ${entry.percentage >= 80 && 'danger'}`}>
                                    <div className={`top-text ${entry.percentage >= 80 && 'danger'}`}>
                                        <div className="text left-text">
                                            {entry.leftText}{' '}
                                            {entry.tooltipDescription && (
                                                <Tooltip
                                                    trackInteraction={true}
                                                    interactionId={`usage-limits:${entry.label}`}
                                                    content={entry.tooltipDescription}
                                                >
                                                    <i className="tooltip-icon fa-solid fa-circle-info"></i>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <div className="text right-text">{entry.rightText}</div>
                                    </div>
                                    <div className={`element ${entry.percentage >= 80 && 'danger'}`}>
                                        <div className={`value`} style={{ width: `${entry.percentage}%` }}></div>
                                    </div>
                                </div>
                                <div className="button">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={entry.increaseDisabled}
                                        onClick={() => entry.onIncreasePressed(entry)}
                                    >
                                        {entry.increaseText || 'Increase'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Section;
