import React from 'react';
import { Avatar } from '@mui/material';
import Tooltip from '../../../../../components/tooltip';

const EntryCard = (props) => {
    const onEdit = async () => {
        if (props.editStorage && props.editStorage.index) return false;
        props.setEditStorage({ index: props.data.originalIndex });

        // Dispatch an event that tells us the process of editing a storage has started.
        document.dispatchEvent(new CustomEvent(`reviewData:editing`, { detail: props.data.label }));
    };

    const getProcessesListing = () => {
        const processes = props.data.processes.map((p) => p.label); // WRONG.
        if (processes.length < 1) return `No processes listed.`;
        const visiblePart = processes.slice(0, 3).join(', ');
        const invisiblePart = processes.slice(3);
        return invisiblePart.length > 0 ? `${visiblePart}, + ${invisiblePart.length} more.` : `${visiblePart}`;
    };

    return (
        <React.Fragment>
            <div className="entry normal" data-cy={props.dataCy}>
                <div className="content" onClick={() => onEdit()}>
                    <div className="left-side">
                        <div className="header">
                            <Avatar src={props.data.image}>{props.data.label[0]}</Avatar>
                            <div className="label">{props.data.label}</div>
                        </div>
                        <div className="processes">{getProcessesListing()}</div>
                    </div>
                    <div className="right-side">
                        {props.alert && props.archived !== true && (
                            <div className="alert-container">
                                <Tooltip content="Click 'Edit' to add missing information">
                                    <i className="alert fa-solid fa-circle-exclamation"></i>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EntryCard;
