import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useWindowSize } from '../../../../utils/helpers';

export const tabsWithoutPreview = ['add-to-website'];

const Component = (props) => {
    const [width] = useWindowSize();

    const getEntries = () => {
        let entries = [
            {
                label: 'Customization',
                value: 'customization',
            },
            {
                label: 'Settings',
                value: 'general',
            },
            {
                label: 'Privacy Policy',
                value: 'privacy-policy',
            },
            {
                label: 'Cookie Policy',
                value: 'cookie-policy',
            },
            {
                label: 'Add to website',
                value: 'add-to-website',
            },
        ].filter((c) => c !== null);

        return entries;
    };

    const onChangeFunc = (ex, val) => {
        props.setValue(val);
    };

    return (
        <div data-cy="tabs" className="tabs">
            <div className="content">
                <Tabs value={props.value} variant={width < 1100 ? `scrollable` : `standard`} onChange={onChangeFunc}>
                    {getEntries().map((entry, index) => (
                        <Tab disabled={props.loading} label={entry.label} key={index} value={entry.value} />
                    ))}
                </Tabs>
            </div>
        </div>
    );
};

export default Component;
