import React from 'react';

// Components
import Panel from './components/panel';
import { SetStorageLocationsContext } from '../..';
import { mapToolLabelsToDataInventory } from '../../../../../../../../processes/panel/views/tools/components/utils/functions';
import {
    createCustomStorageLocationInDataInventory,
    createRecommendedStorageLocationsInBulk,
    createStorageLocationsInDataInventory,
    formatCreatedToolName,
} from '../../../../../../../../thirdParties/components/storageLocations/utils/functions';
import { logError } from '../../../../../../../../../../utils/helpers';
import { AuthStore } from '../../../../../../../../../../utils/context/authStore';
import { makeLocalEndpointRequest } from '../../../../../../../../../../utils/endpoints';

const Component = () => {
    const { stopPicking, dataInventory, setDataInventory, addStorageLocation, setAddingTools, vitalRecommendations, companyData } =
        SetStorageLocationsContext();
    const { globalFlags } = AuthStore();

    const createTools = async (toolLabels) => {
        try {
            // If flag is enabled we use the api
            if (globalFlags.gelDecoupling) {
                // Iterate and create
                for (const [index, label] of toolLabels.entries()) {
                    // Check if the current label is the last one
                    const isLastLabel = index === toolLabels.length - 1 ? true : false;

                    // Make the API Request
                    const response = await makeLocalEndpointRequest(`/api/v1/storageLocations/pickToolFromRecommendations`, {
                        label,
                        options: {
                            syncToSession: isLastLabel, // Set syncToSession to true only for the last label
                            updateCookieBanner: isLastLabel,
                            responseType: 'inventory',
                        },
                    });

                    if (isLastLabel) {
                        // Update data inventory
                        setDataInventory(response);

                        return response;
                    }
                }
            } else {
                // Prepare the objects we need to go ahead and create them in the API
                const { tools, processesDependencies } = await createRecommendedStorageLocationsInBulk({
                    toolLabels,
                    dataInventory,
                    companyData,
                    vitalRecommendations,
                });

                // Create the tools in our inventory database
                const { dataInventoryUpdated } = await createStorageLocationsInDataInventory(tools, processesDependencies);

                // Update data inventory for future.
                setDataInventory(dataInventoryUpdated);

                return dataInventoryUpdated;
            }
        } catch (err) {
            await logError(`module.processes.panel.addTool.createTools`, err, { toolLabels });
        }
    };

    const onToolsPicked = async (toolLabels) => {
        try {
            setAddingTools(true);

            // Map the tool labels picked to our data inventory
            let tools = mapToolLabelsToDataInventory(toolLabels, dataInventory.storageLocations);

            // The data inventory that we will use..
            let dataInventoryUsed = dataInventory;

            // Check if we need to create tools
            const creatingTools = tools.filter((c) => !c.data).map((c) => c.label);

            if (creatingTools.length > 0) {
                // Create the tools that weren't existing yet.
                dataInventoryUsed = await createTools(tools.filter((c) => !c.data).map((c) => c.label));
            }

            // Add all the tools
            for (const tool of tools) {
                const storageLocation = dataInventoryUsed.storageLocations.find((c) => c.label === tool.label);
                const isCreated = creatingTools.includes(storageLocation.label) ? true : false;
                await addStorageLocation(storageLocation, dataInventoryUsed, isCreated);
            }

            setAddingTools(false);

            // Stop picking now
            stopPicking();
        } catch (err) {
            setAddingTools(false);
            await logError(`module.individuals.setStorageLocations.onToolsPicked`, err);
            return false;
        }
    };

    const onCustomToolCreated = async (customTool) => {
        try {
            setAddingTools(true);

            if (globalFlags.gelDecoupling) {
                // Format the label accordingly.
                let label = formatCreatedToolName(customTool.label, dataInventory.storageLocations);

                // Make the API Request
                const response = await makeLocalEndpointRequest(`/api/v1/storageLocations/createCustomTool`, {
                    label,
                    similarTo: customTool.similarData ? customTool.similarData.label : null,
                    options: {
                        syncToSession: true,
                        responseType: 'inventory',
                    },
                });

                const storageLocation = response.storageLocations.find((c) => c.label === label);

                // Add it to tools
                addStorageLocation(storageLocation, response);

                // Update data inventory
                setDataInventory(response);
            } else {
                // Create it..
                const { dataInventoryUpdated, label } = await createCustomStorageLocationInDataInventory({
                    customTool,
                    dataInventory,
                    companyData,
                    vitalRecommendations,
                });

                const storageLocation = dataInventoryUpdated.storageLocations.find((c) => c.label === label);

                // Add it to tools
                addStorageLocation(storageLocation, dataInventoryUpdated);

                // Update data inventory for future.
                setDataInventory(dataInventoryUpdated);
            }

            // Set adding tool false..
            setAddingTools(false);

            // Stop picking now
            stopPicking();
        } catch (err) {
            await logError(`module.processes.panel.onCustomToolCreated`, err, { customTool });

            // Set to false
            setAddingTool(false);
        }
    };

    const onStopPicking = () => {
        stopPicking();
    };

    return (
        <React.Fragment>
            <Panel onToolsPicked={onToolsPicked} onCustomToolCreated={onCustomToolCreated} stopPicking={onStopPicking} />
        </React.Fragment>
    );
};

export default Component;
