import React from 'react';

// UI components
import WorkspaceSettings from './components/workspaceSettings';
import CreateCompany from './components/createCompany';
import ManageCompanies from './components/manageCompanies';

// Dependencies
import { AuthStore } from '../../../../../utils/context/authStore';
import { makeEndpointRequest } from '../../../../../utils/endpoints';
import { logError, trackUserInteraction } from '../../../../../utils/helpers';

const Component = (props) => {
    const { account } = AuthStore();

    const renderCompanyPackageName = (company) => {
        const allowedNames = ['GDPR Essentials', 'GDPR Pro', 'GDPR Essentials (Lite)'];
        if (allowedNames.includes(company.plan.name)) return company.plan.name;
        return 'Custom Plan';
    };

    const switchToCompany = async (company) => {
        try {
            await makeEndpointRequest(`SwitchWorkspaceV2`, {
                _workspaceId: props.data._id,
                _companyId: company._companyId,
            });

            window.location = '/';
        } catch (err) {
            await trackUserInteraction(`Having Difficulties`, { reason: `failed to create new workspace` });
            await logError(`SWITCH_TO_WORKSPACE_COMPANY `, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    const filterUsers = (arr) => arr.filter((e) => (e.isSupport === true && !account.email.includes('@privasee') ? false : true));

    return (
        <React.Fragment>
            <div className="entry">
                <div className="--content">
                    <div className="workspace">
                        <div className="details">
                            <div className="name">{props.data.name}</div>
                            <div className="members">
                                {filterUsers(props.data.users).length} {filterUsers(props.data.users).length === 1 ? 'user' : 'users'}
                            </div>
                        </div>
                        <div className="right-side">
                            {account.workspace._id === props.data._id && <div className="active">Current workspace</div>}
                            <div className="options">
                                <WorkspaceSettings data={props.data} />
                                <CreateCompany data={props.data} />
                                <ManageCompanies data={props.data} />
                            </div>
                        </div>
                    </div>
                    <div className="companies">
                        {props.data.companies.length < 1 && <div className="no-companies">There are no companies in this workspace.</div>}
                        {props.data.companies.map((company, ix) => (
                            <div entry={ix} className={`company`} key={ix} onClick={() => switchToCompany(company)}>
                                <div className="component-company-avatar avatar">
                                    {company.details.companyLegalName ? company.details.companyLegalName.slice(0, 2) : 'CA'}
                                </div>
                                <div className="details">
                                    <div className="name">{company.details.companyLegalName || 'Un-named company'}</div>
                                    <div className="plan">{renderCompanyPackageName(company)}</div>
                                </div>
                                <div className="right-side">
                                    {account._companyId === company._companyId && (
                                        <div className="active">
                                            <i className="fa-regular fa-check"></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
