import React, { useEffect, useState } from 'react';

// Dependencies
import Container from '../../layout/container';
import { AuthStore } from '../../../utils/context/authStore';
import { useHistory } from 'react-router-dom';
import { logError, trackUserInteraction } from '../../../utils/helpers';
import { makeEndpointRequest, makeLocalEndpointRequest } from '../../../utils/endpoints';

// Components
import Plan from './components/plan';
import Loading from './components/loading';
import Currency from './components/currency';
import Recurring from './components/recurring';

export default function ChoosePlan() {
    const { account } = AuthStore();
    const history = useHistory();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const appNotLicensed = account.workspace.licensed === false;
    const [currency, setCurrency] = useState('eur');
    const [recurring, setRecurring] = useState('yearly');

    const loadData = async () => {
        try {
            if (!appNotLicensed) return history.replace('/');
            const data = await makeEndpointRequest('GetPricingPlans');
            setLoading(false);
            setData(data);
            await trackUserInteraction('Choose your plan');
        } catch (err) {
            setData(undefined);
            setLoading(false);
            await logError(`LOAD_CHOOSE_YOUR_PLAN`, err);
            await trackUserInteraction('Having Difficulties', { reason: 'Failed to load the plans to choose.' });
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    const getPricingPlans = (withFilters = false) => {
        const arr = data.pricingPlansListed;
        return withFilters ? arr.filter((p) => p.recurring === recurring) : arr;
    };

    const onPlanSelected = async (entry) => {
        try {
            setSubmitted(true);
            setSelectedPlan(entry._id);

            // Check and get the pricing id
            const stripePricingId = entry.planStripePricingIds[currency];
            if (!stripePricingId)
                throw new Error(
                    `Failed to find stripe pricing id for package ${entry.name} - Currency: ${currency} ${
                        entry.isPremade === true ? '(Premade deal)' : ''
                    }`,
                );

            // Format the request body

            const requestBody = {
                email: account.email,
                packageId: entry._id,
                currency,
            };

            if (entry.customStripePricingIds === true) {
                requestBody.customDealPricingIds = true;
            }

            // Generate session url
            const data = await makeLocalEndpointRequest(`/api/create-gel-checkout-session`, requestBody, 'POST');

            window.location.href = data.url;
        } catch (err) {
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
            await logError('CREATE_PLAN_CHECKOUT_SESSION', err, { entry });
            setSubmitted(false);
            setSelectedPlan(null);
        }
    };

    return (
        <Container title="Choose your plan" classNames={'page-choose-your-plan'}>
            <div className="page-content">
                {data && !loading ? (
                    <React.Fragment>
                        <div className="page-header">
                            <div className="heading"> Ready to unlock all the features?</div>
                            <div className="motto">
                                You're now ready to show the world that you are a privacy champion with your GDPR Essentials Certificate,
                                Privacy Hub, Privacy and Cookie policy.
                            </div>
                        </div>
                        <Recurring recurring={recurring} setRecurring={setRecurring} plans={getPricingPlans()} />
                        <div className="plans-container ">
                            <div className="shared-components pricing-plans">
                                {getPricingPlans(true).map((entry, ix) => (
                                    <Plan
                                        key={ix}
                                        data={entry}
                                        isSelected={selectedPlan === entry.id}
                                        loading={loading || submitted}
                                        onSelect={() => onPlanSelected(entry)}
                                        currency={currency}
                                    />
                                ))}
                            </div>
                        </div>
                        <Currency currency={currency} setCurrency={setCurrency} plans={getPricingPlans()} />
                    </React.Fragment>
                ) : (
                    <Loading loading={loading} data={data} />
                )}
            </div>
        </Container>
    );
}
