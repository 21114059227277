import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { getValidationPropFields, validateAgainstSchema } from '../../../../../../../utils/helpers';
import { gelFlowCreateToolSchema } from '../../../../utils/validations';
import { ModuleState } from '../../../..';
import { filterListingOptions } from './panel';
import Tooltip from '../../../../../../components/tooltip';

const Component = (props) => {
    const { data } = ModuleState();
    const [validationResults, setValidationResults] = useState({});
    const [isSimilarTo, setIsSimilarTo] = useState(null);
    const [searchingText, setSearchingText] = useState('');

    const updateField = (field, value) => {
        props.setEditData((currentData) => {
            let modifiedData = JSON.parse(JSON.stringify(currentData));
            modifiedData.payload[field] = value;
            return modifiedData;
        });
    };

    const validateFields = async () => {
        if (props.editData.active === false) return false;
        try {
            await validateAgainstSchema(gelFlowCreateToolSchema, props.editData.payload);
            setValidationResults({});
            const matchTool = data.storageLocations.find((i) => i.label === props.editData.payload.label);
            const matchIndex = data.storageLocations.findIndex((i) => i.label === props.editData.payload.label);
            if (matchTool && matchIndex !== -1 && matchIndex !== props.editData.index) {
                setValidationResults({
                    label: `Name is already used. Try adding a suffix: ${props.editData.payload.label} (2)`,
                });
            }
        } catch (err) {
            setValidationResults(err);
        }
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [props.editData.payload]);

    // Reset it..
    useEffect(() => {
        setIsSimilarTo(null);
        setSearchingText('');
    }, [props.editData.active]);

    const creatingV2 = props.editData.creating ? true : false;
    const isEmptyManualTool = props.editData.payload.origin === 'manual' && props.editData.payload.processes.length === 0;
    const isEditingV2 = isEmptyManualTool && !props.editData.creating ? true : false;

    const getOptions = () => {
        return filterListingOptions({
            source: props.options,
            searchedText: searchingText,
            categorySelected: 'All',
        });
    };
    // eslint-disable-next-line

    if (props.editData.active === false) return null;

    return (
        <React.Fragment>
            <Dialog
                className={`create-storage-location-modal ${(creatingV2 || isEditingV2) && 'v2'}`}
                open={true}
                maxWidth={creatingV2 || isEditingV2 ? 'lg' : 'sm'}
                fullWidth={true}
            >
                <DialogTitle data-cy="dialog-title">{props.editData.creating ? `Create tool` : `Editing Tool`}</DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <div className="form-group" style={{ marginBottom: 18 }}>
                        <div className="label">Tool or 3rd party Name</div>

                        <div className="input">
                            <TextField
                                autoFocus
                                id="tool-name"
                                fullWidth
                                variant="standard"
                                placeholder="DigitalOcean"
                                value={props.editData.payload.label}
                                onChange={(e) => updateField('label', e.target.value)}
                                data-cy="dialog-input"
                                {...getValidationPropFields(validationResults, props.editData.payload.label.length > 0, `label`)}
                            />
                        </div>
                    </div>
                    {(creatingV2 || isEditingV2) && (
                        <React.Fragment>
                            <div className="heading">
                                Finding a similar tool or third party can help you map your personal data quicker (optional)
                                <div className="tooltip" style={{ display: 'inline-block' }}>
                                    <Tooltip
                                        trackInteraction={true}
                                        interactionId={`gdpr-essentials:finding-a-similar-tool`}
                                        content={`If you find a similar tool or third party, the recommendations that are applied to that tool will be applied to your custom one. Making the process to map your data easier.`}
                                    >
                                        <i className="tooltip-icon fa-solid fa-circle-info"></i>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="subheading">
                                Select a competitor or similar tool or third party. Don’t worry you will be able to edit it.
                            </div>

                            <div className="recommendations-entries">
                                <div className="search">
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Search for a specific tool..."
                                        value={searchingText}
                                        onChange={(e) => setSearchingText(e.target.value)}
                                    />
                                </div>
                                <div className="entries">
                                    {getOptions().map((entry, ix) => (
                                        <div
                                            key={ix}
                                            className={`component-storage-recommendation-entry ${
                                                isSimilarTo && isSimilarTo.label === entry.label ? 'selected' : 'not-selected'
                                            }`}
                                            data-cy="entry"
                                            onClick={() =>
                                                setIsSimilarTo(isSimilarTo !== null && entry.label === isSimilarTo.label ? null : entry)
                                            }
                                        >
                                            <div className="content">
                                                <div className="left-side">
                                                    {entry.image ? (
                                                        <div
                                                            className="comp-img"
                                                            style={{
                                                                backgroundImage: `url("${entry.image}")`,
                                                            }}
                                                        />
                                                    ) : (
                                                        <div className="comp-avatar"> {entry['label'].charAt(0).toUpperCase()} </div>
                                                    )}

                                                    <div className="label">{entry.label}</div>
                                                </div>
                                                <div className="btn">
                                                    <i className={`elm fa-solid fa-check`} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {getOptions().length < 1 && searchingText.length > 0 && (
                                        <div className="no-results">No results for this search</div>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={props.onDismiss} data-cy="dialog-dismiss-button">
                        Dismiss
                    </Button>
                    <Button
                        disabled={Object.values(validationResults).length > 0}
                        variant="contained"
                        color="primary"
                        onClick={() => props.onSubmit(isSimilarTo)}
                        data-cy="dialog-submit-button"
                    >
                        {props.editData.creating ? `Create` : `Save`}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
