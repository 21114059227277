import React, { useState } from 'react';

// Dependencies
import { Button, TextField, Select, MenuItem } from '@mui/material';
import { ComponentState } from '../../../..';
import availableLanguages from '../../../../../../../dummy_data/availableLanguages.json';

const Component = () => {
    const { data, setTranslationField, getTranslationFiledValue, customerTranslations, saveCustomerTranslations, loading } =
        ComponentState();
    const [lang, setLang] = useState(`EN`);

    return (
        <React.Fragment>
            <div className="form-section dual">
                <div className="left-side">Translations</div>
                <div className="right-side">
                    <Select
                        disabled={loading}
                        size="sm"
                        className="language"
                        variant="outlined"
                        value={lang}
                        onChange={(e) => setLang(e.target.value)}
                        data-cy="translations-selector"
                    >
                        {availableLanguages
                            .filter((x) => data.languagesAvailable.includes(x.code) || x.code === 'EN')
                            .map((l, ix) => (
                                <MenuItem value={l.code} key={ix}>
                                    <div className="dropdown-menu-flags">
                                        <img className="flag" src={`/assets/images/flags/${l.code}.png`} />
                                        <span>{l.label}</span>
                                    </div>
                                </MenuItem>
                            ))}
                    </Select>
                </div>
            </div>
            <img
                className="translations preview-image"
                src={`/assets/images/page-privacy-portal-settings/translations/${data.isPrivacyPortalHosted ? 'hosted' : 'embedded'}.png`}
                alt="preview image"
            />
            <div className="form-content">
                <div className="form-group">
                    <div className="label">Welcome Text</div>
                    <TextField
                        fullWidth={true}
                        className="input"
                        placeholder="Welcome Text"
                        value={getTranslationFiledValue(`privacyPortal`, lang, `header`, 'motto')}
                        onChange={(e) => setTranslationField(`privacyPortal`, lang, `header`, `motto`, e.target.value)}
                        variant="standard"
                        disabled={loading}
                    />
                </div>
                <div className="form-group">
                    <div className="label">Header</div>
                    <TextField
                        value={getTranslationFiledValue(`privacyPortal`, lang, `privacyTopArea`, 'heading')}
                        onChange={(e) => setTranslationField(`privacyPortal`, lang, `privacyTopArea`, `heading`, e.target.value)}
                        fullWidth={true}
                        className="input"
                        placeholder="Header"
                        variant="standard"
                        disabled={loading}
                        inputProps={{
                            'data-cy': 'language-privacyTopArea',
                        }}
                    />
                </div>
                <div className="form-group">
                    <div className="label">Introduction</div>
                    <TextField
                        fullWidth={true}
                        className="input"
                        placeholder="Introduction"
                        variant="standard"
                        value={getTranslationFiledValue(`privacyPortal`, lang, `privacyTopArea`, 'introduction')}
                        onChange={(e) => setTranslationField(`privacyPortal`, lang, `privacyTopArea`, `introduction`, e.target.value)}
                        multiline={true}
                        rows={4}
                        disabled={loading}
                    />
                </div>
                <div className="page-footer" style={{ marginBottom: 0 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        data-cy="translations-saveButton"
                        disabled={loading || Object.keys(customerTranslations.privacyPortal.src).length < 1}
                        onClick={saveCustomerTranslations}
                    >
                        Save changes
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
