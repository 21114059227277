import React from 'react';

const Component = (props) => {
    return (
        <React.Fragment>
            <div className={`module-popout`}>
                <div className="module-content">
                    <div className="module-title">Legal responsibilities</div>
                    <div className="module-close" onClick={() => props.setTooltipEnabled(false)} data-cy="panel-close">
                        <i className="icon fa-solid fa-xmark"></i>
                    </div>

                    <div className="module-elements" style={{ marginTop: 36, width: '100%' }}>
                        <div className="legal-role">Example 1:</div>
                        <p>
                            If you use Google Drive to store information - you are the owner of that information, meaning you decide when to
                            update it, delete it, move it or use it. In this scenario you are the Controller and Google Drive is the
                            processor as they are processing information based on your instructions.{' '}
                        </p>
                        <div className="legal-role">Example 2:</div>
                        <p>
                            If you are a service provider and have a web app or mobile app and people use your app to offer services (for
                            example if you are a CRM like Hubspot) then when offering your services you are a processor.
                        </p>

                        <div className="legal-role">You are a controller if...</div>
                        <ul>
                            <li>
                                You decided what the purpose or outcome of the processing was to be, what personal data should be collected
                                and which individuals to collect personal data about.
                            </li>
                            <li>The data you are processing is about your employees.</li>
                            <li>You have a direct relationship with the individuals who are the owners of the data.</li>
                            <li>You exercise professional judgement in the processing of personal data (e.g. Lawyers and Doctors).</li>
                        </ul>

                        <div className="legal-role">You are a processor if...</div>
                        <ul>
                            <li>You do not decide what purpose or purposes the data will be used for.</li>
                            <li>You are following instructions from someone else regarding the processing of personal data.</li>
                            <li>You were given the personal data by a customer or similar third party, or told what data to collect.</li>
                            <li>
                                You do not decide to collect personal data from individuals, do not decide what personal data should be
                                collected from individuals, do not decide whether to disclose the data, or to whom or do not decide how long
                                to retain the data.
                            </li>
                        </ul>

                        <div className="legal-role">You are a joint-controller if (this is rare)...</div>
                        <ul>
                            <li>You have a common objective with others regarding the processing.</li>
                            <li>You are processing the personal data for the same purpose as another controller.</li>
                            <li>
                                You are using the same set of personal data (eg one database) for this processing as another controller.
                            </li>
                            <li>
                                You have designed this process with another controller or you have common information management rules with
                                another controller.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="module-shadow-underlay" />
        </React.Fragment>
    );
};

export default Component;
