import { Button, TextField } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ComponentState } from '../../../../..';
import { filterListingOptions } from '../../../../../../gdprEssentials/steps/storageLocations/picker/components/panel';

let timerInterval = null;

const Component = (props) => {
    const [input, setInput] = useState('');
    const [selected, setSelected] = useState([]);
    const [searchedText, setSearchedText] = useState('');
    const [closing, setClosing] = useState(false);
    const { personalInventory } = ComponentState();

    useEffect(() => {
        if (timerInterval) {
            clearTimeout(timerInterval);
        }

        timerInterval = setTimeout(() => {
            setSearchedText(input);
            timerInterval = null;
        }, 400);
    }, [input]);

    const onCreateCustomTool = (label) => {
        props.onDone(selected, props.meta.options);
        props.createCustomTool(label);
    };

    const closeWithAnimation = (func) => {
        setClosing(true);

        setTimeout(() => {
            setClosing(false);
            func();
        }, 550);
    };

    const getOptions = () => {
        const options = [...props.meta.options];

        // Add custom tools from data inventory
        personalInventory.forEach((storageLocation) => {
            // Does this tool exist in data inventory? (Aka is recommended)
            const isRecommended = props.meta.options.find((c) => storageLocation.label.includes(c.label)) ? true : false;

            // Is a recommended one..
            if (isRecommended) return false;

            options.push({
                label: `${storageLocation.label}`,
                category: `Custom tools`,
                dataResidency: storageLocation.dataResidency,
                image: storageLocation.image,
                securityMeasuresTitle: '',
                securityMeasuresURL: '',
                url: '',
            });
        });

        return options;
    };
    const getEntries = () => {
        const options = getOptions();

        let opts = filterListingOptions({
            source: options,
            searchedText,
            storageLocations: personalInventory,
        });
        return opts;
    };

    const isSelected = (label) => (selected.includes(label) ? true : false);

    const onToolSelected = (label) => {
        setSelected((currentState) => {
            let arr = [...currentState];
            if (currentState.includes(label)) {
                const indexOf = selected.findIndex((c) => c === label);
                if (indexOf === -1) return false;
                arr.splice(indexOf, 1);
            } else {
                arr.push(label);
            }
            return arr;
        });
    };

    const isToolAlreadyAdded = (label) => {
        if (props.data.find((p) => {
            const entryLabel = p.label.trim().toLowerCase();
            const labelChecked = label.trim().toLowerCase();

            return entryLabel === labelChecked;
        })) return true;
        return false;
    };

    const EntryBlock = (props) => {
        return (
            <div
                className={`component-storage-recommendation-entry ${isSelected(props.data.label) ? `selected` : `not-selected`}`}
                data-cy="entry"
                onClick={() => onToolSelected(props.data.label)}
            >
                <div className="content">
                    <div className="left-side">
                        {props.data.image ? (
                            <div
                                className="comp-img"
                                style={{
                                    backgroundImage: `url("${props.data.image}")`,
                                }}
                            />
                        ) : (
                            <div className="comp-avatar"> {props.data['label'].charAt(0).toUpperCase()} </div>
                        )}

                        <div className="label">{props.data.label}</div>
                        {isToolAlreadyAdded(props.data.label) && <div className="already-added-badge">Already added</div>}
                    </div>
                    <div className="btn" key={`${props.data.label}-${isSelected(props.data.label) ? `selected` : `not-selected`}`}>
                        <i className={`elm fa-solid ${isSelected(props.data.label) ? `fa-minus` : `fa-plus`}`} />
                    </div>
                </div>
                {isToolAlreadyAdded(props.data.label) && <div className="already-badge-subfooter">Already added</div>}
            </div>
        );
    };

    return (
        <React.Fragment>
            <div data-cy="module-popout" className={`module-popout dpa-variant module-picker-locations ${closing && `closing`}`}>
                <div className="module-content">
                    <div className="module-close" data-cy="module-close">
                        {selected.length > 0 ? <div className="tools-selected">{selected.length} tools selected</div> : null}
                        <div
                            className="done-button"
                            onClick={() =>
                                closeWithAnimation(() => {
                                    props.onDone(selected, getOptions());
                                })
                            }
                            data-cy="done-button"
                        >
                            Done
                        </div>
                    </div>
                    <div className="module-title">Add a tool or third party</div>

                    <div className="search-container">
                        <TextField
                            data-cy="search-input"
                            variant="outlined"
                            type="text"
                            fullWidth={true}
                            placeholder="Search for a specific tool.."
                            value={input}
                            onChange={(ev) => setInput(ev.target.value)}
                        />
                    </div>
                    {getEntries().length > 0 && (
                        <div className="component-storage-recommendation-entry add-button">
                            <div className="content" onClick={() => closeWithAnimation(() => onCreateCustomTool(''))}>
                                <div className="left-side">
                                    <div className="label">Create a custom tool or third party</div>
                                </div>
                                <div className="btn">
                                    <i className={`elm fa-solid fa-plus`} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="scrollable-container">
                        {searchedText.length < 1 ? (
                            <React.Fragment>
                                <div className="header-subsection">Tools and third parties in your Data Inventory</div>
                                <div className="entries" data-cy="inventory-options">
                                    {getEntries()
                                        .filter((elm) => (elm._inStorageLocations !== true ? false : true))
                                        .map((entry, ix) => (
                                            <EntryBlock data={entry} key={ix} />
                                        ))}
                                </div>
                                <div className="header-subsection">Common tools and third parties</div>
                                <div className="entries" data-cy="inventory-options">
                                    {getEntries()
                                        .filter((elm) => elm._inStorageLocations === false)
                                        .map((entry, ix) => (
                                            <EntryBlock data={entry} key={ix} />
                                        ))}
                                </div>{' '}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {getEntries().length > 0 ? (
                                    <React.Fragment>
                                        <div className="header-subsection">Search results</div>
                                        <div className="entries" data-cy="inventory-options">
                                            {getEntries()
                                                .filter((elm) => (elm._similar === true ? false : true))
                                                .map((entry, ix) => (
                                                    <EntryBlock data={entry} key={ix} />
                                                ))}
                                        </div>
                                        {getEntries().filter((x) => x._similar === true).length > 0 && (
                                            <React.Fragment>
                                                <div className="header-subsection">Other similar tools</div>
                                                <div className="entries" data-cy="similar-tools-options">
                                                    {getEntries()
                                                        .filter((x) => x._similar === true)
                                                        .map((entry, ix) => (
                                                            <EntryBlock data={entry} key={ix} />
                                                        ))}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className="no-results">
                                            <div className="heading">No results</div>
                                            <div className="description">
                                                There is no tool or third party matching this name in our list of recommendations.
                                            </div>
                                            <div className="advice">Press the button below to add it as a custom tool or third party.</div>
                                            <Button
                                                data-cy="create-custom-tool-btn"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => closeWithAnimation(() => onCreateCustomTool(searchedText))}
                                            >
                                                Create custom tool
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default Component;
