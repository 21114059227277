import React, { useState, useEffect } from 'react';
import { Button, ButtonBase } from '@mui/material';
import { ModuleState } from '../../..';
import { createStorageLocationWithRecommendations, createCustomStorageLocation } from '../utils/helpers';
import { capitalizeFirstLetter, logError, trackUserInteraction } from '../../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { getSuggestedToolGroupId, groupSuggestedTools } from '../../../components/functions';

let pendingToolsTimer = null;

// Components
import ModalComponent from './components/modal';
import Panel from './components/panel';
import SuggestedToolBadge from './components/sugestedToolBadge';

const Component = (props) => {
    const { data, dataRef, pendingTools, setPendingTools, pendingToolsRef, setData, vitalRecommendations } = ModuleState();
    const [picking, setPicking] = useState(false);
    const [options, setOptions] = useState([]);
    const [headlessCreate] = useState(props.headlessCreate ? true : false);

    const loadStorageOptions = async () => {
        try {
            const data = await makeEndpointRequest(`getStorageLocationsOptions`);
            setOptions(data);
        } catch (err) {
            await logError('LOAD_RECOMMENDATIONS_STORAGE_LOCS', err);
            await trackUserInteraction(`Having Difficulties`, { reason: `Failed to load the list of storage locations he can pick` });
        }
    };

    useEffect(() => {
        loadStorageOptions();

        return () => {
            clearTimeout(pendingToolsTimer);
        };
        // eslint-disable-next-line
    }, []);

    const [editData, setEditData] = useState({
        active: false,
        payload: {},
        creating: false,
        index: null,
    });

    const deleteLocation = async (label) => {
        const index = data.storageLocations.findIndex((i) => i.label === label);
        if (index === -1) return false;

        const newValues = [...data.storageLocations];

        await trackUserInteraction(`Selected "Delete"`, { tool: newValues[index].label });

        window.showAlert(
            'Confirmation',
            `Are you sure you want to delete ${newValues[index].label}?\n This can be reversed later.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Cancel"`);
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Confirm"`);
                        newValues.splice(index, 1);
                        setData({
                            ...data,
                            storageLocations: newValues,
                        });
                    },
                },
            ],
        );
    };

    const archiveLocation = async (label) => {
        const index = data.storageLocations.findIndex((i) => i.label === label);
        if (index === -1) return false;

        const newValues = [...data.storageLocations];

        await trackUserInteraction(`Selected "Archive"`, { tool: newValues[index].label });
        window.showAlert(
            'Confirmation',
            `Are you sure you want to archive ${newValues[index].label}?\nThis tool or third party won't show up on your inventory or policies. This can be reversed later.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Cancel"`);
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Confirm"`);
                        newValues[index].archived = true;
                        setData({
                            ...data,
                            storageLocations: newValues,
                        });
                    },
                },
            ],
        );
    };

    const editLocation = (option) => {
        const index = data.storageLocations.findIndex((i) => i.label === option.label);

        if (index === -1) return false;

        setEditData({
            creating: false,
            payload: {
                ...option,
            },
            index: index,
        });
    };

    const onModalDismiss = () => {
        setEditData({
            active: false,
            payload: {},
            index: null,
            creating: false,
        });
        if (props.headlessCreate === true) {
            props.onHeadlessDismiss();
        }
    };

    const getNumberOfEntries = (newLabel) => {
        // Get the proper number of tools..
        const numberOfEntries = data.storageLocations.filter((elm) => {
            // Transform Xero (2) into Xero
            const modifiedElmLabel = elm.label
                .replace(/\(\d+\)/g, '')
                .trim()
                .toLowerCase();

            // Just lowercasing..
            const modalChangedLabel = newLabel.trim().toLowerCase();
            return modifiedElmLabel === modalChangedLabel;
        }).length;

        return numberOfEntries;
    };

    const onModalSubmit = async (similarTool = null) => {
        let _editData = { ...editData };

        if (_editData.active === false) return false;

        setEditData({
            payload: {},
            active: false,
            creating: false,
            index: null,
        });

        if (props.headlessCreate === true) {
            props.onHeadClose();
        }

        if (_editData.creating === false) {
            let updatedObject = { ..._editData.payload };

            if (similarTool) {
                const res = await formatToolWithRecommendations(similarTool.label);
                updatedObject = {
                    ...res,
                    label: updatedObject.label,
                    origin: 'manual',
                    // Reset these...
                    securityMeasures: '',
                    dataResidency: '',
                    image: '',
                    // Cookie info
                    cookieExternalInfo: '',
                    cookiePurpose: '',
                    cookieType: '',
                    //
                };
            }

            setData((currentData) => {
                let modifiedData = { ...currentData };
                modifiedData.storageLocations[_editData.index] = updatedObject;
                return modifiedData;
            });
        } else {
            let toolFormatted = null;

            if (similarTool) {
                toolFormatted = await formatToolWithRecommendations(similarTool.label);
            }

            setData((currentData) => {
                let modifiedData = { ...currentData };
                let modalSubmitLabel = formatRightToolName(capitalizeFirstLetter(_editData.payload.label));

                let dataObject = null;

                if (similarTool && toolFormatted) {
                    dataObject = {
                        ...toolFormatted,
                        label: modalSubmitLabel,
                        // Reset these...
                        securityMeasures: '',
                        dataResidency: '',
                        image: '',
                        // Cookie info
                        cookieExternalInfo: '',
                        cookiePurpose: '',
                        cookieType: '',
                        //
                        //
                        origin: 'manual',
                    };
                } else {
                    dataObject = {
                        ...createCustomStorageLocation(modalSubmitLabel),
                        origin: `manual`,
                    };
                }

                modifiedData.storageLocations.push(dataObject);

                return modifiedData;
            });

            await trackUserInteraction(`Created custom tool`, {
                label: _editData.payload.label,
                isSimilarTo: similarTool ? similarTool.label : 'N/A',
            });
        }
    };

    const formatToolWithRecommendations = async (toolName) => {
        try {
            const recommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: [toolName],
                processes: [],
            });

            const storageObject = createStorageLocationWithRecommendations({
                toolName: toolName,
                specificRecommendations: recommendations,
                vitalRecommendations,
                targetAudience: dataRef.current.companyInfo.targetAudience,
                currentStorageLocations: dataRef.current.storageLocations,
                individualsReferring: dataRef.current.companyInfo.individualsReferring,
            });

            if (!storageObject) return null;

            return storageObject;
        } catch (err) {
            await logError('FAILED_GET_TOOL_RECOMMENDATION', err, { toolName });
            return null;
        }
    };

    const getInterfaceData = () => {
        const arr = options.map((item) => {
            return {
                ...item,
                origin: `recommendations`,
            };
        });

        let categories = [];

        arr.forEach((opt) => {
            if (!categories.includes(opt.category)) {
                categories.push(opt.category);
            }
        });

        categories = categories.sort();
        categories.splice(0, 0, 'All');

        return { options: arr, categories };
    };

    const formatRightToolName = (toolName) => {
        const numberOfTools = getNumberOfEntries(toolName);

        if (numberOfTools > 0) {
            return `${toolName} (${numberOfTools + 1})`;
        }

        return toolName;
    };

    const applyRecommendations = async () => {
        const toolsUsed = [...pendingToolsRef.current.map((x) => x.toolName)];
        try {
            let results = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: pendingToolsRef.current.map((x) => x.toolName),
                processes: [],
            });

            let toolsAdded = [...pendingToolsRef.current];

            setPendingTools([]);

            pendingToolsTimer = null;

            // Temporary due to a bug that should be fixed. When picking Xero one process rec is null.
            results.processes = results.processes.filter((x) => x !== null);

            let newElementsToStorageLocations = [];

            toolsAdded.forEach((pendingTool) => {
                const storageObject = createStorageLocationWithRecommendations({
                    toolName: pendingTool.toolName,
                    specificRecommendations: results,
                    vitalRecommendations,
                    targetAudience: dataRef.current.companyInfo.targetAudience,
                    currentStorageLocations: dataRef.current.storageLocations,
                    individualsReferring: dataRef.current.companyInfo.individualsReferring,
                });

                if (!storageObject) return;

                let entry = {
                    ...storageObject,
                    label: formatRightToolName(storageObject.label),
                    origin: `recommendations`,
                };

                newElementsToStorageLocations.push(entry);
            });

            setData({
                ...dataRef.current,
                storageLocations: [...dataRef.current.storageLocations, ...newElementsToStorageLocations],
            });
        } catch (err) {
            await logError(`GET_RECOMMENDATIONS_FOR_TOOLS`, err, { pendingTools, toolsUsed });
            await trackUserInteraction(`Having Difficulties`, { reason: `Failed to get recommendation for tools picked` });
            window.showAlert(
                'Having Difficulties',
                `The tool or third party you've selected couldn't be added. Please try again.`,
                `error`,
            );
        }
    };

    const onToolsPicked = (picked, options) => {
        let arr = [];

        picked.forEach((item) => {
            let src = options.find((i) => i.label === item);
            if (!src) return;
            arr.push({
                label: formatRightToolName(item),
                toolName: item,
                image: src.image,
                pending: true,
            });
        });

        if (arr.length > 0) {
            setPendingTools([...pendingTools, ...arr]);

            if (pendingToolsTimer !== null) {
                clearTimeout(pendingToolsTimer);
            }

            pendingToolsTimer = setTimeout(applyRecommendations, 1000);

            trackUserInteraction(`Selected new tools`, { tools: arr.map((x) => x.label) });
        } else {
            trackUserInteraction(`Closed without selecting new tools`);
        }

        setPicking(false);
    };

    const startPicking = (ev) => {
        const attr = ev.target.getAttribute('data-start-picking');
        if (attr !== 'true') return false;
        trackUserInteraction(`Selected "Add new tools"`);
        setPicking(true);
    };

    const shouldDisplayInList = (item) => {
        if (item.archived === true) return false;
        return true;
    };

    const createCustomTool = (input) => {
        if (picking === true) {
            setPicking(false);
        }

        trackUserInteraction(`Selected "Create custom tool"`);
        setEditData({
            creating: true,
            payload: {
                label: input ? input : ``,
                dataResidency: '',
                securityMeasures: '',
            },
            creating: true,
        });
    };

    useEffect(() => {
        if (props.headlessCreate === true) {
            trackUserInteraction(`Selected "Add new tools"`);
            setPicking(true);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            {headlessCreate === false && (
                <React.Fragment>
                    <div className="component-storage-locations-input">
                        <div data-start-picking="true" onClick={startPicking} data-cy="storage-locations-entries" className="entries">
                            {[...data.storageLocations, ...pendingTools].filter((item) => shouldDisplayInList(item)).length < 1 && (
                                <div className="placeholder-text">Click here to start adding tools or third parties..</div>
                            )}
                            {[...data.storageLocations, ...pendingTools]
                                .filter((item) => shouldDisplayInList(item))
                                .map((entry, index) => (
                                    <div className={`entry`} key={index}>
                                        {entry.image ? (
                                            <div
                                                className="img"
                                                style={{
                                                    backgroundImage: `url("${entry.image}")`,
                                                }}
                                            />
                                        ) : (
                                            <div className="avatar"> {entry['label'].charAt(0).toUpperCase()} </div>
                                        )}
                                        <div className="label">{entry.label}</div>
                                        <div className={`controls ${entry.pending && `is-pending`}`}>
                                            {props.disableEdit !== true && (
                                                <ButtonBase data-cy={`chip-edit`} onClick={() => editLocation(entry)}>
                                                    <i className="elm fa-light fa-pen-to-square" />
                                                </ButtonBase>
                                            )}

                                            {props.deleteInsteadOfArchive ? (
                                                <ButtonBase data-cy={`chip-delete`} onClick={() => deleteLocation(entry.label)}>
                                                    <i className="elm fa-solid fa-trash" />
                                                </ButtonBase>
                                            ) : (
                                                <ButtonBase data-cy={`chip-delete`} onClick={() => archiveLocation(entry.label)}>
                                                    <i className="elm fa-solid fa-box-archive" />
                                                </ButtonBase>
                                            )}
                                        </div>
                                        {getSuggestedToolGroupId(entry) && <SuggestedToolBadge id={getSuggestedToolGroupId(entry)} />}
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="component-storage-locations-buttons">
                        <Button
                            data-cy="add-tool-or-third-party-btn"
                            data-start-picking="true"
                            variant="contained"
                            color="primary"
                            onClick={startPicking}
                        >
                            Add a tool or third party
                        </Button>
                        <div className="right-side">
                            {groupSuggestedTools(data.storageLocations).map((c, ix) => (
                                <SuggestedToolBadge id={c.id} includeFooterLabel={true} key={ix} />
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            )}
            <ModalComponent
                setEditData={setEditData}
                editData={editData}
                onDismiss={onModalDismiss}
                onSubmit={onModalSubmit}
                options={options}
            />
            {picking && <Panel onDone={onToolsPicked} createCustomTool={createCustomTool} meta={getInterfaceData()} />}
        </React.Fragment>
    );
};

export default Component;
