const shareablesEndpoints = {
    getSettingsMeta: {
        link: '/graphql',
        method: 'POST',
        query: `
            query GetSettingsMeta {
                GetSettingsMeta {
                    currentUsage
                    usageLimits
                    package
                    pricingPlansListed
                    monitoringSettings
                    companyEmails
                }
              }
        `,
        parserFunction: ({ data }) => data.GetSettingsMeta,
    },
    ChangePackagePlan: {
        link: '/graphql',
        method: 'POST',
        query: `
        mutation ChangePackagePlan ($id: String) {
            ChangePackagePlan (id: $id)
        }`,
    },
    SetCustomerTranslation: {
        link: '/graphql',
        method: 'POST',
        query: `
        mutation SetCustomerTranslation ($payload: JSON, $system: String) {
            SetCustomerTranslation (payload: $payload, system: $system)
        }`,
    },
};

export default shareablesEndpoints;
