import React, { useState, useEffect } from 'react';
import { ComponentState } from '../../../..';
import { validateAgainstSchema } from '../../../../../../../utils/helpers';
import { editValidationSchema } from '../../validations';

// For now...
import PageComponent from './views/main';
import Avatar from '../../../../../../pages/gdprEssentials/steps/reviewData/components/edit/components/Avatar';

const Component = (props) => {
    const [validationResults, setValidationResults] = useState({});
    const { draftData } = ComponentState();
    const [closing, setClosing] = useState(false);

    const updateField = (field, value) => {
        props.setData((currentData) => {
            let modifiedData = JSON.parse(JSON.stringify(currentData));
            modifiedData.payload[field] = value;
            return modifiedData;
        });
    };

    const closeModal = async (skipForNow = false) => {
        setClosing(true);
        if (skipForNow === false) {
            await trackUserInteraction(`Selected "Close Edit"`);
        }
        setTimeout(() => {
            setClosing(false);
            props.onDismiss();
        }, 550);
    };

    const validateFields = async () => {
        try {
            await validateAgainstSchema(editValidationSchema, props.data);
            setValidationResults({});
            const matchTool = draftData.subProcessors.find((i) => i.label === props.data.label);
            const matchIndex = draftData.subProcessors.findIndex((i) => i.label === props.data.label);
            if (matchTool && matchIndex !== -1 && matchIndex !== props.editData.index) {
                setValidationResults({
                    label: `Name is already used. Try adding a suffix: ${props.data.label} (2)`,
                });
            }
        } catch (err) {
            setValidationResults(err);
            return false;
        }
    };

    const autoSaving = () => {
        const isValid = validateFields();

        // They just finished..
        if (isValid !== false) {
            props.onSubmit();
        }
    };

    useEffect(() => {
        validateFields();
        autoSaving();
        // eslint-disable-next-line
    }, [props.data]);

    const mappedProps = {
        setData: props.setData,
        data: props.data,
        validationResults,
        updateField,
        onDelete: () => {
            props.onDelete();
        },
    };

    //
    return (
        <React.Fragment>
            <div data-cy="module-popout" className={`module-popout dpa-edit ${closing && `closing`}`}>
                <div className="module-content">
                    <div className="module-close" onClick={closeModal} data-cy="panel-close">
                        <i className="icon fa-solid fa-xmark"></i>
                    </div>
                    <div className="module-title">Edit sub-processor</div>
                    <div className="module-subtitle">
                        <Avatar image={props.data.image} label={props.data.label} />
                        {props.data.label}
                    </div>
                    <PageComponent {...mappedProps} />
                </div>
            </div>
            <div className="module-shadow-underlay" />
        </React.Fragment>
    );
};

export default Component;
