import React, { useEffect, useState } from 'react';

import { useStateRef } from '../../../../utils/helpers';
import { InputAdornment, TextField } from '@mui/material';

let timerChanges = null;

import Base from '../base';

const Component = (props) => {
    const [open, setOpen, openRef] = useStateRef(false);
    const [fieldValue, setFieldValue] = useState('');

    // Definitions
    const containerTargetId = 'component-search-container';

    useEffect(() => {
        if (open === true && props.onChanges) {
            if (timerChanges) clearTimeout(timerChanges);
            timerChanges = setTimeout(() => {
                props.onChanges(fieldValue);
            }, 500);
        }
        // eslint-disable-next-line
    }, [fieldValue]);

    const passedProps = {
        containerTargetId,
        open,
        setOpen,
        openRef,
        buttonId: props.buttonId,
    };

    return (
        <Base className="component-search" {...passedProps}>
            <div className="heading">{props.heading}</div>
            <div className="container">
                <TextField
                    fullWidth={true}
                    variant="standard"
                    value={fieldValue}
                    onChange={(ev) => setFieldValue(ev.target.value)}
                    placeholder={props.inputPlaceholder}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {fieldValue.length > 0 && (
                                    <div className="clear-button" size="small" onClick={() => setFieldValue('')}>
                                        <i className="elm fa-solid fa-circle-xmark"></i>
                                    </div>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </Base>
    );
};

export default Component;
