import React, { useEffect, useState } from 'react';

// Dummy data
import CountriesInEEA from '../../../../../../dummy_data/countriesInEEA.json';

// Components
import EuropeanEconomicArea from './components/europeanEconomicArea';
import UnitedKingdom from './components/unitedKingdom';
import InformationWeCollected from './components/informationWeCollected';
import Translations from './components/translations';
import ContentBlocks from './components/contentBlocks';
import AutomatedDecisionMaking from './components/automatedDecisionMaking';

// Schema
import { privacyPolicySchema } from './utils/validation';
import { validateAgainstSchema } from '../../../../../../utils/helpers';
import { ComponentState } from '../../..';
import { Button } from '@mui/material';

const Page = () => {
    const { saveChanges, saveDisabled, data, validationKeys, setValidationKeys } = ComponentState();

    const [operatesLocation, setOperatesLocation] = useState(null);

    useEffect(() => {
        if (CountriesInEEA.includes(data.companyAddress.country)) {
            setOperatesLocation('EU');
        } else if (data.companyAddress.country === 'United Kingdom') {
            setOperatesLocation(`UK`);
        } else {
            setOperatesLocation(`RestOfTheWorld`);
        }
    }, [data.companyAddress.country]);

    const validateFields = async () => {
        try {
            await validateAgainstSchema(privacyPolicySchema, data);
            setValidationKeys((currentState) => {
                let existingKeys = {};
                if (currentState['dataElements'] !== undefined) {
                    // we need this here so we don't have this code undo the validation for the elements which are in the same page
                    existingKeys['dataElements'] = currentState['dataElements'];
                }
                return existingKeys;
            });
        } catch (err) {
            setValidationKeys((currentState) => {
                let existingKeys = {};
                if (currentState['dataElements'] !== undefined) {
                    existingKeys['dataElements'] = currentState['dataElements'];
                }
                return { ...existingKeys, ...err };
            });
        }
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        return () => {
            setValidationKeys({});
        };
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <Translations />
            <EuropeanEconomicArea operatesLocation={operatesLocation} />
            <UnitedKingdom operatesLocation={operatesLocation} />
            <div className="page-footer">
                <Button
                    data-cy="european-economic-save-button"
                    variant="contained"
                    color="primary"
                    onClick={saveChanges}
                    disabled={
                        saveDisabled && Object.keys(validationKeys).length > 0 && !Object.keys(validationKeys).includes(`dataElements`)
                    }
                >
                    Save changes
                </Button>
            </div>
            <InformationWeCollected />
            <div className="page-footer">
                <Button
                    data-cy="information-we-collect-save-button"
                    variant="contained"
                    color="primary"
                    onClick={saveChanges}
                    disabled={
                        saveDisabled && Object.keys(validationKeys).length > 0 && !Object.keys(validationKeys).includes(`dataElements`)
                    }
                >
                    Save changes
                </Button>
            </div>

            <AutomatedDecisionMaking />
            <div className="page-footer">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={saveChanges}
                    data-cy="info-we-collect-saveButton"
                    disabled={
                        (saveDisabled && Object.keys(validationKeys).length > 0 && !Object.keys(validationKeys).includes(`dataElements`)) ||
                        // @TODO: To add this to the validation, not here, for now we're in a rush.
                        (data.automatedDecisionMaking.enabled === true && data.automatedDecisionMaking.clauseText.length < 1)
                    }
                >
                    Save changes
                </Button>
            </div>

            <ContentBlocks pageId="privacy-policy" />
        </React.Fragment>
    );
};

export default Page;
