import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { ComponentState } from '../../..';
import Tooltip from '../../../../../components/tooltip';

const Component = () => {
    const { draftData, updateDraftData } = ComponentState();

    return (
        <React.Fragment>
            <div className="form-group extra-spacing">
                <div className="form-label mb">
                    Is <b>sensitive data</b> processed when providing your services/platform?
                    <Tooltip
                        useHTML={true}
                        content={
                            <React.Fragment>
                                <div style={{ textAlign: 'left' }}>
                                    <p>
                                        Answer yes if you’re likely to process one of the following types of data when offering your
                                        services.
                                    </p>
                                    <ul>
                                        <li>data revealing racial or ethnic origin</li>
                                        <li>data revealing political opinions</li>
                                        <li>data revealing religious or philosophical beliefs</li>
                                        <li>data revealing trade union membership</li>
                                        <li>genetic data</li>
                                        <li>Biometric data (where used for identification purposes)</li>
                                        <li>data concerning health</li>
                                        <li>data concerning a person’s sex life or sexual orientation</li>
                                    </ul>
                                </div>
                            </React.Fragment>
                        }
                    >
                        <div className="tooltip" style={{ display: 'inline-block' }}>
                            <i className="tooltip-icon fa-solid fa-circle-info"></i>
                        </div>
                    </Tooltip>
                </div>
                <div className="form-content">
                    <Select
                        fullWidth={true}
                        variant="outlined"
                        value={draftData.isSensitiveData !== null ? draftData.isSensitiveData : 'none-selected'}
                        onChange={(e) => updateDraftData('isSensitiveData', e.target.value)}
                    >
                        {draftData.isSensitiveData === null && (
                            <MenuItem disabled={true} value="none-selected">
                                Click here to select..
                            </MenuItem>
                        )}
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
