import React from 'react';

// Context
import { OnboardingFunctions } from '../../../components/functions';
import { Button } from '@mui/material';

// Context
import { logError } from '../../../../../../utils/helpers';
import { ViewContext } from '..';
import { OnboardingContext } from '../../..';

const Component = () => {
    const { navigateViews } = OnboardingFunctions();
    const { tasksPending } = OnboardingContext();
    const { activities, updateCompanyActivities } = ViewContext();

    const nextStep = async () => {
        try {
            // Update company activities
            await updateCompanyActivities();

            // Navigate
            navigateViews('forward');
        } catch (err) {
            await logError(`onboarding.activities.nextStep`, err);
        }
    };

    const previousStep = async () => {
        try {
            // Update company activities
            await updateCompanyActivities();

            // Navigate
            navigateViews('backward');
        } catch (err) {
            await logError(`onboarding.activities.previousStep`, err);
        }
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">Core business activity/activities</div>
                    <div className="description">What core activities define the main value you offer to your customers?</div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={nextStep}
                        disabled={activities.length < 1 || tasksPending.generatingActivities}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
