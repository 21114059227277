import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { logError, trackUserInteraction } from '../../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { AuthStore } from '../../../../../../utils/context/authStore';

// UI Components
import { PageContext } from '../../..';

const Component = (props) => {
    const [submitted, setSubmitted] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selected, setSelected] = useState(null);
    const { account } = AuthStore();
    const { loadData } = PageContext();

    const dismissModal = () => {
        trackUserInteraction(`Selected "Dismiss Modal"`);
        setVisible(false);
    };

    const onEventOfButton = ({ detail }) => {
        if (detail !== props.data._id) return false;
        trackUserInteraction(`Selected "Delete Company"`);
        setVisible(true);
        setSubmitted(false);
        setSelected(null);
    };

    const deleteCompany = async () => {
        try {
            // If it's his current company...
            if (account.workspace._id === props.data._id && account._companyId === selected) {
                return window.showAlert(
                    'Unable to proceed',
                    `Is not possible to delete the company you are currently using. Please switch to a different company before deleting this one.`,
                    `warning`,
                );
            }

            const companyData = props.data.companies.find((c) => c._companyId === selected);

            await trackUserInteraction(`Deleted Company`, {
                companyLegalName: companyData.details.companyLegalName,
                _companyId: companyData.details._id,
            });

            // Delete the company..
            await makeEndpointRequest(`DeleteWorkspaceCompanyV2`, {
                _companyId: selected,
                _workspaceId: props.data._id,
            });

            // Refresh data..
            await loadData();

            // Reset this
            setSelected(null);

            // Show this again
            setVisible(true);
        } catch (err) {
            trackUserInteraction(`Having Difficulties`, { reason: `failed to delete the company within workspace` });
            await logError(`DELETE_COMPANY_WITHIN_WORKSPACE`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    const onDeleteConfirmation = async () => {
        // Get the company data
        const company = props.data.companies.find((e) => e._companyId === selected);

        // Hide the dialog..
        setVisible(false);

        const companyName =
            company.details.companyLegalName && company.details.companyLegalName.length > 0
                ? company.details.companyLegalName
                : 'Unnamed company';

        // Ask confirmation..
        window.showAlert(
            'Confirmation',
            `Are you sure you want to delete the company "${companyName}"? If you are sure, confirm by writing "Delete" in the input below.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        // Hide the alert..
                        dismissAlert();

                        // Put it back on
                        setVisible(true);
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert, inputValue }) => {
                        // If they didn't enter the right one.
                        if (inputValue.toString().toLowerCase() !== 'delete') {
                            return false;
                        }

                        dismissAlert();
                        deleteCompany(company._companyId);
                    },
                },
            ],
            {
                input: {
                    type: 'text',
                    placeholder: 'Delete',
                    validationField: ({ inputValue }) => {
                        if (inputValue.toString().toLowerCase() !== 'delete') return 'Invalid confirmation message.';

                        return null; // no error, all good.
                    },
                },
            },
        );
    };

    useEffect(() => {
        document.addEventListener('workspaces:ManageCompanies', onEventOfButton);

        return () => {
            document.removeEventListener('workspaces:ManageCompanies', onEventOfButton);
        };

        // eslint-disable-next-line
    }, []);

    const createCompanies = () => {
        // Hide dialog
        setVisible(false);
        document.dispatchEvent(new CustomEvent('workspaces:CreateCompany', { detail: props.data._id }));
    };
    if (visible === false) return null;

    return (
        <React.Fragment>
            <Dialog className="modal-workspace-manage-companies" open={visible} maxWidth="sm" fullWidth>
                <DialogTitle data-cy="dialog-title">
                    <div
                        style={{
                            justifyContent: 'space-between',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 8,
                            marginBottom: 8,
                        }}
                    >
                        <div className="left-side">
                            <div className="text" style={{ fontSize: 16, lineHeight: '1.5' }}>
                                Select a Company
                            </div>
                        </div>
                        <div className="right-side">
                            <Button variant="contained" size="small" color="primary" onClick={createCompanies}>
                                Create Company
                            </Button>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent style={{ width: '100%', minHeight: 200 }}>
                    {props.data.companies.map((comp, ix) => (
                        <MenuItem
                            key={ix}
                            selected={selected === comp._companyId}
                            onClick={() => setSelected(comp._companyId === selected ? null : comp._companyId)}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    padding: 8,
                                }}
                            >
                                <div className="details" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div className="component-company-avatar avatar" style={{ marginRight: 16 }}>
                                        {(comp.details.companyLegalName || 'Un-named company').slice(0, 2).toString().toUpperCase()}
                                    </div>
                                    <div className="text">{comp.details.companyLegalName || 'Un-named company'}</div>
                                </div>
                                {account._companyId === comp._companyId && <div className="current-company">Current Company</div>}
                            </div>
                        </MenuItem>
                    ))}
                    {props.data.companies.length < 1 && <div className="div">No companies to select.</div>}
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            justifyContent: 'space-between',
                            padding: '10px 8px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <div className="left-side">
                            <Button onClick={dismissModal} variant="outlined" data-cy="dialog-dismiss-button" style={{ marginRight: 8 }}>
                                Dismiss
                            </Button>
                        </div>
                        {props.data.companies.length > 0 && (
                            <div className="right-side">
                                <Button
                                    onClick={() => onDeleteConfirmation()}
                                    variant="contained"
                                    color="primary"
                                    disabled={submitted || selected === null || (selected && selected === account._companyId)}
                                    data-cy="dialog-save-button"
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
