import React from 'react';
import { ComponentState } from '../../..';
import { trackUserInteraction } from '../../../../../../utils/helpers';

const Component = () => {
    const { data } = ComponentState();

    const onProviderClicked = async (provider) => {
        const match = builderProviders.find((x) => x.label === provider);
        await trackUserInteraction(`Selected "Builder Instructions"`, { tool: `provider` });
        window.open(match.link, `_blank`);
    };

    const builderProviders = [
        {
            label: `Wordpress`,
            icon: `fa fa-box`,
            link: `https://wordpress.org/support/article/embeds/`,
        },
        {
            label: `Shopify`,
            icon: `fa-solid fa-box`,
            link: `https://help.widgetic.com/integrations/Shopify/how-to-embed`,
        },
        {
            label: `Squarespace`,
            icon: `fa-brands fa-squarespace`,
            link: `https://support.squarespace.com/hc/en-us/articles/206543617-Embed-Blocks`,
        },
        {
            label: `Webflow`,
            icon: `fa-solid fa-box`,
            link: `https://university.webflow.com/lesson/custom-code-embed`,
        },
        {
            label: `Wix`,
            icon: `fa-brands fa-wix`,
            link: `https://support.wix.com/en/article/wix-editor-embedding-a-site-or-a-widget`,
        },
        {
            label: `Softr`,
            icon: `fa-solid fa-box`,
            link: `https://docs.softr.io/integrations/typeform#adding-the-embed-code`,
        },
    ];

    if (data.isPrivacyPortalHosted === true) return null;

    return (
        <React.Fragment>
            <div id="website-builders" className="step-entry">
                <div className="step-header">
                    <div className="step-badge">Info</div>
                    <div className="step-label">Website Builders</div>
                </div>
                <div className="step-content">
                    <div className="step-text">
                        Here are guides on how to embed code in the most common website and webapp builders. Click on your website builder
                        to learn more.
                    </div>
                    <div className="website-builder-providers">
                        {builderProviders.map((provider) => (
                            <div className="entry" key={provider.label} onClick={() => onProviderClicked(provider.label)}>
                                <div className="icon-container">
                                    <i className={`elm ${provider.icon}`}></i>
                                </div>
                                <div className="label">{provider.label}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
