//  Routes
import Start from './routes/start';
import DescribeService from './routes/describeService';
import MapService from './routes/mapServices';
import SubProcessors from './routes/subProcessors';
import Dynamic from './routes/securityMeasures';
import RestrictedTransfers from './routes/restrictedTransfers';
import Roles from './routes/roles';
import Terms from './routes/terms';
import Completed from './routes/completed';

// Map Routes
export const mapRoutes = {
    start: Start,
    'describe-service': DescribeService,
    'map-services': MapService,
    'sub-processors': SubProcessors,
    'security-measures': Dynamic,
    'restricted-transfers': RestrictedTransfers,
    roles: Roles,
    completed: Completed,
    terms: Terms,
};

export const validateRoute = (id) => {
    const isExistingRoute = mapRoutes[id] ? true : false;

    return isExistingRoute ? id : 'start';
};
