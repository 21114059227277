import React from 'react';

// Components
import Header from './components/header';
import RemoveButton from './components/removeButton';
import Individual from './components/individual';
import SetIndividuals from './components/setIndividuals';

// MUI Components
import { FormHelperText } from '@mui/material';

const Component = (props) => {
    const individualsMissingElements = props.individuals.filter((c) => c.elements.length < 1);

    return (
        <React.Fragment>
            <div className="component-tool">
                <Header label={props.data.label} removeBtn={<RemoveButton data={props.data} />} />
                <div className="individuals">
                    {props.individuals.map((c, index) => (
                        <Individual key={index} process={props.data} individual={c.data} elements={c.elements} />
                    ))}
                    {props.individuals.length < 1 && (
                        <React.Fragment>
                            <div className="--component-no-entry">
                                This process does not gather data from any individuals. To designate the individuals for potential data
                                collection, click the "Add Individuals" button.
                            </div>
                            <FormHelperText error={true} style={{ marginTop: 12 }}>
                                To integrate this process, please add an individual.
                            </FormHelperText>
                        </React.Fragment>
                    )}
                    {individualsMissingElements.length > 0 && (
                        <React.Fragment>
                            <FormHelperText error={true} style={{ marginTop: 12 }}>
                                Please provide details on the data collected from individuals highlighted in red.
                            </FormHelperText>
                        </React.Fragment>
                    )}
                    <div className="footer">
                        <SetIndividuals individuals={props.individuals} process={props.data} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
