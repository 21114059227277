import React from 'react';
import { TextField, Select, MenuItem } from '@mui/material';

// Schema
import { ComponentState } from '../../../..';
import { getValidationPropFields } from '../../../../../../../utils/helpers';

const Page = props => {
    const { data, updateData, validationKeys } = ComponentState();

    return (
        <React.Fragment>
            {props.operatesLocation !== 'EU' && (
                <React.Fragment>
                    <div className="form-label">Do you operate in the European Economic Area?</div>
                    <div className="form-content">
                        <Select
                            data-cy="operateInEU-select"
                            fullWidth={true}
                            onChange={e => updateData(`operateInEU`, e.target.value)}
                            value={data.operateInEU === null ? 'none-selected' : data.operateInEU}
                        >
                            <MenuItem value="none-selected" disabled={true}>
                                Select from list
                            </MenuItem>
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </div>

                    {data.operateInEU && (
                        <React.Fragment>
                            <div className="form-label">Do you have an office, branch or establishment in the European Economic Area?</div>
                            <div className="form-content">
                                <Select
                                    fullWidth={true}
                                    data-cy="branchInEu-select"
                                    onChange={e => updateData(`branchInEU`, e.target.value)}
                                    value={data.branchInEU !== null ? data.branchInEU : 'none-selected'}
                                >
                                    <MenuItem value="none-selected" disabled={true}>
                                        Select from list
                                    </MenuItem>
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </div>
                        </React.Fragment>
                    )}
                    {data.operateInEU && data.branchInEU && (
                        <React.Fragment>
                            <div className="form-label">EEA Branch Address</div>
                            <div className="form-content">
                                <TextField
                                    value={data.branchEUAddress || ''}
                                    fullWidth={true}
                                    data-cy="branchEUAddress-input"
                                    className="input"
                                    placeholder="EEA Branch Address"
                                    variant="standard"
                                    onChange={e => updateData(`branchEUAddress`, e.target.value)}
                                    {...getValidationPropFields(validationKeys, true, `branchEUAddress`)}
                                />
                            </div>
                        </React.Fragment>
                    )}
                    {data.operateInEU && !(data.branchInEU === null) && !data.branchInEU && (
                        <React.Fragment>
                            <div className="form-heading">EEA Representative Information</div>
                            <div className="form-label">Company Name</div>
                            <div className="form-content">
                                <TextField
                                    value={data.euRepInfo.euRepName || ''}
                                    fullWidth={true}
                                    className="input"
                                    data-cy="euRepName-input"
                                    placeholder="Company Name"
                                    variant="standard"
                                    onChange={e => updateData(`euRepInfo.euRepName`, e.target.value)}
                                    {...getValidationPropFields(validationKeys, true, `euRepInfo.euRepName`)}
                                />
                            </div>
                            <div className="form-label">Address</div>
                            <div className="form-content">
                                <TextField
                                    value={data.euRepInfo.euRepAddress || ''}
                                    fullWidth={true}
                                    className="input"
                                    placeholder="Address"
                                    variant="standard"
                                    data-cy="euRepAddress-input"
                                    onChange={e => updateData(`euRepInfo.euRepAddress`, e.target.value)}
                                    {...getValidationPropFields(validationKeys, true, `euRepInfo.euRepAddress`)}
                                />
                            </div>
                            <div className="form-label">Contact Details</div>
                            <div className="form-content">
                                <TextField
                                    value={data.euRepInfo.euRepContact || ''}
                                    fullWidth={true}
                                    data-cy="euRepContact-input"
                                    className="input"
                                    placeholder="Contact details"
                                    variant="standard"
                                    onChange={e => updateData(`euRepInfo.euRepContact`, e.target.value)}
                                    {...getValidationPropFields(validationKeys, true, `euRepInfo.euRepContact`)}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Page;
