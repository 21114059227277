import React from 'react';

// Components
import { TextField } from '@mui/material';

// Context
import { PanelContext } from '..';

const Component = () => {
    const { searchValue, setSearchValue } = PanelContext();

    return (
        <React.Fragment>
            <div className="search-container">
                <TextField
                    value={searchValue}
                    data-cy="search-input"
                    variant="outlined"
                    type="text"
                    fullWidth={true}
                    placeholder="Search for a specific tool.."
                    onChange={(ev) => setSearchValue(ev.target.value)}
                />
            </div>
        </React.Fragment>
    );
};

export default Component;
