import React from 'react';
import { ComponentState } from '../../../index';
import { Switch } from '@mui/material';
import { trackUserInteraction } from '../../../../../../utils/helpers';

const Component = () => {
    const { data, setData, flags, loading, ucSettings, setUcSettings } = ComponentState();

    if (flags.googleConsentMode === false) return null;

    const onSwitch = async () => {
        const newState = !data.cookieBannerSettings.googleConsentMode;

        // Track
        trackUserInteraction(`Switched "Google Consent Mode"`, { newState });

        // Update UC Settings
        setUcSettings({
            ...ucSettings,
            googleConsentMode: newState,
        });

        // Update settings in the company
        setData((currentState) => ({
            ...currentState,
            cookieBannerSettings: {
                ...currentState.cookieBannerSettings,
                googleConsentMode: newState,
            },
        }));

        if (newState === true) {
            window.showAlert(
                `Information`,
                <React.Fragment>
                    Before proceeding, make sure to save your changes. After saving, refer to this{' '}
                    <a
                        href="https://www.notion.so/privaseeio/In-Progress-Google-Consent-Mode-Google-Tag-Manager-configuration-required-8bd19073c5f9475d9d82caf78295b292?pvs=4"
                        target="_blank"
                    >
                        post
                    </a>{' '}
                    for the necessary steps to complete the implementation.
                </React.Fragment>,
                `info`,
                [
                    {
                        text: 'Understood',
                        dataCy: `alert-fix-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                ],
            );
        }
    };

    return (
        <React.Fragment>
            <div className="form-label">Google Consent Mode</div>
            <div className="form-description-with-image">
                <div className="form-description">
                    <p>Enable Google Consent Mode to enhance your cookie banner's compatibility with Google's consent mechanisms.</p>
                </div>
            </div>
            <div className="form-content">
                <Switch
                    checked={data.cookieBannerSettings.googleConsentMode}
                    color="primary"
                    onClick={onSwitch}
                    disabled={loading || !data.cookieBannerSettings.enabled}
                />
                {/* <div style={{ marginTop: 8 }}>
                    Click <a href="#">here</a> for more documentation and to learn what third-parties will be blocked automatically.
                </div> */}
            </div>
        </React.Fragment>
    );
};

export default Component;
