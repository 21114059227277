import { Button, TextField, FormHelperText, Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ModuleState } from '../..';
import { trackUserInteraction, validateAgainstSchema, getValidationPropFields } from '../../../../../utils/helpers';
import { certificateSubmitSchema } from '../../utils/validations';

import AllCountries from '../../../../../dummy_data/countries.json';

const StepComplete = () => {
    const { data, updateData, setStep, submitted, submitData } = ModuleState();
    const [validationResults, setValidationResults] = useState({});

    const validateFields = async () => {
        try {
            await validateAgainstSchema(certificateSubmitSchema, data.companyInfo);
            setValidationResults({});
        } catch (err) {
            setValidationResults(err);
        }
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [data]);

    const goBack = async () => {
        if (submitted === true) return false;
        setStep(`LegalResponsibility`);
        await trackUserInteraction(`Selected "Go Back"`, { destination: `LegalResponsibility` });
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" onClick={goBack} data-cy={`back-button`}>
                        Go back
                    </div>
                    <div className="title" data-cy={`step-title`}>
                        Congrats - you are nearly there!
                    </div>
                    <div className="subtitle">Enter your company details to get your GDPR policies.</div>
                </div>
            </div>
            <div className="complete module-content">
                <div className="section-title">Data Protection Officer</div>
                <div className="section-group">
                    <div className="form-group">
                        <div className="label">Name</div>
                        <div className="input">
                            <TextField
                                autoFocus
                                id="name"
                                fullWidth
                                disabled={submitted}
                                variant="outlined"
                                placeholder="John Doe"
                                data-cy={`input-name`}
                                value={data.companyInfo.dpoName}
                                onChange={(e) => updateData('companyInfo.dpoName', e.target.value)}
                                {...getValidationPropFields(
                                    validationResults,
                                    data.companyInfo.dpoName && data.companyInfo.dpoName.length > 0,
                                    `dpoName`,
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label">Privacy Queries Email</div>
                        <div className="input">
                            <TextField
                                autoFocus
                                id="email"
                                fullWidth
                                disabled={submitted}
                                variant="outlined"
                                data-cy={`input-email`}
                                placeholder="name@domain.com"
                                value={data.companyInfo.inquiriesEmail}
                                onChange={(e) => updateData('companyInfo.inquiriesEmail', e.target.value)}
                                {...getValidationPropFields(
                                    validationResults,
                                    data.companyInfo.inquiriesEmail && data.companyInfo.inquiriesEmail.length > 0,
                                    `inquiriesEmail`,
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="section-title">Company Details</div>
                <div className="section-group">
                    <div className="form-group">
                        <div className="label">Company Legal Name</div>
                        <div className="input">
                            <TextField
                                autoFocus
                                fullWidth
                                disabled={submitted}
                                variant="outlined"
                                placeholder="Company Name LTD"
                                data-cy={`input-companyName`}
                                value={data.companyInfo.companyLegalName}
                                onChange={(e) => updateData('companyInfo.companyLegalName', e.target.value)}
                                {...getValidationPropFields(
                                    validationResults,
                                    data.companyInfo.companyLegalName && data.companyInfo.companyLegalName.length > 0,
                                    `companyLegalName`,
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-group col-2">
                        <div className="label">Address Line 1</div>
                        <div className="input">
                            <TextField
                                autoFocus
                                fullWidth
                                disabled={submitted}
                                variant="outlined"
                                placeholder="Line 1"
                                data-cy={`input-line1`}
                                value={data.companyInfo.companyAddress.line1}
                                onChange={(e) => updateData('companyInfo.companyAddress.line1', e.target.value)}
                                {...getValidationPropFields(
                                    validationResults,
                                    data.companyInfo.companyAddress.line1 && data.companyInfo.companyAddress.line1.length > 0,
                                    `companyAddress.line1`,
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-group col-2">
                        <div className="label">Address Line 2</div>
                        <div className="input">
                            <TextField
                                autoFocus
                                fullWidth
                                disabled={submitted}
                                variant="outlined"
                                placeholder="Line 2"
                                data-cy={`input-line2`}
                                value={data.companyInfo.companyAddress.line2}
                                onChange={(e) => updateData('companyInfo.companyAddress.line2', e.target.value)}
                                {...getValidationPropFields(
                                    validationResults,
                                    data.companyInfo.companyAddress.line2 && data.companyInfo.companyAddress.line2.length > 0,
                                    `companyAddress.line2`,
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-group col-2">
                        <div className="label">City</div>
                        <div className="input">
                            <TextField
                                autoFocus
                                fullWidth
                                disabled={submitted}
                                variant="outlined"
                                placeholder="City"
                                data-cy={`input-city`}
                                value={data.companyInfo.companyAddress.city}
                                onChange={(e) => updateData('companyInfo.companyAddress.city', e.target.value)}
                                {...getValidationPropFields(
                                    validationResults,
                                    data.companyInfo.companyAddress.city && data.companyInfo.companyAddress.city.length > 0,
                                    `companyAddress.city`,
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-group col-2">
                        <div className="label">Postcode</div>
                        <div className="input">
                            <TextField
                                autoFocus
                                fullWidth
                                disabled={submitted}
                                variant="outlined"
                                placeholder="Postcode"
                                data-cy={`input-postcode`}
                                value={data.companyInfo.companyAddress.postCode}
                                onChange={(e) => updateData('companyInfo.companyAddress.postCode', e.target.value)}
                                {...getValidationPropFields(
                                    validationResults,
                                    data.companyInfo.companyAddress.postCode && data.companyInfo.companyAddress.postCode.length > 0,
                                    `companyAddress.postCode`,
                                )}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label">Country</div>
                        <div className="input">
                            <Autocomplete
                                value={data.companyInfo.companyAddress.country}
                                fullWidth={true}
                                disableClearable={true}
                                className="country-autocomplete"
                                options={AllCountries.map((c) => c.name)}
                                groupBy={(option) => {
                                    const o = AllCountries.find((c) => c.name === option);
                                    return o._muiCategory || 'Rest of the world';
                                }}
                                disabled={submitted}
                                renderInput={(params) => <TextField variant="outlined" {...params} placeholder="Country" />}
                                data-cy={`input-country`}
                                freeSolo={false}
                                multiple={false}
                                onChange={(_, value) => updateData('companyInfo.companyAddress.country', value)}
                            />
                            {validationResults[`companyAddress.country`] !== undefined &&
                                data.companyInfo.companyAddress.country &&
                                data.companyInfo.companyAddress.country.length > 0 && (
                                    <FormHelperText error={true}>{validationResults['companyAddress.country']}</FormHelperText>
                                )}
                        </div>
                    </div>
                </div>
                <div className="section-submit">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={Object.keys(validationResults).length > 0 || submitted}
                        onClick={() => submitData()}
                        data-cy={`submit-button`}
                    >
                        {submitted ? `Loading...` : `Generate my Privacy and Cookie Policies`}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default StepComplete;
