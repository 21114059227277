import React from 'react';

// Context
import { ModuleState } from '../..';

// Components
import Group from './components/group';
import { filterProcesses } from '../../utils/helpers';

const Component = () => {
    const { data, options, vitalRecommendations } = ModuleState();

    const getGroups = () => {
        // Groups of data
        const groups = {
            controller: [],
            missingInformation: [],
            processor: [],
            jointController: [],
        };

        // The order of those groups
        let sortingOrder = {
            missingInformation: 1,
            controller: 2,
            processor: 3,
            jointController: 4,
        };

        let dataFiltered = filterProcesses(data, options.filters, vitalRecommendations.retentionPeriods);

        // Iterate
        dataFiltered.forEach((process) => {
            // Any incomplete process will be put there.
            if (!process.validated) {
                groups['missingInformation'].push(process);
                return false;
            }

            // If role is processor
            if (process.role === 'Processor') {
                groups['processor'].push(process);
            }

            // If role is controller
            if (process.role === 'Controller') {
                groups['controller'].push(process);
            }

            // If role is joint controller
            if (process.role === 'Joint Controller') {
                groups['jointController'].push(process);
            }
        });

        // Format sections
        let sections = Object.keys(groups)
            .sort((a, b) => sortingOrder[a] - sortingOrder[b])
            .map((key) => ({ id: key, entries: groups[key] }))
            .filter((c) => c.entries.length > 0); // only those with entries

        return sections;
    };

    return (
        <React.Fragment>
            {getGroups().map((c, ix) => (
                <Group id={c.id} key={ix} entries={c.entries} />
            ))}
        </React.Fragment>
    );
};

export default Component;
