import React, { useState, createContext, useContext, useEffect } from 'react';

// Dependencies
import Container from '../../../layout/container';
import { CircularProgress } from '@mui/material';
import { logError } from '../../../../utils/helpers';
import { makeLocalEndpointRequest } from '../../../../utils/endpoints';

// Context
const Context = createContext({});
export const PageContext = () => useContext(Context);

// Components
import Header from './components/header';
import Details from './components/details';
import Documents from './components/documents';

const Component = (props) => {
    const prod = process.env.NODE_ENV === 'production';
    useEffect(() => {
        const redirectUrl = prod ? 'https://beta.privasee.io/deals' : 'http://localhost:3001/deals';
        window.location.href = redirectUrl;
    }, [prod]);

    return null;

    // Variables
    const dealId = props.match.params.id;

    // Data
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    // Documents
    const [documents, setDocuments] = useState([]);
    const [loadingDocuments, setLoadingDocuments] = useState(true);

    // Other dependencies
    const loadData = async () => {
        try {
            setLoading(true);

            // Get the deal data
            const res = await makeLocalEndpointRequest(`/api/deals/getDeal/${dealId}`, {}, 'GET');

            // Set data...
            setData(res);

            // Load documents now
            loadDocuments(false);

            setLoading(false);
        } catch (err) {
            await logError(`LOAD_DEALS`, err);
        }
    };

    const deleteDeal = async () => {
        try {
            await makeLocalEndpointRequest(`/api/deals/deleteDeal/${dealId}`, {}, 'GET');
            history.push('/deals');
        } catch (err) {
            await logError(`deals.deleteDeal`, err);
        }
    };

    const loadDocuments = async (refresh = false) => {
        try {
            setLoadingDocuments(true);

            // If we refresh the cache  // @TBD: To combine them in one api.
            if (refresh) {
                await makeLocalEndpointRequest(`/api/deals/refreshDocuments/${dealId}`, {}, 'GET');
            }

            // Get documents
            const res = await makeLocalEndpointRequest(`/api/deals/getDocuments/${dealId}`, {}, 'GET');

            // Set documents again
            setDocuments(res);

            // Stop loading
            setLoadingDocuments(false);
        } catch (err) {
            await logError(`deals.loadDocuments`, err);
            setLoadingDocuments(false);
        }
    };

    const ContextProps = {
        // Data
        data,
        setData,
        // Loading
        loading,
        setLoading,
        // Deal id
        dealId,
        // functions
        deleteDeal,
        // Documents
        documents,
        loadDocuments,
        // Loading documents?
        loadingDocuments,
        setLoadingDocuments,
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    if (loading === true) {
        return (
            <Container title="Deals" classNames="page-deals-view">
                <div className="loading-container">
                    <CircularProgress />
                </div>
            </Container>
        );
    }

    return (
        <Container title="Deals" classNames="page-deals-view">
            <Context.Provider value={ContextProps}>
                <Header />
                <div className="page-content">
                    <Details />
                    <Documents />
                </div>
            </Context.Provider>
        </Container>
    );
};

export default Component;
