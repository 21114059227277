import React, { useState } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { ComponentState } from '../../../..';

// Dependencies
import LanguageList from './map';
import { AuthStore } from '../../../../../../../utils/context/authStore';
import { trackUserInteraction } from '../../../../../../../utils/helpers';
import { useEffect } from 'react';

const Component = () => {
    const { data, ucSettings, saveDisabled, updateUcSettings } = ComponentState();
    const { account } = AuthStore();
    const [languagesAvailableInput, setLanguagesAvailableInput] = useState([]);

    useEffect(() => {
        if (ucSettings.languagesAvailable && JSON.stringify(ucSettings.languagesAvailable) !== JSON.stringify(languagesAvailableInput)) {
            setLanguagesAvailableInput([...ucSettings.languagesAvailable]);
        }
        // eslint-disable-next-line
    }, [ucSettings]);

    const notIncludedInYourPlan = async () => {
        await trackUserInteraction(`Feature not included in company plan`, {
            reason: `They tried to add more languages than their current plan allows them to`,
        });
        window.showAlert(
            `Plan limitation`,
            `Your plan allows you to have a maximum of ${account.companyPackage.usageLimits.maxLanguages} languages enabled. Get in touch with us to discuss an upgrade for your plan.`,
            'error',
            [
                {
                    text: 'Dismiss',
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Dismiss"`);
                    },
                },
                {
                    text: 'Contact Us',
                    onClick: async ({ dismissAlert }) => {
                        await trackUserInteraction(`Selected "Contact Us to Upgrade"`);
                        dismissAlert();
                        window.location.href = `mailto:support@privasee.io?subject=Upgrading current plan`;
                    },
                },
            ],
        );
    };

    const removeLanguage = (index) => {
        let newLanguages = [...languagesAvailableInput];
        newLanguages.splice(index, 1);
        setLanguagesAvailableInput(newLanguages);
    };

    useEffect(() => {
        if (JSON.stringify(languagesAvailableInput) === JSON.stringify(ucSettings.languagesAvailable)) return false; //avoiding memory leak
        if (languagesAvailableInput.length < 1) return false;

        updateUcSettings(`languagesAvailable`, languagesAvailableInput);

        // Editable languages must include the new language they added just in case
        const notInEditable = languagesAvailableInput.filter((x) => (ucSettings.editableLanguages.includes(x) ? false : true));
        if (notInEditable.length > 0) {
            updateUcSettings(`editableLanguages`, [...ucSettings.editableLanguages, ...notInEditable]);
        }
        // eslint-disable-next-line
    }, [languagesAvailableInput]);

    return (
        <React.Fragment>
            <div className="form-label">Languages</div>
            <div className="form-description">Choose which languages you want your cookie banner to be displayed in</div>
            <div className="form-content">
                <Autocomplete
                    disabled={saveDisabled || !data.cookieBannerSettings.enabled}
                    multiple
                    options={Object.keys(LanguageList)}
                    className="slim"
                    placeholder="test"
                    freeSolo={false}
                    disableClearable={true}
                    disableCloseOnSelect={true}
                    getOptionLabel={(option) => LanguageList[option]}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                key={index}
                                variant="outlined"
                                label={LanguageList[option]}
                                deleteIcon={
                                    <React.Fragment>
                                        <div data-cy={`chip-delete`} onClick={() => removeLanguage(index)}>
                                            <i className="dropdown-icon fa-solid fa-circle-xmark"></i>
                                        </div>
                                    </React.Fragment>
                                }
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    onChange={(_, value) => {
                        if (value.length < 1) return false;

                        if (
                            value.length > languagesAvailableInput.length && // they're adding new ones
                            value.length > account.companyPackage.usageLimits.maxLanguages && // checking limit
                            !window.isCypressTestEnvironment // temp bugfix
                        )
                            return notIncludedInYourPlan();

                        const newArr = [...languagesAvailableInput];

                        // If we're adding
                        if (value.length > languagesAvailableInput.length) {
                            const newAdded = value[value.length - 1];
                            newArr.push(newAdded);
                        } else {
                            // If we're removing from the array
                            const missingIndex = languagesAvailableInput.findIndex((key) => !value.includes(key));
                            newArr.splice(missingIndex, 1);
                        }

                        setLanguagesAvailableInput(newArr);
                    }}
                    value={languagesAvailableInput}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={languagesAvailableInput.length < 1 ? true : false}
                            placeholder={languagesAvailableInput.length < 1 ? 'Select at least one language' : ''}
                        />
                    )}
                />
            </div>
            {languagesAvailableInput.length > 1 && (
                <React.Fragment>
                    <div className="form-label">Default language</div>
                    <div className="form-description">What should be the default language for your cookie banner?</div>
                    <div className="form-content">
                        <Autocomplete
                            value={languagesAvailableInput[0]}
                            disabled={saveDisabled || !data.cookieBannerSettings.enabled}
                            className="slim"
                            getOptionLabel={(option) => LanguageList[option]}
                            onChange={(_, value) => {
                                const key = value;

                                const newLanguages = [...languagesAvailableInput];

                                const index = newLanguages.findIndex((c) => c === key);

                                if (index === -1) return false;

                                newLanguages.splice(index, 1);
                                newLanguages.splice(0, 0, key);

                                setLanguagesAvailableInput(newLanguages);
                            }}
                            options={Object.keys(LanguageList).filter((elm) => languagesAvailableInput.includes(elm))}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Component;
