import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import lodash from 'lodash';

// Components
import Avatar from './components/Avatar';

// Pages
import General from './pages/general';
import DataOverview from './pages/dataOverview/index';
import Advanced from './pages/advanced';
import SubProcessor from './pages/subProcessor';

import { trackUserInteraction } from '../../../../../../../utils/helpers';

// Context
import { ModuleAPI } from '../../../../components/api';
import { ModuleState } from '../../../..';

const Component = (props) => {
    const { data, updateData, vitalRecommendations, flags } = ModuleState();
    const { refreshCheckedData } = ModuleAPI();
    const [closing, setClosing] = useState(false);
    const [storageData, setStorageData] = useState(null);
    const [page, setPage] = useState('General');
    const [matrixProcess, setMatrixProcess] = useState(null);

    const onClose = async () => {
        // if there's process with not enoug hindividuals or elements => warn them

        let missingData = null;

        const elements = storageData.elements;

        if (missingData !== null) return;
        if (elements.length < 1) {
            missingData = 1;
        } else if (storageData.processes.length < 1) {
            missingData = 2;
        } else if (storageData.individuals.length < 1) {
            missingData = 3;
        }

        if (missingData !== null) {
            let message = null;
            if (missingData === 1) {
                message = `You haven't told us what type of data you hold on ${storageData.label}`;
            } else if (missingData === 2) {
                message = `You haven't told us what you use ${storageData.label} for`;
            } else if (missingData === 3) {
                message = `You haven't told us who the data from ${storageData.label} is from`;
            }

            await trackUserInteraction(`Received warning popup`, {
                reason: `Storage location missing data`,
                value: missingData,
                locationLabel: storageData.label,
            });

            window.showAlert('Confirmation', message, 'warning', [
                {
                    text: 'Skip For Now',
                    dataCy: `alert-skip-button`,
                    onClick: async ({ dismissAlert }) => {
                        closeModal(true);
                        dismissAlert();
                        await trackUserInteraction(`Selected "Skip For Now"`);
                    },
                },
                {
                    text: 'Fix Now',
                    dataCy: `alert-fix-button`,
                    onClick: async ({ dismissAlert }) => {
                        setPage('General');
                        dismissAlert();
                        await trackUserInteraction(`Selected "Fix Now"`);
                    },
                },
            ]);
            return false;
        }

        let missingMatrix = null;

        storageData.processes.forEach((proc) => {
            if (missingMatrix !== null) return;
            const hasMatrixMapping = storageData.matrixMap.find((map) => map.process === proc.label);
            if (!hasMatrixMapping) {
                missingMatrix = proc.label;
            }
        });

        if (missingMatrix !== null) {
            await trackUserInteraction(`Received warning popup`, {
                reason: `Storage location missing data matrix`,
                process: missingMatrix,
            });
            window.showAlert('Confirmation', `You haven't told us the data you use for Process ${missingMatrix}`, 'warning', [
                {
                    text: 'Skip For Now',
                    dataCy: `alert-skip-button`,
                    onClick: async ({ dismissAlert }) => {
                        closeModal(true);
                        dismissAlert();
                        await trackUserInteraction(`Selected "Skip For Now"`);
                    },
                },
                {
                    text: 'Fix Now',
                    dataCy: `alert-fix-button`,
                    onClick: async ({ dismissAlert }) => {
                        setMatrixProcess(missingMatrix);
                        setPage('Advanced');
                        dismissAlert();
                        await trackUserInteraction(`Selected "Fix Now"`);
                    },
                },
            ]);
            return false;
        }

        let missingMatrixSensitive = null;

        storageData.elements
            .filter((elm) => elm.sensitive === true)
            .forEach((elm) => {
                if (missingMatrixSensitive !== null) return false;
                const hasMatrixMapping = storageData.matrixMap.find((map) => map.element === elm.label);
                if (!hasMatrixMapping) {
                    missingMatrixSensitive = elm.label;
                }
            });

        if (missingMatrixSensitive !== null) {
            await trackUserInteraction(`Received warning popup`, {
                reason: `Storage location missing sensitive data matrix`,
                element: missingMatrixSensitive,
            });
            window.showAlert(
                'Confirmation',
                `You haven't told us in what process you use the ${missingMatrixSensitive} sensitive data, and what for`,
                'warning',
                [
                    {
                        text: 'Skip For Now',
                        dataCy: `alert-skip-button`,
                        onClick: async ({ dismissAlert }) => {
                            closeModal(true);
                            dismissAlert();
                            await trackUserInteraction(`Selected "Skip For Now"`);
                        },
                    },
                    {
                        text: 'Fix Now',
                        dataCy: `alert-fix-button`,
                        onClick: async ({ dismissAlert }) => {
                            setMatrixProcess(storageData.processes[0].label);
                            setPage('Advanced');
                            dismissAlert();
                            await trackUserInteraction(`Selected "Fix Now"`);
                        },
                    },
                ],
            );
            return false;
        }

        closeModal();
        cleanupElementsChecked();
    };

    const storageHasElement = (storage, elementLabel) => {
        return storage.elements.find((elm) => elm.label === elementLabel) ? true : false;
    };

    const cleanupElementsChecked = () => {
        const freshChecked = refreshCheckedData();
        const elms = [];

        vitalRecommendations.elements.map((element) => {
            elms.push({
                label: element.label,
                sensitive: false,
                description: element.description,
            });
        });

        let checkedElements = freshChecked.elements.filter((checkedElement) => {
            if (elms.find((e) => e.label === checkedElement.label)) return true;
            if (data.storageLocations.filter((stor) => storageHasElement(stor, checkedElement.label)).length < 1) {
                return false;
            }
            return true;
        });

        updateData('checkedData', { ...freshChecked, elements: checkedElements });
    };

    const closeModal = async (skipForNow = false) => {
        setClosing(true);
        if (skipForNow === false) {
            await trackUserInteraction(`Selected "Close Edit"`);
        }
        setTimeout(() => {
            setClosing(false);
            props.setEditStorage({ index: null });
            setPage('General');
            setMatrixProcess(null);

            // Dispatch an event that tells us the process of editing a storage has finished.
            document.dispatchEvent(new CustomEvent(`reviewData:editing`, { detail: null }));
        }, 550);
    };

    const onEditStorageChanged = () => {
        setStorageData(props.editStorage.index === null ? null : data.storageLocations[props.editStorage.index]);
        setPage('General');
        if (props.editStorage.index !== null) {
            trackUserInteraction('Selected "Edit Tool" ', {
                label: data.storageLocations[props.editStorage.index].label,
            });
        }
    };

    const onStorageDataUpdated = () => {
        if (storageData === null || props.editStorage.index === null) return false;
        const updatedArr = [...data.storageLocations];
        updatedArr[props.editStorage.index] = storageData;
        updateData('storageLocations', updatedArr);
    };

    const getCleanedUpMatrix = (lastObject = undefined) => {
        const processes = lastObject.processes;
        const elements = lastObject.elements;
        const individuals = lastObject.individuals;

        const newMatrix = lastObject.matrixMap.filter((map) => {
            if (
                !processes.find((p) => p.label === map.process) ||
                !elements.find((e) => e.label === map.element) ||
                !individuals.find((e) => e.label === map.individual)
            )
                return false;
            else return true;
        });

        return newMatrix;
    };

    const updateStorageData = async (path, value, cleanupMatrix = false) => {
        setStorageData((currentState) => {
            let manipulatedObj = { ...currentState };
            lodash.set(manipulatedObj, path, value);
            if (cleanupMatrix === true) {
                const cleanMatrix = getCleanedUpMatrix(manipulatedObj);
                lodash.set(manipulatedObj, `matrixMap`, cleanMatrix);
            }
            return manipulatedObj;
        });
    };

    useEffect(() => {
        onEditStorageChanged();
        // eslint-disable-next-line
    }, [props.editStorage]);

    useEffect(() => {
        onStorageDataUpdated();
        // eslint-disable-next-line
    }, [storageData]);

    const onProcessAdded = (process) => {
        setStorageData((lastState) => {
            const newState = { ...lastState };

            const recommendation = vitalRecommendations.processes.find((p) => p.label === process.label);
            if (recommendation) {
                // Adding elements
                recommendation.elementCategories
                    .filter((l) => l.trim().length > 0)
                    .forEach((element) => {
                        if (newState.elements.find((elm) => elm.label === element)) return;
                        const src = vitalRecommendations.elements.find((elm) => elm.label === element) || {};
                        newState.elements.push({
                            label: src.label || element,
                            description: src.description || '',
                            sensitive: false,
                        });
                    });

                // Adding sensitive elements
                recommendation.specialCategoryElements
                    .filter((st) => st.trim().length > 0)
                    .forEach((element) => {
                        if (newState.elements.find((elm) => elm.label === element)) return;
                        const src = vitalRecommendations.sensitiveElements.find((elm) => elm.label === element) || {};
                        newState.elements.push({
                            label: src.label || element,
                            description: '',
                            sensitive: true,
                        });
                    });

                // Adding individuals
                recommendation.subjectCategories
                    .filter((l) => l.trim().length > 0)
                    .forEach((indiv) => {
                        if (newState.individuals.find((x) => x.label === indiv)) return;
                        const src = vitalRecommendations.individuals.find((x) => x.label === indiv) || {};

                        newState.individuals.push({
                            label: src.label || indiv,
                            category: src.category || '',
                        });
                    });

                // Filling up the matrix table
                newState.elements.forEach((elmIterated) => {
                    if (
                        recommendation.elementCategories.includes(elmIterated.label) ||
                        recommendation.specialCategoryElements.includes(elmIterated.label)
                    ) {
                        newState.individuals.forEach((indivIterated) => {
                            if (!recommendation.subjectCategories.includes(indivIterated.label)) return false;
                            const newMatrixEntry = {
                                process: process.label,
                                element: elmIterated.label,
                                individual: indivIterated.label,
                            };
                            if (!newState.matrixMap.find((obj) => JSON.stringify(obj) === JSON.stringify(newMatrixEntry))) {
                                newState.matrixMap.push(newMatrixEntry);
                            }
                        });
                    }
                });
            }
            return newState;
        });
    };

    const onDelete = async () => {
        await trackUserInteraction(`Selected "Delete tool"`, { label: storageData.label });
        window.showAlert('Confirmation', `Are you sure you want to delete ${storageData.label}?`, 'warning', [
            {
                text: 'Cancel',
                dataCy: `alert-cancel-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                    await trackUserInteraction(`Selected "Cancel"`);
                },
            },
            {
                text: 'Confirm',
                dataCy: `alert-submit-button`,

                onClick: async ({ dismissAlert }) => {
                    const updatedArr = [...data.storageLocations];
                    updatedArr.splice(props.editStorage.index, 1);
                    updateData('storageLocations', updatedArr);
                    closeModal(true);
                    dismissAlert();
                    await trackUserInteraction(`Selected "Confirm"`);
                },
            },
        ]);
    };

    const switchAchiveState = async (archiving = false) => {
        await trackUserInteraction(`Selected "${archiving ? `Archive` : `Unarchive`} tool"`, { label: storageData.label });
        window.showAlert(
            'Confirmation',
            archiving === false
                ? `Are you sure you want to unarchive ${storageData.label}?\nThis tool or third party will show up in your inventory and policies.`
                : `Are you sure you want to archive ${storageData.label}?\nThis tool or third party won't show up on your inventory or policies. This can be reversed later.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Cancel"`);
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,

                    onClick: async ({ dismissAlert }) => {
                        const updatedArr = [...data.storageLocations];
                        updatedArr[props.editStorage.index].archived = archiving;
                        updateData('storageLocations', updatedArr);
                        closeModal(true);
                        dismissAlert();
                        await trackUserInteraction(`Selected "Confirm"`);
                    },
                },
            ],
        );
    };

    const mappedPages = {
        General,
        DataOverview,
        Advanced,
        subProcessor: SubProcessor,
    };

    const PageComponent = mappedPages[page];

    const mappedProps = {
        setPage,
        page,
        storageData,
        storageIndex: props.editStorage.index,
        setStorageData,
        updateStorageData,
        matrixProcess,
        onProcessAdded,
        setMatrixProcess,
        onDelete,
        switchAchiveState,
    };

    const dataMatrixIsDisabled = () => {
        const elements = storageData.elements;
        if (storageData.processes.length > 0 && elements.length > 0 && storageData.individuals.length > 0) return false;
        else return true;
    };

    const onTabChanged = async (value) => {
        setPage(value);

        // If tab is Advanced and matrix process is null or no longer existing.
        if (
            value === 'Advanced' &&
            (matrixProcess === null || (matrixProcess && !storageData.processes.find((x) => x.label === matrixProcess)))
        ) {
            setMatrixProcess(storageData.processes[0].label);
        }

        if (value === 'Advanced') {
            const faultyProcess = storageData.processes.find((p) => {
                const hasMatrixMap = storageData.matrixMap.filter((matrix) => matrix.process === p.label).length < 1;
                return hasMatrixMap;
            });

            if (faultyProcess) {
                setMatrixProcess(faultyProcess.label);
            }
        }

        await trackUserInteraction(`Selected "Tab"`, { value });
    };

    if (props.editStorage.index === null || storageData === null) return null;

    const getTabs = () => {
        const arr = [
            {
                label: `General`,
                value: `General`,
            },
            {
                label: `Data Overview`,
                value: `DataOverview`,
            },
            {
                label: `Advanced`,
                value: `Advanced`,
                disabled: dataMatrixIsDisabled(),
            },
        ];

        if (flags.missingSubProcessors) {
            arr.push({
                label: `Sub-Processor`,
                value: `subProcessor`,
            });
        }

        return arr;
    };
    const isTabValid = (tab) => {
        if (tab === 'General' && storageData.dataResidency.length < 1) {
            return false;
        } else if (tab === 'General' && storageData.securityMeasures.length < 1) {
            return false;
        } else if (
            tab === 'DataOverview' &&
            (storageData.elements.length < 1 || storageData.individuals.length < 1 || storageData.processes.length < 1)
        ) {
            return false;
        } else if (
            tab === 'Advanced' &&
            dataMatrixIsDisabled() === false &&
            storageData.processes.filter((process) => {
                const existsMap = storageData.matrixMap.find((mp) => mp.process === process.label);
                if (existsMap) return false;
                return true;
            }).length > 0
        ) {
            return false;
        } else if (
            tab === 'Advanced' &&
            dataMatrixIsDisabled() === false &&
            storageData.elements.filter((elm) => {
                const existsMap = storageData.matrixMap.find((mp) => mp.element === elm.label);
                if (existsMap) return false;
                return true;
            }).length > 0
        ) {
            return false;
        } else if (
            tab === 'Advanced' &&
            dataMatrixIsDisabled() === false &&
            storageData.individuals.filter((indiv) => {
                const existsMap = storageData.matrixMap.find((mp) => mp.individual === indiv.label);
                if (existsMap) return false;
                return true;
            }).length > 0
        ) {
            return false;
        }
        return true;
    };

    return (
        <React.Fragment>
            <div data-cy="module-popout" className={`module-popout ${closing && `closing`}`}>
                <div className="module-content">
                    <div className="module-close" onClick={onClose} data-cy="panel-close">
                        <i className="icon fa-solid fa-xmark"></i>
                    </div>
                    <div className="module-title">Edit tool or third party</div>
                    <div className="module-subtitle">
                        <Avatar image={storageData.image} label={storageData.label} />
                        {storageData.label}
                    </div>
                    <Tabs value={page} onChange={(_, newValue) => onTabChanged(newValue)}>
                        {getTabs().map((entry, index) => (
                            <Tab
                                value={entry.value}
                                key={index}
                                label={
                                    <React.Fragment>
                                        <div className="tab-content">
                                            <span className="text">{entry.label}</span>
                                            {isTabValid(entry.value) === false && <div className="badge badge-data-warning blue" />}
                                        </div>
                                    </React.Fragment>
                                }
                                disabled={entry.disabled ? true : false}
                            />
                        ))}
                    </Tabs>
                    <PageComponent {...mappedProps} />
                </div>
            </div>
            <div className="module-shadow-underlay" />
        </React.Fragment>
    );
};

export default Component;
