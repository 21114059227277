import React from 'react';
import { PanelContext } from '../../..';

const Component = () => {
    const { recommendations } = PanelContext();

    const getLinks = () => {
        const rec = recommendations ? recommendations.externalLinks : null;

        if (!rec) return [];

        let arr = [];

        if (rec.securityMeasures) {
            arr.push({
                label: `Security Page`,
                value: rec.securityMeasures,
            });
        }

        if (rec.privacyPolicy) {
            arr.push({
                label: `Privacy Policy`,
                value: rec.privacyPolicy,
            });
        }

        if (rec.DPA) {
            arr.push({
                label: `Data Processing Agreements`,
                value: rec.DPA,
            });
        }

        return arr;
    };

    // Get the links.
    if (getLinks().length < 1) return null;

    return (
        <React.Fragment>
            <hr style={{ marginTop: 24, marginBottom: 24, borderColor: 'rgba(255,255,255,0.07)' }} />
            <div className="form-group" data-cy="section-externalLinks">
                <div className="component-label">
                    <div className="text" style={{ fontWeight: 500 }}>
                        External Links
                    </div>
                </div>
                <div className="component-content">
                    {getLinks().map((entry, ix) => (
                        <div key={ix} style={{ marginBottom: 8, fontSize: 14 }}>
                            <a href={entry.value} target="_blank">
                                {' '}
                                {entry.label}
                                <i style={{ marginLeft: 8 }} className="fa-solid fa-arrow-up-right-from-square"></i>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
