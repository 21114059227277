import { useEffect } from 'react';

// Dependencies
import { getFromUrlQueryString, logError, removeFromUrlQueryString, setInUrlQueryString } from '../../../../utils/helpers';
import { validateViewRoute } from '../views';

// Context
import { OnboardingFunctions } from './functions';
import { OnboardingContext } from '..';

const Component = () => {
    const { loadData, loadVitalRecommendations, loadRecommendedTools, loadFlags } = OnboardingFunctions();
    const { viewId, showArchivedTools } = OnboardingContext();

    /**
     * Clears the URL parameters if the URL contains an invalid view id.
     * If no view id is found, it sets it to 'welcome'.
     * If an invalid view id is found, it replaces it with 'welcome'.
     */

    const clearUrlParams = () => {
        try {
            // Extract pathName parts.
            const pathnameParts = window.location.pathname.split('/');

            // Extract the view
            let viewExtracted = pathnameParts[2];

            // If there is no view id passed we'll set it to welcome.
            if (!viewExtracted) {
                // Add param to URL by replacing it.
                window.history.replaceState(null, null, `/onboarding/welcome`);

                return true;
            }

            // Check if the path extracted is valid
            if (validateViewRoute(viewExtracted)) return false; // Is valid.

            // Update URL by replacing it.
            window.history.replaceState(null, null, `/onboarding/welcome`);
        } catch (err) {
            logError(`onboarding.clearUrlParam`, err, { pathname: window.location.pathname });
        }
    };

    const updateUrlParams = () => {
        try {
            // Extract pathName parts.
            const pathnameParts = window.location.pathname.split('/');

            // Extract the view
            let viewExtracted = pathnameParts[2];

            if (!viewExtracted) return false;

            // Nothing to update.
            if (viewExtracted === viewId) return false;

            // Update URL by replacing it.
            window.history.pushState(null, null, `/onboarding/${viewId}`);
        } catch (err) {
            logError(`onboarding.updateUrlParams`, err);
        }
    };

    useEffect(() => {
        // Load the data for this system
        loadData();

        // Clear the url params.
        clearUrlParams();

        // Load recommendations
        loadVitalRecommendations();
        loadRecommendedTools();

        // Load flags
        loadFlags();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // If we set it to true..
        if (showArchivedTools) {
            setInUrlQueryString('showArchived', true);
        }

        // We turn it off but we have that in param.
        if (!showArchivedTools && getFromUrlQueryString('showArchived')) {
            removeFromUrlQueryString('showArchived');
        }
    }, [showArchivedTools]);

    useEffect(() => {
        // Update the url params whenever the view id updates.
        updateUrlParams();

        // eslint-disable-next-line
    }, [viewId]);

    return null;
};

export default Component;
