import React from 'react';

// Components
import { ModuleState } from '../..';
import { Button } from '@mui/material';
import AssignBulk from './components/assignBulk';

// Controls
import SearchAPI from '../../../../components/dataControls/search';
import FiltersAPI from '../../../../components/dataControls/filters';
import { getFilterFields } from '../../utils/functions';
import { trackUserInteraction } from '../../../../../utils/helpers';

const Component = () => {
    const {
        data,
        dataControls,
        setDataControls,
        selectedEntities,
        deleteEntities,
        setSelectedEntities,
        submitted,
        createEntry,
        settingsMeta,
        setEditingId,
        editingId,
    } = ModuleState();

    const onClickCreate = async () => {
        trackUserInteraction(`Selected "Add New"`);
        const entry = await createEntry();
        setEditingId(entry._id);
    };

    const onBulkDelete = async () => {
        window.showAlert('Confirmation', `Are you sure you want to delete ${selectedEntities.length} question(s)?`, 'warning', [
            {
                text: 'Cancel',
                dataCy: `alert-cancel-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },
            {
                text: 'Confirm',
                dataCy: `alert-submit-button`,
                onClick: async ({ dismissAlert }) => {
                    dismissAlert();

                    deleteEntities(selectedEntities);

                    setSelectedEntities([]);
                },
            },
        ]);
    };

    const selectAll = () => {
        trackUserInteraction(`Selected "Select All"`);
        setSelectedEntities(selectedEntities.length > 0 ? [] : data.map((c) => c._id));
    };

    const filterFields = getFilterFields({ settingsMeta, data });

    // Definitions
    const searchButtonId = 'search-btn-trigger';
    const filterButtonId = 'filter-btn-trigger';

    return (
        <React.Fragment>
            <div className="page-controls">
                <div className="left-side">
                    <Button id={searchButtonId} variant={`${dataControls.search.length > 0 ? 'contained' : 'outlined'}`} color="primary">
                        Search
                    </Button>
                    <Button
                        id={filterButtonId}
                        variant={`${Object.keys(dataControls.filters).length > 0 ? 'contained' : 'outlined'}`}
                        color="primary"
                    >
                        Filter
                    </Button>
                    <Button
                        variant={`${selectedEntities.length > 0 ? 'contained' : 'outlined'}`}
                        disabled={editingId !== null}
                        color="primary"
                        onClick={selectAll}
                    >
                        Select All
                    </Button>
                    {selectedEntities.length > 0 && (
                        <React.Fragment>
                            <Button variant="contained" color="primary" disabled={submitted || editingId !== null} onClick={onBulkDelete}>
                                Delete
                            </Button>
                            <AssignBulk />
                        </React.Fragment>
                    )}
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" disabled={submitted} onClick={onClickCreate}>
                        New
                    </Button>
                </div>
                <SearchAPI
                    onChanges={(value) => setDataControls({ ...dataControls, search: value })}
                    buttonId={searchButtonId}
                    heading={'Search by Question or Answer'}
                    inputPlaceholder={'Text...'}
                />
                <FiltersAPI
                    onChanges={(value) => setDataControls({ ...dataControls, filters: value })}
                    buttonId={filterButtonId}
                    fields={filterFields}
                    urlSupport={true}
                />
            </div>
        </React.Fragment>
    );
};

export default Component;
