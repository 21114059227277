import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { trackUserInteraction, useWindowSize } from '../../../../utils/helpers';
import { AuthStore } from '../../../../utils/context/authStore';

const Menu = (props) => {
    const [width] = useWindowSize();
    const { account } = AuthStore();

    const onTabChanged = async (newValue) => {
        props.onChange(newValue);
        await trackUserInteraction(`Changed Tab`, { newValue });
    };

    const getEntries = () => {
        const arr = [
            {
                label: `Company Details`,
                value: `company`,
            },
            {
                label: `Scanners & Notifications`,
                value: `scanners`,
            },
            {
                label: `Billing`,
                value: `billing`,
                disabled: !account.stripeCustomerId,
            },
            {
                label: `Usage limits`,
                value: `usage-limits`,
            },
        ];
        return arr;
    };

    return (
        <div className="menu">
            <Tabs value={props.value} variant={width < 1100 ? `scrollable` : `standard`} onChange={(_, newValue) => onTabChanged(newValue)}>
                {getEntries().map((entry) => (
                    <Tab
                        disabled={!props.data || props.loading || entry.disabled}
                        // icon={<i className={`icon ${entry.icon}`}></i>}
                        label={entry.label}
                        value={entry.value}
                        key={entry.value}
                    />
                ))}
            </Tabs>
        </div>
    );
};

export default Menu;
