/**
 * This function will format the data from data matrice to the tools format required for our processor module.
 * @param {*} dataInventory - Your data inventory
 * @param {*} _individualId
 */

import { logError } from '../../../../utils/helpers';

export const convertMatriceEntriesToProcesses = (dataInventory, _individualId) => {
    try {
        // Formatting the list of processes
        let processes = [];

        // Go through the processes
        dataInventory.processes.forEach((process) => {
            // Not linked
            const matriceMatch = dataInventory.matrices.find((c) =>
                c.entries.find((d) => d._processId === process._id && d._individualId === _individualId),
            );
            if (!matriceMatch) return;

            processes.push({
                _processId: process._id,
                storageLocations: [],
            });
        });

        // Setting the storage locations
        processes.forEach((process, index) => {
            // We first will populate the storage locations field.
            dataInventory.storageLocations.forEach((storageLocation) => {
                // This tool must be associated with the process and the individual id.
                if (!storageLocation.processes.includes(process._processId)) return;
                if (!storageLocation.individuals.includes(_individualId)) return;

                const matrice = dataInventory.matrices.find((c) => c._storageLocationId === storageLocation._id);
                let elements = [];

                // If there's a matrice means we can map the elements
                if (matrice) {
                    elements = matrice.entries
                        .filter((c) => c._processId === process._processId && c._individualId === _individualId)
                        .map((c) => c._elementId);
                }

                processes[index].storageLocations.push({
                    _id: storageLocation._id, // to avoid confusion we'll call it _id not _stoargeLocationId (since that's an actual field)
                    elements,
                });
            });
        });

        return processes;
    } catch (err) {
        logError(`module.individuals.utils.convertMatriceEntriesToProcesses`, err, { matrices });
        return [];
    }
};

/**
 * This is just for the front-end so we can display the labels etc.
 * @param {*} processes - the return from convertMatriceEntriesToProcesses function.
 * @param {*} dataInventory - the whole data inventory.
 * @returns
 */

export const mapProcessesEntriesToDataInventory = (processes, dataInventory) => {
    try {
        return processes
            .map((c) => {
                // get the tool mentioned
                const processMentioned = dataInventory.processes.find((b) => b._id === c._processId);
                if (!processMentioned) return null;

                return {
                    storageLocations: c.storageLocations
                        .map((i) => {
                            // get the tool mentioned
                            const storageLocation = dataInventory.storageLocations.find((b) => b._id === i._id);
                            if (!storageLocation) return null;

                            return {
                                ...i,
                                elements: i.elements
                                    .map((_id) => {
                                        // get the tool mentioned
                                        const element = dataInventory.elements.find((c) => c._id === _id);
                                        if (!element) return null;

                                        return {
                                            _id,
                                            data: element,
                                        };
                                    })
                                    .filter((c) => c !== null),
                                data: storageLocation,
                            };
                        })
                        .filter((c) => c !== null),
                    data: processMentioned,
                };
            })
            .filter((c) => c !== null);
    } catch (err) {
        logError(`module.individuals.utils.mapProcessesEntriesToDataInventory`, err);
        return [];
    }
};
