import React, { useEffect, useState } from 'react';

import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/material';

const Component = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    // Definitions
    const containerTargetId = props.containerTargetId;

    const closePopup = () => {
        props.setOpen(false);
        setAnchorEl(null);
    };

    const onElementsClicked = (ev) => {
        // eslint-disable-next-line
        const buttonTarget = document.getElementById(props.buttonId);

        // If they click the trigger and the menu is not opened.
        if (ev.target === buttonTarget && !props.openRef.current) {
            setAnchorEl(ev.target);
            props.setOpen(true);
            return false;
        }

        // If they click on the trigger to close it.
        if (ev.target === buttonTarget && props.openRef.current) return closePopup();
    };

    const onClickAway = () => {
        // Check there's no dropdown opened.
        const modalExists = document.getElementsByClassName('MuiPaper-root')[0];
        const dropdown = document.getElementsByClassName('MuiList-root')[0];

        if (modalExists && modalExists.contains(dropdown)) return false;

        closePopup();
    };
    useEffect(() => {
        document.addEventListener('click', onElementsClicked);
        return () => {
            document.removeEventListener('click', onElementsClicked);
        };
        // eslint-disable-next-line
    }, []);

    if (props.open === false) return null;

    return (
        <React.Fragment>
            <Popper open={props.open} anchorEl={anchorEl} placement="bottom-start" className={`component-data-controls ${props.className}`}>
                <ClickAwayListener onClickAway={onClickAway}>
                    <div id={containerTargetId} className="contents">
                        {props.children}
                    </div>
                </ClickAwayListener>
            </Popper>
        </React.Fragment>
    );
};

export default Component;
