import React from 'react';
import { Button } from '@mui/material';
import { trackUserInteraction } from '../../../../../utils/helpers';
import { ModuleState } from '../../index';

export const entries = [
    {
        label: `B2B`,
        value: `b2b`,
        description: `Startups, businesses/companies or corporates`,
        icon: `buildings`,
    },
    {
        label: `B2C`,
        value: `b2c`,
        description: `Individuals, sole-traders or unincorporated partnerships`,
        icon: `user-tie`,
    },
];

const StepAudience = () => {
    const { data, updateData, setStep, isEditingSession } = ModuleState();

    const nextStep = async () => {
        await trackUserInteraction(`Selected "Next"`, { destination: `Industry` });
        let lastBiggestStep = data._meta ? data._meta.biggestStep : 'Welcome';
        setStep(`Industry`);

        if (lastBiggestStep === 'Audience' && isEditingSession === false) {
            window.showAlert(
                'For your peace of mind',
                `You don't need to have all the information right now. If you are not sure about something you can always add more information or change your answers later.`,
                'info',
                [
                    {
                        text: 'Good to know',
                        dataCy: `alert-confirm-button`,
                        onClick: async ({ dismissAlert }) => {
                            setStep(`Industry`);
                            dismissAlert();
                        },
                    },
                ],
            );
        }
    };

    const goBack = async () => {
        setStep('Welcome');
        await trackUserInteraction(`Selected "Go Back"`, { destination: `Welcome` });
    };

    const audienceSelected = async (entry) => {
        const arrs = data.companyInfo.targetAudience || [];
        if (arrs.includes(entry.value)) {
            arrs.splice(arrs.indexOf(entry.value), 1);
        } else {
            arrs.push(entry.value);
        }
        updateData(`targetAudience`, arrs);
        await trackUserInteraction(`Selected "Audience"`, { targetAudience: arrs });
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    {/* <div className="back-button">Go back</div> */}
                    <div className="back-button" data-cy="back-button" onClick={goBack}>
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Choose who your target audience is
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={data.companyInfo && data.companyInfo.targetAudience && data.companyInfo.targetAudience.length < 1}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
            <div className="target-audience module-content">
                <div className="entries d-flex-row-columns centered">
                    {entries.map((entry, index) => (
                        <React.Fragment key={index}>
                            <div
                                data-cy={`entry-${entry.value}`}
                                onClick={() => audienceSelected(entry)}
                                className={data.companyInfo.targetAudience.includes(entry.value) ? `entry selected` : `entry`}
                            >
                                <div className="check-container">
                                    <i className="check-icon fa-solid fa-check"></i>
                                </div>
                                <i className={`icon fa-solid fa-${entry.icon}`}></i>
                                <div className="label">{entry.label}</div>
                                <div className="description">{entry.description}</div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default StepAudience;
