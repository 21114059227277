import React from 'react';
import { ViewContext } from '..';
import { OnboardingContext } from '../../..';
import { TextField } from '@mui/material';

const Component = () => {
    const { companyDescription, setCompanyDescription } = ViewContext();
    const { tasksPending } = OnboardingContext();

    return (
        <React.Fragment>
            <TextField
                multiline={true}
                rows={6}
                value={companyDescription}
                onChange={(ev) => setCompanyDescription(ev.target.value)}
                fullWidth={true}
                disabled={tasksPending.generatingDescription}
                placeholder="Describe your company here.."
            />
        </React.Fragment>
    );
};

export default Component;
