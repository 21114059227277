import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

// Components
import Panel from './components/panel';

// Dependencies
import { logError } from '../../../../../../../utils/helpers';
import { makeLocalEndpointRequest } from '../../../../../../../utils/endpoints';
import { OnboardingFunctions } from '../../../../components/functions';
import { formatCreatedToolName } from '../../../../../thirdParties/components/storageLocations/utils/functions';
import { OnboardingContext } from '../../../..';

// Context
const Context = createContext({});
export const PickContext = () => useContext(Context);

const Component = (props) => {
    const [pickTools, setPickTools] = useState(false);
    const { dataInventory } = OnboardingContext();
    const { refreshDataInventory } = OnboardingFunctions();

    // This will be used so we can set the panel's view and more from outside it.
    const panelController = useRef(null);

    const onPanelClose = async (instant = false) => {
        try {
            // If we close the panel in an instant
            if (instant) {
                setPickTools(false);
                return false;
            }

            // Close the panel
            panelController.current.modal.current.close(() => {
                setPickTools(false);
            });
        } catch (err) {
            await logError(`onboarding.createTools.onPanelClose`, err);
        }
    };

    const onToolsPicked = async (pickedToolsLabels) => {
        try {
            // Iterate and create
            for (const [index, label] of pickedToolsLabels.entries()) {
                // Check if the current label is the last one
                const isLastLabel = index === pickedToolsLabels.length - 1 ? true : false;

                // Make the API Request
                await makeLocalEndpointRequest(`/api/v1/storageLocations/pickToolFromRecommendations`, {
                    label,
                    options: {
                        syncToSession: isLastLabel, // Set syncToSession to true only for the last label
                        updateCookieBanner: isLastLabel,
                    },
                });
            }

            // Refresh the data inventory
            await refreshDataInventory();
        } catch (err) {
            await logError(`onboarding.createTools.onToolsPicked`, err);
        }
    };

    const onCustomToolCreated = async (customTool) => {
        try {
            // Format the label accordingly.
            let label = formatCreatedToolName(customTool.label, dataInventory.storageLocations);

            // Make the API Request
            await makeLocalEndpointRequest(`/api/v1/storageLocations/createCustomTool`, {
                label,
                similarTo: customTool.similarData ? customTool.similarData.label : null,
                options: {
                    syncToSession: true,
                },
            });

            // Refresh the data inventory
            await refreshDataInventory();
        } catch (err) {
            await logError(`onboarding.createTools.onCustomToolCreate`, err);
        }
    };

    const onEventPickToolStart = (ev) => {
        ev.preventDefault();
        setPickTools(true);
    };
    useEffect(() => {
        document.addEventListener('toolPicker.open', onEventPickToolStart);

        return () => {
            document.removeEventListener('toolPicker.open', onEventPickToolStart);
        };
    }, []);

    const PassedProps = {
        pickTools,
        setPickTools,
        // Refs
        panelController,
        // Callbacks
        onPanelClose,
        onToolsPicked,
        onCustomToolCreated,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Panel />
            {props.renderCallToAction({ setPickTools, pickTools })}
        </Context.Provider>
    );
};
export default Component;
