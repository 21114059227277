import React, { useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import { trackUserInteraction } from '../../../../../../utils/helpers';

import ucSupportedTools from '../dataset/ucSupportedTools';
import { ComponentState } from '../../..';
import { useState } from 'react';

import CodeBuilder from './generateCode';

const Component = () => {
    const { data } = ComponentState();
    const [codeCopied, setCodeCopied] = useState(false);
    const [toolsAffected, setToolsAffected] = useState([]);

    const getCode = () => {
        if (!data.cookieBannerSettings.enabled) return `Feature is not enabled`;

        const apiDomain = window.location.href.includes('localhost:3000') ? `http://localhost:5000` : window.location.origin;

        let result = `<script id="privasee-cb" src="${apiDomain}/cdn/${data._id}/cookie-banner.js"></script>`;

        // If they use SCB.

        if (data.smartCookieBlocker) {
            result += `\n<link rel="preload" href="//privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js" as="script" />`;
            result += `\n<script type="application/javascript" id="uc-block" src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"></script>`;
        }

        return result;
    };

    const copyCode = async () => {
        await trackUserInteraction(`Selected "Copy code"`, {
            type: `consentManagement`,
        });
        navigator.clipboard.writeText(getCode());
        setCodeCopied(true);
        setTimeout(() => setCodeCopied(false), 2500);
    };

    const getToolsAffected = () => {
        // Variables
        const usingSmartCookieBlocker = data.smartCookieBlocker ? true : false;
        const cookies = data.thirdPartyCookies.entries.filter((c) => c.type !== 'Necessary');

        // If they do not use smart cookie blocker..
        if (usingSmartCookieBlocker === false) {
            setToolsAffected(cookies.map((c) => c.name));
            return true;
        }

        // If they do use smart cookie blocker..
        if (usingSmartCookieBlocker === true) {
            // Format the array of non-supported by smart cookie blocker.
            let arr = cookies.filter((c) => {
                const supported = ucSupportedTools.find((x) => c.name.includes(x));

                if (!supported) {
                    return true;
                }

                return false;
            });

            setToolsAffected(arr.map((c) => c.name)); // All tools.
            return true;
        }
    };

    useEffect(() => {
        getToolsAffected();
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     // eslint-disable-next-line
    //     console.log(toolsAffected);
    // }, [toolsAffected]);

    if (!data.cookieBannerSettings.enabled) return null;

    return (
        <React.Fragment>
            <div className="step-entry">
                <div className="step-header">
                    <div className="step-badge">Step 1</div>
                    <div className="step-label">Add the cookie banner script to your website</div>
                </div>
                <div className="step-content">
                    <div className="step-two-rows">
                        <div className="parent-container-left" style={{ flex: 1 }}>
                            <div className="step-text">
                                Add the code below inside the <b>head</b> tag on your site. Add it as high as possible as it needs to load
                                before other scripts. Once you add it, your cookie banner will appear.
                            </div>

                            <div className={`multiline-code ${!data.smartCookieBlocker && 'centered'}`}>
                                <TextField
                                    id="cmp-code"
                                    className="input"
                                    disabled={true}
                                    fullWidth={true}
                                    multiline={true}
                                    value={getCode()}
                                />
                                <Button
                                    className="button"
                                    size="small"
                                    disabled={getCode().includes('Feature') || codeCopied}
                                    variant="contained"
                                    color="primary"
                                    onClick={copyCode}
                                >
                                    <i className="icon fa-solid fa-copy"></i> {codeCopied ? 'Code copied!' : 'Copy code'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="step-entry">
                <div className="step-header">
                    <div className="step-badge">Step 2</div>
                    <div className="step-label">Configure cookies to be blocked</div>
                </div>
                <div className="step-content">
                    <div className="step-two-rows">
                        {toolsAffected.length > 0 ? (
                            <div className="parent-container-left" style={{ flex: 1 }}>
                                <div className="step-text">
                                    <p>
                                        All the tools and third parties that place cookies on your website that are not strictly necessary
                                        must be blocked until a user gives their consent.
                                    </p>
                                    <p>
                                        We've detected that you need to manually block {toolsAffected.length} tool
                                        {toolsAffected.length === 1 ? '' : 's'}:{' '}
                                        <span style={{ marginLeft: 1, fontWeight: 500 }}>{toolsAffected.join(', ')}</span>
                                    </p>
                                </div>
                                <div className="container-example">
                                    <div className="badge-example">Example</div>
                                    <p>
                                        1. Find the script tag that implements <b>{toolsAffected[0]}</b> and replace{' '}
                                        <code>type="application/javascript</code> with <code>type="text/plain"</code>. If your script line
                                        doesn't have a type, add it.
                                    </p>

                                    <div className="before-text">Before</div>
                                    <TextField
                                        className="input-link"
                                        disabled={true}
                                        fullWidth={true}
                                        value={`<script type="application/javascript" src="https://script-website.com/bundle.js"></script>`}
                                    />
                                    <div className="before-text">After</div>
                                    <TextField
                                        className="input-link mb"
                                        disabled={true}
                                        fullWidth={true}
                                        value={`<script type="text/plain" src="https://script-website.com/bundle.js"></script>`}
                                    />

                                    <p>
                                        2. Find the script tag that implements <b>{toolsAffected[0]}</b> and add{' '}
                                        <code>data-usercentrics="{toolsAffected[0]}"</code> This is case sensitive.
                                    </p>
                                    <div className="before-text">Before</div>

                                    <TextField
                                        className="input-link"
                                        disabled={true}
                                        fullWidth={true}
                                        value={`<script type="text/plain" src="https://script-website.com/bundle.js"></script>`}
                                    />

                                    <div className="before-text">After</div>

                                    <TextField
                                        className="input-link mb"
                                        disabled={true}
                                        fullWidth={true}
                                        value={`<script type="text/plain" data-usercentrics="${toolsAffected[0]}" src="https://script-website.com/bundle.js></script>`}
                                    />

                                    <p>
                                        3. Remove any <code>async</code> attribute if present. It may be the case that your script doesn’t
                                        have it.
                                    </p>
                                    <div className="before-text">Before</div>

                                    <TextField
                                        className="input-link"
                                        disabled={true}
                                        fullWidth={true}
                                        value={`<script type="text/plain" data-usercentrics="${toolsAffected[0]}" src="https://script-website.com/bundle.js" async></script>`}
                                    />

                                    <div className="before-text">After</div>

                                    <TextField
                                        className="input-link"
                                        disabled={true}
                                        fullWidth={true}
                                        value={`<script type="text/plain" data-usercentrics="${toolsAffected[0]}" src="https://script-website.com/bundle.js"></script>`}
                                    />

                                    <p style={{ marginTop: 18, fontWeight: 500 }}>
                                        * Tip: For cookies that are added to your website via Google Tag Manager, please see:{' '}
                                        <a
                                            target="_blank"
                                            href="https://privasee.help.center/article/1009-how-to-configure-your-cookie-banner-with-google-tag-manager"
                                        >
                                            How to configure your Cookie Banner with Google Tag Manager?
                                        </a>
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="step-content">
                                <div className="step-text">
                                    It seems there is no cookie that requires to be manually blocked by a change in code.
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {toolsAffected.length > 0 && <CodeBuilder data={toolsAffected} />}
            {data.smartCookieBlocker && (
                <div className="step-entry">
                    <div className="step-header">
                        <div className="step-badge">Step 3</div>
                        <div className="step-label">Automatically Block Cookies</div>
                    </div>
                    <div className="step-content">
                        <div className="step-two-rows">
                            <div className="parent-container-left" style={{ flex: 1 }}>
                                <div className="step-text">
                                    Your cookie banner will automatically block all the cookies detected on your website from our{' '}
                                    <a
                                        href="https://privaseeio.notion.site/What-tools-are-blocked-by-Smart-Cookie-Blocker-73fced80e6d64b8bb6a843043339adab"
                                        target="_blank"
                                    >
                                        supported list of tools and third parties
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Component;
