import React, { useState, createContext, useContext } from 'react';

// Components
import Header from './components/header';
import DoYouProcessData from './components/doYouProcessData';
import Individuals from './components/individuals';

// Context
import { OnboardingContext } from '../..';
import { logError } from '../../../../../utils/helpers';
import { OnboardingFunctions } from '../../components/functions';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { companyData } = OnboardingContext();
    const { updateCompanyData } = OnboardingFunctions();

    const [children, setChildren] = useState(companyData.individualsReferring.filter((c) => c.isChildren).map((c) => c.label));
    const [processChildrenData, setProcessChildrenData] = useState(children.length > 0 ? true : false);

    /**
     * When the user taps "Next" we will update the company individual references and mark the right individual as Children or not.
     */

    const updateIndividualReferences = async () => {
        try {
            let newIndividualsReferring = [...companyData.individualsReferring];

            newIndividualsReferring = newIndividualsReferring.map((c) => ({
                ...c,
                isChildren: children.includes(c.label) ? true : false,
            }));

            // If a children is not yet defined.
            for (const label of children) {
                const alreadyDefined = newIndividualsReferring.find((c) => c.label === label);

                if (alreadyDefined) continue;

                // We create this new referring without defining the referring role.
                newIndividualsReferring.push({
                    label,
                    isChildren: true,
                    referringRole: null,
                });
            }

            await updateCompanyData({
                individualsReferring: newIndividualsReferring,
                processChildrenData: children.length > 0 ? true : false,
            });
        } catch (err) {
            await logError(`onboarding.children.updateIndividualReferences`, err);
        }
    };

    const PassedProps = {
        children,
        setChildren,
        processChildrenData,
        setProcessChildrenData,
        updateIndividualReferences,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <div className="module-content children-screen">
                <DoYouProcessData />
                {processChildrenData && (
                    <React.Fragment>
                        <Individuals />
                    </React.Fragment>
                )}
            </div>
        </Context.Provider>
    );
};

export default Component;
