import React from 'react';
import { PageContext } from '..';

const Component = () => {
    const { data } = PageContext();

    return (
        <React.Fragment>
            <div className="conversations">
                <div className="entries" id="conversation-container">
                    {data.conversation.map((c, index) => (
                        <div className={`entry ${c.sender === 'User' ? 'user' : 'bot'} ${c.animShowUp && 'animShowUp'}`} key={index}>
                            <div className="content">
                                <div className={`avatar ${c.sender === 'User' ? 'user' : 'bot'}`}>{c.sender === 'User' && 'YOU'}</div>
                                <div className="message">
                                    {c.message.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                    {data.waitingReply && (
                        <div className="entry bot">
                            <div className="content">
                                <div className="avatar bot"></div>
                                <div className="message">
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                        <div className="blink">
                                            <div className="icon"></div>
                                        </div>
                                        {data.prefetched === false && (
                                            <div className="loading-text" style={{ marginLeft: 16 }}>
                                                We need to train the AI before answering your question, which may take a moment. Please
                                                wait.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
