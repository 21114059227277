import React from 'react';
import ReactDOM from 'react-dom';

// Components
import PickPanel from '../../../../../../../../../thirdParties/components/pickPanel';

// Context
import { SetStorageLocationsContext } from '../../..';

const Component = (props) => {
    const { panelController, dataInventory, toolsRecommendations } = SetStorageLocationsContext();

    // We need to render the panel there.
    const domNode = document.getElementById('panel-container');
    if (!domNode) return null;

    return ReactDOM.createPortal(
        <React.Fragment>
            <PickPanel
                //Important class name
                className="module-picker-locations"
                // Ref to control the panel
                innerRef={panelController}
                // Data Inventory is important to know what tools they have.
                dataInventory={dataInventory}
                // Options the user can pick storage locations
                options={toolsRecommendations}
                // When tools are created
                addPickedTools={props.onToolsPicked}
                createCustomTool={props.onCustomToolCreated}
                // When they close the panel
                closePanel={props.stopPicking}
                // View type will make sure to have a category called "from your data inventory"
                viewType="pickFromInventory"
            />
        </React.Fragment>,
        domNode,
    );
};

export default Component;
