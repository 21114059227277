import React from 'react';
import { ComponentState } from '../../..';

const Component = () => {
    const { data, updateData, loading } = ComponentState();

    const onChange = (val) => {
        if (data.privacyPortalSettings.theme.mode === val) return false;
        if (loading === true) return false;
        updateData(`privacyPortalSettings.theme.mode`, val);
    };

    const entries = [
        {
            label: `Light theme`,
            description: `Choose this option if your website background is light-themed.`,
            value: `light`,
            icon: `fa-solid fa-brightness`,
        },
        {
            label: `Dark theme`,
            description: `Choose this option if your website background is dark-themed.`,
            value: `dark`,
            icon: `fa-solid fa-moon`,
        },
    ];

    return (
        <React.Fragment>
            <div className="form-label">Choose your website theme</div>
            <div className="form-content">
                <div className="component-switch-cards extra-mt">
                    {entries.map((entry, ix) => (
                        <div
                            onClick={() => onChange(entry.value)}
                            className={`entry ${
                                data.privacyPortalSettings &&
                                data.privacyPortalSettings.theme &&
                                data.privacyPortalSettings.theme.mode === entry.value &&
                                `selected`
                            }`}
                            key={ix}
                        >
                            <div className="icon">
                                <i className={`elm ${entry.icon}`}></i>
                            </div>
                            <div className="title">{entry.label}</div>
                            <div className="description">{entry.description}</div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
