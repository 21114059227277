import React from 'react';
import { ComponentState } from '../../..';

const Component = (props) => {
    const { isModuleTaskCompleted } = ComponentState();

    const checked = isModuleTaskCompleted(
        props.data.type === 'securityMeasure' ? 'security-measures' : props.data.id,
        props.data.type === 'securityMeasure' ? props.data.id : null,
    );

    return (
        <React.Fragment>
            <div className="entry" onClick={props.onSelected}>
                <div className="left-side">
                    <i className={`icon fa-regular fa-circle${checked ? '-check' : ''}`}></i>
                    <div className="label">{props.data.label}</div>
                </div>
                <div className="right-side">
                    <i className="icon fa-solid fa-chevron-right"></i>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
