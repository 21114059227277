import React from 'react';

// Context
import { PanelContext } from '../..';

// Dependencies
import { mapEntriesToVisualData } from '../../utils/functions';

// Components
import AddProcess from './components/addProcess';
import Process from './components/process';

const Component = () => {
    const { dataInventory, data } = PanelContext();

    // Transform the data for easy read.
    const entries = mapEntriesToVisualData(data.matrixMap, dataInventory);

    return (
        <React.Fragment>
            <AddProcess entries={entries} />
            {entries.length > 0 && (
                <div className="entries">
                    {entries.map((entry, ix) => (
                        <Process key={ix} data={entry.data} individuals={entry.individuals} />
                    ))}
                </div>
            )}

            {entries.length < 1 && (
                <div className="no-entries">
                    <div className="icon">
                        <i className="elm fa-regular fa-circle-info"></i>
                    </div>
                    <div className="label">Click the button above to designate the purpose of this tool.</div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Component;
