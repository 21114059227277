import { CircularProgress } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ComponentState } from '..';
import Container from '../../../layout/container';

const Component = (props) => {
    const { loading, data, securityMeasures } = ComponentState();
    if (loading || securityMeasures === null) return <LoadingComponent />;
    if (!loading && data === undefined) return <FailedLoadingComponent />;

    return <AppWrapper>{props.children}</AppWrapper>;
};

let cancellableTimer = null;

const LoadingComponent = () => {
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        cancellableTimer = setTimeout(() => {
            setShowLoading(true);
            cancellableTimer = null;
        }, 500);

        return () => {
            if (cancellableTimer) {
                clearTimeout(cancellableTimer);
                cancellableTimer = null;
            }
        };
    }, []);

    return (
        <AppWrapper title="Loading">
            <div className={`--component-loading-screen ${showLoading && 'visible'}`}>
                <CircularProgress />
                <div className="message">Loading your DPA...</div>
            </div>
        </AppWrapper>
    );
};

const FailedLoadingComponent = () => {
    return (
        <AppWrapper title="Failed to load">
            <div className="--component-error-screen">
                <i className="icon fa-regular fa-circle-exclamation"></i>
                <div className="heading">Having Difficulties</div>
                <div className="message">
                    We're experiencing technical difficulties, please try again later or contact us for more info.
                </div>
            </div>
        </AppWrapper>
    );
};

const AppWrapper = (props) => (
    <Container
        title={`Data Processing Agreement${props.title ? ` - ${props.title}` : ``}`}
        classNames="page-dpa"
        isAlternateBackground={true}
    >
        {props.children}
    </Container>
);

export default Component;
