import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { formatNumber } from '../../../../utils/helpers';

const Component = (props) => {
    const isMostPopular = props.data.pricingTableDetails.isMostPopular;

    const p = props.data;

    const getPriceDisplay = (entry) => {
        let price = entry.prices[props.currency];
        if (!price) return 'Unknown';

        price = formatNumber(price);

        if (props.currency === 'eur') {
            return `${price}€`;
        } else if (props.currency === 'usd') {
            return `$${price}`;
        } else {
            return `£${price}`;
        }
    };

    return (
        <React.Fragment>
            <div className={`entry-price ${isMostPopular && 'most-popular'} ${props.loading && `is-loading`}`}>
                {props.loading && props.isSelected && (
                    <div className="loading-effect">
                        <CircularProgress />
                        <div className="text">Loading please wait..</div>
                    </div>
                )}
                <div className="header">
                    <div
                        className="image"
                        style={{
                            backgroundImage: `url("/assets/images/plans/${p.pricingTableDetails.iconImage}")`,
                        }}
                    ></div>
                    <div className="name">{p.name}</div>

                    {p.pricingTableDetails.isMostPopular && <div className="most-popular">Most popular</div>}

                    <div className="price">
                        {!p.isCustomAndNotListed ? (
                            <React.Fragment>
                                {getPriceDisplay(p)}
                                {p.recurring && (
                                    <span className="mo">
                                        <span className="hash">/</span>
                                        {p.recurring === 'monthly' ? 'month' : 'year'}
                                    </span>
                                )}
                                <span className="vat">(excl VAT)</span>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>-</React.Fragment>
                        )}
                    </div>
                </div>
                <div className="features">
                    {!p.isCustomAndNotListed ? (
                        <React.Fragment>
                            <div className="heading-text">Includes:</div>
                            <div className="entries">
                                {p.pricingTableDetails.features.length < 1 && (
                                    <div className="entry">
                                        <div className="icon">
                                            <i className="elm fa-light fa-circle-check"></i>
                                        </div>
                                        <div className="text">
                                            Please check again later. We haven't updated the features on this plan yet.
                                        </div>
                                    </div>
                                )}
                                {p.pricingTableDetails.features.map((f, ix) => (
                                    <div className="entry" key={ix}>
                                        <div className="icon">
                                            <i className="elm fa-light fa-circle-check"></i>
                                        </div>
                                        <div className="text" dangerouslySetInnerHTML={{ __html: f }} />
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="heading-text">Info</div>
                            <div className="entries">
                                <div className="entry">
                                    <div className="icon">
                                        <i className="elm fa-light fa-circle-info"></i>
                                    </div>
                                    <div className="text">
                                        This is a custom plan our company has prepared for you. Please get in touch with us to get more
                                        details about your features.
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className="end-divider"></div>
                <div className="action-area">
                    <Button variant="contained" onClick={props.onSelect} disabled={props.submitted || props.loading} color="primary">
                        Select this plan
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
