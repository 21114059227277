import moment from 'moment';
import React from 'react';
import Tooltip from '../../../components/tooltip';

const Component = (props) => {
    const getStatusText = (entry) => {
        if (entry.data.published && !entry.downloadLink) return 'Awaiting Completion';
        if (entry.data.published && entry.downloadLink) return 'Completed';
        return 'Draft';
    };

    const onEditRowClicked = (event, entry) => {
        props.onSelect(entry);
    };
    return (
        <React.Fragment>
            <div className="table">
                <div className="row header">
                    <div className="col name">Name</div>
                    <div className="col status">
                        Status{' '}
                        <div className="tooltip" style={{ display: 'inline-block' }}>
                            <Tooltip
                                trackInteraction={true}
                                interactionId={`gdpr-essentials:what-data-category-is`}
                                useHTML={true}
                                content={
                                    <React.Fragment>
                                        These are the statuses:
                                        <ul style={{ textAlign: 'left' }}>
                                            <li>Draft is a DPA that has not been submitted yet.</li>
                                            <li>Awaiting Completion is a submitted DPA that is being manually processed.</li>
                                            <li>Completed means that the DPA is ready to download.</li>
                                        </ul>
                                    </React.Fragment>
                                }
                            >
                                <i className="tooltip-icon fa-solid fa-circle-info"></i>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="col date">Created At</div>
                    <div className="col date">Last Updated</div>
                    <div className="col options"></div>
                </div>
                {props.data.length < 1 && (
                    <div className="row not-clickable">
                        <div className="col name">None.</div>
                    </div>
                )}
                {props.data.map((entry, ix) => (
                    <div className="row" key={ix} data-id={`${entry._id}`}>
                        <div className="col name">{entry.name || 'Unnamed'}</div>
                        <div className="col status">{getStatusText(entry)}</div>
                        <div className="col date">{moment(entry.createdAt).format('DD MMM YY, HH:mm')}</div>
                        <div className="col date">{moment(entry.updatedAt).format('DD MMM YY, HH:mm')}</div>
                        <div className="col options">
                            {entry.downloadLink && (
                                <i
                                    onClick={() => props.onDownload(entry)}
                                    className="option fa-regular fa-download"
                                    data-cy="download-btn"
                                ></i>
                            )}
                            <i className="option fa-solid fa-cog" onClick={() => props.onEdit(entry)}></i>{' '}
                            <i className="option fa-solid fa-edit" onClick={(ev) => onEditRowClicked(ev, entry)}></i>
                            <i className="option fa-solid fa-trash" onClick={() => props.onDelete(entry)}></i>{' '}
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default Component;
