import React, { useState } from 'react';
import { ModuleState } from '../../..';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const Component = () => {
    const { submitted, updateEntities, settingsMeta, selectedEntities, setSelectedEntities, editingId } = ModuleState();

    const [activeModal, setActiveModal] = useState(null);

    const [selectedValue, setSelectedValue] = useState(null);

    const startSelecting = () => {
        setActiveModal(true);
        setSelectedValue(null);
    };

    const closeModal = () => {
        setActiveModal(false);
        setSelectedValue(null);
    };

    const submitSelection = async () => {
        // Update
        updateEntities(selectedEntities.map((c) => ({ _id: c, payload: { assignedTo: selectedValue } })));

        // Close modal
        closeModal();

        // Set set selected entities empty now
        setSelectedEntities([]);
    };

    return (
        <React.Fragment>
            <Button variant="contained" color="primary" disabled={submitted || editingId !== null} onClick={startSelecting}>
                Assign to Someone
            </Button>
            {activeModal && (
                <Modal
                    data={selectedValue}
                    setData={setSelectedValue}
                    options={settingsMeta.companyEmails}
                    onClose={closeModal}
                    onSubmit={submitSelection}
                />
            )}
        </React.Fragment>
    );
};

const Modal = (props) => {
    return (
        <React.Fragment>
            <Dialog className="edit-dpa-modal" open={true} onClose={props.onClose} maxWidth={'xs'} fullWidth={true}>
                <DialogTitle>Select a person from your workspace</DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <div className="--entries">
                        {props.options.map((c, index) => (
                            <React.Fragment key={index}>
                                <div
                                    className="--entry"
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}
                                >
                                    <div className="checkbox">
                                        <Checkbox
                                            style={{ padding: '2px !important' }}
                                            value={props.data === c ? true : false}
                                            onClick={() => props.setData(c)}
                                        />
                                    </div>
                                    <div className="--label" style={{ fontSize: 14 }}>
                                        {c}
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" onClick={props.onClose}>
                        Dismiss
                    </Button>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Button variant="contained" onClick={props.onSubmit}>
                            Submit
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
