import React from 'react';

import { Fab } from '@mui/material';
import Tooltip from '../../../../components/tooltip';

// Components
import Filters from './components/filters';

import { ModuleState } from '../..';

const Component = () => {
    const { downloadRopa } = ModuleState();

    return (
        <React.Fragment>
            <div className="page-sub-header">
                <Filters />
                <div className="right-side">
                    <Tooltip position="left" content="Download Record of Processing Activities">
                        <Fab size="small" className="add-btn" color="primary" onClick={downloadRopa}>
                            <i className="icon fa-solid fa-download"></i>
                        </Fab>
                    </Tooltip>
                    {/* <div className="divider" style={{ marginLeft: 8 }}></div>
                    <Tooltip position="bottom" content="Settings">
                        <SettingsMenu />
                    </Tooltip> */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
