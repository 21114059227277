import { Button, CircularProgress, TextField } from '@mui/material';
import React, { useState } from 'react';
import { SettingsContext } from '../../..';
import { getValidationPropFields, logError, trackUserInteraction } from '../../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';

const Component = () => {
    const { updateData, data, loading, validationKeys } = SettingsContext();
    const [generatingCompanyDescription, setGeneratingCompanyDescription] = useState(false);

    const generateCompanyDescription = async () => {
        try {
            // Already generating, waiting
            if (generatingCompanyDescription) return false;

            // Mark..
            setGeneratingCompanyDescription(true);

            trackUserInteraction(`Selected "Generate Again" on Company Description`, {
                from: 'settings',
            });

            // Make endpoint..
            const response = await makeEndpointRequest(`GenerateCompanyDescription`, {
                domains: data.companyDomains,
            });

            trackUserInteraction(`Generated Company Description`, {
                response,
                from: 'settings',
            });

            // There's something to work with.
            if (response.length > 1) {
                updateData(`companyDescription`, response);
            }

            // Mark false..
            setGeneratingCompanyDescription(false);
        } catch (err) {
            await logError(`gel.api.generateCompanyDescription`, err);
            setGeneratingCompanyDescription(false);
        }
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="label">Company Description</div>
                <TextField
                    value={data.companyDescription || ''}
                    fullWidth={true}
                    className="textarea-description"
                    placeholder="Company description.."
                    disabled={generatingCompanyDescription || loading}
                    variant="outlined"
                    multiline={true}
                    rows={6}
                    onChange={(e) => updateData(`companyDescription`, e.target.value)}
                    {...getValidationPropFields(validationKeys, !loading, `companyDescription`)}
                />
                <div className="button-container" style={{ marginTop: 16, marginBottom: 16 }}>
                    <Button
                        disabled={generatingCompanyDescription}
                        variant="contained"
                        color="primary"
                        onClick={generateCompanyDescription}
                    >
                        Generate Again
                        {generatingCompanyDescription && <CircularProgress size={16} style={{ marginLeft: 16 }} />}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
