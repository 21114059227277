import React from 'react';

// Components

import YourOwnPrivacyPortal from './components/getCode';
import AddItToWebsite from './components/installCode';
import WebsiteBuilders from './components/websiteBuilders';
import Support from './components/support';
import CheckImplementation from './components/checkImplementation';

const Component = () => {
    return (
        <div className="steps">
            <YourOwnPrivacyPortal />
            <AddItToWebsite />
            <CheckImplementation />
            <WebsiteBuilders />
            <Support />
        </div>
    );
};

export default Component;
