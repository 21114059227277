import React from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';

// Dependencies
import { trackUserInteraction } from '../../../../../utils/helpers';

// Context
import { ModuleState } from '../../index';
import { ModuleAPI } from '../../components/api';
import moment from 'moment';

const CompanyInsights = () => {
    const { data, setStep, updateData, generatingCompanyDescription } = ModuleState();
    const { generateCompanyDescription, generateCompanyActivities } = ModuleAPI();

    const nextStep = async () => {
        let nextStep = 'StorageLocations';

        setStep(nextStep);

        // Generate activities..
        if (
            !data._meta.companyActivitiesGenerated &&
            data.companyInfo.services.length < 1 &&
            data.companyInfo.companyDescription.length > 0
        ) {
            // Generate..
            generateCompanyActivities();
        }

        await trackUserInteraction(`Selected "Next"`, { destination: nextStep });
    };

    const goBack = async () => {
        setStep('CompanyInsights');
        await trackUserInteraction(`Selected "Go Back"`, { destination: `CompanyInsights` });
    };

    const generateAgain = () => {
        // Check if we have previously generated company description data
        if (
            data._meta.companyDescriptionLastGeneratedAt &&
            data._meta.companyDescriptionGenerated &&
            data._meta.companyDescriptionGeneratedUses === 3 // Used already 3 times
        ) {
            // Calculate the time elapsed since the last generation using Moment.js
            const currentTime = moment();
            const lastGeneratedTime = moment(data._meta.companyDescriptionLastGeneratedAt);
            const timeDifferenceInMinutes = currentTime.diff(lastGeneratedTime, 'minutes');

            // Check if it's been less than 5 minutes since the last generation
            if (timeDifferenceInMinutes < 5) {
                window.showAlert('Cooldown period', `Wait ${5 - timeDifferenceInMinutes} minutes before generating again.`, `error`);
                return; // Stop execution if the condition is not met
            }
        }

        trackUserInteraction(`Selected "Generate Again" on Company Description`, {
            from: 'onboardingFlow',
        });

        // Do it..
        generateCompanyDescription();
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={goBack}>
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        How you would describe your business?
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={data.companyInfo.companyDescription.length < 1}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
            <div className="describe-company module-content">
                <div className="textfield">
                    <TextField
                        multiline={true}
                        rows={6}
                        value={data.companyInfo.companyDescription}
                        onChange={(ev) => updateData(`companyInfo.companyDescription`, ev.target.value)}
                        fullWidth={true}
                        disabled={generatingCompanyDescription}
                        placeholder="Describe your company here.."
                    />
                    {generatingCompanyDescription && (
                        <div className="alert-ai">
                            <CircularProgress size={30} />
                            <div className="details">
                                <div className="heading">Crafting Your Company Description</div>
                                <div className="description">
                                    Please allow us a moment to explore your website and create a compelling company description tailored
                                    just for you.
                                </div>
                            </div>
                        </div>
                    )}
                    <Button
                        onClick={generateAgain}
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 16 }}
                        disabled={generatingCompanyDescription}
                    >
                        Generate Again
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CompanyInsights;
