import { MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { EntryState } from '../entry';
import { isValueNotRequired } from '../../utils/functions';

const Component = () => {
    const { setData, data, field, getOptions } = EntryState();

    if (data.filter === 'is') {
        return (
            <div className="field value">
                <Select fullWidth={true} value={data.value || 'none'} onChange={(ev) => setData('value', ev.target.value)}>
                    <MenuItem value="none" disabled={true}>
                        Select an option
                    </MenuItem>
                    {getOptions().map((opt, ix) => {
                        // If they pass those fields we'll use those.
                        const value = field.propertyCompared ? opt[field.propertyCompared] : opt;
                        const label = field.optionsLabel ? opt[field.optionsLabel] : opt;

                        return (
                            <MenuItem key={ix} value={value}>
                                {label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </div>
        );
    }

    return (
        <div className="field value">
            <TextField
                disabled={isValueNotRequired(data.filter)}
                fullWidth={true}
                value={data.value}
                onChange={(ev) => setData('value', ev.target.value)}
                variant="outlined"
                placeholder="Enter a value"
            />
        </div>
    );
};

export default Component;
