import React from 'react';

// Context
import { PanelContext } from '../../..';

const Component = () => {
    const { showCustomToolModal } = PanelContext();

    return (
        <React.Fragment>
            <div className="component-storage-recommendation-entry add-button">
                <div className="content" onClick={() => showCustomToolModal()}>
                    <div className="left-side">
                        <div className="label">Create a custom tool or third party</div>
                    </div>
                    <div className="btn">
                        <i className="elm fa-solid fa-plus"></i>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
