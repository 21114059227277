const analyticsEndpoints = {
    TrackAnalyticAmplitude: {
        link: '/guest/graphql',
        method: 'POST',
        query: `
            query(
                $event_title: String!, 
                $event_properties:JSONObject,
                $user_id: String!
                $user_properties: JSONObject,
                $project_key: String!
            ) {
            TrackAnalyticAmplitude(
                event_title:$event_title, 
                event_properties: $event_properties, 
                user_id:$user_id,
                user_properties:$user_properties,
                project_key: $project_key
            )
        }
        `,
    },
};

export default analyticsEndpoints;
