import React from 'react';
import { SettingsContext } from '../../..';
import { getValidationPropFields, trackUserInteraction } from '../../../../../../utils/helpers';
import { Autocomplete, Button, TextField } from '@mui/material';
import Tooltip from '../../../../../components/tooltip';

const Component = () => {
    const { data, updateData, loading, validationKeys } = SettingsContext();

    const AddAnotherWebsite = () => {
        trackUserInteraction(`Selected "Add another website"`, { reason: 'Adds another website to his company scannable domains' });
        const websites = [...data.monitoringSettings.domains];
        websites.push('');
        updateData(`monitoringSettings.domains`, websites);
    };

    const RemoveWebsite = (index) => {
        trackUserInteraction(`Selected "Remove website"`, { reason: 'Removed one website from his list of scannable domains' });
        const websites = [...data.monitoringSettings.domains];
        websites.splice(index, 1);
        updateData(`monitoringSettings.domains`, websites);
    };

    return (
        <React.Fragment>
            <div className="form-group websites" style={{ marginBottom: 24 }}>
                <div className="label">
                    Websites Scanned
                    <Tooltip content="These are the domains that our scanner will use when looking for changes in your business.">
                        <i style={{ marginLeft: 8 }} className="color-primary tooltip-icon fa-solid fa-circle-question"></i>
                    </Tooltip>
                </div>
                <div className="input shared-component-domain-edits">
                    {data.monitoringSettings.domains.map((_, index) => (
                        <div className="listed-domain-entry" key={index}>
                            <Autocomplete
                                value={data.monitoringSettings.domains[index] || ''}
                                disabled={loading}
                                freeSolo={true}
                                onChange={(_, newValue) => {
                                    updateData(`monitoringSettings.domains[${index}]`, newValue);
                                }}
                                // Bugfix for free solo when they write but don't press Enter.
                                onInputChange={(ev) => {
                                    if (!ev) return false;
                                    updateData(`monitoringSettings.domains[${index}]`, ev.target.value);
                                }}
                                fullWidth={true}
                                disableClearable={true}
                                autoSelect={true}
                                options={
                                    data.monitoringSettings.domains[index].length > 0
                                        ? []
                                        : data.companyDomains.filter((c) => (data.monitoringSettings.domains.includes(c) ? false : true))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        className="input input-domain"
                                        variant="outlined"
                                        placeholder="Company website"
                                        fullWidth={true}
                                        {...getValidationPropFields(validationKeys, !loading, `monitoringSettings.domains[${index}]`)}
                                    />
                                )}
                            />

                            {data.monitoringSettings.domains.length > 1 && (
                                <Button
                                    className="remove-domain-btn"
                                    variant="standard"
                                    color="primary"
                                    size="small"
                                    onClick={() => RemoveWebsite(index)}
                                >
                                    <i className="icon fa-solid fa-trash"></i>
                                </Button>
                            )}
                        </div>
                    ))}
                </div>
                <Button variant="contained" color="primary" size="small" className="add-more-websites-btn" onClick={AddAnotherWebsite}>
                    Add another website
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Component;
