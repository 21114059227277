import React from 'react';

// Components
import AutoComplete from '../../../../thirdParties/components/editPanel/components/autocomplete';

// Context
import { OnboardingContext } from '../../..';
import { ViewContext } from '..';

const Component = () => {
    const { dataInventory, setViewId } = OnboardingContext();
    const { children, setChildren } = ViewContext();

    const goToIndividuals = () => {
        setViewId('individuals');
    };

    const getOptions = () => {
        return dataInventory.individuals.map((c) => ({
            label: c.label,
        }));
    };

    const getCurrentValue = () => {
        return children.map((label) => ({
            label: label,
        }));
    };

    const onIndividualAdded = (elm) => {
        setChildren([...children, elm.label]);
    };

    const onIndividualRemoved = (elm) => {
        setChildren((curr) => curr.filter((d) => d !== elm.label));
    };

    return (
        <React.Fragment>
            <div className="form-entry">
                <div className="label">Which of the provided individuals might be under age?</div>
                <div className="content">
                    <AutoComplete
                        // The value and options
                        value={getCurrentValue()}
                        options={getOptions()}
                        // Props to the autocomplete..
                        groupCategories={false}
                        subjectsView={false}
                        placeholder={'Click here to select the individuals.. '}
                        // Callbacks when data is changed
                        onEntityAdded={(elm) => onIndividualAdded(elm)}
                        onEntityRemoved={(elm) => onIndividualRemoved(elm)}
                        notCreatable={true}
                    />
                </div>
                <div className="subText" style={{ color: 'rgba(255,255,255,0.8)', fontSize: 14, marginTop: 16 }}>
                    Can't find the right individual? Add it on the{' '}
                    <a href="#" className="color-gold" onClick={goToIndividuals}>
                        "Individuals Step"
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
