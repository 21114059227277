import React from 'react';
import { ComponentState } from '../../../../..';
import { useHistory } from 'react-router-dom';

const Component = (props) => {
    const { serviceVerifications } = ComponentState();
    const history = useHistory();

    const review = async () => {
        history.push('/stages/gdpr-essentials/edit/data-inventory');
    };

    const getAdvice = () => {
        // Shortcuts
        const cookieBanner = serviceVerifications['cookieBanner'];
        const inventoryAssessment = serviceVerifications['inventoryAssessment'];

        // If cookie banner is not found
        if (cookieBanner !== null && cookieBanner.data.result.found === false) {
            return (
                <React.Fragment>
                    <p>
                        Our website scanner was unable to locate the cookie banner on your site. For more information, please refer to our
                        guide on how our platform performs website scans and learn about potential solutions by clicking{' '}
                        <a
                            href="https://privaseeio.notion.site/Why-doesn-t-the-platform-scan-my-domain-83646845485c4e9bbb9d69d03fc906fa"
                            target="_blank"
                        >
                            here
                        </a>
                        .
                    </p>
                </React.Fragment>
            );
        }

        // If new tools are found
        if (inventoryAssessment !== null && inventoryAssessment.data.missingTools.length > 0) {
            return (
                <React.Fragment>
                    <p>
                        Your website is using tools that are placing cookies without proper consent. Take a moment to examine your data
                        inventory and address any new findings.
                    </p>
                    <p>Unlisted tools identified during the latest scan: {inventoryAssessment.data.missingTools.join(', ')}</p>
                    <p>
                        Click{' '}
                        <a href="#" onClick={review}>
                            here
                        </a>{' '}
                        to review and add these tools to your data inventory.
                    </p>
                </React.Fragment>
            );
        }

        return null;
    };

    // No advice to give.
    if (getAdvice() === null || props.scanning) return null;

    return (
        <React.Fragment>
            <div className="advice">
                <div className="icon">
                    <i className="elm fa-solid fa-circle-exclamation"></i>
                </div>
                <div className="message">{getAdvice()}</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
