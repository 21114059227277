import React, { useEffect } from 'react';
import { TextField, Button, FormHelperText, Select, MenuItem, Autocomplete } from '@mui/material';
import Tooltip from '../../../../components/tooltip';
import { validateAgainstSchema, getValidationPropFields, trackUserInteraction } from '../../../../../utils/helpers';
import { settingsGeneralSchema } from '../../utils/validation';

import CountriesJSON from '../../../../../dummy_data/countries.json';

// Components
import CompanyDescription from './components/companyDescription';
import SalesTarget from './components/salesTarget';
import Industry from './components/industry';
import Funding from './components/funding';
import CompanySize from './components/companySize';
import Services from './components/services';

const Component = (props) => {
    const validateFields = async () => {
        try {
            await validateAgainstSchema(settingsGeneralSchema, props.data);
            props.setValidationKeys({});
        } catch (err) {
            props.setValidationKeys(err);
        }
    };

    useEffect(() => {
        validateFields();
        return () => {
            props.setValidationKeys({});
        };
        // eslint-disable-next-line
    }, [props.data]);

    const AddAnotherWebsite = () => {
        trackUserInteraction(`Selected "Add another website"`, { reason: 'Adds another website to his company' });
        const websites = [...props.data.companyDomains];
        websites.push('');
        props.updateData(`companyDomains`, websites);
    };

    const RemoveWebsite = (index) => {
        trackUserInteraction(`Selected "Remove website"`, { reason: 'Removed one website from his list of domains' });
        const websites = [...props.data.companyDomains];
        websites.splice(index, 1);
        props.updateData(`companyDomains`, websites);
    };

    return (
        <div className="section general">
            <div className="section-content">
                <div className="section-group no-sections">
                    <div className="form-group">
                        <div className="label">Company Legal Name</div>
                        <TextField
                            value={props.data.companyLegalName || ''}
                            fullWidth={true}
                            className="input"
                            disabled={props.loading}
                            placeholder="Company legal name"
                            variant="outlined"
                            onChange={(e) => props.updateData(`companyLegalName`, e.target.value)}
                            {...getValidationPropFields(props.validationKeys, !props.loading, `companyLegalName`)}
                        />
                    </div>
                    <div className="form-group websites" style={{ marginBottom: 24 }}>
                        <div className="label">
                            {props.data.companyDomains.length > 0 ? 'Websites' : 'Website'}
                            <Tooltip content="These domains will appear in your privacy portal and will help the platform understand what your business is about.">
                                <i style={{ marginLeft: 6 }} className="color-primary tooltip-icon fa-solid fa-circle-question"></i>
                            </Tooltip>
                        </div>
                        <div className="input shared-component-domain-edits">
                            {props.data.companyDomains.map((_, index) => (
                                <div className="listed-domain-entry" key={index}>
                                    <Autocomplete
                                        value={props.data.companyDomains[index] || ''}
                                        disabled={props.loading}
                                        freeSolo={true}
                                        onChange={(_, newValue) => {
                                            props.updateData(`companyDomains[${index}]`, newValue);
                                        }}
                                        // Bugfix for free solo when they write but don't press Enter.
                                        onInputChange={(ev) => {
                                            if (!ev) return false;
                                            props.updateData(`companyDomains[${index}]`, ev.target.value);
                                        }}
                                        fullWidth={true}
                                        disableClearable={true}
                                        autoSelect={true}
                                        options={
                                            props.data.companyDomains[index].length > 0
                                                ? []
                                                : props.data.monitoringSettings.domains.filter((c) =>
                                                      props.data.companyDomains.includes(c) ? false : true,
                                                  )
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                className="input input-domain"
                                                variant="outlined"
                                                placeholder="Company website"
                                                fullWidth={true}
                                                {...getValidationPropFields(
                                                    props.validationKeys,
                                                    !props.loading,
                                                    `companyDomains[${index}]`,
                                                )}
                                            />
                                        )}
                                    />

                                    {props.data.companyDomains.length > 1 && (
                                        <Button
                                            className="remove-domain-btn"
                                            variant="standard"
                                            color="primary"
                                            size="small"
                                            onClick={() => RemoveWebsite(index)}
                                        >
                                            <i className="icon fa-solid fa-trash"></i>
                                        </Button>
                                    )}
                                </div>
                            ))}
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="add-more-websites-btn"
                            onClick={AddAnotherWebsite}
                        >
                            Add another website
                        </Button>
                    </div>

                    <div className="form-group">
                        <div className="label">
                            Data Protection Officer (DPO){' '}
                            <Tooltip content="Leave it blank if you don't have one">
                                <i className="color-primary tooltip-icon fa-solid fa-circle-question"></i>
                            </Tooltip>
                        </div>
                        <TextField
                            value={props.data.dpoName || ''}
                            fullWidth={true}
                            disabled={props.loading}
                            className="input"
                            placeholder="Data Protection Officer"
                            onChange={(e) => props.updateData(`dpoName`, e.target.value)}
                            variant="outlined"
                            {...getValidationPropFields(props.validationKeys, !props.loading, `dpoName`)}
                        />
                    </div>

                    <div className="form-group">
                        <div className="label">Privacy Email</div>
                        <TextField
                            value={props.data.inquiriesEmail || ''}
                            fullWidth={true}
                            id="email"
                            className="input"
                            placeholder="Email"
                            disabled={props.loading}
                            type="email"
                            variant="outlined"
                            onChange={(e) => props.updateData(`inquiriesEmail`, e.target.value)}
                            {...getValidationPropFields(props.validationKeys, !props.loading, `inquiriesEmail`)}
                        />
                    </div>
                    <CompanyDescription />
                    <Services />
                    <SalesTarget />
                    <Industry />
                    <Funding />
                    <CompanySize />
                </div>
                <div className="section-header second">Company Registered Address</div>
                <div className="section-group no-sections">
                    <div className="form-group">
                        <div className="label">Address Line 1</div>
                        <TextField
                            value={props.data.companyAddress ? props.data.companyAddress.line1 : ''}
                            fullWidth={true}
                            className="input"
                            variant="outlined"
                            disabled={props.loading}
                            placeholder="Line 1"
                            onChange={(e) => props.updateData(`companyAddress.line1`, e.target.value)}
                            {...getValidationPropFields(props.validationKeys, !props.loading, `companyAddress.line1`)}
                        />
                    </div>
                    <div className="form-group">
                        <div className="label">Address Line 2</div>
                        <TextField
                            value={props.data.companyAddress ? props.data.companyAddress.line2 : ''}
                            fullWidth={true}
                            className="input"
                            placeholder="Line 2"
                            disabled={props.loading}
                            variant="outlined"
                            onChange={(e) => props.updateData(`companyAddress.line2`, e.target.value)}
                            {...getValidationPropFields(props.validationKeys, !props.loading, `companyAddress.line2`)}
                        />
                    </div>
                    <div className="form-group">
                        <div className="label">Postcode</div>
                        <TextField
                            value={props.data.companyAddress ? props.data.companyAddress.postCode : ''}
                            fullWidth={true}
                            disabled={props.loading}
                            className="input"
                            placeholder="Postcode"
                            variant="outlined"
                            onChange={(e) => props.updateData(`companyAddress.postCode`, e.target.value)}
                            {...getValidationPropFields(props.validationKeys, !props.loading, `companyAddress.postCode`)}
                        />
                    </div>
                    <div className="form-group">
                        <div className="label">City</div>
                        <TextField
                            value={props.data.companyAddress ? props.data.companyAddress.city : ''}
                            fullWidth={true}
                            className="input"
                            placeholder="City"
                            disabled={props.loading}
                            variant="outlined"
                            onChange={(e) => props.updateData(`companyAddress.city`, e.target.value)}
                            {...getValidationPropFields(props.validationKeys, !props.loading, `companyAddress.city`)}
                        />
                    </div>
                    <div className="form-group">
                        <div className="label">Country</div>
                        <Select
                            fullWidth={true}
                            disabled={props.loading}
                            onChange={(e) => props.updateData(`companyAddress.country`, e.target.value)}
                            value={props.data.companyAddress ? props.data.companyAddress.country : ''}
                        >
                            {CountriesJSON.map((country) => (
                                <MenuItem key={country.name} value={country.name}>
                                    {country.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {props.validationKeys[`companyAddress.country`] && !props.loading && (
                            <FormHelperText error={true}>{props.validationKeys['companyAddress.country']}</FormHelperText>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Component;
