const endpoints = {
    UpdateStorageLocation: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation UpdateStorageLocation($_id: String, $payload: JSON, $options: JSON)
            {
                UpdateStorageLocation(_id: $_id, payload: $payload, options: $options)
            }
        `,
        parserFunction: ({ data }) => data.UpdateStorageLocation,
    },

    SetStorageMatriceEntries: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation SetStorageMatriceEntries($_id: String, $entries: [JSON])
            {
                SetStorageMatriceEntries(_id: $_id, entries: $entries)
            }
        `,
        parserFunction: ({ data }) => data.SetStorageMatriceEntries,
    },
    CreateStorageLocations: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation CreateStorageLocations($entries: [JSON], $processesDependencies: [JSON])
            {
                CreateStorageLocations(entries: $entries, processesDependencies: $processesDependencies)
            }
        `,
        parserFunction: ({ data }) => data.CreateStorageLocations,
    },

    DeleteStorageLocation: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation DeleteStorageLocation($_storageLocationId: String)
            {
                DeleteStorageLocation(_storageLocationId: $_storageLocationId)
            }
        `,
        parserFunction: ({ data }) => data.DeleteStorageLocation,
    },
    CreateProcess: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation CreateProcess($payload: JSON, $additionalPayload: JSON)
            {
                CreateProcess(payload: $payload, additionalPayload: $additionalPayload)
            }
        `,
        parserFunction: ({ data }) => data.CreateProcess,
    },
    DeleteProcess: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation DeleteProcess($_id: String)
            {
                DeleteProcess(_id: $_id)
            }
        `,
        parserFunction: ({ data }) => data.DeleteProcess,
    },
    UpdateProcess: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation UpdateProcess($_id: String, $payload: JSON, $additionalPayload: JSON)
            {
                UpdateProcess(_id: $_id, payload: $payload, additionalPayload: $additionalPayload)
            }
        `,
        parserFunction: ({ data }) => data.UpdateProcess,
    },
    UpdateIndividual: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation UpdateIndividual($_id: String, $payload: JSON, $individualReferring: JSON)
            {
                UpdateIndividual(_id: $_id, payload: $payload, individualReferring: $individualReferring)
            }
        `,
        parserFunction: ({ data }) => data.UpdateIndividual,
    },
    DeleteIndividual: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation DeleteIndividual($_id: String)
            {
                DeleteIndividual(_id: $_id)
            }
        `,
        parserFunction: ({ data }) => data.DeleteIndividual,
    },
    DeleteElement: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation DeleteElement($_id: String)
            {
                DeleteElement(_id: $_id)
            }
        `,
        parserFunction: ({ data }) => data.DeleteElement,
    },
    CreateIndividual: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation CreateIndividual($payload: JSON, $individualReferring: JSON)
            {
                CreateIndividual(payload: $payload, individualReferring: $individualReferring)
            }
        `,
        parserFunction: ({ data }) => data.CreateIndividual,
    },
    CreateElement: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation CreateElement($payload: JSON)
            {
                CreateElement(payload: $payload)
            }
        `,
        parserFunction: ({ data }) => data.CreateElement,
    },
    CleanupInventory: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation CleanupInventory
            {
                CleanupInventory
            }
        `,
        parserFunction: ({ data }) => data.CleanupInventory,
    },
};

export default endpoints;
