import React, { useEffect } from 'react';

// Components
import Header from './components/header';
import StorageLocations from './components/storageLocations';
import Buttons from './components/buttons';
import Toast from './components/toast';

// Context
import { OnboardingContext } from '../..';
import { logError } from '../../../../../utils/helpers';

const Component = () => {
    const { scanner, setScanner, tasksPending } = OnboardingContext();

    const showScannerResults = async () => {
        try {
            if (scanner.results.length < 1) return false; // Nothing to show off.

            const groups = [
                {
                    label: `Found by scanning your domains`,
                    entries: scanner.results,
                },
            ];

            const content = (
                <React.Fragment>
                    <div className="toolsAdded" style={{ width: '100%' }}>
                        <b style={{ marginBottom: 16, display: 'block' }}>New third party tools added:</b>
                        {groups.map((group, gix) => (
                            <div style={{ width: '100%', width: 380, marginBottom: groups.length > 1 ? 8 : 0 }} key={gix}>
                                {groups.length > 1 && (
                                    <div className="heading" style={{ width: '100%', textAlign: 'left', marginBottom: 6 }}>
                                        {group.label}
                                    </div>
                                )}
                                <ul style={{ textAlign: 'left', paddingLeft: 30, marginTop: 6, marginBottom: 6 }}>
                                    {group.entries.map((c, index) => (
                                        <li key={index}>{c.label}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        <div className="info-block">
                            These tools have been found. Please add them to the inventory if they are used and personal data is processed.
                        </div>
                    </div>
                </React.Fragment>
            );

            window.showAlert(`Success`, content, `info`, [
                {
                    text: 'OK',
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                    },
                },
            ]);
        } catch (err) {
            logError(`onboarding.showScannerResults`, err);
        }
    };

    useEffect(() => {
        if (scanner.lastScannedAt !== null && scanner.reviewed === false) {
            showScannerResults();

            // Mark that is has been reviewed.
            setScanner((curr) => ({ ...curr, reviewed: true }));
        }

        // eslint-disable-next-line
    }, [scanner]);

    return (
        <React.Fragment>
            <Header />
            <div className="storage-locations module-content">
                <div className="sect-component">
                    <StorageLocations />
                    <Buttons />

                    {/* Toast */}
                    {tasksPending.scanningWebsites && <Toast />}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
