import React, { createContext, useContext, useRef, useState } from 'react';

// Components
import ButtonCTA from './components/button';
import Panel from './components/panel';

// Dependencies
import { logError, trackUserInteraction } from '../../../../../../../../utils/helpers';
import {
    createCustomStorageLocationInDataInventory,
    createRecommendedStorageLocationsInBulk,
    createStorageLocationsInDataInventory,
    formatCreatedToolName,
} from '../../../../../../thirdParties/components/storageLocations/utils/functions';

// Context
import { PanelContext } from '../../../..';
import { mapToolLabelsToDataInventory } from '../utils/functions';
import { makeEndpointRequest, makeLocalEndpointRequest } from '../../../../../../../../utils/endpoints';
import { AuthStore } from '../../../../../../../../utils/context/authStore';

// Context
const Context = createContext({});
export const AddToolContext = () => useContext(Context);

const Component = () => {
    const { data, setData, companyData, vitalRecommendations, dataInventory, setDataInventory } = PanelContext();
    const { setPanelVisible } = PanelContext();
    const [addingTool, setAddingTool] = useState(false);

    const [pickingTools, setPickingTools] = useState(false);
    const panelController = useRef(null);
    const { globalFlags } = AuthStore();

    const createTools = async (toolLabels) => {
        try {
            // If flag is enabled we use the api
            if (globalFlags.gelDecoupling) {
                // Iterate and create
                for (const [index, label] of toolLabels.entries()) {
                    // Check if the current label is the last one
                    const isLastLabel = index === toolLabels.length - 1 ? true : false;

                    // Make the API Request
                    const response = await makeLocalEndpointRequest(`/api/v1/storageLocations/pickToolFromRecommendations`, {
                        label,
                        options: {
                            syncToSession: isLastLabel, // Set syncToSession to true only for the last label
                            updateCookieBanner: isLastLabel,
                            responseType: 'inventory',
                        },
                    });

                    if (isLastLabel) {
                        // Update data inventory
                        setDataInventory(response);

                        return response;
                    }
                }
            } else {
                // Prepare the objects we need to go ahead and create them in the API
                const { tools, processesDependencies } = await createRecommendedStorageLocationsInBulk({
                    toolLabels,
                    dataInventory,
                    companyData,
                    vitalRecommendations,
                });

                // Create the tools in our inventory database
                const { dataInventoryUpdated } = await createStorageLocationsInDataInventory(tools, processesDependencies);

                // Update data inventory for future.
                setDataInventory(dataInventoryUpdated);

                return dataInventoryUpdated;
            }
        } catch (err) {
            await logError(`module.processes.panel.addTool.createTools`, err, { toolLabels });
        }
    };

    /**
     * This will get the recommendations for an existing tool.
     * Example: Add "Absence.io" (it must already exist) to a process called "Timesheet" (something..)
     * @param {*} tool
     * @param {*} dbUpdated
     * @returns
     */

    const getIndividualRecommendationsForProcessAndTool = async (tool, dbUpdated) => {
        try {
            // Get the specific recommendations.
            let specificRecommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: [tool.label],
                processes: [],
            });

            let individuals = [];

            // Get if there is one for this specific process name.
            const rec = specificRecommendations.processes.find((c) => c.label === data.label);

            // There is no recommendation.
            if (!rec) return [];

            // We now try to map them to existing entries (if they exist)
            rec.subjectCategories.forEach((individualLabel) => {
                // Does this individual exist?
                const individual = dbUpdated.individuals.find((c) => c.label === individualLabel);
                if (!individual) return;

                let elements = [];

                [...rec.elementCategories, ...rec.specialCategoryElements].forEach((elementLabel) => {
                    // Does this individual exist?
                    const element = dbUpdated.elements.find((c) => c.label === elementLabel);
                    if (!element) return;

                    // eslint-disable-next-line
                    elements.push(element._id);
                });

                // Add recommendation
                individuals.push({
                    _individualId: individual._id,
                    elements,
                });
            });

            return individuals;
        } catch (err) {
            await logError(`module.processes.panel.addTool.getRecommendationsForTool`, err);
            return [];
        }
    };

    const addTool = async (label, dbInventory) => {
        try {
            // Get the storage location added
            const storageLocation = dbInventory.storageLocations.find((c) => c.label === label);
            if (!storageLocation) return false;

            // If tool is in data inventory and is already part of this process we skip it.
            if (data.tools.find((c) => c._storageLocationId === storageLocation._id)) return;

            // When we just created a tool, it may already have some recommendations. We need to display those or we'll overwrite them.
            // Also when we add a tool and we have specific recommendations (and the elements, individuals already exist) we add them in here.
            const individuals = await getIndividualRecommendationsForProcessAndTool(storageLocation, dbInventory);

            setData((currentState) => {
                let newState = { ...currentState };

                // Add it..
                newState.tools.push({
                    _storageLocationId: storageLocation._id,
                    individuals,
                });

                return newState;
            });
        } catch (err) {
            await logError(`module.processes.panel.addTool`, err);
        }
    };

    const onToolsPicked = async (toolLabels) => {
        try {
            setAddingTool(true);

            // Map the tool labels picked to our data inventory
            let tools = mapToolLabelsToDataInventory(toolLabels, dataInventory.storageLocations);

            // The data inventory that we will use..
            let dataInventoryUsed = dataInventory;

            // Check if we need to create tools
            const creatingTools = tools.filter((c) => !c.data).map((c) => c.label);

            if (creatingTools.length > 0) {
                // Create the tools that weren't existing yet.
                dataInventoryUsed = await createTools(tools.filter((c) => !c.data).map((c) => c.label));
            }

            // Added tools..
            trackUserInteraction(`Added tools to process`, { tools: tools.map((c) => c.label) });

            // Add all the tools
            for (const tool of tools) {
                await addTool(tool.label, dataInventoryUsed);
            }

            // Set adding to false
            setAddingTool(false);
        } catch (err) {
            await logError(`module.processes.panel.onToolsPicked`, err);
            setAddingTool(false);
            return false;
        }
    };

    const onCustomToolCreated = async (customTool) => {
        try {
            setAddingTool(true);

            if (globalFlags.gelDecoupling) {
                // Format the label accordingly.
                let label = formatCreatedToolName(customTool.label, dataInventory.storageLocations);

                // Make the API Request
                const response = await makeLocalEndpointRequest(`/api/v1/storageLocations/createCustomTool`, {
                    label,
                    similarTo: customTool.similarData ? customTool.similarData.label : null,
                    options: {
                        syncToSession: true,
                        responseType: 'inventory',
                    },
                });

                // Add it to tools
                addTool(label, response);

                // Update data inventory
                setDataInventory(response);

                // Track..
                trackUserInteraction(`Created Custom Tool`, { label });
            } else {
                // Create it..
                const { dataInventoryUpdated, label } = await createCustomStorageLocationInDataInventory({
                    customTool,
                    dataInventory,
                    companyData,
                    vitalRecommendations,
                });

                // Add it to tools
                addTool(label, dataInventoryUpdated);

                // Update data inventory for future.
                setDataInventory(dataInventoryUpdated);

                // Track..
                trackUserInteraction(`Created Custom Tool`, { label });
            }

            // Set adding tool false..
            setAddingTool(false);
        } catch (err) {
            await logError(`module.processes.panel.onCustomToolCreated`, err, { customTool });

            // Set to false
            setAddingTool(false);
        }
    };

    const onPanelClose = () => {
        setPickingTools(false);
        setPanelVisible(true);
    };

    const openPanel = () => {
        setPickingTools(true);
        setPanelVisible(false); // Hide current edit processor panel
    };

    const PassedProps = {
        // Panel
        setPickingTools,
        pickingTools,
        panelController,
        // Callbacks
        onToolsPicked,
        onCustomToolCreated,
        // Closing and opening
        openPanel,
        onPanelClose,
        addingTool,
    };

    return (
        <Context.Provider value={PassedProps}>
            <ButtonCTA />
            <Panel />
        </Context.Provider>
    );
};

export default Component;
