import React from 'react';

// Components
import { FormHelperText, MenuItem, Select } from '@mui/material';

// Context
import { PanelContext } from '../../../';
import { ViewContext } from '..';

const Component = () => {
    const { validationResults } = ViewContext();
    const { data, setData } = PanelContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Are any of your {data.label || 'individuals'} under the age of 16?</div>
                </div>
                <div className="component-description">
                    <div className="text">The GDPR gives special protection to the data of individuals under the age of 16.</div>
                </div>
                <div className="component-content">
                    <Select
                        value={data.individualReferring.isChildren === null ? 'none' : data.individualReferring.isChildren}
                        fullWidth={true}
                        data-cy="selector-isChildren"
                        onChange={(ev) =>
                            setData({
                                ...data,
                                individualReferring: {
                                    ...data.individualReferring,
                                    isChildren: ev.target.value,
                                },
                            })
                        }
                    >
                        {data.individualReferring.isChildren === null && (
                            <MenuItem value="none" disabled={true}>
                                Select an option
                            </MenuItem>
                        )}
                        {[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                        ].map((c) => (
                            <MenuItem key={c.value} value={c.value}>
                                {c.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {validationResults[`individualReferring.isChildren`] !== undefined && (
                        <FormHelperText error={true}>{validationResults['individualReferring.isChildren']}</FormHelperText>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
