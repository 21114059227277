import React, { createContext, useContext, useEffect, useState } from 'react';

// Components
import Container from '../../layout/container';
import Header from './components/header';
import SubHeader from './components/subHeader';
import Entries from './components/entries';
import Entry from './components/entry';
import Functions from './components/entry/components/functions';

// Utils
import API from './utils/api';

// Dependencies
import { checkUserFlag, logError, trackUserInteraction, useStateRef } from '../../../utils/helpers';
import { makeEndpointRequest, makeLocalEndpointRequest } from '../../../utils/endpoints';
import { CircularProgress } from '@mui/material';
import moment from 'moment';

// Context
const Context = createContext({});
export const ModuleState = () => useContext(Context);

const Component = () => {
    const [data, setData, dataRef] = useStateRef(null);
    const [loading, setLoading] = useState(true);
    const [companyData, setCompanyData] = useState(null);
    const [panelData, setPanelData] = useState({ _id: null, method: null });
    const [toolsRecommended, setToolsRecommended] = useState([]);
    const [vitalRecommendations, setVitalRecommendations] = useState([]);

    const [options, setOptions] = useState({
        filters: [],
    });

    const loadData = async () => {
        try {
            setLoading(true);

            // Get data inventory
            const res = await makeEndpointRequest(`GetDataInventory`, {
                onlyPublishedStorageLocations: false,
                bundleStorageLocations: false,
            });

            setData(res);
            setLoading(false);
            trackUserInteraction(`Individuals`);
        } catch (err) {
            await logError(`individuals.loadData`, err);
            setLoading(false);
            setData(null);
            trackUserInteraction(`Having Difficulties`, { reason: 'Failed to load individuals' });
        }
    };

    const refreshDataInventory = async () => {
        try {
            // Get data inventory
            const res = await makeEndpointRequest(`GetDataInventory`, {
                onlyPublishedStorageLocations: false,
                bundleStorageLocations: false,
            });

            setData(res);

            return res;
        } catch (err) {
            await logError(`module.individuals.refreshDataInventory`, err);
            throw err;
        }
    };

    const refreshCompanyData = async () => {
        try {
            // Replace this with a await endpoint request.
            const res = await makeEndpointRequest(`GetCompanyData`);
            setCompanyData(res);
            return res;
        } catch (err) {
            await logError(`module.individuals.refreshCompanyData`, err);
            throw err;
        }
    };

    const downloadRopa = async () => {
        try {
            // Check the v2 flag
            const v2Flag = await checkUserFlag('ropa-v2', false);

            await trackUserInteraction(`Selected "Download ROPA"`, {
                v2FlagEnabled: v2Flag,
            });

            // download it..
            const res = await makeLocalEndpointRequest(`/api/documents/ropa?v2=${v2Flag}`, {}, 'GET', null, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            const fileName = `ROPA - ${companyData.companyLegalName} - ${moment().format('DD-MM-yyyy')}.csv`;
            link.setAttribute('download', fileName); // set the file name
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            await logError(`DOWNLOAD_ROPA`, err);
            trackUserInteraction(`Having Difficulties`, { reason: `failed to download ropa` });
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    useEffect(() => {
        // Load data..
        loadData();

        // eslint-disable-next-line
    }, []);

    const ContextProps = {
        // Data
        data,
        setData,
        dataRef,
        // Functions for Data
        refreshDataInventory,
        // Company Data
        companyData,
        setCompanyData,
        // Options
        options,
        setOptions,
        // Panel Data
        panelData,
        setPanelData,
        // Recommended
        toolsRecommended,
        setToolsRecommended,
        // Vital Recommendations
        vitalRecommendations,
        setVitalRecommendations,
        // Others
        downloadRopa,
        refreshCompanyData,
    };

    // If we are loading the data
    if (loading === true && !data) {
        return (
            <Container title={`Individuals - Loading..`} classNames="page-module page-individuals">
                <div className="--view-information">
                    <div className="icon">
                        <CircularProgress size={25} />
                    </div>
                    <div className="heading">Individuals</div>
                    <div className="message">Please wait a moment while we retrieve your data.</div>
                </div>
            </Container>
        );
    }

    // If we failed to load the data
    if (loading === false && !data) {
        return (
            <Container title={`Individuals - Having Difficulties`} classNames="page-module page-individuals">
                <div className="--view-information">
                    <div className="icon">
                        <i className="elm fa-light fa-circle-exclamation"></i>
                    </div>
                    <div className="heading">Having Difficulties</div>
                    <div className="message">
                        We're experiencing technical difficulties while retrieving your data. Please try again later.
                    </div>
                </div>
            </Container>
        );
    }

    return (
        <Context.Provider value={ContextProps}>
            <Container title={`Individuals`} classNames="page-module page-individuals" isAlternateBackground={true}>
                <Header />
                <SubHeader />
                <Entries />

                {/* Edit Panel */}
                <Functions>
                    <Entry />
                </Functions>

                {/* Utils */}
                <API />
            </Container>
        </Context.Provider>
    );
};

export default Component;
