import React from 'react';

// Context
import { ModuleState } from '../..';

// Components
import GroupTools from './components/groupTools';
import EditTools from './components/editTools';
import PickTools from './components/pickTools';

// Dependencies
import { getFilteredStorageLocations, groupStorageLocations } from './utils/functions';
import { getFilterFields } from '../filters/functions';

const Component = () => {
    const { data, options, dataProcessingAgreements, toolsFound } = ModuleState();

    // Get the filters..
    const filterFields = getFilterFields(data);

    // This will get us the storage locations filtered.
    const storageLocations = getFilteredStorageLocations(data, options.filters, filterFields, dataProcessingAgreements);

    // Get the groups
    const groups = groupStorageLocations(storageLocations, options);

    return (
        <React.Fragment>
            <div className="component-storageLocations-groups">
                {groups.length > 0 && (
                    <React.Fragment>
                        {toolsFound.length > 0 && <GroupTools id={`newTools`} storageLocations={toolsFound} />}
                        {groups.map((c, ix) => (
                            <GroupTools id={c.id} storageLocations={c.entries} key={ix} />
                        ))}
                    </React.Fragment>
                )}
                {groups.length < 1 && (
                    <React.Fragment>
                        <div className="component-no-groups">
                            <div className="icon">
                                <i className="elm fa-solid fa-triangle-exclamation"></i>
                            </div>
                            {options.filters.length < 1 ? (
                                <React.Fragment>
                                    <div className="heading">No Tools Found</div>
                                    <div className="message">We couldn't find any tools in your data inventory.</div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="heading">No Tools Matching</div>
                                    <div className="message">
                                        We couldn't find any tools in your data inventory that match your criteria.
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                )}
                <EditTools />
                <PickTools />
            </div>
        </React.Fragment>
    );
};

export default Component;
