import React from 'react';

// Components
import { Button, CircularProgress } from '@mui/material';
import Tooltip from '../../../../../components/tooltip';

// Context
import { OnboardingFunctions } from '../../../components/functions';

// Dependencies
import { logError } from '../../../../../../utils/helpers';
import { OnboardingContext } from '../../..';

const Component = () => {
    const { navigateViews } = OnboardingFunctions();
    const { dataInventory, tasksPending } = OnboardingContext();

    const nextStep = async () => {
        try {
            // Move.
            navigateViews('forward');
        } catch (err) {
            await logError(`onboarding.dataInventory.nextStep`, err);
        }
    };

    const previousStep = async () => {
        try {
            // Move.
            navigateViews('backward');
        } catch (err) {
            await logError(`onboarding.dataInventory.previousStep`, err);
        }
    };

    const getNextButtonContent = () => {
        if (tasksPending.addingScannedTools || tasksPending.scanningWebsites) {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress size={16} style={{ marginRight: 8 }} />
                    <span>{tasksPending.addingScannedTools ? 'Updating data' : 'Still Scanning'}</span>
                </div>
            );
        }

        return <React.Fragment>Next</React.Fragment>;
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">Data Inventory</div>
                    <div className="subtitle">
                        Add the tools and third parties that you use in your company
                        <Tooltip
                            useHTML={true}
                            trackInteraction={true}
                            interactionId="gdpr_essentials:add-tools-and-third-parties"
                            leftAligned={true}
                            content={
                                <p>
                                    A "tool or a third party" is software or services used in your business which contain Personal Data.
                                    <br />
                                    <br />
                                    This includes data about customers, employees, contactors or any other person).
                                    <br />
                                    <br />
                                    For example, you use Hubspot to store your customer contact information. Or you use Google Drive to keep
                                    your Employees' passports. This includes data about customers, employees, contactors or any other
                                    person).
                                </p>
                            }
                        >
                            <span className="tooltip">
                                <i data-cy="data-inventory-tooltip" className="tooltip-icon fa-solid fa-circle-info"></i>
                            </span>
                        </Tooltip>
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={nextStep}
                        disabled={
                            dataInventory.storageLocations.length < 1 || tasksPending.scanningWebsites || tasksPending.addingScannedTools
                        }
                        data-cy="next-button"
                    >
                        {getNextButtonContent()}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
