import React, { useEffect, useState } from 'react';
import { trackUserInteraction } from '../../../../../../../../utils/helpers';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const Component = (props) => {
    const [pickingColor, setPickingColor] = useState(false);
    const [value, setValue] = useState(props.data);

    const onColorChanged = (color) => {
        // Checking that the hex entered by them is valid (It happens if they type manually wrong)
        var RegExp = /^#[0-9A-F]{6}$/i;
        const check = RegExp.test(color);
        if (!check) return false;

        // Updating it
        setValue(color.replace('#', ''));
        props.onChange(color.replace('#', ''));
    };

    useEffect(() => {
        if (JSON.stringify(props.data) !== JSON.stringify(value)) {
            setValue(props.data);
        }
        // eslint-disable-next-line
    }, [props.data]);

    return (
        <React.Fragment>
            <div className="color-picker-wrapper">
                <div
                    className="element"
                    style={{
                        backgroundColor: `#${props.data}`,
                    }}
                    onClick={() => {
                        if (props.disabled) return false;
                        if (pickingColor === false) {
                            trackUserInteraction(`Selected "Color Picker`, { label: props.label });
                            setPickingColor(true);
                        }
                    }}
                >
                    {pickingColor && (
                        <ClickAwayListener
                            onClickAway={() => {
                                setPickingColor(false);
                            }}
                        >
                            <div className="color-popper">
                                <HexColorPicker color={value} onChange={onColorChanged} />
                                <HexColorInput color={value} onChange={onColorChanged} className="input-color" />
                            </div>
                        </ClickAwayListener>
                    )}
                </div>
                <div className="label">{props.label}</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
