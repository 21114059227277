import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@mui/material';
import { logError, trackUserInteraction } from '../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../utils/endpoints';
import { PageContext } from '..';

const Component = () => {
    const { loadData } = PageContext();
    const [submitted, setSubmitted] = useState(false);
    const [visible, setVisible] = useState(false);
    const [fields, setFields] = useState({});

    const onEventOfButton = () => {
        trackUserInteraction(`Selected "Create Workspace"`);
        setVisible(true);
        setFields({
            name: '',
        });
    };

    const dismissModal = () => {
        trackUserInteraction(`Selected "Dismiss Modal"`);
        setVisible(false);
    };

    const onSubmit = async () => {
        try {
            setSubmitted(true);
            await makeEndpointRequest(`CreateWorkspaceV2`, { name: fields.name });
            setSubmitted(false);
            await loadData();
            setVisible(false);
            await trackUserInteraction(`Created new workspace`, fields);
        } catch (err) {
            trackUserInteraction(`Having Difficulties`, { reason: `failed to create new workspace` });
            await logError(`CREATE_WORKSPACE`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
            setSubmitted(false);
        }
    };

    useEffect(() => {
        document.addEventListener('workspaces:CreateWorkspace', onEventOfButton);

        return () => {
            document.removeEventListener('workspaces:CreateWorkspace', onEventOfButton);
        };
    }, []);

    if (visible === false) return null;

    return (
        <React.Fragment>
            <Dialog open={true} maxWidth="xs" fullWidth>
                <DialogTitle data-cy="dialog-title">Create Workspace</DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <Input
                        placeholder="Name"
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, name: e.target.value })}
                        fullWidth
                        data-cy="dialog-input"
                        disabled={submitted}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={dismissModal} variant="outlined" data-cy="dialog-dismiss-button">
                        Dismiss
                    </Button>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        disabled={fields.name.length < 1 || submitted}
                        data-cy="dialog-save-button"
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
