// Steps
import Welcome from '../steps/welcome';
import Audience from '../steps/audience/index';
import Industry from '../steps/industry/index';
import CompanyInsights from '../steps/companyInsights/index';
import StorageLocations from '../steps/storageLocations/index';
import LoadingScreen from '../steps/loading/index';
import IdentifyElements from '../steps/identifyElements/index';
import DescribeCompany from '../steps/describeCompany';
import Services from '../steps/services';
import ReviewData from '../steps/reviewData/index';
import IdentifySensitive from '../steps/identifySensitive/index';
import IdentifyVulnerable from '../steps/identifyVulnerable/index';
import LegalResponsibility from '../steps/legalResponsibility/index';
import Complete from '../steps/complete';
import Individuals from '../steps/individuals';
import Children from '../steps/children';

export const mappedSteps = {
    Welcome,
    Services,
    Audience,
    Industry,
    CompanyInsights,
    DescribeCompany,
    StorageLocations,
    LoadingScreen,
    IdentifyElements,
    Individuals,
    IdentifySensitive,
    Children,
    IdentifyVulnerable,
    ReviewData,
    LegalResponsibility,
    Complete,
};

export const mapSlugsToSteps = {
    start: 'Welcome',
    audience: 'Audience',
    'describe-company': 'DescribeCompany',
    industry: 'Industry',
    'company-insights': 'CompanyInsights',
    'data-inventory': 'StorageLocations',
    'personal-data': 'IdentifyElements',
    'sensitive-data': 'IdentifySensitive',
    'vulnerable-individuals': 'IdentifyVulnerable',
    'review-tools': 'ReviewData',
    'legal-responsibility': 'LegalResponsibility',
    activities: 'Services',
    complete: 'Complete',
    individuals: 'Individuals',
    children: 'Children',
};
