import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from 'react-tippy';
import { trackUserInteraction } from '../../utils/helpers';

const Component = (props) => {
    const [trackedInteraction, setTrackedInteraction] = useState(false);
    const trackedRef = useRef(false);
    const layoutTooltipContainerClass = props.leftAligned ? `layout-tooltip-container left-aligned` : `layout-tooltip-container`;
    useEffect(() => {
        trackedRef.current = trackedInteraction;
    }, [trackedInteraction]);

    const trackAmplitude = async () => {
        if (trackedRef.current === true) return false; // So we don't spam us.
        if (!(props.trackInteraction === true && props.interactionId !== undefined)) return false;
        if (account) {
            await trackUserInteraction('Tooltip Hovered', { id: props.interactionId });
        }
        setTrackedInteraction(true);
    };

    return (
        <Tooltip
            title={props.useHTML ? undefined : props.content}
            html={props.useHTML ? <div className={layoutTooltipContainerClass}>{props.content}</div> : undefined}
            position={props.position || 'bottom'}
            arrow={props.arrow !== undefined ? props.arrow : true}
            onShow={trackAmplitude}
            interactive={props.contentSelectable}
            trigger={props.trigger}
            animation="scale"
            className="layout-tooltip general"
        >
            {props.children}
        </Tooltip>
    );
};

export default Component;
