import { Button } from '@mui/material';
import React from 'react';
import { ViewContext } from '..';

const Component = () => {
    const { validationKeys, submitted, submitData } = ViewContext();

    return (
        <React.Fragment>
            <div className="section-submit">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={submitData}
                    disabled={Object.keys(validationKeys).length > 0 || submitted}
                >
                    Generate my privacy and cookie policies
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Component;
