import React from 'react';

// Components
import { Button } from '@mui/material';
import { PanelContext } from '../../..';

const Component = () => {
    const { data, updateData } = PanelContext();

    const setArchiveStatus = (archiving) => {
        window.showAlert(
            'Confirmation',
            archiving === false
                ? `Are you sure you want to unarchive ${data.label}?\nThis tool or third party will show up in your inventory and policies.`
                : `Are you sure you want to archive ${data.label}?\nThis tool or third party won't show up on your inventory or policies. This can be reversed later.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        updateData({ archived: archiving });
                    },
                },
            ],
        );
    };

    return (
        <React.Fragment>
            <div className="component-section" style={{ maxWidth: 800 }}>
                <h4>Archive</h4>

                {!data.archived ? (
                    <React.Fragment>
                        <p style={{ fontSize: 14, color: 'rgba(255,255,255,0.8)' }}>
                            Archiving this tool ensures it won't be mentioned in your policies. This way, only actively used tools are
                            featured in your policies. If the tool is currently not used but might be needed in the future, archiving is a
                            practical choice to maintain a concise and relevant policy. Remember, archiving is reversible, allowing you to
                            reintroduce the tool as needed.
                        </p>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <p style={{ fontSize: 14, color: 'rgba(255,255,255,0.8)' }}>
                            Unarchiving this tool reintroduces it into your policies, making sure it's mentioned. This straightforward
                            action enhances transparency and contributes to keeping your policy up-to-date, providing a clear understanding
                            of how you're currently using the data.
                        </p>
                    </React.Fragment>
                )}
                <Button
                    data-cy="btn-archive"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 16 }}
                    onClick={() => setArchiveStatus(!data.archived)}
                >
                    {!data.archived ? ' Archive this Tool' : 'Restore it from Archive'}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Component;
