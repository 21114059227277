import React, { useState } from 'react';
import { Button, MenuItem, Select } from '@mui/material';
import { ComponentState } from '../../../../..';
import { logError } from '../../../../../../../../utils/helpers';
import { v4 as uuid } from 'uuid';

// Components
import Entry from './components/entry';
import availableLanguages from '../../../../../../../../dummy_data/availableLanguages.json';

const Component = (props) => {
    const { loading, data, saveContentBlocks, saveDisabled, validationKeys, updateData, deleteTranslationField } = ComponentState();
    const [lang, setLang] = useState(`EN`);

    const entries = data.privacyPortalSettings.contentBlocks.filter((b) => b._pageId === props.pageId);

    const createBlock = async () => {
        try {
            const currentEntries = [...data.privacyPortalSettings.contentBlocks];
            currentEntries.push({
                _pageId: props.pageId,
                _blockId: uuid(),
            });
            updateData(`privacyPortalSettings.contentBlocks`, currentEntries);
        } catch (err) {
            await logError(`CREATE_BLOCK`, err);
        }
    };

    const onDelete = (_id) => {
        window.showAlert('Confirmation', `Are you sure you want to delete this custom clause?`, 'warning', [
            {
                text: 'Cancel',
                dataCy: `alert-cancel-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },
            {
                text: 'Confirm',
                dataCy: `alert-submit-button`,
                onClick: async ({ dismissAlert }) => {
                    let currentEntries = [...data.privacyPortalSettings.contentBlocks];
                    currentEntries = currentEntries.filter((e) => e._blockId !== _id);
                    updateData(`privacyPortalSettings.contentBlocks`, currentEntries);

                    // Deleting the customer translations..
                    deleteTranslationField(`privacyPortal`, `contentBlock:${_id}`);
                    dismissAlert();
                },
            },
        ]);
    };

    return (
        <React.Fragment>
            <div className="form-section dual content-blocks">
                <div className="left-side">Custom Privacy Policy Clauses</div>
                <div className="right-side">
                    <Button variant="outlined" onClick={createBlock} disabled={loading} style={{ marginRight: 16 }}>
                        Add Custom Clause
                    </Button>
                    <Select
                        disabled={loading}
                        size="sm"
                        className="language"
                        variant="outlined"
                        value={lang}
                        onChange={(e) => setLang(e.target.value)}
                        data-cy="customPrivacyCluases-translations-selector"
                    >
                        {availableLanguages
                            .filter((x) => data.languagesAvailable.includes(x.code) || x.code === 'EN')
                            .map((l, ix) => (
                                <MenuItem value={l.code} key={ix}>
                                    <div className="dropdown-menu-flags">
                                        <img className="flag" src={`/assets/images/flags/${l.code}.png`} />
                                        <span>{l.label}</span>
                                    </div>
                                </MenuItem>
                            ))}
                    </Select>
                </div>
            </div>
            {entries.length < 1 && (
                <div className="no-entries">
                    <div className="heading">Add Custom Clause</div>
                    <div className="message">Click on the button "Add Custom Close"</div>
                </div>
            )}
            <div className="content-blocks-entries">
                {entries.map((entry, ix) => (
                    <Entry lang={lang} data={entry} key={ix} onDelete={() => onDelete(entry._blockId)} />
                ))}
            </div>
            <div className="page-footer">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={saveContentBlocks}
                    disabled={
                        saveDisabled && Object.keys(validationKeys).length > 0 && !Object.keys(validationKeys).includes(`dataElements`)
                    }
                >
                    Save changes
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Component;
