import React, { useEffect, useState } from 'react';

import { Autocomplete, TextField, createFilterOptions, MenuItem, Button, Select } from '@mui/material';
import examplesDataCollected from '../../../../../../../dummy_data/examplesDataCollected.json';
import { normal as personalDataCategories } from '../../../../../../../dummy_data/dataCategories.json';

import Tooltip from '../../../../../../components/tooltip';
import { ComponentState } from '../../../..';
import { trackUserInteraction } from '../../../../../../../utils/helpers';
import sensitiveCatsExamples from '../../../../../../../dummy_data/examplesSensitiveDataCollected.json';

const InputGroup = (props) => {
    const [examples, setExamples] = useState(props.value && props.value.length > 0 ? props.value : []);

    useEffect(() => {
        if (examples.length < 1) {
            setExamples(examplesDataCollected[props.label] ? examplesDataCollected[props.label] : []);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.value && props.value.length > 0 && JSON.stringify(props.value) !== JSON.stringify(examples)) {
            setExamples(props.value);
        }
        // eslint-disable-next-line
    }, [props.value]);

    useEffect(() => {
        props.markValidationStatus(examples.length > 0 || props.isSensitive === true || props.label === 'Criminal Records');
        // eslint-disable-next-line
    }, [examples]);

    const filter = createFilterOptions();

    const getOptionsFiltered = () => {
        const opts = examplesDataCollected[props.label] ? examplesDataCollected[props.label] : [];
        const newOpts = opts.filter((opt) => (examples.includes(opt) ? false : true));
        return newOpts;
    };

    return (
        <React.Fragment>
            <Autocomplete
                multiple
                options={getOptionsFiltered()}
                className="input"
                freeSolo={true}
                disableClearable={true}
                onChange={(_, value) => {
                    const newValues = value.map((entry) => {
                        return entry.label ? entry.value : entry;
                    });
                    setExamples(newValues);
                    props.updateExamples(newValues);
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                        filtered.push({ label: `Add "${params.inputValue}"`, value: params.inputValue });
                    }
                    return filtered;
                }}
                isOptionEqualToValue={(option, value) => {
                    return option.label === value;
                }}
                getOptionLabel={(option) => (option.label ? option.label : option)}
                value={examples}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={examples.length < 1 && props.defaultExamples.length > 0}
                        helperText={examples.length < 1 && props.defaultExamples.length > 0 ? 'You must have at least one example.' : ''}
                        placeholder={
                            examples.length < 1
                                ? props.defaultExamples.length > 0
                                    ? `No examples`
                                    : `Write here your examples of data collected`
                                : ``
                        }
                    />
                )}
            />
        </React.Fragment>
    );
};

const Component = () => {
    const { data, setData, setChangesDetected, setValidationKeys, flags } = ComponentState();

    const [invalids, setInvalids] = useState({});
    const [previewEnabled, setPreviewEnabled] = useState(false);
    const [personalData, setPersonalData] = useState([]);
    const [sensitiveData, setSensitiveData] = useState([]);

    const updateExamples = (_id, newExamples) => {
        setData((currState) => {
            let src = [...currState.dataElements];

            const indexOf = src.findIndex((x) => x._id === _id);

            src[indexOf].dataExamples = newExamples;

            return {
                ...currState,
                dataElements: src,
            };
        });
        setChangesDetected(true);
    };

    const markValidationStatus = (id, bool) => {
        setInvalids((currState) => {
            let manState = { ...currState };
            manState[id] = bool;
            return manState;
        });
    };

    useEffect(() => {
        setValidationKeys((currState) => {
            let newValidationKeys = Object.assign({}, currState);
            const existInvalids = Object.values(invalids).filter((x) => x === false).length > 0;
            if (!existInvalids) {
                if (newValidationKeys['dataElements']) {
                    delete newValidationKeys[`dataElements`];
                }
            } else {
                newValidationKeys['dataElements'] = true;
            }
            return newValidationKeys;
        });
        // eslint-disable-next-line
    }, [invalids]);

    useEffect(() => {
        const makeCategories = () => {
            let cats = { personal: [], sensitive: [] };
            data.dataElements.map((elm) => {
                if (elm.isSensitive) {
                    cats.sensitive.push(elm.elementName);
                } else {
                    cats.personal.push(elm.elementName);
                }
            });
            setPersonalData(cats.personal);
            setSensitiveData(cats.sensitive);
        };
        makeCategories();
        // eslint-disable-next-line
    }, []);

    const getDataExamples = (cat) => {
        let examples = examplesDataCollected[cat] ? examplesDataCollected[cat] : [];
        return examples;
    };

    const showCatWithoutExamples = (cat) => {
        const normalOnes = ['Criminal Records'];
        if (normalOnes.includes(cat) || !personalDataCategories.includes(cat)) return true;
        return false;
    };

    const getCategoryData = (cat) => {
        const match = data.dataElements.find((elm) => elm.elementName === cat);
        if (!match) return null;
        let values = match.dataExamples.join(', ');
        const examples = getDataExamples(cat);
        return values ? values : examples.join(', ');
    };

    const resetDefault = (id, cat) => {
        const examples = examplesDataCollected[cat] ? examplesDataCollected[cat] : [];
        updateExamples(id, examples);
        trackUserInteraction(`Selected "Reset to Default`, {
            cat,
        });
    };

    const getPersonalDataCategories = () => {
        let arr = [...personalDataCategories];
        let customOnes = [];

        data.dataElements.forEach((item) => {
            if (item.sensitive === false) return false;
            if (arr.includes(item.elementName)) return false;
            if (customOnes.includes(item.elementName)) return false;
            customOnes.push(item.elementName);
        });

        customOnes = customOnes.sort();

        return [...arr, ...customOnes];
    };

    const getListingOfEditCategories = () => {
        return getPersonalDataCategories()
            .map((name) => {
                let match = data.dataElements.find((x) => x.elementName === name);
                return match;
            })
            .filter((x) => x !== undefined); // maybe the user don't have them in his data.
    };

    return (
        <React.Fragment>
            <div className="form-section dual">
                <div className="left-side">Information we collect about you - Section</div>
                <div className="right-side">
                    <Tooltip
                        trackInteraction={true}
                        interactionId={`privacy-portal-settings:info-we-collect-about-you-language-switcher`}
                        content={`Custom example translations coming soon`}
                    >
                        <Select className="language-demo" variant="outlined" value={'EN'} disabled>
                            <MenuItem value={'EN'}>
                                <div className="dropdown-menu-flags">
                                    <img
                                        className="flag"
                                        src={`/assets/images/flags/EN.png`}
                                        // style={{ backgroundImage: `url("/assets/images/flags/${cc.code}.png")` }}
                                    />
                                    <span>English</span>
                                </div>
                            </MenuItem>
                        </Select>
                    </Tooltip>
                </div>
            </div>{' '}
            <div className="section-text" style={{ marginTop: 16, marginBottom: 16 }}>
                Use the inputs below to give examples of the types of data that you collect. These will appear on your privacy policy.
            </div>
            <div className="preview-container">
                <div className="title">Preview section</div>
                <div className="description">If you wish to see how your section will look like you can enable this preview</div>
                <div className={`content ${previewEnabled ? `enabled` : `disabled`}`}>
                    <div className="text-1">These are the categories of data we may process:</div>
                    <ul>
                        {getPersonalDataCategories().map((cat, index) =>
                            personalData.includes(cat) && (getCategoryData(cat) ? true : showCatWithoutExamples(cat) ? true : false) ? (
                                <React.Fragment key={index}>
                                    <li>
                                        {getCategoryData(cat) ? (
                                            <React.Fragment>
                                                <b>{cat}: </b> examples include {getCategoryData(cat)}.
                                            </React.Fragment>
                                        ) : (
                                            <b>{cat}</b>
                                        )}
                                    </li>
                                </React.Fragment>
                            ) : null,
                        )}
                        {(sensitiveData.length > 0 || flags.renderAllSpecialCategoryData) && (
                            <React.Fragment>
                                <li>
                                    <b>Special Category Data, examples include:</b>
                                    <ul>
                                        {Object.keys(sensitiveCatsExamples)
                                            .filter((x) => sensitiveData.includes(x) || flags.renderAllSpecialCategoryData)
                                            .map((key, ix) => (
                                                <li key={ix}>
                                                    {sensitiveCatsExamples[key]}
                                                    {getCategoryData(key) ? ` (for example: ${getCategoryData(key)})` : ``}
                                                </li>
                                            ))}
                                    </ul>
                                </li>
                            </React.Fragment>
                        )}
                        <li>
                            <b> Aggregated Data: </b>
                            examples include such as statistical or demographic data for any purpose. Aggregated Data could be derived from
                            your personal data but is not considered personal data in law as this data will not directly or indirectly
                            reveal your identity.
                            <ul>
                                <li>
                                    For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific
                                    website feature.
                                </li>
                            </ul>
                            However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly
                            identify you, we treat the combined data as personal data which will be used in accordance with this privacy
                            policy.
                        </li>
                    </ul>
                </div>
                <div className={`toggler ${previewEnabled ? `enabled` : `disabled`}`}>
                    <Button variant="contained" color="primary" onClick={() => setPreviewEnabled(!previewEnabled)}>
                        {previewEnabled ? `Stop preview of section` : `Preview this section`}
                    </Button>
                </div>
                <div className="description" style={{ marginTop: 16 }}>
                    * Remember to press the "Save changes" button at the bottom of this page or these changes won't be saved.
                </div>
            </div>
            <div className="section-group no-sections">
                {getListingOfEditCategories().map((entry, key) => (
                    <React.Fragment key={key}>
                        <div className="form-group">
                            <div className="form-header">
                                <div className="label">{entry.elementName}</div>
                                <div className="reset-button">
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        disabled={getDataExamples(entry.elementName).length < 1}
                                        onClick={() => resetDefault(entry._id, entry.elementName)}
                                    >
                                        Reset default
                                    </Button>
                                </div>
                            </div>
                            <InputGroup
                                label={entry.elementName}
                                value={entry.dataExamples}
                                isSensitive={entry.isSensitive}
                                defaultExamples={getDataExamples(entry.elementName)}
                                updateExamples={(values) => updateExamples(entry._id, values)}
                                markValidationStatus={(bool) => markValidationStatus(entry._id, bool)}
                            />
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </React.Fragment>
    );
};

export default Component;
