import React from 'react';
import { ComponentState } from '../../../index';
import { Switch } from '@mui/material';
import { trackUserInteraction } from '../../../../../../utils/helpers';

const Component = () => {
    const { data, loading, setData } = ComponentState();

    const onSwitch = async () => {
        trackUserInteraction(`Selected "Smart Cookie Blocker"`, {
            currentState: data.smartCookieBlocker,
        });

        // If they wanna activate..
        if (data.smartCookieBlocker === false) {
            window.showAlert(
                `Warning`,
                <React.Fragment>
                    Please remember to list any third party present on your website in your personal data inventory. <br />
                    <br />
                    Example: If you have a Youtube video please list Youtube in your personal data inventory so users can give their consent
                    and see the video.
                    <br />
                    <br />
                    Click{' '}
                    <a
                        href="https://privaseeio.notion.site/What-tools-are-blocked-by-Smart-Cookie-Blocker-73fced80e6d64b8bb6a843043339adab"
                        target="_blank"
                    >
                        here
                    </a>{' '}
                    to see what third parties will be blocked automatically and be sure they're in your inventory.
                </React.Fragment>,
                `warning`,
                [
                    {
                        text: 'Dismiss',
                        dataCy: `alert-skip-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            await trackUserInteraction(`Selected "Dismiss"`);
                        },
                    },
                    {
                        text: 'Confirm',
                        dataCy: `alert-fix-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            await trackUserInteraction(`Selected "Confirm"`);
                            setData({
                                ...data,
                                smartCookieBlocker: true,
                            });

                            setTimeout(() => {
                                window.showAlert(
                                    `Reminder`,
                                    `You have enabled the Smart Cookie Blocker. Once you press the "Save changes" button at the end of this page - Please remember to copy your updated code snippet from "Add to website" again. Don't worry your old code snippet is still functional.`,
                                    `info`,
                                    [
                                        {
                                            text: 'Understood',
                                            dataCy: 'alert-submit-button',
                                            onClick: async ({ dismissAlert }) => {
                                                dismissAlert();
                                            },
                                        },
                                    ],
                                );
                            }, 500);
                        },
                    },
                ],
            );
        } else {
            setData({
                ...data,
                smartCookieBlocker: false,
            });

            window.showAlert(
                `Reminder`,
                `You have disabled the Smart Cookie Blocker. Once you press the "Save changes" button at the end of this page - Remember to copy your updated code snippet from "Add to website" again.`,
                `info`,
                [
                    {
                        text: 'Understood',
                        dataCy: 'alert-submit-button',
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                ],
            );
        }
    };

    return (
        <React.Fragment>
            <div className="form-label">Smart Cookie Blocker</div>
            <div className="form-description-with-image">
                <div className="form-description">
                    <p>
                        Blocks a number of popular third-party cookies automatically. It activates the service once the user has given
                        consent.
                    </p>
                    <p>
                        This setting will ensure that cookies that are not on your cookie banner aren't placed on the users' device. Note
                        that this may not load embedded iframes/videos until the user has consented to the cookies placed by that service.
                    </p>
                    <p>
                        {' '}
                        Examples are embedded Calendly pages or YouTube videos. See all tools and third-parties supported{' '}
                        <a
                            href="https://privaseeio.notion.site/What-tools-are-blocked-by-Smart-Cookie-Blocker-73fced80e6d64b8bb6a843043339adab"
                            target="_blank"
                        >
                            here
                        </a>
                        .
                    </p>
                    <p>
                        This is an optional feature. You can still configure the cookie banner to block tools and third parties manually.
                        You will find instructions in the “Add to website” section.
                    </p>
                </div>
                <div
                    className="image scb"
                    style={{
                        backgroundImage: `url("/assets/images/page-cookie-banner/example_scb.png")`,
                    }}
                ></div>
            </div>
            <div className="form-content">
                <Switch
                    checked={data.smartCookieBlocker ? true : false}
                    color="primary"
                    onClick={onSwitch}
                    disabled={loading || !data.cookieBannerSettings.enabled}
                />
                {/* <div style={{ marginTop: 8 }}>
                    Click <a href="#">here</a> for more documentation and to learn what third-parties will be blocked automatically.
                </div> */}
            </div>
        </React.Fragment>
    );
};

export default Component;
