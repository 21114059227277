import React from 'react';

// Components
import { TextField } from '@mui/material';
import { getValidationPropFields } from '../../../../../../../../utils/helpers';

// Context
import { ViewContext } from '..';
import { PanelContext } from '../../..';

const Component = () => {
    const { data, setData } = PanelContext();
    const { validationResults } = ViewContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Tool or 3rd party name</div>
                </div>
                <div className="component-content">
                    <TextField
                        placeholder="Tool Name"
                        variant="standard"
                        value={data.label}
                        onChange={(ev) => setData({ ...data, label: ev.target.value })}
                        fullWidth={true}
                        data-cy={`input-label`}
                        {...getValidationPropFields(validationResults, true, `label`)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
