import React from 'react';
import { PanelContext } from '..';

import { ButtonBase } from '@mui/material';

const Component = () => {
    const { saveChanges, stopEditing, isToolNameAlreadyUsed } = PanelContext();

    return (
        <React.Fragment>
            <div className="panel-component-actions">
                <ButtonBase
                    className={`component-btn`}
                    disabled={isToolNameAlreadyUsed() ? true : false}
                    onClick={saveChanges}
                    data-cy="btn-save"
                >
                    <i className="elm fa-solid fa-floppy-disk"></i>
                </ButtonBase>
                <ButtonBase className="component-btn" onClick={stopEditing} data-cy="btn-close">
                    <i className="elm fa-solid fa-xmark"></i>
                </ButtonBase>
            </div>
        </React.Fragment>
    );
};

export default Component;
