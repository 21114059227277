import React from 'react';

// Context
import { OnboardingFunctions } from '../../../components/functions';

// Components
import { Button } from '@mui/material';
import { ViewContext } from '..';

const Component = () => {
    const { navigateViews } = OnboardingFunctions();
    const { domains, updateDomains, validationResults } = ViewContext();

    const nextStep = async () => {
        try {
            // Update domains
            await updateDomains();

            // Move forward
            navigateViews('forward');
        } catch (err) {
            await logError(`onboarding.welcome.nextStep`, err);
        }
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="title">
                        <div className="step-icon">
                            <i className="icon fa-solid fa-magnifying-glass"></i>
                        </div>
                        Setup your scanner
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={domains.length < 1 || Object.keys(validationResults).length > 0}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
