import React from 'react';

// Context
import { ViewContext } from '..';

const Component = () => {
    const { processChildrenData, setProcessChildrenData, setChildren } = ViewContext();

    const onProcessChange = (newValue) => {
        setProcessChildrenData(newValue);

        if (newValue === false) {
            setChildren([]);
        }
    };

    return (
        <React.Fragment>
            <div className="form-entry">
                <div className="label">Do you process data from anyone under the age of 16?</div>
                <div className="content">
                    <div className="yes-no-questions">
                        {[true, false].map((c, ix) => (
                            <div className={`entry ${processChildrenData === c && 'selected'}`} key={ix} onClick={() => onProcessChange(c)}>
                                {c === true ? 'Yes' : 'No'}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
