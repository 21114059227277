import React from 'react';
import { Button } from '@mui/material';
import { trackUserInteraction } from '../../../../../utils/helpers';
import { ModuleState } from '../../index';

export const types = [
    {
        label: `Self-funded / Bootstrapped`,
        value: `self-funded`,
        icon: `fa-solid fa-wallet`,
    },
    {
        label: `Investor Backed`,
        value: `investor-backed`,
        icon: `fa-solid fa-money-check-dollar`,
    },
];

export const sizes = ['1-10', '11-50', '51-150', '151-500', '500+'];

const CompanyInsights = () => {
    const { data, updateData, setStep } = ModuleState();

    const nextStep = async () => {
        let nextStep = `DescribeCompany`;

        setStep(nextStep);
        await trackUserInteraction(`Selected "Next"`, { destination: nextStep });
    };

    const goBack = async () => {
        setStep('Industry');
        await trackUserInteraction(`Selected "Go Back"`, { destination: `Industry` });
    };

    const selectType = async (entry) => {
        await trackUserInteraction(`Selected "Company Type"`, { size: entry.value });
        updateData(`companyInfo.companyInsights.type`, entry.value === data.companyInfo.companyInsights.type ? null : entry.value);
    };

    const selectSize = async (value) => {
        await trackUserInteraction(`Selected "Company Size"`, { size: value });
        updateData(`companyInfo.companyInsights.size`, value === data.companyInfo.companyInsights.size ? null : value);
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={goBack}>
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Tell us more about your company
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={data.companyInfo.companyInsights.size === null || data.companyInfo.companyInsights.type === null}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
            <div className="company-insights module-content">
                <div className="section-title">What kind of funding do you have?</div>
                <div className="entries types d-flex-row-columns centered">
                    {types.map((entry, index) => (
                        <React.Fragment key={index}>
                            <div
                                onClick={() => selectType(entry)}
                                className={data.companyInfo.companyInsights.type === entry.value ? `entry selected` : `entry`}
                                data-cy={`entry-type-${index}`}
                            >
                                <div className="label">{entry.label}</div>
                                <i className={`icon ${entry.icon}`}></i>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                <div className="divider-line"></div>
                <div className="section-title">How many people work at this company?</div>
                <div className="entries sizes d-flex-row-columns centered">
                    {sizes.map((entry, index) => (
                        <React.Fragment key={index}>
                            <div
                                onClick={() => selectSize(entry)}
                                className={data.companyInfo.companyInsights.size === entry ? `entry selected` : `entry`}
                                data-cy={`entry-size-${index}`}
                            >
                                <div className="label">{entry}</div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default CompanyInsights;
