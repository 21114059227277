import Information from './information';
import DetailedView from './detailedView';
import SubProcessor from './subProcessor';
import DangerZone from './dangerZone';

export default {
    information: Information,
    detailedView: DetailedView,
    subProcessor: SubProcessor,
    dangerZone: DangerZone,
};
