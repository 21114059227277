import * as Yup from 'yup';
import { websiteValidationScheme } from '../../gdprEssentials/utils/validations';

export const settingsGeneralSchema = Yup.object({
    companyAddress: Yup.object({
        line1: Yup.string().trim().label('This').required('This field is required.'),
        line2: Yup.string().trim().label('This'),
        city: Yup.string().trim().label('This').required('This field is required.'),
        postCode: Yup.string().trim().label('This').required('This field is required.'),
        country: Yup.string().trim().label('This').required('This field is required.'),
    }),
    companyLegalName: Yup.string().trim().label('This').required('This field is required.'),
    inquiriesEmail: Yup.string().trim().label(`This`).required(),
    dpoName: Yup.string().trim().label(`This`),
    companyDomains: Yup.array().of(websiteValidationScheme).min(1),
    industry: Yup.array().min(1).label('This'),
    targetAudience: Yup.array().min(1).label('This'),
    monitoringSettings: Yup.object({
        domains: Yup.array().of(websiteValidationScheme).min(1),
        emails: Yup.array().of(Yup.string().email().label('This')),
    }),
    companyInsights: Yup.object({
        size: Yup.string().label('This').required(),
        type: Yup.string().label('This').required(),
    }),
});
