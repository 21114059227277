import React, { createContext, useContext } from 'react';

// Component
import Role from './components/role';
import LegalBasis from './components/legalBasis';
import SensitiveLegalBasis from './components/sensitiveLegalBasis';

// Dependencies
import { logError } from '../../../../../../../utils/helpers';
import { OnboardingFunctions } from '../../../../components/functions';
import { ViewContext } from '../..';

// Context
const Context = createContext({});
export const ProcessContext = () => useContext(Context);

const Component = (props) => {
    const { updateProcess } = OnboardingFunctions();
    const { isProcessIncomplete, isCollectingSensitiveData } = ViewContext();

    const updateData = (payload) => {
        try {
            // If role is processor we must unset the sensitive and personal legal basis
            if (payload.role && payload.role === 'Processor') {
                payload.personalLegalBasis = null;
                payload.sensitiveLegalBasis = null;
            }

            // Update it.
            updateProcess(props.data._id, payload);
        } catch (err) {
            logError(`onboarding.updateData`, err);
        }
    };

    const PassedProps = {
        data: props.data,
        updateData,
        isCollectingSensitiveData: isCollectingSensitiveData(props.data),
        hasCompanySensitiveData: props.hasCompanySensitiveData,
    };

    return (
        <Context.Provider value={PassedProps}>
            <div className="entry">
                <div className="label">
                    {isProcessIncomplete(props.data) && <div className="badge badge-data-warning blue"></div>}
                    <div className="text">{props.data.label}</div>
                </div>
                <Role />
                <div className="legal-options">
                    <LegalBasis />
                    {props.hasCompanySensitiveData && <SensitiveLegalBasis />}
                </div>
            </div>
        </Context.Provider>
    );
};

export default Component;
