import React from 'react';
import ReactDOM from 'react-dom';

// Components
import PickPanel from '../../../../../../../thirdParties/components/pickPanel';

// Context
import { AddToolContext } from '..';
import { PanelContext } from '../../../../..';

const Component = () => {
    const { pickingTools, panelController, onToolsPicked, onCustomToolCreated, onPanelClose } = AddToolContext();
    const { dataInventory, toolsRecommendations } = PanelContext();

    // We are not creating tools.
    if (pickingTools === false) return null;

    // We need to render the panel there.
    const domNode = document.getElementById('panel-container');
    if (!domNode) return null;

    return ReactDOM.createPortal(
        <React.Fragment>
            <PickPanel
                //Important class name
                className="module-picker-locations"
                // Ref to control the panel
                innerRef={panelController}
                // Data Inventory is important to know what tools they have.
                dataInventory={dataInventory}
                // When they picked tools
                addTool={onToolsPicked}
                // Options the user can pick storage locations
                options={toolsRecommendations}
                // When tools are created
                addPickedTools={onToolsPicked}
                createCustomTool={onCustomToolCreated}
                // When they close the panel
                closePanel={onPanelClose}
                // View type will make sure to have a category called "from your data inventory"
                viewType="pickFromInventory"
            />
        </React.Fragment>,
        domNode,
    );
};

export default Component;
