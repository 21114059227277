import React, { useState } from 'react';
import lodash from 'lodash';

// Components
import { Button } from '@mui/material';
import { ComponentState } from '../..';
import { trackUserInteraction } from '../../../../../utils/helpers';

// UI components
import Picker from './components/picker/index';
import CreateModal from './components/createModal';
import EditPanel from './components/editPanel/index';

import EntryCard from './components/entryCard';

// Dependencies
import { sortSubProcessors } from './utils.js';

const Component = () => {
    const { draftData, updateDraftData, setRoute } = ComponentState();
    const [pickingTools, setPickingTools] = useState(false);
    const [editData, setEditData] = useState(null);

    const isNextDisabled = () => {
        if (draftData.subProcessors.length < 1) return true;
        if (invalidSubProcessors.length > 0) return true;
        return false;
    };

    const goBack = () => {
        setRoute('start');
        trackUserInteraction(`Selected "Go Back"`);
    };

    const onDone = () => {
        setRoute('start');
        trackUserInteraction(`Selected "Done"`, {});
    };

    const startPicking = () => {
        setPickingTools(true);
    };

    const createCustomLocation = async (label) => {
        setEditData({
            payload: {
                label,
                processes: [],
                image: '',
                dataResidency: '',
                url: '',
                origin: 'manual',
                securityMeasuresTitle: '',
                securityMeasuresURL: '',
            },
            creating: true,
            index: null,
        });
    };

    const editLocation = async (index) => {
        const location = draftData.subProcessors[index];
        if (!location) return false;
        if (editData !== null) return false;

        setEditData({
            payload: {
                ...location,
            },
            creating: false,
            index: index,
        });
    };

    const onCompletedEditing = () => {
        if (editData.index === null) {
            const newTool = editData.payload;
            const toolsFormatted = [...draftData.subProcessors, { ...newTool }];
            updateDraftData('subProcessors', toolsFormatted);
            // Finished..
            setEditData(null);
        } else {
            const toolsFormatted = [...draftData.subProcessors];
            lodash.set(toolsFormatted, `[${editData.index}]`, editData.payload);
            updateDraftData(`subProcessors`, toolsFormatted);
        }
    };

    const onDelete = () => {
        window.showAlert('Confirmation', `Are you sure you want to delete ${editData.payload.label}?`, 'warning', [
            {
                text: 'Cancel',
                dataCy: `alert-cancel-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },
            {
                text: 'Confirm',
                dataCy: `alert-submit-button`,
                onClick: async ({ dismissAlert }) => {
                    const toolsFormatted = [...draftData.subProcessors];
                    toolsFormatted.splice(editData.index, 1);
                    updateDraftData(`subProcessors`, toolsFormatted);
                    onDismissEditing();
                    dismissAlert();
                },
            },
        ]);
    };

    const onDismissEditing = () => {
        setEditData(null);
    };

    // Sorted
    const validatedSubProcessors = sortSubProcessors(draftData.subProcessors).valids;
    const invalidSubProcessors = sortSubProcessors(draftData.subProcessors).invalids;

    return (
        <React.Fragment>
            <div className="route-header">
                <div className="left-side">
                    <div className="button-left">
                        <Button variant="text" onClick={goBack}>
                            Go Back
                        </Button>
                    </div>
                    <div className="title">Your Sub-processors</div>
                    <div className="description">
                        Sub-processors are tools and third parties that you use or subcontract to directly offer your services to your
                        clients.
                    </div>
                </div>
                <div className="button-right">
                    <Button variant="contained" color="primary" disabled={isNextDisabled()} onClick={onDone}>
                        Done
                    </Button>
                </div>
            </div>
            <div className="route-content sub-processors">
                {invalidSubProcessors.length > 0 && (
                    <React.Fragment>
                        <div className="entries-label">More information needed ({invalidSubProcessors.length})</div>
                        <div className="entries-description">
                            We couldn't find recommendations for the following tools. Please click on each tool and fill in the missing
                            information.
                        </div>
                        <div className="entries mb">
                            {invalidSubProcessors.map((entry, sortedIndex) => (
                                <EntryCard key={sortedIndex} data={entry} invalid={true} onEdit={() => editLocation(entry.originalIndex)} />
                            ))}
                        </div>
                    </React.Fragment>
                )}
                {draftData.subProcessors.length > 0 && (
                    <React.Fragment>
                        <div className="entries-label">Your tools and third parties</div>
                        <div className="entries ">
                            <div className="entry create" data-cy="create-entry-button">
                                <div className="content" onClick={startPicking}>
                                    <i className="icon fa-solid fa-circle-plus"></i>
                                    <div className="label">Add Sub-Processor</div>
                                </div>
                            </div>
                            {validatedSubProcessors.map((entry, sortedIndex) => (
                                <EntryCard key={sortedIndex} data={entry} onEdit={() => editLocation(entry.originalIndex)} />
                            ))}
                        </div>
                    </React.Fragment>
                )}

                {draftData.subProcessors.length < 1 && (
                    <React.Fragment>
                        <div className="container-empty">
                            <div className="no-entries">
                                <div className="description">To begin adding sub-processors, please click the button below.</div>
                                <Button variant="contained" color="primary" onClick={startPicking}>
                                    Add a Sub-processor
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <Picker enabled={pickingTools} createCustomTool={createCustomLocation} setPickingTools={setPickingTools} />
                {editData !== null && editData.creating === true && (
                    <CreateModal editData={editData} setEditData={setEditData} onSubmit={onCompletedEditing} onDismiss={onDismissEditing} />
                )}
                {editData !== null && editData.creating === false && (
                    <EditPanel
                        editData={editData}
                        data={editData.payload}
                        setData={setEditData}
                        onSubmit={onCompletedEditing}
                        onDelete={onDelete}
                        onDismiss={onDismissEditing}
                    />
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
