import React from 'react';

// Components
import { MenuItem, Select } from '@mui/material';

// Context
import { ProcessContext } from '..';

// Dependencies
import { sensitiveLegalBasis } from '../../../../../../gdprEssentials/steps/legalResponsibility/utils/dataMaps';

const Component = () => {
    const { data, updateData, isCollectingSensitiveData } = ProcessContext();

    const isFieldDisabled = data.role === 'Processor' || !data.role || !isCollectingSensitiveData;

    const onValueChange = (e) => {
        const selection = sensitiveLegalBasis.find((p) => p.string === e.target.value);

        updateData({
            ...data,
            sensitiveLegalBasis: selection,
        });
    };

    return (
        <React.Fragment>
            <div className="sensitive-legal gdpr-legal">
                <div className="sub-label">Sensitive legal basis</div>
                <Select
                    fullWidth={true}
                    disabled={isFieldDisabled ? true : false}
                    value={data.sensitiveLegalBasis ? data.sensitiveLegalBasis.string : 'no-selection'}
                    onChange={onValueChange}
                >
                    <MenuItem value="no-selection" disabled>
                        {data.role === 'Processor' || !isCollectingSensitiveData ? `No need for selection` : `Select Option`}
                    </MenuItem>

                    {sensitiveLegalBasis.map((entry, ix) => (
                        <MenuItem value={entry.string} key={ix}>
                            {entry.string}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </React.Fragment>
    );
};

export default Component;
