import React from 'react';

// Components
import CreateCustomBlock from './components/createCustomBlock';
import Entry from './components/entry';
import NoSearchResults from './components/noResults';

// Context
import { PanelContext } from '../..';
import { filterOptions } from '../../utils/functions';

const Component = () => {
    const { category, options, searchValue } = PanelContext();

    const getOptions = () => filterOptions({ options, searchValue, category });

    const getCustomBlockPosition = () => {
        if (searchValue.length > 0 && getOptions().entries.length > 0) return 'bottom';
        if (searchValue.length > 0 && getOptions().entries.length < 1) return null; // we don't show it at all.
        if (category === 'all') return 'top';
        if (category !== 'all') return 'bottom';
    };

    return (
        <React.Fragment>
            <div className="scrollable-container">
                <div className="entries" data-cy="inventory-options">
                    {/* We show the custom block first when on first all. */}
                    {getCustomBlockPosition() === 'top' && <CreateCustomBlock />}

                    {/* Get the entries filtered. */}
                    {getOptions().entries.map((entry, ix) => (
                        <Entry data={entry} key={ix} />
                    ))}

                    {/* We show the custom block at depending on circumstances */}
                    {getCustomBlockPosition() == 'bottom' && <CreateCustomBlock />}

                    {/* If we are searching and we have similar tools */}
                    {searchValue.length > 0 && getOptions().similarEntries.length > 0 && (
                        <React.Fragment>
                            <div className="header-subsection">Other similar tools</div>
                            <div className="entries" data-cy="similar-tools-options">
                                {getOptions().similarEntries.map((entry, ix) => (
                                    <Entry data={entry} key={ix} />
                                ))}
                            </div>
                        </React.Fragment>
                    )}

                    {/* If there are no search results at all */}
                    {getOptions().entries.length < 1 && <NoSearchResults />}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
