import React from 'react';

// Context
import { OnboardingFunctions } from '../../../components/functions';

const Component = () => {
    const { navigateViews } = OnboardingFunctions();

    const previousStep = async () => {
        // Move.
        navigateViews('backward');
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">Congrats - you are nearly there!</div>

                    <div className="subtitle">Enter your company details to get your GDPR policies.</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
