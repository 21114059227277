import React, { useState } from 'react';

// Components
import RolesTooltip from './components/rolesTooltip';
import Tooltip from '../../../../../../components/tooltip';

const Component = (props) => {
    const [tooltipEnabled, setTooltipEnabled] = useState(false);

    return (
        <React.Fragment>
            <div className="entry field-hints desktop">
                <div className="label">Process Name</div>
                <div className="roles">
                    Legal Responsibility
                    <div className="tooltip-container-legal">
                        <div
                            className="action-area"
                            onMouseEnter={() => {
                                setTooltipEnabled(true);
                            }}
                        />
                        <i className="tooltip-icon fa-solid fa-circle-info"></i>
                    </div>
                </div>
                <div className="legal-options">
                    <div className="gdpr-legal">
                        Personal data legal justification
                        <Tooltip
                            trackInteraction={true}
                            interactionId={`onboarding:personal-data-legal-justification`}
                            content="To process personal data you must select a valid justification from the list below"
                        >
                            <i className="tooltip-icon fa-solid fa-circle-info"></i>
                        </Tooltip>
                    </div>
                    {props.hasCompanySensitiveData && (
                        <div className="gdpr-legal">
                            Sensitive data legal justification
                            <Tooltip
                                trackInteraction={true}
                                interactionId={`gdpr-essentials:sensitive-data-legal-justification`}
                                content="To process sensitive data you must select a valid justification from the list below"
                            >
                                <i className="tooltip-icon fa-solid fa-circle-info"></i>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
            {tooltipEnabled && <RolesTooltip setTooltipEnabled={setTooltipEnabled} />}
        </React.Fragment>
    );
};

export default Component;
