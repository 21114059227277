import React from 'react';

// Context
import { ViewContext } from '../../..';
import { getValidationPropFields } from '../../../../../../../../utils/helpers';
import { Autocomplete, FormHelperText, TextField } from '@mui/material';

// Dependencies
import AllCountries from '../../../../../../../../dummy_data/countries.json';

const Component = () => {
    const { submitted, form, updateData, validationKeys } = ViewContext();

    return (
        <React.Fragment>
            <div className="form-group col-2">
                <div className="label">Address Line 1</div>
                <div className="input">
                    <TextField
                        autoFocus
                        fullWidth
                        disabled={submitted}
                        variant="outlined"
                        placeholder="Line 1"
                        data-cy={`input-line1`}
                        value={form.companyAddress.line1}
                        onChange={(e) => updateData('companyAddress.line1', e.target.value)}
                        {...getValidationPropFields(validationKeys, form.companyAddress.line1, `companyAddress.line1`)}
                    />
                </div>
            </div>
            <div className="form-group col-2">
                <div className="label">Address Line 2</div>
                <div className="input">
                    <TextField
                        autoFocus
                        fullWidth
                        disabled={submitted}
                        variant="outlined"
                        placeholder="Line 2"
                        data-cy={`input-line2`}
                        value={form.companyAddress.line2}
                        onChange={(e) => updateData('companyAddress.line2', e.target.value)}
                        {...getValidationPropFields(validationKeys, form.companyAddress.line2, `companyAddress.line2`)}
                    />
                </div>
            </div>
            <div className="form-group col-2">
                <div className="label">City</div>
                <div className="input">
                    <TextField
                        autoFocus
                        fullWidth
                        disabled={submitted}
                        variant="outlined"
                        placeholder="City"
                        data-cy={`input-city`}
                        value={form.companyAddress.city}
                        onChange={(e) => updateData('companyAddress.city', e.target.value)}
                        {...getValidationPropFields(validationKeys, form.companyAddress.city, `companyAddress.city`)}
                    />
                </div>
            </div>
            <div className="form-group col-2">
                <div className="label">Postcode</div>
                <div className="input">
                    <TextField
                        autoFocus
                        fullWidth
                        disabled={submitted}
                        variant="outlined"
                        placeholder="Postcode"
                        data-cy={`input-postcode`}
                        value={form.companyAddress.postCode}
                        onChange={(e) => updateData('companyAddress.postCode', e.target.value)}
                        {...getValidationPropFields(validationKeys, form.companyAddress.postCode, `companyAddress.postCode`)}
                    />
                </div>
            </div>
            <div className="form-group">
                <div className="label">Country</div>
                <div className="input">
                    <Autocomplete
                        value={form.companyAddress.country}
                        fullWidth={true}
                        disableClearable={true}
                        className="country-autocomplete"
                        options={AllCountries.map((c) => c.name)}
                        groupBy={(option) => {
                            const o = AllCountries.find((c) => c.name === option);
                            return o._muiCategory || 'Rest of the world';
                        }}
                        disabled={submitted}
                        renderInput={(params) => <TextField variant="outlined" {...params} placeholder="Country" />}
                        data-cy={`input-country`}
                        freeSolo={false}
                        multiple={false}
                        onChange={(_, value) => updateData('companyAddress.country', value)}
                    />
                    {validationKeys[`companyAddress.country`] !== undefined && form.companyAddress.country && (
                        <FormHelperText error={true}>{validationKeys['companyAddress.country']}</FormHelperText>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
