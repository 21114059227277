import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ComponentState } from '../../../..';

// Dependencies
import { makeEndpointRequest } from '../../../../../../../utils/endpoints';
import { logError, trackUserInteraction } from '../../../../../../../utils/helpers';

// Component
import Panel from './components/panel';

const Component = (props) => {
    const { draftData, updateDraftData } = ComponentState();
    const [options, setOptions] = useState([]);

    const loadStorageOptions = async () => {
        try {
            const data = await makeEndpointRequest(`getStorageLocationsOptions`);
            setOptions(data);
        } catch (err) {
            await logError('LOAD_RECOMMENDATIONS_STORAGE_LOCS', err);
            await trackUserInteraction(`Having Difficulties`, { reason: `Failed to load the list of storage locations he can pick` });
        }
    };

    useEffect(() => {
        loadStorageOptions();
    }, []);

    const getInterfaceData = () => {
        const arr = options.map((item) => {
            return {
                ...item,
                origin: `recommendations`,
            };
        });

        let categories = [];

        arr.forEach((opt) => {
            if (!categories.includes(opt.category)) {
                categories.push(opt.category);
            }
        });

        categories = categories.sort();
        categories.splice(0, 0, 'All');

        return { options: arr, categories };
    };

    const onLocationAdded = async (labels, options) => {
        try {
            // Stop the picking
            props.setPickingTools(false);

            let recommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: labels,
                processes: [],
            });

            // Map the new picked tools..
            const tools = labels
                .map((e) => options.find((c) => c.label === e) || null)
                .filter((x) => x !== null)
                .map((r) => {
                    const matchRec = recommendations.storageLocations.find((e) => e.label === r.label);
                    const processes = matchRec ? matchRec.processRecommendations.filter((s) => s.length > 0) : [];

                    return {
                        label: r.label,
                        image: r.image,
                        url: r.url,
                        dataResidency: r.dataResidency,
                        securityMeasuresURL: r.securityMeasuresURL,
                        securityMeasuresTitle: r.securityMeasuresTitle,
                        processes: processes,
                        type: 'recommendations',
                    };
                });

            // Add it..
            const newTools = [...draftData.subProcessors];

            // Work the label..
            tools.forEach((tool) => {
                const newTool = { ...tool };

                const numberOfEntries = newTools.filter((elm) => {
                    // Turn Xero (2) into Xero
                    const modifiedElmLabel = elm.label
                        .replace(/\(\d+\)/g, '')
                        .trim()
                        .toLowerCase();

                    // Format the name of this new tool
                    const toolLabel = tool.label.trim().toLowerCase();
                    return modifiedElmLabel === toolLabel;
                });

                if (numberOfEntries.length > 0) {
                    newTool.label = `${newTool.label} (${numberOfEntries.length + 1})`;
                }

                // Adding it to the array
                newTools.push(newTool);
            });

            // eslint-disable-next-line
            // console.log('New tools', newTools);
            updateDraftData('subProcessors', newTools);
        } catch (err) {
            await logError(`dpa.onLocationAdded`, err, { labels, options });
        }
    };

    if (props.enabled !== true) return null;

    return (
        <React.Fragment>
            <Panel
                data={draftData.subProcessors}
                options={options}
                meta={getInterfaceData()}
                onDone={onLocationAdded}
                createCustomTool={props.createCustomTool}
            />
        </React.Fragment>
    );
};

export default Component;
