import React from 'react';
import { useHistory } from 'react-router-dom';

const Shortcut = () => {
    const history = useHistory();

    const goTo = () => history.push('/audits');

    return (
        <React.Fragment>
            <div className="card-bg shortcut-card shareables">
                <div className="header">
                    <div className="title">
                        Audits <span className="badge">New</span>
                    </div>

                    <div className="arrow settings" onClick={goTo}>
                        <i className="icon fa-light fa-chevron-right"></i>
                    </div>
                </div>
                <div className="header-description">Find recommendations on how to improve your existing policies.</div>
            </div>
        </React.Fragment>
    );
};

export default Shortcut;
