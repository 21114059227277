import React from 'react';
import { getSuggestedToolGroupBadgeColor, getSuggestedToolGroupLabel } from '../../../../components/functions';

const Component = (props) => {
    const groupColor = getSuggestedToolGroupBadgeColor(props.id);
    return (
        <React.Fragment>
            <div
                className="shared-component chip-just-added chip-just-added-position"
                style={{ backgroundColor: `${groupColor}`, marginRight: props.includeFooterLabel ? 8 : 0 }}
            ></div>
            {props.includeFooterLabel && <div className="text">{getSuggestedToolGroupLabel(props.id)}</div>}
        </React.Fragment>
    );
};

export default Component;
