import React from 'react';
import { PanelContext } from '..';
import { lowerCaseString } from '../utils/functions';

const Component = () => {
    const { options, setCategory, category } = PanelContext();

    const getCategories = () => {
        let categories = [];

        options.forEach((opt) => {
            if (!categories.includes(opt.category)) {
                categories.push(opt.category);
            }
        });

        categories = categories.sort();
        categories.splice(0, 0, 'All');

        return categories;
    };

    return (
        <React.Fragment>
            <div className="categories">
                {getCategories().map((cat, index) => (
                    <div
                        onClick={() => setCategory(lowerCaseString(cat) === category ? 'all' : lowerCaseString(cat))}
                        className={`component-entry ${lowerCaseString(cat) === category && 'selected'}`}
                        key={index}
                    >
                        {cat}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default Component;
