import React from 'react';

// Components
import { Button } from '@mui/material';
import DoYouProcessData from './components/doYouProcessData';
import Individuals from './components/individuals';

// Context
import { ModuleState } from '../..';

// Dependencies
import { trackUserInteraction } from '../../../../../utils/helpers';
import { ModuleAPI } from '../../components/api';

const Component = () => {
    const { setStep } = ModuleState();
    const { filterCheckedData } = ModuleAPI();

    const goBack = async () => {
        setStep('IdentifySensitive');
        await trackUserInteraction(`Selected "Go Back"`, { destination: `IdentifySensitive` });
        filterCheckedData();
    };

    const nextStep = async () => {
        const nextOne = `ReviewData`;
        setStep(nextOne);
        await trackUserInteraction(`Selected "Next"`, { destination: nextOne });
        filterCheckedData();
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" onClick={goBack} data-cy="back-button">
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Children Data
                    </div>
                    <div className="subtitle">The GDPR gives special protection to the data of individuals under the age of 16.</div>
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" disabled={false} onClick={nextStep} data-cy="next-button">
                        Next
                    </Button>
                </div>
            </div>
            <div className="module-content children-screen">
                <DoYouProcessData />
                <Individuals />
            </div>
        </React.Fragment>
    );
};

export default Component;
