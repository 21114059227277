import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { trackUserInteraction } from '../../../../../utils/helpers';

// Context
import { ModuleState } from '../../index';
import { ModuleAPI } from '../../components/api';

export const sensitiveIndividualsList = [
    {
        label: 'Children',
    },
    {
        label: `None`,
    },
];

const StepVulnerable = () => {
    const { data, updateData, step, setStep } = ModuleState();
    const { filterCheckedData, refreshCheckedData } = ModuleAPI();
    const [noneSelected, setNoneSelected] = useState(data.checkedData.individuals.length < 1);

    const nextStep = async () => {
        filterCheckedData();
        const nextOne = `ReviewData`;
        setStep(nextOne);
        await trackUserInteraction(`Selected "Next"`, { destination: nextOne });
    };

    const goBack = async () => {
        filterCheckedData();
        setStep('IdentifySensitive');
        await trackUserInteraction(`Selected "Go Back"`, { destination: `IdentifySensitive` });
    };

    const warnAgainstRemoving = (individual) => {
        window.showAlert(
            'Confirmation',
            `Are you sure about removing ${individual.label}? This will remove it from your data inventory entirely.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        removeIndividualSelected(individual);
                    },
                },
            ],
        );
    };

    const removeIndividualSelected = async (individual) => {
        let checkedData = data.checkedData;
        checkedData.individuals.forEach((indivIterated, index) => {
            if (indivIterated.label === individual.label) {
                checkedData.individuals.splice(index, 1);
            }
        });
        updateData(`checkedData`, checkedData);
        await trackUserInteraction(`Removed selected "Sensitive Individual"`, {
            removed: individual.label,
        });
    };

    const onIndividualSelected = async (individual) => {
        if (individual.label === 'None') {
            await trackUserInteraction(`${noneSelected ? `Removed selected` : `Selected`} "None"`, { screen: `reviewIndividuals` });
            setNoneSelected(!noneSelected);
            return false;
        }
        let checkedData = data.checkedData;
        let lastBiggestStep = data._meta.biggestStep;
        if (checkedData.individuals.find((iterated) => iterated.label === individual.label)) {
            if (lastBiggestStep !== step) {
                warnAgainstRemoving(individual);
                return false;
            }
            removeIndividualSelected(individual);
        } else {
            let indivAdded = { label: individual.label, type: 'manual' };
            await trackUserInteraction(`Selected "Sensitive Individual`, { added: individual.label });
            checkedData.individuals.push(indivAdded);
            updateData('checkedData', checkedData);
            if (noneSelected) {
                setNoneSelected(false);
            }
        }
    };

    useEffect(() => {
        refreshCheckedData();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" onClick={goBack} data-cy="back-button">
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Vulnerable Individuals
                    </div>
                    <div className="subtitle">Do you process data from any of the following vulnerable individuals? If so, which?</div>
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" disabled={false} onClick={nextStep} data-cy="next-button">
                        Next
                    </Button>
                </div>
            </div>
            <div className="identify-elements module-content">
                <div className="entries d-flex-row-columns centered">
                    {sensitiveIndividualsList.map((elm, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`entry ${elm.label === 'None' && data.checkedData.individuals.length > 0 && `disabled`}  ${
                                    data.checkedData.individuals.find((i) => i.label === elm.label) ||
                                    (elm.label === 'None' && noneSelected === true)
                                        ? `selected`
                                        : `not-selected`
                                }`}
                                key={index}
                                onClick={() => onIndividualSelected(elm)}
                                data-cy={`entry-${index}`}
                            >
                                <div className="content">
                                    <div className="left-side">
                                        <div className="label">{elm.label}</div>
                                        {/* <div className="description">{elm.description}</div> */}
                                    </div>
                                    <div className="checkmark">
                                        <i className="icon fa-solid fa-check"></i>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default StepVulnerable;
