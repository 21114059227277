import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';

// Required fonts

Font.register({ family: 'Poppins', src: '/assets/fonts/Poppins-Regular.ttf', fontWeight: 'normal' });
Font.register({ family: 'Poppins', src: '/assets/fonts/Poppins-Bold.ttf', fontWeight: 'bold' });

// The stylesheet required (Is not React usual stylesheet so can't use HTML CSS)

const styles = StyleSheet.create({
    body: {
        flexDirection: 'row',
        backgroundColor: `#131333`,
        color: 'white',
        position: 'relative',
        overflow: 'hidden',
        topLeftCorner: {
            position: 'absolute',
            top: '-10pt',
            left: '0',
            height: 150,
            width: 150,
        },
        bottomRightCorner: {
            position: 'absolute',
            bottom: '-10pt',
            right: '0',
            height: 150,
            width: 150,
        },
        logo: {
            position: 'absolute',
            top: '30pt',
            right: '40pt',
            height: 90,
            width: 70,
        },
        background: {
            position: 'absolute',
            top: '20pt',
            left: '180pt',
            height: '700pt',
            width: '900pt',
            textAlign: 'center',
            padding: '16pt',
            opacity: 0.3,
        },
    },
    topArea: {
        position: 'absolute',
        top: '20pt',
        left: '210pt',
        height: '250pt',
        width: '800pt',
        textAlign: 'center',
        padding: '16pt',
        line1: {
            fontSize: '55pt',
            fontWeight: 'bold',
            fontFamily: 'Poppins',
            color: '#F8A426',
            textTransform: 'uppercase',
        },
        line2: {
            marginTop: '-25pt',
            fontSize: '55pt',
            fontWeight: 'bold',
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
        },
        line3: {
            marginTop: '10pt',
            fontSize: '45pt',
            fontWeight: 'bold',
            fontFamily: 'Poppins',
            color: '#F8A426',
        },
    },
    contentArea: {
        position: 'absolute',
        top: '320pt',
        left: '130pt',
        height: '300pt',
        width: '1100pt',
        padding: '16pt',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        entry: {
            width: '33%',
            marginBottom: '22pt',
            label: {
                fontWeight: 'normal',
                color: 'white',
                opacity: 0.6,
                marginBottom: '2pt',
                fontSize: '18pt',
                fontFamily: 'Poppins',
            },
            value: {
                fontFamily: 'Poppins',
                fontSize: '18pt',
            },
        },
    },
    footerArea: {
        position: 'absolute',
        bottom: '40pt',
        left: '135pt',
        height: '200pt',
        width: '850pt',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        scanArea: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            code: {
                heigh: '130pt',
                width: '130pt',
            },
            hint: {
                color: 'white',
                width: '130pt',
                fontSize: '12pt',
                textAlign: 'center',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                opacity: 0.9,
            },
        },
        textArea: {
            flex: 1,
            marginLeft: '40pt',
            generalText: {
                fontSize: '19pt',
                color: 'white',
                opacity: 0.8,
            },
        },
    },
});

// Definitions
const imageAssetsPath = `/assets/images/page-certificates/`;

const DocumentPDF = (props) => {
    return (
        <Document>
            <Page size="TABLOID" orientation="landscape" style={styles.body}>
                <View style={styles.topArea}>
                    <Text style={styles.topArea.line1}>{props.content.certName}</Text>
                    <Text style={styles.topArea.line2}>Certificate</Text>
                    <Text style={styles.topArea.line3}>{props.companyInfo.companyLegalName}</Text>
                </View>
                <View style={styles.contentArea}>
                    {props.content.dataList.map((entry, index) => (
                        <View style={{ ...styles.contentArea.entry, width: entry.width || '33%' }} key={index}>
                            <Text style={styles.contentArea.entry.label}>{entry.label}</Text>
                            <Text style={styles.contentArea.entry.value}>{entry.value}</Text>
                        </View>
                    ))}
                </View>
                <View style={styles.footerArea}>
                    <View style={styles.footerArea.ScanArea}>
                        <Image style={styles.footerArea.scanArea.code} src={props.qr} />
                        <Text style={styles.footerArea.scanArea.hint}>Scan to verify</Text>
                    </View>
                    <View style={styles.footerArea.textArea}>
                        <Text style={styles.footerArea.textArea.generalText}>{props.content.copyrightText}</Text>
                    </View>
                </View>
                {/* Asset photos */}
                <Image style={styles.body.topLeftCorner} src={`${imageAssetsPath}/corner_left.png`} />
                <Image style={styles.body.bottomRightCorner} src={`${imageAssetsPath}/corner_right.png`} />
                <Image style={styles.body.logo} src={`${imageAssetsPath}/company_logo.png`} />
                <Image style={styles.body.background} src={`${imageAssetsPath}/bg.png`} />
            </Page>
        </Document>
    );
};

export default DocumentPDF;
