import React, { createContext, useContext } from 'react';
import { makeEndpointRequest } from '../../../../../../../../utils/endpoints';

// Context
const Context = createContext({});
export const SetProcessesContext = () => useContext(Context);

// Components
import Modal from './components/modal';

const Component = (props) => {
    const { vitalRecommendations, dataInventory, setDataInventory, companyData } = props;

    const getOptions = () => {
        try {
            // If is too early and is still loading.
            if (!vitalRecommendations || !vitalRecommendations.individuals) return [];

            let processes = [];

            // Adding the vital core recommendations of processes...
            vitalRecommendations.processes.forEach((p) => {
                processes.push({
                    label: p.label,
                    category: p.category || '',
                });
            });

            // Adding custom ones
            dataInventory.processes.forEach((proc) => {
                // Already exist in this array
                if (processes.find((x) => x.label === proc.label)) return;

                // Adding the new one..
                processes.push({
                    label: proc.label,
                    category: 'Custom',
                });
            });

            // Adding core services
            companyData.services.forEach((proc) => {
                // Already exist in this array
                if (processes.find((x) => x.label === proc.label)) return;

                // Adding the new one..
                processes.push({
                    label: proc.label,
                    category: 'Core Services',
                });
            });

            // Sort them like this to avoid a warning in the browser that they weren't ordered right.
            return processes.sort((a, b) => a.category.localeCompare(b.category));
        } catch (err) {
            logError(`module.individuals.setProcesses.getOptions`, err);
            return [];
        }
    };

    const createProcess = async (processName) => {
        try {
            // Get recommendations for this process.
            const recommendation = vitalRecommendations.processes.find((c) => c.label === processName);

            let personalLegalBasis = null;
            let sensitiveLegalBasis = null;
            let role = recommendation ? recommendation.legalResponsibility : null;

            if (recommendation) {
                // Extracting..
                const art6 = recommendation.article6.split('~');
                const art9 = recommendation.article9.split('~');

                // Format the data
                personalLegalBasis = { string: art6[0] ? art6[0].trim() : '', gdpr: art6[1] ? art6[1].trim() : '' };
                sensitiveLegalBasis = art9[1] ? { string: art9[0] ? art9[0].trim() : '', gdpr: art9[1] ? art9[1].trim() : '' } : null;
            }

            // Create the new process in the back-end
            const newProcess = await makeEndpointRequest(`CreateProcess`, {
                payload: {
                    label: processName,
                    role,
                    personalLegalBasis,
                    sensitiveLegalBasis,
                },
            });

            // Add it to data inventory
            setDataInventory((currentState) => {
                // Format new data
                let newState = { ...currentState };

                // Change it..
                newState.processes.push(newProcess);

                return newState;
            });

            return newProcess;
        } catch (err) {
            await logError(`module.individuals.setProcesses.createProcess`, err, { processName });
            return false;
        }
    };

    const addProcess = async (processAdded) => {
        try {
            // Does this individual exist in the data inventory?
            let inventory = dataInventory.processes.find((c) => c.label === processAdded.label);

            // Keep track..
            let processCreated = false;

            // Does it exist in the data inventory already?
            if (!inventory) {
                // Create the process
                inventory = await createProcess(processAdded.label);

                // Mark is created
                processCreated = true;

                // If it failed to create
                if (inventory === null) throw new Error(`Failed to create process.`);
            }

            // Inform the higher component that this tool has been added.
            props.onProcessAdded(inventory, processCreated);
        } catch (err) {
            await logError(`module.individuals.setProcess.addProcess`, err, { processAdded });
            return false;
        }
    };

    const removeProcess = async (processRemoved) => {
        try {
            props.onProcessRemoved(processRemoved);
        } catch (err) {
            await logError(`module.individuals.setProcess.removeProcess`, err, { processAdded });
            return false;
        }
    };

    const passedProps = {
        currentData: props.currentProcesses,
        options: getOptions(),
        closeModal: props.closeModal,
        tool: props.tool,
        textContents: props.textContents,
        // Callback functions
        addProcess,
        removeProcess,
    };

    return (
        <Context.Provider value={passedProps}>
            <Modal />
        </Context.Provider>
    );
};

export default Component;
