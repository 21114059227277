import { PageMappings } from '.';

export const isValidPageToRender = str => {
    if (PageMappings[str] !== undefined) return true;
    return false;
};

export const decideBestPage = tab => {
    if (isValidPageToRender(tab)) {
        return tab;
    } else {
        return `settings`; // default one if nothing else works.
    }
};

export const updateUrl = tab => window.history.pushState('_', '_', `/cookie-banner/${tab}`);
