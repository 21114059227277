import React from 'react';

// Components
import Card from './components/card';

const Component = ({ id, storageLocations }) => {
    const getHeading = () => {
        if (id === 'validated') return `Your tools and third parties`;
        if (id === 'missingInformation') return `Missing Information (${storageLocations.length})`;
        if (id === 'archived') return `Archived tools`;
        if (id === 'newTools') return `New Tools`;

        return 'Un-named group of tools';
    };

    const getDescription = () => {
        if (id == `validated`)
            return `Tools and third parties that you use to store and process information.\n Since many of these companies will process data on your behalf we need to provide information about them in your privacy policy.`;
        if (id === 'missingInformation')
            return `We're missing some information for the following Tools and Third Parties. Complete the missing information so they appear in your policies.`;
        if (id === `archived`)
            return `${storageLocations.length} tools have been archived. This means they will not appear on your inventory or your policies.
        `;

        if (id === `newTools`) {
            return `We've found new tools and third parties that you may be using. Click into them and save them to keep them, archive them so they don't get recommended again or delete them.`;
        }

        return null;
    };

    /**
     * When there is a new tool found by Diana, we need to display it on the Third Parties module.
     * In order to show it in this Component we need to format the data a bit.
     * @param {*} scanTool
     * @returns Data formatted right for the component.
     */

    const formatScannedToolData = (scanTool) => {
        return {
            _id: scanTool._id,
            label: scanTool.data.label,
            createdAt: scanTool.scannedAt,
            processes: [], // Needed to avoid error.
        };
    };

    // It's good to simply know if it's a new tool or not.
    const isNewTool = id === 'newTools' ? true : false;

    return (
        <React.Fragment>
            <div className="component-group">
                <div className="sub-component-header">
                    <div className="heading">{getHeading()}</div>
                    {getDescription() && <div className="description">{getDescription()}</div>}
                </div>
                <div
                    className="sub-component-entries"
                    data-cy={id === 'archived' ? 'sub-component-entries-archived' : 'sub-component-entries'}
                >
                    {storageLocations.map((c, ix) => (
                        <Card
                            // If the group is 'new tools' (tools found by the scanner) we need to fake the data a bit so it can be seen.
                            data={isNewTool ? formatScannedToolData(c) : c}
                            key={`${id}-${ix}`}
                            isNewTool={isNewTool}
                            showMissInfoBadge={id === 'missingInformation'}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
