import { ClickAwayListener } from '@mui/material';
import React from 'react';

const Component = (props) => {
    return (
        <React.Fragment>
            <ClickAwayListener onClickAway={props.close}>
                <div className={`module-popout`}>
                    <div className="module-content">
                        <div className="module-title">Common Knowledge</div>
                        <div className="module-close" onClick={props.close} data-cy="panel-close">
                            <i className="icon fa-solid fa-xmark"></i>
                        </div>

                        <div className="module-elements" style={{ marginTop: 36, width: '100%' }}>
                            <div className="legal-role">
                                Popular countries <b>outside</b> of the EEA, UK or Adequate Countries:
                            </div>
                            <ul>
                                <li>United States</li>
                                <li>Brazil</li>
                                <li>Mexico</li>
                                <li>China</li>
                                <li>India</li>
                            </ul>

                            <div className="legal-role">The European Economic Area (EEA) includes:</div>
                            <ul>
                                <li>Austria</li>
                                <li>Belgium</li>
                                <li>Bulgaria</li>
                                <li>Croatia</li>
                                <li>Republic of Cyprus</li>
                                <li>Czech Republic</li>
                                <li>Denmark</li>
                                <li>Estonia</li>
                                <li>Finland</li>
                                <li>France</li>
                                <li>Germany</li>
                                <li>Greece</li>
                                <li>Hungary</li>
                                <li>Iceland</li>
                                <li>Ireland</li>
                                <li>Italy</li>
                                <li>Latvia</li>
                                <li>Liechtenstein</li>
                                <li>Lithuania</li>
                                <li>Luxembourg</li>
                                <li>Malta</li>
                                <li>Netherlands</li>
                                <li>Norway</li>
                                <li>Poland</li>
                                <li>Portugal</li>
                                <li>Romania</li>
                                <li>Slovakia</li>
                                <li>Slovenia</li>
                                <li>Spain</li>
                                <li>Sweden</li>
                            </ul>
                            <div className="legal-role">The list of adequate countries includes:</div>
                            <ul>
                                <li>Andorra</li>
                                <li>Argentina</li>
                                <li>Canada</li>
                                <li>Faroe Islands</li>
                                <li>Guernsey</li>
                                <li>Israel</li>
                                <li>Isle of Man</li>
                                <li>Japan</li>
                                <li>Jersey</li>
                                <li>New Zealand</li>
                                <li>Republic of Korea</li>
                                <li>Switzerland</li>
                                <li>United Kingdom</li>
                                <li>Uruguay</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
            <div className="module-shadow-underlay" />
        </React.Fragment>
    );
};

export default Component;
