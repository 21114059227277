import React from 'react';

// Dependencies
import { trackUserInteraction } from '../../../../../utils/helpers';

// Components
import { Button } from '@mui/material';
import { ComponentState } from '../..';

// UI Components
import Elements from './components/elements';
import SensitiveElements from './components/sensitiveElements';
import Individuals from './components/individuals';

const Component = () => {
    const { setRoute, isModuleTaskCompleted } = ComponentState();

    const isNextDisabled = () => {
        const check = isModuleTaskCompleted('service-information');
        return check ? false : true;
    };

    const goBack = () => {
        setRoute('describe-service');
        trackUserInteraction(`Selected "Go Back"`);
    };

    const goNext = () => {
        setRoute('start');
        trackUserInteraction(`Selected "Done"`);
    };

    return (
        <React.Fragment>
            <div className="route-header">
                <div className="left-side">
                    <div className="button-left">
                        <Button variant="text" onClick={goBack}>
                            Go Back
                        </Button>
                    </div>
                    <div className="title">Other information about your service</div>
                </div>
                <div className="button-right">
                    <Button variant="contained" color="primary" disabled={isNextDisabled()} onClick={goNext}>
                        Done
                    </Button>
                </div>
            </div>
            <div className="route-content map-services">
                <Elements />
                <SensitiveElements />
                <Individuals />
            </div>
        </React.Fragment>
    );
};

export default Component;
