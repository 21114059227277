import React, { createContext, useContext, useEffect, useState } from 'react';
import lodash from 'lodash';

// Components
import Header from './components/header';
import DPO from './components/dpo';
import Company from './components/company';
import SubmitButton from './components/submitButton';

// Context
import { OnboardingContext } from '../..';
import { certificateSubmitSchema } from '../../../gdprEssentials/utils/validations';
import { logError, validateAgainstSchema } from '../../../../../utils/helpers';
import { OnboardingFunctions } from '../../components/functions';
import { useHistory } from 'react-router-dom';
import { AuthStore } from '../../../../../utils/context/authStore';
import { makeLocalEndpointRequest } from '../../../../../utils/endpoints';

// View Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { setAccount } = AuthStore();

    const { companyData } = OnboardingContext();
    const { updateCompanyData } = OnboardingFunctions();
    const history = useHistory();

    const [submitted, setSubmitted] = useState(false);
    const [validationKeys, setValidationKeys] = useState({});
    const [form, setForm] = useState({
        dpoName: companyData.dpoName || '',
        inquiriesEmail: companyData.inquiriesEmail || '',
        companyLegalName: companyData.companyLegalName || '',
        companyAddress: {
            line1: companyData.companyAddress ? companyData.companyAddress.line1 : '',
            line2: companyData.companyAddress ? companyData.companyAddress.line2 : '',
            city: companyData.companyAddress ? companyData.companyAddress.city : '',
            postCode: companyData.companyAddress ? companyData.companyAddress.postCode : '',
            country: companyData.companyAddress ? companyData.companyAddress.country : '',
        },
    });

    const updateData = (path, value) => {
        setForm((currentState) => {
            let newState = { ...currentState };
            lodash.set(newState, path, value);
            return newState;
        });
    };

    const validateForm = async () => {
        try {
            await validateAgainstSchema(certificateSubmitSchema, form);
            setValidationKeys({});
        } catch (err) {
            setValidationKeys(err);
        }
    };

    const submitData = async () => {
        try {
            // Mark that we start submitting
            setSubmitted(true);

            // Update company data - @Reminder - The form structure is 1:1 with company data.
            await updateCompanyData(form);

            // Make the API Request
            await makeLocalEndpointRequest(`/api/v1/onboarding/complete`);

            // Mark him as gel completed in case he isn't.
            setAccount((currentState) => {
                return {
                    ...currentState,
                    hasCompletedGDPREssentials: true,
                };
            });

            // History
            history.push('/');
        } catch (err) {
            // We have problems.
            setSubmitted(false);
            await logError(`onboarding.complete.submitData`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later.`);
        }
    };

    useEffect(() => {
        validateForm();
        // eslint-disable-next-line
    }, [form]);

    const PassedProps = { form, setForm, submitted, setSubmitted, validationKeys, setValidationKeys, updateData, submitData };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <div className="complete module-content">
                <DPO />
                <Company />
                <SubmitButton />
            </div>
        </Context.Provider>
    );
};

export default Component;
