import React from 'react';

// Context
import { ModuleState } from '../..';

// Components
import Entry from '../entry';

const Component = () => {
    const { filteredData } = ModuleState();

    return (
        <React.Fragment>
            <div className="entries">
                {filteredData.length < 1 && (
                    <div className="no-entries">
                        <div className="title">No records</div>
                        <div className="description">Get started by pressing the "NEW" button.</div>
                    </div>
                )}
                {filteredData.map((c, index) => (
                    <Entry data={c} key={index} />
                ))}
            </div>
        </React.Fragment>
    );
};

export default Component;
