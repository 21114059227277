import React, { useState, useEffect } from 'react';
import { PageContext } from '../..';

// Components
import Header from './components/header';
import SubHeader from './components/subHeader';
import Matrix from './components/matrix';

const Component = () => {
    const [pickedProcess, setPickedProcess] = useState(null);
    const { editingToolId, data } = PageContext();

    const getToolData = () => {
        const res = data.storageLocations.find((c) => c._id === editingToolId);
        if (!res) return null;

        return res;
    };

    const getProcesses = () => {
        const tool = getToolData();
        if (!tool) return [];

        return tool.processes.sort((a, b) => a.label.localeCompare(b.label));
    };

    const setDefaultProcess = () => {
        const tool = getToolData();
        if (!tool) return false;

        const process = getProcesses()[0];

        if (!process) return false;

        setPickedProcess(process.label);
    };

    useEffect(() => {
        setDefaultProcess();
        // eslint-disable-next-line
    }, []);

    if (!pickedProcess || !getToolData()) return null;

    return (
        <React.Fragment>
            <div className="page-content" style={{ marginTop: 0 }}>
                <Header toolName={getToolData().label} />
                <SubHeader processes={getProcesses().map((c) => c.label)} setProcess={setPickedProcess} currentProcess={pickedProcess} />
                <Matrix pickedProcess={pickedProcess} toolData={getToolData()} />
            </div>
        </React.Fragment>
    );
};

export default Component;
