import React from 'react';

// Components
import Tool from '../tool';
import PickTool from '../pickTool';
import CreateEntryComponent from './components/pickToolCard';

import { Button } from '@mui/material';

// Context
import { OnboardingContext } from '../../../..';

const Component = (props) => {
    const { showArchivedTools, setShowArchivedTools } = OnboardingContext();

    // If there are no entries, display nothing.
    if (props.entries.length < 1 && props.optionalSection) return null;

    const getTitle = () => {
        if (props.id === 'incomplete') return `More Information Needed (${props.entries.length})`;
        if (props.id === 'validated') return `Your tools and third parties`;
        if (props.id === `archived`) return `Archived Tools (${props.entries.length})`;
        return null;
    };

    const getDescription = () => {
        if (props.id === `incomplete`) {
            return (
                <React.Fragment>
                    We couldn't find recommendations for the following tools. Please click on each tool and fill in the missing information.
                </React.Fragment>
            );
        }

        if (props.id === `archived`) {
            return (
                <React.Fragment>
                    <b>{props.entries.length}</b> tools have been archived. This means they will not appear on your inventory or your
                    policies.
                </React.Fragment>
            );
        }

        return null;
    };

    return (
        <React.Fragment>
            <div className={`entries-heading ${props.id === 'archived' && 'archive'}`}>
                <div className="left-area">
                    <div className="entries-label">{getTitle()}</div>
                    {getDescription() && <div className="entries-description">{getDescription()}</div>}
                </div>
                {props.id === 'archived' && (
                    <React.Fragment>
                        <div className="side-area">
                            <Button variant="outlined" color="primary" onClick={() => setShowArchivedTools(!showArchivedTools)}>
                                {showArchivedTools ? 'Hide' : 'Show'} Archived
                            </Button>
                        </div>
                    </React.Fragment>
                )}
            </div>

            <div className={`collapsed-section ${props.id === 'archived' ? showArchivedTools : true}`}>
                <div className={`entries ${props.addMarginBottom && 'mb'}`}>
                    {/* Showing the add tool button */}
                    {props.id === 'validated' && (
                        <React.Fragment>
                            <PickTool
                                renderCallToAction={({ setPickTools }) => <CreateEntryComponent onClick={() => setPickTools(true)} />}
                            />
                        </React.Fragment>
                    )}

                    {/* looping through each tool and displaying it */}
                    {props.entries.map((tool, index) => (
                        <Tool data={tool} key={index} isIncomplete={props.id === 'incomplete'} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
