import React from 'react';

// Context
import { ModuleState } from '../../..';

// Components
import { Button } from '@mui/material';

// Dependencies
import FiltersAPI from '../../../../../components/dataControls/filters';
import { getFilterFields } from '../../../utils/helpers';

// Definitions
const filterButtonId = 'filter-btn-trigger';

const Component = () => {
    const { data, options, setOptions } = ModuleState();

    const filterFields = getFilterFields(data);

    return (
        <React.Fragment>
            <div className="component-filters">
                <Button
                    id={filterButtonId}
                    variant={`${Object.keys(options.filters).length > 0 ? 'contained' : 'outlined'}`}
                    color="primary"
                >
                    Filter
                </Button>

                <FiltersAPI
                    urlSupport={true}
                    filters={options.filters}
                    onChanges={(value) => setOptions({ ...options, filters: value })}
                    buttonId={filterButtonId}
                    fields={filterFields}
                />
            </div>
        </React.Fragment>
    );
};

export default Component;
