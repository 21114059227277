export const filtersLabels = {
    is: 'is...',
    contains: 'contains',
    notContains: 'does not contain..',
    isEmpty: 'is empty',
    notEmpty: 'is not empty',
    // hasAnyOf: 'Has any of..',
    // hasNoneOf: 'Has none of..',
    // hasAllOf: 'Has all of..',
};
// @Reminder: If we ever add a type that needs value of type array to revamp the slugs support.

export const filtersRequiringOptions = ['is'];
export const filtersRequiringValue = ['contains', 'notContains', 'is'];
