const gdprEssentialsWizardEndpoints = {
    GetStorageLocationRecommendations: {
        link: '/graphql',
        method: 'POST',
        query: `
        query GetStorageLocationRecommendations {
            GetStorageLocationRecommendations {
                label,
                type,
                category,
                description,
                processRecommendations,
                cookieType,
                cookiePurpose,
                cookieExternalInfo,
                legalResponsibility,
                image,
                dataResidency,
                securityMeasures,
                customDataResidency,
            }
          }`,
        parserFunction: ({ data }) => data.GetStorageLocationRecommendations,
    },
    GetDataProcessRecommendations: {
        link: '/graphql',
        method: 'POST',
        query: `
        query GetDataProcessRecommendations {
          GetDataProcessRecommendations {
            label,
            category,
            description,
            elementCategories,
            subjectCategories,
            specialCategoryElements,
            article6,
            article9,
            legalResponsibility,
            }
          }`,
        parserFunction: ({ data }) => data.GetDataProcessRecommendations,
    },
    GetDataElementRecommendations: {
        link: '/graphql',
        method: 'POST',
        query: `
        query GetDataElementRecommendations {
          GetDataElementRecommendations {
                label,
                description,
            }
          }`,
        parserFunction: ({ data }) => data.GetDataElementRecommendations,
    },
    GetSensitiveDataRecommendations: {
        link: '/graphql',
        method: 'POST',
        query: `
        query GetSensitiveDataRecommendations {
          GetSensitiveDataRecommendations {
                label,
            }
          }`,
        parserFunction: ({ data }) => data.GetSensitiveDataRecommendations,
    },
    GetDataSubjectRecommendations: {
        link: '/graphql',
        method: 'POST',
        query: `
        query GetDataSubjectRecommendations {
          GetDataSubjectRecommendations {
                label,
                category,
            }
          } `,
        parserFunction: ({ data }) => data.GetDataSubjectRecommendations,
    },
    GetVitalRecommendations: {
        link: '/graphql',
        method: 'POST',
        query: `
        query GetVitalRecommendations  {
              GetVitalRecommendations {
                  elements {
                      label
                      description
                  }
                  processes {
                              label
                              category
                              description
                              elementCategories
                              subjectCategories
                              specialCategoryElements
                              article6
                              article9
                              legalResponsibility
                              inDPA
                              appliedToSubjectTypesOf
                              retentionPeriod
                  }
                  individuals {
                      label
                      category
                      inDpa
                      type
                      allowedTypes
                  }
                  sensitiveElements {
                      label
                  }
                  retentionPeriods
              }
          }
      `,
        parserFunction: ({ data }) => data.GetVitalRecommendations,
    },
    UpdateGELSession: {
        link: '/graphql',
        method: 'POST',
        query: `
          mutation UpdateSession ($session: JSON)
            {
            UpdateSession (
                session: $session
            )
          }
      `,
    },
    GetHSDataFromSession: {
        link: '/graphql',
        method: 'POST',
        query: `query GetHSDataFromSession {
        GetHSDataFromSession {
              email,
              website,
              _companyId
          }
      }        
      `,
    },
    GetGELSession: {
        link: '/graphql',
        method: 'POST',
        query: `
          query  GetUserSession
          {
            GetUserSession {
            
              stepNumber
              checkedData {
                elements {
                  label
                  type
                  sensitive
                }
                individuals {
                  label
                  type
                }
              }
              _meta {
                domainLastScannedAt
                biggestStep
                companyActivitiesGenerated
                companyActivitiesGeneratedUses
                companyActivitiesLastGeneratedAt
                companyDescriptionGenerated
                companyDescriptionGeneratedUses
                companyDescriptionLastGeneratedAt
              }
              companyInfo {
                industry
                companyInsights
                targetAudience
                companyDomains
                companyLegalName
                companyDescription
                processChildrenData
                companyAddress {
                  line1
                  line2
                  postCode
                  country
                  city
                },
                individualsReferring {
                  label
                  referringRole
                  isChildren
                }
                inquiriesEmail
                dpoName
                services {
                  label
                  origin
                }
              }
              processesDependencies {
              	processName
              	legalResponsibility
              	personalLegalBasis {
              		string
              		gdpr
              	}
              	sensitiveLegalBasis {
              		string
              		gdpr
              	}
              }
              storageLocations {
                _storageLocationId
              	label
              	category
              	description
              	cookieType
              	cookiePurpose
              	cookieExternalInfo
              	image
              	securityMeasures
              	dataResidency
              	origin
              	archived
              	validated
                isSubProcessor
                subProcessorMeasures
              	individuals {
              		label
              		category
                }
              	elements {
              		label
              		description
              		sensitive
              	}
              	processes {
              		label
              		category
              	}
              	matrixMap {
              		process
              		element
              		individual
              	}
              }
            }
          }
      `,
        parserFunction: ({ data }) => data.GetUserSession,
    },
    SetDataInventory: {
        link: '/graphql',
        method: 'POST',
        query: `
        mutation SetDataInventory ($input: JSON)
        {
          SetDataInventory (input: $input)
        }
      `,
        timeout: 180000,
        parserFunction: ({ data }) => data.SetDataInventory,
    },
    SendEmailAlertForGEL: {
        link: '/graphql',
        method: 'POST',
        query: `
          mutation SendGelEmail
          {
          SendGelEmail
          }
        `,
        timeout: 180000,
        parserFunction: ({ data }) => data.SendEmailAlertForGEL,
    },
    ScanWebsiteDomain: {
        link: `/graphql`,
        method: `POST`,
        timeout: 180000,
        query: `
        query ScanWebsite ($domains: [String!]!)
        {
          ScanWebsite  (domains: $domains){
            label
          }
        }
      `,
        parserFunction: ({ data }) => data.ScanWebsite,
    },
    getStorageLocationsOptions: {
        link: `/graphql`,
        method: `POST`,
        query: `query GetStorageLocationOptions {
          GetStorageLocationOptions {
              label,
              image,
              category
              dataResidency
              url
              securityMeasuresTitle
              securityMeasuresURL
              externalLinks
          }
      }`,
        parserFunction: ({ data }) => data.GetStorageLocationOptions,
    },
    getSpecificRecommendation: {
        link: `/graphql`,
        method: `POST`,
        query: `
          query GetSpecificRecommendation ($locations: [String], $processes: [String])
          {
            GetSpecificRecommendation (locations: $locations, processes: $processes){
              processes {
                				label
                				category
                				description
                				elementCategories
                				subjectCategories
                				specialCategoryElements
                				article6
                				article9
                				legalResponsibility
                        retentionPeriod
                			}
              storageLocations {
                		label
                      type
                      category
                      description
                      cookieType
                      dataResidency
                      customDataResidency
                      securityMeasures
                      cookiePurpose
                      cookieExternalInfo
                      legalResponsibility
                      image
                      dataResidency
                      customDataResidency
                      externalLinks
                      dpaType
                      processRecommendations
                    }
          }
        }
      `,
        parserFunction: ({ data }) => data.GetSpecificRecommendation,
    },
};

export default gdprEssentialsWizardEndpoints;
