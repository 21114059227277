import { MenuItem, Select } from '@mui/material';
import React from 'react';

import { ComponentState } from '../../../..';
import { ComponentState as CB_ComponentState } from '../../../../../cookieBanner/index';

const Component = (props) => {
    const { data, updateData } = props.componentSource === 'cb' ? CB_ComponentState() : ComponentState();

    if (data.privacyPortalSettings && data.privacyPortalSettings.features && data.privacyPortalSettings.features.cookiePolicy === false)
        return null;

    return (
        <React.Fragment>
            <div className="form-label">Do you use web beacons (sometimes known as clear GIFs, Web bugs or pixel tags?)</div>
            <div className="form-content">
                <Select
                    fullWidth={true}
                    data-cy="use-beacon-input"
                    onChange={(e) => updateData(`thirdPartyCookies.useWebBeacons`, e.target.value)}
                    value={data.thirdPartyCookies.useWebBeacons || false}
                >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                </Select>
            </div>
        </React.Fragment>
    );
};

export default Component;
