import React from 'react';
import { ComponentState } from '../../../..';

// Components
import { Button } from '@mui/material';
import { useState } from 'react';

import Advice from './components/advice';
import Check from './components/check';
import LastScanResults from './components/lastScanResults';

// Dependencies
import { logError, trackUserInteraction } from '../../../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../../../utils/endpoints';

const Component = () => {
    const { data, serviceVerifications, setServiceVerifications } = ComponentState();

    const [submitted, setSubmitted] = useState(false);
    const [verificationStep, setVerificationStep] = useState(0);

    const checkServices = async () => {
        try {
            // Mark as submitted
            setSubmitted(true);

            // First check
            setVerificationStep(1);
            const { result: cookieBannerResult, debug: cookieBannerDebug } = await makeEndpointRequest('CheckCookieBannerImplementation');

            // Check that it's been more than 15 seconds since last scan.
            if (cookieBannerResult === 'cooldown') {
                window.showAlert('Cooldown period', `Wait ${cookieBannerDebug.cooldownSecondsLeft} seconds before trying again.`, `error`);
                setSubmitted(false);
                return false;
            }

            // We just got the result
            setServiceVerifications((currentState) => ({
                ...currentState,
                cookieBanner: {
                    scannedAt: new Date(),
                    data: {
                        ...cookieBannerResult,
                    },
                },
            }));

            // If first test failed
            if (cookieBannerResult.result.found === false) {
                setSubmitted(false);
                setVerificationStep(0);
                return false;
            }

            // Next check now
            setVerificationStep(2);
            const { result: inventoryAssessmentResult } = await makeEndpointRequest(`GetInventoryAssessment`);

            // We just got the result
            setServiceVerifications((currentState) => ({
                ...currentState,
                inventoryAssessment: {
                    scannedAt: new Date(),
                    data: {
                        ...inventoryAssessmentResult,
                    },
                },
            }));

            // Reset..
            setSubmitted(false);
            setVerificationStep(0);
        } catch (err) {
            trackUserInteraction(`Having Difficulties`, { reason: `failed to verify cb implementation` });
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
            setSubmitted(false);
            await logError(`cookieBanner.checkServices`, err);
        }
    };

    const getServicesStatus = (id) => {
        // Get cookie banner..
        if (id === 'cookieBanner') {
            const res = serviceVerifications['cookieBanner'];

            // We're scanning.
            if (submitted && verificationStep === 1) return `pending`;
            if (!res) return `unknown`; // Unknown - we never scanned it.
            if (res.data.result.found === true) return 'success'; // Success

            // Otherwise failed
            return 'failed';
        }

        // Get cookie banner..
        if (id === 'inventoryAssessment') {
            const res = serviceVerifications['inventoryAssessment'];

            // We're scanning.
            if (submitted && verificationStep === 2) return `pending`;
            if (submitted && verificationStep !== 2) return 'unknown'; // we don't know how this one will be.
            if (!res) return `unknown`; // Unknown - we never scanned it.
            if (res.data.missingTools.length < 1) return 'success'; // Success

            // Otherwise failed
            return 'failed';
        }

        return 'unknown';
    };

    if (!data.cookieBannerSettings.enabled) return null;

    return (
        <React.Fragment>
            <div className="step-entry scan-entry">
                <div className="step-header">
                    <div className="step-badge">Step {data.smartCookieBlocker ? 4 : 3}</div>
                    <div className="step-label">Review Implementation</div>
                </div>
                <div className="step-content">
                    <div className="step-two-rows">
                        <div className="parent-container-rows" style={{ flex: 1 }}>
                            <div className="step-text">
                                Ensure Correct Installation: Let our assistant robots validate your cookie banner setup. Just click below to
                                start the quick verification process
                            </div>

                            <div className="check-installation">
                                <div className="checkpoints">
                                    <div className="header">Checks</div>
                                    <div className="content">
                                        <Check
                                            label="Code Integration Check"
                                            description={`Verifies the presence of the required snippet code on your website.`}
                                            status={getServicesStatus('cookieBanner')}
                                        />
                                        <Check
                                            label="Inventory Assessment"
                                            description={`Examine website for missing tools that may place cookies without consent.`}
                                            status={getServicesStatus('inventoryAssessment')}
                                        />
                                    </div>
                                    <div className="footer">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={!data.companyDomains[0] || submitted}
                                            onClick={checkServices}
                                            size="small"
                                        >
                                            {submitted ? 'Checking..' : 'Check Installation'}
                                        </Button>
                                        <div className="right-side">
                                            <LastScanResults scanning={submitted} />
                                        </div>
                                    </div>
                                </div>
                                <Advice scanning={submitted} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
