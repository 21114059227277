import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getValidationPropFields } from '../../../../utils/helpers';
import { editSchema } from '../utils/validations';

const Component = (props) => {
    const [validationResults, setValidationResults] = useState({});

    const updateField = (field, value) => {
        props.setEditData((currentData) => {
            let modifiedData = JSON.parse(JSON.stringify(currentData));
            modifiedData.payload[field] = value;
            return modifiedData;
        });
    };

    const validateFields = async () => {
        if (props.editData.active === false) return false;
        try {
            await validateAgainstSchema(editSchema, props.editData.payload);
            setValidationResults({});
        } catch (err) {
            setValidationResults(err);
        }
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [props.editData.payload]);

    return (
        <React.Fragment>
            <Dialog className={`edit-dpa-modal`} open={true} maxWidth={'sm'} fullWidth={true}>
                <DialogTitle data-cy="dialog-title">{props.editData.creating ? `Create DPA` : `Editing DPA`}</DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <div className="form-group" style={{ marginBottom: 18 }}>
                        <div className="label">Name</div>

                        <div className="input">
                            <TextField
                                autoFocus
                                id="tool-name"
                                fullWidth
                                variant="standard"
                                placeholder="Unnamed"
                                value={props.editData.payload.name}
                                onChange={(e) => updateField('name', e.target.value)}
                                data-cy="dialog-input"
                                {...getValidationPropFields(validationResults, props.editData.payload.name.length > 0, `label`)}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={props.onDismiss} data-cy="dialog-dismiss-button">
                        Dismiss
                    </Button>
                    <Button
                        disabled={Object.values(validationResults).length > 0}
                        variant="contained"
                        color="primary"
                        onClick={() => props.onSubmit()}
                        data-cy="dialog-submit-button"
                    >
                        {props.editData.creating ? `Create` : `Save`}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
