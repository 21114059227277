import React from 'react';

// Components
import Header from './components/header';
import IndividualGroup from './components/individualGroup';

// Dependencies
import { getIndividualsTypes } from '../../../gdprEssentials/steps/individuals/components/functions';

const Component = () => {
    return (
        <React.Fragment>
            <Header />
            <div className="module-content individuals">
                <div className="categories">
                    {getIndividualsTypes().map((individualType, index) => (
                        <IndividualGroup data={individualType} key={index} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
