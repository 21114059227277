import * as Yup from 'yup';
import { filtersLabels } from './definitions';

export const filterSchema = Yup.object({
    type: Yup.string().required().oneOf(['primary', 'or', 'and']),
    key: Yup.string().required(),
    filter: Yup.string().required().oneOf(Object.keys(filtersLabels)), // Use Object.values()
    value: Yup.string().nullable(),
});

export const validationFiltersSchema = Yup.object({
    filters: Yup.array().of(filterSchema).min(1),
});
