import { CircularProgress } from '@mui/material';
import React from 'react';

const Component = (props) => {
    // Not checked at all
    if (props.value === 'unknown') {
        return (
            <div className="icon">
                <i className="elm grey fa-regular fa-question"></i>
            </div>
        );
    }

    // Failed
    if (props.value === 'failed') {
        return (
            <div className="icon">
                <i className="elm red fa-regular fa-xmark"></i>
            </div>
        );
    }

    // Pending
    if (props.value === 'pending') {
        return (
            <div className="icon">
                <CircularProgress size={20} />
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="icon">
                <i className="elm fa-solid green fa-check"></i>
            </div>
        </React.Fragment>
    );
};

export default Component;
