import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

// Context
import { PageContext } from '../../..';

const Component = () => {
    const { isFilteredByProcess, setIsFilteredByProcess } = PageContext();

    const history = useHistory();

    const goToEditInventory = () => {
        history.push('/stages/gdpr-essentials/edit');
    };

    return (
        <React.Fragment>
            <div className="subheader">
                <div className="left-side">
                    <div style={{ marginBottom: 30, marginTop: 14 }}>
                        <Button onClick={goToEditInventory} variant="contained" color="primary">
                            Edit Data Inventory
                        </Button>
                    </div>
                    <div className="question-container">
                        <div className="text">Group by purposes</div>
                        <div className="options">
                            <div onClick={() => setIsFilteredByProcess(true)} className={`option ${isFilteredByProcess ? 'selected' : ''}`}>
                                Yes
                            </div>
                            <div
                                onClick={() => setIsFilteredByProcess(false)}
                                className={`option ${!isFilteredByProcess ? 'selected' : ''}`}
                            >
                                No
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
