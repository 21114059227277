const userEndpoints = {
    sendDataRequestToDPO: {
        link: '/guest/graphql',
        method: 'POST',
        query: `
        mutation ($body: JSON, $companyId: String, $action: JSON) {
            SendDataRequestToDPO(body: $body, companyId: $companyId, action: $action)
        }`,
    },
    GetCompanyScoreAndSteps: {
        link: '/graphql',
        method: 'POST',
        query: `
        query($payload: JSON) {
            GetCompanyScoreAndSteps(payload: $payload)
        }`,
        parserFunction: ({ data }) => data.GetCompanyScoreAndSteps,
    },
    GetHubspotIdentificationToken: {
        link: '/graphql',
        method: 'POST',
        query: `
        query {
            GetHubspotIdentificationToken
        }`,
        parserFunction: ({ data }) => data.GetHubspotIdentificationToken,
    },
    SendSignupEmail: {
        link: `/guest/graphql`,
        method: `POST`,
        query: `
            mutation SendSignupEmail($body: JSON) {
                SendSignupEmail(body: $body)
            }
        `,
    },
    getHSData: {
        link: '/graphql',
        method: 'POST',
        query: `query GetHSData {
            GetHSData {
                email,
                website,
                _companyId
            }
        }        
        `,
    },
    SendFeedbackEmail: {
        link: `/graphql`,
        method: `POST`,
        query: `
            mutation SendFeedbackEmail($body: JSON) {
                SendFeedbackEmail(body: $body)
            }
        `,
    },
    ChargeCookieBanner: {
        link: `/graphql`,
        method: `POST`,
        query: `
            mutation ChargeCookieBanner {
                ChargeCookieBanner
            }
        `,
    },
};

export default userEndpoints;
