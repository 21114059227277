import React from 'react';

const Component = () => {
    return (
        <React.Fragment>
            <div className="page-header">
                <div className="label">Create Security Measure</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
