import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { formatDescription } from '../../../../gdprEssentials/steps/identifyElements';

const Component = (props) => {
    return (
        <React.Fragment>
            <Autocomplete
                value={props.value}
                fullWidth={true}
                multiple={true}
                freeSolo={true}
                disableClearable={true}
                disableCloseOnSelect={true}
                onChange={(_, values) => {
                    // Formatting the value
                    const newValues = values.map((x) => {
                        if (x.newlyCreated) return x.input;
                        if (x.label) return x.label;
                        return x;
                    });

                    props.onDataChanged(newValues);
                }}
                filterOptions={(options, params) => {
                    const { inputValue } = params;
                    const filteredInput = inputValue.toLowerCase().trim();
                    const filtered = options.filter((option) => {
                        const optionInput = option[`label`].toString().toLowerCase().trim();
                        const isMatch = optionInput.includes(filteredInput);
                        return isMatch ? true : false;
                    });

                    if (inputValue !== '' && filtered.length < 1) {
                        const newOption = {
                            newlyCreated: true,
                            label: `Add "${inputValue}"`,
                            input: inputValue.replace(/\*/g, ''),
                        };
                        filtered.push(newOption);
                    }
                    return filtered;
                }}
                isOptionEqualToValue={(option, value) => {
                    return option.label === value ? true : false;
                }}
                renderOption={
                    props.includeDescriptions
                        ? (defaultProps, option) => (
                              <div
                                  {...defaultProps}
                                  className={`autocomplete-review-data-option ${defaultProps['aria-selected'] && 'selected'}`}
                              >
                                  <div className="label">{option[`${'label'}`]} </div>
                                  {option.description && (
                                      <div className="description">{option.description ? formatDescription(option.description) : ''}</div>
                                  )}
                              </div>
                          )
                        : undefined
                }
                groupBy={props.groupBy}
                options={props.options}
                renderInput={(params) => (
                    <TextField variant="outlined" {...params} placeholder={props.value.length < 1 ? props.placeholder : ''} />
                )}
            />
        </React.Fragment>
    );
};

export default Component;
