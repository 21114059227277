import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { ComponentState } from '../../..';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { fileToBase64, logError, trackUserInteraction } from '../../../../../../utils/helpers';

const Component = () => {
    const { data, updateData, setLoading, loading } = ComponentState();

    const attachImage = async () => {
        const elm = document.getElementById('logo-file');
        elm.click();
        await trackUserInteraction(`Selected "Attach image"`, { image: `company-logo` });
    };

    const onImageAttached = async ev => {
        try {
            setLoading(true);
            let file = ev.target.files[0];

            if (file.size > 2000000) {
                window.showAlert('Oops.', `The image size is bigger than 2 MB. Please try a smaller one!`, `error`);
                setLoading(false);
                return false;
            }
            const base64 = await fileToBase64(file);
            const fileExtension = file.type.split('image/')[1];
            await makeEndpointRequest(`UploadCompanyLogo`, {
                data: base64,
                extension: file.type.split('image/')[1],
            });
            await trackUserInteraction(`Company logo updated`, {
                newLogo: `images/company-logo.${fileExtension}`,
            });
            updateData(`companyLogo`, `images/company-logo.${fileExtension}`);
            setLoading(false);
            window.showAlert('Logo updated', `The new logo has been applied and saved.`, `success`);
        } catch (err) {
            window.showAlert('Having Difficulties', `The changes you've made couldn't be saved. Please try again!`, `error`);
            setLoading(false);
            await logError(`UPLOAD_COMPANY_LOGO`, err);
            await trackUserInteraction(`Having Difficulties`, { reason: `failed to update company logo ` });
        }
    };

    const deleteCompanyLogo = async () => {
        try {
            setLoading(true);
            await makeEndpointRequest(`DeleteCompanyLogo`);
            await trackUserInteraction(`Selected "Remove Logo"`);
            updateData(`companyLogo`, null);
            setLoading(false);
            window.showAlert('Logo removed', `The logo has been removed successfully.`, `success`);
        } catch (err) {
            window.showAlert('Having Difficulties', `The changes you've made couldn't be saved. Please try again!`, `error`);
            setLoading(false);
            await logError(`DELETE_COMPANY_LOGO`, err);
            await trackUserInteraction(`Having Difficulties`, { reason: `failed to delete company logo ` });
        }
    };

    const getImagePath = () => {
        if (data.companyLogo !== null) {
            const bucketName = process.env.REACT_APP_S3_CUSTOMER_ASSETS_BUCKET;
            return `https://${bucketName}.s3.eu-west-1.amazonaws.com/${data._id}/${data.companyLogo}?time=${new Date().toISOString()}`; // to avoid cache issues.
        }
        return null;
    };

    return (
        <React.Fragment>
            <div className="form-label mb-less">Logo</div>
            <div className="form-description">Changing this image will update all logos on our products where we feature your logo.</div>
            <div className="buttons">
                <Button variant="outlined" color="primary" disabled={loading} onClick={attachImage}>
                    {data.companyLogo ? `Replace this image` : `Select image`}
                </Button>
                {data && data.companyLogo !== null && (
                    <Button data-cy="remove-logo-button" variant="outlined" color="primary" disabled={loading} onClick={deleteCompanyLogo}>
                        Remove
                    </Button>
                )}
            </div>
            <div className="form-content">
                <input
                    id="logo-file"
                    accept=".png, .jpg, .jpeg"
                    onChange={onImageAttached}
                    type="file"
                    value={''}
                    style={{ display: 'none' }}
                    data-cy="logo-input"
                />
                <div className="image-preview">
                    <div
                        className="logo"
                        style={{
                            backgroundImage: loading || data.companyLogo === null ? '' : `url("${getImagePath()}")`,
                        }}
                    >
                        {loading ? <CircularProgress /> : getImagePath() === null ? <React.Fragment>No logo</React.Fragment> : null}
                    </div>
                </div>
                <div className="container-tips">
                    <div className="tip">
                        * Requirements: File extension must be JPG, JPEG or PNG and the file size can't be larger than 2 MB.
                    </div>
                    <div className="tip">* Photo size recommendation: 380x130 or 200x180</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
