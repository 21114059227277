import React from 'react';

// Context
import { OnboardingContext } from '../../..';

// Components
import { Button, Checkbox } from '@mui/material';

// Context
import { ViewContext } from '..';

const Component = (props) => {
    const { scanWebsite, setScanWebsite, addWebsite } = ViewContext();

    const { companyData } = OnboardingContext();

    return (
        <React.Fragment>
            <div className="footer-section">
                <div className="left-side">
                    <Button variant="outlined" color="primary" size="small" onClick={addWebsite}>
                        Add Another
                    </Button>
                </div>
                <div className="right-side">
                    <div className="checkbox-container">
                        <Checkbox size="small" value={props.scanWebsite} onClick={() => setScanWebsite(!scanWebsite)} />
                        <div className="label">
                            Don't re-scan my {companyData.companyDomains && companyData.companyDomains.length > 1 ? 'domains' : 'domain'}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
