/**
 *
 * @param {*} entity - dpa data
 * @param {*} subProcessors - subProcessors array
 * @returns The sub-processors missing accordingly to our inventory and current tools.
 */

export const getSubProcessorsMissing = (entity, subProcessors) => {
    try {
        // Get this DPA last known sub processors.
        const lastProcessors = entity.meta.lastSubProcessors;
        const currentProcessors = subProcessors;
        const currentTools = entity.data.draft.subProcessors.map((c) => c.label);

        // Check if our current list of processor from data inventory is missing from the last processors.
        const missingProcessors = currentProcessors.filter((c) => {
            // Was this sub-processor an option last time we were editing?
            const missingFromLastKnown = !lastProcessors.includes(c);

            // Is it already a tool ?
            const missingFromCurrentTools = !currentTools.includes(c);

            return missingFromLastKnown && missingFromCurrentTools ? true : false;
        });

        // If we are indeed missing..
        if (missingProcessors.length > 0) return missingProcessors;

        return [];
    } catch (err) {
        throw err;
    }
};
