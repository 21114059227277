import React, { createContext, useContext, useEffect, useState } from 'react';

// Components
import Header from './components/header';
import Footer from './components/footer';
import Domain from './components/domain';

// Context
import { OnboardingContext } from '../..';
import { logError, validateAgainstSchema } from '../../../../../utils/helpers';
import { domainsValidationSchema } from './utils/validation';
import { OnboardingFunctions } from '../../components/functions';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { companyData } = OnboardingContext();
    const { updateCompanyData, generateCompanyDescription, scanWebsites } = OnboardingFunctions();

    const [domains, setDomains] = useState(companyData.companyDomains.length > 0 ? companyData.companyDomains : ['']);
    const [scanWebsite, setScanWebsite] = useState(true);
    const [validationResults, setValidationResults] = useState([]);

    const addWebsite = async () => {
        setDomains((currentState) => [...currentState, ['']]);
    };

    const updateDomain = (index, value) => {
        setDomains((currentState) => {
            let newState = [...currentState];
            newState[index] = value;

            return newState;
        });
    };

    const deleteDomain = (index) => {
        setDomains((currentState) => {
            let newState = [...currentState];
            newState.splice(index, 1);

            return newState;
        });
    };

    /**
     * Once all current domains have been validated we need to update the domains on the back-end.
     * @Reminder -  This function is called when the user moves forward.
     */

    const updateDomains = async () => {
        try {
            // Update monitoring settings
            let monitoringSettings = companyData.monitoringSettings;
            monitoringSettings.domains = domains;

            // Update the domains
            await updateCompanyData({
                companyDomains: domains,
                monitoringSettings,
            });

            // Generate a description if one is not already generated.
            if (companyData.companyDescription.length < 1) {
                generateCompanyDescription(domains);
            }

            // If we want to scan the website.
            if (scanWebsite) {
                scanWebsites(domains);
            }
        } catch (err) {
            await logError(`onboarding.updateDomains`, err);
        }
    };

    /**
     * Validate the current domains from the local state before we can proceed further.
     */

    const validateDomains = async () => {
        try {
            await validateAgainstSchema(domainsValidationSchema, {
                companyDomains: domains,
            });
            setValidationResults({});
        } catch (err) {
            setValidationResults(err);
        }
    };

    useEffect(() => {
        validateDomains();
        // eslint-disable-next-line
    }, [domains]);

    const PassedProps = {
        domains,
        setDomains,
        scanWebsite,
        setScanWebsite,
        addWebsite,
        updateDomains,
        validationResults,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <div className="welcome module-content">
                <div className="desc">
                    Which domains do you want us to scan to find your tools and third parties?
                    <br />
                    This can be your website, web app or subdomain.
                </div>
                {}
                <div className="component shared-component-domain-edits">
                    {domains.map((domain, ix) => (
                        <Domain
                            currentIndex={ix}
                            showDelete={domains.length > 1 ? true : false}
                            key={ix}
                            value={domain}
                            onChange={(value) => updateDomain(ix, value)}
                            onDelete={() => deleteDomain(ix)}
                        />
                    ))}
                    <Footer />
                </div>
            </div>
        </Context.Provider>
    );
};

export default Component;
