import React from 'react';
import { ComponentState } from '../../../index';
import { Switch } from '@mui/material';
import { trackUserInteraction } from '../../../../../../utils/helpers';

const Component = () => {
    const { data, flags, loading, ucSettings, setUcSettings } = ComponentState();

    if (flags.californiaPolicy === false) return null;

    const onSwitch = async () => {
        trackUserInteraction(`Switched "CCPA"`, {
            newState: !ucSettings.ccpa.isActive,
        });
        setUcSettings({
            ...ucSettings,
            ccpa: {
                ...ucSettings.ccpa,
                isActive: !ucSettings.ccpa.isActive,
            },
        });
    };

    return (
        <React.Fragment>
            <div className="form-label">CCPA</div>
            <div className="form-description-with-image">
                <div className="form-description">
                    <p>
                        This feature will modify your cookie banner for California visitors to comply with the California Consumer Privacy
                        Act (CCPA)
                    </p>
                </div>
            </div>
            <div className="form-content">
                <Switch
                    checked={ucSettings.ccpa.isActive ? true : false}
                    color="primary"
                    onClick={onSwitch}
                    disabled={loading || !data.cookieBannerSettings.enabled}
                />
                {/* <div style={{ marginTop: 8 }}>
                    Click <a href="#">here</a> for more documentation and to learn what third-parties will be blocked automatically.
                </div> */}
            </div>
        </React.Fragment>
    );
};

export default Component;
