import React from 'react';
import { ComponentState } from '../../..';
import { trackUserInteraction } from '../../../../../../utils/helpers';

const Component = () => {
    const { data } = ComponentState();

    const liveChatSupport = async () => {
        if (window.HubSpotConversations === undefined && window.location.href.includes('localhost:3000')) return false; // blocking it on localhost since it won't work (chat is disabled)

        await trackUserInteraction(`Selected "Request Support via Chat"`, {
            subject: `privacy-portal`,
            isPrivacyPortalHosted: data.isPrivacyPortalHosted,
        });
        window.HubSpotConversations.widget.open();
    };

    return (
        <React.Fragment>
            <div className="step-entry">
                <div className="step-header">
                    <div className="step-badge">Info</div>
                    <div className="step-label">Support</div>
                </div>
                <div className="step-content">
                    <div className="step-text">
                        If you can't find out how to implement or use this privacy portal on your website,{' '}
                        <a style={{ cursor: 'pointer' }} onClick={liveChatSupport}>
                            click here{' '}
                        </a>{' '}
                        to request support via live chat.
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
