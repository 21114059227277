import React, { useContext, createContext, useState, useEffect } from 'react';
import { AuthStore } from '../../../utils/context/authStore';

// Dependencies
import { logError, trackUserInteraction } from '../../../utils/helpers';
import { makeEndpointRequest } from '../../../utils/endpoints';

// Components
import Container from '../../layout/container';
import Header from './components/header';
import Controls from './components/controls';
import Forms from './components/forms';
import LoadingContainer from './components/loading';
import { useHistory } from 'react-router-dom';

// Context
const Context = createContext({});
export const ModuleState = () => useContext(Context);

const Component = (props) => {
    const history = useHistory();
    const [data, setData] = useState({
        name: '',
        description: '',
        category: null,
        section: null,
        gdprArt32: null,
    });

    const { globalFlags } = AuthStore();
    const [dependencies, setDependencies] = useState({ categories: [], sections: [], gdprArts: [] });

    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const paramsId = props.match.params.id;

    const loadData = async () => {
        try {
            setLoading(true);

            // Get the security measure itself
            const data = await makeEndpointRequest('GetSecurityMeasure', { _id: props.match.params.id });
            setData({ ...data });

            // Get the dependencies
            const res = await makeEndpointRequest('GetRecommendedSecurityMeasures');

            const sections = [],
                categories = [],
                gdprArts = [];

            res.forEach((entry) => {
                if (!sections.includes(entry.details.section)) {
                    sections.push(entry.details.section);
                }

                if (!categories.includes(entry.details.category)) {
                    categories.push(entry.details.category);
                }

                if (!gdprArts.includes(entry.details.gdprArt32) && entry.details.gdprArt32) {
                    gdprArts.push(entry.details.gdprArt32);
                }
            });

            setDependencies({ sections, categories, gdprArts });
            setLoading(false);
        } catch (err) {
            await logError(`LOAD_SECURITY_MEASURE`, err);
        }
    };

    useEffect(() => {
        // IF they don't have access to this Module yet.
        if (globalFlags.securityMeasures !== null && !globalFlags.securityMeasures) {
            history.push('/');
            return false;
        }
        // eslint-disable-next-line
    }, [globalFlags]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    const onSubmit = async () => {
        try {
            setSubmitted(true);
            await trackUserInteraction(`Selected "Save"`);
            await makeEndpointRequest(`UpdateSecurityMeasure`, {
                _id: props.match.params.id,
                payload: {
                    name: data.name,
                    description: data.description,
                    category: data.category,
                    section: data.section,
                },
            });
            setSubmitted(false);
            window.showAlert('Changes Saved', `The changes you've made have been saved.`, `success`);
        } catch (err) {
            await logError(`SUBMIT_SECURITY_MEASURE`, err, data);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
            setSubmitted(false);
        }
    };

    const onDelete = async () => {
        window.showAlert('Confirmation', `Are you sure you want to delete this security measure? This is not reversible.`, 'warning', [
            {
                text: 'Cancel',
                dataCy: `alert-cancel-button`,
                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },
            {
                text: 'Confirm',
                dataCy: `alert-submit-button`,
                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                    try {
                        await trackUserInteraction(`Selected "Delete Security Measure"`, { _id: paramsId });
                        await makeEndpointRequest(`DeleteSecurityMeasure`, { _id: paramsId });
                        history.replace('/security-measures');
                    } catch (err) {
                        await logError(`DELETE_SECURITY_MEASURE`, err, paramsId);
                        await trackUserInteraction(`Having Difficulties`, { reason: 'Failed to delete a security measure' });
                    }
                },
            },
        ]);
    };

    const ContextProps = {
        data,
        setData,
        dependencies,
        setDependencies,
        loading,
        setLoading,
        submitted,
        setSubmitted,
        onSubmit,
        onDelete,
        submitDisabled:
            submitted ||
            loading ||
            data.name.length < 1 ||
            data.description.length < 1 ||
            data.category === null ||
            data.section === null ||
            data.gdprArt32 === null,
    };

    return (
        <Container
            title={`Security Measures - Edit`}
            classNames="page-security-measure-create page-security-measure-shared"
            useAlternateBackground={true}
        >
            <Context.Provider value={ContextProps}>
                <LoadingContainer loading={loading}>
                    <Header />
                    <Controls />
                    <Forms />
                </LoadingContainer>
            </Context.Provider>
        </Container>
    );
};

export default Component;
