import React from 'react';

// Component
import DpoName from './components/dpoName';
import InquiriesEmail from './components/inquiriesEmail';

const Component = () => {
    return (
        <React.Fragment>
            <div className="section-title">Data Protection Officer</div>
            <div className="section-group">
                <DpoName />
                <InquiriesEmail />
            </div>
        </React.Fragment>
    );
};

export default Component;
