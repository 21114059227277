import React from 'react';

// Components
import { MenuItem, Select } from '@mui/material';

// Context
import { ProcessContext } from '..';

// Dependencies
import { personalLegalBasis } from '../../../../../../gdprEssentials/steps/legalResponsibility/utils/dataMaps';

const Component = () => {
    const { data, updateData, hasCompanySensitiveData } = ProcessContext();

    const isFieldDisabled = data.role === 'Processor' || !data.role;

    const onValueChange = (e) => {
        const selection = personalLegalBasis.find((p) => p.string === e.target.value);

        updateData({
            ...data,
            personalLegalBasis: selection,
        });
    };

    return (
        <React.Fragment>
            <div className="personal-legal gdpr-legal" style={hasCompanySensitiveData ? {} : { width: '100%' }}>
                <div className="sub-label">Personal legal basis</div>
                <Select
                    fullWidth={true}
                    disabled={isFieldDisabled ? true : false}
                    value={data.personalLegalBasis ? data.personalLegalBasis.string : 'no-selection'}
                    onChange={onValueChange}
                >
                    <MenuItem value="no-selection" disabled>
                        {data.role === 'Processor' ? `No need for selection` : `Select Option`}
                    </MenuItem>

                    {personalLegalBasis.map((entry, ix) => (
                        <MenuItem value={entry.string} key={ix}>
                            {entry.string}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </React.Fragment>
    );
};

export default Component;
