import React from 'react';

// Context
import { OnboardingFunctions } from '../../../components/functions';
import { Button } from '@mui/material';
import { ViewContext } from '..';
import { OnboardingContext } from '../../..';
import { logError } from '../../../../../../utils/helpers';

const Component = () => {
    const { companyData, tasksPending } = OnboardingContext();
    const { navigateViews, updateCompanyData, generateCompanyActivities } = OnboardingFunctions();
    const { companyDescription } = ViewContext();

    const updateCompanyDescription = async () => {
        try {
            // Update description if is now changed.
            if (companyData.companyDescription !== companyDescription) {
                await updateCompanyData({ companyDescription });
            }
        } catch (err) {
            throw err;
        }
    };

    const nextStep = async () => {
        try {
            // Update
            await updateCompanyDescription();

            // If they have no activities we will generate them now.
            if (companyData.services.length < 1) {
                // Generate the core activities now.
                generateCompanyActivities(companyDescription, companyData.services);
            }

            // Move.
            navigateViews('forward');
        } catch (err) {
            await logError(`onboarding.describeCompany.nextStep`, err);
        }
    };

    const previousStep = async () => {
        try {
            // Update
            await updateCompanyDescription();

            // Move.
            navigateViews('backward');
        } catch (err) {
            await logError(`onboarding.describeCompany.previousStep`, err);
        }
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">How you would describe your business?</div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={nextStep}
                        disabled={companyDescription.length < 1 || tasksPending.generatingDescription}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
