import React from 'react';
import { ViewContext } from '..';

const Component = (props) => {
    const { options, setOptions } = ViewContext();

    return (
        <React.Fragment>
            <div
                onClick={() => setOptions({ ...options, size: props.data })}
                className={options.size === props.data ? `entry selected` : `entry`}
            >
                <div className="label">{props.data}</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
