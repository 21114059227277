import { CircularProgress } from '@mui/material';
import React from 'react';

const Section = (props) => {
    return (
        <div className="section loading">
            <div className="section-content" style={{ padding: 0 }}>
                {props.data === null ? (
                    <React.Fragment>
                        <p className="loading-text">An error occurred, please come back later.</p>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="loading-element">
                            <p className="loading-text">Loading your {props.placeHolderText || 'settings'}...</p>
                            <CircularProgress />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default Section;
