const endpoints = {
    getDashboardDetails: {
        link: '/graphql',
        method: 'POST',
        query: `query GetDashboardDetails   {
            GetDashboardDetails {
                stagesProgress {
                    essentials
                    breach
                    stakeholder
                    regulator
                } 
                personalDataInventory {
                    elements
                    locations
                    processes
                    subjects
                }
                theme {
                    mode
                    color
                }
                companyLegalName
                certificates {                    
                    type
                    _id
                    dateLastVerify
                    dateNextVerify
                    expired
                }
            }
        }`,
        parserFunction: ({ data }) => data.GetDashboardDetails,
    },
    checkPrivacyPortal: {
        link: '/graphql',
        method: 'POST',
        query: `query CheckPrivacyPortal {
            CheckPrivacyPortal {
                email,
                website,
                _companyId
            }
        }        
        `,
    },
    GetCompanySuggestedTools: {
        link: '/graphql',
        method: 'POST',
        query: `query GetCompanySuggestedTools {
            GetCompanySuggestedTools 
            }        
        `,
        parserFunction: ({ data }) => data.GetCompanySuggestedTools,
    },
};

export default endpoints;
