import React, { createContext, useContext, useState } from 'react';

// Components
import Header from './components/header';
import Option from './components/option';

// Imported Context
import { OnboardingContext } from '../..';
import { logError } from '../../../../../utils/helpers';
import { OnboardingFunctions } from '../../components/functions';

// View Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { dataInventory, vitalRecommendations } = OnboardingContext();
    const [checkedOptions, setCheckedOptions] = useState(dataInventory.elements.filter((c) => c.sensitive === false).map((c) => c.label));
    const { deleteElement, createElement } = OnboardingFunctions();

    /**
     * Get the list of options that are listed in this view plus if they're checked or not.
     */

    const getListedOptions = () => {
        let opts = [];

        vitalRecommendations.elements.forEach((element) => {
            opts.push({
                label: element.label,
                description: element.description,
                isChecked: checkedOptions.includes(element.label),
            });
        });

        return opts;
    };

    /** When an option is selected we need to either check or uncheck it. */
    const onOptionSelected = (label) => {
        const action = checkedOptions.includes(label) ? 'delete' : 'insert';

        const onAction = () => {
            setCheckedOptions(action == 'delete' ? checkedOptions.filter((d) => d !== label) : [...checkedOptions, label]);
        };

        if (action === 'delete') {
            window.showAlert(
                'Confirmation',
                `Are you sure about removing ${label}? This will remove it from your data inventory entirely.`,
                'warning',
                [
                    {
                        text: 'Cancel',
                        dataCy: `alert-cancel-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                        },
                    },
                    {
                        text: 'Confirm',
                        dataCy: `alert-submit-button`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            onAction();
                        },
                    },
                ],
            );
            return true;
        }

        onAction();
    };

    /**
     * When the user taps "Next" we will proceed an filter out the elements he left unchecked but were present in the inventory before this stage.
     */

    const filterOptionsFromInventory = async () => {
        try {
            // Get the elements that require deletion
            const deletedElements = dataInventory.elements.filter((c) => c.sensitive === false && !checkedOptions.includes(c.label));

            for (const [index, element] of deletedElements.entries()) {
                // Determinate if this is the last element to delete.
                const isLastElement = index === deletedElements.length - 1;

                // Delete it
                await deleteElement(element._id, isLastElement);
            }
        } catch (err) {
            await logError(`onboarding.personalData.filterOptionsFromInventory`, err);
        }
    };

    /**
     *
     * When the user selected that he uses a certain sensitive element we need to also create it in the db if it doesn't exists.
     */

    const createOptionsInInventory = async () => {
        try {
            for (let i = 0; i < checkedOptions.length; i++) {
                const checkedOption = checkedOptions[i];

                // Check if this checked sensitive element exists
                const exists = dataInventory.elements.find((d) => d.sensitive === false && d.label === checkedOption);
                if (exists) continue;

                // Is this the last one to create? If so we we want to refresh db inventory.
                const isLastCheckedOption = i === checkedOptions.length - 1;

                await createElement({ label: checkedOption, sensitive: false }, isLastCheckedOption);
            }
        } catch (err) {
            await logError(`onboarding.personalData.createOptionsInInventory`, err);
            return false;
        }
    };

    const PassedProps = {
        onOptionSelected,
        filterOptionsFromInventory,
        createOptionsInInventory,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <div className="identify-elements module-content">
                <div className="entries d-flex-row-columns centered">
                    {getListedOptions().map((c, index) => (
                        <Option data={c} key={index} />
                    ))}
                </div>
            </div>
        </Context.Provider>
    );
};

export default Component;
