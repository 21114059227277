const endpoints = {
    CheckPrivacyPortalImplementation: {
        link: '/graphql',
        method: 'POST',
        query: `
            query CheckPrivacyPortalImplementation {
                CheckPrivacyPortalImplementation {
                    result
                    debug
                }
              }
        `,
        timeout: 60000,
        parserFunction: ({ data }) => data.CheckPrivacyPortalImplementation,
    },
    CheckCookieBannerImplementation: {
        link: '/graphql',
        method: 'POST',
        query: `
            query CheckCookieBannerImplementation {
                CheckCookieBannerImplementation {
                    result
                    debug
                }
              }
        `,
        timeout: 60000,
        parserFunction: ({ data }) => data.CheckCookieBannerImplementation,
    },

    GetInventoryAssessment: {
        link: '/graphql',
        method: 'POST',
        query: `
            query GetInventoryAssessment {
                GetInventoryAssessment {
                    result
                }
              }
        `,
        timeout: 60000,
        parserFunction: ({ data }) => data.GetInventoryAssessment,
    },
    GetImplementationsChecks: {
        link: '/graphql',
        method: 'POST',
        query: `
            query GetImplementationsChecks {
                GetImplementationsChecks {
                    privacyPortal
                    cookieBanner
                    inventoryAssessment
                }
              }
        `,
        parserFunction: ({ data }) => data.GetImplementationsChecks,
    },
    // @Reminder: This gets only the pending scans.
    GetCompanyScans: {
        link: '/graphql',
        method: 'POST',
        query: `query GetCompanyScans {
            GetCompanyScans {
                scans
            }
        }        
        `,
        parserFunction: ({ data }) => data.GetCompanyScans,
    },
};

export default endpoints;
