import React from 'react';

// Dependencies
import { uppercaseFirstLetter } from '../../../../../../utils/helpers';

// Components
import { Autocomplete, TextField } from '@mui/material';

const Component = (props) => {
    return (
        <React.Fragment>
            <Autocomplete
                value={props.value}
                fullWidth={true}
                className="input-services"
                disableClearable={true}
                options={[]}
                disabled={props.disabled}
                renderInput={(params) => <TextField fullWidth={true} variant="outlined" {...params} placeholder={props.inputPlaceholder} />}
                freeSolo={false}
                multiple={true}
                filterOptions={(options, params) => {
                    // // Get what we wrote in.
                    const { inputValue } = params;

                    // // Let's prepare our input for easy filtering.
                    const filteredInput = inputValue.toLowerCase().trim();

                    // // Let's check the filtered ones..
                    const filtered = options.filter((option) => {
                        const optionLabel = option.label ? option.label : option;
                        const optionInput = optionLabel.toString().toLowerCase().trim();
                        const isMatch = optionInput.includes(filteredInput);
                        return isMatch ? true : false;
                    });

                    // If they wrote something and there's no filtered results.
                    if (inputValue.length > 0 && filtered.length < 1) {
                        const inputValueFormatted = props.firstLetterUppercase ? uppercaseFirstLetter(inputValue) : inputValue;

                        const newOption = {
                            label: `Add "${inputValueFormatted}"`,
                            inputValue: inputValueFormatted.replace(/\*/g, ''),
                        };

                        filtered.push(newOption);
                    }

                    return filtered;
                }}
                onChange={(_, inputValues) => {
                    // If they add a new one
                    if (inputValues.length > props.value.length) {
                        const newAdded = inputValues[inputValues.length - 1];
                        props.onEntryAdded({ label: newAdded.inputValue, origin: 'manual' });
                    } else {
                        //Updated removed ones
                        props.updateEntries(inputValues);
                    }
                }}
            />
        </React.Fragment>
    );
};

export default Component;
