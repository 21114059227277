import React, { useEffect, useState } from 'react';
import { ComponentState } from '../../..';

// Components
import PreviewIframe from '../../../components/iframePreview';

const Component = () => {
    const { data } = ComponentState();

    const [active, setActive] = useState(false);
    const [display, setDisplay] = useState(false);
    const onActivation = () => {
        setActive(true);
        setTimeout(() => {
            setDisplay(true);
            setTimeout(() => document.dispatchEvent(new CustomEvent('updateSettingsPreviewPanel')), 700);
        }, 700);
    };

    useEffect(() => {
        document.addEventListener('openSettingsPreviewPanel', onActivation);
        return () => {
            document.removeEventListener('openSettingsPreviewPanel', onActivation);
        };
    }, []);

    if (active === false) return null;

    return (
        <React.Fragment>
            <div className={`module-popout`}>
                <div className="module-content">
                    <div className="module-close" onClick={() => setActive(false)} data-cy="panel-close">
                        <i className="icon fa-solid fa-xmark"></i>
                    </div>
                    <div className="module-title">Preview</div>

                    <div className="iframe-container">{display === true && <PreviewIframe dataTrigger={data} />}</div>
                </div>
            </div>
            <div className="module-shadow-underlay" />
        </React.Fragment>
    );
};

export default Component;
