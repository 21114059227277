// Reminder: This whole default colors set is also in this api: CreateSettings
const colors = {
    // Accent default color
    primary: `1d1e44`,
    // Text color
    text: '1f1f1f',
    // Links
    linkIcon: '303030',
    linkFont: '303030',
    // The background color of the main container that pops up
    layerBackground: 'fdfdfd',
    // Border color around divs
    tabsBorderColor: 'dcdcdc', // Grey
    // The second color of the tabs
    secondLayerTab: '1d1e44', // Privasee blue
    // Button Accept
    acceptBtnBackground: '1d1e44', // Privasee blue
    acceptBtnText: 'fdfdfd',
    // Button Deny
    denyBtnBackground: '1d1e44', // Privasee blue
    denyBtnText: 'fdfdfd',
    // Button More
    moreBtnBackground: 'dcdcdc',
    moreBtnText: '1f1f1f',
    // Button save
    saveBtnBackground: 'dcdcdc',
    saveBtnText: '1f1f1f',
};

export default colors;
