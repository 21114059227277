import React, { createContext, useContext, useRef } from 'react';

// Context
import { ModuleState } from '../../..';

// Dependencies
import { logError, trackUserInteraction } from '../../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { getCurrentMatriceEntries, updateStorageLocationRelationships } from '../../../../processes/utils/functions';
import { convertMatriceEntriesToProcesses } from '../../../utils/functions';

// Context
const Context = createContext({});
export const FunctionsContext = () => useContext(Context);

const Component = (props) => {
    const { data: dataInventory, companyData, panelData, setPanelData } = ModuleState();
    const { refreshDataInventory, refreshCompanyData } = ModuleState();

    // This will be used so we can set the panel's view and more from outside it.
    const panelController = useRef(null);

    const closePanel = () => {
        // Close the panel
        panelController.current.modal.current.close(() => {
            // Reset it.
            setPanelData({ _id: null, method: null });
        });
    };

    /**
     * This function is used to generate the data required for the panel interface.
     * @returns
     */

    const getInitialData = () => {
        // Get the data of the individual
        const currentData = panelData.method === 'edit' ? dataInventory.individuals.find((c) => c._id === panelData._id) || {} : {};

        // The array of tools that are linked to this process.
        let processes = [];

        let individualReferring = {
            isChildren: null,
            label: currentData.label || '',
            referringRole: null,
        };

        // If there is an id let's convert current matrices to tools linked format of data.
        if (currentData._id) {
            processes = convertMatriceEntriesToProcesses(dataInventory, currentData._id);
            let matchReferring = companyData.individualsReferring.find((c) => c.label === currentData.label);
            if (matchReferring) {
                individualReferring = {
                    label: matchReferring.label,
                    isChildren: matchReferring.isChildren === undefined ? null : matchReferring.isChildren,
                    referringRole: matchReferring.referringRole || null,
                };
            }
        }

        // @Bugfix: we need to do the json parse, json stringify to remove any link to the useState, otherwise when you changed something then simply closed the panel, the data inventory was updated in hook state.

        return JSON.parse(
            JSON.stringify({
                _id: currentData._id || null,
                label: currentData.label || '',
                description: currentData.description || '',
                individualReferring,
                processes,
            }),
        );
    };

    /**
     * This is a function that helps us get all the Matrice Entries linked to a storage location.
     * @param {*} _storageLocationId - ._id of storage location.
     * @param {*} tools
     * @returns
     */

    const convertProcessesToMatriceEntries = (_storageLocationId, _individualId, processes) => {
        try {
            // Get current matrice entries for this storage location.
            const entries = [];

            // Iterate through all the processes.
            processes.forEach((process) => {
                const matchStorageLocation = process.storageLocations.find((c) => c._id === _storageLocationId);
                if (!matchStorageLocation) return; // This process is not associated with this storage location.

                matchStorageLocation.elements.forEach((_elementId) => {
                    entries.push({
                        _processId: process._processId,
                        _elementId,
                        _individualId,
                    });
                });
            });

            return entries;
        } catch (err) {
            logError(`module.individuals.convertProcessesToMatriceEntries`, err);
            throw err;
        }
    };

    /**
     * This function will set the matrice entries based on the processes array from the panel data.
     * @param {*} _individualId
     * @param {*} tools
     * @returns
     */

    const setMatriceEntries = async (_individualId, processes) => {
        try {
            // We'll refresh data inventory to make sure we'll not overwrite existing matrice entries.
            const inventoryUpdated = await refreshDataInventory();

            // We'll iterate through each tool in storage location.
            for (const storageLocation of inventoryUpdated.storageLocations) {
                try {
                    // Get current matrice entries for this storage location.
                    const matrixEntries = convertProcessesToMatriceEntries(storageLocation._id, _individualId, processes);

                    // We'll go through this storage location only if it HAD this individual or if we have matrice entries for it now.
                    if (!storageLocation.individuals.includes(_individualId) && matrixEntries.length < 1) continue;

                    // Get current matrice entries from DB.
                    let entries = await getCurrentMatriceEntries(storageLocation._id, inventoryUpdated);

                    // Remove existing ones from this tool linked to this process
                    entries = entries.filter((c) => c._individualId !== _individualId);

                    const newEntries = [
                        // Existing entries (not related to this process)
                        ...entries,
                        // This new individual entries
                        ...matrixEntries,
                    ];

                    // Set the new processes.
                    await makeEndpointRequest(`SetStorageMatriceEntries`, {
                        _id: storageLocation._id,
                        entries: newEntries,
                    });

                    // Update location data to reflect that is using this process id.
                    await updateStorageLocationRelationships(storageLocation, newEntries);
                } catch (err) {
                    await logError(`module.individuals.setMatriceEntries.toolIterated`, err, {
                        storageLocationId: storageLocation._id,
                        _individualId,
                    });
                }
            }

            // Refresh the inventory again now to apply relationships too
            refreshDataInventory();
        } catch (err) {
            await logError(`module.individuals.setMatriceEntries`, err, { _processId, tools });
            return false;
        }
    };

    /**
     * A simple function that creates the process in our database and then sets the matrice entries.
     * @param {*} param.data - The data from the panel
     * @param {*} param.controller - The controller to control the panel from within (set tab etc)
     * @param {*} param.validation - A validation object that informs you if the form was valid and if not which one.
     */

    const createIndividual = async ({ data }) => {
        try {
            trackUserInteraction(`Selected "Create Process"`, { individualName: data.label });

            // Close panel
            closePanel();

            // By default is an nothing set
            let individualReferring = null;

            if (data.individualReferring.referringRole !== null || data.individualReferring.isChildren !== null) {
                individualReferring = {
                    referringRole: data.individualReferring.referringRole || undefined,
                    isChildren: data.individualReferring.isChildren || undefined,
                };
            }

            // Create te process
            const individual = await makeEndpointRequest(`CreateIndividual`, {
                payload: {
                    label: data.label,
                    description: data.description,
                },
                individualReferring,
            });

            // Update the data matrice accordingly (While filtering out unfinished tools)
            // @Reminder: this will also update data inventory and set the new process in the data inventory.
            setMatriceEntries(individual._id, data.processes);

            // Refresh company data now to make sure individuals referring roles are appearing.
            refreshCompanyData();
        } catch (err) {
            await logError(`module.individuals.createIndividual`, err, { data });
        }
    };

    /**
     * A simple function that updates the individual in our database and then sets the matrice entries.
     * @param {*} param.data - The data from the panel
     * @param {*} param.controller - The controller to control the panel from within (set tab etc)
     * @param {*} param.validation - A validation object that informs you if the form was valid and if not which one.
     */

    const updateIndividual = async ({ data }) => {
        try {
            trackUserInteraction(`Selected "Update Individual"`, { individualName: data.label });

            // Close panel
            closePanel();

            // Update process
            await makeEndpointRequest(`UpdateIndividual`, {
                _id: data._id,
                payload: {
                    label: data.label,
                    description: data.description,
                },
                individualReferring: {
                    referringRole: data.individualReferring.referringRole || 'others',
                    isChildren: data.individualReferring.isChildren || false,
                },
            });

            // Update matrice
            setMatriceEntries(data._id, data.processes);

            // Refresh company data now to make sure individuals referring roles are appearing.
            refreshCompanyData();

            // // eslint-disable-next-line
            // console.log(`update`, { data, controller, validation });
        } catch (err) {
            await logError(`module.individual.updateIndividual`, err, { data });
        }
    };

    /**
     * A simple function that deletes the individual from our database and then sets the matrice entries to remove any links to this process.
     * @param {*} param.data - The data from the panel
     */

    const deleteIndividual = async ({ data }) => {
        try {
            trackUserInteraction(`Selected "Delete Individual"`, { individualName: data.label });

            // Close panel to avoid crashes
            closePanel();

            // Delete it using the API
            await makeEndpointRequest(`DeleteIndividual`, { _id: data._id });

            // Refresh inventory to be squeaky clean.
            refreshDataInventory();
        } catch (err) {
            await logError(`module.individuals.deleteIndividual`, err, { data });
        }
    };

    const PassedProps = {
        panelController,
        closePanel,
        getInitialData,
        // Functions
        createIndividual,
        updateIndividual,
        deleteIndividual,
    };

    return <Context.Provider value={PassedProps}>{props.children}</Context.Provider>;
};

export default Component;
