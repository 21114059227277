// Same code is also in the back-end of the app utils, please make sure to keep them in sync.

export const storageIsInvalid = (storage) => {
    let isInvalid = false;
    if (storage.matrixMap.length < 1) {
        isInvalid = true;
    } else if (
        storage.processes.filter((process) => {
            const existsMap = storage.matrixMap.find((mp) => mp.process === process.label);
            if (existsMap) return false;
            return true;
        }).length > 0
    ) {
        isInvalid = true;
    } else if (storage.processes.length < 1) {
        isInvalid = true;
    } else if (storage.elements.length < 1) {
        isInvalid = true;
    } else if (
        storage.elements.filter((element) => {
            const existsMap = storage.matrixMap.find((mp) => mp.element === element.label);
            if (existsMap) return false;
            return true;
        }).length > 0
    ) {
        isInvalid = true;
    } else if (storage.individuals.length < 1) {
        isInvalid = true;
    } else if (
        storage.individuals.filter((indiv) => {
            const existsMap = storage.matrixMap.find((mp) => mp.individual === indiv.label);
            if (existsMap) return false;
            return true;
        }).length > 0
    ) {
        isInvalid = true;
    } else if (storage.dataResidency.length < 1 || storage.securityMeasures.length < 1) {
        isInvalid = true;
    }
    return isInvalid;
};
