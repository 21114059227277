import { useEffect } from 'react';

// Context
import { logError } from '../../../../../../utils/helpers';
import { PanelContext } from '..';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { convertMatriceToMatriceView } from '../../storageLocations/components/editTools/utils/helpers';

const Component = () => {
    const { setData, dataInventoryRef, functions } = PanelContext();

    /**
     * When we add a process like Customer Support we need to load the process recommendations.
     */

    const loadProcessRecommendations = async ({ detail }) => {
        try {
            // Get the process they added
            const { processAdded } = detail;

            // Get the recommendations
            let recommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: [],
                processes: [processAdded.label],
            });

            // If there's no rec we do nothing.
            const rec = recommendations.processes[0];
            if (!rec) return false;

            // These are the individuals we collect data from when using this Process.
            let individuals = [];

            for (const label of rec.subjectCategories) {
                // Does this individual exist in the data inventory?
                let inventory = dataInventoryRef.current.individuals.find((c) => c.label === label);

                // If they don't exist
                if (!inventory) {
                    inventory = await functions.createIndividual(label);
                }

                // Add it.
                individuals.push(inventory._id);
            }

            // These are the data categories we collect from the individuals above.
            const elements = [];

            // Let's put them together
            let recElements = [
                ...rec.elementCategories.map((c) => ({ label: c, sensitive: false })),
                ...rec.specialCategoryElements.map((c) => ({ label: c, sensitive: true })),
            ];

            for (const row of recElements) {
                // Does this individual exist in the data inventory?
                let inventory = dataInventoryRef.current.elements.find((c) => c.label === row.label);

                // If they don't exist
                if (!inventory) {
                    inventory = await functions.createElement({
                        label: row.label,
                        sensitive: row.sensitive,
                    });
                }

                // Add it.
                elements.push(inventory._id);
            }

            // Let's prepare the matrix entries now.
            let additionalMatrixEntries = [];

            // This will be for the matrix
            for (const individual of individuals) {
                for (const element of elements) {
                    additionalMatrixEntries.push({
                        _processId: processAdded._id,
                        _elementId: element,
                        _individualId: individual,
                    });
                }
            }

            // Convert these additional matrix entries to map view.
            const matrixViewEntries = convertMatriceToMatriceView(additionalMatrixEntries);

            // If this process had recommendations and now we need to set up the matrix map entries
            if (matrixViewEntries.length > 0) {
                setData((currentState) => {
                    let newState = { ...currentState };

                    // Get the index of the process
                    let mIndex = newState.matrixMap.findIndex((p) => p._processId === processAdded._id);

                    // If is not here it means we called this event too fast.
                    if (mIndex === -1) return newState;

                    newState.matrixMap[mIndex].individuals = matrixViewEntries[0].individuals;

                    return newState;
                });
            }
        } catch (err) {
            await logError(`thirdParties.loadProcessRecommendations`, err, { detail });
        }
    };

    useEffect(() => {
        document.addEventListener('thirdParties:onProcessAdded', loadProcessRecommendations);
        return () => {
            document.removeEventListener('thirdParties:onProcessAdded', loadProcessRecommendations);
        };
        // eslint-disable-next-line
    }, []);

    return null;
};

export default Component;
