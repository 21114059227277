import React from 'react';
import { SettingsContext } from '../../..';
import { FormHelperText, MenuItem, Select } from '@mui/material';

import { entries } from '../../../../gdprEssentials/steps/audience/index';
const Component = () => {
    const { data, updateData, validationKeys, loading } = SettingsContext();

    const onDataChanged = (ev) => {
        const newValue = ev.target.value.filter((c) => c !== 'none'); // Bugfix.

        updateData('targetAudience', newValue);
    };
    return (
        <React.Fragment>
            <div className="form-group">
                <div className="label">Sales Target</div>
                <Select
                    disabled={loading}
                    multiple={true}
                    value={data.targetAudience.length > 0 ? data.targetAudience : ['none']}
                    onChange={onDataChanged}
                >
                    {data.targetAudience.length < 1 && (
                        <MenuItem disabled={true} value="none">
                            Click here to select
                        </MenuItem>
                    )}
                    {entries.map((c, index) => (
                        <MenuItem key={index} value={c.value}>
                            {c.label}
                        </MenuItem>
                    ))}
                </Select>
                {validationKeys[`targetAudience`] && !loading && (
                    <FormHelperText error={true}>{validationKeys['targetAudience']}</FormHelperText>
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
