import React from 'react';

// Components
import { Button } from '@mui/material';

// Context
import { OnboardingFunctions } from '../../../components/functions';
import { OnboardingContext } from '../../..';
import { ViewContext } from '..';

const Component = () => {
    const { filterOptionsFromInventory, createOptionsInInventory } = ViewContext();
    const { navigateViews } = OnboardingFunctions();
    const { dataInventory } = OnboardingContext();

    const nextStep = async () => {
        // filter out the options unchecked now.
        filterOptionsFromInventory();

        // Create the ones mentioned
        createOptionsInInventory();

        // Move on.
        navigateViews('forward');
    };

    const previousStep = () => {
        navigateViews('backward');
    };

    const getDescription = () => {
        let stringLabel = `data subjects`; // default

        // If the data inventory of individuals is not empty we'll show the first 5 labels
        if (dataInventory.individuals.length > 0) {
            stringLabel = dataInventory.individuals
                .map((c) => c.label)
                .slice(0, 5)
                .join(', ');
        }

        return `Based on your ${stringLabel} it looks like you may be using these personal data categories.`;
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">Data Categories</div>
                    <div className="subtitle">{getDescription()}</div>
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" onClick={nextStep} data-cy="next-button">
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
