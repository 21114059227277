import React from 'react';

// Context
import { PageContext } from '../../..';

const Component = (props) => {
    const { setEditingToolId } = PageContext();

    const goBack = () => {
        setEditingToolId(null);
    };

    return (
        <React.Fragment>
            <div className="header" style={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                <div className="left-side" style={{ display: 'flex', alignItems: 'center' }}>
                    <div onClick={goBack}>
                        <i className="icon fa-solid fa-chevron-left"></i>
                    </div>

                    <div className="label">{props.toolName}</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
