import React from 'react';

// Components
import { Button } from '@mui/material';
import { PanelContext } from '../../..';

const Component = () => {
    const { data, functions, closePanel } = PanelContext();

    const deleteTool = () => {
        window.showAlert(
            'Confirmation',
            `Are you sure you want to delete ${data.label}? If you are sure, confirm by writing "Delete" in the input below.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        // Hide the alert..
                        dismissAlert();
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert, inputValue }) => {
                        // If they didn't enter the right one.
                        if (inputValue.toString().toLowerCase() !== 'delete') {
                            return false;
                        }

                        dismissAlert();
                        closePanel(true);
                        functions.delete();
                    },
                },
            ],
            {
                input: {
                    type: 'text',
                    placeholder: 'Delete',
                    validationField: ({ inputValue }) => {
                        if (inputValue.toString().toLowerCase() !== 'delete') return 'Invalid confirmation message.';

                        return null; // no error, all good.
                    },
                },
            },
        );
    };

    return (
        <React.Fragment>
            <div style={{ maxWidth: 800, width: '100%' }}>
                <hr style={{ borderColor: 'rgba(255,255,255, 0.07)', marginTop: 36, marginBottom: 12 }} />
            </div>
            <div className="component-section" style={{ maxWidth: 800 }}>
                <h4>Delete</h4>

                <p style={{ fontSize: 14, color: 'rgba(255,255,255,0.8)' }}>
                    Deleting this tool removes it from your policies permanently. If you're sure it's no longer needed, proceed with
                    caution, as this action is irreversible. Once deleted, the tool won't be mentioned or considered in your policies.
                </p>

                <Button
                    variant="contained"
                    color="primary"
                    disabled={!data.archived}
                    style={{ marginTop: 16 }}
                    onClick={deleteTool}
                    data-cy="btn-delete"
                >
                    {data.archived ? 'Delete this tool or third party' : 'You can only delete archived Tools'}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Component;
