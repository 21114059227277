import * as Yup from 'yup';
import { websiteValidationScheme } from '../../../gdprEssentials/utils/validations';

export const editValidationSchema = Yup.object({
    label: Yup.string().required('This field is required.').trim().label('This'),
    dataResidency: Yup.string().trim().label('This').required('This field is required.'),
    url: websiteValidationScheme,
    securityMeasuresTitle: Yup.string().required('This field is required.').trim().label('This'),
    securityMeasuresURL: Yup.string()
        .test('isEmailOrWebsite', 'Must be a valid URL or email', (value) => {
            if (value && value.includes('@')) return Yup.string().required().email().isValidSync(value);
            return websiteValidationScheme.isValidSync(value);
        })
        .label('This')
        .required('This field is required.'),
});
