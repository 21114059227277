import { TextField } from '@mui/material';
import React from 'react';
import { EntryState } from '..';

const Component = () => {
    const { data, setData, isEditing } = EntryState();

    return (
        <React.Fragment>
            <div className="component-field" style={{ width: '100%' }}>
                <div style={{ fontWeight: 500, fontSize: 16, marginBottom: 16 }}>Answer</div>
                <TextField
                    value={data.answer}
                    className="component-answer"
                    onChange={(e) => setData({ answer: e.target.value })}
                    multiline={true}
                    disabled={!isEditing}
                    rows={3}
                    fullWidth={true}
                    placeholder="Write your answer here.."
                />
            </div>
        </React.Fragment>
    );
};

export default Component;
