import React, { useState } from 'react';

// Panel Context
import { PanelContext } from '../../../..';

// Components
import Button from './components/button';
import SetProcesses from '../../shareableComponents/setProcesses';
import { logError, trackUserInteraction } from '../../../../../../../../utils/helpers';

const Component = (props) => {
    const { vitalRecommendations, dataInventory, setDataInventory, companyData, data, setData } = PanelContext();
    const [enabled, setEnabled] = useState(false);

    /**
     * This is called when a process has been added.
     * @param {*} inventoryProcess Data inventory - Process.
     * @param {*} isCreated - If the process needed to be created first or not.
     */

    const onProcessAdded = async (inventoryProcess) => {
        try {
            // Add the individual to the panel's tool list of individuals.
            setData((currentState) => {
                let newState = { ...currentState };

                newState.processes.push({
                    _processId: inventoryProcess._id,
                    storageLocations: [],
                });

                return newState;
            });

            trackUserInteraction(`Added new process to Individual`, {
                label: inventoryProcess.label,
            });
        } catch (err) {
            await logError(`individuals.onProcessAdded`, err, { inventoryProcess });
        }
    };

    /**
     * This is called when the process has been removed.
     * @param {*} processRemoved { _id, label}
     */

    const onProcessRemoved = async (processRemoved) => {
        try {
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the process removed
                const index = newState.processes.findIndex((c) => c._processId === processRemoved._id);
                if (index === -1) return newState;

                newState.processes.splice(index, 1);

                return newState;
            });

            trackUserInteraction(`Removed process from Individual`, {
                label: processRemoved.label,
            });
        } catch (err) {
            await logError(`individuals.onProcessRemoved`, err, { processRemoved });
        }
    };

    return (
        <React.Fragment>
            <Button setEnabled={setEnabled} enabled={enabled} />
            {enabled && (
                <SetProcesses
                    // Closing
                    closeModal={() => setEnabled(false)}
                    // Requirements
                    vitalRecommendations={vitalRecommendations}
                    dataInventory={dataInventory}
                    setDataInventory={setDataInventory}
                    companyData={companyData}
                    // Text
                    textContents={{
                        heading: `Processes for Individual ${data.label}`,
                        description: `Which processes collect data from the Individual ${data.label}?`,
                    }}
                    // Current data: This is the entries from mapProcessesEntriesToDataInventory
                    currentProcesses={props.entries.map((c) => ({ _id: c.data._id, label: c.data.label }))}
                    // Callbacks
                    onProcessAdded={onProcessAdded}
                    onProcessRemoved={onProcessRemoved}
                />
            )}
        </React.Fragment>
    );
};

export default Component;
