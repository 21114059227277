import React from 'react';
import { addEllipsisIfExceedsMaxLength, logError, trackUserInteraction } from '../../../../../../../../../utils/helpers';

// Components
import EditElements from '../../../shareableComponents/setElements';

// Context
import { PanelContext } from '../../../../..';
import { getToolRecommendations } from '../../../../../../../thirdParties/components/storageLocations/components/editTools/utils/helpers';

const Component = (props) => {
    const { vitalRecommendations, dataInventory, toolsRecommendations, setDataInventory, setData } = PanelContext();

    // Get recommendations avatar
    const getAvatar = () => {
        const recommendation = getToolRecommendations(props.storageLocation.label, toolsRecommendations);
        return recommendation ? recommendation.image : '';
    };

    /**
     *
     * @param {*} inventoryElement
     * @param {*} isCreated
     * @returns
     */

    const elementAdded = async (inventoryElement) => {
        try {
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the current process.
                const processIndex = newState.processes.findIndex((c) => c._processId === props.process._id);
                if (processIndex === -1) return newState;

                // Get the index of current storage Locations
                const toolIndex = newState.processes[processIndex].storageLocations.findIndex((c) => c._id === props.storageLocation._id);
                if (toolIndex === -1) return newState;

                // Add element
                newState.processes[processIndex].storageLocations[toolIndex].elements.push(inventoryElement._id);

                return newState;
            });

            trackUserInteraction(`Added data category to Storage Location`, {
                storageLocation: props.storageLocation.label,
                element: inventoryElement.label,
                process: props.process.label,
            });
        } catch (err) {
            await logError(`individuals.elementAdded`, err, { inventoryElement });
            return false;
        }
    };

    const elementRemoved = async (elementRemoved) => {
        try {
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the current process.
                const processIndex = newState.processes.findIndex((c) => c._processId === props.process._id);
                if (processIndex === -1) return newState;

                // Get the index of current storage Locations
                const toolIndex = newState.processes[processIndex].storageLocations.findIndex((c) => c._id === props.storageLocation._id);
                if (toolIndex === -1) return newState;

                // Add element
                newState.processes[processIndex].storageLocations[toolIndex].elements = newState.processes[processIndex].storageLocations[
                    toolIndex
                ].elements.filter((id) => id !== elementRemoved._id);

                return newState;
            });

            trackUserInteraction(`Removed data category from Storage Location`, {
                storageLocation: props.storageLocation.label,
                element: elementRemoved.label,
                process: props.process.label,
            });
        } catch (err) {
            await logError(`individuals.elementRemoved`, err, { elementRemoved });
            return false;
        }
    };

    return (
        <React.Fragment>
            <div className="--component-entry">
                <div className="individual">
                    <div className={`label ${props.elements.length < 1 && 'invalid'}`} style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            className="image"
                            style={{
                                backgroundImage: `url("${getAvatar()}")`,
                                backgroundColor: 'grey',
                                height: 22,
                                width: 22,
                                borderRadius: '6px',
                                backgroundSize: 'contain',
                                marginRight: 6,
                            }}
                        ></div>
                        {addEllipsisIfExceedsMaxLength(props.storageLocation.label, 20)}
                    </div>

                    {props.elements.length < 1 && (
                        <div className="warning">
                            <i className="icon fa-solid fa-triangle-exclamation"></i>
                        </div>
                    )}
                </div>
                <div className="elements">
                    <EditElements
                        vitalRecommendations={vitalRecommendations}
                        // Data Inventory
                        dataInventory={dataInventory}
                        setDataInventory={setDataInventory}
                        // Texts
                        textContents={{ placeholder: 'Click here to select the data categories' }}
                        // Current Data
                        currentElements={props.elements.map((c) => ({ _id: c._id, label: c.data.label }))}
                        // Callbacks
                        onElementAdded={elementAdded}
                        onElementRemoved={elementRemoved}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
