import React from 'react';

// Context
import { OnboardingFunctions } from '../../../components/functions';
import { Button } from '@mui/material';

const Component = () => {
    const { navigateViews } = OnboardingFunctions();

    const nextStep = () => {
        navigateViews('forward');
    };

    const previousStep = () => {
        navigateViews('backward');
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">Individuals</div>
                    <div className="subtitle">The people who’s data you collect and process</div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        // disabled={dataInventory.storageLocations.length < 1}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
