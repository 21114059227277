import React from 'react';

// Components
import { FormHelperText, MenuItem, Select } from '@mui/material';

// Context
import { ViewContext } from '..';
import { PanelContext } from '../../..';
import { getIndividualsTypes } from '../../../../../gdprEssentials/steps/individuals/components/functions';

const Component = () => {
    const { validationResults } = ViewContext();
    const { data, setData } = PanelContext();

    const getSingularLabel = (label) => {
        const adjustedLabelToSingular = {
            Prospects: 'Prospect',
            Customers: 'Customer',
            Staff: 'Staff',
            Others: 'Other',
        };

        if (!adjustedLabelToSingular[label]) return label;

        return adjustedLabelToSingular[label];
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Type</div>
                </div>
                <div className="component-content">
                    <Select
                        value={data.individualReferring.referringRole || 'none'}
                        fullWidth={true}
                        data-cy="selector-type"
                        onChange={(ev) =>
                            setData({
                                ...data,
                                individualReferring: {
                                    ...data.individualReferring,
                                    referringRole: ev.target.value,
                                },
                            })
                        }
                    >
                        {data.individualReferring.referringRole === null && (
                            <MenuItem value="none" disabled={true}>
                                Select an option here
                            </MenuItem>
                        )}
                        {getIndividualsTypes().map((c) => (
                            <MenuItem key={c.value} value={c.value}>
                                {getSingularLabel(c.label)}
                                {c.description ? ': ' : ''}
                                {c.description && <span style={{ marginLeft: 4, color: 'rgba(255,255,255,0.6)' }}>{c.description}</span>}
                            </MenuItem>
                        ))}
                    </Select>
                    {validationResults[`individualReferring.referringRole`] !== undefined && (
                        <FormHelperText error={true}>{validationResults['individualReferring.referringRole']}</FormHelperText>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
