import React, { useEffect } from 'react';

import { TextField, Autocomplete } from '@mui/material';

// Context
import { ModuleState } from '../../..';
import { ModuleAPI } from '../../../components/api';

const Component = () => {
    const { data, setStep } = ModuleState();
    const { setIndividualIsChildren } = ModuleAPI();

    const getData = (isAdded = false) => {
        let individuals = data.companyInfo.individualsReferring.filter((c) => c.isChildren === isAdded).map((c) => c.label);

        return individuals.sort((a, b) => a.localeCompare(b));
    };

    const onIndividualsDataChanged = (inputValue) => {
        // Get current list
        const currentList = getData(true);

        // Is adding a new one
        if (inputValue.length > currentList.length) {
            let latestAdded = inputValue[inputValue.length - 1];
            if (!latestAdded) return false; // error?

            // Marking a new one..
            setIndividualIsChildren(latestAdded, true);
        }

        // Is removing one
        if (inputValue.length < currentList.length) {
            // Get the one missing from input values
            const missingOne = currentList.find((c) => !inputValue.includes(c));

            if (!missingOne) return; // error?

            // Marking untrue
            setIndividualIsChildren(missingOne, false);
        }
    };

    const resetChildrenIndividuals = () => {
        // When they just said they do not process data we must set it to false.
        getData(true).forEach((individual) => {
            setIndividualIsChildren(individual, false);
        });
    };

    useEffect(() => {
        document.addEventListener('children:resetIndividualsWithChildren', resetChildrenIndividuals);

        return () => {
            document.removeEventListener('children:resetIndividualsWithChildren', resetChildrenIndividuals);
        };

        // eslint-disable-next-line
    }, []);

    const goToIndividuals = () => {
        setStep('Individuals');
    };
    // We don't render if is not processing.
    if (!data.companyInfo.processChildrenData) return null;

    return (
        <React.Fragment>
            <div className="form-entry">
                <div className="label">Which of the provided individuals might be under age?</div>
                <div className="content">
                    <Autocomplete
                        value={getData(true)}
                        fullWidth={true}
                        className="input-individuals"
                        disableClearable={true}
                        options={getData(false)}
                        disabled={!data.companyInfo.processChildrenData}
                        freeSolo={false}
                        renderInput={(params) => (
                            <TextField
                                fullWidth={true}
                                variant="outlined"
                                {...params}
                                placeholder={getData(true).length < 1 ? 'Click here to select the individuals..' : undefined}
                            />
                        )}
                        multiple={true}
                        onChange={(_, inputValues) => onIndividualsDataChanged(inputValues)}
                    />
                </div>
                <div className="subText" style={{ color: 'rgba(255,255,255,0.8)', fontSize: 14, marginTop: 16 }}>
                    Can't find the right individual? Add it on the{' '}
                    <a href="#" className="color-gold" onClick={goToIndividuals}>
                        "Individuals Step"
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
