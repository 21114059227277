import React from 'react';
import { SettingsContext } from '../../..';
import { FormHelperText, MenuItem, Select } from '@mui/material';

import { sizes } from '../../../../gdprEssentials/steps/companyInsights/index';

const Component = () => {
    const { data, updateData, loading, validationKeys } = SettingsContext();

    const onDataChanged = (ev) => {
        updateData('companyInsights.size', ev.target.value);
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="label">Company Size</div>
                <Select disabled={loading} value={data.companyInsights.size} onChange={onDataChanged}>
                    {sizes.map((entry, index) => (
                        <MenuItem key={index} value={entry}>
                            {entry}
                        </MenuItem>
                    ))}
                </Select>
                {validationKeys[`companyInsights.size`] && !loading && (
                    <FormHelperText error={true}>{validationKeys['companyInsights.size']}</FormHelperText>
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
