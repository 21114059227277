import React from 'react';

// Context
import { PanelContext } from '../../../../..';

const Component = (props) => {
    const { setData } = PanelContext();

    const onRemove = () => {
        const deleteFunc = () => {
            setData((currentState) => {
                let newState = { ...currentState };

                const processIndex = newState.processes.findIndex((c) => c._processId === props.data._id);
                if (processIndex === -1) return newState;

                newState.processes.splice(processIndex, 1);

                return newState;
            });
        };

        window.showAlert('Confirmation', `Do you confirm that you wish to unlink this process from the current individual?`, 'warning', [
            {
                text: 'Cancel',
                dataCy: `alert-cancel-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },

            {
                text: `Confirm`,
                dataCy: `alert-submit-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                    deleteFunc();
                },
            },
        ]);
    };

    return (
        <React.Fragment>
            <div className="component-btn" onClick={onRemove}>
                <i className="icon fa-solid fa-times-circle"></i>
                <div className="label">Unlink Data Process</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
