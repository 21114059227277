import React from 'react';

const Component = (props) => {
    return (
        <React.Fragment>
            <div className="entry create" data-cy="create-entry-button" onClick={props.onClick}>
                <div className="content">
                    <i className="icon fa-solid fa-circle-plus"></i>
                    <div className="label">Add third party or tool</div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default Component;
