import React, { useEffect, useState, createContext, useContext } from 'react';
import lodash from 'lodash';

// Dependencies
import { logError, trackUserInteraction, useStateRef } from '../../../utils/helpers';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { mapSlugsToSteps, mappedSteps } from './components/mappings';

// Components
import Container from '../../layout/container';
import Tooltip from '../../components/tooltip';
import API from './components/api';

// Context
import { AuthStore } from '../../../utils/context/authStore';
import { isStepMoreAdvanced } from './components/functions';

const Context = createContext({});
export const ModuleState = () => useContext(Context);

const Stage = (props) => {
    const [data, setData, dataRef] = useStateRef({
        companyInfo: {
            targetAudience: [],
            industry: [],
            services: [],
            dpoName: '',
            inquiriesEmail: '',
            companyLegalName: '',
            companyDomains: [''],
            companyInsights: {
                size: null,
                type: null,
            },
            companyDescription: '',
            processChildrenData: false,
            companyAddress: {
                website: '',
                line1: '',
                line2: '',
                city: '',
                postCode: '',
                country: '',
            },
            individualsReferring: [],
        },
        storageLocations: [],
        processesDependencies: [],
        checkedData: {
            elements: [],
            individuals: [],
        },
        _meta: {
            domainLastScannedAt: null,
            biggestStep: 'Welcome',
            // Description generated
            companyDescriptionGenerated: false,
            companyDescriptionGeneratedUses: 0,
            companyDescriptionLastGeneratedAt: null,
            // Activities generated
            companyActivitiesGenerated: false,
            companyActivitiesGeneratedUses: 0,
            companyActivitiesLastGeneratedAt: null,
        },
    });
    // @Reminder: Update session schema data structure.

    // Module necessities
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState('Welcome');
    const [submitted, setSubmitted, submittedRef] = useStateRef(false);
    const [criticalError, setCriticalError] = useState(false);

    // Optionals
    const [flags, setFlags] = useState({
        showDeleteInsteadOfArchive: false, // Important to have it default to false.
    });
    const [suggestedTools, setSuggestedTools] = useState([]);
    const [dataProcessingAgreements, setDataProcessingAgreements, dataProcessingAgreementsRef] = useStateRef([]);
    const [pendingTools, setPendingTools, pendingToolsRef] = useStateRef([]);

    // AI
    const [generatingCompanyDescription, setGeneratingCompanyDescription] = useState(false);
    const [generatingCompanyActivities, setGeneratingCompanyActivities] = useState(false);

    // Recommendations
    const [vitalRecommendations, setVitalRecommendations] = useState({
        elements: [],
        individuals: [],
        processes: [],
        sensitiveElements: [],
    });

    // Scanning
    const [scanning, setScanning] = useState(false);
    const [scanCancelled, setScanCancelled, scanCancelledRef] = useStateRef(false);
    const [scansReviewed, setScansReviewed] = useState([]);
    const [scannerResults, setScannerResults] = useState(null);

    // Context
    const { setAccount, globalFlags } = AuthStore();

    // Variables
    const isEditingSession = window.location.href.includes('/gdpr-essentials/edit');
    const paramStage = props.match.params.stage;

    const loadSession = async () => {
        try {
            // Mark as loading..
            setLoading(true);

            // Load their session..
            const res = await makeEndpointRequest(`GetGELSession`);

            // If they have session..
            if (res !== null) {
                // Set their new session data..
                setData(res);

                // What was their last step?
                const stepStored = Object.keys(mappedSteps)[res.stepNumber] || `Welcome`;

                // Now we need to set their link to the param if they have one..
                let newStep = isEditingSession ? `Welcome` : stepStored;

                // If their url is redirecting them to a specific step.
                if (paramStage) {
                    const match = mapSlugsToSteps[paramStage];

                    // Is a valid url slug..
                    if (match) {
                        newStep = match;
                    }
                }

                // If we are not editing and the step we have in url is not the last one in our session..
                if (!isEditingSession && newStep !== stepStored) {
                    // Have we reached this far?
                    const isThisStepMoreAdvanced = isStepMoreAdvanced(stepStored, newStep);

                    if (!isThisStepMoreAdvanced) {
                        // the user hasn't got there yet in his linear journey.
                        newStep = stepStored;
                    }
                }

                setStep(newStep);
            }

            await trackUserInteraction(`GDPR Essentials`, {
                previousSession: res !== null ? `True` : `False`,
                isEditingSession: isEditingSession ? `Yes` : `No`,
            });

            setLoading(false);
        } catch (err) {
            setLoading(false);
            setCriticalError(true);
            await logError('LOAD_GDPR_ESSENTIALS', err);
            await trackUserInteraction(`Having Difficulties`, {
                reason: `Failed to load the GDPR Essentials dependencies.`,
            });
        }
    };

    const updateData = async (path, value) => {
        setData((currentState) => {
            let manipulatedObj = { ...currentState };
            lodash.set(manipulatedObj, path, value);
            return manipulatedObj;
        });
    };

    const submitData = async () => {
        try {
            await trackUserInteraction(`Selected "Submit"`);

            // Inform the user interface that we're submitting the data..
            setSubmitted(true);

            // Make the endpoint request
            await makeEndpointRequest(`SetDataInventory`, {
                input: {
                    session: {
                        storageLocations: data.storageLocations.map((entry) => {
                            let formattedEntry = { ...entry };
                            delete formattedEntry.tempData;

                            return formattedEntry;
                        }),
                        processesDependencies: data.processesDependencies,
                        companyInfo: data.companyInfo,
                        checkedData: data.checkedData,
                        _meta: data._meta,
                        stepNumber: Object.keys(mappedSteps).indexOf(step),
                    },
                    scansReviewed,
                    suggestedTools: suggestedTools.map((c) => c._id),
                },
            });

            // They finished the GDPR Essentials..
            await trackUserInteraction(`Finished GDPR Essentials`);

            // Mark him as gel completed in case he isn't.
            setAccount((currentState) => {
                return {
                    ...currentState,
                    hasCompletedGDPREssentials: true,
                };
            });

            // redirect..
            props.history.replace('/');
        } catch (err) {
            setSubmitted(false);
            await logError(`GDPR_ESSENTIALS`, err);
            await trackUserInteraction(`Having Difficulties`, { reasons: `Failed to submit on GDPR Essentials` });
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later.`);
        }
    };

    /**
     * A simple redirect here for when they go to GDPR Essentials. If the person has the flag enabled we will redirect them to the Onboarding Flow.
     */

    const checkForOnboardingFlag = async () => {
        try {
            if (!globalFlags.onboarding) return true;

            // Take them to onboarding by force.
            props.history.replace('/onboarding');
        } catch (err) {
            await logError(`checkForOnboardingFlag`, err);
        }
    };

    useEffect(() => {
        loadSession();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        checkForOnboardingFlag();
        // eslint-disable-next-line
    }, [globalFlags]);

    const ContextProps = {
        // Steps
        step,
        _setStep: setStep, // native one..
        setStep: (stepPassed) => {
            setStep(stepPassed);
            const arr = Object.keys(mappedSteps);
            if (arr.indexOf(stepPassed) > arr.indexOf(data._meta.biggestStep)) {
                updateData('_meta.biggestStep', stepPassed);
            }
        },
        // Progress
        progress,
        setProgress,
        // Data
        data,
        dataRef,
        setData,
        updateData,
        submitData,
        // Others
        criticalError,
        loading,
        // Scanning
        scanning,
        setScanning,
        // Scan cancelled
        scanCancelled,
        setScanCancelled,
        scanCancelledRef,
        // Pending tools
        pendingTools,
        setPendingTools,
        pendingToolsRef,
        // Vital Recommendations
        vitalRecommendations,
        setVitalRecommendations,
        // Submitted
        submitted,
        submittedRef,
        setSubmitted,
        // Scanner results
        scannerResults,
        setScannerResults,
        // Company scans
        scansReviewed,
        setScansReviewed,
        // Flags
        flags,
        setFlags,
        // Suggested tools
        suggestedTools,
        setSuggestedTools,
        // Data Processing Agreements
        dataProcessingAgreements,
        setDataProcessingAgreements,
        dataProcessingAgreementsRef,
        // AI
        generatingCompanyDescription,
        setGeneratingCompanyDescription,
        generatingCompanyActivities,
        setGeneratingCompanyActivities,
        // Others..
        isEditingSession,
        biggestStep: data._meta.biggestStep,
    };

    const StepComponent = mappedSteps[loading || criticalError ? 'LoadingScreen' : step];

    return (
        <Container title={`GDPR Essentials`} classNames="module-gdpr-essentials">
            <div className="module-header">
                <span className="title">GDPR Essentials</span>
                <Tooltip
                    trackInteraction={true}
                    interactionId={`gdpr-essentials:autosave`}
                    content={
                        isEditingSession
                            ? `Your progress is not saved on each step. You must go to the last step of the flow and select "Update Data Inventory" to save the changes you've made.`
                            : `Your progress is automatically saved every 5 seconds so you can resume the activity later if needed.`
                    }
                >
                    <div className="autosave-label">Autosave {isEditingSession ? `disabled` : `enabled`}</div>
                </Tooltip>
            </div>
            <div className="progress-bar">
                <div className="bar">
                    <div className="value" style={{ width: `${progress}%` }} />
                </div>
                <div className="procent">{progress}%</div>
            </div>
            <div className="module-container">
                <Context.Provider value={ContextProps}>
                    <API urlParams={props.match.params}>
                        <StepComponent />
                    </API>
                </Context.Provider>
            </div>
        </Container>
    );
};

export default Stage;
