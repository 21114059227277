import React, { useEffect, useState } from 'react';
import { ComponentState } from '..';
import { getProductsDomain } from '../../../../utils/helpers';

export const getPrivacyPortalPath = (isHosted = false, id = '') =>
    `${isHosted == false ? `embedded-privacy-portal` : `privacy-portal`}/${id}?embed-preview=true`;

const Component = (props) => {
    const { data } = ComponentState();
    const [iframeRoute, setIframeRoute] = useState(getPrivacyPortalPath(data.isPrivacyPortalHosted, data._id));

    const onUpdate = () => {
        const doc = document.getElementById('iframe-preview');
        if (!doc || (doc && !doc.contentWindow)) return false;

        // Figure out these two..
        const productsDomain = getProductsDomain();
        const iframePath = getPrivacyPortalPath(data.isPrivacyPortalHosted, data._id);

        // The final domain path
        const fullAddress = `${productsDomain}/${iframePath}`;

        // Set iframe route
        setIframeRoute(fullAddress);

        doc.contentWindow.postMessage(
            {
                type: 'previewChanges',
                payload: {
                    ...data,
                    theme: data.privacyPortalSettings.theme,
                },
            },
            '*',
        );
    };

    useEffect(() => {
        const doc = document.getElementById('iframe-preview');
        if (!doc) return false;
        if (!data) return false;
        onUpdate();
        // eslint-disable-next-line
    }, [props.dataTrigger, data.companyLogo]);

    useEffect(() => {
        document.addEventListener('updateSettingsPreviewPanel', onUpdate);
        return () => {
            document.removeEventListener('updateSettingsPreviewPanel', onUpdate);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div
                className={`iframe-container ${data && data.isPrivacyPortalHosted === false ? `embedded` : `hosted`} ${
                    data ? data.privacyPortalSettings.theme.mode : 'dark'
                }`}
            >
                <iframe data-cy="iframe" id="iframe-preview" src={iframeRoute} />
            </div>
        </React.Fragment>
    );
};

export default Component;
