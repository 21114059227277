import { customCountries, securityMeasures } from '../../../gdprEssentials/steps/reviewData/components/edit/pages/general';

/**
 * The function is done like this so we can access the filters easily.
 * @param {*} data
 * @returns Filters
 */

export const getFilterFields = (data) => {
    return [
        {
            key: 'label',
            type: 'text',
            label: 'Name',
        },
        {
            key: 'processes',
            label: 'Processes',
            type: 'array',
            propertyCompared: 'label',
            options: data.processes, // We will compare our value against the "label" in processes
            optionsLabel: 'label',
        },
        {
            key: 'dataElements',
            label: 'Data Categories',
            type: 'array',
            propertyCompared: 'label',
            options: data.elements,
            optionsLabel: 'label',
        },
        {
            key: 'individuals',
            label: 'Individuals',
            type: 'array',
            propertyCompared: 'label',
            options: data.individuals,
            optionsLabel: 'label',
        },
        {
            key: 'dataResidency',
            label: 'Data Residency',
            type: 'text',
            options: customCountries.map((c) => c.name),
        },
        {
            key: 'securityMeasures',
            type: 'text',
            label: 'Security Measures',
            options: securityMeasures,
        },
        {
            key: 'isSubProcessor',
            type: 'text',
            label: 'Sub-Processor',
            options: ['Yes', 'No'],
        },
    ];
};
