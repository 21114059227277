import React, { createContext, useContext, useEffect, useState } from 'react';

// Components
import Question from './components/question';
import Answer from './components/answer';
import Details from './components/details';
import Header from './components/header';
import Properties from './components/properties';
import { ModuleState } from '../..';
import { trackUserInteraction } from '../../../../../utils/helpers';
import { Button } from '@mui/material';

// Context
const Context = createContext({});
export const EntryState = () => useContext(Context);

const Component = (props) => {
    const { expandedQuestions, setExpandedQuestions, updateEntities, editingId, setEditingId } = ModuleState();

    const [tempData, setTempData] = useState(props.data);

    // Variables needed
    const isEditing = props.data._id === editingId ? true : false;
    const dataChanged = JSON.stringify(tempData) !== JSON.stringify(props.data);

    const onSave = () => {
        updateEntities([{ _id: props.data._id, payload: tempData }]);
        setEditingId(null);
        trackUserInteraction(`Selected "Save"`);
    };

    const onEdit = (state) => {
        const execFunction = () => {
            setEditingId(state === false ? null : props.data._id);
            setTempData(props.data);
            trackUserInteraction(`Selected "${state ? 'Edit' : 'Stop Editing'}"`);
        };

        if (isEditing && dataChanged) {
            window.showAlert('Confirmation', `Are you certain you wish to stop editing and discard all the changes?`, 'warning', [
                {
                    text: `Cancel`,
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        execFunction();
                    },
                },
            ]);

            return false;
        }

        execFunction();
    };

    useEffect(() => {
        // If we changed the data using the bulk tools.
        if (JSON.stringify(props.data) !== JSON.stringify(tempData)) {
            setTempData(props.data);
        }

        // eslint-disable-next-line
    }, [props.data]);

    const PassedProps = {
        data: tempData,
        setData: (data) => setTempData((curr) => ({ ...curr, ...data })),
        setExpanded: (value) => setExpandedQuestions((cs) => ({ ...cs, [props.data._id]: value })),
        expanded: expandedQuestions[props.data._id] ? true : false,
        isEditing,
    };

    return (
        <Context.Provider value={PassedProps}>
            <div className="entry component-entry">
                <Header />
                <div className={`content ${PassedProps.expanded ? 'expanded' : 'not-expanded'}`}>
                    <div
                        className="component-container"
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}
                    >
                        <div className="left-side" style={{ flex: 1 }}>
                            <Question />
                            <Answer />
                        </div>
                        <div
                            className="right-side"
                            style={{ borderLeft: 'solid 1px rgba(255,255,255, 0.07)', marginLeft: 16, paddingLeft: 16 }}
                        >
                            <Details />
                        </div>
                    </div>
                    <Properties />
                    <div style={{ marginTop: 24 }}>
                        {isEditing ? (
                            <React.Fragment>
                                <Button variant="contained" color="primary" onClick={() => onEdit(false)} style={{ marginRight: 8 }}>
                                    Stop Editing
                                </Button>
                                <Button variant="contained" color="primary" disabled={!dataChanged} onClick={onSave}>
                                    Save Changes
                                </Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Button variant="contained" color="primary" onClick={() => onEdit(true)}>
                                    Edit
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        </Context.Provider>
    );
};

export default Component;
