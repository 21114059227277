import React from 'react';

// Context
import { ViewContext } from '..';

const Component = (props) => {
    const { selectOption, currentOptions } = ViewContext();

    const isSelected = currentOptions.includes(props.value);

    return (
        <React.Fragment>
            <div className={`entry ${isSelected && 'selected'}`} onClick={() => selectOption(props.value)}>
                {isSelected && (
                    <div className="check-container">
                        <i className={`check-icon fa-solid fa-check`}></i>
                    </div>
                )}
                <i className={`icon fa-solid ${props.icon}`}></i>
                <div className="label">{props.label}</div>
                <div className="description">{props.description}</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
