import React from 'react';

// Components
import { Avatar } from '@mui/material';
import Tooltip from '../../../../../../components/tooltip';

// Context
import { OnboardingContext } from '../../../..';

// Dependencies
import { getToolRecommendations } from '../../../../../thirdParties/components/storageLocations/components/editTools/utils/helpers';
import { addEllipsisIfExceedsMaxLength } from '../../../../../../../utils/helpers';

const Component = (props) => {
    const { dataInventory, toolsRecommended, setEditingLocId } = OnboardingContext();

    const getProcessesListing = () => {
        if (props.data.processes.length < 1) return `No processes listed.`;

        let processes = [];

        // Map the processes ids to their
        processes = props.data.processes
            .map((id) => {
                const match = dataInventory.processes.find((c) => c._id === id);

                return match ? match.label : null;
            })
            .filter((c) => c !== null);

        const visiblePart = processes.slice(0, 3).join(', ');
        const invisiblePart = processes.slice(3);

        // Format..
        let str = invisiblePart.length > 0 ? `${visiblePart}, + ${invisiblePart.length} more.` : `${visiblePart}`;

        return addEllipsisIfExceedsMaxLength(str, 118);
    };

    const onToolSelected = () => {
        setEditingLocId(props.data._storageLocationId);
    };

    // Get the recommendations for this specific tool
    const locationRecommendations = getToolRecommendations(props.data.label, toolsRecommended);

    return (
        <React.Fragment>
            <div className="entry normal" onClick={onToolSelected}>
                <div className="content">
                    <div className="left-side">
                        <div className="header">
                            <Avatar className="avatar" src={locationRecommendations ? locationRecommendations.image : undefined}>
                                {props.data.label[0]}
                            </Avatar>

                            <div className="label">{props.data.label}</div>
                        </div>
                        <div className="processes">{getProcessesListing()} </div>
                    </div>
                    <div className="right-side">
                        {props.isIncomplete && (
                            <div className="alert-container">
                                <Tooltip content="Click 'Edit' to add missing information">
                                    <i className="alert fa-solid fa-circle-exclamation"></i>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
