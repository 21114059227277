import React, { createContext, useContext, useState } from 'react';

// Components
import Header from './components/header';
import Option from './components/option';
import CreateCustomOption from './components/createCustomOption';

// Context
import { OnboardingContext } from '../..';
import { entries } from '../../../gdprEssentials/steps/industry';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { companyData } = OnboardingContext();

    const [currentOptions, setCurrentOptions] = useState(companyData.industry);

    /**
     * When we click on an option we need to either add it to the array of audiences or remove it.
     * @param {*} value
     */

    const selectOption = (value) => {
        setCurrentOptions((arr) => {
            return arr.includes(value) ? arr.filter((item) => item !== value) : [...arr, value];
        });
    };

    const getCustomOptions = () => {
        return currentOptions.filter((option) => !entries.find((d) => d.label === option));
    };

    const PassedProps = {
        currentOptions,
        setCurrentOptions,
        selectOption,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <div className="industry module-content">
                <div className="entries d-flex-row-columns">
                    {entries.map((entry, ix) => (
                        <Option key={ix} icon={`fa-${entry.icon}`} label={`${entry.label}`} />
                    ))}
                    {getCustomOptions().map((opt, index) => (
                        <Option key={index} icon={`fa-rectangle-history-circle-plus`} label={`${opt}`} />
                    ))}
                    <CreateCustomOption />
                </div>
            </div>
        </Context.Provider>
    );
};

export default Component;
