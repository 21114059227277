import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Checkbox } from '@mui/material';

const Component = (props) => {
    const [pickedExisting, setPickedExisting] = useState(null);
    const [name, setName] = useState('');

    return (
        <React.Fragment>
            <Dialog className={`create-dpa-modal create-storage-location-modal`} open={true} maxWidth={'sm'} fullWidth={true}>
                <DialogTitle data-cy="dialog-title">Create a new DPA</DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <div className="form-group">
                        <div className="label">Name</div>
                        <div className="input">
                            <TextField
                                placeholder="Name"
                                fullWidth={true}
                                variant="standard"
                                value={name}
                                onChange={(ev) => setName(ev.target.value)}
                            />
                        </div>
                    </div>
                    {props.data.length > 0 && (
                        <React.Fragment>
                            <div className="form-group">
                                <div className="label">(Optional) Clone data from existing DPA</div>
                                <div className="input dpas-existing">
                                    {props.data.map((entry) => (
                                        <div className="row">
                                            <Checkbox
                                                checked={pickedExisting === entry._id}
                                                onClick={() => setPickedExisting(entry._id === pickedExisting ? null : entry._id)}
                                            />
                                            <div className="name"> {entry.name || 'Unnamed '}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={props.onDismiss} data-cy="dialog-dismiss-button">
                        Dismiss
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={name.length < 1}
                        onClick={() =>
                            props.onSubmit({
                                _cloneId: pickedExisting,
                                name,
                            })
                        }
                        data-cy="dialog-submit-button"
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
