import React, { createContext, useContext, useState } from 'react';

// Components
import Container from '../../layout/container';
import Header from './components/layout/header';
import ProgressBar from './components/layout/progressBar';

// Functions
import Functions from './components/functions';
import Callbacks from './components/callbacks';

// Dependencies
import { MappedViews, validateViewRoute } from './views';
import { getFromUrlQueryString } from '../../../utils/helpers';

// Context
const Context = createContext({});
export const OnboardingContext = () => useContext(Context);

const Component = (props) => {
    // Data
    const [companyData, setCompanyData] = useState(null);
    const [dataInventory, setDataInventory] = useState({
        processes: [],
        processes: [],
        individuals: [],
        storageLocations: [],
        matrices: [],
    });

    // Recommendations
    const [toolsRecommended, setToolsRecommended] = useState([]);
    const [vitalRecommendations, setVitalRecommendations] = useState({
        elements: [],
        individuals: [],
        processes: [],
        sensitiveElements: [],
    });

    // General UI States
    const [loading, setLoading] = useState(true);
    const [editingLocId, setEditingLocId] = useState(null);
    const [viewId, setViewId] = useState(validateViewRoute(props.match.params.viewId) ? props.match.params.viewId : 'welcome');
    const [flags, setFlags] = useState({
        showDeleteInsteadOfArchive: false,
        missingSubProcessors: false,
    });
    const [showArchivedTools, setShowArchivedTools] = useState(getFromUrlQueryString('showArchived') ? true : false);
    const [tasksPending, setTasksPending] = useState({
        generatingDescription: false,
        generatingActivities: false,
        scanningWebsites: false,
        addingScannedTools: false,
    });

    const [scanner, setScanner] = useState({
        reviewed: false,
        lastScannedAt: null,
        results: [],
    });

    const PassedProps = {
        // Data
        companyData,
        setCompanyData,
        dataInventory,
        setDataInventory,
        // View ID
        viewId,
        setViewId,
        // Loading state
        loading,
        setLoading,
        // Recommendations
        toolsRecommended,
        setToolsRecommended,
        vitalRecommendations,
        setVitalRecommendations,
        // Editing
        editingLocId,
        setEditingLocId,
        // Flags
        flags,
        setFlags,
        // Archived tools
        showArchivedTools,
        setShowArchivedTools,
        // Tasks
        tasksPending,
        setTasksPending,
        // Scanner
        scanner,
        setScanner,
    };

    const RenderedView = MappedViews[companyData === null || loading ? 'loading' : viewId];

    return (
        <Context.Provider value={PassedProps}>
            <Functions>
                <Container title="Onboarding" classNames="page-onboarding module-gdpr-essentials" isAlternateBackground={false}>
                    <Header />
                    <ProgressBar />
                    <div className="module-container">
                        <RenderedView />
                    </div>
                </Container>
                <Callbacks />
            </Functions>
        </Context.Provider>
    );
};

export default Component;
