import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';
import { EntryState } from '..';

const Component = () => {
    const { data, setData, isEditing } = EntryState();

    const [editData, setEditData] = useState({ method: null, index: null, data: null });

    const onCreate = async () => {
        setEditData({
            method: 'create',
            index: null,
            data: {
                label: '',
                value: '',
            },
        });
    };

    const onEdit = (index) => {
        if (!isEditing) return false;

        setEditData({
            method: 'edit',
            index,
            data: data.properties[index],
        });
    };

    const closeModal = () => {
        setEditData({
            method: null,
            index: null,
            data: null,
        });
    };

    const deleteData = async () => {
        const newProperties = [...data.properties];
        newProperties.splice(editData.index);
        setData({ properties: newProperties });
        closeModal();
    };

    const submitData = async () => {
        const newProperties = [...data.properties];

        if (editData.method === 'edit') {
            newProperties[editData.index] = { ...editData.data };
        } else {
            newProperties.push(editData.data);
        }

        setData({ properties: newProperties });

        closeModal();
    };

    return (
        <React.Fragment>
            {(data.properties.length > 0 || isEditing) && (
                <React.Fragment>
                    <hr style={{ borderColor: 'rgba(255,255,255, 0.07)', marginBottom: 18, marginTop: 24, width: '100%' }} />
                    <div className="component-properties">
                        {data.properties.map((c, index) => (
                            <div className={`--component-entry ${isEditing && 'selectable'}`} key={index} onClick={() => onEdit(index)}>
                                <div className="label">{c.label}</div>
                                <div className="value">{c.value}</div>
                            </div>
                        ))}

                        {isEditing && (
                            <div className="--component-entry add selectable" onClick={onCreate}>
                                <i className="icon fa-solid fa-circle-plus"></i>
                                <div className="label">Add Property</div>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}
            {editData.method !== null && (
                <Modal
                    method={editData.method}
                    data={editData.data}
                    setData={(newData) => setEditData((currentState) => ({ ...currentState, data: { ...currentState.data, ...newData } }))}
                    onSubmit={submitData}
                    onDelete={deleteData}
                    onClose={closeModal}
                />
            )}
        </React.Fragment>
    );
};

const Modal = (props) => {
    return (
        <React.Fragment>
            <Dialog className="edit-dpa-modal" open={true} onClose={props.onClose} maxWidth={'xs'} fullWidth={true}>
                <DialogTitle>{props.method === 'edit' ? 'Edit' : 'Create'} a property </DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <div className="form-group" style={{ marginBottom: 24 }}>
                        <div className="label" style={{ marginBottom: 6 }}>
                            Attribute
                        </div>

                        <div className="input">
                            <TextField
                                autoFocus
                                fullWidth
                                variant="standard"
                                placeholder="Department"
                                value={props.data.label}
                                onChange={(e) => props.setData({ label: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="form-group" style={{ marginBottom: 24 }}>
                        <div className="label" style={{ marginBottom: 6 }}>
                            Value
                        </div>

                        <div className="input">
                            <TextField
                                autoFocus
                                fullWidth
                                variant="standard"
                                placeholder="Tech IT"
                                value={props.data.value}
                                onChange={(e) => props.setData({ value: e.target.value })}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined" onClick={props.onClose}>
                        {props.method === 'edit' ? 'Cancel' : 'Dismiss'}
                    </Button>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {props.method === 'edit' && (
                            <Button variant="outlined" onClick={props.onDelete} style={{ marginRight: 8 }}>
                                Delete
                            </Button>
                        )}

                        <Button variant="contained" onClick={props.onSubmit}>
                            {props.method === 'edit' ? 'Save' : 'Create'}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
