import { FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ComponentState } from '../../../../../../..';
import { makeEndpointRequest } from '../../../../../../../../../../utils/endpoints';

// Dependencies
import { logError, trackUserInteraction, useStateRef } from '../../../../../../../../../../utils/helpers';

// Component
import Autocomplete from '../../../../../../mapServices/utils/autocomplete';
import { AuthStore } from '../../../../../../../../../../utils/context/authStore';

const Component = (props) => {
    const { vitalRecommendations } = ComponentState();
    const [recommendations, setRecommendations] = useState({ processes: [] });
    const { globalFlags } = AuthStore();

    // eslint-disable-next-line
    const [session, setSession, sessionRef] = useStateRef(null);

    const getOptions = () => {
        let allProcesses = vitalRecommendations.processes;

        const arr = [];

        // Adding the recommendations for this tool alone.
        recommendations.processes.forEach((p) => {
            arr.push({ label: p, category: `Processes related to ${props.data.label}` });
        });

        // The custom ones should be in the right section
        props.data.processes.forEach((p) => {
            if (allProcesses.find((e) => e.label === p)) return false;
            arr.push({ label: p, category: `Processes related to ${props.data.label}` });
        });

        // Adding the ones in DPA marked.
        allProcesses
            .filter((e) => e.inDPA === true)
            .forEach((p) => {
                arr.push({ label: p.label, category: `Typical processes used to offer services` });
            });

        // The rest
        allProcesses
            .filter((e) => e.inDPA === false)
            .filter((e) => !recommendations.processes.includes(e.label))
            .forEach((p) => {
                arr.push({ label: p.label, category: `All other processes` });
            });

        return arr;
    };

    const loadRecommendations = async () => {
        try {
            let recommendations = await makeEndpointRequest(`getSpecificRecommendation`, {
                locations: [props.data.label],
                processes: [],
            });
            const matchRec = recommendations.storageLocations.find((e) => e.label === props.data.label);
            const processes = matchRec ? matchRec.processRecommendations.filter((s) => s.length > 0) : [];

            setRecommendations({ processes });
        } catch (err) {
            await logError(`LOAD_RECOMMENDATIONS`, err);
        }
    };

    const loadSession = async () => {
        try {
            // Get user session..
            const res = await makeEndpointRequest(`GetGELSession`);
            if (!res) throw new Error(`The user has no session.`);

            // Session the session data..
            setSession(res);

            return res; // we need this
        } catch (err) {
            await logError(`LOAD_SESSION_FOR_DPA`, err);
        }
    };

    useEffect(() => {
        loadRecommendations();
        loadSession();
        // eslint-disable-next-line
    }, []);

    const getProcessesMissingFromDataInventory = (sessionFetched, processes) => {
        // Get the storage location
        const storageLocation = sessionFetched.storageLocations.find((c) => c.label === props.data.label);

        // If there is no storage location with that label it means we cannot know if this tool is synced with data inventory.
        if (!storageLocation) {
            props.updateField('processes', processes);
            return true;
        }

        // The current processes of the storage location
        const currentProcesses = storageLocation.processes.map((c) => c.label);

        // What processes are not on the storage location
        const missingProcesses = processes.filter((p) => !currentProcesses.includes(p));

        return missingProcesses;
    };

    const showAlertMissingProcesses = (processes) => {
        let message = (
            <React.Fragment>
                <div style={{ textAlign: 'left', fontSize: 14, lineHeight: 1.4 }}>
                    <p>The following processes are missing from your Data Inventory:</p>
                    <ul style={{ width: '100%', textAlign: 'left' }}>
                        {processes.map((proc, index) => (
                            <li key={index}>{proc}</li>
                        ))}
                    </ul>
                    <p>
                        Go to data inventory and edit <b>{props.data.label}</b> and add the processes there. After that press the "Check
                        Again" button below to add the processes to this Data Processing Agreement.
                    </p>
                </div>
            </React.Fragment>
        );

        window.showAlert(
            'Change Required',
            message,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-skip-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Cancel"`);
                    },
                },

                {
                    text: 'Check again',
                    onClick: async ({ dismissAlert }) => {
                        // Dismiss...
                        dismissAlert();

                        // Amplitude
                        await trackUserInteraction(`Selected "Check again"`);

                        // Reload session
                        const lastSession = await loadSession();

                        // Check again
                        const processesMissing = await getProcessesMissingFromDataInventory(lastSession, processes);

                        // If there's nothing missing now
                        if (processesMissing.length < 1) {
                            // Update processes
                            props.updateField('processes', [...props.data.processes, ...processes]);
                            dismissAlert();
                            return true;
                        }

                        // Show it after a few seconds to dismiss alert..
                        setTimeout(() => {
                            showAlertMissingProcesses(processesMissing);
                        }, 500);
                    },
                },
                {
                    text: 'Open your Data Inventory',
                    fullWidth: true,
                    onClick: async () => {
                        // Track..
                        await trackUserInteraction(`Selected "Open your Data Inventory"`);

                        // Mark it so we know we've been redirect by DPA..
                        await localStorage.setItem('@redirectedByDPA', true);

                        // Open in new tab
                        window.open('/stages/gdpr-essentials/edit/review-tools', `_blank`);
                    },
                },
            ],
            {},
        );
    };

    const onProcessChanges = (newValues) => {
        // Get the processes added..
        const processesAdded = newValues.filter((c) => !props.data.processes.includes(c));

        // Get the storage location
        const storageLocation = session.storageLocations.find((c) => c.label === props.data.label);

        // If there is no storage location with that label it means we cannot know if this tool is synced with data inventory.
        if (!storageLocation || !globalFlags.addProcessesToDPA) {
            props.updateField('processes', newValues);
            return true;
        }

        // What processes are not on the storage location
        const missingProcesses = getProcessesMissingFromDataInventory(session, processesAdded);

        // If there is processes missing.
        if (missingProcesses.length > 0) return showAlertMissingProcesses(missingProcesses);

        // Update field..
        props.updateField('processes', newValues);
    };

    return (
        <React.Fragment>
            <div className="form-group extra-spacing">
                <div className="form-label mb">
                    When offering your services, what are the purposes for using <b>{props.data.label}</b>?
                </div>
                <div className="form-content">
                    <Autocomplete
                        value={props.data.processes}
                        options={getOptions()}
                        onDataChanged={onProcessChanges}
                        placeholder="Select a purpose"
                        includeDescriptions={false}
                        fullWidth={true}
                        groupBy={(elm) => elm.category}
                    />
                    {props.data.processes.length < 1 && (
                        <FormHelperText error={true}>You need to choose at least one purpose.</FormHelperText>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
