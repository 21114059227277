import React, { useState } from 'react';
import { logError, trackUserInteraction } from '../../../../utils/helpers';
import { AuthStore } from '../../../../utils/context/authStore';
import { getCertificateGenerated } from '../components/certificates/utils';
import { Button } from '@mui/material';

const Section = () => {
    const { account } = AuthStore();
    const [loading, setLoading] = useState(false);

    const entries = [
        {
            label: `GDPR Essentials Certificate`,
            type: `gdpr-essentials`,
            imagePath: `/assets/images/page-dashboard/gdpr_badge_on.svg`,
        },
    ];

    const downloadCertificate = async (entry) => {
        try {
            setLoading(true);
            await trackUserInteraction('Downloaded certificate', {
                companyId: account._companyId,
                type: entry.type,
            });
            await getCertificateGenerated(account._companyId, entry.type);
            setLoading(false);
        } catch (err) {
            await logError(`DOWNLOAD_CERTIFICATE`, err);
            await trackUserInteraction(`Having Difficulties`, { reason: `Failed to download certificate` });
            setLoading(false);
        }
    };

    const goToVerifyPage = () => {
        let link = `${window.location.origin}/verify/${account._companyId}`;
        window.open(link, '_blank').focus();
    };

    const popupCenter = ({ url, title, w, h }) => {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : screen.width;
        const height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;
        const newWindow = window.open(
            url,
            title,
            `
          scrollbars=yes,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `,
        );

        if (window.focus) newWindow.focus();
    };

    const shareOnMedia = async (typeMedia) => {
        const sharingPost = `${window.location.origin}/share/${account._companyId}/certificates/gdpr-essentials`;
        await trackUserInteraction(`Selected "Share Certificate`, { destination: typeMedia });
        let finalLink = ``;

        if (typeMedia === 'facebook') {
            finalLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(sharingPost)}`;
        } else if (typeMedia === 'linkedin') {
            finalLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(sharingPost)}`;
        } else if ((typeMedia = 'twitter')) {
            finalLink = `http://twitter.com/share?text=I'm never putting our business at risk from fines again.&url=${encodeURI(
                sharingPost,
            )}&hashtags=gdpr,privasee,gdprCertification`;
        }

        popupCenter({
            url: finalLink,
            title: `sharing_${typeMedia}`,
            h: 550,
            w: 550,
        });
    };

    return (
        <div className="section certificates">
            <div className="section-summary">
                <div className="title">Certificates</div>
                <div className="description">Show the world your commitment to maintaining good data privacy standards.</div>
            </div>
            <div className="section-content">
                <div className="steps">
                    <div className="step-entry">
                        <div className="step-header">
                            <div className="step-badge">Step 1</div>
                            <div className="step-label">Get your certificate</div>
                        </div>
                        <div className="step-content">
                            <div className="step-two-rows">
                                <div className="parent-container-rows" style={{ flex: 1 }}>
                                    <div className="step-text">
                                        Your GDPR Certificate is a great way to showcase your compliance standards. Covers topics such as
                                        Data Mapping, Legal Basis Selection, Privacy and Cookie Policy. Click on the "Download" button to
                                        get it in PDF format.
                                    </div>
                                    <div className="certificates">
                                        {entries.map((entry, ix) => (
                                            <div key={ix} className={`entry`}>
                                                <div className="content">
                                                    {/* <img className="badge" alt="Badge" src={entry.imagePath} /> */}
                                                    <div className="title">{entry.label}</div>
                                                    <Button
                                                        variant="contained"
                                                        disabled={loading}
                                                        onClick={() => downloadCertificate(entry)}
                                                    >
                                                        <i className="icon fa-light fa-cloud-arrow-down"></i>
                                                        Download
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="step-image-container">
                                    <div
                                        className="step-image-entry"
                                        style={{
                                            backgroundImage: `url("/assets/images/page-shareables/demo/certificate.png")`,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step-entry">
                        <div className="step-header">
                            <div className="step-badge">Step 2</div>
                            <div className="step-label">Demonstrate your compliance level</div>
                        </div>
                        <div className="step-content">
                            <div className="step-text">
                                Once you've downloaded your Certificate, the next step is to share it. You can share it on social media,
                                print it, or share it via email.
                            </div>
                        </div>
                    </div>
                    <div className="step-entry">
                        <div className="step-header">
                            <div className="step-badge">Step 3</div>
                            <div className="step-label">Verify your certificate</div>
                        </div>
                        <div className="step-content">
                            <div className="step-two-rows">
                                <div className="step-text">
                                    All certificates come with a QR code. When scanned it will point people to your website and GDPR
                                    policies.
                                </div>
                                <div className="step-image-container">
                                    <div
                                        className="step-image-entry"
                                        style={{
                                            backgroundImage: `url("/assets/images/page-shareables/demo/qr-page.png")`,
                                        }}
                                    />
                                </div>
                            </div>
                            <Button variant="outlined" className="step-button" onClick={goToVerifyPage}>
                                <i className="icon fa-solid fa-up-right-from-square"></i>
                                Show QR Verification Page
                            </Button>
                        </div>
                    </div>
                    <div className="step-entry">
                        <div className="step-header">
                            <div className="step-badge">Step 4</div>
                            <div className="step-label">Share it</div>
                        </div>
                        <div className="step-content">
                            <div className="step-two-rows">
                                <div className="step-text">
                                    Sharing it on your social media may be a good marketing move to impress your customers. Press a button
                                    below to share it on of your social media.
                                </div>
                            </div>
                            <div className="multiple-buttons">
                                <Button variant="outlined" className="step-button" onClick={() => shareOnMedia('linkedin')}>
                                    <i className="icon fa-brands fa-linkedin"></i>
                                    Share on LinkedIn
                                </Button>
                                <Button variant="outlined" className="step-button" onClick={() => shareOnMedia('facebook')}>
                                    <i className="icon fa-brands fa-facebook"></i>
                                    Share on Facebook
                                </Button>
                                <Button variant="outlined" className="step-button" onClick={() => shareOnMedia('twitter')}>
                                    <i className="icon fa-brands fa-twitter"></i>
                                    Share on Twitter
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section;
