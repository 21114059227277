import { Button } from '@mui/material';
import React from 'react';
import { ComponentState } from '../../../..';

import { trackUserInteraction } from '../../../../../../../utils/helpers';
import defaultColors from './defaultColors';

// Components
import Entry from './components/entry';

const Component = () => {
    const { data, ucSettings, updateUcSettings, loading } = ComponentState();

    const customizableColors = [
        {
            path: `layerBackground`,
            label: `Background Color`,
        },

        {
            path: `text`,
            label: `Text Color`,
        },

        {
            path: `linkIcon`,
            additionalPaths: ['linkFont'],
            label: `Link Color`,
        },
        {
            path: `primary`,
            label: `Accent Color`,
        },

        {
            path: `acceptBtnBackground`,
            additionalPaths: ['denyBtnBackground'],
            label: `Primary Buttons (Background)`,
        },
        {
            path: `acceptBtnText`,
            additionalPaths: ['denyBtnText'],
            label: `Primary Buttons (Text)`,
        },
        {
            path: `moreBtnBackground`,
            additionalPaths: ['saveBtnBackground'],
            label: `Secondary Buttons (Background)`,
        },
        {
            path: `moreBtnText`,
            additionalPaths: ['saveBtnText'],
            label: `Secondary Buttons (Text)`,
        },
        // Group 2
        {
            path: `secondLayerTab`,
            label: `Tab Color`,
            group: 2,
        },
        {
            path: `tabsBorderColor`,
            label: `Border Color`,
            group: 2,
        },
    ];

    const resetColors = async () => {
        await trackUserInteraction(`Selected "Reset Colors"`);
        updateUcSettings(`customization.color`, { ...defaultColors });
    };

    return (
        <React.Fragment>
            <div className="form-label mb-less">Customize colors</div>
            <div className="form-description">Modify the palette of colors used by the Cookie Banner</div>
            <div className="form-content">
                <div
                    className="cmp-image image-1"
                    style={{
                        backgroundImage: `url("https://privasee-public-assets.s3.eu-west-1.amazonaws.com/cookie-banner/CMP+-+Colour+Guide+-+Layer+1.png")`,
                    }}
                ></div>
                <div className="grid-color-picker">
                    {customizableColors
                        .filter((g) => g.group !== 2)
                        .map((entry, ix) => (
                            <div key={ix} className="grid-element">
                                <div className="grid-content">
                                    <Entry
                                        label={entry.label}
                                        disabled={loading === true || !data.cookieBannerSettings.enabled}
                                        data={ucSettings.customization.color[entry.path] || ''}
                                        onChange={(color) => {
                                            updateUcSettings(`customization.color.${entry.path}`, color);
                                            if (entry.additionalPaths) {
                                                entry.additionalPaths.forEach((path) => {
                                                    updateUcSettings(`customization.color.${path}`, color);
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
                <div className="divider-line cmp"></div>
                <div
                    className="cmp-image image-2"
                    style={{
                        backgroundImage: `url("https://privasee-public-assets.s3.eu-west-1.amazonaws.com/cookie-banner/CMP+-+Colour+Guide+-+Layer+2.png")`,
                    }}
                ></div>
                <div className="grid-color-picker">
                    {customizableColors
                        .filter((g) => g.group === 2)
                        .map((entry, ix) => (
                            <div key={ix} className="grid-element">
                                <div className="grid-content">
                                    <Entry
                                        label={entry.label}
                                        disabled={loading === true || !data.cookieBannerSettings.enabled}
                                        data={ucSettings.customization.color[entry.path] || ''}
                                        onChange={(color) => {
                                            updateUcSettings(`customization.color.${entry.path}`, color);
                                            if (entry.additionalPaths) {
                                                entry.additionalPaths.forEach((path) => {
                                                    updateUcSettings(`customization.color.${path}`, color);
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <Button variant="outlined" style={{ marginTop: 16, marginBottom: 24 }} onClick={resetColors}>
                Reset to default colors
            </Button>
        </React.Fragment>
    );
};

export default Component;
