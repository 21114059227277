// Views
import Welcome from './welcome/index';
import Loading from './loading';
import ReviewTools from './reviewTools';
import LegalResponsibility from './legalResponsibility';
import Individuals from './individuals';
import Audience from './audience';
import Industry from './industry';
import CompanyInsights from './companyInsights';
import PersonalData from './personalData';
import SensitiveData from './sensitiveData/index';
import Children from './children';
import DescribeCompany from './describeCompany';
import Activities from './activities';
import DataInventory from './dataInventory';
import Complete from './complete';
/**
 * Map of all the possible views within this process.
 */

export const MappedViews = {
    welcome: Welcome,
    loading: Loading,
    'review-tools': ReviewTools,
    'legal-responsibility': LegalResponsibility,
    individuals: Individuals,
    audience: Audience,
    industry: Industry,
    'company-insights': CompanyInsights,
    'personal-data': PersonalData,
    'sensitive-data': SensitiveData,
    children: Children,
    'describe-company': DescribeCompany,
    activities: Activities,
    'data-inventory': DataInventory,
    complete: Complete,
};

/**
 * Ensures that a route exists.
 * @param {*} route - String
 * @returns
 */

export const validateViewRoute = (route) => (MappedViews[route] ? true : false);
