import React from 'react';
import { EntryState } from '..';
import { Checkbox } from '@mui/material';
import Tooltip from '../../../../../components/tooltip';

import { ModuleState } from '../../..';

const Component = () => {
    const { setExpanded, expanded, data } = EntryState();
    const { selectedEntities, setSelectedEntities } = ModuleState();

    const needsIntervention = data.answer.trim().length < 1 || data.question.trim().length < 1 ? true : false;

    return (
        <React.Fragment>
            <div className="header">
                <div className="left-side">
                    <div className="input">
                        <Checkbox
                            checked={selectedEntities.includes(data._id) ? true : false}
                            onClick={() =>
                                setSelectedEntities((st) => (st.includes(data._id) ? st.filter((c) => c !== data._id) : [...st, data._id]))
                            }
                        />
                    </div>
                </div>
                <div className="right-side" onClick={() => setExpanded(!expanded)}>
                    <div className="label">{data.question || 'New question'}</div>
                    {needsIntervention && (
                        <React.Fragment>
                            <Tooltip content="Complete both the question and answer fields.">
                                <div
                                    style={{
                                        padding: '8px 10px',
                                        fontSize: 12,
                                        border: `solid 1px #e2852d`,
                                        fontWeight: 500,
                                        color: `#e2852d`,
                                        borderRadius: 12,
                                    }}
                                >
                                    Incomplete
                                </div>
                            </Tooltip>
                        </React.Fragment>
                    )}

                    <div className="toggler">
                        {expanded ? <i className="icon fa-regular fa-chevron-up"></i> : <i className="icon fa-regular fa-chevron-down"></i>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default Component;
