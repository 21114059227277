const badgeEntries = [
    {
        id: 1,
        imagePath: '/assets/images/page-shareables/badges/1.png',
        height: 141.6,
        width: 114,
    },
    {
        id: 2,
        imagePath: '/assets/images/page-shareables/badges/2.png',
        height: 141.6,
        width: 114,
    },
    // commented out as these are unavailable due to Alex not being able to export them in PNG.
    // {
    //     id: 3,
    //     imagePath: `/assets/images/page-shareables/badges/3.png`,
    //     height: 97,
    //     width: 95,
    // },
    // {
    //     id: 4,
    //     imagePath: `/assets/images/page-shareables/badges/4.png`,
    //     height: 97,
    //     width: 95,
    // },
    {
        id: 5,
        imagePath: '/assets/images/page-shareables/badges/5.png',
        height: 116.39999999999999,
        width: 85.2,
    },
    {
        id: 6,
        imagePath: '/assets/images/page-shareables/badges/6.png',
        height: 116.39999999999999,
        width: 85.2,
    },
    {
        id: 7,
        imagePath: '/assets/images/page-shareables/badges/7.png',
        height: 120,
        width: 120,
    },
    {
        id: 8,
        imagePath: '/assets/images/page-shareables/badges/8.png',
        height: 120,
        width: 120,
    },
    {
        id: 9,
        imagePath: '/assets/images/page-shareables/badges/9.png',
        height: 79.2,
        width: 204,
    },
    {
        id: 10,
        imagePath: '/assets/images/page-shareables/badges/10.png',
        height: 79.2,
        width: 211.2,
    },
    {
        id: 11,
        imagePath: '/assets/images/page-shareables/badges/11.png',
        height: 78,
        width: 168,
    },
    {
        id: 12,
        imagePath: '/assets/images/page-shareables/badges/12.png',
        height: 78,
        width: 180,
    },
    {
        id: 13,
        imagePath: '/assets/images/page-shareables/badges/13.png',
        height: 66,
        width: 180,
    },
    {
        id: 14,
        imagePath: '/assets/images/page-shareables/badges/14.png',
        height: 54,
        width: 180,
    },
    {
        id: 15,
        imagePath: '/assets/images/page-shareables/badges/15.png',
        height: 54,
        width: 180,
    },
    {
        id: 16,
        imagePath: '/assets/images/page-shareables/badges/16.png',
        height: 54,
        width: 180,
    },
];

module.exports = { badgeEntries };