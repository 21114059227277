import React from 'react';

// Components
import { TextField, Autocomplete, FormHelperText } from '@mui/material';
import { customCountries } from '../../../../../../../../gdprEssentials/steps/reviewData/components/edit/pages/general';

const Component = (props) => {
    return (
        <React.Fragment>
            <div className="form-group extra-spacing">
                <div className="form-label mb">Data Residency</div>
                <div className="form-content">
                    <Autocomplete
                        value={props.data.dataResidency}
                        fullWidth={true}
                        className="country-autocomplete"
                        disableClearable={true}
                        options={customCountries.map((c) => c.name)}
                        groupBy={(option) => {
                            const o = customCountries.find((c) => c.name === option);
                            return o._muiCategory || 'Rest of the world';
                        }}
                        renderInput={(params) => <TextField fullWidth={true} variant="standard" {...params} placeholder="No selection" />}
                        freeSolo={false}
                        multiple={false}
                        onChange={(_, value) => props.updateField('dataResidency', value)}
                    />

                    {props.validationResults[`dataResidency`] !== undefined && (
                        <FormHelperText error={true}>{props.validationResults['dataResidency']}</FormHelperText>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
