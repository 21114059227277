import React, { useState } from 'react';

// Components
import Button from './components/button';
import SetProcesses from '../../../../../../../individuals/panel/views/processes/shareableComponents/setProcesses';

// Context
import { PanelContext } from '../../../../../editPanel';
import { logError, trackUserInteraction } from '../../../../../../../../../utils/helpers';

const Component = (props) => {
    const { vitalRecommendations, dataInventory, setDataInventory, data, setData, companyData } = PanelContext();

    const [enabled, setEnabled] = useState(false);

    /**
     * This is called when a process has been added.
     * @param {*} inventoryProcess Data inventory - Process.
     * @param {*} isCreated - If the process needed to be created first or not.
     */

    const onProcessAdded = async (inventoryProcess) => {
        try {
            // Add the individual to the panel's tool list of individuals.
            setData((currentState) => {
                let newState = { ...currentState };

                newState.matrixMap.push({
                    _processId: inventoryProcess._id,
                    individuals: [],
                });

                return newState;
            });

            // Dispatch event
            document.dispatchEvent(
                new CustomEvent(`thirdParties:onProcessAdded`, {
                    detail: {
                        processAdded: inventoryProcess,
                    },
                }),
            );

            trackUserInteraction(`Added new process to Tool`, {
                label: inventoryProcess.label,
            });
        } catch (err) {
            await logError(`thirdParties.onProcessAdded`, err, { inventoryProcess });
        }
    };

    /**
     * This is called when the process has been removed.
     * @param {*} processRemoved { _id, label}
     */

    const onProcessRemoved = async (processRemoved) => {
        try {
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the process removed
                const index = newState.matrixMap.findIndex((c) => c._processId === processRemoved._id);
                if (index === -1) return newState;

                newState.matrixMap.splice(index, 1);

                return newState;
            });

            trackUserInteraction(`Removed process from tool`, {
                label: processRemoved.label,
            });
        } catch (err) {
            await logError(`individuals.onProcessRemoved`, err, { processRemoved });
        }
    };

    return (
        <React.Fragment>
            <Button setEnabled={setEnabled} enabled={enabled} />
            {enabled && (
                <SetProcesses
                    // Closing
                    closeModal={() => setEnabled(false)}
                    // Requirements
                    vitalRecommendations={vitalRecommendations}
                    dataInventory={dataInventory}
                    companyData={companyData}
                    setDataInventory={setDataInventory}
                    // Text
                    textContents={{
                        heading: `Purposes`,
                        description: (
                            <React.Fragment>
                                What is the purpose of <b>{data.label}</b>?
                            </React.Fragment>
                        ),
                    }}
                    // Current data: This is the entries from mapProcessesEntriesToDataInventory
                    currentProcesses={props.entries.map((c) => ({ _id: c.data._id, label: c.data.label }))}
                    // Callbacks
                    onProcessAdded={onProcessAdded}
                    onProcessRemoved={onProcessRemoved}
                />
            )}
        </React.Fragment>
    );
};

export default Component;
