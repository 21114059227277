import React, { useState, useEffect } from 'react';
import { Button, TextField, MenuItem, Select, FormHelperText, Autocomplete } from '@mui/material';
import { gelFlowEditModalSchema } from '../../../../../utils/validations';
import { getValidationPropFields, validateAgainstSchema } from '../../../../../../../../utils/helpers';
import allCountries from '../../../../../../../../dummy_data/countries.json';
import { ModuleState } from '../../../../..';

export const securityMeasures = ['Encryption in Transit', 'Encryption at Rest', 'Encryption in Transit, Encryption at Rest', 'None'];

// Components
let updateGeneralDataTimer = null;
export const customCountries = [
    {
        name: 'European Economic Area',
        code: 'EEA',
        _muiCategory: 'Most common',
    },
    {
        name: 'Global',
        code: 'GO',
        _muiCategory: 'Most common',
    },
    ...allCountries,
];

const General = (props) => {
    const [pageData, setPageData] = useState({
        label: props.storageData.label,
        securityMeasures: props.storageData.securityMeasures,
        dataResidency: props.storageData.dataResidency,
    });
    const { data } = ModuleState();

    const [validationResults, setValidationResults] = useState({});

    const validateFields = async () => {
        try {
            await validateAgainstSchema(gelFlowEditModalSchema, pageData);
            setValidationResults({});

            if (updateGeneralDataTimer !== null) {
                clearTimeout(updateGeneralDataTimer);
            }

            const matchTool = data.storageLocations.find((i) => i.label === pageData.label);
            const matchIndex = data.storageLocations.findIndex((i) => i.label === pageData.label);

            if (matchTool && matchIndex !== -1 && matchIndex !== props.storageIndex) {
                setValidationResults({
                    label: `Name is already used. Try adding a suffix: ${pageData.label} (2)`,
                });
                return false;
            }

            updateGeneralDataTimer = setTimeout(() => {
                props.updateStorageData(`label`, pageData.label);
                props.updateStorageData(`securityMeasures`, pageData.securityMeasures);
                props.updateStorageData(`dataResidency`, pageData.dataResidency);
                updateGeneralDataTimer = null;
            }, 1000);
        } catch (err) {
            setValidationResults(err);
        }
    };

    useEffect(() => {
        validateFields();
        // eslint-disable-next-line
    }, [pageData]);

    return (
        <React.Fragment>
            <div className="module-elements">
                <div className="form-group">
                    <div className="label">
                        <span className="text">Tool or 3rd party name</span>
                    </div>
                    <div className="input-container">
                        <TextField
                            placeholder="Tool Name"
                            variant="standard"
                            value={pageData.label}
                            onChange={(ev) => setPageData({ ...pageData, label: ev.target.value })}
                            fullWidth={true}
                            data-cy={`input-label`}
                            {...getValidationPropFields(validationResults, true, `label`)}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="label">
                        <span className="text">Security Measures</span>
                        {pageData.securityMeasures.length < 1 && <div className="badge badge-data-warning blue" />}
                    </div>
                    <div className="input-container">
                        <Select
                            onChange={(ev) => setPageData({ ...pageData, securityMeasures: ev.target.value })}
                            fullWidth={true}
                            variant="standard"
                            data-cy={`input-securityMeasures`}
                            value={pageData.securityMeasures || 'disabled'}
                        >
                            <MenuItem disabled value="disabled">
                                No selection
                            </MenuItem>
                            {securityMeasures.map((c, ix) => (
                                <MenuItem key={ix} value={c}>
                                    {c}
                                </MenuItem>
                            ))}
                        </Select>
                        {validationResults[`securityMeasures`] !== undefined && (
                            <FormHelperText error={true}>{validationResults['securityMeasures']}</FormHelperText>
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <div className="label">
                        <span className="text">Data Residency</span>
                        {pageData.dataResidency.length < 1 && <div className="badge badge-data-warning blue" />}
                    </div>
                    <div className="input-container">
                        <Autocomplete
                            value={pageData.dataResidency}
                            fullWidth={true}
                            className="country-autocomplete"
                            disableClearable={true}
                            options={customCountries.map((c) => c.name)}
                            groupBy={(option) => {
                                const o = customCountries.find((c) => c.name === option);
                                return o._muiCategory || 'Rest of the world';
                            }}
                            renderInput={(params) => (
                                <TextField fullWidth={true} variant="standard" {...params} placeholder="No selection" />
                            )}
                            data-cy={`input-dataResidency`}
                            freeSolo={false}
                            multiple={false}
                            onChange={(_, value) => setPageData({ ...pageData, dataResidency: value })}
                        />

                        {validationResults[`dataResidency`] !== undefined && (
                            <FormHelperText error={true}>{validationResults['dataResidency']}</FormHelperText>
                        )}
                    </div>
                </div>
            </div>
            <div className="buttons-footer" style={{ marginTop: 24 }}>
                {props.storageData.archived === true ? (
                    <Button data-cy="tool-delete-button" variant="outlined" color="primary" onClick={props.onDelete}>
                        Delete this tool or third party
                    </Button>
                ) : (
                    <Button data-cy="tool-archive-button" variant="outlined" color="primary" onClick={() => props.switchAchiveState(true)}>
                        Archive (not used or doesn't contain personal data)
                    </Button>
                )}
                {props.storageData.archived === true && (
                    <Button
                        data-cy="tool-unarchive-button"
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: 8 }}
                        onClick={() => props.switchAchiveState(false)}
                    >
                        Restore it from archive
                    </Button>
                )}
            </div>
        </React.Fragment>
    );
};

export default General;
