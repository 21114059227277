import React from 'react';

// Components
import { MenuItem, Select } from '@mui/material';

// Context
import { PanelContext } from '../../..';

const Component = () => {
    const { data, updateData } = PanelContext();

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <span className="text">Is Sub-Processor?</span>
                </div>
                <div className="component-content">
                    <Select
                        value={data.isSubProcessor}
                        fullWidth={true}
                        data-cy="is-sub-processor"
                        variant="standard"
                        onChange={(ev) => updateData({ isSubProcessor: ev.target.value })}
                    >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
