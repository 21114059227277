/**
 * The function is done like this so we can access the filters easily.
 * @param {*} data
 * @returns Filters
 */

import { logError } from '../../../../utils/helpers';
import { applyFilters } from '../../../components/dataControls/filters/utils/functions';
import {
    legalResponsibilityRoles,
    personalLegalBasis,
    sensitiveLegalBasis,
} from '../../gdprEssentials/steps/legalResponsibility/utils/dataMaps';

export const getFilterFields = (data, retentionPeriods = []) => {
    // // eslint-disable-next-line
    // console.log({ retentionPeriods });

    return [
        {
            key: 'label',
            type: 'text',
            label: 'Name',
        },
        {
            key: 'legalResponsibility',
            label: 'Legal Responsibility',
            type: 'text',
            options: legalResponsibilityRoles,
        },

        {
            key: 'legalBasis',
            label: 'Legal Basis',
            type: 'text',
            options: personalLegalBasis.map((c) => c.string),
        },
        {
            key: 'sensitiveLegalBasis',
            label: 'Sensitive Legal Basis',
            type: 'text',
            options: sensitiveLegalBasis.map((c) => c.string),
        },
        {
            key: 'individuals',
            label: 'Individuals',
            type: 'array',
            propertyCompared: 'label',
            options: data.individuals, // We will compare our value against the "label" in processes
            optionsLabel: 'label',
        },
        {
            key: 'dataElements',
            label: 'Data Categories',
            type: 'array',
            propertyCompared: 'label',
            options: data.elements.filter((c) => c.sensitive === false),
            optionsLabel: 'label',
        },
        {
            key: 'sensitiveDataElements',
            label: 'Sensitive Data Categories',
            type: 'array',
            propertyCompared: 'label',
            options: data.elements.filter((c) => c.sensitive === true),
            optionsLabel: 'label',
        },
        {
            key: 'storageLocations',
            label: 'Tools & Third Parties',
            type: 'array',
            propertyCompared: 'label',
            options: data.storageLocations,
            optionsLabel: 'label',
        },
        {
            key: 'retentionPeriod',
            label: 'Retention Period',
            type: 'text',
            options: [...retentionPeriods.map((c) => c.name), 'Alternatively'],
        },
    ];
};

/**
 * A simple function that tells us if a certain process is involved in the process of collecting sensitive data.
 * @param {*} dataInventory
 * @param {*} process a process from data inventory
 * @returns boolean
 */

export const isProcessCollectingSensitiveData = (dataInventory, process) => {
    try {
        // Get all sensitive elements
        const sensitiveElements = dataInventory.elements.filter((c) => c.sensitive === true).map((c) => c._id);

        let isUsingSensitiveData = false;

        // Iterate through all matrices
        dataInventory.matrices.forEach(({ entries }) => {
            entries.forEach((matrice) => {
                const matchSensitive = sensitiveElements.includes(matrice._elementId) && matrice._processId === process._id;

                if (matchSensitive) {
                    isUsingSensitiveData = true;
                }
            });
        });

        return isUsingSensitiveData;
    } catch (err) {
        logError(`module.processes.isProcessCollectingSensitiveData`, err, { process });
        return false;
    }
};

/**
 *  this will filter the processes
 * @param {*} dataInventory
 * @param {*} optionsFilters
 * @returns
 */

export const filterProcesses = (dataInventory, optionsFilters, retentionPeriods = []) => {
    try {
        // Get filter fields.
        const fields = getFilterFields(dataInventory, retentionPeriods);

        // We simulate data like this so we can filter it.
        const simulatedData = dataInventory.processes.map((c) => {
            let retentionPeriod = null;

            if (c.retention.type && c.retention.type.includes('recommendation') && retentionPeriods) {
                let id = c.retention.type.split(':')[1];

                const match = retentionPeriods.find((d) => parseInt(d.id) === parseInt(id));

                if (match) {
                    retentionPeriod = match.name;
                }
            } else {
                retentionPeriod = 'Alternatively';
            }

            return {
                _id: c._id, // so we can put it back together
                label: c.label,
                retentionPeriod,
                legalResponsibility: c.role,
                legalBasis: c.personalLegalBasis ? c.personalLegalBasis.string : null,
                sensitiveLegalBasis: c.sensitiveLegalBasis ? c.sensitiveLegalBasis.string : null,
                storageLocations: dataInventory.storageLocations.filter((d) => d.processes.includes(c._id)),
                individuals: dataInventory.individuals.filter((individual) => {
                    // Matched
                    const match = dataInventory.matrices.find((m) => {
                        const matchEntry = m.entries.find((z) => z._processId === c._id && z._individualId === individual._id);
                        return matchEntry;
                    });

                    // Get all the individuals that are linked to this process through a matrix table.
                    return match ? true : false;
                }),
                dataElements: dataInventory.elements
                    .filter((c) => c.sensitive === false)
                    .filter((element) => {
                        // Matched
                        const match = dataInventory.matrices.find((m) => {
                            const matchEntry = m.entries.find((z) => z._processId === c._id && z._elementId === element._id);
                            return matchEntry;
                        });

                        // Get all the normal elements that are linked to this process through a matrix table.
                        return match ? true : false;
                    }),
                sensitiveDataElements: dataInventory.elements
                    .filter((c) => c.sensitive === true)
                    .filter((element) => {
                        // Matched
                        const match = dataInventory.matrices.find((m) => {
                            const matchEntry = m.entries.find((z) => z._processId === c._id && z._elementId === element._id);
                            return matchEntry;
                        });

                        // Get all the sensitive elements that are linked to this process through a matrix table.
                        return match ? true : false;
                    }),
            };
        });

        // Filter the data
        const filteredData = applyFilters(simulatedData, optionsFilters, fields);

        // We now put it back together.
        const processes = filteredData.map(({ _id }) => dataInventory.processes.find((c) => c._id === _id));

        return processes;
    } catch (err) {
        logError(`module.processes.filterProcesses`, err);
        return [];
    }
};
