const shareablesEndpoints = {
    UpdateUsercentricsSettings: {
        link: '/graphql',
        method: 'POST',
        query: `
            mutation EditBannerSettings ($settingsId: String, $email: String, $payload: JSON)
            {
                EditBannerSettings  (
                    settingsId: $settingsId,
                    email: $email, 
                    payload: $payload
                )
            }
        `,
        parserFunction: ({ data }) => data.EditBannerSettings,
    },
    GetUsercentricsSettings: {
        link: '/graphql',
        method: 'POST',
        query: `
        query GetSettings($settingsId: String, $email: String){
          GetSettings (settingsId: $settingsId, email: $email){
              language
              privacyPolicyUrl
              buttonPrivacyOpenIconUrl
              buttonDisplayLocation
              firstLayer{
                  variant
              }
              secondLayer{
                  variant
                  denyButtonText
              }
              customization
              languagesAvailable
              editableLanguages
              privacyButtonIsVisible
              ccpa
              googleConsentMode
              links {
                privacyPolicy {
                    label
                    url
                }
                cookiePolicy {
                    label
                    url
                }
              }
            }
          }
        `,
        parserFunction: ({ data }) => data.GetSettings,
    },
};

export default shareablesEndpoints;
