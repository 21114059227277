import React from 'react';

// Context
import { AddToolContext } from '..';
import { CircularProgress } from '@mui/material';

const Component = () => {
    const { openPanel, addingTool } = AddToolContext();

    return (
        <React.Fragment>
            <div className={`add-tool-btn ${addingTool && 'disabled'}`} variant="contained" onClick={openPanel}>
                <i className="icon fa-solid fa-circle-plus"></i>
                <div className="label">Add a tool or third party</div>
                {addingTool && <CircularProgress style={{ marginLeft: 16 }} size={20} color="primary" />}
            </div>
        </React.Fragment>
    );
};

export default Component;
