import React from 'react';

// Components
import Header from './components/header';
import SubHeader from './components/subHeader';
import List from './components/list';

const Component = () => {
    return (
        <React.Fragment>
            <Header />
            <SubHeader />
            <List />
        </React.Fragment>
    );
};

export default Component;
