import React, { useEffect, useState } from 'react';

import { Autocomplete, TextField, Chip } from '@mui/material';

import { ComponentState } from '../../../../..';
import { ComponentState as CB_ComponentState } from '../../../../../../cookieBanner/index';
import { logError } from '../../../../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../../../../utils/endpoints';

// Components
import Dialog from './components/dialog';

const Component = (props) => {
    const [localData, setLocalData] = useState(props.data);
    const [editCookie, setEditCookie] = useState(null);
    const [editData, setEditData] = useState({});
    const { data, specificRecommendations } = props.componentSource === 'cb' ? CB_ComponentState() : ComponentState();

    const [languages, setLanguages] = useState({
        privacyPortal: null,
    });

    const getPreparedTools = () => {
        return props.tools.map((tool) => {
            return {
                name: tool.label,
                _storageLocationId: tool._storageLocationId,
                id: tool._id,
                type: props.type,
                purpose: '',
                external_link: '',
                translations: {
                    cookiePurpose: {},
                },
                cookieDefaultConsentStatus: false,
            };
        });
    };

    useEffect(() => {
        props.syncCookies(
            props.type,
            localData.filter((c) => c.purpose.length > 0),
        );
        //eslint-disable-next-line
    }, [localData]);

    const cancelEdit = () => {
        if (editData.purpose.length < 1 || editData.external_link.length < 1) {
            const newArr = [...localData];
            newArr.splice(editCookie, 1);
            setLocalData(newArr);
        }
        setEditData({});
        setEditCookie(null);
    };

    const saveEdit = () => {
        const newArr = [...localData];
        newArr[editCookie] = {
            ...newArr[editCookie],
            purpose: editData.purpose,
            external_link: editData.external_link,
            translations: editData.translations,
            cookieDefaultConsentStatus: editData.cookieDefaultConsentStatus,
            cookieNames: editData.cookieNames,
            cookieExpiry: editData.cookieExpiry,
        };
        setLocalData(newArr);
        setEditCookie(null);
        setEditData({});
    };

    const startEditing = (index) => {
        setEditCookie(index);
        setEditData(localData[index]);
    };

    const deleteCookie = (index) => {
        const newArr = [...localData];
        let newDeletedArr = { ...props.previouslyDeletedRecommendation };
        newDeletedArr[newArr[index].name] = newArr[index];
        newArr.splice(index, 1);
        setLocalData(newArr);
        props.setPreviouslyDeletedRecommendation(newDeletedArr);
    };

    const startCreation = (value, index) => {
        let storageLoc = specificRecommendations.storageLocations.find((loc) => loc.label === value.name);

        if (storageLoc) {
            // Loading the recommendations for the cookies
            value.purpose = storageLoc.cookiePurpose || '';
            value.external_link = storageLoc.cookieExternalInfo || '';
        }

        if (props.previouslyDeletedRecommendation[value.name] !== undefined) {
            // If the user recently deleted a cookie when he re-adds it the data is there
            const previouslyDeleted = { ...props.previouslyDeletedRecommendation[value.name] };
            value.purpose = previouslyDeleted.purpose;
            value.external_link = previouslyDeleted.external_link;
            value.translations = previouslyDeleted.translations;
            value.cookieDefaultConsentStatus = previouslyDeleted.cookieDefaultConsentStatus;
            value.cookieNames = previouslyDeleted.cookieNames;
            value.cookieExpiry = previouslyDeleted.cookieExpiry;
        }

        setEditCookie(index);
        setEditData(value);
    };

    const getLanguages = async () => {
        try {
            const langs = data.languagesAvailable;

            const res = await makeEndpointRequest('GetSystemTranslations', {
                systems: ['privacyPortal'],
                languages: langs,
                payload: {
                    _companyId: data._id,
                },
            });

            setLanguages({
                privacyPortal: res.privacyPortal,
            });
        } catch (err) {
            await logError(`GET_LANGUAGES`, err);
        }
    };

    useEffect(() => {
        getLanguages();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className="form-label">
                Add all tools and 3rd parties that place <b>{props.label} cookies</b> on your website
            </div>

            <div className="form-content">
                <Autocomplete
                    multiple={true}
                    data-cy={`autocomplete-cookies-${props.label}`}
                    options={getPreparedTools()}
                    onChange={(_, value) => {
                        setLocalData(value);
                        startCreation(value[localData.length], localData.length);
                    }}
                    isOptionEqualToValue={(option, value) => {
                        return option.name === value.name ? true : false;
                    }}
                    noOptionsText="There are no more tools or third-parties available to pick"
                    getOptionLabel={(option) => `${option.name}`}
                    value={localData}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                key={index}
                                variant="outlined"
                                label={option['name']}
                                deleteIcon={
                                    <React.Fragment>
                                        <div data-cy={`chip-edit`} onClick={() => startEditing(index)}>
                                            <i className="dropdown-icon fa-light fa-pen-to-square" />
                                        </div>

                                        <div data-cy={`chip-delete`} onClick={() => deleteCookie(index)}>
                                            <i className="dropdown-icon fa-solid fa-circle-xmark"></i>
                                        </div>
                                    </React.Fragment>
                                }
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => <TextField {...params} placeholder={localData.length < 1 ? `${props.label} Cookies` : ''} />}
                />
            </div>
            {editCookie !== null && editData && (
                <Dialog
                    languagesAvailable={data.languagesAvailable}
                    languages={languages}
                    cancelEdit={cancelEdit}
                    editData={editData}
                    setEditData={setEditData}
                    saveEdit={saveEdit}
                />
            )}
        </React.Fragment>
    );
};

export default Component;
