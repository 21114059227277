import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { useEffect } from 'react';
import { trackUserInteraction, logError, formatNumber } from '../../../../../../utils/helpers';
import { AuthStore } from '../../../../../../utils/context/authStore';
import { makeLocalEndpointRequest } from '../../../../../../utils/endpoints';

const Component = (props) => {
    const { account } = AuthStore();
    const [show, setShow] = useState(false);

    const onCancel = async () => {
        await trackUserInteraction(`Selected "Cancel"`);
        props.hidePaywall();
        setShow(false);
    };

    const onSubmit = async () => {
        try {
            if (account.stripeCustomerId) return false; // is not gonna work.
            await trackUserInteraction(`Selected "Update Billing Details"`);
            const data = await makeLocalEndpointRequest(
                `/api/create-cb-checkout-session`,
                {
                    email: account.email,
                    packageId: account.companyPackage._id,
                },
                'POST',
            );
            window.location.href = data.url;
        } catch (err) {
            await logError(`GET_CHECKOUT_COOKIE_BANNER`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later.`, `error`);
        }
    };

    const onShow = ({ detail }) => {
        if (detail === true) {
            if (account.stripeCustomerId) return false; // is not gonna work.
            trackUserInteraction(`Update billing details required`, {
                reason: `trialModeEnded`,
            });
            setShow(true);
        } else setShow(false);
    };

    useEffect(() => {
        document.addEventListener('cookieBanner:showUpdateInvoice', onShow);
        document.addEventListener('cookieBanner:startCheckoutSession', onSubmit);
        return () => {
            document.removeEventListener('cookieBanner:showUpdateInvoice', onShow);
            document.removeEventListener('cookieBanner:startCheckoutSession', onSubmit);
        };
        // eslint-disable-next-line
    }, []);

    if (show === false) return null;

    return (
        <React.Fragment>
            <Dialog open={true}>
                <DialogTitle>Reached monthly limit of sessions</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p style={{ margin: 0, marginBottom: 8 }}>
                            You've reached your monthly limit of{' '}
                            {formatNumber(account.companyPackage.usageLimits.cookieBannerDiscountedSessions)} free sessions available with
                            your current plan.
                        </p>
                        <p style={{ margin: 0, marginBottom: 0 }}>
                            In order to continue using the cookie banner, please press the button below to add your billing details.
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: 18 }}>
                    <Button color="primary" variant="outlined" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" onClick={onSubmit}>
                        Update billing details
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
