import React from 'react';

const Component = () => {
    return (
        <div className="page-header">
            <div className="label">Security Measures</div>
        </div>
    );
};

export default Component;
