import React from 'react';
import { ComponentState } from '../../..';

// Component
import Autocomplete from '../utils/autocomplete';

const Component = () => {
    const { draftData, updateDraftData, vitalRecommendations } = ComponentState();

    const getOptions = () => {
        let arr = vitalRecommendations.individuals.filter((e) => e.inDpa === true);
        arr = arr.map((x) => ({ label: x.label, category: x.category }));
        return arr;
    };

    return (
        <React.Fragment>
            <div className="form-group extra-spacing">
                <div className="form-label mb">
                    What <b>individuals’</b> data is processed when providing your services/platform?
                </div>
                <div className="form-content">
                    <Autocomplete
                        value={draftData.individuals}
                        options={getOptions()}
                        groupBy={(e) => e.category}
                        onDataChanged={(newValues) => updateDraftData('individuals', newValues)}
                        placeholder="Select individuals from this list"
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
