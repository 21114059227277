import React, { createContext, useContext, useState } from 'react';

// Components
import Button from './components/button';
import Modal from './components/modal';

// Panel Context
import { PanelContext } from '../../../../../../';

// Dependencies
import { createIndividual as formatIndividual } from '../../../../../../../../../gdprEssentials/steps/storageLocations/utils/helpers';
import { logError, trackUserInteraction } from '../../../../../../../../../../../utils/helpers';
import { getIndividualTypesIdFromLabel } from '../../../../../../../../../gdprEssentials/steps/individuals/components/functions';
import { makeEndpointRequest } from '../../../../../../../../../../../utils/endpoints';

// Context
const Context = createContext({});
export const SetIndividualsContext = () => useContext(Context);

const Component = (props) => {
    const [enabled, setEnabled] = useState(false);

    const { vitalRecommendations, dataInventory, setDataInventory, setData } = PanelContext();

    const getOptions = () => {
        try {
            // If is too early and is still loading.
            if (!vitalRecommendations || !vitalRecommendations.individuals) return [];

            let individuals = [];

            // Adding the vital core recommendations of processes...
            vitalRecommendations.individuals.forEach((p) => {
                individuals.push(
                    formatIndividual({
                        label: p.label,
                        category: p.category || '',
                    }),
                );
            });

            // Adding custom ones
            dataInventory.individuals.forEach((indiv) => {
                // Already exist in this array
                if (individuals.find((x) => x.label === indiv.label)) return;

                // Adding the new one..
                individuals.push(
                    formatIndividual({
                        label: indiv.label,
                        category: 'Custom',
                    }),
                );
            });

            // Sort them like this to avoid a warning in the browser that they weren't ordered right.
            return individuals.sort((a, b) => a.category.localeCompare(b.category));
        } catch (err) {
            logError(`module.processes.panel.getIndividualOptions`, err);
            return [];
        }
    };

    const createIndividual = async (individualName) => {
        try {
            // Get recommendations for this process.
            let rec = vitalRecommendations.individuals.find((c) => c.label === individualName);

            // Format referring role
            let referringRole = rec && rec.type && getIndividualTypesIdFromLabel(rec.type) ? getIndividualTypesIdFromLabel(rec.type) : null;

            // Create individual..
            const newIndividual = await makeEndpointRequest(`CreateIndividual`, {
                payload: {
                    label: individualName,
                },
                individualReferring: {
                    referringRole: referringRole || undefined,
                    isChildren: individualName === 'Children' ? true : undefined,
                },
            });

            // Add it to data inventory
            setDataInventory((currentState) => {
                // Format new data
                let newState = { ...currentState };

                // Change it..
                newState.individuals.push(newIndividual);

                return newState;
            });

            return newIndividual;
        } catch (err) {
            await logError(`module.thirdParties.panel.createIndividual`, err, { individualName });
            return false;
        }
    };

    const addIndividual = async (individualAdded) => {
        try {
            // Does this individual exist in the data inventory?
            let inventory = dataInventory.individuals.find((c) => c.label === individualAdded.label);

            // Does it exist in the data inventory already?
            if (!inventory) {
                // Create the process
                inventory = await createIndividual(individualAdded.label);

                // If it failed to create
                if (inventory === null) throw new Error(`Failed to create individual.`);
            }

            // Add the individual to the panel's tool list of individuals.
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the current tool.
                const mIndex = newState.matrixMap.findIndex((c) => c._processId === props.process._id);
                if (mIndex === -1) return newState;

                // Found index
                newState.matrixMap[mIndex].individuals.push({
                    _individualId: inventory._id,
                    elements: [],
                });

                return newState;
            });

            trackUserInteraction(`Added new individual to Process`, {
                label: individualAdded.label,
                processLabel: props.process.label,
            });
        } catch (err) {
            await logError(`module.thirdParties.panel.addIndividual`, err, { individualAdded });
            return false;
        }
    };

    const removeIndividual = async (individualRemoved) => {
        try {
            // Remove the individual to the panel's tool list of individuals.
            setData((currentState) => {
                let newState = { ...currentState };

                // Get the index of the current tool.
                const mIndex = newState.matrixMap.findIndex((c) => c._processId === props.process._id);
                if (mIndex === -1) return newState;

                // Found index
                newState.matrixMap[mIndex].individuals = newState.matrixMap[mIndex].individuals.filter(
                    (c) => c._individualId !== individualRemoved._id,
                );

                return newState;
            });

            trackUserInteraction(`Removed individual from Process`, {
                label: individualRemoved.label,
                processLabel: props.process.label,
            });
        } catch (err) {
            await logError(`module.processes.panel.removeIndividual`, err);
            return false;
        }
    };
    const passedProps = {
        individuals: props.individuals,
        enabled,
        options: getOptions(),
        setEnabled,
        process: props.process,
        // Callback functions
        addIndividual,
        removeIndividual,
    };

    return (
        <Context.Provider value={passedProps}>
            <Button />
            <Modal />
        </Context.Provider>
    );
};

export default Component;
