import React, { useState, useEffect, createContext, useContext } from 'react';
import Container from '../../layout/container';
import lodash from 'lodash';
import { logError, trackUserInteraction } from '../../../utils/helpers';
import { makeEndpointRequest } from '../../../utils/endpoints';
import { AuthStore } from '../../../utils/context/authStore';
import { useHistory } from 'react-router-dom';

// Components
import Menu from './components/menu';
import Header from './components/header';

// Context
const Context = createContext({});
export const SettingsContext = () => useContext(Context);

// Sections
import Loading from './sections/loading/index';
import Company from './sections/company/index';
import Billing from './sections/billing/index';
import UsageLimits from './sections/usageLimits';
import Scanners from './sections/scanners';

const SectionMaps = {
    company: Company,
    loading: Loading,
    billing: Billing,
    scanners: Scanners,
    'usage-limits': UsageLimits,
};

export const getDefaultSection = (paramTab, mappedSections, defaultIfNot) => {
    if (!paramTab) return defaultIfNot;
    const match = Object.keys(mappedSections)
        .map((k) => k.toString().toLocaleLowerCase())
        .find((x) => x === paramTab);
    if (!match) return defaultIfNot;
    return paramTab;
};

const Component = (props) => {
    const { account } = AuthStore();
    const [data, setData] = useState(undefined);
    const [sectionRendered, setSectionRendered] = useState(getDefaultSection(props.match.params.tab, SectionMaps, `company`));
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState(undefined);
    const [validationKeys, setValidationKeys] = useState({});
    const history = useHistory();

    const [flags, setFlags] = useState({});

    const updateData = (path, val) => {
        const objMod = Object.assign({}, data);
        lodash.set(objMod, path, val);
        setData({ ...objMod });
    };

    const submitData = async () => {
        try {
            await trackUserInteraction(`Selected "Save Changes"`);
            const privacyFields = {
                companyLegalName: data.companyLegalName,
                inquiriesEmail: data.inquiriesEmail,
                dpoName: data.dpoName,
                companyAddress: data.companyAddress,
                companyDomains: data.companyDomains,
                monitoringSettings: data.monitoringSettings,
                companyDescription: data.companyDescription,
                targetAudience: data.targetAudience,
                industry: data.industry,
                companyInsights: data.companyInsights,
                services: data.services,
            };
            setLoading(true);
            await makeEndpointRequest('SaveCompanyInfo', { update: privacyFields });
            setDefaultData({ ...data });
            setLoading(false);
            window.showAlert('Settings Saved', `All the changes you've made have been saved.`, `success`);
        } catch (err) {
            await logError(`UPDATE_SETTINGS`, err);
            await trackUserInteraction(`Having Difficulties`, {
                reason: `Failed to save the privacy settings`,
            });
            window.showAlert('Having Difficulties', `The changes you've made couldn't be saved. Please try again!`, `error`);
            setLoading(false);
        }
    };

    const redirectPrivacyPortalSettings = async () => {
        trackUserInteraction(`Selected "Looking for Privacy Portal Settings"`);
        history.push(`/settings/privacy-portal`);
    };

    const SectionComponent = SectionMaps[!data || (data && Object.keys(data).length < 1) ? 'loading' : sectionRendered];

    const loadFlags = async () => {
        try {
            setFlags({
                ...flags,
            });
        } catch (err) {
            await logError(`FAILED_CHECK_FLAGS`, err, { page: '/settings' });
        }
    };

    useEffect(() => {
        const asyncLoadData = async () => {
            try {
                setLoading(true);
                await trackUserInteraction(`Settings`);
                const companyInfo = await makeEndpointRequest(`getPrivacyPortal`, { companyId: account._companyId });
                await loadFlags();

                // @Reminder: In the next months we should combine the api above and this one.
                const meta = await makeEndpointRequest(`getSettingsMeta`);

                const dataObject = {
                    ...companyInfo,
                    ...meta,
                };

                setData({ ...dataObject });
                setDefaultData({ ...dataObject });
                setLoading(false);
            } catch (err) {
                await logError(`LOAD_SETTINGS`, err);
                await trackUserInteraction(`Having Difficulties`, {
                    reason: `Loading settings failed`,
                });
                setData(null);
                setLoading(false);
            }
        };
        asyncLoadData();
        // eslint-disable-next-line
    }, []);

    const refreshUsageData = async () => {
        try {
            const meta = await makeEndpointRequest(`getSettingsMeta`);
            setData({ ...data, ...meta });
            setDefaultData({ ...defaultData, ...meta });
        } catch (err) {
            await logError(`REFRESH_PACKAGE_USAGE_DATA`, err);
        }
    };

    useEffect(() => {
        window.history.pushState('_', '_', `/settings/${sectionRendered}`);
        setData({ ...defaultData });
        // eslint-disable-next-line
    }, [sectionRendered]);

    const SectionProps = {
        sectionRendered,
        setSectionRendered,
        data,
        setData,
        updateData,
        loading,
        setLoading,
        submitData,
        validationKeys,
        setValidationKeys,
        refreshUsageData,
        flags,
    };

    return (
        <Container title={`Settings`} classNames="page-settings">
            <Context.Provider value={SectionProps}>
                <Header />
                <div className="page-content">
                    <Menu loading={loading} data={data} value={sectionRendered} onChange={(val) => setSectionRendered(val)} />
                    <SectionComponent {...SectionProps} />
                </div>
                <div className="pps-container">
                    <div className="shortcut-pps" onClick={redirectPrivacyPortalSettings}>
                        Looking for <span>Privacy portal settings</span>? Click here.
                    </div>
                </div>
            </Context.Provider>
        </Container>
    );
};

export default Component;
