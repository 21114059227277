import React from 'react';

import { Checkbox } from '@mui/material';

const Component = (props) => {
    const individuals = props.toolData.individuals.map((c) => c.label);
    const elements = props.toolData.elements.map((c) => c.label);

    const isChecked = (element, individual) => {
        const match = props.toolData.matrixMap.find(
            (c) => c.element === element && c.individual === individual && c.process === props.pickedProcess,
        );

        return match ? true : false;
    };

    return (
        <React.Fragment>
            <div className="data-matrix-table">
                <div className="header">
                    <div className="entry empty"></div>
                    {individuals.map((entry, index) => (
                        <div key={index} className="entry">
                            {entry}
                        </div>
                    ))}
                </div>
                <div className="rows">
                    {elements.map((element, ix) => (
                        <div className="entry" key={ix}>
                            <div className="label">
                                <div className="text">{element}</div>
                            </div>
                            {individuals.map((individual, ixx) => (
                                <div key={ixx} className={`checkbox ${ixx === 0 ? `first` : `second`}`}>
                                    <Checkbox
                                        disabled={true}
                                        className="golden-checkbox"
                                        key={`checkbox--${ix}-${ixx}`}
                                        checked={isChecked(element, individual) ? true : false}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
