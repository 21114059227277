import React from 'react';
import { useHistory } from 'react-router-dom';
import { HomepageContext } from '..';
import { CircularProgress } from '@mui/material';
import { AuthStore } from '../../../../utils/context/authStore';

// Renders N number of items from an array
const renderNItems = (array, nameTag, n) => {
    const toRender = [];
    for (let i = 0; i < n; i++) {
        const itemToRender = array ? array[i] : '';
        if (itemToRender) {
            if (!nameTag) toRender.push(`${i > 0 ? ', ' : ''}${itemToRender}`);
            if (itemToRender[nameTag]) {
                toRender.push(`${i > 0 ? ', ' : ''}${itemToRender[nameTag]}`);
            }
        }
    }
    return toRender && toRender.length > 0 ? toRender : '';
};

const PersonalDataInventory = () => {
    const { loading, error, data } = HomepageContext();
    const { checkUserAccess } = AuthStore();

    const router = useHistory();

    return (
        <React.Fragment>
            <div className="card-bg shortcut-card data-inventory">
                <div className="header">
                    <div data-cy="data-inventory-title" className="title">
                        Data Inventory
                    </div>
                </div>
                <div className="header-description wb">
                    This inventory holds records of what data you collect. Updating it will guarantee that your policies are up-to-date.
                </div>
                <div className="content">
                    {loading === false && error === false ? (
                        <React.Fragment>
                            <div className="entries">
                                <div className="entry">
                                    <i className="icon fa-light fa-server"></i>
                                    <div className="details">
                                        <div className="--left-side">
                                            <div className="label">Tools and Third Parties</div>
                                            <div data-cy="data-locations" className="description">
                                                <span>{data.personalDataInventory.locations.length}</span>{' '}
                                                {renderNItems(data.personalDataInventory.locations, null, 2)}
                                            </div>
                                        </div>
                                        <div className="--right-side">
                                            <div
                                                className={`arrow ${(loading || error) && `disabled`}`}
                                                onClick={() =>
                                                    router.push(checkUserAccess({ flag: 'modules' }) ? '/third-parties' : '/data-inventory')
                                                }
                                            >
                                                <i className="icon fa-light fa-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <i className="icon fa-light fa-clipboard-list"></i>
                                    <div className="details">
                                        <div className="--left-side">
                                            <div className="label">Data Processes</div>
                                            <div data-cy="data-processes" className="description">
                                                <span>{data.personalDataInventory.processes.length}</span>{' '}
                                                {renderNItems(data.personalDataInventory.processes, null, 2)}
                                            </div>
                                        </div>
                                        <div className="--right-side">
                                            <div
                                                className={`arrow ${(loading || error) && `disabled`}`}
                                                onClick={() =>
                                                    router.push(checkUserAccess({ flag: 'modules' }) ? '/processes' : '/data-inventory')
                                                }
                                            >
                                                <i className="icon fa-light fa-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <i className="icon fa-light fa-users"></i>
                                    <div className="details">
                                        <div className="--left-side">
                                            <div className="label">Categories of Individuals</div>
                                            <div data-cy="data-subjects" className="description">
                                                <span>{data.personalDataInventory.subjects.length}</span>{' '}
                                                {renderNItems(data.personalDataInventory.subjects, null, 2)}
                                            </div>
                                        </div>
                                        <div className="--right-side">
                                            <div
                                                className={`arrow ${(loading || error) && `disabled`}`}
                                                onClick={() =>
                                                    router.push(checkUserAccess({ flag: 'modules' }) ? '/individuals' : '/data-inventory')
                                                }
                                            >
                                                <i className="icon fa-light fa-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="entry">
                                    <i className="icon fa-light fa-list-ul"></i>
                                    <div className="details">
                                        <div className="--left-side">
                                            <div className="label">Personal Data Categories</div>
                                            <div data-cy="data-categories" className="description">
                                                <span>{data.personalDataInventory.elements.length}</span>{' '}
                                                {renderNItems(data.personalDataInventory.elements, null, 2)}
                                            </div>
                                        </div>
                                        {/* <div className="--right-side">
                                            <div
                                                className={`arrow ${(loading || error) && `disabled`}`}
                                                onClick={() => router.push(globalFlags.elementsModule ? '/elements' : '/data-inventory')}
                                            >
                                                <i className="icon fa-light fa-chevron-right"></i>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : loading ? (
                        <div className="centered-container">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="centered-container">
                            <i className="icon fa-solid fa-triangle-exclamation"></i>
                            <span>Experiencing technical difficulties</span>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default PersonalDataInventory;
