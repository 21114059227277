import React, { useState } from 'react';
import { PageContext } from '..';
import { getGravatarUrl, logError, trackUserInteraction } from '../../../../utils/helpers';

// Components
import { Avatar, ButtonBase, Menu, MenuItem } from '@mui/material';
import { AuthStore } from '../../../../utils/context/authStore';
import { makeEndpointRequest } from '../../../../utils/endpoints';

const Component = () => {
    const { data } = PageContext();
    const { account } = AuthStore();

    const getUsers = () => data.users.filter((e) => (e.isSupport === true && !account.email.includes('@privasee') ? false : true));

    return (
        <React.Fragment>
            <div className="list">
                <div className="row header">
                    <div className="col user">User</div>
                    <div className="col role">Role</div>
                    <div className="col options"></div>
                </div>
                {getUsers().map((entry, ix) => (
                    <div className="row" key={ix}>
                        <div className="col user">
                            <Avatar src={getGravatarUrl(entry.details.email)} />
                            <div className="text">{entry.details.email}</div>
                        </div>
                        <div className="col role">
                            {entry.role === 'admin' ? 'Admin' : 'Member'} {entry.isSupport && '(Support)'}
                        </div>
                        <div className="col options">
                            <ActionsDropdown user={entry} />
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

const ActionsDropdown = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { data, loadData } = PageContext();
    const { account } = AuthStore();

    // Get the user current role
    const currentRole = data.users.find((e) => e._userId === account._id).role;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const removeUser = async () => {
        try {
            await makeEndpointRequest(`RemoveUserWorkspace`, { _workspaceId: data._id, _userId: props.user._userId });
            await loadData();
            await trackUserInteraction(`Removed user from workspace`, { _workspaceId: data._id, _userId: props.user._userId });
        } catch (err) {
            trackUserInteraction(`Having Difficulties`, { reason: `failed to remove user from workspace` });
            await logError(`REMOVE_USER_FROM_WORKSPACE`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    const confirmUserRemoval = () => {
        // Hiding it..
        handleClose();

        if (data.users.length === 1) {
            return window.showAlert(
                'Information',
                `You cannot have a workspace with no users. You need to consider deleting the workspace completely.`,
                `warning`,
            );
        }

        // Show alert..
        window.showAlert('Confirmation', `Are you sure you want to delete ${props.user.details.email} from this workspace?`, 'warning', [
            {
                text: 'Cancel',
                dataCy: `alert-cancel-button`,

                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                },
            },
            {
                text: 'Confirm',
                dataCy: `alert-submit-button`,
                onClick: async ({ dismissAlert }) => {
                    dismissAlert();
                    removeUser();
                },
            },
        ]);
    };

    const changeRole = async (newRole) => {
        // Hiding it..
        handleClose();

        try {
            await makeEndpointRequest(`UpdateUserWorkspace`, {
                _workspaceId: data._id,
                _userId: props.user._userId,
                payload: {
                    role: newRole,
                },
            });

            await loadData();
            await trackUserInteraction(`Updated user role`, { role: newRole, _userId: props.user._userId, _workspaceId: data._id });
        } catch (err) {
            trackUserInteraction(`Having Difficulties`, { reason: `failed to update user in workspace` });
            await logError(`UPDATE_USER_IN_WORKSPACE`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    const confirmRoleChange = async (newRole) => {
        // Close dropdown
        handleClose();

        const admins = data.users.filter((e) => e.role === 'admin');

        if (admins.length === 1 && newRole === 'member') {
            return window.showAlert('Information', `You cannot have a workspace with no admins.`, `warning`);
        }

        // Show alert..
        window.showAlert(
            'Confirmation',
            `Are you sure you want to change ${props.user.details.email}'s role to ${newRole === 'admin' ? 'Admin' : 'Member'}?`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,

                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        changeRole(newRole);
                    },
                },
            ],
        );
    };

    const getOptions = () => {
        const arr = [];

        if (currentRole === 'admin') {
            arr.push({
                label: `Set Role to ${props.user.role === 'admin' ? 'Member' : 'Administrator'}`,
                onClick: () => confirmRoleChange(props.user.role === 'admin' ? 'member' : 'admin'),
            });
        }

        if (currentRole === 'admin' || props.user._userId === account._id) {
            arr.push({
                label: props.user._userId === account._id ? `Leave Workspace` : `Remove from Workspace`,
                onClick: () => confirmUserRemoval(),
            });
        }

        return arr;
    };

    return (
        <React.Fragment>
            <ButtonBase className="btn" onClick={handleClick} disabled={getOptions().length < 1}>
                <i className="elm fa-solid fa-ellipsis"></i>
            </ButtonBase>
            <Menu anchorEl={anchorEl} open={anchorEl ? true : false} onClose={handleClose}>
                {getOptions().map((entry, ix) => (
                    <MenuItem key={ix} onClick={entry.onClick}>
                        {entry.label}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
};

export default Component;
