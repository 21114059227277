import React from 'react';

// Context
import { OnboardingFunctions } from '../../../components/functions';
import { Button } from '@mui/material';

// Context
import { logError } from '../../../../../../utils/helpers';
import { ViewContext } from '..';

const Component = () => {
    const { navigateViews } = OnboardingFunctions();
    const { updateIndividualReferences } = ViewContext();

    const nextStep = async () => {
        try {
            // Update
            updateIndividualReferences();

            // Navigate
            navigateViews('forward');
        } catch (err) {
            await logError(`onboarding.children.nextStep`, err);
        }
    };

    const previousStep = async () => {
        try {
            // Update
            updateIndividualReferences();

            // Navigate
            navigateViews('backward');
        } catch (err) {
            await logError(`onboarding.children.previousStep`, err);
        }
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">Children Data</div>
                    <div className="description">The GDPR gives special protection to the data of individuals under the age of 16.</div>
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" onClick={nextStep} data-cy="next-button">
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
