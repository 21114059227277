import React from 'react';

// Definitions
import { trackUserInteraction } from '../../../../../../../../../../utils/helpers';

const Component = (props) => {
    const onPress = () => {
        trackUserInteraction(`Selected "Add a Data Process`);
        props.setEnabled(true);
    };

    return (
        <React.Fragment>
            <div className={`add-tool-btn`} variant="contained" onClick={onPress} data-cy="btn-add-process">
                <i className="icon fa-solid fa-circle-plus"></i>
                <div className="label">Add a Purpose</div>
            </div>
        </React.Fragment>
    );
};

export default Component;
