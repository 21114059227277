import React from 'react';

// Context
import { ViewContext } from '..';

const Component = (props) => {
    const { onOptionSelected } = ViewContext();

    return (
        <React.Fragment>
            <div
                className={`entry ${props.data.isChecked ? 'selected' : 'not-selected'} ${props.data.disabled && 'disabled'}`}
                onClick={() => onOptionSelected(props.data.label)}
            >
                <div className="content">
                    <div className="left-side">
                        <div className="label">{props.data.label}</div>
                    </div>
                    <div className="checkmark">
                        <i className="icon fa-solid fa-check"></i>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
