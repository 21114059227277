import React from 'react';

import { TextField } from '@mui/material';
import { PageContext } from '..';

const Component = () => {
    const { data, setData, sendMessage } = PageContext();

    return (
        <React.Fragment>
            <div className="input-area">
                <div className="input-container">
                    <TextField
                        fullWidth={true}
                        disabled={data.waitingReply}
                        placeholder="Ask your question here..."
                        variant="outlined"
                        value={data.input}
                        onChange={(ev) => setData((currentState) => ({ ...currentState, input: ev.target.value }))}
                        maxRows={10}
                        multiline={true}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter' && !ev.shiftKey) {
                                ev.preventDefault();
                                sendMessage();
                            }
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
