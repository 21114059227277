import React from 'react';

// Components
import Entry from './components/entry';

// Context
import { OnboardingContext } from '../../../..';

const Component = () => {
    const { dataInventory } = OnboardingContext();

    const getListingStorageLocations = () => {
        return dataInventory.storageLocations.filter((c) => c.archived === false);
    };

    const startPicking = async (ev) => {
        const attr = ev.target.getAttribute('data-start-picking');
        if (attr !== 'true') return false;

        document.dispatchEvent(new CustomEvent('toolPicker.open'));
    };

    return (
        <React.Fragment>
            <div className="component-storage-locations-input">
                <div className="entries" data-start-picking="true" onClick={startPicking}>
                    {getListingStorageLocations().map((storageLocation, index) => (
                        <Entry data={storageLocation} key={index} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
