import React from 'react';
import { ComponentState } from '..';
import { CircularProgress } from '@mui/material';

const Component = () => {
    const { loading } = ComponentState();
    return (
        <React.Fragment>
            <div className={`page-not-loaded ${loading ? `loading` : ``}`}>
                {loading ? (
                    <React.Fragment>
                        <CircularProgress size={30} /> <span>Loading the settings..</span>
                    </React.Fragment>
                ) : (
                    `We failed to load this page, please contact us for technical support.`
                )}
            </div>
        </React.Fragment>
    );
};

export default Component;
