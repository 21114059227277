import React from 'react';

// Components
import Autocomplete from '../../components/Autocomplete';
import ModalIndividual from './components/modalIndividual';

// Context
import { ModuleState } from '../../../../../../';

// Dependencies
import { createIndividual } from '../../../../../storageLocations/utils/helpers';

const Home = (props) => {
    const { data, vitalRecommendations } = ModuleState();

    const getAutocompleteOptions = () => {
        let processes = [],
            individuals = [],
            elements = [];

        // Iterate through company services
        data.companyInfo.services.forEach((service) => {
            processes.push({
                label: service.label,
                category: `Core Services provided to your customers`,
            });
        });

        vitalRecommendations.processes.forEach((p) => {
            processes.push({
                label: p.label,
                category: p.category || '',
            });
        });

        vitalRecommendations.elements.forEach((e) => {
            elements.push({
                label: e.label,
                description: e.description || '',
                sensitive: false,
            });
        });

        vitalRecommendations.sensitiveElements.forEach((e) => {
            elements.push({
                label: e.label,
                description: '',
                sensitive: true,
            });
        });

        vitalRecommendations.individuals.forEach((i) => {
            individuals.push(
                createIndividual({
                    label: i.label,
                    category: i.category || '',
                }),
            );
        });

        // Adding custom ones
        data.storageLocations.forEach((storage) => {
            storage.processes.forEach((process) => {
                if (processes.find((x) => x.label === process.label)) return;
                if (props.storageData.processes.find((y) => y.label === process.label)) return;
                processes.push({
                    label: process.label,
                    category: `Custom`,
                });
            });

            storage.elements.forEach((element) => {
                if (elements.find((x) => x.label === element.label)) return;
                if (props.storageData.elements.find((x) => x.label === element.label)) return;

                elements.push({
                    label: element.label,
                    description: ``,
                    sensitive: element.sensitive,
                });
            });

            storage.individuals.forEach((individual) => {
                if (individuals.find((x) => x.label === individual.label)) return;
                if (props.storageData.individuals.find((y) => y.label === individual.label)) return;

                individuals.push(
                    createIndividual({
                        label: individual.label,
                        category: `Custom`,
                    }),
                );
            });
        });

        // Adding individuals refer
        data.companyInfo.individualsReferring.forEach((i) => {
            // Already added.
            if (individuals.find((c) => c.label === i.label)) return;

            individuals.push(
                createIndividual({
                    label: i.label,
                    category: 'Custom',
                }),
            );
        });

        return {
            processes,
            elements,
            individuals,
        };
    };

    const onIndividualsChange = (newValue) => {
        // Get the current data...
        const currentData = props.storageData.individuals;

        // It means we deleted an individual
        if (currentData.length > newValue.length) {
            props.updateStorageData(`individuals`, newValue, true);
            return true;
        }

        // Get the newly added individual..
        let addedIndividual = newValue[newValue.length - 1];

        // The added individual does not have a type set (is custom)
        // Get recommendations for this new one
        const recommendations = vitalRecommendations.individuals.find((c) => c.label === addedIndividual.label);

        // Is this individual referrence already set? If so we don't care either.
        const alreadyReferred = data.companyInfo.individualsReferring.find((c) => c.label === addedIndividual.label);

        // Check if it has type recommendations (the API will set it in background)
        const hasRecommendations = recommendations && recommendations.type ? true : false;

        // If is a custom one and we don't know what they are
        if (!hasRecommendations && !alreadyReferred) {
            document.dispatchEvent(new CustomEvent(`individualModal:start`, { detail: addedIndividual }));
            return true;
        }

        // If it got here it means they did had a type.
        props.updateStorageData(`individuals`, newValue, true);

        return true;
    };

    return (
        <React.Fragment>
            <div className="module-elements">
                <div className="form-group">
                    <div className="label">
                        <span className="text">What is the purpose of</span>
                        <span className="location">{props.storageData.label}</span>
                        <span className="text">?</span>
                        {props.storageData.processes.length < 1 && <div className="badge badge-data-warning blue" />}
                    </div>
                    <div className="input-container">
                        <Autocomplete
                            value={props.storageData.processes}
                            onChange={(value) => {
                                if (props.storageData.processes.length < value.length) {
                                    props.onProcessAdded(value[value.length - 1]);

                                    // Dispatch an event that tells us ta process has been added
                                    document.dispatchEvent(
                                        new CustomEvent(`reviewData:edit.processAdded`, { detail: value[value.length - 1].label }),
                                    );
                                } else {
                                    // Find out which process has been removed
                                    const processRemoved = props.storageData.processes.find(
                                        (process) => !value.some((p) => p.label === process.label),
                                    );

                                    // Dispatch an event that tells us ta process has been added
                                    if (processRemoved) {
                                        document.dispatchEvent(
                                            new CustomEvent(`reviewData:edit.processRemoved`, { detail: processRemoved.label }),
                                        );
                                    }
                                }
                                props.updateStorageData(`processes`, value, true);
                            }}
                            options={getAutocompleteOptions().processes}
                            type="processes"
                            groupCategories={true}
                            dataCy={`input-processes`}
                            placeholder="Select here to add a process"
                        />
                    </div>
                </div>
                {data.checkedData.elements.filter((i) => i.sensitive === true).length > 0 && (
                    <React.Fragment>
                        <div className="form-group">
                            <div className="label">
                                <span className="text">Do you store any of these sensitive data categories in</span>
                                <span className="location">{props.storageData.label}</span>
                                <span className="text">?</span>
                            </div>
                            <div className="input-container">
                                <Autocomplete
                                    value={props.storageData.elements.filter((elm) => elm.sensitive === true)}
                                    onChange={(value) => {
                                        const currentArr = props.storageData.elements.filter((elm) => elm.sensitive === false);
                                        props.updateStorageData(`elements`, [...currentArr, ...value], true);
                                    }}
                                    disableFreeSolo={true}
                                    options={getAutocompleteOptions().elements.filter((e) => e.sensitive === true)}
                                    type="elements"
                                    groupCategories={false}
                                    sensitiveElements={true}
                                    dataCy={`input-sensitive-elements`}
                                    placeholder="Select here to add a sensitive data category"
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <div className="form-group">
                    <div className="label">
                        <span className="text">Which personal data categories does</span>
                        <span className="location">{props.storageData.label}</span>
                        <span className="text">store for you?</span>
                        {props.storageData.elements.length < 1 && <div className="badge badge-data-warning blue" />}
                    </div>
                    <div className="input-container">
                        <Autocomplete
                            value={props.storageData.elements.filter((elm) => elm.sensitive === false)}
                            onChange={(value) => {
                                const currentArr = props.storageData.elements.filter((elm) => elm.sensitive === true);
                                props.updateStorageData(`elements`, [...currentArr, ...value], true);
                            }}
                            subjectsView={true}
                            options={getAutocompleteOptions().elements.filter((e) => e.sensitive === false)}
                            type="elements"
                            groupCategories={false}
                            sensitiveElements={false}
                            dataCy={`input-normal-elements`}
                            placeholder="Select here to add a personal data category"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="label">
                        <span className="text">Which individual categories does this</span>
                        <span className="location">{props.storageData.label}</span>
                        <span className="text">data belong to?</span>
                        {props.storageData.individuals.length < 1 && <div className="badge badge-data-warning blue" />}
                    </div>
                    <div className="input-container">
                        <Autocomplete
                            value={props.storageData.individuals}
                            onChange={(value) => onIndividualsChange(value)}
                            options={getAutocompleteOptions().individuals}
                            type="individuals"
                            groupCategories={true}
                            dataCy={`input-individuals`}
                            placeholder="Select here to add an  individual category"
                        />
                    </div>
                </div>
            </div>
            <ModalIndividual storageData={props.storageData} updateStorageData={props.updateStorageData} />
        </React.Fragment>
    );
};

export default Home;
