import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import React from 'react';
import 'react-tippy/dist/tippy.css';

const Theme = (props) => {
    // Variable required to match background right
    const isEmbeddedPortal = window.location.href.includes(`embedded-privacy-portal`) ? true : false;

    const theme = createTheme({
        typography: {
            fontFamily: ['Poppins', 'sans-serif'].join(','),
        },
        palette: {
            mode: `dark`,
            primary: {
                main: '#FCC21C',
            },
            secondary: {
                main: '#2A2861',
            },
            background: {
                // We need to make the background transparent for the embedded portal to avoid glitch loading.
                default: isEmbeddedPortal ? 'transparent' : '#1d1e44',
                paper: '#141436',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    );
};

export default Theme;
