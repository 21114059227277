import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ModuleState } from '../../../../..';
import { trackUserInteraction } from '../../../../../../../../utils/helpers';
import { formatDescription } from '../../../../identifyElements';
import { createIndividual } from '../../../../storageLocations/utils/helpers';

const Component = (props) => {
    const { data } = ModuleState();
    return (
        <React.Fragment>
            <Autocomplete
                multiple={true}
                data-cy={props.dataCy}
                freeSolo={props.disableFreeSolo ? false : true}
                selectOnFocus
                fullWidth={true}
                clearOnBlur
                handleHomeEndKeys
                disableClearable={true}
                value={props.value}
                options={props.options}
                disableCloseOnSelect={true}
                isOptionEqualToValue={(option, value) => {
                    return option.label === value.label;
                }}
                onChange={(_, value) => {
                    const updatedValues = value;
                    let latestAdded = value[value.length - 1];
                    if (typeof latestAdded === 'string') {
                        // fix to autocomplete when you press Enter without selecting.
                        latestAdded = { input: latestAdded, newlyCreated: true };
                    }
                    if (latestAdded && latestAdded.newlyCreated) {
                        if (props.type === 'processes' && !props.options.find((p) => p.label === latestAdded.input)) {
                            let alreadyExists = false;
                            data.storageLocations.forEach((storage) => {
                                if (alreadyExists === true) return true;
                                storage.processes.forEach((process) => {
                                    if (alreadyExists === true) return false;
                                    if (process.label === latestAdded.input) {
                                        alreadyExists = true;
                                    }
                                });
                            });
                            if (alreadyExists) {
                                window.showAlert(
                                    'Having Difficulties',
                                    `A process named "${latestAdded.input}" already exists. Please try a different name.`,
                                );
                                return false;
                            }
                        }

                        const mapLabels = {
                            processes: 'process',
                            individuals: 'individual',
                            elements: `${props.sensitiveElements ? `sensitive data element` : `data element`}`,
                        };

                        trackUserInteraction(`Created custom ${mapLabels[props.type]}`, { label: latestAdded.input });

                        updatedValues[value.length - 1] = { label: latestAdded.input };
                        if (props.type === 'processes') {
                            updatedValues[value.length - 1].category = '';
                        } else if (props.type === 'elements') {
                            updatedValues[value.length - 1].description = '';
                            updatedValues[value.length - 1].sensitive = props.sensitiveElements;
                        }

                        // New thing.. this crap must be rewritten one day
                        if (props.type === 'individuals') {
                            updatedValues[value.length - 1] = createIndividual({
                                label: latestAdded.input,
                            });
                        }
                    }
                    props.onChange(updatedValues);
                }}
                filterOptions={(options, params) => {
                    const { inputValue } = params;
                    const filteredInput = inputValue.toLowerCase().trim();
                    const filtered = options.filter((option) => {
                        const optionInput = option[`label`].toString().toLowerCase().trim();
                        const isMatch = optionInput.includes(filteredInput);
                        return isMatch ? true : false;
                    });

                    if (inputValue !== '' && filtered.length < 1 && !props.notCreatable && props.disableFreeSolo !== true) {
                        const newOption = { newlyCreated: true, label: `Add "${inputValue}"`, input: inputValue.replace(/\*/g, '') };
                        filtered.push(newOption);
                    }
                    return filtered;
                }}
                renderOption={(defaultProps, option) =>
                    props.subjectsView === true ? (
                        <div
                            {...defaultProps}
                            className={`autocomplete-review-data-option ${defaultProps['aria-selected'] && 'selected'}`}
                            data-cy="autocomplete-options"
                        >
                            <div className="label">{option[`${'label'}`]} </div>
                            {option.description && (
                                <div className="description">{option.description ? formatDescription(option.description) : ''}</div>
                            )}
                        </div>
                    ) : (
                        <div {...defaultProps} data-cy="autocomplete-options">
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <div style={{ flex: 2 }}>{option[`${'label'}`]} </div>
                                <div style={{ flex: 3, fontSize: 11 }}>{option.description || ''}</div>
                            </div>
                        </div>
                    )
                }
                getOptionLabel={(option) => `${option.label}`}
                groupBy={props.groupCategories ? (option) => option.category : undefined}
                renderInput={(params) => <TextField placeholder={props.value.length < 1 ? props.placeholder : ''} {...params} />}
            />
        </React.Fragment>
    );
};

export default Component;
