import { CircularProgress } from '@mui/material';
import React from 'react';

const Component = (props) => {
    if (props.loading === false) return props.children;
    return (
        <React.Fragment>
            <div className="loading-container">
                <div className="label">Loading...</div>
                <CircularProgress size={40} />
            </div>
        </React.Fragment>
    );
};

export default Component;
