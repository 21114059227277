import React from 'react';

// Context
import { OnboardingFunctions } from '../../../components/functions';
import { Button } from '@mui/material';

// Context
import { ViewContext } from '..';
import { logError } from '../../../../../../utils/helpers';

const Component = () => {
    const { navigateViews, updateCompanyData } = OnboardingFunctions();
    const { options } = ViewContext();

    const nextStep = async () => {
        try {
            // Update on back-end
            await updateCompanyData({ companyInsights: options });

            // Navigate
            navigateViews('forward');
        } catch (err) {
            await logError(`onboarding.audience.nextStep`, err);
        }
    };

    const previousStep = () => {
        navigateViews('backward');
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" data-cy="back-button" onClick={previousStep}>
                        Go back
                    </div>
                    <div className="title">Tell us more about your company</div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={options.type === null || options.size === null}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
