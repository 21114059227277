import { useEffect, useState } from 'react';

// Context
import { ModuleState } from '..';
import { removeFromUrlQueryString, getFromUrlQueryString, setInUrlQueryString, logError, checkUserFlag } from '../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../utils/endpoints';

const Component = () => {
    const { flags, options, setVitalRecommendations, setToolsRecommended, setFlags } = ModuleState();
    const { data, editingLocId, setCompanyData, setEditingLocId, setSessionData, setStorageLocationsOptions } = ModuleState();

    // Variables
    const [checkStorageExists, setCheckStorageExists] = useState(false);

    const fetchStorageLocationsOptions = async () => {
        try {
            const data = await makeEndpointRequest(`getStorageLocationsOptions`);
            setStorageLocationsOptions(data);
        } catch (err) {
            logError(`createTools.getOptions`, err);
            return [];
        }
    };

    const loadVitalRecommendations = async () => {
        try {
            const res = await makeEndpointRequest('GetVitalRecommendations');
            setVitalRecommendations(res);
        } catch (err) {
            await logError(`loadVitalRecommendations`, err);
        }
    };

    const loadRecommendedTools = async () => {
        try {
            const data = await makeEndpointRequest(`getStorageLocationsOptions`);
            setToolsRecommended(data);
        } catch (err) {
            await logError('loadRecommendedTools', err);
        }
    };

    const loadFlags = async () => {
        try {
            if (window.isCypressTestEnvironment) return true; // @Bugfix: This is affecting cypress tests when running in bulk.

            const missingSubProcessors = await checkUserFlag('missingSubProcessors', false);

            setFlags({
                ...flags,
                missingSubProcessors,
            });
        } catch (err) {
            await logError(`thirdParties.loadFlags`, err);
        }
    };

    const loadCompanyData = async () => {
        try {
            // Replace this with a await endpoint request.
            const res = await makeEndpointRequest(`GetCompanyData`);
            setCompanyData(res);
        } catch (err) {
            await logError(`thirdParties.loadCompanyData`, err);
        }
    };

    const loadSessionData = async () => {
        try {
            // Replace this with a await endpoint request.
            const res = await makeEndpointRequest(`GetGELSession`);
            if (res === null) throw new Error(`User has no company session.`);

            setSessionData(res);
        } catch (err) {
            await logError(`thirdParties.loadSessionData`, err);
        }
    };

    const checkStorageFromUrlExists = async () => {
        try {
            const id = getFromUrlQueryString('editingStorageLocationId');

            const exists = data.storageLocations.find((c) => c._storageLocationId === id);
            if (exists) return false;

            // It doesn't exist so remove this..
            removeFromUrlQueryString('editingStorageLocationId');
            setEditingLocId(null);
        } catch (err) {
            await logError(`thirdParties.checkStorageFromUrlExists`, err);
        }
    };

    useEffect(() => {
        // If we set it to true..
        if (options.showArchived) {
            setInUrlQueryString('showArchived', true);
        }

        // We turn it off but we have that in param.
        if (!options.showArchived && getFromUrlQueryString('showArchived')) {
            removeFromUrlQueryString('showArchived');
        }
    }, [options.showArchived]);

    useEffect(() => {
        // If we set it to true..
        if (editingLocId) {
            setInUrlQueryString('editingStorageLocationId', editingLocId);
        }

        // We turn it off but we have that in param.
        if (!editingLocId && getFromUrlQueryString('editingStorageLocationId')) {
            removeFromUrlQueryString('editingStorageLocationId');
        }
    }, [editingLocId]);

    useEffect(() => {
        if (!checkStorageExists && data && editingLocId) {
            checkStorageFromUrlExists();
            setCheckStorageExists(true);
        }
        // eslint-disable-next-line
    }, [data, editingLocId]);

    useEffect(() => {
        // Load flags
        loadFlags();

        // Load dependencies
        loadVitalRecommendations();

        // Load recommended tools
        loadRecommendedTools();

        // Load company data
        loadCompanyData();

        // Load session data
        loadSessionData();

        // Fetch storage options
        fetchStorageLocationsOptions();

        // eslint-disable-next-line
    }, []);

    return null;
};

export default Component;
