import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { ComponentState } from '../../..';
import { ComponentState as CB_ComponentState } from '../../../../cookieBanner/index';

// Components

import UseWebBeacon from './components/useWebBeacon';
import ManageCookieType from './components/ManageCookieType';

const Page = (props) => {
    const { data, updateData, saveChanges, saveDisabled } = props.source === 'cb' ? CB_ComponentState() : ComponentState();
    const [previouslyDeletedRecommendation, setPreviouslyDeletedRecommendation] = useState({});
    const [useCookies, setUseCookies] = useState(false);

    useEffect(() => {
        setUseCookies(
            data.privacyPortalSettings && data.privacyPortalSettings.features && data.privacyPortalSettings.features.cookiePolicy === true,
        );
    }, [data]);

    const cookieTypes = [
        {
            label: `Necessary`,
            type: `Necessary`,
        },
        {
            label: `Functionality`,
            type: `Functional`,
        },
        {
            label: `Analytical or performance`,
            type: `Analytical`,
        },
        {
            label: `Targeting`,
            type: `Targeting`,
        },
    ];

    const getNotUsedTools = () => {
        const locations = data.thirdParties.map((l) => {
            return {
                label: l.label,
                _storageLocationId: l._storageLocationId,
                _id: l._id,
            };
        });
        return locations.filter((loc) => {
            const hasCookies = data.thirdPartyCookies.entries.filter((c) => c.name === loc.label);
            return hasCookies.length > 0 ? false : true;
        });
    };

    const syncCookies = (type, newCookies) => {
        let strippedCookies = data.thirdPartyCookies.entries.filter((c) => c.type !== type);
        strippedCookies = [...strippedCookies, ...newCookies];
        updateData(`thirdPartyCookies.entries`, strippedCookies);
    };

    return (
        <React.Fragment>
            <UseWebBeacon componentSource={props.source} useCookies={useCookies} />

            {useCookies && (
                <React.Fragment>
                    <div className="form-heading">Third Party Cookies</div>{' '}
                    {cookieTypes.map((cookieType) => (
                        <ManageCookieType
                            componentSource={props.source}
                            key={cookieType.type}
                            type={cookieType.type}
                            label={cookieType.label}
                            tools={getNotUsedTools()}
                            data={data.thirdPartyCookies.entries.filter((c) => c.type === cookieType.type)}
                            syncCookies={syncCookies}
                            previouslyDeletedRecommendation={previouslyDeletedRecommendation}
                            setPreviouslyDeletedRecommendation={setPreviouslyDeletedRecommendation}
                        />
                    ))}
                </React.Fragment>
            )}

            <div className="page-footer">
                <Button variant="contained" color="primary" onClick={saveChanges} disabled={saveDisabled}>
                    Save changes
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Page;
