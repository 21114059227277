import React from 'react';

// Components
import Panel from './components/panel';
import Functions from './components/functions';

// Context
import { ModuleState } from '../../../..';

const Component = () => {
    const { data, setData, editingLocId, setEditingLocId, refreshDataInventory } = ModuleState();
    const { flags, toolsRecommended, vitalRecommendations, companyData } = ModuleState();

    return (
        <React.Fragment>
            <Functions
                // Data Inventory
                data={data}
                setData={setData}
                // Editing Storage Location ID
                editingLocId={editingLocId}
                setEditingLocId={setEditingLocId}
                // Functions
                refreshDataInventory={refreshDataInventory}
                // Recommendations
                vitalRecommendations={vitalRecommendations}
            >
                <Panel
                    // Data Inventory
                    data={data}
                    // Company
                    companyData={companyData}
                    // Editing Storage Location ID
                    editingLocId={editingLocId}
                    // Flags - @Reminder: Make sure the flags in Third Parties and Onboarding Flow are always the same.
                    flags={{
                        missingSubProcessors: flags.missingSubProcessors,
                    }}
                    // Recommendations
                    toolsRecommended={toolsRecommended}
                    vitalRecommendations={vitalRecommendations}
                />
            </Functions>
        </React.Fragment>
    );
};

export default Component;
