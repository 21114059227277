import React, { useState } from 'react';
import { Button } from '@mui/material';
import { trackUserInteraction } from '../../../../../utils/helpers';
import { Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@mui/material';
import { ModuleState } from '../..';

export const entries = [
    {
        label: `Fintech / Financial Services`,
        icon: `coins`,
    },
    {
        label: `Medtech / Healthcare`,
        icon: `briefcase-medical`,
    },
    {
        label: `eCommerce / Retail`,
        icon: `shop`,
    },
    {
        label: `Mobility / Logistics`,
        icon: `box`,
    },
    {
        label: `Sustainability`,
        icon: `leaf`,
    },
    {
        label: `InsurTech / LegalTech / RegTech`,
        icon: `car-crash`,
    },
    {
        label: `PropTech / Real Estate`,
        icon: `house`,
    },
    {
        label: `EdTech`,
        icon: `book-open`,
    },
    {
        label: `SaaS`,
        icon: `envelopes-bulk`,
    },
    {
        label: `Agency/Consultancy`,
        icon: `people-group`,
    },
    {
        label: `Marketing/Advertising`,
        icon: `megaphone`,
    },
];
// Reminder: Update HS "getHSInfoForUser"

const StepIndustry = () => {
    const { data, updateData, setStep } = ModuleState();

    const [modalActive, setModalActive] = useState(false);
    const [inputText, setInputText] = useState('');

    const nextStep = async () => {
        await trackUserInteraction(`Selected "Next"`, { destination: `CompanyInsights` });
        setStep('CompanyInsights');
    };

    const previousStep = async () => {
        setStep('Audience');
        await trackUserInteraction(`Selected "Go Back"`, { destination: `Audience` });
    };

    const warnAboutRemovingCustom = async (entry) => {
        window.showAlert(
            'Confirmation',
            `Are you sure about removing ${entry.label}? This will remove it from your industry options.`,
            'warning',
            [
                {
                    text: 'Cancel',
                    dataCy: `alert-cancel-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-submit-button`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Removed selected "Industry"`, { industry: entry.label });
                        let newArr = data.companyInfo.industry;
                        newArr.splice(newArr.indexOf(entry.label), 1);
                        updateData(`companyInfo.industry`, newArr);
                    },
                },
            ],
        );
    };

    const industrySelected = async (entry) => {
        let newArr = data.companyInfo.industry;
        if (newArr.includes(entry.label)) {
            if (!entries.find((item) => item.label === entry.label)) {
                warnAboutRemovingCustom(entry);
                return false;
            }
            await trackUserInteraction(`Removed selected "Industry"`, { industry: entry.label });
            newArr.splice(newArr.indexOf(entry.label), 1);
        } else {
            newArr.push(entry.label);
            await trackUserInteraction(`Selected "Industry"`, { industry: entry.label });
        }
        updateData(`companyInfo.industry`, newArr);
    };

    const createCustom = async () => {
        await trackUserInteraction(`Selected "Create Another"`);
        setModalActive(true);
        setInputText('');
    };

    const saveNewIndustry = async () => {
        await trackUserInteraction(`Created custom industry`, {
            label: inputText,
        });
        let newArr = data.companyInfo.industry;

        newArr.push(inputText);

        updateData(`companyInfo.industry`, newArr);
        setModalActive(false);
    };

    const dismissModal = async () => {
        await trackUserInteraction(`Selected "Dismiss"`);
        setModalActive(false);
    };

    const getEntries = () => {
        const arr = [...entries];
        let currentUserArr = data.companyInfo.industry;
        currentUserArr.forEach((item) => {
            if (!arr.find((x) => x.label === item)) {
                arr.push({
                    label: item,
                    icon: `rectangle-history-circle-plus`,
                });
            }
        });
        return arr;
    };

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" onClick={previousStep} data-cy="back-button">
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Choose your industry
                    </div>
                </div>
                <div className="right-side">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={data.companyInfo.industry.length < 1}
                        onClick={nextStep}
                        data-cy="next-button"
                    >
                        Next
                    </Button>
                </div>
            </div>
            <div className="industry module-content">
                <div className="entries d-flex-row-columns">
                    {getEntries().map((entry, index) => (
                        <div
                            onClick={() => industrySelected(entry)}
                            key={index}
                            className={data.companyInfo.industry.includes(entry.label) ? `entry selected` : `entry`}
                            data-cy={`entry-${index}`}
                        >
                            <div className="content">
                                <i className={`icon fa-solid fa-${entry.icon}`}></i>
                                <div className="label" data-cy={`entry-label`}>
                                    {entry.label}
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className={`entry last`} data-cy={`entry-create-another`}>
                        <div className="content" onClick={createCustom}>
                            <i className={`icon fa-solid fa-plus`}></i>

                            <div className="label" data-cy="entry-other-label">
                                Add custom industry
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modalActive && (
                <Dialog open={modalActive} maxWidth="xs" fullWidth>
                    <DialogTitle data-cy="dialog-title">What is this new industry?</DialogTitle>
                    <DialogContent style={{ width: '100%' }}>
                        <Input
                            placeholder="Industry name"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            fullWidth
                            data-cy="dialog-input"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={dismissModal} variant="outlined" data-cy="dialog-dismiss-button">
                            Dismiss
                        </Button>
                        <Button
                            onClick={saveNewIndustry}
                            variant="contained"
                            color="primary"
                            disabled={inputText.trim().length < 1}
                            data-cy="dialog-save-button"
                        >
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default StepIndustry;
