import React, { useState } from 'react';
import { Button, Select, MenuItem } from '@mui/material';
import { ModuleState } from '../..';
import { trackUserInteraction } from '../../../../../utils/helpers';
import lodash from 'lodash';

import Tooltip from '../../../../components/tooltip';

import { personalLegalBasis, sensitiveLegalBasis, legalResponsibilityRoles } from './utils/dataMaps';

const StepLegalResponsibility = () => {
    const { setStep, data, setData, vitalRecommendations } = ModuleState();
    const [processesWithoutRecommendations, setProcessesWithoutRecommendations] = useState([]);
    const [tooltipEnabled, setTooltipEnabled] = useState(false);
    const [amplitudeTooltipTracked, setAmplitudeTooltipTracked] = useState(false);

    const goBack = async () => {
        setStep(`ReviewData`);
        await trackUserInteraction(`Selected "Go Back"`, { destination: `ReviewData` });
    };

    const nextStep = async () => {
        setStep('Complete');
        await trackUserInteraction(`Selected "Go Next"`, { destination: `Complete` });
    };

    const getData = (processName) => {
        const match = data.processesDependencies.find((p) => p.processName === processName);
        const result = match ? match : {};
        return result;
    };

    const isStoringSensitiveElements = (processName) => {
        let storingSensitive = false;
        data.storageLocations.forEach((location) => {
            if (location.archived === true) return;
            if (location.validated === false) return;
            location.matrixMap.forEach((map) => {
                const element = location.elements.find((elm) => elm.label === map.element);
                if (element && element.sensitive === true && map.process === processName) {
                    storingSensitive = true;
                }
            });
        });
        return storingSensitive;
    };

    const updateData = (processName, path, newValue) => {
        if (processesWithoutRecommendations.length > 0) {
            // When a process is updated, it means the user has done something to it, so we remove the blue dot
            setProcessesWithoutRecommendations((currentArr) => currentArr.filter((p) => p !== processName));
        }

        setData((currentState) => {
            let newState = { ...currentState };
            let foundMatch = false;
            newState.processesDependencies.forEach((p, index) => {
                if (p.processName === processName) {
                    lodash.set(newState, `processesDependencies[${index}].${path}`, newValue);
                    foundMatch = true;
                }
            });
            if (foundMatch === false) {
                const newEntry = {
                    processName,
                    legalResponsibility: null,
                    personalLegalBasis: null,
                    sensitiveLegalBasis: null,
                };
                lodash.set(newEntry, `${path}`, newValue);
                newState.processesDependencies.push(newEntry);
            }
            return newState;
        });
    };

    const getUniqueProcesses = () => {
        const uniqueProcesses = [];
        data.storageLocations.forEach((storage) => {
            if (storage.archived === true) return;

            // Removed. Manuel wants to show even processes from Incomplete tools.
            // So they can get recommendations if there's any, so we don't lose them.
            // if (storage.validated === false) return;

            storage.processes.forEach((process) => {
                if (uniqueProcesses.includes(process.label)) return;
                uniqueProcesses.push(process.label);
            });
        });
        return uniqueProcesses;
    };

    const isProcessValid = (processName) => {
        const data = getData(processName);

        if (Object.keys(data).length < 1) {
            // the user hasn't done anything to the process
            return false;
        }
        if (data.legalResponsibility === null) {
            // the user hasn't selected legal responsability
            return false;
        }
        if (data.personalLegalBasis === null) {
            // the user hasn't selected personal legal basis
            if (!['Processor'].includes(data.legalResponsibility)) {
                return false;
            }
        }
        if (data.sensitiveLegalBasis === null && isStoringSensitiveElements(processName)) {
            // the user hasn't selected sensitive legal basis and they should
            if (data.legalResponsibility !== 'Processor') {
                return false;
            }
        }

        return true;
    };

    const isNextDisabled = () => {
        let disable = false;
        getUniqueProcesses().forEach((processName) => {
            if (disable === true) return false; // skip to save memory
            if (isProcessValid(processName) === false) {
                disable = true;
            }
        });
        return disable;
    };

    const autofillLegal = async () => {
        let processesWithout = [];

        getUniqueProcesses().forEach((processName) => {
            // Get process data
            const processData = getData(processName);
            let currentLegalRepo = processData.legalResponsibility;

            // Get recommendation
            const recommendation = vitalRecommendations.processes.find((p) => p.label === processName);
            if (!recommendation) {
                if (!isProcessValid(processName)) {
                    processesWithout.push(processName);
                }
                return false;
            }

            // Update legal resp only if empty
            if (recommendation.legalResponsibility && !processData.legalResponsibility) {
                updateData(processName, `legalResponsibility`, recommendation.legalResponsibility);
                currentLegalRepo = recommendation.legalResponsibility;
            }

            // Format the new legal basis..
            const article6 = recommendation.article6.split('~');
            const newLegalBasis = {
                string: article6[0] ? article6[0].trim() : '',
                gdpr: article6[1] ? article6[1].trim() : '',
            };

            // Update it only if empty
            if (
                personalLegalBasis.find((l) => l.string === newLegalBasis.string) &&
                !processData.personalLegalBasis &&
                currentLegalRepo !== 'Processor'
            ) {
                updateData(processName, `personalLegalBasis`, newLegalBasis);
            }

            // Update onl if empty and storing sensitive data.
            if (isStoringSensitiveElements(processName) && !processData.sensitiveLegalBasis) {
                // Format article 9
                const article9 = recommendation.article9.split('~');
                const newSensitiveBasis = article9[1]
                    ? {
                          string: article9[0] ? article9[0].trim() : '',
                          gdpr: article9[1] ? article9[1].trim() : '',
                      }
                    : '';

                if (newSensitiveBasis.string && sensitiveLegalBasis.find((l) => l.string === newSensitiveBasis.string)) {
                    updateData(processName, `sensitiveLegalBasis`, newSensitiveBasis);
                }
            }
        });

        if (processesWithout.length > 0) {
            await trackUserInteraction(`Legal Autofill failed for certain processes`, {
                failedProcesses: processesWithout,
            });

            window.showAlert(
                'Warning',
                <React.Fragment>
                    <p>The following processes couldn't get recommendations from our AI Legal Framework.</p>
                    <ul style={{ textAlign: 'left' }}>
                        {processesWithout.map((ent, ix) => (
                            <li key={ix}>{ent}</li>
                        ))}
                    </ul>
                    <p>
                        You will need to add this information before going forward. If you are not sure about what legal basis to choose
                        feel free to reach out through Live Chat and someone from our team will be happy to help.
                    </p>
                </React.Fragment>,
                'warning',
                [
                    {
                        text: 'Understood',
                        dataCy: `alert-button-confirm`,
                        onClick: async ({ dismissAlert }) => {
                            dismissAlert();
                            await trackUserInteraction(`Selected "Understood"`);
                        },
                    },
                ],
            );
        }

        setProcessesWithoutRecommendations(processesWithout);
    };

    const onAutofill = async () => {
        await trackUserInteraction(`Selected "Autofill" `);
        window.showAlert(
            'Disclaimer',
            `This advice is general in nature and does not take into account your specific objectives. You should consider whether
            this advice is suitable to you and your circumstances. Where relevant you should consider seeking professional legal
            advice.`,
            'warning',
            [
                {
                    text: 'Dismiss',
                    dataCy: `alert-button-dismiss`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        await trackUserInteraction(`Selected "Dismiss"`);
                    },
                },
                {
                    text: 'Confirm',
                    dataCy: `alert-button-confirm`,
                    onClick: async ({ dismissAlert }) => {
                        dismissAlert();
                        autofillLegal();
                        await trackUserInteraction(`Selected "Confirm"`);
                    },
                },
            ],
        );
    };

    // @Bugfix: In the past we checked only "checkedData" but old legacy users will not have checkedData.
    const hasSensitiveElements =
        data.storageLocations.filter((c) => c.elements.find((e) => e.sensitive === true)).length > 0 ? true : false;

    return (
        <React.Fragment>
            <div className="module-subheader">
                <div className="left-side">
                    <div className="back-button" onClick={goBack} data-cy="back-button">
                        Go back
                    </div>
                    <div className="title" data-cy="step-title">
                        Legal Responsibility and Justification
                    </div>
                </div>
                <div className="right-side">
                    <Button variant="contained" color="primary" disabled={isNextDisabled()} onClick={nextStep} data-cy="next-button">
                        Next
                    </Button>
                </div>
            </div>
            <Button variant="contained" color="primary" onClick={onAutofill} style={{ marginBottom: 14 }} data-cy="autofill">
                Autofill
            </Button>
            <div className="legal-responsability module-content">
                <div className="processes">
                    <div className="entry field-hints desktop">
                        <div className="label">Process Name</div>
                        <div className="roles">
                            Legal responsibility{' '}
                            <div className="tooltip-container-legal">
                                <div
                                    className="action-area"
                                    onMouseEnter={() => {
                                        setTooltipEnabled(true);

                                        if (amplitudeTooltipTracked === false) {
                                            trackUserInteraction(`Tooltip Hovered`, { id: `gdpr-essentials:legal-responsibility` });
                                            setAmplitudeTooltipTracked(true);
                                        }
                                    }}
                                />
                                <i className="tooltip-icon fa-solid fa-circle-info"></i>
                            </div>
                        </div>
                        <div className="legal-options">
                            <div className="gdpr-legal" style={hasSensitiveElements ? {} : { width: '100%' }}>
                                Personal data legal justification
                                <Tooltip
                                    trackInteraction={true}
                                    interactionId={`gdpr-essentials:personal-data-legal-justification`}
                                    content="To process personal data you must select a valid justification from the list below"
                                >
                                    <i className="tooltip-icon fa-solid fa-circle-info"></i>
                                </Tooltip>
                            </div>
                            {hasSensitiveElements && (
                                <React.Fragment>
                                    <div className="gdpr-legal">
                                        Sensitive data legal justification
                                        <Tooltip
                                            trackInteraction={true}
                                            interactionId={`gdpr-essentials:sensitive-data-legal-justification`}
                                            content="To process sensitive data you must select a valid justification from the list below"
                                        >
                                            <i className="tooltip-icon fa-solid fa-circle-info"></i>
                                        </Tooltip>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    {getUniqueProcesses().map((processName, index) => (
                        <div className="entry" key={index}>
                            <div className="label">
                                {!isProcessValid(processName) && <div className="badge badge-data-warning blue" />}
                                <span className="text">{processName}</span>
                            </div>
                            <div className="roles">
                                <div className="sub-label">Legal responsibility</div>

                                {legalResponsibilityRoles.map((role, xx) => (
                                    <div
                                        key={xx}
                                        onClick={() => {
                                            updateData(processName, `legalResponsibility`, role);
                                            if (role === 'Processor') {
                                                updateData(processName, `personalLegalBasis`, null);
                                                updateData(processName, `sensitiveLegalBasis`, null);
                                            }
                                        }}
                                        data-cy={`process-${index}-legalResponsibility-${xx}`}
                                        className={`role-entry ${getData(processName).legalResponsibility === role && `active`}`}
                                    >
                                        {role}
                                    </div>
                                ))}
                            </div>
                            <div className="legal-options">
                                <div className="personal-legal gdpr-legal" style={hasSensitiveElements ? {} : { width: '100%' }}>
                                    <div className="sub-label">Personal legal basis</div>
                                    <Select
                                        fullWidth={true}
                                        disabled={
                                            ['Processor'].includes(getData(processName).legalResponsibility) ||
                                            !getData(processName).legalResponsibility
                                        }
                                        data-cy={`process-${index}-personalLegalBasis`}
                                        value={
                                            getData(processName).personalLegalBasis
                                                ? getData(processName).personalLegalBasis.string
                                                : 'no-selection'
                                        }
                                        onChange={(e) =>
                                            updateData(
                                                processName,
                                                `personalLegalBasis`,
                                                personalLegalBasis.find((p) => p.string === e.target.value),
                                            )
                                        }
                                    >
                                        <MenuItem value="no-selection" disabled>
                                            {['Processor'].includes(getData(processName).legalResponsibility)
                                                ? `No need for selection`
                                                : `Select Option`}
                                        </MenuItem>

                                        {personalLegalBasis.map((entry, ix) => (
                                            <MenuItem value={entry.string} key={ix} data-cy={`process-${index}-personalLegalBasis-${ix}`}>
                                                {entry.string}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                {hasSensitiveElements && (
                                    <React.Fragment>
                                        <div className="sensitive-legal gdpr-legal">
                                            <div className="sub-label">Sensitive legal basis</div>
                                            <Select
                                                fullWidth={true}
                                                disabled={
                                                    !getData(processName).legalResponsibility ||
                                                    !isStoringSensitiveElements(processName) ||
                                                    getData(processName).legalResponsibility === 'Processor'
                                                }
                                                value={
                                                    getData(processName).sensitiveLegalBasis
                                                        ? getData(processName).sensitiveLegalBasis.string
                                                        : 'no-selection'
                                                }
                                                data-cy={`process-${index}-sensitiveLegalBasis`}
                                                onChange={(e) =>
                                                    updateData(
                                                        processName,
                                                        `sensitiveLegalBasis`,
                                                        sensitiveLegalBasis.find((p) => p.string === e.target.value),
                                                    )
                                                }
                                            >
                                                <MenuItem value="no-selection" disabled>
                                                    {!isStoringSensitiveElements(processName) ||
                                                    getData(processName).legalResponsibility === 'Processor'
                                                        ? `No need for selection`
                                                        : `Select Option`}
                                                </MenuItem>
                                                {sensitiveLegalBasis.map((entry, ix) => (
                                                    <MenuItem
                                                        value={entry.string}
                                                        key={ix}
                                                        data-cy={`process-${index}-sensitiveLegalBasis-${ix}`}
                                                    >
                                                        {entry.string}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                {tooltipEnabled && (
                    <React.Fragment>
                        <div className={`module-popout`}>
                            <div className="module-content">
                                <div className="module-title">Legal responsibilities</div>
                                <div className="module-close" onClick={() => setTooltipEnabled(false)} data-cy="panel-close">
                                    <i className="icon fa-solid fa-xmark"></i>
                                </div>

                                <div className="module-elements" style={{ marginTop: 36, width: '100%' }}>
                                    <div className="legal-role">Example 1:</div>
                                    <p>
                                        If you use Google Drive to store information - you are the owner of that information, meaning you
                                        decide when to update it, delete it, move it or use it. In this scenario you are the Controller and
                                        Google Drive is the processor as they are processing information based on your instructions.{' '}
                                    </p>
                                    <div className="legal-role">Example 2:</div>
                                    <p>
                                        If you are a service provider and have a web app or mobile app and people use your app to offer
                                        services (for example if you are a CRM like Hubspot) then when offering your services you are a
                                        processor.
                                    </p>

                                    <div className="legal-role">You are a controller if...</div>
                                    <ul>
                                        <li>
                                            You decided what the purpose or outcome of the processing was to be, what personal data should
                                            be collected and which individuals to collect personal data about.
                                        </li>
                                        <li>The data you are processing is about your employees.</li>
                                        <li>You have a direct relationship with the individuals who are the owners of the data.</li>
                                        <li>
                                            You exercise professional judgement in the processing of personal data (e.g. Lawyers and
                                            Doctors).
                                        </li>
                                    </ul>

                                    <div className="legal-role">You are a processor if...</div>
                                    <ul>
                                        <li>You do not decide what purpose or purposes the data will be used for.</li>
                                        <li>You are following instructions from someone else regarding the processing of personal data.</li>
                                        <li>
                                            You were given the personal data by a customer or similar third party, or told what data to
                                            collect.
                                        </li>
                                        <li>
                                            You do not decide to collect personal data from individuals, do not decide what personal data
                                            should be collected from individuals, do not decide whether to disclose the data, or to whom or
                                            do not decide how long to retain the data.
                                        </li>
                                    </ul>

                                    <div className="legal-role">You are a joint-controller if (this is rare)...</div>
                                    <ul>
                                        <li>You have a common objective with others regarding the processing.</li>
                                        <li>You are processing the personal data for the same purpose as another controller.</li>
                                        <li>
                                            You are using the same set of personal data (eg one database) for this processing as another
                                            controller.
                                        </li>
                                        <li>
                                            You have designed this process with another controller or you have common information management
                                            rules with another controller.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="module-shadow-underlay" />
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default StepLegalResponsibility;
