const endpoints = {
    GetWorkspaces: {
        link: '/graphql',
        method: 'POST',
        query: `query GetWorkspaces
          {
            GetWorkspaces
          }
        `,
        parserFunction: ({ data }) => data.GetWorkspaces,
    },
    GetWorkspaceDetailsV2: {
        link: '/graphql',
        method: 'POST',
        query: `query GetWorkspaceDetails($_id: String)
          {
            GetWorkspaceDetails(_id: $_id)
          }
        `,
        parserFunction: ({ data }) => data.GetWorkspaceDetails,
    },
    CreateWorkspaceV2: {
        link: `/graphql`,
        method: `POST`,
        query: `
          mutation CreateWorkspaceV2($name: String) {
            CreateWorkspaceV2(name: $name)
          }
      `,
        parserFunction: ({ data }) => data.CreateWorkspaceV2,
    },
    DeleteWorkspaceV2: {
        link: `/graphql`,
        method: `POST`,
        query: `
            mutation DeleteWorkspaceV2($_id: String) {
              DeleteWorkspaceV2(_id: $_id)
            }
        `,
        parserFunction: ({ data }) => data.DeleteWorkspaceV2,
    },
    SwitchWorkspaceV2: {
        link: `/graphql`,
        method: `POST`,
        query: `
          mutation SwitchWorkspaceV2($_companyId: String, $_workspaceId: String) {
            SwitchWorkspaceV2(_companyId: $_companyId, _workspaceId: $_workspaceId)
          }
      `,
        parserFunction: ({ data }) => data.SwitchWorkspaceV2,
    },
    DeleteWorkspaceCompanyV2: {
        link: `/graphql`,
        method: `POST`,
        query: `
      mutation DeleteWorkspaceCompanyV2($_companyId: String, $_workspaceId: String) {
        DeleteWorkspaceCompanyV2(_companyId: $_companyId, _workspaceId: $_workspaceId)
      }
  `,
        parserFunction: ({ data }) => data.DeleteWorkspaceCompanyV2,
    },
    CreateWorkspaceCompanyV2: {
        link: `/graphql`,
        method: `POST`,
        query: `
          mutation CreateWorkspaceCompanyV2($companyLegalName: String, $_workspaceId: String) {
            CreateWorkspaceCompanyV2(companyLegalName: $companyLegalName, _workspaceId: $_workspaceId)
          }
      `,
        parserFunction: ({ data }) => data.DeleteWorkspaceCompanyV2,
    },
    InviteToWorkspaceV2: {
        link: `/graphql`,
        method: `POST`,
        query: `
      mutation InviteToWorkspaceV2($email: String, $role: String, $_workspaceId: String) {
        InviteToWorkspaceV2(email: $email, role: $role, _workspaceId: $_workspaceId)
      }
  `,
        parserFunction: ({ data }) => data.InviteToWorkspaceV2,
    },
    UpdateUserWorkspace: {
        link: `/graphql`,
        method: `POST`,
        query: `
  mutation UpdateUserWorkspace($_userId: String, $payload: JSON, $_workspaceId: String) {
    UpdateUserWorkspace(_userId: $_userId, payload: $payload, _workspaceId: $_workspaceId)
  }
`,
        parserFunction: ({ data }) => data.UpdateUserWorkspace,
    },
    RemoveUserWorkspace: {
        link: `/graphql`,
        method: `POST`,
        query: `
        mutation RemoveUserWorkspace($_userId: String,  $_workspaceId: String) {
          RemoveUserWorkspace(_userId: $_userId,  _workspaceId: $_workspaceId)
        }
        `,
        parserFunction: ({ data }) => data.UpdateUserWorkspace,
    },
};

export default endpoints;
