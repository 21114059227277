import React from 'react';

const Component = () => {
    return (
        <React.Fragment>
            <div className="scanning-alert">
                <div className="icon-container">
                    <i className="elm fa-solid fa-magnifying-glass fa-beat"></i>
                </div>
                <span>
                    {' '}
                    We're scanning your website to find tools and third parties. In the meantime you can add the ones you know manually.
                </span>
            </div>
        </React.Fragment>
    );
};

export default Component;
