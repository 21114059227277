import React from 'react';
import { isValueNotRequired } from '../../utils/functions';
import { filtersRequiringOptions, filtersLabels } from '../../utils/definitions';
import { EntryState } from '../entry';

import { MenuItem, Select } from '@mui/material';

const Component = () => {
    const { data, setData, getOptions } = EntryState();

    const getAvailableFilters = () => {
        let arr = Object.keys(filtersLabels);

        // If the filter is one that required options we must make sure they have options attached.
        if (!getOptions().length > 0) {
            arr = arr.filter((e) => !filtersRequiringOptions.includes(e));
        }

        return arr;
    };

    const changeFilter = (newValue) => {
        setData('filter', newValue);

        // Since is no longer required we will delete it.
        if (isValueNotRequired(newValue)) {
            setData('value', '');
        }
    };

    return (
        <React.Fragment>
            <div className="field filter">
                <Select fullWidth={true} value={data.filter} onChange={(ev) => changeFilter(ev.target.value)}>
                    {getAvailableFilters().map((fieldKey, ix) => (
                        <MenuItem key={ix} value={fieldKey}>
                            {filtersLabels[fieldKey]}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </React.Fragment>
    );
};
export default Component;
