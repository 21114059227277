import React, { useEffect, useState } from 'react';

// Context
import { ModuleState } from '../../..';

// Components
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Autocomplete, TextField } from '@mui/material';

// Dependencies
import { logError, trackUserInteraction, useStateRef } from '../../../../../../utils/helpers';
import { makeEndpointRequest } from '../../../../../../utils/endpoints';
import { AuthStore } from '../../../../../../utils/context/authStore';
import { ModuleAPI } from '../../../components/api';

const Component = () => {
    const { dataProcessingAgreementsRef } = ModuleState();
    const { loadDataProcessingAgreements } = ModuleAPI();
    const [componentData, setComponentData] = useState({ label: null, processes: [], dataProcessingAgreements: [] });
    const { globalFlagsRef } = AuthStore();

    // eslint-disable-next-line
    const [isRedirected, setIsRedirected, isRedirectedRef] = useStateRef(false);

    const reviewProcesses = async ({ detail }) => {
        // IF we're redirected by dpa we don't need to have that..
        if (isRedirectedRef.current) return false;

        // they don't have this flag..
        if (!globalFlagsRef.current.addProcessesToDPA) return false;

        const { label, processes } = detail; // Extract..

        // Is he editing?
        const isEditing = window.location.href.includes(`/edit`);
        if (!isEditing) return false;

        // They don't have DPAs..
        if (dataProcessingAgreementsRef.current.length < 1) return false;

        // Is this used?
        const dpas = getDPAsWithThisStorageLocation(label, processes);
        if (dpas.length < 1) return false; // we don't use this storage location on a draft dpa.

        // Set these reviewing processes
        setComponentData({
            label,
            processes: processes.map((c) => ({ label: c, checked: true })),
            dataProcessingAgreements: dpas.map((d) => ({ _id: d._id, name: d.name, checked: true })),
        });

        // Track user interaction..
        await trackUserInteraction(`DPA - Review processes`, { label, processes });
    };

    /**
     *
     * @param {} label Label of the storage location
     * @returns Array of drat dpas that have this storage location.
     */

    const getDPAsWithThisStorageLocation = (label, processes) => {
        const arr = dataProcessingAgreementsRef.current.filter((c) => {
            // Is already published. we don't aim those.
            if (c.data.published) return false;

            // this storage location label is used in a draft dpa.
            const matchingTool = c.data.draft.subProcessors.find((l) => l.label === label);

            // If they have a matching tool..
            if (matchingTool) {
                const processesMissing = processes.filter((c) => !matchingTool.processes.includes(c));
                if (processesMissing.length < 1) return false; // They have all the processes.
            }

            return matchingTool ? true : false;
        });

        return arr;
    };

    useEffect(() => {
        document.addEventListener(`reviewData:done.processAdded`, reviewProcesses);

        checkRedirected();

        return () => {
            document.removeEventListener(`reviewData:done.processAdded`, reviewProcesses);
        };

        // eslint-disable-next-line
    }, []);

    const onDismiss = async () => {
        await trackUserInteraction(`Selected "Dismiss"`);
        setComponentData({ processes: [], label: null });
    };

    const checkRedirected = async () => {
        const checked = await localStorage.getItem('@redirectedByDPA');

        if (checked) {
            setIsRedirected(true);
            await localStorage.removeItem('@redirectedByDPA');
        }
    };

    const onSubmit = async () => {
        try {
            // Call apis etc.
            const toAddProcesses = componentData.processes.filter((c) => c.checked === true);
            const forDPAs = componentData.dataProcessingAgreements; // we just add it to all dpas no longer filtering it as per Alex instructions

            // Call api
            await makeEndpointRequest('AddProcessToDPA', {
                _ids: forDPAs.map((c) => c._id),
                locationLabel: componentData.label,
                processes: toAddProcesses.map((c) => c.label),
            });

            // Hide away
            setComponentData({ processes: [], label: null });

            // Reload processing agreements now.
            await loadDataProcessingAgreements();
        } catch (err) {
            // Log error
            await logError(`SUBMIT_PROCESSES_REVIEWED_TO_DPA`, err, { ...componentData });

            // Hide away
            setComponentData({ processes: [], label: null });
        }
    };

    /**
     * This simply checks if the form is valid to submit.
     * @returns true or false
     */

    const isFormValid = () => {
        const validDPA = componentData.dataProcessingAgreements.filter((c) => c.checked === true);
        const validProcesses = componentData.processes.filter((c) => c.checked === true);

        return validDPA.length > 0 && validProcesses.length > 0 ? true : false;
    };

    if (componentData.label === null) return null;

    return (
        <React.Fragment>
            <Dialog className={`dpa-new-processes-modal`} open={true} maxWidth={'md'} fullWidth={true}>
                <DialogTitle data-cy="dialog-title">Review processes for {componentData.label}</DialogTitle>
                <DialogContent style={{ width: '100%' }}>
                    <div className="message">
                        <p>
                            <b>{componentData.label}</b> is marked as a sub-processor. Which means that you make use of their services to
                            build/offer your own.
                        </p>
                        <p>
                            You may use {componentData.label} for different things, can you tell us for which purposes you use{' '}
                            {componentData.label} for when offering services only?"
                        </p>
                    </div>
                    <div className="form-group" style={{ marginBottom: 18 }}>
                        <div className="label">I am using the following processes when offering my services</div>

                        <div className="input">
                            <Autocomplete
                                value={componentData.processes.filter((c) => c.checked === true).map((c) => c.label)}
                                fullWidth={true}
                                className="country-processes"
                                disableClearable={true}
                                options={componentData.processes.map((c) => c.label)}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth={true}
                                        variant="outlined"
                                        {...params}
                                        placeholder={
                                            componentData.processes.filter((c) => c.checked === true).length < 1
                                                ? 'No processes'
                                                : undefined
                                        }
                                    />
                                )}
                                freeSolo={false}
                                multiple={true}
                                onChange={(_, checkedLabels) => {
                                    // Re-apply checks..
                                    const newProcesses = componentData.processes.map((c) => ({
                                        label: c.label,
                                        checked: checkedLabels.includes(c.label) ? true : false,
                                    }));

                                    setComponentData({ ...componentData, processes: newProcesses });
                                }}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onDismiss} data-cy="dialog-dismiss-button">
                        Dismiss
                    </Button>
                    <Button
                        disabled={!isFormValid()}
                        variant="contained"
                        color="primary"
                        onClick={() => onSubmit()}
                        data-cy="dialog-submit-button"
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default Component;
