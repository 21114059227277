import React from 'react';

// Components
import { Entry } from './type';

// Context
import { ViewContext } from '..';
import { PanelContext } from '../../..';

const Component = () => {
    const { setType } = ViewContext();
    const { data } = PanelContext();

    const getEntries = () => {
        const entries = [
            {
                id: 'manual:specificTime',
                name: 'Set a specific time period',
                explanation: 'Set a specific number of days/months/years to keep data for',
            },
            {
                id: 'manual:customText',
                name: 'Write a custom retention period',
                explanation: 'Make a custom retention period in free-text',
            },
        ];

        if (data.role === 'Processor') {
            entries.push({
                id: 'manual:asRequiredByController',
                name: 'As required by the Data Controller',
                explanation: `We'll keep the data as long as needed for the purposes set by the data controller.`,
            });
        }

        return entries;
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label" style={{ marginBottom: 24 }}>
                    <div className="text">Alternatively</div>
                </div>
                <div className="component-content">
                    <div className="retention-periods">
                        {getEntries().map((c, ix) => (
                            <Entry
                                key={ix}
                                id={c.id}
                                data={c}
                                isChecked={data.retention.type === c.id}
                                onClick={() => setType(c.id)} // This will set the type to be manual:{string}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
