import React, { createContext, useContext, useEffect, useState } from 'react';

// Components
import Header from './components/header';
import Textarea from './components/textarea';
import Footer from './components/footer';

// Context
import { OnboardingContext } from '../..';

// Context
const Context = createContext({});
export const ViewContext = () => useContext(Context);

const Component = () => {
    const { companyData } = OnboardingContext();
    const [companyDescription, setCompanyDescription] = useState('');

    // When the API is finished loading
    useEffect(() => {
        setCompanyDescription(companyData.companyDescription);
    }, [companyData.companyDescription]);

    const PassedProps = {
        companyDescription,
        setCompanyDescription,
    };

    return (
        <Context.Provider value={PassedProps}>
            <Header />
            <div className="describe-company module-content">
                <div className="textfield">
                    <Textarea />
                    <Footer />
                </div>
            </div>
        </Context.Provider>
    );
};

export default Component;
