import moment from 'moment';
import React from 'react';
import { AuditsContext } from '..';
import { trackUserInteraction } from '../../../../utils/helpers';

export const getStatusColor = val => {
    if (val === 'Compliant') return `green`;
    else if (val === 'Partially Compliant') return `yellow`;
    else return `red`;
};
const Dashboard = () => {
    const { data, setAuditId, setView } = AuditsContext();

    const clickedCard = async entry => {
        trackUserInteraction(`Selected Audit`, { entryId: entry.id });
        setAuditId(entry.id);
        setView('Entry');
        window.history.pushState('_', '_', `/audits/${entry.id}`);
    };

    return (
        <div className="dashboard">
            <div className="left-side">
                <div className="heading-area">
                    <div
                        className="logo"
                        style={{
                            backgroundImage: `url("${data.logoURL}")`,
                        }}
                    />
                    <div className="texts">
                        <div className="name-line">
                            <div className="text">{data.companyName}</div>
                            <div className="badge">Audited</div>
                        </div>
                        <div className="website" onClick={() => window.open(data.websiteURL, `_blank`)}>
                            <i className="elm fa-solid fa-globe"></i> Website
                        </div>
                    </div>
                </div>
                <div className="audit-items">
                    {data.auditItems.map((entry, ix) => (
                        <div onClick={() => clickedCard(entry)} className="entry" key={ix}>
                            <div className="top-header">
                                <div className="title">{entry.title}</div>
                                <div className={`status ${getStatusColor(entry.status)}`}>{entry.status}</div>
                            </div>
                            <div className="description">{entry.description}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="right-side">
                <div className="boxed-content">
                    <div className="top-part">
                        <div className="scoreboard">
                            <div className="text">Privacy Score</div>
                            <div className="value">{data.score}%</div>
                            <div className="bar">
                                <div className="current-value" style={{ width: `${data.score}%` }}></div>
                            </div>
                        </div>
                        <div className="shortcut-links">
                            {data.shortcutLinks.map((entry, ix) => (
                                <div key={ix} onClick={() => window.open(entry.link, `_blank`)} className="entry">
                                    <div className="icon-container">
                                        <i className={`elm ${entry.icon}`}></i>
                                    </div>
                                    <div className="label">{entry.label}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="bottom-part">
                        <div className="heading">Live feed</div>
                        <div className="live-feed">
                            {data.liveFeed.map((entry, ix) => (
                                <div key={ix} className={`entry`}>
                                    <div className="date">{moment(new Date(`${entry.date}`)).format(`MMMM DD, yyyy`)}</div>
                                    <div className="text">
                                        Score changed from <span className="badge">{entry.previousScore}%</span> to{' '}
                                        <span className="badge">{entry.nextScore}%</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
