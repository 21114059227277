import React, { useState } from 'react';
import { AuditsContext } from '..';
import { logError, trackUserInteraction } from '../../../../utils/helpers';
import { getStatusColor } from './dashboard';
import { Button, TextField } from '@mui/material';
import { makeLocalEndpointRequest } from '../../../../utils/endpoints';
import moment from 'moment';

const Component = () => {
    const { data, auditId, setAuditId, setView, setData } = AuditsContext();
    const entry = data.auditItems.find((entry) => entry.id === auditId);
    const [correction, setCorrection] = useState(null);

    const goBack = () => {
        trackUserInteraction(`Clicked "Go Back"`);
        setView('Dashboard');
        setAuditId(null);
        window.history.pushState('_', '_', `/audits`);
    };

    const selectResource = async (resource) => {
        await trackUserInteraction(`Accessed Resource`, { resource: resource.name });
        window.open(resource.url, `_blank`);
    };

    const correctSwitch = () => {
        if (correction === null) return setCorrection('');
        if (correction !== null && correction.length < 1) return setCorrection(null);
        submitCorrection();
    };

    const submitCorrection = async () => {
        try {
            let correctionText = correction;
            let newData = { ...data };
            let index = null;
            let biggestId = 0;
            newData.auditItems.forEach((x, ix) => {
                if (x.id === auditId) {
                    index = ix;
                }
            });

            if (index === null) throw new Error(`Failed to find the audit entry id.`);
            newData.auditItems[index].details.auditTrail.forEach((x) => {
                if (x.id > biggestId) {
                    biggestId = x.id;
                }
            });
            newData.auditItems[index].details.auditTrail.push({
                date: new Date(),
                id: parseInt(biggestId) + 1,
                description: `The user submitted a comment: ${correctionText}`,
            });
            await makeLocalEndpointRequest(`/api/audits`, { ...newData, correctionText, auditItem: index }, `POST`);
            setData({ ...newData });
            setCorrection(null);
            window.showAlert(
                'Correction sent, an audit review is in process',
                `You've sent us the correction successfully and a member of our team will review your audit and get in touch.`,
                `success`,
            );
        } catch (err) {
            trackUserInteraction(`Having Difficulties`, { reason: `failed to submit correction on audit` });
            await logError(`SUBMIT_AUDIT_CORRECTION`, err);
            window.showAlert('Having Difficulties', `We're experiencing technical difficulties, please try again later`, `error`);
        }
    };

    return (
        <div className="audit-entry">
            <div className="left-side">
                <div className="details">
                    <div className="top-header">
                        <div className="title">{entry.title}</div>
                        <div className={`status ${getStatusColor(entry.status)}`}>{entry.status}</div>
                    </div>
                    <div className="body">
                        {entry.details.context && (
                            <div className="message context">
                                <div
                                    className="text"
                                    dangerouslySetInnerHTML={{ __html: entry.details.context.replaceAll('{BR}', '<br />') }}
                                />
                            </div>
                        )}
                        {entry.details.explanation && (
                            <div className="message context">
                                <div className="heading">Internal explanation</div>
                                <div
                                    className="text"
                                    dangerouslySetInnerHTML={{ __html: entry.details.explanation.replaceAll('{BR}', '<br />') }}
                                />
                            </div>
                        )}
                        {entry.details.recommendation && (
                            <div className="message recommendation">
                                <div className="heading">Internal recommendation</div>
                                <div
                                    className="text"
                                    dangerouslySetInnerHTML={{ __html: entry.details.recommendation.replaceAll('{BR}', '<br />') }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="audit-trail">
                    {entry.details.auditTrail.length > 0 && <div className="heading">Activity</div>}
                    {entry.details.auditTrail.map((entry, ix) => (
                        <div key={ix} className={`entry`}>
                            <div className="date">{moment(entry.date).format('MMMM DD, yyyy, HH:mm')}</div>
                            <div className="message">{entry.description}</div>
                        </div>
                    ))}
                </div>
                {correction !== null && (
                    <div className="correction-area">
                        <div className="heading">What have you done to correct this issue?</div>
                        <TextField
                            rows={5}
                            multiline={true}
                            variant="standard"
                            value={correction}
                            placeholder="Write here the measures you have taken to correct this issue, post links to documents required etc."
                            fullWidth={true}
                            onChange={(e) => setCorrection(e.target.value)}
                        />
                    </div>
                )}
                <div className="buttons">
                    <Button variant="outlined" color="primary" onClick={goBack}>
                        Go back
                    </Button>
                    {entry.status !== 'Compliant' && (
                        <Button
                            // disabled={correction !== null && correction.length < 1}
                            variant="contained"
                            color="primary"
                            onClick={correctSwitch}
                        >
                            {correction === null ? `Correct it` : correction.length > 0 ? `Submit correction` : `Cancel correction`}
                        </Button>
                    )}
                </div>
            </div>
            <div className="right-side">
                <div className="resources">
                    <div className="heading">Resources</div>
                    {entry.resourceURLS.map((en, ix) => (
                        <div onClick={() => selectResource(en)} key={ix} className="entry">
                            {en.title}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Component;
