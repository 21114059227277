import React from 'react';
import { Button } from '@mui/material';
import { ComponentState } from '../..';
import UpdateAppSumoDialog from './components/updateAppSumoDetails';

// Components
import Switch from './components/switch';
import SmartCookieBlocker from './components/smartCookieBlocker';
import PrivacyTrigger from './components/privacyTrigger/index';
import Colors from './components/colors';
import Language from './components/languages';
import CCPA from './components/ccpa';
import Link from './components/link';
import TCF from './components/tcf';
import GoogleConsentMode from './components/googleConsentMode';

const Component = () => {
    const { saveChanges, saveDisabled, setUpdateInvoiceDetails } = ComponentState();

    return (
        <React.Fragment>
            <UpdateAppSumoDialog hidePaywall={() => setUpdateInvoiceDetails(false)} />
            <Switch />
            <Language />
            <PrivacyTrigger />
            <Colors />
            <Link />
            <SmartCookieBlocker />
            <CCPA />
            <TCF />
            <GoogleConsentMode />
            <div className="page-footer">
                <Button data-cy="save-button" variant="contained" color="primary" onClick={saveChanges} disabled={saveDisabled}>
                    Save changes
                </Button>
            </div>
        </React.Fragment>
    );
};

export default Component;
