import { FormHelperText, TextField } from '@mui/material';
import React from 'react';

// Context
import { PanelContext } from '../../..';
import { ViewContext } from '..';

const Component = () => {
    const { data, setData } = PanelContext();
    const { validationResults } = ViewContext();

    const setValue = (value) => {
        setData((currentState) => {
            let newState = { ...currentState };

            newState.retention.customText = value;

            return newState;
        });
    };

    const getErrorMessage = () => {
        if (validationResults['retention.customText']) return validationResults['retention.customText'];
        return null;
    };

    // We don't render if that is true.
    if (data.retention.type !== 'manual:customText') return null;

    return (
        <React.Fragment>
            <div className="form-group">
                <div className="component-label">
                    <div className="text">Specify a Personalized Retention Duration</div>
                </div>
                <div className="component-description">
                    <div className="text">
                        Define your preferred timeframe for Infrastructure, Integrations, or File Storage. Customize retention with
                        free-text entry in days, months, or years for flexible data management.
                    </div>
                </div>
                <div className="component-content">
                    <TextField
                        value={data.retention.customText}
                        type="text"
                        rows={4}
                        data-cy="input-customRetentionText"
                        multiline={true}
                        onChange={(ev) => setValue(ev.target.value)}
                        fullWidth={true}
                        variant="outlined"
                        placeholder="Write your custom text here.."
                    />
                    {/* Error */}
                    {getErrorMessage() && (
                        <React.Fragment>
                            <FormHelperText style={{ marginTop: 6 }} error={true}>
                                {getErrorMessage()}
                            </FormHelperText>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Component;
